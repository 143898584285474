import React, { useState } from 'react';

import RadioButtons from '../../../Common/Components/RadioButtons';
import WizardPeriodSelector from '../../../Common/Components/WizardPeriodSelector';
import { ReactComponent as NodesIcon } from '../../../img/icons/nodes.svg';
import { RadioButtonElementProps } from '../../../types/RadioButtonsProps';
import { WizardNodeAndPeriodSelectorProps } from '../../../types/WizardNodeAndPeriodSelectorProps';

function WizardNodeAndPeriodSelector(props: WizardNodeAndPeriodSelectorProps): JSX.Element {
  const {
    selectedNodes,
    granularity,
    setGranularity,
    mode,
    setMode,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isGroupType = false,
    selectedGroup,
  } = props;

  const granularityOptions = isGroupType
    ? [{ label: 'Summary', key: 'aggregated' }, { label: 'Individual node', key: 'individual' }]
    : [{ label: 'Individual node', key: 'individual' }];

  const [selectedMode, setSelectedMode] = useState<RadioButtonElementProps>(granularityOptions.find((e) => e.key === mode) as RadioButtonElementProps);

  return (
    <div className="modal-divided">
      <div className="modal-divided__sidebar">
        <div className="energy-node-selector energy-node-selector--individual-node">
          <span className="site-info">
            <NodesIcon />
            <span className="site-info__title">
              {/* eslint-disable-next-line no-nested-ternary */}
              {isGroupType ? 'Group selected' : (selectedNodes.length > 1 ? 'Nodes selected' : 'Node ID')}
            </span>
            <span className="site-info__subtitle">
              <div>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isGroupType ? selectedGroup?.name : (selectedNodes.length > 1 ? selectedNodes.length : selectedNodes[0]?.nodeid)}
              </div>
            </span>
          </span>
          <div className="energy-node-selector__radio-title required"> Granularity </div>
          <RadioButtons
            onClick={(clickedRadioBtn: RadioButtonElementProps) => {
              setMode(clickedRadioBtn.key);
              setSelectedMode(clickedRadioBtn);
            }}
            selected={selectedMode}
            list={granularityOptions}
            type="light"
            extraClasses="vertical"
          />
        </div>
      </div>
      <div className="modal-divided__content">
        <WizardPeriodSelector
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          granularity={granularity}
          setGranularity={setGranularity}
          addClass="date-period-selector--individual-node"
        />
      </div>
    </div>
  );
}

export default WizardNodeAndPeriodSelector;
