/* eslint-disable */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { postRequest, putRequest, deleteRequest } from '../../../../../utils/fetch';
import LightingMap from '../../../../../Common/Components/LightingMap';
import Utils from '../../../../../utils/Utils';
import Loading from '../../../../../Common/Components/Loading';
import Textbox from '../../../../../Common/Components/Textbox';
import formValidation from '../../../../../utils/form/formValidation';
import Modal from '../../../../../Common/Components/Modal';
import { AdminSiteModalProps } from '../../../../../types/AdminSiteModalProps';
import { GeoFence, SiteObject, SiteWithNodeAggregates } from '../../../../../types/SiteObject';
import SmallDataBox from '../../Components/SmallDataBox';
import { SmallDataBoxItems } from '../../../../../types/SmallDataBox';
import SiteModalCustomTitle from './SiteModalCustomTitle';
import { FormValidationProps } from '../../../../../types/formValidation';

import { ReactComponent as ErrorIcon } from '../../../../../img/icons/error.svg';
import { ReactComponent as SiteLocatorIcon } from '../../../../../img/icons/site-locator.svg';
import { ReactComponent as HandshakeIcon } from '../../../../../img/icons/handshake.svg';
import { ReactComponent as NodesIcon } from '../../../../../img/icons/nodes.svg';
import { MapSearchResult } from '../../../../../types/MapProps';
import MapUtils from '../../../../../utils/MapUtils';
import { useAppContext } from '../../../../../utils/AppContext';
import SelectBox from '../../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';

const smallDataBoxWidth = 160;
const selectboxWidth = 210;
const geoFenceRadiusMiles = 200;

function AdminSiteModal(props: AdminSiteModalProps): JSX.Element {
  const { type, site, partners, customersMap, setModalOpen, updateSites } = props;

  const { addNotification } = useAppContext();

  const [createSitePartner, setCreateSitePartner] = useState<SelectBoxItemType>({ key: '', title: '' });
  const [createSiteAccount, setCreateSiteAccount] = useState<SelectBoxItemType>({ key: '', title: '' });
  const [siteEdited, setSiteEdited] = useState<SiteWithNodeAggregates>(site);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number; }>(Utils.getSiteLatLng(site));
  const [getSitesForOrg, setGetSitesForOrg] = useState('');
  const [siteValidation, setSiteValidation] = useState({
    hasSiteNameError: false,
    siteNameErrorMessage: '',
    hasPartnerError: false,
    partnerErrorMessage: '',
    hasAccountError: false,
    accountErrorMessage: '',
    hasGeofenceError: false,
    geofenceErrorMessage: '',
    hasMapError: false,
    mapErrorMessage: '',
  });

  const latLngToFixed = 6;
  const defaultZoom = 14;
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { data: orgSites } = useSWR<SiteObject[]>(
    getSitesForOrg ? [`/organizations/${getSitesForOrg}/sites`, 'AdminSiteModalSites'] : null,
  );

  useEffect(() => {
    if (createSiteAccount.key) {
      setGetSitesForOrg(createSiteAccount.key);
    } else if (createSitePartner.key) {
      setGetSitesForOrg(createSitePartner.key);
    } else {
      setGetSitesForOrg('');
    }
  }, [createSiteAccount.key, createSitePartner.key]);

  useEffect(() => {
    if (type === 'edit') {
      if (site && site.orgid) {
        setGetSitesForOrg(site.orgid);
      } else if (site && site.partner_org_id) {
        setGetSitesForOrg(site.partner_org_id);
      } else {
        setGetSitesForOrg('');
      }
    }
  }, [site]);

  const allSitesGeoJson = useMemo(() => {
    const siteGeoJson: Map<string, GeoJSON.Position[][]> = new Map();

    orgSites?.forEach((siteObj) => {
      if (MapUtils.isValidGeoFence(siteObj.geo_fence)) {
        siteGeoJson.set(siteObj.siteid, [siteObj.geo_fence.map((geoFence) => [parseFloat(geoFence.longitude), parseFloat(geoFence.latitude)])]);
      }
    });

    return siteGeoJson;
  }, [orgSites]);

  const filteredAllSitesGeoJson: GeoJSON.MultiPolygon['coordinates'] = useMemo(() => {
    const tmpMap = new Map(Array.from(allSitesGeoJson));
    tmpMap.delete(siteEdited.siteid);
    const tmpArr = Array.from(tmpMap.values());

    if (!siteEdited.latitude || !siteEdited.longitude) {
      return [];
    }

    // for map update
    setMapCenter(Utils.getSiteLatLng(siteEdited));

    return [
      tmpArr
        .flat(1)
        .filter((siteGeoJson) => MapUtils.getDistance([parseFloat(siteEdited.longitude), parseFloat(siteEdited.latitude)], siteGeoJson[0]) < geoFenceRadiusMiles),
    ];
  }, [siteEdited.siteid, siteEdited.latitude, siteEdited.longitude, allSitesGeoJson]);

  const validateSiteName = (name: string): FormValidationProps => formValidation(name, { required: true, min: 2, max: 256, noSpecCharacters: true });
  const validatePartnerId = (id: string): FormValidationProps => formValidation(id, { required: true });
  const validateAccountId = (id: string): FormValidationProps => formValidation(id, { required: true });
  const validateGeoFence = (geofence: GeoFence | undefined): FormValidationProps => {
    const validate: FormValidationProps = {
      hasError: false,
      errorMsg: '',
    };

    if (geofence === undefined || geofence.length === 0) {
      validate.hasError = true;
      validate.errorMsg = 'Site has no geo-fence';
    }

    return validate;
  };

  const setSiteName = (name: string): void => {
    const validation = validateSiteName(name);
    setSiteEdited((oldValues) => ({ ...oldValues, name }));
    setSiteValidation((oldValues) => ({ ...oldValues, hasSiteNameError: validation.hasError, siteNameErrorMessage: validation.errorMsg }));
  };

  const setSearchLocation = (search: MapSearchResult): void => {
    if (!MapUtils.isValidSearchLocation(search)) {
      return;
    }

    const latMargin = 0.125;
    const lonMargin = 0.2;

    const NE = L.latLng({
      lat: search.position.lat + latMargin,
      lng: search.position.lng - lonMargin,
    });
    const SW = L.latLng({
      lat: search.position.lat - latMargin,
      lng: search.position.lng + lonMargin,
    });
    const NW = L.latLng(NE.lat, SW.lng);
    const SE = L.latLng(SW.lat, NE.lng);

    const newGeoFence = [
      {
        latitude: NE.lat.toFixed(latLngToFixed),
        longitude: NE.lng.toFixed(latLngToFixed),
      },
      {
        latitude: NW.lat.toFixed(latLngToFixed),
        longitude: NW.lng.toFixed(latLngToFixed),
      },
      {
        latitude: SW.lat.toFixed(latLngToFixed),
        longitude: SW.lng.toFixed(latLngToFixed),
      },
      {
        latitude: SE.lat.toFixed(latLngToFixed),
        longitude: SE.lng.toFixed(latLngToFixed),
      },
    ];

    // adjust for leaflet-geosearch version >= 3.7.0
    let street, city, state, country;
    if (search.resultType && (search.resultType === 'street' || search.resultType === 'houseNumber')) {
      if (search.address.label) {
        [street, city, state, country] = search.address.label.split(', ');
        state = state.substr(0, state.indexOf(' '));
      } else {
        [street, city, state, country] = [search.address.street, search.address.city, search.address.state, search.address.countryCode];
      }
    } else {
      [city, state, country] = search.address.label
      ? search.address.label.split(', ')
      : [search.address.city, search.address.state, search.address.countryCode];
      street = '';
    }

    setSiteEdited((oldValues) => ({
      ...oldValues,
      latitude: search.position.lat.toFixed(latLngToFixed),
      longitude: search.position.lng.toFixed(latLngToFixed),
      geo_fence: newGeoFence,
      city: city || '',
      country: country || '',
      postal_code: search.address.postalCode || '',
      state: state || '',
      street1: street || '',
    }));

    setMapCenter(search.position);
  };

  const setGeoFence = (geoFence: GeoFence, center: L.LatLng) => {
    setSiteEdited((oldValues) => ({ ...oldValues, geo_fence: geoFence, latitude: center.lat.toFixed(latLngToFixed), longitude: center.lng.toFixed(latLngToFixed) }));
  };

  const accountItems: SmallDataBoxItems = useMemo(() => [
    { title: 'Partner', text: site.partner_org_name || '' },
    { title: 'Account', text: site.org_name || '' },
  ], [site]);

  const createEditSiteName = (
    <Textbox
      label="Site name"
      name="Site name"
      placeholder="Enter"
      isRequired
      value={siteEdited.name}
      error={siteValidation.hasSiteNameError}
      errorMessage={siteValidation.siteNameErrorMessage}
      onChange={(e) => {
        setSiteName(e.target.value);
      }}
    />
  );

  const createEditContactInfo = (
    <>
      <div className="site-modal--section">Contact info</div>
      <Textbox
        label="Name"
        name="Name"
        placeholder="Enter"
        value={siteEdited.contact_name || ''}
        onChange={(e) => setSiteEdited((oldValues) => ({ ...oldValues, contact_name: e.target.value }))}
      />
      <Textbox
        label="Phone"
        name="Phone"
        placeholder="Enter"
        value={siteEdited.contact_phone || ''}
        onChange={(e) => setSiteEdited((oldValues) => ({ ...oldValues, contact_phone: e.target.value }))}
      />
      <Textbox
        label="Email"
        name="Email"
        placeholder="Enter"
        value={siteEdited.contact_email || ''}
        onChange={(e) => setSiteEdited((oldValues) => ({ ...oldValues, contact_email: e.target.value }))}
      />
    </>
  );

  const createEditAddress = (
    <>
      <Textbox
        label="Address 1"
        name="Address 1"
        placeholder="Enter"
        value={siteEdited.street1 || ''}
        onChange={(e) => setSiteEdited((oldValues) => ({ ...oldValues, street1: e.target.value }))}
      />
      <Textbox
        label="Address 2"
        name="Address 2"
        placeholder="Enter"
        value={siteEdited.street2 || ''}
        onChange={(e) => setSiteEdited((oldValues) => ({ ...oldValues, street2: e.target.value }))}
      />
      <Textbox
        label="City"
        name="City"
        placeholder="Enter"
        value={siteEdited.city || ''}
        onChange={(e) => setSiteEdited((oldValues) => ({ ...oldValues, city: e.target.value }))}
      />
      <div className="site-modal--state-zip">
        <div className="site-modal--state">
          <Textbox
            label="State"
            name="State"
            placeholder="Enter"
            value={siteEdited.state || ''}
            onChange={(e) => setSiteEdited((oldValues) => ({ ...oldValues, state: e.target.value }))}
          />
        </div>
        <div className="site-modal--zip">
          <Textbox
            label="Zip"
            name="Zip"
            placeholder="Enter"
            value={siteEdited.postal_code || ''}
            onChange={(e) => setSiteEdited((oldValues) => ({ ...oldValues, postal_code: e.target.value }))}
          />
        </div>
      </div>
    </>
  );

  const validateForm = (apiType: string): boolean => {
    const siteNameValidation = validateSiteName(siteEdited.name);
    const geofenceValidation = validateGeoFence(siteEdited.geo_fence);

    let validation = {
      ...siteValidation,
      hasSiteNameError: siteNameValidation.hasError,
      siteNameErrorMessage: siteNameValidation.errorMsg,
      hasGeofenceError: geofenceValidation.hasError,
      geofenceErrorMessage: geofenceValidation.errorMsg,
    };

    if (apiType === 'create') {
      const partnerValidation = validatePartnerId(createSitePartner.key);
      const accountValidation = validateAccountId(createSiteAccount.key);

      validation = {
        ...validation,
        hasPartnerError: partnerValidation.hasError,
        partnerErrorMessage: partnerValidation.errorMsg,
        hasAccountError: accountValidation.hasError,
        accountErrorMessage: accountValidation.errorMsg,
      };
    }

    setSiteValidation((oldValues) => ({ ...oldValues, ...validation }));

    if (validation.hasSiteNameError || validation.hasPartnerError || validation.hasAccountError || validation.hasMapError) {
      return false;
    }

    return true;
  };

  const removeExtraFields = (allSiteObj: SiteWithNodeAggregates): SiteObject => {
    const {
      number_of_nodes,
      added_since_sunday,
      watch_list,
      partner_org_id,
      partner_org_name,
      orgid,
      org_name,
      altitude,
      ...siteObj
    } = allSiteObj;

    return siteObj;
  };

  const sendToSiteApi = async (apiType: string) => {
    if (!validateForm(apiType)) {
      return;
    }

    const siteBody = removeExtraFields(siteEdited);

    try {
      let result;

      switch (apiType) {
        case 'delete':
          result = await deleteRequest(`/organizations/${site.orgid}/sites/${site.siteid}`);
          break;
        case 'create':
          setSubmitDisabled(true);
          result = await postRequest(`/organizations/${createSiteAccount.key}/sites`, siteBody);
          break;
        default:
          result = await putRequest(`/organizations/${site.orgid}/sites/${site.siteid}`, siteBody);
          break;
      }

      if (!result.error) {
        addNotification({ type: 'success', message: `Your ${apiType} site operation is completed.` });
        updateSites();
        setModalOpen(0);
      } else {
        setSubmitDisabled(false);
        addNotification({ type: 'error', message: `Your ${apiType} site operation has failed: ${result.error}` });
      }
    } catch (error) {
      setSubmitDisabled(false);
      addNotification({ type: 'error', message: `Your ${apiType} site operation has failed.` });
    }
  };

  return (
    <>
      {type === 'create' && (
      <Modal
        setModalOpen={() => setModalOpen(0)}
        width="1166"
        title="Create site"
        primaryButtonAction={() => sendToSiteApi('create')}
        primaryButtonLabel="Create"
        primaryButtonDisabled={submitDisabled}
        secondaryButtonLabel="Cancel"
        className="padding-30"
      >
        <div className="site-modal">
          <div className="site-modal--left">
            <div className="site-modal--info">
              <div className="site-modal--infoicon">1</div>
              Enter site details & contact info
            </div>
            {createEditSiteName}
            <SelectBox
              label="Partner"
              title={createSitePartner.title || 'Select partner'}
              type="light"
              listWidth={selectboxWidth}
              isRequired
              list={Utils.arrayToSelectbox(Array.from(partners.values()), 'orgid', 'name')}
              onClick={(item) => {
                const partnerValidation = validatePartnerId(item.key);
                setSiteValidation((oldValues) => ({ ...oldValues, hasPartnerError: partnerValidation.hasError, partnerErrorMessage: partnerValidation.errorMsg }));
                setCreateSitePartner(item);
                setCreateSiteAccount({ key: '', title: '' });
              }}
              error={siteValidation.hasPartnerError}
              errorMessage={siteValidation.partnerErrorMessage}
            />
            <SelectBox
              label="Account"
              title={createSiteAccount.title || 'Select account'}
              type="light"
              listWidth={selectboxWidth}
              isRequired
              list={createSitePartner.key ? Utils.arrayToSelectbox(Array.from(customersMap.values()).filter((customer) => customer.po === createSitePartner.key), 'orgid', 'name') : []}
              onClick={(item) => {
                const accountValidation = validateAccountId(item.key);
                setSiteValidation((oldValues) => ({ ...oldValues, hasAccountError: accountValidation.hasError, accountErrorMessage: accountValidation.errorMsg }));
                setCreateSiteAccount(item);
              }}
              error={siteValidation.hasAccountError}
              errorMessage={siteValidation.accountErrorMessage}
            />
            {createEditContactInfo}
          </div>
          <div className={`site-modal--map ${siteValidation.hasGeofenceError || siteValidation.hasMapError ? 'map-error' : ''}`}>
            <div className="site-modal--info">
              <div className="site-modal--infoicon">2</div>
              Define the site geo-fence
            </div>
            <LightingMap
              id="SitemanagerModalMap"
              zoom={1}
              center={siteEdited.geo_fence ? mapCenter : { lat: 39.8333, lng: -98.5833 }} // center of US
              defaultLayer="Map"
              geoFence={siteEdited.geo_fence || undefined}
              setGeoFence={setGeoFence}
              setMapError={(errorMsg: string) => setSiteValidation((oldValues) => ({ ...oldValues, hasMapError: (errorMsg !== ''), mapErrorMessage: errorMsg }))}
              allSitesGeoJson={filteredAllSitesGeoJson}
              setSearchResult={setSearchLocation}
              featuresEnabled={{ layersSelectionControl: true, search: true }}
            />
            {siteValidation.hasGeofenceError && (
            <div className="map-error-text">
              <ErrorIcon />
              {siteValidation.geofenceErrorMessage}
            </div>
            )}
            {siteValidation.hasMapError && (
            <div className="map-error-text">
              <ErrorIcon />
              {siteValidation.mapErrorMessage}
            </div>
            )}
          </div>
          <div className="site-modal--right">
            <div className="site-modal--info">
              <div className="site-modal--infoicon">3</div>
              Refine the site address
            </div>
            {createEditAddress}
          </div>
        </div>
      </Modal>
      )}
      {type === 'edit' && (
      <Modal
        setModalOpen={() => setModalOpen(0)}
        width="1166"
        customTitle={<SiteModalCustomTitle site={siteEdited} orgId={site.orgid} title="Edit site" />}
        primaryButtonAction={() => sendToSiteApi('edit')}
        primaryButtonLabel="Save"
        tertiaryButtonLabel="Cancel"
        secondaryButtonLabel={site.number_of_nodes === 0 ? 'Delete' : undefined}
        secondaryButtonAction={site.number_of_nodes === 0 ? () => sendToSiteApi('delete') : undefined}
        className="padding-30"
      >
        <div className="site-modal">
          <div className="site-modal--left">
            {createEditSiteName}
            {accountItems.length && (
            <SmallDataBox
              icon={<HandshakeIcon width="24px" height="15px" fill="#747676" />}
              items={accountItems}
              hasToolTip
              width={smallDataBoxWidth}
            />
            )}
            <SmallDataBox
              icon={<NodesIcon width="24px" height="24px" fill="#747676" />}
              items={[{ title: 'Total nodes', text: site.number_of_nodes?.toString() || '' }]}
            />
            {createEditContactInfo}
          </div>
          <div className={`site-modal--map ${siteValidation.hasGeofenceError || siteValidation.hasMapError ? 'map-error' : ''}`}>
            {siteEdited ? (
              <>
                <LightingMap
                  id="SitemanagerModalMap"
                  zoom={defaultZoom}
                  center={mapCenter}
                  defaultLayer="Map"
                  geoFence={siteEdited.geo_fence || undefined}
                  setGeoFence={setGeoFence}
                  setMapError={(errorMsg: string) => setSiteValidation((oldValues) => ({ ...oldValues, hasMapError: (errorMsg !== ''), mapErrorMessage: errorMsg }))}
                  allSitesGeoJson={filteredAllSitesGeoJson}
                  setSearchResult={setSearchLocation}
                  featuresEnabled={{ layersSelectionControl: true, search: true }}
                />
                {siteValidation.hasGeofenceError && (
                <div className="map-error-text">
                  <ErrorIcon />
                  {siteValidation.geofenceErrorMessage}
                </div>
                )}
                {siteValidation.hasMapError && (
                <div className="map-error-text">
                  <ErrorIcon />
                  {siteValidation.mapErrorMessage}
                </div>
                )}
              </>
            ) : (<Loading />)}
          </div>
          <div className="site-modal--right">
            {createEditAddress}
          </div>
        </div>
      </Modal>
      )}
      {type === 'view' && (
      <Modal
        setModalOpen={() => setModalOpen(0)}
        width="935"
        customTitle={<SiteModalCustomTitle site={site} orgId={site.orgid} title="View site" />}
        primaryButtonAction={() => setModalOpen(0)}
        primaryButtonLabel="Close"
        className="padding-30"
      >
        <div className="site-modal">
          <div className="site-modal--left">
            <SmallDataBox
              icon={<SiteLocatorIcon width="21px" height="24px" fill="#747676" />}
              items={[{ title: 'Site name', text: site.name }]}
              width={smallDataBoxWidth}
              hasToolTip
            />
            {accountItems.length && (
            <SmallDataBox
              icon={<HandshakeIcon width="24px" height="15px" fill="#747676" />}
              items={accountItems}
              hasToolTip
              width={smallDataBoxWidth}
            />
            )}
            <SmallDataBox
              icon={<NodesIcon width="24px" height="24px" fill="#747676" />}
              items={[{ title: 'Total nodes', text: site.number_of_nodes?.toString() || '' }]}
            />
            {(site.contact_name || site.contact_phone || site.contact_email) && (
            <div className="info-box">
              <div className="info-box--title">Contact info</div>
              <div className="info-box--text">{site.contact_name || ''}</div>
              <div className="info-box--text">{site.contact_phone || ''}</div>
              <div className="info-box--text">{site.contact_email || ''}</div>
            </div>
            )}
            <div className="info-box">
              <div className="info-box--title">Site address</div>
              <div className="info-box--text">{site.street1 || ''}</div>
              <div className="info-box--text">{site.street2 || ''}</div>
              <div className="info-box--text">{`${site.city || ''} ${site.state || ''}, ${site.postal_code || ''}`}</div>
            </div>
          </div>
          <div className="site-modal--map">
            {site ? (
              <LightingMap
                id="SitemanagerModalMap"
                zoom={defaultZoom}
                center={Utils.getSiteLatLng(site)}
                defaultLayer="Map"
                geoFence={site.geo_fence || undefined}
                featuresEnabled={{ layersSelectionControl: true }}
              />
            ) : (<Loading />)}
          </div>
        </div>
      </Modal>
      )}
    </>
  );
}

export default AdminSiteModal;
