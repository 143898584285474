import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import SlideIn from '../../../Common/Components/SlideIn';
import Button from '../../../Common/Components/Button';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import { FirmwareObject } from '../../../types/FirmwareObjects';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Utils from '../../../utils/Utils';
import { NodeObject } from '../../../types/NodeObject';
import { postRequest } from '../../../utils/fetch';

function LightsFirmware(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const history = useHistory();

  const [expand, setExpand] = useState(false);
  const [firmwareDetails, setFirmwareDetails] = useState<Array<FirmwareObject> | undefined>();

  const selectedFirmwareByNode = useMemo(() => Utils.getFirmwareByNodeModelName(selectedNode, firmwareDetails), [firmwareDetails, selectedNode]);

  const isSensityUserAdmin = Utils.isSensityUserAdmin();

  useEffect(() => {
    postRequest('/fota/firmware/details', { firmwareNames: [selectedNode.softwareVersion] })
      .then((response) => {
        if (!response.error) {
          setFirmwareDetails(response.data as Array<FirmwareObject>);
        }
      });
  }, [selectedNode]);

  return (
    <div className="firmware">
      <SlideIn expand={expand} setExpand={setExpand}>
        <>
          <div className="slide-in__title">
            <span>App firmware</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
            { isSensityUserAdmin && (
            <div className="slide-in__title-subtitle">
              <div className="slide-in__link">
                <Button
                  label="View FOTA campaigns"
                  onClick={() => { history.push('/admin/fotacampaigns'); }}
                />
              </div>
            </div>
            )}
          </div>
          <div className="slide-in__content">
            <SlideinListElementBig
              title="Node model"
              value={selectedNode.modelName}
              isFirstElement
              valueType="small"
            />
            <SlideinListElementBig
              title="ID"
              value={selectedFirmwareByNode?.firmwareId || '--'}
              valueType="small"
            />
            <SlideinListElementBig
              title="Release date"
              value={selectedFirmwareByNode?.releaseDate ? DateTime.fromISO(selectedFirmwareByNode?.releaseDate).toFormat('MM/dd/yyyy') : '--'}
              valueType="small"
            />
            <SlideinListElementBig
              title="Version"
              value={selectedFirmwareByNode?.version || '--'}
              valueType="small"
            />
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsFirmware;
