import React from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderButtonPropsType } from '../../../types/HeaderButton';

function HeaderButton(props: HeaderButtonPropsType): JSX.Element {
  const { disabled, link, path, icon, name, setActiveHeaderButton, order } = props;
  const history = useHistory();
  const isSelected = path.includes(name) ? 'active' : '';

  const btnStyle = disabled ? { cursor: 'default' } : {};

  return (
    <li className={`header__links-item ${isSelected}`}>
      <button
        type="button"
        onClick={() => {
          setActiveHeaderButton(order);
          history.push(link);
        }}
        className="header__dropdown-button"
        data-testid={`headerbutton${name}`}
        disabled={disabled}
        style={btnStyle}
      >
        {icon}
      </button>
    </li>
  );
}

export default HeaderButton;
