import React from 'react';
import { ToolbarButtonPropsType } from '../../types/ToolbarButtonPropsType';
import Tooltip from './Tooltip';

function ToolbarButton(props: ToolbarButtonPropsType): JSX.Element {
  const {
    children,
    icon,
    toggleToolbarBtn,
    activeToolbarBtn,
    addClass,
    order,
    last,
    noborder,
    tooltipText,
    shouldOpenModal,
  } = props;

  const tooltipOffset = -15;

  return (
    <div data-testid="tooltip-component" className={`toolbar-tabs__links-icons ${order === activeToolbarBtn ? 'active' : ''} ${addClass || ''}`}>
      <Tooltip text={tooltipText} offset={tooltipOffset}>
        <div
          onClickCapture={() => {
            toggleToolbarBtn(activeToolbarBtn === order ? 0 : order);
            if (shouldOpenModal) {
              shouldOpenModal();
            }
          }}
          className={`toolbar-tabs__links-icons-header ${last ? 'last' : ''} ${noborder ? 'no-border' : ''}`}
          data-testid="toolbar-btn"
        >
          <div>{icon}</div>
        </div>
      </Tooltip>
      { order === activeToolbarBtn && <div className="toolbar-tabs__links-icons-content">{children}</div>}
    </div>
  );
}

export default ToolbarButton;
