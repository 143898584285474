/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useRef, useEffect } from 'react';

import MultiSelectBox from './MultiSelectBox';
import { FilterMultiSelectBoxProps } from '../../types/FilterMultiSelectBox.d';
import { MultiSelectBoxItem } from '../../types/MultiSelectBox.d';

import { ReactComponent as Close } from '../../img/icons/close-small.svg';
import { ReactComponent as DownArrowIcon } from '../../img/icons/down-arrow.svg';

const multiSelectBoxHeight = 162;
const defaultWidth = 200;

export default function FilterMultiSelectBox(
  props: FilterMultiSelectBoxProps,
): JSX.Element {
  const {
    type = 'input',
    data = [],
    width = defaultWidth,
    selectedData,
    label,
    inputId,
    placeholder = 'Select',
    onInputChange,
    onCheckboxClick,
    hideSearchBox = false,
  } = props;
  const selectedTextData = typeof selectedData === 'string' ? selectedData : '';

  const [inputValue, setInputValue] = useState(selectedTextData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filterText, setFilterText] = useState<string>('');

  // eslint-disable-next-line no-nested-ternary
  const sortedData = useRef(data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0))));
  const filteredData = filterText?.length
    ? sortedData.current.filter((item: MultiSelectBoxItem) => item.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()))
    : sortedData.current;

  const selectAllMode = filteredData.length > 1;

  const placeholderText = !selectedData ? placeholder : '';

  const filterInput = useRef<HTMLInputElement>(null);

  const filterMultiSelectBox = useRef<HTMLDivElement>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
    setIsDropdownOpen(true);
    if (onInputChange) {
      onInputChange(event.target.value);
    }
  };

  const handleOnCheckboxClick = (selected: Set<string>) => {
    setInputValue('');
    if (selected.size === 0 && onInputChange) {
      onInputChange('');
    } else {
      onCheckboxClick(selected);
    }

    const element = filterInput.current as HTMLInputElement;
    element.focus();
  };

  useEffect(() => {
    if (
      !selectedData
      || (selectedData instanceof Set && selectedData?.size === 0)
      || typeof selectedData === 'string'
    ) {
      setInputValue(selectedTextData);
    }

    if (!selectedData) {
      setFilterText('');
    }
  }, [selectedData, selectedTextData]);

  const showXButton = !!(
    (selectedData instanceof Set && !!selectedData.size)
    || (typeof selectedData === 'string' && selectedData.length)
  );

  function handleCloseIconClick() {
    setInputValue('');
    if (onInputChange) {
      onInputChange('');
    } else {
      onCheckboxClick(new Set([]));
    }
  }

  function handleInputWrapperClick() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <div className="filter-multi-select-box" ref={filterMultiSelectBox} style={{ width }}>
      {label && (
        <label className="input-label" htmlFor={inputId}>
          {label}
        </label>
      )}
      <div className="input-wrapper">
        <div
          role="button"
          onClick={handleInputWrapperClick}
          onKeyDown={handleInputWrapperClick}
        >
          {type === 'input' ? (
            <input
              id={inputId}
              data-testid={inputId}
              type="text"
              data-lpignore // Disable LastPass
              style={{
                width,
              }}
              placeholder={placeholderText}
              disabled={selectedData instanceof Set}
              value={inputValue}
              onChange={handleOnChange}
              ref={filterInput}
              className={`${isDropdownOpen ? 'open' : ''}`}
              autoComplete="off"
            />
          ) : (
            <textarea
              id={inputId}
              data-testid={inputId}
              data-lpignore
              style={{
                width,
              }}
              placeholder={placeholder}
              value={inputValue}
              onChange={handleOnChange}
              className="notes"
            />
          )}

          <span className="vertical-center selected-text">
            {selectedData instanceof Set && (
            <span data-testid="selected-text">
              <span className="selected-highlight">
                {selectedData?.size || 0}
              </span>
              {' '}
              selected
            </span>
            )}
          </span>
        </div>

        {showXButton && type === 'input' && (
          <button
            type="button"
            data-testid="x-button"
            className="vertical-center close-icon"
            onClick={handleCloseIconClick}
            onKeyDown={handleCloseIconClick}
          >
            <Close />
          </button>
        )}
        {data.length && (
          <DownArrowIcon
            onClick={() => handleInputWrapperClick()}
            className="arrow-down"
          />
        )}
      </div>

      {isDropdownOpen
        && data.length
        ? (
          <MultiSelectBox
            className={`overlay-box ${inputId}`}
            filteredData={filteredData}
            data={data}
            onCheckboxClick={handleOnCheckboxClick}
            height={multiSelectBoxHeight}
            width={width}
            filterText={filterText}
            setFilterText={setFilterText}
            showSearchInput={hideSearchBox ? false : data.length > 3}
            selectedData={
              selectedData instanceof Set ? selectedData : new Set()
            }
            selectAllMode={selectAllMode}
          />
        ) : (
          <></>
        )}
    </div>
  );
}
