import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';

import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import Table from '../../../../Common/Components/Table';
import getHeaderProps from '../../../../utils/getHeaderProps';
import { SystemalertsPageProps } from '../../../../types/SystemalertsPageProps';
import { SystemAlertObject } from '../../../../types/SystemAlertObject';
import SystemAlertModal from './Components/SystemAlertModal';

import { getRequest } from '../../../../utils/fetch';
import { useAppContext } from '../../../../utils/AppContext';
import Loading from '../../../../Common/Components/Loading';

function SystemAlertsPage(props: SystemalertsPageProps): JSX.Element {
  const headers = getHeaderProps('SystemAlerts');
  const { addNotification } = useAppContext();

  const [openModal, setOpenModal] = useState(0);
  const [selectedAlert, setSelectedAlert] = useState<SystemAlertObject>();
  const [systemalerts, setSystemAlerts] = useState<Array<SystemAlertObject>>();

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'SystemAlerts');
  const { data: systemalertsResp, mutate: updateAlerts } = useSWR<Array<SystemAlertObject>>(() => '/system-feedback-channel/system-alerts&uilimit=2000');
  useEffect(() => {
    if (systemalertsResp) {
      const lastEntry = systemalertsResp.length - 1;
      setSystemAlerts(systemalertsResp);
      getAllSystemAlerts(systemalertsResp[lastEntry].id);
    }
    function getAllSystemAlerts(afterEntry: number) {
      try {
        getRequest(
          `/system-feedback-channel/system-alerts?after=${afterEntry}`,
        ).then((response) => {
          setSystemAlerts(systemalertsResp?.concat(response));
        });
      } catch (e) {
        addNotification({ type: 'error', message: 'Unable to retrieve all system alerts' });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemalertsResp]);
  return (
    <div className="content content--admin">
      <AdminSidebar title="System alerts">
        <AdminSidebarAdminItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin page-content--large-bottom-margin sitemanager">
        <div className="table table--light system-alerts auto-height">
          {systemalerts
            ? (
              <>
                <Table
                  headers={headers}
                  data={systemalerts}
                  skipCellMeasure
                  cellOnClickColumns={['id', 'severity', 'status', 'category', 'cause', 'remedy', 'comment', 'closedBy']}
                  cellOnClick={(alert: SystemAlertObject) => {
                    setSelectedAlert(alert);
                    setOpenModal(1);
                  }}
                  autoheight
                />
              </>
            ) : (<Loading />)}
        </div>
      </div>
      {openModal === 1 && selectedAlert && (
        <SystemAlertModal
          setOpenModal={setOpenModal}
          selectedAlert={selectedAlert}
          updateAlerts={updateAlerts}
        />
      )}
    </div>
  );
}

export default SystemAlertsPage;
