/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

function ProgressBar(props) {
  const {
    size = 150,
    progress = 0,
    trackWidth = 8,
    trackColor = '#ddd',
    indicatorWidth = 8,
    indicatorColor = '#07c',
    indicatorCap = 'round',
    label = 'Loading...',
    labelColor = '#333',
    caller = '',
    spinnerMode = false,
    spinnerSpeed = 1,
    showPercentage = false,
  } = props;

  const center = size / 2;
  const radius = (center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth)) - 16;
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  const hideLabel = !!((size < 100 || !label.length || spinnerMode));

  return (
    <>
      <div
        className="svg-pi-wrapper"
        style={{ width: size, height: size }}
      >
        <svg
          transform="scale(0.7)"
          className="svg-pi"
          height={size}
          width={size}
          fill="none"
          viewBox={`0 0 ${size} ${size}`}
        >

          <defs>
            <linearGradient id="spinner-secondHalf">
              <stop offset="0%" stopOpacity="1" stopColor="#AAA" />
              <stop offset="100%" stopOpacity="1" stopColor="#AAA" />
            </linearGradient>
            <linearGradient id="spinner-firstHalf">
              <stop offset="0%" stopOpacity="1" stopColor="#AAA" />
              <stop offset="100%" stopOpacity="0" stopColor="#AAA" />
            </linearGradient>
          </defs>

          <g strokeWidth="5">
            <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
            <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />

            <path
              stroke="#AAA"
              strokeLinecap="round"
              d="M 4 100 A 96 96 0 0 1 4 98"
            />

            <animateTransform
              from="0 100 100"
              to="360 100 100"
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1600ms"
            />
          </g>

          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className="svg-pi-indicator"
            style={{ animationDuration: spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>

        {!hideLabel && (
          <div
            className="svg-pi-label"
            style={{ color: labelColor }}
          >
            <span className="svg-pi-label__loading">
              {label}
            </span>

            {showPercentage && caller === 'nodes' && (
              <span className="svg-pi-label__progress">
                {`${
                  progress > 100 ? 100 : progress
                }%`}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ProgressBar;
