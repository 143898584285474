import React, { useRef } from 'react';
import { List, ListRowProps } from 'react-virtualized';

import Checkbox from './Checkbox';
import { MultiSelectBoxProps } from '../../types/MultiSelectBox.d';

import { ReactComponent as SearchIcon } from '../../img/icons/search.svg';

const listWidth = 440;
const listHeight = 362;
const listRowHeight = 40;

export default function MultiSelectBox(
  props: MultiSelectBoxProps,
): JSX.Element {
  const {
    filteredData,
    data,
    selectedData = new Set(),
    height = listHeight,
    width = listWidth,
    className,
    selectAllMode,
    filterText,
    setFilterText,
    showSearchInput = true,
    onCheckboxClick,
    renderLeftSideData,
  } = props;

  const searchRef = useRef<HTMLInputElement>(null);

  const extendedData = !selectAllMode
    ? filteredData
    : [
      {
        id: 'select_all',
        name: 'Select all',
      } as {
        id: number | string;
        name: string;
      },
    ].concat(filteredData);

  const intrinsicHeight = extendedData.length * listRowHeight + 2;

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setFilterText) {
      setFilterText(e.target.value);
    }
  };

  const rowRendererData = ({ key, index, style }: ListRowProps) => {
    const itemId = extendedData[index].id;
    const itemName = extendedData[index].name;

    return (
      <div key={key} style={style} className="filter-list-row">
        <span className="filter-list-row__checkbox">
          <Checkbox
            checked={(itemId === 'select_all' && filteredData.every((d) => selectedData.has(d.name))) || selectedData.has(itemName)}
            onClick={(checked) => {
              const isFiltered = data.length !== filteredData.length;
              const filteredKeys = filteredData.map((f) => f.name);
              const isSelectAll = itemId === 'select_all';
              const newSet = new Set(selectedData);

              if (checked) { // checkbox new state is checked
                if (isSelectAll) { // "select all" checkbox
                  if (isFiltered) { // filtered, select only the filtered ones
                    onCheckboxClick(new Set([...Array.from(selectedData), ...filteredKeys]));
                  } else { // not filtered, select everything
                    onCheckboxClick(new Set(data.map((d) => d.name)));
                  }
                } else {
                  newSet.add(itemName);
                  onCheckboxClick(newSet);
                }
              } else if (!checked) { // checkbox new state is unchecked
                if (isSelectAll) { // "select all" checkbox
                  if (isFiltered) { // filtered unselect only all of the the filtered items
                    onCheckboxClick(new Set(Array.from(selectedData).filter((name) => !filteredKeys.includes(name))));
                  } else { // unselect all
                    onCheckboxClick(new Set());
                  }
                } else if (selectedData.size === 1) { // last checkbox unchecked
                  onCheckboxClick(new Set());
                } else {
                  newSet.delete(itemName);
                  onCheckboxClick(newSet);
                }
              }

              if (searchRef.current) {
                searchRef.current.focus();
              }
            }}
            dark={false}
          />
        </span>
        <span className="filter-list-row__name">
          {extendedData[index].name}
        </span>
        {renderLeftSideData && renderLeftSideData(extendedData[index])}
      </div>
    );
  };

  return (
    <div className={`multi-select-box ${className}`}>
      {showSearchInput && setFilterText && (
        <div className="search-container">
          <input
            ref={searchRef}
            className="search-input"
            value={filterText}
            onChange={handleChangeFilter}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setFilterText('');
              }
            }}
          />
          <SearchIcon className="search-icon" />
        </div>
      )}
      {extendedData.length
        && (
          <List
            width={width}
            height={intrinsicHeight < height ? intrinsicHeight : height}
            rowCount={extendedData.length}
            rowHeight={listRowHeight}
            rowRenderer={rowRendererData}
            data={extendedData}
          />
        )}
    </div>
  );
}
