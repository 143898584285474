/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import Loading from '../../../Common/Components/Loading';
import { SchedulePageListProps } from '../../../types/SchedulePageList';
import AssignedLightingGroupsModal from './AssignedLightingGroupsModal';
import { ScheduleObject } from '../../../types/ScheduleObject';

// icons
import { ReactComponent as FilterIcon } from '../../../img/icons/filter.svg';
import { ReactComponent as EditIcon } from '../../../img/icons/edit.svg';
import { ReactComponent as SchedulesHighlightIcon } from '../../../img/icons/schedule-highlight.svg';
import Tooltip from '../../../Common/Components/Tooltip';
import Utils from '../../../utils/Utils';

const maxItems = 10;
const rowHeight = 43;
const listWidth = 430;

function SchedulePageList(props: SchedulePageListProps): JSX.Element {
  const {
    data = [],
    groupNames,
    setSelectedItems,
    selectedItems,
    selectedDate,
    setOpenScheduleModal,
    setActiveSchedule,
    defaultGroupDefaultSchedule,
  } = props;
  const [openModal, setModalOpen] = useState(false);
  const [groupIndex, setGroupIndex] = useState(0);
  const computedHeight = (data?.length || 0) < maxItems
    ? rowHeight * (data?.length || 0)
    : rowHeight * maxItems;

  const isNonReadOnly = Utils.isNonReadOnly();

  const schedulePageListRenderer = ({ key, index, style }: ListRowProps) => (
    <div key={key} style={style}>
      <div className="schedule-list__element">
        <button
          type="button"
          className={`schedule-list__filter ${selectedItems.get(data[index].scheduleId) ? 'schedule-list__filter--active' : ''}`}
          onClick={() => {
            const newSelectedSchedules: Map<string, ScheduleObject> = new Map(Array.from(selectedItems));
            if (selectedItems.size > 0 && selectedItems.get(data[index].scheduleId)) {
              newSelectedSchedules.delete(data[index].scheduleId);
            } else {
              newSelectedSchedules.set(data[index].scheduleId, data[index]);
            }

            setSelectedItems(newSelectedSchedules);
          }}
        >
          <FilterIcon />
        </button>
        <div className="schedule-list__name-cell schedule-list__name-cell--schedule">
          <div className="schedule-list__name">
            {data[index].name}
            <div className="schedule-list__description">{data[index].description}</div>
          </div>
          {data[index].events && data[index].events.some((event) => (event?.date?.toString() === selectedDate)) && (
          <div className="schedule-list__special-date">
            <Tooltip text="Special date in effect">
              <SchedulesHighlightIcon className="schedule-list__special-date-icon" />
            </Tooltip>
          </div>
          )}
        </div>
        <button
          type="button"
          className="schedule-list__groups"
          onClick={() => {
            setModalOpen(true);
            setGroupIndex(index);
          }}
        >
          <span className="schedule-list__groups-value">
            {data[index].isDefault
              && defaultGroupDefaultSchedule
              && data[index].lightingGroups
              ? (
                // TODO - fix this
                data[index].lightingGroups?.length // + 1
              )
              : data[index].lightingGroups?.length}
          </span>
          <span className="schedule-list__groups-text">Groups</span>
        </button>
        {isNonReadOnly && (
        <div
          className="schedule-list__edit"
          onClick={() => {
            setActiveSchedule(data[index]);
            setOpenScheduleModal(true);
          }}
        >
          <EditIcon className="schedule-list__edit-icon" />
        </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {data && data.length ? (
        <List
          width={listWidth}
          height={computedHeight}
          rowHeight={rowHeight}
          rowCount={data && data.length}
          rowRenderer={schedulePageListRenderer}
          list={data}
        />
      ) : (<Loading />)}
      {openModal && data && (
      <AssignedLightingGroupsModal
        groupNames={groupNames}
        setModalOpen={setModalOpen}
        groups={data[groupIndex].lightingGroups || []}
        groupsLength={data[groupIndex].lightingGroups ? data[groupIndex].lightingGroups?.length : 0}
        name={data[groupIndex].name}
      />
      )}
    </>
  );
}

export default SchedulePageList;
