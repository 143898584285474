import React from 'react';
import { WizardDateAndColumnSelectorProps } from '../../../types/WizardDateAndColumnSelectorProps';
import WizardColumnSelector from '../../../Common/Components/WizardColumnSelector';
import WizardPeriodSelector from '../../../Common/Components/WizardPeriodSelector';

function WizardPeriodAndColumnSelector(props: WizardDateAndColumnSelectorProps): JSX.Element {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    granularity,
    setGranularity,
    selectedColumns,
    setSelectedColumns,
    mode,
    nodeGroup,
    allSelected,
    setAllSelected,
  } = props;

  return (
    <div className="modal-divided">
      <div className="modal-divided__sidebar">
        <WizardPeriodSelector
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          granularity={granularity}
          setGranularity={setGranularity}
        />
      </div>
      <div className="modal-divided__content">
        <WizardColumnSelector
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          mode={mode}
          nodeGroup={nodeGroup}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
        />
      </div>
    </div>
  );
}

export default WizardPeriodAndColumnSelector;
