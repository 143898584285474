import React from 'react';
import Modal from '../../../../../Common/Components/Modal';

import { CopyApiKeyProps } from '../../../../../types/CopyApiKeyProps';

function AdminSidebarCopyAPIKey(props: CopyApiKeyProps): JSX.Element {
  const {
    setOpenModal,
    copyApiKey,
  } = props;

  return (
    <Modal
      setModalOpen={() => setOpenModal(0)}
      title="Copy API Key"
      primaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="OK"
      className="admin-user-modal"
    >
      <div className="admin-user-modal-content">
        API key is &nbsp;
        {copyApiKey}
      </div>
    </Modal>
  );
}

export default AdminSidebarCopyAPIKey;
