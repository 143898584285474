import React from 'react';
import { SubtoolbarLinkElementPropsType } from '../../../types/SubtoolbarLinkElementPropsType';

function SubtoolbarLinkElement(props: SubtoolbarLinkElementPropsType): JSX.Element {
  const { subtitle, icon } = props;
  return (
    <div>
      { icon }
      <span>{ subtitle }</span>
    </div>
  );
}

export default SubtoolbarLinkElement;
