import React from 'react';
import Tooltip from '../../../../Common/Components/Tooltip';
import { SmallDataBoxProps } from '../../../../types/SmallDataBox';

function SmallDataBox(props: SmallDataBoxProps): JSX.Element {
  const { icon, items, width, hasToolTip } = props;

  const tooltipOffset = 0;
  const style = width !== undefined ? { width: `${width}px` } : {};

  const smallDataBoxItem = (title: string, text: string) => (
    <div key={title} className="small-data-box--item">
      <div className="small-data-box--title">{title}</div>
      <div className="small-data-box--text" style={style}>{text}</div>
    </div>
  );

  return (
    <div className="small-data-box">
      <div className="small-data-box--icon">{icon}</div>
      <div className="small-data-box--items">
        {items.map((item) => {
          if (hasToolTip && item.text !== '') {
            return (
              <Tooltip key={item.text} text={item.text} offset={tooltipOffset}>
                {smallDataBoxItem(item.title, item.text)}
              </Tooltip>
            );
          }
          return smallDataBoxItem(item.title, item.text);
        })}
      </div>
    </div>
  );
}

export default SmallDataBox;
