/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../img/icons/close.svg';
import { WizardModalType } from '../../types/WizardModal';
import Button from './Button';

function WizardModal(props: WizardModalType): JSX.Element {
  const {
    title,
    width,
    widthType,
    height,
    heightType,
    className = '',
    closeModal,
    navItems,
    submitButtonAction,
  } = props;

  const modalSize = {
    width: width ? `${width}${widthType || 'px'}` : 'auto',
    height: height ? `${height}${heightType || 'px'}` : 'auto',
  };

  const modalContentSize = {
    width: width ? `calc(${width}${widthType || 'px'} - 170px)` : 'auto',
  };

  const [activeStep, setActiveStep] = useState(1);
  const [lastVisitedStep, setLastVisitedStep] = useState(1);

  const navComponent = Array.from(navItems).map(([id, item]) => (
    <li
      className={`${item.className} ${activeStep === id ? 'active' : ''} ${lastVisitedStep === id ? 'last-visited' : ''}`}
      key={id}
      id={id.toString()}
      onClick={() => {
        const isActiveStepValid = navItems.get(activeStep)?.validatorFn({ displayError: true });
        const isActiveStepLast: boolean = activeStep === lastVisitedStep;
        if ((isActiveStepValid || isActiveStepLast) && id <= lastVisitedStep) {
          setActiveStep(id);
        }
      }}
    >
      <span>{item.label}</span>
      <div className="report-navigation-bar__label">{item.labelName}</div>
    </li>
  ));

  return (
    <>
      <div style={modalSize} className={`wizard-modal modal ${className}`} data-testid="wizard-modal-component">
        <button type="button" onClick={() => closeModal()} data-testid="modal-close" className="modal__close-button"><CloseIcon /></button>
        <div className="wizard-modal-divided">
          <div className="wizard-modal-divided__sidebar">
            <ul className="report-navigation-bar">
              {navComponent}
            </ul>
          </div>
          <div className="wizard-modal-divided__content" style={modalContentSize}>
            <div className="modal__title">
              {title && (
                <h2 className="modal__title-main">
                  {title}
                  {' '}
                  -
                  {' '}
                  <span className="modal__title-sub">{navItems.get(activeStep)?.labelName}</span>
                </h2>
              )}
            </div>
            {navItems.get(activeStep)?.content}
            <div className="modal__footer">
              <Button
                label="Cancel"
                buttonType="secondary"
                buttonSize="medium"
                onClick={() => closeModal()}
              />
              {activeStep !== 1 && (
              <Button
                onClick={() => {
                  const isActiveStepLast = activeStep === lastVisitedStep;
                  const isActiveStepValid = !!navItems.get(activeStep)?.validatorFn({ displayError: !isActiveStepLast });
                  if (isActiveStepValid || isActiveStepLast) {
                    setActiveStep(Math.max(activeStep - 1, 1));
                  }
                }}
                label="Back"
                buttonType="primary"
              />
              )}
              {(activeStep !== navItems.size) ? (
                <Button
                  onClick={() => {
                    const isActiveStepValid = !!navItems.get(activeStep)?.validatorFn({ displayError: true });
                    if (isActiveStepValid) {
                      const nextStep = Math.min(activeStep + 1, navItems.size);
                      setLastVisitedStep(Math.max(nextStep, lastVisitedStep));
                      setActiveStep(nextStep);
                    }
                  }}
                  label="Next"
                  buttonType="primary"
                />
              ) : (
                <Button
                  onClick={(event) => {
                    const isActiveStepValid = !!navItems.get(activeStep)?.validatorFn({ displayError: true });
                    if (isActiveStepValid) {
                      submitButtonAction(event);
                    }
                  }}
                  label="Run"
                  buttonType="primary"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal__backdrop" />
    </>
  );
}

export default WizardModal;
