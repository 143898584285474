import { useState } from 'react';
import { EnergyPageProps } from '../../types/EnergyPageProps';

export default function useEnergyPagePageState(): EnergyPageProps {
  const [activeToolbar, setActiveToolbar] = useState(0);
  const [activeToolbarBtn, setActiveToolbarBtnToolbar] = useState<number>(0);

  const handleActiveToolbar = (toolbarNum: number) => {
    setActiveToolbar(() => (toolbarNum === activeToolbar ? 0 : toolbarNum));
  };

  return {
    activeToolbar,
    activeToolbarBtn,
    setActiveToolbarBtnToolbar,
    handleActiveToolbar,
  };
}
