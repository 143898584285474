import React from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { DeleteProjectKeyProps } from '../../../../../types/DeleteProjectKeyProps';
import { useAppContext } from '../../../../../utils/AppContext';
import { postRequest } from '../../../../../utils/fetch';
import { ReactComponent as WarningIcon } from '../../../../../img/icons/warning.svg';

function DeleteKey(props: DeleteProjectKeyProps): JSX.Element {
  const {
    modalOpen,
    selectedSlotKey,
    selectedProject,
    updateProject,
  } = props;

  const { addNotification } = useAppContext();
  return (
    <Modal
      setModalOpen={modalOpen}
      className="admin-user-modal"
      title="Delete key"
      primaryButtonAction={async () => {
        if (selectedProject !== undefined) {
          try {
            const path = `/organizations/${selectedProject.orgId}/api-key/project/${selectedProject.projectId}/deactivate`;
            const resp = await postRequest(path, {
              slotNumber: selectedSlotKey,
              deactivateOperation: 'REVOKE',
            });
            if (resp.error) {
              addNotification({ type: 'error', message: 'Your "Delete key" operation has failed.' });
            } else {
              if (updateProject) {
                updateProject();
              }
              addNotification({
                type: 'success',
                message: `Your selected key under ${selectedProject.projectName} has been successfully deleted`,
              });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your Delete key operation has failed.' });
          }
          modalOpen(false);
        }
      }}
      primaryButtonLabel="Apply"
      secondaryButtonAction={() => modalOpen(false)}
      secondaryButtonLabel="Cancel"
      borderTopColor="yellow"
    >
      <div className="admin-user-modal-content">
        <WarningIcon className="admin-user-modal-content-icon" />
        Are you sure you want to delete the selected key?
      </div>
    </Modal>
  );
}

export default DeleteKey;
