import React from 'react';
import { ToolbarPropsType } from '../../types/ToolbarPropsType';

function Toolbar(props: ToolbarPropsType): JSX.Element {
  const { children } = props;

  return (
    <div className="toolbar">
      <div className="toolbar-tabs">
        <div className="toolbar-tabs__container">
          { children }
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
