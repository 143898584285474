import React, { useState } from 'react';
import Loading from '../../../Common/Components/Loading';
import Modal from '../../../Common/Components/Modal';
import { ClearSelectedAlarmsProps } from '../../../types/ClearSelectedAlarmsProps';
import { useAppContext } from '../../../utils/AppContext';
import { postRequest } from '../../../utils/fetch';

function ClearSelectedAlarms(props: ClearSelectedAlarmsProps): JSX.Element {
  const { setShowModal,
    selectedAlarms,
    setSelectedAlarms,
    selectedCustomer,
    selectedSite,
    updateAlerts } = props;

  const { addNotification } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const clearSelectedLimit = 1000;
  const idlist = Array.from(selectedAlarms.values()).filter((alarm) => alarm.status === 'Active').map((a) => a.id);
  const selectedAlarmsCount = idlist.length;
  if (selectedAlarmsCount > clearSelectedLimit) {
    idlist.length = clearSelectedLimit;
  }

  return (
    <div className="alarm-clear">
      <Modal
        title="Dismiss selected alarms"
        width="284"
        setModalOpen={() => setShowModal(0)}
        primaryButtonAction={async () => {
          setIsLoading(true);
          if (idlist.length > 0) {
            try {
              const path = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/dismiss`;
              const result = await postRequest(path, { alertIds: idlist });

              if (!result.error) {
                addNotification({ type: 'success', message: `${idlist.length} alarms successfully dismissed.` });
              } else {
                addNotification({ type: 'error', message: `Cannot dismiss the selected alarms. API reported error: ${result.error}` });
              }
              setShowModal(0);
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Dismiss alarms" operation has failed.' });
            }
            setIsLoading(false);
            setSelectedAlarms(new Map());
            setShowModal(0);
            updateAlerts();
          } else {
            addNotification({ type: 'error', message: 'No active alarms were selected.' });
            setIsLoading(false);
            setSelectedAlarms(new Map());
            setShowModal(0);
          }
        }}
        secondaryButtonAction={() => setShowModal(0)}
        primaryButtonLabel="Dismiss"
        secondaryButtonLabel="Cancel"
      >
        {isLoading
          ? <Loading isLoading />
          : (
            <div className="content">
              <div className="content-text">
                <>
                  {selectedAlarmsCount > clearSelectedLimit
                      && (
                      <div>
                        <span>
                          Only
                          &nbsp;
                          {clearSelectedLimit}
                          &nbsp;
                          of the
                          &nbsp;
                          {selectedAlarmsCount}
                          &nbsp;
                          selected active alarms can be dismissed at a time.
                          <br />
                          <br />
                        </span>
                      </div>
                      )}
                  <div>
                    <span>
                      Do you want to dismiss these
                      <br />
                      {Math.min(clearSelectedLimit, selectedAlarmsCount)}
                      <span> active alarms?</span>
                    </span>
                  </div>
                </>
              </div>
            </div>
          )}
      </Modal>
    </div>
  );
}

export default ClearSelectedAlarms;
