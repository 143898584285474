/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import { AutoCompleteProps } from '../../types/AutoCompleteBoxType';
import Textbox from './Textbox';

const listWidth = 350;
const listRowHeight = 41;
const searchBoxRowNumber = 5;

function AutoCompleteBox(props: AutoCompleteProps): JSX.Element {
  const {
    value,
    setValue,
    autoCompleteOptions,
    type = 'light',
    className = '',
    placeHolder,
    label,
    error,
    errorMessage,
    isRequired = false,
    id,
  } = props;

  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setFilteredOptions(autoCompleteOptions?.filter((item: string) =>
      item.toLowerCase().includes(value?.toLowerCase() || '')));
  }, [autoCompleteOptions, value]);

  const getPartnerList = ({ key, index, style }: ListRowProps) => (
    <div
      key={key}
      style={style}
      className="autocomplete__list-element"
      id={`${id}-${index}`}
      onClick={(e) => {
        if (filteredOptions) {
          setValue(filteredOptions[index]);
          setToggle(false);
        }
      }}
    >
      <span>{(filteredOptions && filteredOptions[index]) || ''}</span>
    </div>
  );

  const searchBoxType = type || '';
  const computedHeight = filteredOptions.length < searchBoxRowNumber
    ? (listRowHeight * filteredOptions.length)
    : (listRowHeight * searchBoxRowNumber);

  return (
    <div
      className={`autocomplete ${searchBoxType} ${className}`}
      id={id}
    >
      <Textbox
        type="text"
        placeholder={placeHolder}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
          setToggle(true);
        }}
        onBlur={(e: FocusEvent) => {
          if (!e.relatedTarget || (e.relatedTarget as HTMLElement).parentElement?.id !== id) {
            setToggle(false);
          }
        }}
        label={label}
        error={error}
        errorMessage={errorMessage}
        isRequired={isRequired}
      />
      { toggle && value !== ''
      && (
      <List
        width={listWidth}
        height={computedHeight}
        rowCount={filteredOptions.length}
        rowHeight={listRowHeight}
        rowRenderer={getPartnerList}
        data={filteredOptions}
        className="autocomplete__list"
        style={{
          position: 'absolute',
          top: '61px',
          zIndex: 200,
        }}
      />
      )}
    </div>
  );
}

export default AutoCompleteBox;
