import React, { useState } from 'react';
import DateRangePicker from '../../../Common/Components/DateRangePicker';
import DropDown from '../../../Common/Components/DropDown';
import { DatePeriodSelectorProps } from '../../../types/DatePeriodSelectorProps';
import { useAppContext } from '../../../utils/AppContext';

function DatePeriodSelector(props: DatePeriodSelectorProps): JSX.Element {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleActiveToolbar,
    maxDays,
  } = props;

  const { addNotification } = useAppContext();
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(endDate);
  const [daysDelta, setDaysDelta] = useState(0);

  return (
    <DropDown
      primaryButtonLabel="Cancel"
      primaryButtonType="primary"
      secondaryButtonType="secondary"
      secondaryButtonLabel="Submit"
      secondaryButtonAction={() => {
        if (Number.isNaN(daysDelta)) {
          addNotification({ type: 'error', message: 'Please select both start and end date.' });
          return;
        }
        if (daysDelta === 0) {
          addNotification({ type: 'error', message: 'Please select minimum 1 day difference between both the dates.' });
          return;
        }
        if (daysDelta > 31) {
          addNotification({ type: 'error', message: 'Please select maximum 31 day difference between both the dates.' });
          return;
        }
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
        handleActiveToolbar(0);
      }}
      primaryButtonAction={() => {
        handleActiveToolbar(0);
      }}
      className="dropdown-date-period-selector"
    >
      <DateRangePicker
        startDate={selectedStartDate}
        setStartDate={setSelectedStartDate}
        endDate={selectedEndDate}
        setEndDate={setSelectedEndDate}
        maxDaysRange={maxDays}
        setDaysDelta={setDaysDelta}
        type="dark"
      />
    </DropDown>
  );
}

export default DatePeriodSelector;
