/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { createRef, useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import Loading from './Loading';
import Textbox from './Textbox';
import { EnergyWizardSummaryProps } from '../../types/EnergyWizardSummaryProps';
import Utils from '../../utils/Utils';

function WizardSummary(props: EnergyWizardSummaryProps): JSX.Element {
  const {
    selectedGroup,
    groupFilterActiveTab,
    selectedSite,
    mode,
    reportName,
    setReportName,
    utilityReference,
    setUtilityReference,
    reportNameErrorActive,
    loading,
    recipients,
    selectedColumns,
    granularity,
    startDate,
    endDate,
    selectedNodes,
  } = props;

  const list = createRef<List>();

  const [data] = useState<string[]>(recipients.map((r) => r.email));
  const [formattedDate] = useState<string>(() => `${startDate?.toFormat('LLLL d, yyyy')} - ${endDate?.toFormat('LLLL d, yyyy')}`);

  const renderer = ({ key, index, style }: ListRowProps) => (
    <div key={key} style={style}>{data[index]}</div>);

  const getSelectedName = (selected: string) => {
    let selectedName;
    switch (selected) {
      case 'site':
        selectedName = selectedSite.name;
        break;
      case 'LIGHTING':
      case 'ORGANIZATIONAL':
        selectedName = selectedGroup?.name;
        break;
      case 'nodeid':
        selectedName = selectedNodes && selectedNodes[0].nodeid;
        break;
      case 'nodeList':
        selectedName = selectedNodes?.length.toString();
        break;
      default:
        selectedName = '';
    }
    return selectedName;
  };

  const listHeight = 100;
  const rowHeight = 20;

  return (
    (!loading ? (
      <div className="energy-summary">
        <div className="energy-summary__instruction">Report summary</div>
        <div className="energy-summary__divided-content">
          <div className="energy-summary__divided-content-left">
            <div className="energy-summary__table">
              <div className="energy-summary__table-label">{Utils.getChartTypeString(groupFilterActiveTab)}</div>
              <div className="energy-summary__table-field">{getSelectedName(groupFilterActiveTab)}</div>
              <div className="energy-summary__table-label">Report data by</div>
              <div className="energy-summary__table-field">{Utils.getModeString(mode)}</div>
              <div className="energy-summary__table-label">Time period</div>
              <div className="energy-summary__table-field">{formattedDate}</div>
              <div className="energy-summary__table-label">Data interval</div>
              <div className="energy-summary__table-field">{Utils.getGranularityOptionString(granularity)}</div>
              <div className="energy-summary__table-label">Recipients</div>
              <div className="energy-summary__table-field">
                <List
                  ref={list}
                  width={1}
                  height={listHeight}
                  rowHeight={rowHeight}
                  rowCount={data && data.length}
                  rowRenderer={renderer}
                  list={data}
                  containerStyle={{
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </div>
            </div>
            <div className="energy-summary__input">
              <Textbox
                onChange={(e) => {
                  setReportName(e.target.value);
                }}
                label="Report name"
                value={reportName}
                isRequired
                error={reportNameErrorActive}
                errorMessage="Report name is required."
                placeholder="Enter report name"
              />
            </div>
          </div>
          <div className="energy-summary__divided-content-right">
            <div className="energy-summary__table">
              <div className="energy-summary__table-label">Output columns</div>
              <div className="energy-summary__table-field">
                {selectedColumns.map((columnName) => (
                  <div key={columnName}>
                    <span className="margin-bottom-5">
                      {Utils.getColumnNameString(columnName)}
                    </span>
                    {'\n'}
                  </div>
                ))}
              </div>
            </div>
            <div className="energy-summary__input">
              <Textbox
                onChange={(e) => {
                  setUtilityReference(e.target.value);
                }}
                label="Utility billing reference"
                placeholder="Enter bill reference"
                value={utilityReference}
              />
            </div>
          </div>
        </div>
      </div>
    ) : <Loading />)
  );
}

export default WizardSummary;
