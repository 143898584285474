/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import { ReactComponent as ArrowIcon } from '../../img/icons/arrow.svg';
import {
  SelectBoxPropsType,
} from '../../types/SelectBoxPropsType';
import Loading from './Loading';

const maxItemsNumber = 4;
const listDefaultWidth = 195;
const itemsHeight = 40;
const itemsHeightWithBorder = 41;

function SelectBox(props: SelectBoxPropsType): JSX.Element {
  const {
    list,
    title,
    icon,
    onClick,
    type,
    label,
    selectedItemKey = '',
    maxItems = maxItemsNumber,
    listWidth = listDefaultWidth,
    isHorizontal,
    isRequired,
    errorMessage,
    error = false,
    selectboxWidth = listWidth,
    initialOpen,
    setIsClosedScheduleList = () => undefined,
    alwaysOpen = false,
    rowRenderer,
    disabled = false,
    displayArrow = true,
  } = props;

  const [toggle, setToggle] = useState(initialOpen || false);
  // eslint-disable-next-line no-nested-ternary
  const isActive = alwaysOpen ? 'open' : (toggle ? 'open' : 'hide');
  const selectBoxType = type || '';
  const computedHeight = (list?.length || 0) < maxItems
    ? itemsHeight * (list?.length || 0)
    : itemsHeight * maxItems;
  const horizontalClass = isHorizontal ? 'horizontal' : '';

  function listRowRenderer({ key, index, style }: ListRowProps) {
    if (!list) {
      return <></>;
    }

    return (
      <li
        style={style}
        data-testid={list[index].title}
        key={list[index].key || key}
        onClick={() => {
          onClick(list[index]);
          setToggle(false);
          setIsClosedScheduleList(toggle);
        }}
      >
        <div
          className={`${selectedItemKey === list[index].key ? 'selected' : ''}`}
        >
          {list[index].title}
        </div>
      </li>
    );
  }

  return (
    <div className={`selectbox ${selectBoxType} ${horizontalClass} ${error ? 'error' : ''} ${disabled ? 'disabled' : ''}`}>
      <div className="selectbox__label">
        {label}
        {isRequired && (<span className="selectbox__label-required">*</span>)}
      </div>
      {!alwaysOpen && (
      <button
        type="button"
        className={`selectbox__header selectbox__header--${isActive}`}
        onClick={() => { setIsClosedScheduleList(toggle); setToggle(!toggle); }}
        style={{ width: listWidth }}
        disabled={disabled}
      >
        {icon}
        <span>{title}</span>
        {displayArrow && <ArrowIcon />}
      </button>
      )}
      {error && errorMessage && (
        <span
          className="error-msg"
          style={{ width: listWidth }}
        >
          {errorMessage}
        </span>
      )}
      <ul
        className={`${isActive} ${selectBoxType}`}
        style={{
          maxHeight: `${maxItems * itemsHeightWithBorder}px`,
          width: listWidth,
        }}
      >
        {list ? (
          <List
            width={selectboxWidth}
            height={computedHeight}
            rowCount={list?.length || 0}
            rowHeight={itemsHeight}
            rowRenderer={rowRenderer || listRowRenderer}
            data={list}
          />
        )
          : (
            <div className="selectbox__loader">
              <Loading isLoading />
            </div>
          )}
      </ul>
    </div>
  );
}

export default SelectBox;
