/* eslint-disable no-nested-ternary */
import React, { createRef, useEffect, useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import Button from './Button';
import Textbox from './Textbox';
import { ReactComponent as MapIcon } from '../../img/icons/plus.svg';
import { ReactComponent as TrashCanIcon } from '../../img/icons/trash.svg';
import { AccountEcpdEditorProps } from '../../types/AccountEcpdEditorProps';
import Utils from '../../utils/Utils';

const listWidth = 200;
const listHeight = 240;
const listRowHeight = 45;

function AccountEcpdEditor(props: AccountEcpdEditorProps): JSX.Element {
  const {
    accountEcpds,
    setAccountEcpds,
  } = props;

  const accountEcpdsRegex = /^[a-zA-Z0-9]{0,20}$/;

  const [newAccountEcpd, setNewAccountEcpd] = useState<string>();
  const [newAccountEcpdError, setNewAccountEcpdError] = useState<boolean>(false);
  const [newAccountEcpdErrorMessage, setNewAccountEcpdErrorMessage] = useState<string>('');
  const [displayedAccountEcpd, setDisplayedAccountEcpd] = useState<{ accountEcpd: string, isFilled: boolean }[]>([]);

  const list = createRef<List>();
  const emptyAccountEcpdCount = 5;
  const isVerizonAdmin = Utils.isSensityAdmin();

  useEffect(() => {
    const emptyAccountEcpd = '';
    if (accountEcpds) {
      const accountEcpdsWithFilledFlag = accountEcpds?.map((acc) => ({ accountEcpd: acc, isFilled: true }));
      setDisplayedAccountEcpd(accountEcpdsWithFilledFlag
        .concat(
          new Array(Math.max((emptyAccountEcpdCount - accountEcpdsWithFilledFlag.length), 0))
            .fill({ accountEcpd: emptyAccountEcpd, isFilled: false }),
        ));
    }
  }, [accountEcpds]);

  const renderer = ({ key, index, style }: ListRowProps) => (
    <div
      className={`accountnumber-editor__list-element ${displayedAccountEcpd[index].isFilled ? 'accountnumber-list-element--filled' : 'accountnumber-list-element--empty'}`}
      key={key}
      style={style}
    >
      <Textbox
        disabled
        value={displayedAccountEcpd[index].accountEcpd}
      />
      {
        isVerizonAdmin && (
          <TrashCanIcon onClick={() => removeAccountEcpd(displayedAccountEcpd[index].accountEcpd)} />
        )
      }
      {
        !isVerizonAdmin && (
          <TrashCanIcon className="isDisabled" onClick={() => removeAccountEcpd(displayedAccountEcpd[index].accountEcpd)} />
        )
      }
    </div>
  );

  const addAccountEcpd = () => {
    if (accountEcpds && newAccountEcpd) {
      const maxEcpdLimit = 5;
      const maxEcpdError = (accountEcpds.length === maxEcpdLimit);
      const invalidEcpdError = newAccountEcpd.match(accountEcpdsRegex) === null;
      const uniqueEcpdError = accountEcpds.filter((acc) => acc === newAccountEcpd).length !== 0;
      setNewAccountEcpdError(uniqueEcpdError || invalidEcpdError);
      setNewAccountEcpdErrorMessage(
        invalidEcpdError
          ? 'Account ECPD may have upto a maximum of 20 alphanumeric characters.'
          : (uniqueEcpdError
            ? 'Account ECPD must be unique.'
            : ''),
      );

      if (!uniqueEcpdError && !invalidEcpdError) {
        if (accountEcpds.length < maxEcpdLimit) {
          setAccountEcpds([...accountEcpds, newAccountEcpd]);
          setNewAccountEcpd('');
        } else {
          setNewAccountEcpdError(maxEcpdError);
          setNewAccountEcpdErrorMessage(
            maxEcpdError
              ? 'Maximum of 5 Account ECPD\'s allowed'
              : '',
          );
        }
      }
    }
  };

  const removeAccountEcpd = (accountEcpdToDelete: string) => {
    if (accountEcpds) {
      setAccountEcpds(accountEcpds.filter((acc) => acc !== accountEcpdToDelete));
    }
  };

  return (
    <div className="accountnumber-editor">
      <div className="accountnumber-editor__header">
        <Textbox
          onChange={(e) => {
            setNewAccountEcpd(e.target.value);
          }}
          label="ECPD(s)"
          placeholder="Enter ECPD"
          disabled={!isVerizonAdmin}
          value={newAccountEcpd}
          error={newAccountEcpdError}
          errorMessage={newAccountEcpdErrorMessage}
        />
        {
        isVerizonAdmin && (
          <Button onClick={() => addAccountEcpd()} buttonType="primary" extraClasses="round-button"><MapIcon /></Button>
        )
      }
        {
        !isVerizonAdmin && (
          <Button onClick={() => addAccountEcpd()} buttonType="primary" extraClasses="round-button isDisabled"><MapIcon /></Button>
        )
      }
      </div>
      <div className="accountnumber-editor__list">
        <List
          ref={list}
          width={listWidth}
          height={listHeight}
          rowHeight={listRowHeight}
          rowCount={displayedAccountEcpd && displayedAccountEcpd.length}
          rowRenderer={renderer}
          list={displayedAccountEcpd}
          containerStyle={{
            width: '100%',
            maxWidth: '100%',
          }}
          style={{
            width: '100%',
          }}
        />
      </div>
      <span className="accountnumber-editor__datasize-text">
        {accountEcpds && accountEcpds?.length}
        {' '}
        {accountEcpds && accountEcpds.length > 1 ? 'account ECPD' : 'account ECPD'}
      </span>
    </div>
  );
}

export default AccountEcpdEditor;
