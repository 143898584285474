/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import Button from '../../../../Common/Components/Button';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import Loading from '../../../../Common/Components/Loading';
import ProjectsPageLayout from './Components/ProjectsPageLayout';
// import { sensitySystemsOrgId } from '../../../../utils/constants';
import { getRequest } from '../../../../utils/fetch';
import { CustomerObject } from '../../../../types/CustomerObject';

import { ProjectObject } from '../../../../types/ProjectObject';
import Utils from '../../../../utils/Utils';
import TableToolbar from '../Components/TableToolbar';
import TableToolbarSubtitle from '../Components/TableToolbarSubtitle';
import CreateProjectModal from './Components/CreateProjectModal';
import Textbox from '../../../../Common/Components/Textbox';

import { ReactComponent as SearchIcon } from '../../../../img/icons/search.svg';
import { UserObject } from '../../../../types/UserObject';

function ProjectAPIkeysPage(): JSX.Element {
  const [filteredOrderedProjects, setFilteredOrderedProjects] = useState<Array<ProjectObject>>();
  const { data: orderedProject, mutate: updateProjects } = useSWR<Array<ProjectObject>>(
    ['/api-key/active', 'GetProjects'],
    (url) => getRequest(url, {}, (data: Array<ProjectObject>): Array<ProjectObject> => {
      const tmpData = data ? [...data] : [];
      tmpData?.sort((a, b) => (a.projectName.toLowerCase() > b.projectName.toLowerCase() ? 1 : -1));
      setFilteredOrderedProjects(tmpData);
      return tmpData;
    }),
  );

  const [filteredProjects, setFilteredProjects] = useState<Map<string, ProjectObject> | undefined>(undefined);

  const [searchProject, setSearchProject] = useState('');
  const [toggleCreateProject, setToggleCreateProject] = useState(false);

  const isSensityAdmin = Utils.isSensityAdmin();
  const isPartnerUserAdmin = Utils.isPartnerUserAdmin();
  const isEndUserAdmin = Utils.isEndUserAdmin();
  // const isAdminUser = Utils.isAdminUser();
  const isSensityUserAdmin = Utils.isSensityUserAdmin();
  const { data: customerResp } = useSWR<Map<string, CustomerObject>>(['/organizations', 'ProjectsPage'], getRequest, { compare: () => false });
  const allCustomers = useMemo(() => {
    const tmpMap = new Map();
    customerResp?.forEach((customer) => {
      tmpMap.set(customer.orgid, customer);
    });
    return tmpMap;
  }, [customerResp]);
  const partnersList = useMemo(() => (allCustomers && allCustomers.size > 0 ? Array.from(allCustomers.values()).filter((customer: CustomerObject) => customer.type === 'PARTNER' || customer.type === 'ROOT').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : []), [allCustomers]);
  const customersList = useMemo(() => (allCustomers && allCustomers.size > 0 ? Array.from(allCustomers.values()).filter((customer: CustomerObject) => customer.type !== 'PARTNER').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : []), [allCustomers]);
  const partnersListForNonAdmin = useMemo(() => (allCustomers && allCustomers.size > 0 ? Array.from(allCustomers.values()).filter((customer: CustomerObject) => customer.type === 'PARTNER').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : []), [allCustomers]);
  const { data: usersResp } = useSWR<Map<string, UserObject>>(['/organizations/65a7341d-91c6-4286-90f7-2583b6b82f07/users', 'ProjectsPage'], getRequest, { compare: () => false });
  const allUsers = useMemo(() => {
    const tmpMap = new Map();
    usersResp?.forEach((user) => {
      tmpMap.set(user.userid, user);
    });
    return tmpMap;
  }, [usersResp]);
  useEffect(() => {
    if (orderedProject !== undefined) {
      const tmpMapGroup = new Map();
      orderedProject.forEach((project) => {
        tmpMapGroup.set(project.projectId, project);
      });

      setFilteredProjects(tmpMapGroup);
    }
  }, [orderedProject]);

  const [hasSearchResult, setHasSearchResult] = useState(false);

  useEffect(() => {
    setFilteredOrderedProjects(() => {
      const tmpArr: Array<ProjectObject> = [];
      orderedProject?.forEach((project) => {
        // eslint-disable-next-line max-len
        if ((project.projectName.toLowerCase().includes(searchProject.toLowerCase())) || (allCustomers.get(project.parentOrgId)?.name.toLowerCase().includes(searchProject.toLowerCase())) || (allCustomers.get(project.orgId)?.name.toLowerCase().includes(searchProject.toLowerCase()))) {
          tmpArr.push(project);
        } else {
          setHasSearchResult(true);
        }
      });
      /* allCustomers.forEach((customers, partnerId) => {
        if (!searchProject && customers.length === 0) {
          tmpMap.set(partnerId, []);
        }
        const partnerName = partners.get(partnerId) === undefined ? 'No partner' : partners.get(partnerId)?.name;
        if (customers.length === 0) {
          if (!searchProject || partnerName?.toLowerCase().includes(searchProject.toLowerCase())) {
            tmpMap.set(partnerId, []);
          } else {
            setHasSearchResult(true);
          }
        }
        customers.forEach((customer) => {
          const customerPartnerId = customer.po === sensitySystemsOrgId ? '' : customer.po || '';
          const tmpArr = tmpMap.get(customerPartnerId) || [];

          if (!searchProject || customer.name.toLowerCase().includes(searchProject.toLowerCase()) || partnerName?.toLowerCase().includes(searchProject.toLowerCase())) {
            tmpMap.set(customerPartnerId, [...tmpArr, customer]);
          } else {
            setHasSearchResult(true);
          }
        });
      }); */

      return tmpArr;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProject]);

  return (
    <>
      <div className="content content--admin">
        <AdminSidebar title="Account admin">
          <AdminSidebarAdminItems path={window.location.pathname} />
        </AdminSidebar>
        <div className="page-content page-content--medium-margin projects">
          <TableToolbar
            title="Project API Keys"
            subtitle={<TableToolbarSubtitle listLength={0} download={() => (0)} subtitle="Projects" />}
            addClass="accounts"
          >
            <div className="tabletoolbar__children">
              <Textbox
                value={searchProject}
                placeholder="Project Name, Account or Partner"
                onChange={(e) => setSearchProject(e.target.value)}
                icon={<SearchIcon />}
                small
              />
              {Utils.isSensityUserAdmin() && (
                <div className="tabletoolbar__children-btn">
                  <Button onClick={() => setToggleCreateProject(true)} label="Create new project" buttonType="secondary" />
                </div>
              )}
            </div>
          </TableToolbar>
          <div className="accounts__partners">
            {orderedProject
              ? (
                <>
                  <ProjectsPageLayout
                    projects={filteredOrderedProjects}
                    filteredProjects={filteredProjects}
                    updateProjects={updateProjects}
                    partnersList={(isSensityAdmin) ? partnersList : partnersListForNonAdmin || []}
                    customersList={customersList || []}
                    allCustomersList={allCustomers || []}
                    allUsersList={allUsers || []}
                  />
                </>
              ) : (<Loading isLoading={!hasSearchResult} />)}
          </div>
        </div>
        {toggleCreateProject && (
        <CreateProjectModal
          modalOpen={setToggleCreateProject}
          partnersList={(isSensityAdmin) ? partnersList : partnersListForNonAdmin || []}
          customersList={customersList || []}
          updateProjects={updateProjects}
          isSensityUserAdmin={isSensityUserAdmin}
          isPartnerUserAdmin={isPartnerUserAdmin}
          isEndUserAdmin={isEndUserAdmin}
        />
        )}
      </div>
    </>
  );
}

export default ProjectAPIkeysPage;
