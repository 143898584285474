/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { TableColumnFilterFieldProps } from '../../types/TableColumnFilterFieldProps';

function TableColumnFilterField(props: TableColumnFilterFieldProps): JSX.Element {
  const {
    onChange,
    value = '',
    label = '',
    icon = null,
    placeholder = '',
    autofocus = false,
    close = null,
    dark = false,
  } = props;
  const style: React.CSSProperties = icon ? { paddingLeft: '30px', paddingRight: '20px' } : {};

  return (
    <div className={`textbox table-textbox ${dark ? 'dark' : ''}`}>
      {label && <label htmlFor="textbox">{label}</label>}
      {icon}
      <input
        type="text"
        className={`textbox__input ${icon ? 'textbox__input--icon' : ''}`}
        autoFocus={autofocus}
        onChange={onChange}
        value={value}
        style={style}
        placeholder={placeholder}
      />
      {close}
    </div>
  );
}

export default TableColumnFilterField;
