/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';

import Utils from '../../../../../utils/Utils';
import { WizardNodeGroupedListProps } from '../../../../../types/AlertActivityReportWizard';
import { TableFiltering, TableSorting } from '../../../../../types/table';
import Checkbox from '../../../../../Common/Components/Checkbox';
import { NodeObject } from '../../../../../types/NodeObject';
import TableColumnFilterField from '../../../../../Common/Components/TableColumnFilterField';
import { ReactComponent as FilterIcon } from '../../../../../img/icons/filter.svg';
import { ReactComponent as SortIcon } from '../../../../../img/icons/sort.svg';
import { ReactComponent as CloseSmallIcon } from '../../../../../img/icons/close-small.svg';

function WizardNodeGroupedList(props: WizardNodeGroupedListProps): JSX.Element {
  const { viewBy, nodes, selectedItems, setSelectedItems, updateList } = props;

  const col = viewBy === 'group' ? 'fixtureType' : 'lightinggroup';
  const [sortedAndFilteredNodes, setSortedAndFilteredNodes] = useState<NodeObject[]>(nodes);
  const [filtering, setFiltering] = useState<TableFiltering>({});
  const [sorting, _setSorting] = useState<TableSorting>({ by: 'nodeid', dir: 'asc' });
  const setSorting = (column: string) => {
    _setSorting((oldSorting) => {
      const newSorting: TableSorting = { by: column, dir: 'asc' };

      if (oldSorting?.by === column) {
        newSorting.dir = Utils.getNextSortDir(oldSorting);
      }

      return newSorting;
    });
  };

  useEffect(() => {
    setSortedAndFilteredNodes(Utils.filterAndSortTable(filtering, sorting, nodes));
    updateList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering, sorting]);

  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    if (sortedAndFilteredNodes.length === 0) {
      return <div key={key} style={style}>&nbsp;</div>;
    }

    return (
      <div key={key} style={style}>
        <div className="expanded-node-list-item">
          <Checkbox
            dark={false}
            checked={selectedItems.has(sortedAndFilteredNodes[index].nodeid)}
            onClick={(checked) => {
              const newVal = new Map(selectedItems);

              if (checked) {
                newVal.set(sortedAndFilteredNodes[index].nodeid, sortedAndFilteredNodes[index]);
              } else {
                newVal.delete(sortedAndFilteredNodes[index].nodeid);
              }

              setSelectedItems(newVal);
              updateList();
            }}
          />
          <div className="expanded-node-list-item--name">{sortedAndFilteredNodes[index].nodeid}</div>
          <div className={`expanded-node-list-item--count ${sortedAndFilteredNodes.length > 5 ? 'has-scroll' : ''}`}>{viewBy === 'group' ? sortedAndFilteredNodes[index].fixtureType : sortedAndFilteredNodes[index].lightinggroup}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="expanded-table-list table__header">
        <div className="table__header-cell header-nodeid">
          {filtering.nodeid !== undefined ? (
            <TableColumnFilterField
              onChange={(event) => {
                setFiltering((oldVal) => ({ ...oldVal, nodeid: event?.target?.value || '' }));
              }}
              value={filtering.nodeid || ''}
              icon={<FilterIcon style={{ left: '10px' }} />}
              placeholder="Enter Node ID"
              autofocus
              dark={false}
              close={(
                <span
                  className="close"
                  style={{ right: '10px', top: 'calc(50% - 5px)' }}
                  onClick={() => {
                    setFiltering((oldVal) => {
                      const newVal = { ...oldVal };
                      delete newVal.nodeid;
                      return newVal;
                    });
                  }}
                >
                  <CloseSmallIcon />
                </span>
                  )}
            />
          ) : (
            <>
              <button
                type="button"
                className="filter"
                onClick={() => setFiltering((oldVal) => ({ ...oldVal, nodeid: '' }))}
              >
                <FilterIcon />
              </button>
              Node ID
              <button
                type="button"
                className={`sort ${sorting.by === 'nodeid' ? sorting.dir : ''}`}
                onClick={() => setSorting('nodeid')}
              >
                <SortIcon />
              </button>
            </>
          )}
        </div>
        <div className="table__header-cell header-group-fixture">
          {filtering[col] !== undefined ? (
            <TableColumnFilterField
              onChange={(event) => {
                setFiltering((oldVal) => ({ ...oldVal, [col]: event?.target?.value || '' }));
              }}
              value={filtering[col] || ''}
              icon={<FilterIcon style={{ left: '10px' }} />}
              placeholder={`Enter ${viewBy === 'group' ? 'Fixture type' : 'Group'}`}
              autofocus
              dark={false}
              close={(
                <span
                  className="close"
                  style={{ right: '10px', top: 'calc(50% - 5px)' }}
                  onClick={() => {
                    setFiltering((oldVal) => {
                      const newVal = { ...oldVal };
                      delete newVal[col];
                      return newVal;
                    });
                  }}
                >
                  <CloseSmallIcon />
                </span>
                  )}
            />
          ) : (
            <>
              <button
                type="button"
                className="filter"
                onClick={() => setFiltering((oldVal) => ({ ...oldVal, [col]: '' }))}
              >
                <FilterIcon />
              </button>
              {viewBy === 'group' ? 'Fixture type' : 'Group'}
              <button
                type="button"
                className={`sort ${sorting.by === col ? sorting.dir : ''}`}
                onClick={() => setSorting(col)}
              >
                <SortIcon />
              </button>
            </>
          )}
        </div>
      </div>
      <div className="expanded-table-list table__data">
        <AutoSizer disableWidth>
          {() => (
            <List
              // ref={groupedListRef}
              width={1}
              height={Math.min((sortedAndFilteredNodes.length * 40) + 30, 200)}
              rowHeight={40}
              rowCount={sortedAndFilteredNodes.length || 0}
              rowRenderer={rowRenderer}
              containerStyle={{
                width: '100%',
                maxWidth: '100%',
              }}
              style={{
                width: '100%',
              }}
              list={sortedAndFilteredNodes}
            />
          )}
        </AutoSizer>
      </div>
    </>
  );
}

export default WizardNodeGroupedList;
