import React from 'react';
import { SlideinListElementSmallPropTypes } from '../../../types/SlideinListElementSmallPropTypes';

function SlideinListElementSmall(
  props: SlideinListElementSmallPropTypes,
): JSX.Element {
  const {
    title,
    prefix,
    date,
    children,
    isFirstElement,
    border,
  } = props;

  const addClass = isFirstElement ? 'mg-top' : '';
  return (
    <div
      className={`list-element small ${border || ''} ${addClass}`}
    >
      <p className="list-element-title">{title}</p>
      {(date || prefix) && (
        <div className="list-element-footer">
          {prefix && <span>{prefix}</span>}
          {date}
        </div>
      )}
      {children}
    </div>
  );
}

export default SlideinListElementSmall;
