/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';

import Button from '../../Common/Components/Button';
import CustomizeList from '../../Common/Components/CustomizeList';
import DropDown from '../../Common/Components/DropDown';
import Table from '../../Common/Components/Table';
import Toolbar from '../../Common/Components/Toolbar';
import ToolbarHeading from '../../Common/Components/ToolbarHeading';
import ToolbarItem from '../../Common/Components/ToolbarItem';
import ToolbarLink from '../../Common/Components/ToolbarLink';
import Tooltip from '../../Common/Components/Tooltip';
import NoData from '../../Common/Components/NoData';
import { ReactComponent as CalendarIcon } from '../../img/icons/calendar.svg';
import { ReactComponent as CustomizeIcon } from '../../img/icons/customize-list.svg';
import { ReactComponent as DownloadIcon } from '../../img/icons/download.svg';
// Icons
import { ReactComponent as FilterLargeIcon } from '../../img/icons/filterlarge.svg';
import { ReactComponent as HourglassIcon } from '../../img/icons/hourglass.svg';
import { ReactComponent as MoreIcon } from '../../img/icons/more-horizontal.svg';
import { ReactComponent as AlarmIcon } from '../../img/icons/notification-filled.svg';
import { ReactComponent as PlaybookIcon } from '../../img/icons/playbook.svg';
import { ReactComponent as TooltipIcon } from '../../img/icons/tooltip-inverse.svg';
import { ReactComponent as TrashIcon } from '../../img/icons/trash.svg';
import { AlertNewObject, AlertObject } from '../../types/AlertObject';
import { GroupObject } from '../../types/GroupObject';
import { NodeObject } from '../../types/NodeObject';
import { PageComponentProps } from '../../types/PageComponentProps';
import { SelectBoxItemType } from '../../types/SelectBoxPropsType';
import { SiteObject } from '../../types/SiteObject';
import { getRequest } from '../../utils/fetch';
import { groupsNodesFn, nodeNamesFn, nodesFetcherFn } from '../../utils/ApiDataHelpers';
import { defaultHeaders } from '../../utils/getHeaderProps';
import useAlarmsPageState from '../../utils/state/useAlarmsPageState';
import { useAppContext } from '../../utils/AppContext';
import Utils from '../../utils/Utils';
import { nodesPageSize } from '../../utils/constants';
// import AlarmsFilter from './Components/AlarmsFilter';
import DatePeriodSelector from '../Energy/components/DatePeriodSelector';
import GroupsFilter from '../Lights/Components/GroupsFilter';
import AlarmPlaybook from './Components/AlarmPlaybook';
import AlarmsClearByType from './Components/AlarmsClearByType';
import AlarmsDuration from './Components/AlarmsDuration';
import ClearAlarm from './Components/ClearAlarm';
import ClearAlarmType from './Components/ClearAlarmType';
import ClearSelectedAlarms from './Components/ClearSelectedAlarms';

function AlarmsPage(props: PageComponentProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
  } = props;
  const {
    selectedAlarms,
    setSelectedAlarms,
    headers,
    setHeaders,
    activeToolbar,
    handleActiveToolbar,
  } = useAlarmsPageState();

  const history = useHistory();
  const isNonReadOnly = Utils.isNonReadOnly();

  // daterange
  const maxSelectableDuration = 90;
  const initDate = () => {
    const end = DateTime.fromMillis(Date.now());
    const start = end.minus({ days: 7 });
    return {
      start,
      end,
    };
  };
  const [startDate, setStartDate] = useState(initDate().start);
  const [endDate, setEndDate] = useState(initDate().end);

  // toggle "Active" and "All" alarms
  const [active, setActive] = useState(true);

  // selected alarm for clearing and playbook
  const [selectedAlarm, setSelectedAlarm] = useState<AlertObject>();

  // control the modals
  const modalPlaybook = 1;
  const modalClearAlarm = 2;
  const modalDropdownClearByType = 3;
  const modalClearByType = 4;
  const modalClearSelected = 5;
  const modalCustomizeList = 6;

  const toolbarDaterange = 1;
  const toolbarDuration = 2;
  const toolbarFiltergroup = 3;

  const hoursInDay = 24;
  const minsInHour = 60;
  const secsInMin = 60;
  const msInSec = 1000;

  const alarmsLimit = 10000;
  const { addNotification } = useAppContext();

  const fixColCount = 2;

  // check whether alarm duration matches user settings
  const now = (new Date()).getTime();
  const durationMatches = (durationSettings:Record<string, string>, alarm:AlertObject) => {
    let start;
    let end;
    if (alarm.raisedAtFmtTableSort) {
      start = alarm.raisedAtFmtTableSort;
    } else {
      // eslint-disable-next-line no-multi-assign
      alarm.raisedAtFmtTableSort = start = (new Date(alarm.raisedAt)).getTime();
    }
    if (alarm.clearedAt) {
      if (alarm.clearedAtFmtTableSort) {
        end = alarm.clearedAtFmtTableSort;
      } else {
        // eslint-disable-next-line no-multi-assign
        alarm.clearedAtFmtTableSort = end = (new Date(alarm.clearedAt)).getTime();
      }
    } else {
      end = now;
    }
    const duration = end - start;
    const threshold = (parseInt(durationSettings.days, 10) * hoursInDay + parseInt(durationSettings.hours, 10)) * (minsInHour * secsInMin * msInSec);
    return (durationSettings.compare === 'More than'
      ? duration > threshold
      : duration < threshold);
  };

  // check if alarm is contained in selected lighting groups and/or org groups

  const groupsMatch = (groups:Record<string, Array<string>>, alarm:AlertObject) => {
    const match = groups.filteredLightingGroups.includes(alarm.lightinggroup || '')
      || (alarm.orggroups
        && alarm.orggroups.length > 0
        // check if the intersection of the alarm's orggroups array and the selected orggroups array has any elements
        && (alarm.orggroups.split(', ').filter((value) => groups.filteredOrgGroups.includes(value)).length > 0));
    return match;
  };

  // render the Actions column (tooltip, playbook, clear)
  const actionsCell = (alarm: AlertObject) => {
    const { details } = alarm;
    let description = '';

    if (details) {
      try {
        description = JSON.parse(details as string).message;
      } catch (e) {
        description = (details as Record<string, string>).message;
      }
    }

    return (
      <div className="alarm-actions">
        <Tooltip
          text={description || ''}
          position="top"
          offset={-2}
        >
          <TooltipIcon />
        </Tooltip>
        <Tooltip
          text="Playbook"
          position="top"
          offset={-2}
        >
          <Button
            onClick={() => {
              setSelectedAlarm(alarm);
              setShowModal(modalPlaybook);
            }}
          >
            <PlaybookIcon />
          </Button>
        </Tooltip>
        <Tooltip
          text="Dismiss alarm"
          position="top"
          offset={-2}
        >
          <Button
            onClick={() => {
              setSelectedAlarm(alarm);
              setShowModal(modalClearAlarm);
            }}
          >
            <TrashIcon />
          </Button>
        </Tooltip>
      </div>
    );
  };

  // render the Severity column, including colored icon
  // eslint-disable-next-line react/prop-types
  const severityCell = (severity: string) =>
    (
      <div>
        <AlarmIcon className={`alarm-icon alarm-icon__${severity}`} />
        <span>{severity}</span>
      </div>
    );

  // Site data

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(() => `/organizations/${selectedCustomer.id}/sites`);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const site = useMemo(() => sitesResp?.find(
    (item) => item.siteid === selectedSite.id,
  ), [sitesResp, selectedSite.id]);

  useEffect(() => {
    setFilteredAlarms(undefined);
  }, [startDate, endDate, active]);

  // Alarms

  const [alertsUpdateTrigger, setAlertsUpdateTrigger] = useState(1);
  const [alertsResp, setAlertsResp] = useState<AlertObject[] | undefined>(undefined);

  useEffect(() => {
    if (alertsResp?.length === alarmsLimit) {
      addNotification({
        type: 'info',
        message: `No more than ${alarmsLimit} alarms can be retrieved at a time.  Modify the date range to see others.`,
      });
    }
  }, [addNotification, alertsResp]);

  useEffect(() => {
    if (!site) {
      return;
    }
    const { offset } = DateTime.fromObject({ zone: site?.time_zone || 'utc' });
    const startUTC = startDate.setZone('utc').startOf('day').minus({ minutes: offset });
    const endUTC = endDate.setZone('utc').endOf('day').minus({ minutes: offset });
    if (active && alertsUpdateTrigger > 0) {
      getRequest(
        `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts?raisedAfter=${startUTC}&raisedBefore=${endUTC}&activeOnly=true&withDisplay=true&size=${nodesPageSize}&uilimit=${alarmsLimit}`,
      ).then((response) => {
        setAlertsResp(response);
      });
    } else {
      getRequest(
        `/alerts?siteId=${selectedSite.id}&raisedAfter=${startUTC}&raisedBefore=${endUTC}&withDisplay=true&size=${nodesPageSize}&uilimit=${alarmsLimit}`,
      ).then((response) => {
        setAlertsResp(response);
      });
    }
  }, [active, startDate, endDate, alertsUpdateTrigger, selectedCustomer, selectedSite, site]);

  const updateAlerts = (() => {
    setAlertsUpdateTrigger(alertsUpdateTrigger + 1);
  });

  // Nodes (for node names)

  const { data: nodesResp } = useSWR<Array<NodeObject>>(() => `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/lkp/nodes?size=${nodesPageSize}`, nodesFetcherFn);
  const nodeNames = useMemo(() => nodeNamesFn(nodesResp || []), [nodesResp]);

  // Groups

  const { data: groupsResp } = useSWR<Array<GroupObject>>(() => `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups`);

  const {
    groupsNodes = {},
    lightingGroups = [],
    orgGroups = [],
  } = useMemo(() => groupsNodesFn(groupsResp, nodesResp), [groupsResp, nodesResp]);

  const groupsFilterObject: Record<string, Array<string>> = {
    filteredLightingGroups: [],
    filteredOrgGroups: [],
  };

  const [groupFilterActiveTab, setGroupFilterActiveTab] = useState(0);
  const [applyGroupFilterObject, setApplyGroupFilter] = useState(groupsFilterObject);
  const [filterGroupsSubtitle, setFilterGroupsSubtitle] = useState(
    applyGroupFilterObject.filteredLightingGroups
      .concat(applyGroupFilterObject.filteredOrgGroups).length,
  );

  const [showModal, setShowModal] = useState(0);

  const [selectedClearType, setSelectedClearType] = useState<string>('');

  const [filteredAlarms, setFilteredAlarms] = useState<AlertObject[]>();

  // alarm type and duration
  const defaultAlarmsDurationSettings: Record<string, string> = {
    alarmType: 'All',
    compare: 'Any',
    days: '7',
    hours: '0',
  };

  const [alarmsCount, setAlarmsCount] = useState<number>(0);

  const [alarmsDurationSettings, setApplyAlarmsDuration] = useState(defaultAlarmsDurationSettings);
  const alarmTypeItems: SelectBoxItemType[] = useMemo(() => {
    const tmpArr: SelectBoxItemType[] = [];
    const alertNames = new Set<string>();

    alertsResp?.forEach((alert) => {
      if (alert.name) alertNames.add(alert.name);
    });

    let index = 1;
    alertNames.forEach((name) => {
      tmpArr.push({ key: index.toString(), title: name });
      index += 1;
    });

    return tmpArr;
  }, [alertsResp]);

  const clearableAlarms: SelectBoxItemType[] = useMemo(() => {
    const doNotListAlarms = ['ThreadFailure', 'NodeLocationChangedFailure', 'WarmStartFailure', 'FirmwareUpgradeFailure', 'TelemetryLoggingError', 'AppRestoreError'];
    const tmpArr: SelectBoxItemType[] = [];
    const alertNames = new Set<string>();

    const filteredAlarmsByType = alertsResp?.filter((a) => !doNotListAlarms.includes(a.type));

    filteredAlarmsByType?.forEach((alert) => {
      if (alert.name) alertNames.add(alert.name);
    });

    let index = 1;
    alertNames.forEach((name) => {
      tmpArr.push({ key: index.toString(), title: name });
      index += 1;
    });

    return tmpArr;
  }, [alertsResp]);

  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);
  const [filteredCount, setFilteredCount] = useState(0);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const downloadAlarms = () => {
    const csvHeaders = headers.filter((header) => header.key !== 'nodeid');
    Utils.downloadCSV([{ key: 'nodeid', val: 'NodeID' }, { key: 'severity', val: 'Severity' }, ...csvHeaders].filter((header) => !['actions', 'nodeidFmt', 'severityFmt', 'rowSelectCheckbox'].includes(header.key)), filteredTableData || [], 'alarms');
  };

  useEffect(() => {
    if (
      Array.isArray(alertsResp)
      && Array.isArray(nodesResp)
      && Array.isArray(groupsResp)
      && (
        alertsResp.length === 0
        || nodesResp.length === 0
        || groupsResp.length === 0
      )
    ) {
      setFilteredAlarms([]);
      forceUpdate();
    } else if (
      Array.isArray(alertsResp)
      && alertsResp.length > 0
      && Object.keys(groupsNodes).length > 0
    ) {
      // eslint-disable-next-line no-console
      const alarms = alertsResp.filter((alarm) =>
        ((typeof groupsNodes[alarm.originatorId] !== 'undefined')
        && (alarmsDurationSettings.alarmType === 'All' || alarmsDurationSettings.alarmType === alarm.name)
        && (alarmsDurationSettings.compare === 'Any' || durationMatches(alarmsDurationSettings, alarm))
        && (filterGroupsSubtitle === 0 || groupsMatch(applyGroupFilterObject, alarm))
        )).map((a) => {
        a.name = a.name || a.type;
        // eslint-disable-next-line no-nested-ternary
        a.status = a.clearedAt ? 'Cleared' : a.dismissedAt ? 'Dismissed' : 'Active';
        a.actions = a.actions || actionsCell(a);
        a.nodename = a.nodename || nodeNames.get(a.originatorId) || '';
        a.severityFmt = a.severityFmt || severityCell(a.severity);
        a.severityFmtTableSort = ['Minor', 'Major', 'Critical'].indexOf(a.severity);
        a.lightinggroup = a.lightinggroup || groupsNodes[a.originatorId].lightinggroup.name;
        a.orggroups = a.orggroups || groupsNodes[a.originatorId].orggroups.map((g) => g.name).join(', ');
        a.raisedAtFmtTableSort = a.raisedAtFmtTableSort || new Date(a.raisedAt).getTime();
        a.raisedAtFmt = a.raisedAtFmt || Utils.convertISOtoAlarmTime2(a.raisedAt, site ? site.time_zone : 'utc');
        a.clearedAtFmtTableSort = a.clearedAt ? new Date(a.clearedAt).getTime() : 0;
        a.dismissedAtFmt = a.dismissedAtFmt || Utils.convertISOtoAlarmTime2((a.dismissedAt ? a.dismissedAt : ''), site ? site.time_zone : 'utc');
        a.clearedAtFmt = a.clearedAtFmt || Utils.convertISOtoAlarmTime2((a.clearedAt ? a.clearedAt : ''), site ? site.time_zone : 'utc');
        return a;
      });

      const filteredGroupsLength = applyGroupFilterObject.filteredLightingGroups
        .concat(applyGroupFilterObject.filteredOrgGroups).length;
      setFilterGroupsSubtitle(filteredGroupsLength);

      // don't count alarms for nodes that are no longer on this site (not in any groups)
      setAlarmsCount(alertsResp.filter((alarm) => typeof groupsNodes[alarm.originatorId] !== 'undefined').length);
      setFilteredAlarms(alarms);
      setFilteredCount(alarms.length);
      forceUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alarmsDurationSettings,
    applyGroupFilterObject,
    filterGroupsSubtitle,
    alertsResp,
    nodesResp,
    groupsResp,
    // groupsNodes,
    // nodeNames,
    // site,
  ]);

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'Alarms');

  return (
    <div className="alarms">
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <div className="toolbar-tabs__container-tabset-group">
            <ToolbarHeading title="Alarms" subtitle={selectedSite.name} />
            {Utils.isVerizonUser()
              && (
                <>
                  <ToolbarItem
                    addClass="toolbar-alarms-active"
                    onclick={() => { if (!active) { setActive(true); updateAlerts(); } }}
                    content={<span>Active</span>}
                    tabPanelisActive={active ? 1 : 0}
                    order={1}
                  />
                  <ToolbarItem
                    addClass="toolbar-alarms-all"
                    onclick={() => { if (active) { setActive(false); updateAlerts(); } }}
                    content={<span>All</span>}
                    tabPanelisActive={active ? 1 : 0}
                    order={0}
                  />
                </>
              )}
            <ToolbarItem
              icon={<CalendarIcon />}
              fields={[
                { name: 'Start', value: startDate.toFormat('LLLL d, yyyy') },
                { name: 'End', value: endDate.toFormat('LLLL d, yyyy') },
              ]}
              onclick={() => handleActiveToolbar(toolbarDaterange)}
              tabPanelisActive={activeToolbar}
              order={toolbarDaterange}
              addClass="toolbaritem-alarms-daterange"
            >
              <DatePeriodSelector
                startDate={startDate.toJSDate()}
                endDate={endDate.toJSDate()}
                setStartDate={(dt) => {
                  setStartDate(DateTime.fromJSDate(dt as Date));
                }}
                setEndDate={(dt) => setEndDate(DateTime.fromJSDate(dt as Date))}
                maxDays={maxSelectableDuration}
                handleActiveToolbar={handleActiveToolbar}
              />
            </ToolbarItem>
            <ToolbarItem
              addClass="toolbaritem-alarms-duration last"
              icon={<HourglassIcon />}
              onclick={() => handleActiveToolbar(toolbarDuration)}
              order={toolbarDuration}
              tabPanelisActive={activeToolbar}
              fields={
                [{ name: 'Alarm name',
                  value: `${alarmsDurationSettings.alarmType}` },
                { name: 'Duration',
                  value: alarmsDurationSettings.compare === 'Any'
                    ? 'Any'
                    : `${alarmsDurationSettings.compare} ${alarmsDurationSettings.days} days, ${alarmsDurationSettings.hours} hours` },
                ]
                }
            >
              <AlarmsDuration
                className="dropdown-alarms-duration"
                alarmsDurationSettings={alarmsDurationSettings}
                setApplyAlarmsDuration={setApplyAlarmsDuration}
                handleActiveToolbar={handleActiveToolbar}
                alarmTypeItems={[{ key: '0', title: 'All' }, ...alarmTypeItems]}
              />
            </ToolbarItem>
          </div>
          <ToolbarLink
            addClass="toolbaritem-alarms-filtergroups"
            icon={<FilterLargeIcon />}
            title="Filter groups"
            subtitleStyle="light"
            subtitle="All groups"
            subtitleNum={filterGroupsSubtitle}
            subtitleText="group"
            onclick={() => handleActiveToolbar(toolbarFiltergroup)}
            tabPanelisActive={activeToolbar}
            order={toolbarFiltergroup}
          >
            <GroupsFilter
              className="dropdown-groups-filter"
              lightingGroups={lightingGroups}
              orgGroups={orgGroups}
              setApplyGroupFilter={setApplyGroupFilter}
              filteredLightingGroups={applyGroupFilterObject.filteredLightingGroups}
              filteredOrgGroups={applyGroupFilterObject.filteredOrgGroups}
              handleActiveToolbar={handleActiveToolbar}
              setGroupFilterActiveTab={setGroupFilterActiveTab}
              groupFilterActiveTab={groupFilterActiveTab}
            />
          </ToolbarLink>
        </div>
        <button type="button" className={`commissioning__info--actions-btn commissioning__info--actions-list ${activeToolbar === 4 ? 'selected' : ''}`} onClick={() => handleActiveToolbar(4)}>
          <MoreIcon />
        </button>
        {activeToolbar === 4 ? (
          <div className="actions-alarms">
            <DropDown>
              <>
                <button type="button" onClick={() => { setShowModal(modalCustomizeList); handleActiveToolbar(0); }} className="actions-alarms__element">
                  <CustomizeIcon />
                  Customize list
                </button>
                <button type="button" onClick={() => { downloadAlarms(); handleActiveToolbar(0); }} className="actions-alarms__element">
                  <DownloadIcon />
                  Download list
                </button>
              </>
            </DropDown>
          </div>
        ) : <></>}
      </Toolbar>
      <div className="alarms-subheading-container">
        <div className="alarms-subheading">
          <span className="alarms-subheading__counts">
            <span>Total for time period</span>
            <span className="alarms-subheading__count">
              {alarmsCount}
            </span>
            <>
              (
              <span className="alarms-subheading__count">
                {filteredCount.toLocaleString()}
              </span>
              <span>after applying filters)</span>
            </>
            <span> Selected</span>
            <span className="alarms-subheading__count">{selectedAlarms?.size}</span>
          </span>
        </div>

        {(isNonReadOnly && selectedAlarms?.size > 0)
          && (
          <div className="alarms-clearbytype-link">
            <TrashIcon />
            <Button
              label="Dismiss selected alarms"
              onClick={(() => {
                handleActiveToolbar(0);
                setShowModal(showModal === modalClearSelected ? 0 : modalClearSelected);
              })}
            />
          </div>
          )}
        {isNonReadOnly && (
          <div className="alarms-clearbytype-link">
            <TrashIcon />
            <Button
              label="Dismiss alarms by type"
              onClick={(() => {
                handleActiveToolbar(0);
                setShowModal(showModal === modalDropdownClearByType ? 0 : modalDropdownClearByType);
              })}
            />
          </div>
        )}
        {showModal === modalDropdownClearByType && clearableAlarms.length > 0 && (
          <AlarmsClearByType
            className="dropdown-alarms-clearbytype"
            alarmTypeItems={clearableAlarms}
            handleActiveToolbar={() => null}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            setSelectedClearType={setSelectedClearType}
            setShowModal={setShowModal}
          />
        )}
      </div>
      <div className="alarms-container">
        {(filteredAlarms && filteredAlarms.length === 0)
          ? <NoData />
          : (
            <div className="table table--light">
              <Table
                headers={headers}
                data={filteredAlarms}
                fixColCount={fixColCount}
                setFilteredCount={setFilteredCount}
                setFilteredTableData={setFilteredTableData}
                selectedItems={selectedAlarms}
                setSelectedItems={setSelectedAlarms}
                skipCellMeasure
                cellOnClickColumns={['nodeid']}
                cellOnClick={(e) => history.push('/lights', { selectedNodes: [e.nodeid] })}
              />
            </div>
          )}
      </div>
      {showModal === modalPlaybook && selectedAlarm && (
        <AlarmPlaybook
          alarm={selectedAlarm as AlertNewObject}
          setShowModal={setShowModal}
        />
      )}
      {showModal === modalClearAlarm && selectedAlarm && (
        <ClearAlarm
          alarm={selectedAlarm}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          setShowModal={setShowModal}
        />
      )}
      {showModal === modalClearByType && (
        <ClearAlarmType
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          selectedClearType={selectedClearType || alarmTypeItems[0].title}
          alarms={filteredAlarms || []}
          setShowModal={setShowModal}
          updateAlerts={() => updateAlerts()}
        />
      ) }
      {showModal === modalClearSelected && (
        <ClearSelectedAlarms
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          selectedAlarms={selectedAlarms}
          setSelectedAlarms={setSelectedAlarms}
          setShowModal={setShowModal}
          updateAlerts={() => updateAlerts()}
        />
      ) }
      {showModal === modalCustomizeList && (
        <CustomizeList
          setOpenModal={setShowModal}
          headerList={headers}
          setHeaders={setHeaders}
          defaultHeaders={defaultHeaders.Alarms}
        />
      )}
    </div>
  );
}

export default AlarmsPage;
