/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../Common/Components/Button';

import RebootModal from '../../../Common/Components/RebootModal';
import SlideIn from '../../../Common/Components/SlideIn';
import { ReactComponent as FairIcon } from '../../../img/icons/fair.svg';
import { ReactComponent as HighGoodIcon } from '../../../img/icons/high-good.svg';
import { ReactComponent as PoorIcon } from '../../../img/icons/low-poor.svg';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { GenericSensorData, StaticSensorValue } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { ConnectionStatusProps, SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import SlideinListElementSmall from '../Components/SlideinListElementSmall';
import LightsNetworkSessionHistory from './Components/LightsNetworkSessionHistory';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';
import { getRequest, postRequest } from '../../../utils/fetch';
import { useAppContext } from '../../../utils/AppContext';
import { TSData } from '../../../types/TSData';

function LightsNetwork(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite, fetchedBulkSensorData } = props;
  const { addNotification } = useAppContext();
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const [expand, setExpand] = useState(false);
  const [openSessionHistory, setOpenSessionHistory] = useState(0);
  const [openRebootModal, setOpenRebootModal] = useState(0);

  const isVerizonUser = Utils.isVerizonUser();
  const isSensityUserAdmin = Utils.isSensityUserAdmin();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatusProps>({} as ConnectionStatusProps);
  const [refreshLoadingIsConnected, setRefreshLoadingIsConnected] = useState(false);
  const [latestSensorTsFromDWH, setLatestSensorTsFromDWH] = useState(new Date(0).getTime());
  const [onDemandReadTriggered, setOnDemandReadTriggered] = useState(false);
  const [newSensorDataAvailable, setNewSensorDataAvailable] = useState(false);
  const history = useHistory();

  const timeList = [{ title: '0:00 AM', key: '1' }];
  const startFromTime = timeList[0].title.split(':');
  let convertedStartTime = parseInt(startFromTime[0], 10);

  const everySecond = 2;
  const fullDay = 24;
  const fullDayHalfHours = 48;

  for (let i = 2; i < fullDayHalfHours; i += 1) {
    if (i % everySecond === 0) {
      convertedStartTime += 1;
      startFromTime[1] = '00';
    } else {
      startFromTime[1] = '30';
    }

    if (i < fullDay) {
      timeList.push({ title: `${convertedStartTime}:${startFromTime[1]} AM`, key: i.toString() });
    } else {
      timeList.push({ title: `${convertedStartTime}:${startFromTime[1]} PM`, key: i.toString() });
    }
  }

  const [tsData, setTsData] = useState<TSData[]>([]);
  const displayedSensors = ['linkQuality', 'sinr', 'radioSignalStrength', 'modemBootCount'];
  const timestamp = useRef<number>(0);
  const [sensorData, setSensorData] = useState<(GenericSensorData)>({ nodeid: selectedNode.nodeid } as GenericSensorData);
  const [refreshLoadingSensors, setRefreshLoadingSensors] = useState(false);
  const dateConvertFn = (val) => Utils.getConvertedDate(val, undefined, selectedSite.timezone);

  const commissioningFields = {
    tsNetStat: { value: '--' },
    activationDate: { value: '--', conversion: dateConvertFn },
    commissionedDate: { value: '--', conversion: (val) => val || '--' },
    ligLastReported: { value: '--', conversion: dateConvertFn },
    lastRegistration: { value: '--', conversion: dateConvertFn },
    firstInNetsense: { value: '--', conversion: dateConvertFn },
  } as {[index: string]: StaticSensorValue};

  const getTsData = () => {
    try {
      postRequest<{ nodeIds: string[] }, TSData[]>(
        `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/device_information`,
        { nodeIds: [selectedNode.nodeid] },
      )
        .then((resp) => {
          if (resp.error) {
            throw Error(resp.error);
          }
          setTsData(resp.data);
        }).catch((error) => {
          addNotification({ type: 'warning', message: `Could not fetch external device information: '${error.message || error}'` });
        });
    } catch (error) {
      addNotification({ type: 'warning', message: `Could not fetch external device information: '${error.message || error}'` });
    }
  };

  useEffect(() => {
    if (selectedSite.id && selectedNode.nodeid) {
      getRequest(`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/${selectedNode.nodeid}/connection_status`)
        .then((responses) => {
          setConnectionStatus(responses);
          setRefreshLoadingIsConnected(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode.nodeid, refreshLoadingIsConnected]);

  useEffect(() => {
    Promise.resolve(fetchedBulkSensorData).then((response) => {
      const newData = Sensors.getSensorTableDataWithValueAndSensor(
        selectedItems,
        displayedSensors,
        response,
        selectedSite.timezone,
        undefined,
        commissioningFields,
      );
      let latestSensorTs = new Date(0).getTime();
      if (response.sensorHistories && response.sensorHistories[0]) {
        Object.values(response.sensorHistories[0].data).forEach((d) => {
          const utcSensorTimestamp = `${d.timestamp}Z`;
          const sensorTs = new Date(utcSensorTimestamp).getTime();
          if (latestSensorTs < sensorTs) {
            latestSensorTs = sensorTs;
          }
        });
      }
      setLatestSensorTsFromDWH(latestSensorTs);
      setSensorData(newData[0]);
    });
    getTsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedBulkSensorData]);

  const refreshSensors = useCallback(() => {
    setRefreshLoadingSensors(true);
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = sensorData ? [{ ...sensorData }] : undefined;
    const tmpDisplayedData = Object.entries(sensorData).reduce((acc, e) =>
      (displayedSensors.includes(e[0]) ? acc : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: selectedNode.nodeid });
    setSensorData(tmpDisplayedData as GenericSensorData);

    Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      [selectedNode],
      displayedSensors.map((sen) => Sensors.getSensorId(sen)),
    )
      .then((response) => {
        setOnDemandReadTriggered(true);
        setRefreshLoadingSensors(false);
        if (timestamp.current <= currentDate) {
          let earliestSensorTs;
          if (response.sensorHistories && response.sensorHistories[0]) {
            Object.values(response.sensorHistories[0].data).forEach((d) => {
              const sensorTs = new Date(d.timestamp).getTime();
              if (!earliestSensorTs || sensorTs < earliestSensorTs) {
                earliestSensorTs = sensorTs;
              }
            });
          }
          if (latestSensorTsFromDWH < earliestSensorTs) {
            const newData = Sensors.getSensorTableDataWithValueAndSensor(
              selectedItems,
              displayedSensors,
              response,
              selectedSite.timezone,
              originalData,
              commissioningFields,
            );
            setSensorData(newData[0]);
            setNewSensorDataAvailable(true);
          } else {
            setSensorData(sensorData);
          }
        }
      });

    getTsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer.id, selectedItems, selectedNode, selectedSite.id, sensorData]);

  const getIcon = (sensorid: 'sinr' | 'radioSignalStrength' | 'linkQuality') => {
    const sinrGood = 5;
    const sinrPoor = -6;
    const rssGood = -102;
    const rssPoor = -120;
    const linkqGood = -10;
    const linkqPoor = -17;

    if ((sensorid === 'sinr' && sensorData?.sinr?.value && parseFloat(sensorData.sinr.value) >= sinrGood)
      || (sensorid === 'radioSignalStrength' && sensorData?.radioSignalStrength?.value && parseFloat(sensorData?.radioSignalStrength?.value) >= rssGood)
      || (sensorid === 'linkQuality' && sensorData?.linkQuality?.value && parseFloat(sensorData?.linkQuality?.value) >= linkqGood)) { // good
      return (
        <Tooltip
          text="Good"
        >
          <HighGoodIcon />
        </Tooltip>
      );
    } if ((sensorid === 'sinr' && sensorData?.sinr?.value && parseFloat(sensorData?.sinr?.value) < sinrPoor)
    || (sensorid === 'radioSignalStrength' && sensorData?.radioSignalStrength?.value && parseFloat(sensorData?.radioSignalStrength?.value) < rssPoor)
    || (sensorid === 'linkQuality' && sensorData?.linkQuality?.value && parseFloat(sensorData?.linkQuality?.value) < linkqPoor)) { // poor
      return (
        <Tooltip
          text="Poor"
        >
          <PoorIcon />
        </Tooltip>
      );
    } if ((sensorid === 'sinr' && sensorData?.sinr?.value && parseFloat(sensorData?.sinr.value) > sinrPoor && parseFloat(sensorData?.sinr?.value) < sinrGood)
    || (sensorid === 'radioSignalStrength' && sensorData?.radioSignalStrength?.value && parseFloat(sensorData?.radioSignalStrength?.value) > rssPoor && parseFloat(sensorData?.radioSignalStrength?.value) < rssGood)
    || (sensorid === 'linkQuality' && sensorData?.linkQuality?.value && parseFloat(sensorData?.linkQuality?.value) > linkqPoor && parseFloat(sensorData?.linkQuality?.value) < linkqGood)) { // fair
      return (
        <Tooltip
          text="Fair"
        >
          <FairIcon />
        </Tooltip>
      );
    }
    return <></>;
  };

  return (
    <div className="network">
      <SlideIn hasExpandButton expand={expand} setExpand={setExpand}>
        <>
          <div className="slide-in__title">
            <span>Network</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="slide-in__content network__content">
            <SlideinListElementBig
              title="Connected to platform"
              valueBold={(connectionStatus && connectionStatus.isConnected !== undefined) ? ((connectionStatus.isConnected) ? 'Yes' : 'No') : '--'}
              border="light"
              isFirstElement
              refreshLoading={refreshLoadingIsConnected}
              refreshValue={() => {
                setRefreshLoadingIsConnected(true);
              }}
            />
            {isVerizonUser && (
            <SlideinListElementBig
              title="Network status"
              valueBold={tsData[0] && tsData[0].connected ? (tsData[0].connected === 'true' ? 'Connected' : 'Disconnected') : 'N/A'}
              border="light"
              isFirstElement
            />
            )}
            {/*  <div className="slide-in__link btn-container">
                {isVerizonUser && (
                <Button
                  onClick={() => {
                    missingAPIcall();
                    setOpenSessionHistory(1);
                  }}
                  label="Connection history"
                />
                )}
              </div> */}
            <div className="custom-list-element sensors">
              <div className="list-element-title">Sensors</div>
              {(!refreshLoadingSensors) && (
              <SyncIcon
                data-testid="icon"
                className="list-element__refresh"
                onClick={() => {
                  refreshSensors();
                }}
              />
              )}
              {(onDemandReadTriggered && !newSensorDataAvailable && !refreshLoadingSensors) && (
              <div className="list-element__refresh-result">
                <Tooltip text="There's no newer data available.">
                  <InfoIcon />
                </Tooltip>
              </div>
              )}
              {(refreshLoadingSensors) && (
              <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
              )}
              <SlideinListElementBig
                title="Signal strength"
                valueBold={sensorData?.radioSignalStrength?.value || '--'}
                date={sensorData?.radioSignalStrength?.time}
                value={sensorData?.radioSignalStrength?.unit || 'dBm'}
                icon={getIcon('radioSignalStrength')}
              />
              {isVerizonUser && (
              <>
                <SlideinListElementBig
                  title="SINR"
                  valueBold={sensorData?.sinr?.value || '--'}
                  date={sensorData?.sinr?.time}
                  value={sensorData?.sinr?.unit || 'dB'}
                  icon={getIcon('sinr')}
                />
                <SlideinListElementBig
                  title="Received link quality"
                  valueBold={sensorData?.linkQuality?.value || '--'}
                  date={sensorData?.linkQuality?.time}
                  value={sensorData?.linkQuality?.unit || 'dB'}
                  icon={getIcon('linkQuality')}
                  border="light"
                />
              </>
              )}
            </div>
            <div className="slide-in__link">
              <Button
                onClick={() => {
                  localStorage.setItem('analysisSelectedNodes', JSON.stringify([selectedNode.nodeid]));
                  localStorage.setItem('analysisNetworkStatsDashboardFlag', 'true');
                  localStorage.setItem('analysisPowerQualityDashboardFlag', 'false');
                  history.push('/analysis');
                }}
                label="Navigate to Dashboard"
              />
            </div>
          </div>
        </>
      </SlideIn>
      {expand && (
        <SlideIn isExpanded>
          <div className="slide-in__content network__content">
            <div className="slide-in__title" />
            <SlideinListElementSmall
              title="Activated"
              date={Utils.getConvertedDate(tsData[0]?.lastActivationDate || undefined, undefined, selectedSite.timezone) || '--'}
              isFirstElement
            />
            <SlideinListElementSmall
              title="Commissioned"
              date={selectedNode.commissionedDate || '--'}
              isFirstElement
            />
            {/* }
            <SlideinListElementSmall
              title="Last data report"
              date={Utils.getConvertedDate(selectedNode.ligLastReported, undefined, selectedSite.timezone) || '--'}
            />
            { */}
            {isVerizonUser && (
            <>
              {/* <SlideinListElementSmall
                title="Last registration"
                date="--"
              />
              <SlideinListElementSmall
                title="First platform connection"
                date="--"
                border="light"
            /> */ }
              <div className="add-mg-top">
                <SlideinListElementSmall
                  title="Reboot modem"
                  date={sensorData?.modemBootCount?.time || '--'}
                  prefix="Last"
                  border="light"
                >
                  <div className="add-prop">
                    <p>
                      Reboot count:
                      <span>{parseFloat(sensorData?.modemBootCount?.value) || '--'}</span>
                    </p>
                    {isSensityUserAdmin
                  && (
                  <div className="slide-in__link">
                    <Button onClick={() => setOpenRebootModal(1)} label="Reboot" />
                  </div>
                  )}
                  </div>
                </SlideinListElementSmall>
              </div>
            </>
            )}
          </div>
        </SlideIn>
      )}
      {(openSessionHistory > 0) && (
      <LightsNetworkSessionHistory
        openModal={openSessionHistory}
        setOpenModal={setOpenSessionHistory}
      />
      )}
      {openRebootModal === 1 && (
        <RebootModal
          customerid={selectedCustomer.id}
          siteid={selectedSite.id}
          nodeList={[selectedNode.nodeid]}
          setModalOpen={() => setOpenRebootModal(0)}
        />
      )}
    </div>
  );
}

export default LightsNetwork;
