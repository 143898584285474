import React, { useState } from 'react';
import Modal from '../../../Common/Components/Modal';
import { RemoveNodesProps } from '../../../types/RemoveNodesProps';
import { useAppContext } from '../../../utils/AppContext';
import { postRequestAll } from '../../../utils/fetch';

function RemoveNodes(props: RemoveNodesProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
    selectedNodes,
    activeToolbarBtn,
    closeDeleteNodes,
    updateNodes,
  } = props;
  const [openModal, setOpenModal] = useState(activeToolbarBtn !== 0);

  const { addNotification } = useAppContext();

  return (
    <div className="multi-node-modals">
      {openModal && (
      <div className="manual-override">
        <Modal
          title="Remove nodes from site"
          width="335"
          setModalOpen={() => {
            setOpenModal(false);
            closeDeleteNodes();
          }}
          primaryButtonLabel="Submit"
          primaryButtonAction={async () => {
            if (selectedNodes.size > 0) {
              const nodeIdList = Array.from(selectedNodes.values());
              try {
                if (selectedCustomer === undefined) {
                  addNotification({ type: 'warning', message: 'Please select a site before clicking the Submit button.' });
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const requests: any[] = Array.from(nodeIdList.values()).map(
                    (value) => ({
                      path: `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/${value.nodeid}/remove`,
                    }),
                  );
                  postRequestAll(requests)
                    .then((results) => {
                      if (results.resolved === requests.length) {
                        addNotification({ type: 'info', message: 'The selected nodes have successfully started assigning to the default site.' });
                        if (updateNodes) {
                          updateNodes();
                        }
                      } else {
                        addNotification({ type: 'error', message: 'Your "Remove nodes from site" operation has failed.' });
                      }
                    });
                }
              } catch (e) {
                addNotification({ type: 'error', message: 'Your "Remove nodes" operation has failed.' });
              }
            } else {
              addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
            }
            closeDeleteNodes();
          }}
          secondaryButtonLabel="Cancel"
        >
          <div className="subtitle">
            Are you sure you want to remove nodes from
            <span>
              {' '}
              {selectedSite.name}
            </span>
            ?
          </div>
          <div className="content">
            <div className="content-selected-nodes">
              <span className="content-selected-nodes-title">Remove</span>
              <span className="content-selected-nodes-big">{selectedNodes.size}</span>
              <span>{selectedNodes.size > 1 ? 'Nodes selected' : 'Node selected'}</span>
            </div>
            <div className="content-text">
              Nodes will be assigned to default site.
            </div>
          </div>
        </Modal>
      </div>
      )}
    </div>
  );
}

export default RemoveNodes;
