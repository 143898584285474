import React from 'react';
import useSWR from 'swr';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { slideInWidth } from '../../../utils/constants';
import { CustomAttributeInstance } from '../../../types/CustomAttributes';

function LightsIDsMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems } = props;
  const nodeIds = Array.from(selectedItems.keys());
  const { data: customAttributeInstances } = useSWR<Array<CustomAttributeInstance>>([
    `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/lkp/nodes/custom-attributes?nodeId=${nodeIds.join(',')}`,
  ]);

  const headers: Array<{key: string, val: string}> = [{ key: 'nodeId', val: 'Node ID' }];
  const tableData: Array<Record< string, string >> = [];

  if (selectedItems && selectedItems.size > 0) {
    nodeIds.forEach((nodeId) => {
      const selectedItem = selectedItems.get(nodeId) || {};
      const tableRow = { nodeId };
      Object.keys(selectedItem).filter((key) => key.startsWith('customAttributes_'))
        .forEach((key) => {
          const value = selectedItem[key];
          const newKey = key.replace('customAttributes_', '');
          if (!headers.find((h) => h.key === newKey)) {
            headers.push({ key: newKey, val: newKey });
          }
          tableRow[newKey] = value;
        });
      const userCustomAttributeInstance = customAttributeInstances?.find((ca) => ca.nodeId === nodeId);
      if (userCustomAttributeInstance) {
        const userCustomAttributes = userCustomAttributeInstance.attributes.filter((a) => a.id !== undefined);
        userCustomAttributes.forEach((ca) => {
          if (!headers.find((h) => h.val === ca.name)) {
            headers.push({ key: ca.name, val: ca.name });
          }
          // eslint-disable-next-line dot-notation
          tableRow[ca.name] = ca.value;
        });
      }
      tableData.push(tableRow);
    });
  }

  return (
    <div className="lights-ids-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Light IDs</span>
          </div>
          <div className="slide-in__content">
            <div className="table small-table">
              <Table
                headers={headers}
                data={tableData}
                dataFetchedAlready
                fixColCount={1}
                dark
              />
            </div>
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsIDsMulti;
