/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';

import Table from '../../../../Common/Components/Table';
import { PageComponentProps } from '../../../../types/PageComponentProps';
import getHeaderProps from '../../../../utils/getHeaderProps';
import AdminSidebarConfigItems from '../../Components/AdminSidebarConfigItems';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import TableToolbar from '../../../Admin/Pages/Components/TableToolbar';
import { AlertActivityReportsObject, AlertActivityReportsTableItem } from '../../../../types/AlertActivityReports';
import Utils from '../../../../utils/Utils';
import Tooltip from '../../../../Common/Components/Tooltip';
import Button from '../../../../Common/Components/Button';
import Modal from '../../../../Common/Components/Modal';
import { deleteRequest, postRequest } from '../../../../utils/fetch';
import { useAppContext } from '../../../../utils/AppContext';

import { ReactComponent as CsvIcon } from '../../../../img/icons/csv-file.svg';
import { ReactComponent as EditIcon } from '../../../../img/icons/edit.svg';
import { ReactComponent as RunIcon } from '../../../../img/icons/play-circle.svg';
import { ReactComponent as DownloadIcon } from '../../../../img/icons/download.svg';
import { ReactComponent as DeleteIcon } from '../../../../img/icons/trash.svg';
import DownloadAlertActivityReportModal from './Components/DownloadAlertActivityReportModal';
import ToolbarButton from '../../../../Common/Components/ToolbarButton';
import AlertActivityReportWizard from './Components/AlertActivityReportWizard';

const tableRowHeight = 40;
const recipientsMaxRows = 7;
const modalControlsHeight = 240;

function AlertActivityReportsPage(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;

  const { addNotification } = useAppContext();
  const mainHeaders = getHeaderProps('AlertActivityReports');
  const recipientsHeaders = getHeaderProps('AlertActivityReportsRecipients');

  const modalRecipients = 1;
  const modalCreateReport = 2;
  const modalEditReport = 3;
  const modalDownloadReport = 4;
  const modalRunReport = 5;
  const modalDeleteReport = 6;

  const [selectedReport, setSelectedReport] = useState<AlertActivityReportsObject>();
  const [alertActivityReports, setAlertActivityReports] = useState<AlertActivityReportsTableItem[]>([]);
  const [openModal, _setOpenModal] = useState<number>(0);
  const setOpenModal = (newVal) => {
    if (newVal === 0) {
      setSelectedReport(undefined);
    }

    _setOpenModal(newVal);
  };

  const { data: alertActivityReportsResp, mutate: updateAlertActivityReportList } = useSWR<AlertActivityReportsObject[]>(
    [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/reports`, 'GetAlertActivityReports'],
  );

  const isNonReadOnly = Utils.isNonReadOnly();

  useEffect(() => {
    if (alertActivityReportsResp) {
      setAlertActivityReports(alertActivityReportsResp.map((item) => {
        const tableItem: AlertActivityReportsTableItem = {
          name: item.name,
          frequency: Utils.capitalizeString(item.frequency || ''),
          recipientsTableSort: item.recipients.map((recipient) => recipient.email).join(),
          recipients: item.recipients.length > 0 ? (
            <div>
              {item.recipients[0].email}
              {item.recipients.length > 1 && (
                <span>
                  ,&nbsp;
                  <Button
                    buttonType="textlink"
                    label={`+${item.recipients.length - 1}`}
                    onClick={() => {
                      setSelectedReport(item);
                      setOpenModal(modalRecipients);
                    }}
                  />
                </span>
              )}
            </div>
          ) : (<div />),
          actions: (
            <div>
              { isNonReadOnly && (
                <div className="alert-activity-reports-actions">
                  <Tooltip text="Edit report">
                    <EditIcon
                      onClick={() => {
                        setSelectedReport(item);
                        setOpenModal(modalEditReport);
                      }}
                    />
                  </Tooltip>
                  <Tooltip text="Run report">
                    <RunIcon
                      onClick={() => {
                        setSelectedReport(item);
                        setOpenModal(modalRunReport);
                      }}
                    />
                  </Tooltip>
                  <Tooltip text="Download report">
                    <DownloadIcon
                      onClick={() => {
                        setSelectedReport(item);
                        setOpenModal(modalDownloadReport);
                      }}
                    />
                  </Tooltip>
                  <Tooltip text="Delete report">
                    <DeleteIcon
                      onClick={() => {
                        setSelectedReport(item);
                        setOpenModal(modalDeleteReport);
                      }}
                    />
                  </Tooltip>
                </div>
              )}
              { !isNonReadOnly && (
                <div className="alert-activity-reports-actions readonly">
                  <Tooltip text="Download report">
                    <DownloadIcon
                      onClick={() => {
                        setSelectedReport(item);
                        setOpenModal(modalDownloadReport);
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          ),
        };

        return tableItem;
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertActivityReportsResp]);

  return (
    <div className="config__content alert-activity-reports">
      <AdminSidebar selectedSite={selectedSite} title="Site config">
        <AdminSidebarConfigItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin">
        <TableToolbar title="Alert Activity Reports">
          <div className="alert-activity-reports-tabletoolbar">
            <div className="tabletoolbar__children">
              {isNonReadOnly && (
              <div className="tabletoolbar__children-content" data-testid="openCreateAlertActivityReportModal">
                <ToolbarButton
                  order={1}
                  toggleToolbarBtn={() => undefined}
                  activeToolbarBtn={1}
                  shouldOpenModal={() => setOpenModal(modalCreateReport)}
                  icon={(
                    <div className="request-report">
                      <CsvIcon />
                      <div>
                        <span>Request</span>
                        <span>Detailed report</span>
                      </div>
                    </div>
                  )}
                  tooltipText=""
                  noborder
                />
              </div>
              )}
            </div>
          </div>
        </TableToolbar>
        <div className="table table--light top-border-light auto-height">
          <Table
            headers={mainHeaders}
            data={alertActivityReports}
            autoheight
            skipCellMeasure
            colWidthCalcFn={(col: number, width: number): number => {
              const { name, frequency, actions } = { name: 400, frequency: 150, actions: 150 };
              const margin = 5;
              const remainColCount = 1;
              const colWidth = (width - name - frequency - actions - margin) / remainColCount;

              return [name, frequency, colWidth, actions][col];
            }}
          />
        </div>
      </div>
      {openModal === modalCreateReport && (
        <AlertActivityReportWizard
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          closeModal={() => setOpenModal(0)}
          updateAlertActivityReportList={updateAlertActivityReportList}
        />
      )}
      {selectedReport !== undefined && (
        (openModal === modalEditReport && (
          <AlertActivityReportWizard
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            selectedReport={selectedReport}
            closeModal={() => setOpenModal(0)}
            updateAlertActivityReportList={updateAlertActivityReportList}
          />
        ))
        || (openModal === modalRecipients && (
          <Modal
            title="Recipients"
            width="640"
            height={(selectedReport.recipients.length < recipientsMaxRows
              ? (modalControlsHeight + (selectedReport.recipients.length * tableRowHeight))
              : (modalControlsHeight + (recipientsMaxRows * tableRowHeight))).toString()}
            setModalOpen={() => setOpenModal(0)}
            secondaryButtonLabel="Close"
            hasTable
          >
            <div className="table table--light">
              <Table
                headers={recipientsHeaders}
                data={selectedReport.recipients}
                skipCellMeasure
                autoheight
              />
            </div>
          </Modal>
        ))
        || (openModal === modalDownloadReport && (
          <DownloadAlertActivityReportModal
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            selectedReport={selectedReport}
            setOpenModal={setOpenModal}
          />
        ))
        || (openModal === modalRunReport && (
          <Modal
            title="Run report"
            width="360"
            setModalOpen={() => setOpenModal(0)}
            primaryButtonLabel="Confirm"
            primaryButtonAction={async () => {
              try {
                const result = await postRequest(`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/reports/${selectedReport.id}/run`);

                if (!result.error) {
                  addNotification({ type: 'info', message: 'Your "Run report" operation is completed.' });
                } else {
                  addNotification({ type: 'error', message: `Your "Run report" operation has failed: ${result.error}` });
                }

                setOpenModal(0);
              } catch (e) {
                addNotification({ type: 'error', message: 'Your "Run report" operation has failed.' });
                setOpenModal(0);
              }
            }}
            secondaryButtonLabel="Cancel"
          >
            <span>Are you sure you want to run this report?</span>
          </Modal>
        ))
        || (openModal === modalDeleteReport && (
          <Modal
            title="Delete report"
            width="360"
            setModalOpen={() => setOpenModal(0)}
            primaryButtonLabel="Confirm"
            primaryButtonAction={async () => {
              try {
                const result = await deleteRequest(`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/reports/${selectedReport.id}`);

                if (!result.error) {
                  addNotification({ type: 'info', message: 'Your "Delete report" operation is completed.' });
                } else {
                  addNotification({ type: 'error', message: `Your "Delete report" operation has failed: ${result.error}` });
                }
              } catch (e) {
                addNotification({ type: 'error', message: 'Your "Delete report" operation has failed.' });
              } finally {
                setOpenModal(0);
                updateAlertActivityReportList();
              }
            }}
            secondaryButtonLabel="Cancel"
          >
            <span>Are you sure you want to delete this report?</span>
          </Modal>
        ))
      )}
    </div>
  );
}

export default AlertActivityReportsPage;
