import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';

import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { LightsFirmwareTable } from '../../../types/LightsFirmwareTable';
import { slideInWidth } from '../../../utils/constants';
import getHeaderProps from '../../../utils/getHeaderProps';
import Utils from '../../../utils/Utils';
import Button from '../../../Common/Components/Button';
import { postRequest } from '../../../utils/fetch';
import { FirmwareObject } from '../../../types/FirmwareObjects';
import { NodeObject } from '../../../types/NodeObject';
import Loading from '../../../Common/Components/Loading';

function LightsFirmwareMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems } = props;
  const history = useHistory();
  const headers = getHeaderProps('LightsFirmware');

  const [selectedNodes, setSelectedNodes] = useState<Array<LightsFirmwareTable> | undefined>([]);
  const [loading, setLoading] = useState(false);
  const isSensityUserAdmin = Utils.isSensityUserAdmin();

  useEffect(() => {
    setLoading(true);
    const firmwareNames: Array<string> = Array.from(new Set(Array.from(selectedItems.values()).map((selectedItem) => selectedItem.softwareVersion)));
    postRequest('/fota/firmware/details', { firmwareNames })
      .then((response) => {
        if (!response.error) {
          const nodes: Array<LightsFirmwareTable> = Array.from(selectedItems.values()).map((selectedItem: NodeObject) => {
            const firmware = Utils.getFirmwareByNodeModelName(selectedItem, response.data as Array<FirmwareObject>);
            return {
              nodeId: selectedItem.nodeid,
              modelName: selectedItem.modelName,
              firmwareId: firmware?.firmwareId,
              version: firmware?.version,
              date: Utils.getConvertedDate(firmware?.releaseDate).split(',')[0],
            };
          });

          setSelectedNodes(nodes);
        }
      }).finally(() => setLoading(false));
  }, [selectedItems]);

  return (
    <div className="firmware-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>App firmware</span>
            { isSensityUserAdmin && (
            <div className="slide-in__title-subtitle">
              <div className="slide-in__link">
                <Button
                  label="View FOTA campaigns"
                  onClick={() => { history.push('/admin/fotacampaigns'); }}
                />
              </div>
            </div>
            )}
          </div>
          <div className="slide-in__content">
            {loading ? <Loading isLoading={loading} /> : (
              <div className="table">
                <Table
                  headers={headers}
                  data={selectedNodes}
                  fixColCount={1}
                  dark
                />
              </div>
            )}
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsFirmwareMulti;
