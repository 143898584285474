import React from 'react';
import { SoftwareVersionModalProps } from '../../../types/SoftwareVersionModalProps';
import Modal from '../../Components/Modal';

function SoftwareVersionModal(props: SoftwareVersionModalProps): JSX.Element {
  const { setModalOpen, releaseVersion, apiURL, gitBuild } = props;

  return (
    <Modal
      title="Software version"
      width="300"
      primaryButtonLabel="Close"
      setModalOpen={setModalOpen}
    >
      <div className="swversion-modal">
        <span className="swversion-modal__title">Release version</span>
        <span className="swversion-modal__value">{releaseVersion}</span>
        <span className="swversion-modal__title">API host URL</span>
        <span className="swversion-modal__value">{apiURL}</span>
        <span className="swversion-modal__title">Commit ID</span>
        <span className="swversion-modal__value">{gitBuild}</span>
      </div>
    </Modal>
  );
}

export default SoftwareVersionModal;
