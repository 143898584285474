/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { List, ListRowProps, ListRowRenderer } from 'react-virtualized';
import RadioButtons from '../../../Common/Components/RadioButtons';
import { ReactComponent as LihtingGroupIcon } from '../../../img/icons/lighting-group.svg';
import { ReactComponent as OrgGroupIcon } from '../../../img/icons/org-group.svg';
import { WizardGroupsSelectorPropsType } from '../../../types/GroupsSelector';
import { LightsGroupsItem } from '../../../types/LightsGroupProps';
import { RadioButtonElementProps } from '../../../types/RadioButtonsProps';
import { ReactComponent as MapIcon } from '../../../img/icons/site-locator.svg';

const rowHeight = 41;

function WizardGroupSelector(props: WizardGroupsSelectorPropsType): JSX.Element {
  const {
    lightingGroups,
    orgGroups,
    groupFilterActiveTab,
    setGroupFilterActiveTab,
    hightlightedGroup,
    setHightlightedGroup,
    granularity,
    setGranularity,
    selectedSite,
  } = props;

  const radioOptions = [
    { label: 'Site', key: 'site' },
    { label: 'Lighting group', key: 'LIGHTING' },
    { label: 'Org group', key: 'ORGANIZATIONAL' },
  ];

  const granularityOptions = [
    { label: 'Summary', key: 'AGGREGATED' },
    { label: 'Individual node', key: 'INDIVIDUAL' },
  ];

  const [groupType, setGroupType] = useState<RadioButtonElementProps>(radioOptions.find((e) => e.key === groupFilterActiveTab) as RadioButtonElementProps);
  const [selectedGranularity, setSelectedGranularity] = useState<RadioButtonElementProps>(granularityOptions.find((e) => e.key === granularity) as RadioButtonElementProps);

  const groupLineHeight = 41;
  const listWidth = 364;
  const listHeight = 274;

  const renderLightingGroupList = ({ key, index, style }: ListRowProps) => (
    renderGroupList(lightingGroups, { key, index, style } as ListRowProps)
  );

  const renderOrgGroupList = ({ key, index, style }: ListRowProps) => (
    renderGroupList(orgGroups, { key, index, style } as ListRowProps)
  );

  const renderGroupList = (groups: LightsGroupsItem[], { key, index, style }: ListRowProps) => (
    <div
      key={key}
      className={`group-list-row
      ${hightlightedGroup?.groupId === groups[index].groupId ? 'group-list-row--selected' : ''}`}
      style={style}
      onClick={() => {
        setHightlightedGroup(groups[index]);
      }}
    >
      <span className="group-list-row__group-name">
        {groups[index].name}
      </span>
      <span className="group-list-row__node-length">
        {groups[index].nodeList?.length}
      </span>
    </div>
  );

  const renderGroupSelectorTab = (
    title: string,
    icon: JSX.Element,
    listElementRenderer: ListRowRenderer,
    groupList: LightsGroupsItem[],
  ) => (
    <>
      <div className="group-info">
        {icon}
        <div className="group-info__title required">
          {title}
        </div>
      </div>
      <div className="group-actions group-actions__container-group open">
        <div className="group-actions__container-group-list">
          <List
            width={listWidth}
            height={groupList.length > 6 ? listHeight : groupList.length * rowHeight}
            rowCount={groupList.length}
            rowHeight={groupLineHeight}
            rowRenderer={listElementRenderer}
            data={groupList}
          />
        </div>
      </div>
    </>
  );

  return (
    <div className="modal-divided">
      <div className="modal-divided__sidebar energy-node-selector">
        <div className="energy-node-selector__radio-title required"> Select nodes in </div>
        <RadioButtons
          onClick={(clickedRadioBtn: RadioButtonElementProps) => {
            setGroupFilterActiveTab(clickedRadioBtn.key);
            setGroupType(clickedRadioBtn);
          }}
          selected={groupType}
          list={radioOptions}
          type="light"
          extraClasses="vertical margin-bottom-40"
        />
        <div className="energy-node-selector__radio-title required"> Granularity </div>
        <RadioButtons
          onClick={(clickedRadioBtn: RadioButtonElementProps) => {
            setGranularity(clickedRadioBtn.key);
            setSelectedGranularity(clickedRadioBtn);
          }}
          selected={selectedGranularity}
          list={granularityOptions}
          type="light"
          extraClasses="vertical"
        />
      </div>
      <div className="modal-divided__content energy-node-selector energy-node-selector--group-selector">
        {groupFilterActiveTab === 'site' && (
        <span className="site-info">
          <MapIcon />
          <span className="site-info__title">
            Site
          </span>
          <span className="site-info__subtitle">
            <div>
              {selectedSite.name}
            </div>
          </span>
        </span>
        ) }
        {groupFilterActiveTab === 'LIGHTING' && (
        <>
            {renderGroupSelectorTab(
              'Select lighting group',
              <LihtingGroupIcon />,
              renderLightingGroupList,
              lightingGroups,
            )}
        </>
        ) }
        { groupFilterActiveTab === 'ORGANIZATIONAL' && (
        <>
          {renderGroupSelectorTab(
            'Select org group',
            <OrgGroupIcon />,
            renderOrgGroupList,
            orgGroups,
          )}
        </>
        )}
      </div>
    </div>
  );
}

export default WizardGroupSelector;
