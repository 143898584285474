import { addDays, subDays, differenceInDays, min } from 'date-fns';
import React, { useEffect } from 'react';
import { DateRangePickerProps } from '../../types/DateRangePickerProps';
import Datepicker from './Datepicker';

function DateRangePicker(props: DateRangePickerProps): JSX.Element {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    maxDaysRange,
    setDaysDelta,
    type,
    pickerPlacement = 'bottom',
  } = props;

  const now = new Date();
  const minRange = subDays(now, maxDaysRange);
  const maxRange = addDays(now, maxDaysRange);

  useEffect(() => {
    if (setDaysDelta) {
      endDate?.setMilliseconds(0);
      startDate?.setMilliseconds(0);
      setDaysDelta(differenceInDays(endDate, startDate) + 1);
    }
  }, [startDate, endDate, setDaysDelta]);

  const setStartDateWithTime = (date: Date) => {
    setStartDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  };

  const setEndDateWithTime = (date:Date) => {
    const minDate = min([
      new Date(),
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
    ]);

    setEndDate(minDate);
  };

  return (
    <>
      <Datepicker
        placeholder="Select start date"
        label="Start date"
        selectedDate={startDate}
        setSelectedDate={setStartDateWithTime}
        minDate={minRange}
        maxDate={endDate}
        type={type}
        placement={pickerPlacement}
      />
      <Datepicker
        placeholder="Select end date"
        label="End date"
        selectedDate={endDate}
        setSelectedDate={setEndDateWithTime}
        minDate={startDate}
        maxDate={maxRange > now ? now : maxRange}
        type={type}
        placement={pickerPlacement}
      />
    </>
  );
}

export default DateRangePicker;
