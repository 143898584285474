/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Button from '../../../Common/Components/Button';
import DropDown from '../../../Common/Components/DropDown';
import Textbox from '../../../Common/Components/Textbox';
import { ScheduleMetaData } from '../../../types/ScheduleObject';
import { ScheduleToolbarProps } from '../../../types/ScheduleToolbarProps';

import { ReactComponent as EditIcon } from '../../../img/icons/edit.svg';
import { ReactComponent as CloseIcon } from '../../../img/icons/close.svg';
import formValidation from '../../../utils/form/formValidation';
import Modal from '../../../Common/Components/Modal';
import Utils from '../../../utils/Utils';
import { postRequest } from '../../../utils/fetch';

import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { ReactComponent as WarningIcon } from '../../../img/icons/warning.svg';
// import { ReactComponent as FallbackDriverLevelIcon } from '../../../img/icons/fallback-driver-level.svg';
// import ToolbarButton from '../../../Common/Components/ToolbarButton';

function ScheduleToolbar(props: ScheduleToolbarProps): JSX.Element {
  const {
    scheduleData,
    setScheduleData,
    openScheduleModal,
    setOpenScheduleModal,
    saveSchedule,
    deleteSchedule,
    openToolbarDropdown,
    selectedCustomer,
    isReadOnly,
    names } = props;

  const [openNameDropdown, setOpenNameDropdown] = useState(false);
  const [confirmEditScheduleModal, setConfirmEditScheduleModal] = useState(false);
  const [confirmDeleteScheduleModal, setConfirmDeleteScheduleModal] = useState(false);
  const [hash, setHash] = useState<ScheduleMetaData>();

  const [scheduleToolbarData, setScheduleToolbarData] = useState({
    name: scheduleData.name || '',
    nameError: false,
    nameErrorMessage: '',
    description: scheduleData.description || '',
    descriptionError: false,
    descriptionErrorMessage: '',
  });

  useEffect(() => {
    async function getHash() {
      const postBody = [scheduleData.scheduleId];
      const hashResp = await postRequest(
        `/organizations/${selectedCustomer.id}/schedules/projections/hash`,
        postBody,
      );
      if (!hashResp.error) {
        const response = hashResp.data as ScheduleMetaData[];
        setHash(response[0]);
      }
    }
    if (Utils.isVerizonUser() && selectedCustomer) {
      getHash();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData.scheduleId]);

  const modalScheduleCreate = 1;
  const modalScheduleEdit = 2;

  // eslint-disable-next-line no-nested-ternary
  const title = openScheduleModal === modalScheduleCreate ? 'Create' : (openScheduleModal === modalScheduleEdit ? 'Edit' : 'View');

  const setName = (name: string) => {
    const nameValidation = formValidation(name, { required: true, max: 255 });
    const nonUniqueName = names?.includes(name);

    if (nameValidation.hasError) {
      setScheduleToolbarData((oldValues) => ({ ...oldValues, name, nameError: true, nameErrorMessage: nameValidation.errorMsg }));
    } else if (nonUniqueName) {
      setScheduleToolbarData((oldValues) => ({ ...oldValues, name, nameError: true, nameErrorMessage: 'Schedule name already exists.' }));
    } else {
      setScheduleToolbarData((oldValues) => ({ ...oldValues, name, nameError: false, nameErrorMessage: '' }));
    }
  };

  const setDescription = (description: string) => {
    const descrValidation = formValidation(description, { max: 255 });

    if (descrValidation.hasError) {
      setScheduleToolbarData((oldValues) => ({ ...oldValues, description, descriptionError: true, descriptionErrorMessage: descrValidation.errorMsg }));
    } else {
      setScheduleToolbarData((oldValues) => ({ ...oldValues, description, descriptionError: false, descriptionErrorMessage: '' }));
    }
  };

  useEffect(() => {
    if (openToolbarDropdown.open && !isReadOnly) {
      setName(scheduleToolbarData.name);
      setOpenNameDropdown(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openToolbarDropdown]);

  const showDeleteButton = openScheduleModal === modalScheduleEdit && !isReadOnly && scheduleData.lightingGroups?.length === 0 && !scheduleData.isDefault;

  return (
    <div className="schedule">
      <div className="schedule__content">
        <div className="schedule__content-title">
          {title}
          {' '}
          schedule
        </div>
        <div className="schedule__content-toolbar">
          <DropDown>
            <button
              type="button"
              className="schedule__content-toolbar__btn"
              onClick={() => {
                if (!isReadOnly) {
                  setOpenNameDropdown(!openNameDropdown);
                }
              }}
            >
              {!isReadOnly && <EditIcon />}
              <div className="schedule__content-toolbar__btn-content">
                <span className="schedule__content-toolbar__btn-content-label">
                  Name
                  {!isReadOnly && <span className="required">*</span>}
                </span>
                <span className={`schedule__content-toolbar__btn-content-text ${scheduleData.name !== '' ? 'filled' : ''}`}>
                  {scheduleData.name !== '' ? scheduleData.name : 'Enter name' }
                </span>
                <span className="schedule__content-toolbar__btn-content-label">Description</span>
                <span className={`schedule__content-toolbar__btn-content-text ${scheduleData.description !== '' ? 'filled' : ''}`}>
                  {scheduleData.description !== '' ? scheduleData.description : 'Enter description' }
                </span>
              </div>
            </button>
          </DropDown>
          {openNameDropdown && (
          <div className="schedule__content-toolbar-edit">
            <Textbox
              label="Name"
              isRequired
              onChange={(e) => setName(e.target.value)}
              value={scheduleToolbarData.name}
              styleType="dark"
              placeholder="Enter name"
              errorMessage={scheduleToolbarData.nameErrorMessage}
              error={scheduleToolbarData.nameError}
            />
            <Textbox
              label="Description"
              type="textarea"
              onChange={(e) => setDescription(e.target.value)}
              value={scheduleToolbarData.description}
              styleType="dark"
              placeholder="Enter description"
              errorMessage={scheduleToolbarData.descriptionErrorMessage}
              error={scheduleToolbarData.descriptionError}
            />
            <div className="schedule__content-toolbar-edit-footer">
              <Button
                onClick={() => setOpenNameDropdown(false)}
                label="Cancel"
                buttonType="primary"
                buttonSize="medium"
                extraClasses="margin-right-10"
              />
              <Button
                onClick={() => {
                  setScheduleData((oldSchedule) => ({ ...oldSchedule, name: scheduleToolbarData.name, description: scheduleToolbarData.description }));
                  setOpenNameDropdown(false);
                }}
                label="Apply"
                buttonType="secondary"
                buttonSize="medium"
                extraClasses="margin-right-0"
              />
            </div>
          </div>
          )}
        </div>
      </div>
      { Utils.isVerizonUser()
        && (
          <div className="schedule__hash">
            <div>
              <span>
                {'   '}
                sha256:
              </span>
              {' '}
              <span>
                {hash ? hash.sha256 : ''}
              </span>
            </div>
            <div>
              <span>sha256doc:</span>
              {' '}
              <span>
                {hash ? hash.sha256doc : ''}
              </span>
            </div>
          </div>
        )}
      <div className="schedule-btns">
        {showDeleteButton && (
          <Button
            buttonType="primary"
            extraClasses="schedule-btns-delete"
            onClick={() => setConfirmDeleteScheduleModal(true)}
            label="Delete"
          />
        )}
        {(!isReadOnly && (openScheduleModal === modalScheduleCreate || openScheduleModal === modalScheduleEdit)) && (
        <Button
          buttonType={showDeleteButton ? 'secondary' : 'primary'}
          extraClasses="schedule-btns-save"
          onClick={() => {
            if (openScheduleModal === modalScheduleCreate) {
              saveSchedule();
            } else {
              setConfirmEditScheduleModal(true);
            }
          }}
          label="Save"
        />
        )}
        <Button onClick={() => setOpenScheduleModal(0)} extraClasses="schedule-btns-close"><CloseIcon /></Button>
      </div>
      {confirmEditScheduleModal && (
        <Modal
          title="Edit schedule"
          width="300"
          primaryButtonLabel="Save"
          primaryButtonAction={() => {
            setConfirmEditScheduleModal(false);
            saveSchedule();
          }}
          secondaryButtonLabel="Cancel"
          secondaryButtonAction={() => setConfirmEditScheduleModal(false)}
          setModalOpen={() => setConfirmEditScheduleModal(false)}
        >
          <div className="edit-schedule-confirm">
            <div className="edit-schedule-confirm--title">Modify schedule?</div>
            <div>
              <div className="icon">
                <InfoIcon className="icon-info" />
              </div>
              <div>Schedule will be updated for all nodes in all assigned lighting groups.</div>
            </div>
            <div>
              <div className="icon">
                <WarningIcon className="icon-warning" />
              </div>
              <div>
                Please allow up to
                <span className="bold"> 3 days </span>
                for the new schedule to reach all nodes.
              </div>
            </div>
          </div>
        </Modal>
      )}
      {confirmDeleteScheduleModal && (
        <Modal
          title="Delete schedule"
          width="300"
          primaryButtonLabel="Delete"
          primaryButtonAction={() => {
            setConfirmDeleteScheduleModal(false);
            deleteSchedule();
          }}
          secondaryButtonLabel="Cancel"
          secondaryButtonAction={() => setConfirmDeleteScheduleModal(false)}
          setModalOpen={() => setConfirmDeleteScheduleModal(false)}
        >
          <div className="delete-schedule-confirm">Are you sure you want to delete this schedule?</div>
        </Modal>
      )}
    </div>
  );
}

export default ScheduleToolbar;
