/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';

import Datepicker from '../../../Common/Components/Datepicker';
import Table from '../../../Common/Components/Table';
import Button from '../../../Common/Components/Button';
import { AuditTrailTable } from '../../../types/AuditTrailTable';
import { PageComponentProps } from '../../../types/PageComponentProps';
import { useAppContext } from '../../../utils/AppContext';
import { getRequest, putRequest } from '../../../utils/fetch';
import getHeaderProps from '../../../utils/getHeaderProps';
import Utils from '../../../utils/Utils';
import AdminSidebarConfigItems from '../Components/AdminSidebarConfigItems';
import AdminSidebar from '../../../Common/Components/AdminSidebar';
import { GroupObject } from '../../../types/GroupObject';
import { ScheduleObject } from '../../../types/ScheduleObject';
import TableToolbar from '../../Admin/Pages/Components/TableToolbar';
import Tooltip from '../../../Common/Components/Tooltip';

import { ReactComponent as CalendarIcon } from '../../../img/icons/calendar-white.svg';
import DropDown from '../../../Common/Components/DropDown';
import TableToolbarSubtitle from '../../Admin/Pages/Components/TableToolbarSubtitle';
import AuditActions from './AuditActions';

function AuditLogSettings(props: PageComponentProps): JSX.Element {
  const auditPageSize = 1000;
  const { selectedCustomer, selectedSite } = props;
  const headers = getHeaderProps('AuditEntry');

  const noSiteMessage = 'No site is selected for the operation.';
  const serverErrorMessage = 'Could not fetch audit trail.';

  const { addNotification } = useAppContext();

  const initDate = () => {
    const start = new Date(Date.now());
    const end = new Date(Date.now());

    start.setDate(start.getDate() - 7);

    return {
      start,
      end,
    };
  };

  const [startDate, setStartDate] = useState(initDate().start);
  const [endDate, setEndDate] = useState(initDate().end);
  const [dataFetchedAlready, setDataFetchedAlready] = useState<boolean>(false);
  const [auditResp, setAuditResp] = useState<AuditTrailTable | undefined>(undefined);
  const [showModal, setShowModal] = useState(0);
  const [selectedRecordIdItems, setSelectedRecordIdItems] = useState<string>('');

  const [fromLightsPage, setFromLightsPage] = useState<boolean>(sessionStorage.getItem('auditSelectedNodes') !== null);

  // const hoursInDay = 24;

  const [groupList, setGroupList] = useState<Map<string, GroupObject> | undefined>(undefined);
  const {
    data: groupsResp,
  } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups']
    : null);

  const [scheduleList, setScheduleList] = useState<Map<string, ScheduleObject> | undefined>(undefined);
  const {
    data: scheduleResp,
  } = useSWR<Array<ScheduleObject>>(selectedSite.id
    ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/schedules-detailed`, 'GetSchedules']
    : null);

  const actionsCell = ((subjectedId: string) => (
    <div className="alarm-actions">
      <Tooltip
        text="Details"
        position="top"
        offset={-2}
      >
        <Button
          onClick={async () => {
            try {
              const path = `/useraudit/records/${subjectedId}`;
              const resp = await getRequest(path);
              if (!resp.error) {
                setSelectedRecordIdItems(resp);
                setShowModal(1);
              } else {
                addNotification({ type: 'error', message: 'Unable to fetch audit subject items.' });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Unable to fetch audit subject items.' });
            }
          }}
        >
          <span>Details</span>
        </Button>
      </Tooltip>
    </div>
  ));

  const fetchAuditData = (async () => {
    if (!selectedCustomer?.id || !selectedSite?.id) {
      addNotification({ type: 'warning', message: noSiteMessage });
    } else {
      const beginningOfStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      let endOfEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);

      if (Date.now() < endOfEndDate.getTime()) {
        endOfEndDate = new Date();
      }

      const startDateTimestamp = Math.floor(beginningOfStartDate.getTime() / 1000);
      const endDateTimestamp = Math.floor(endOfEndDate.getTime() / 1000);
      try {
        setDataFetchedAlready(false);
        setAuditResp(undefined);

        const siteQueryParam = selectedSite.id ? `&siteId=${selectedSite.id}` : '';
        const auditUrl = `/useraudit?from=${startDateTimestamp}&to=${endDateTimestamp}&size=${auditPageSize}${siteQueryParam}`;
        const putBody = {
          auditSubjectItemIds: JSON.parse(sessionStorage.getItem('auditSelectedNodes') || '[]'),
        };

        const resp = fromLightsPage
          ? await putRequest<{ auditSubjectItemIds?: string[] }, AuditTrailTable>(auditUrl, putBody)
          : await putRequest<{ auditSubjectItemIds?: string[] }, AuditTrailTable>(auditUrl);

        const audits: AuditTrailTable = resp.data || [];
        audits?.forEach((record) => {
          record.auditTimeTableSort = parseInt(record.auditTime, 10) || 0;
          record.auditTime = Utils.getConvertedDate(record.auditTime);
          record.description = Utils.removeTrailingBrackets(record.description);
          if (record.subjectedId === selectedSite.id) {
            record.subjectedId = `Site: ${selectedSite.name}`;
          } else {
            const groupName = groupList?.get(record.subjectedId)?.name;
            if (groupName) {
              record.subjectedId = groupName === undefined ? record.subjectedId : `Group: ${groupName}`;
            } else {
              const name = scheduleList?.get(record.subjectedId)?.name;
              if (name) {
                record.subjectedId = name === undefined ? record.subjectedId : `Schedule: ${name}`;
              } else if (record.subjectedId) {
                record.subjectedId = `Node: ${record.subjectedId}`;
              } else if (record.subjectedItemsCount > 1) {
                record.subjectedId = `${record.subjectedItemsCount} nodes`;
              } else if (record.subjectedItemsCount === 1) {
                record.subjectedId = '1 node';
              } else {
                record.subjectedId = '';
              }
            }
          }
          if (record.subjectedItemsCount > 0) {
            record.actions = record.actions || actionsCell(record.auditId);
          }

          return record;
        });

        setAuditResp(audits);
        setDataFetchedAlready(true);
      } catch (e) {
        setAuditResp([]);
        setDataFetchedAlready(false);
        addNotification({ type: 'warning', message: serverErrorMessage });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (groupsResp !== undefined) {
      const tmpMapGroup = new Map();
      groupsResp.forEach((group) => {
        tmpMapGroup.set(group.groupId, group);
      });

      setGroupList(tmpMapGroup);
    }

    if (scheduleResp !== undefined) {
      const tmpMapSchedule = new Map();
      scheduleResp.forEach((schedule) => {
        tmpMapSchedule.set(schedule.scheduleId, schedule);
      });

      setScheduleList(tmpMapSchedule);
    }

    if (groupsResp && scheduleResp) {
      fetchAuditData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsResp, scheduleResp]);

  const [filteredTableData, setFilteredTableData] = useState([]);

  const downloadAuditList = () => {
    Utils.downloadCSV(headers, filteredTableData as Record<string, string | number>[], 'audit_trail');
  };

  const [toggleDateDropdown, setToggleDateDropdown] = useState(false);

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'AuditLog');

  return (
    <div className="config__content audit-trail">
      <AdminSidebar selectedSite={selectedSite} title="Site config">
        <AdminSidebarConfigItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content">
        <TableToolbar
          title="Audit trail"
          subtitle={<TableToolbarSubtitle listLength={auditResp?.length || 0} download={() => downloadAuditList()} subtitle="Events" />}
        >
          <>
            {fromLightsPage && (
              <div className="audit-trail-from-lights">
                <div>
                  {`of ${JSON.parse(sessionStorage.getItem('auditSelectedNodes') || '0').length}`}
                  {' node(s) selected on the Lights page'}
                  {' '}
                  <Button
                    buttonType="secondary"
                    buttonSize="small"
                    label="Clear selection"
                    onClick={() => {
                      sessionStorage.removeItem('auditSelectedNodes');
                      setFromLightsPage(false);
                      fetchAuditData();
                    }}
                  />
                </div>
              </div>
            )}
          </>
          <div className="audit-trail-tabletoolbar">
            <div className="tabletoolbar__children">
              <div className="tabletoolbar__children-content" data-testid="openDateDropdown" onClick={() => setToggleDateDropdown(!toggleDateDropdown)}>
                <CalendarIcon />
                <div className="tabletoolbar__children-content--date">
                  <span className="tabletoolbar__children-content--date-start">Start</span>

                  <span className="tabletoolbar__children-content--date-startdate">{DateTime.fromISO(startDate.toISOString()).toFormat('MMMM dd, yyyy')}</span>
                  <span className="tabletoolbar__children-content--date-end">End</span>
                  <span className="tabletoolbar__children-content--date-enddate">{DateTime.fromISO(endDate.toISOString()).toFormat('MMMM dd, yyyy')}</span>
                </div>
              </div>
              {toggleDateDropdown && (
                <DropDown
                  primaryButtonLabel="Cancel"
                  primaryButtonAction={() => setToggleDateDropdown(false)}
                  primaryButtonType="primary"
                  secondaryButtonLabel="Submit"
                  secondaryButtonAction={async () => {
                    await fetchAuditData();
                    setToggleDateDropdown(false);
                  }}
                  secondaryButtonType="secondary"
                >
                  <>
                    <Datepicker
                      placement="bottom"
                      selectedDate={startDate}
                      setSelectedDate={setStartDate}
                      placeholder="Select start date"
                      label="Start date"
                      labelPlacement="above"
                      popperPosition="inline"
                      type="dark"
                      maxDate={endDate}
                    />
                    <Datepicker
                      placement="bottom"
                      selectedDate={endDate}
                      setSelectedDate={setEndDate}
                      label="End date"
                      labelPlacement="above"
                      popperPosition="inline"
                      type="dark"
                      minDate={startDate}
                      maxDate={new Date()}
                    />
                  </>
                </DropDown>
              )}
            </div>
          </div>
        </TableToolbar>
        <div className="table table--light top-border-light auto-height">
          <Table
            headers={headers}
            data={auditResp}
            setFilteredTableData={setFilteredTableData}
            dataFetchedAlready={dataFetchedAlready}
            autoheight
            skipCellMeasure
          />
        </div>
      </div>
      {showModal && (
        <AuditActions
          selectedRecordIdItems={selectedRecordIdItems}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
}

export default AuditLogSettings;
