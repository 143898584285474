import React from 'react';
import { HeaderDropDownPropsType } from '../../../types/HeaderDropDownPropsType';

function HeaderDropDown(props: HeaderDropDownPropsType): JSX.Element {
  const { children, icon, link, path = '', order = undefined, toggle = null, setToggle = () => undefined, last = false, addStyle = {}, external = false } = props;
  const isOpen = order === toggle ? 'open' : 'hide';
  const isSelected = path.startsWith(link) ? 'selected' : '';
  const isLast = last ? 'last' : '';

  return (
    <div className={`header__dropdown header__links-item ${isSelected} ${isOpen} ${isLast}`}>
      <button
        data-testid="header__links-item-btn"
        type="button"
        className="header__dropdown-button"
        style={addStyle}
        onClick={() => {
          if (external) {
            window.open(link, '_blank')?.focus();
          } else {
            setToggle(order === toggle ? null : order);
          }
        }}
      >
        { icon }
      </button>
      <div className="header__dropdown-list">
        { children }
      </div>
    </div>
  );
}

export default HeaderDropDown;
