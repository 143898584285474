import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import PowerDrawGraph from '../../../../../Common/Components/PowerDrawGraph';

import { ViewFixtureProps, ViewFixtureStateProp } from '../../../../../types/ViewFixtureProps';

import Textbox from '../../../../../Common/Components/Textbox';

function ViewFixture(props: ViewFixtureProps): JSX.Element {
  const { modalOpen, fixtureDetails } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewFixture, setViewFixture] = useState<ViewFixtureStateProp>({
    name: fixtureDetails?.name || '',
    PowerDraw: fixtureDetails?.PowerDraw || '',
    description: fixtureDetails?.description || '',
    fixtureType: { title: fixtureDetails?.fixtureType, key: fixtureDetails?.fixtureType } || { title: '', key: '' },
    MaxPower0: fixtureDetails?.MaxPower0 || '',
    MaxPower10: fixtureDetails?.MaxPower10 || '',
    MaxPower50: fixtureDetails?.MaxPower50 || '',
    MaxPower80: fixtureDetails?.MaxPower80 || '',
    MaxPower100: fixtureDetails?.MaxPower100 || '',
    MinPower0: fixtureDetails?.MinPower0 || '',
    MinPower10: fixtureDetails?.MinPower10 || '',
    MinPower50: fixtureDetails?.MinPower50 || '',
    MinPower80: fixtureDetails?.MinPower80 || '',
    MinPower100: fixtureDetails?.MinPower100 || '',
    manufacturer: fixtureDetails?.manufacturer || '',
    manufacturersku: fixtureDetails?.manufacturersku || '',
    nemasocket: fixtureDetails?.nemasocket || 'Yes',
  });

  return (
    <div className="fixture-interactions view-fixture">
      <Modal
        setModalOpen={modalOpen}
        primaryButtonLabel="Close"
        primaryButtonAction={() => modalOpen(false)}
        width="854"
        height="520"
      >
        <div className="modal-divided">
          <div className="modal-divided__leftcontent">
            <div className="modal-divided__content-data">
              <div className="viewFixture-title"> Fixture details </div>
              <Textbox
                label="Name"
                value={fixtureDetails?.name || '--'}
                placeholder={fixtureDetails?.name}
                disabled
              />
              <Textbox
                label="Description"
                value={fixtureDetails?.description || '--'}
                placeholder={fixtureDetails?.description}
                disabled
              />
              <Textbox
                label="Fixture type"
                value={fixtureDetails?.type || '--'}
                placeholder={fixtureDetails?.type}
                disabled
              />
              <Textbox
                label="Wattage"
                value={`${fixtureDetails?.powerDraw}W` || '--'}
                placeholder={`${fixtureDetails?.powerDraw}W`}
                disabled
              />
              <Textbox
                label="NEMA socket"
                value={fixtureDetails?.nemaSocket || '--'}
                placeholder={fixtureDetails?.nemaSocket}
                disabled
              />
              <Textbox
                label="Manufacturer"
                value={fixtureDetails?.manufacturer || '--'}
                placeholder={fixtureDetails?.manufacturer}
                disabled
              />
              <Textbox
                label="SKU"
                value={fixtureDetails?.manufacturerSku || '--'}
                placeholder={fixtureDetails?.manufacturerSku}
                disabled
              />
            </div>
          </div>
          <div className="modal-divided__rightcontent">
            <div className="viewFixture-title"> Power draw profile </div>
            <PowerDrawGraph
              className="powerdraw-chart"
              rawDatasets={fixtureDetails}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ViewFixture;
