/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartDataSets } from 'chart.js';
import { DateTime } from 'luxon';
import React from 'react';
import { ReactComponent as LightsOffIcon } from '../img/icons/lights-off.svg';
import { ReactComponent as LightsOn100Icon } from '../img/icons/lights-on-100.svg';
import { ReactComponent as LightsOn25Icon } from '../img/icons/lights-on-25.svg';
import { ReactComponent as LightsOn50Icon } from '../img/icons/lights-on-50.svg';
import { ReactComponent as LightsOn75Icon } from '../img/icons/lights-on-75.svg';
import { AddNotificationProps } from '../types/AppContext';
import { GenericSensorData, GenericSensorTableData, StaticSensorValue } from '../types/GenericSensorData';
import { NodeObject } from '../types/NodeObject';
import { SelectedCustomer, SelectedSite } from '../types/PageComponentProps';
import { SelectBoxItemType } from '../types/SelectBoxPropsType';
import { BulkSensorReponse, BulkSensorRequestPayload, RealTimeSensorRequestPayload, SensorDatapoint, SensorHistories, SensorRequestParams, SensorResponseData } from '../types/SensorRequestData';
import { SensorReportResponse, SensorReportResponseItem, SensorRequestPayload } from '../types/SensorRequestPayload.d';
import { postRequest } from './fetch';
import Utils from './Utils';

class Sensors {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  static createChartPayload(
    startDate: DateTime,
    endDate: DateTime,
    selectedItems: Map<string, NodeObject>,
    sensorName1: string,
    sensorName2: string,
    timezone: string,
  ): SensorRequestPayload {
    const startDateFormatted = startDate.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    const endDateFormatted = endDate.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    return {
      nodeIds: Array.from(selectedItems).map(([, node]) => node.nodeid),
      start: startDateFormatted,
      end: endDateFormatted,
      timezone,
      sensorIds:
        Array.from(
          new Set([
            Sensors.getSensorId(sensorName1),
            Sensors.getSensorId(sensorName2),
          ]),
        ).filter((sensorId) => sensorId.length > 0),
    };
  }

  static getChartDataFromResults(
    selectedSite: SelectedSite,
    results: SensorReportResponse,
    sensor1Key: string,
    selectedNodes: Map<string, NodeObject>,
  ): ChartDataSets[] {
    const borderDash = [5, 5];
    return results?.reports
      .map((sensorData, index) => {
        const isFirstSensor = sensor1Key === Sensors.getSensorName(sensorData.sensorId);
        return {
          label: sensorData.nodeId,
          nodeName: selectedNodes.get(sensorData.nodeId)?.name || '',
          yAxisID: Sensors.getSensorName(sensorData.sensorId),
          sensorLabel: Sensors.getSensorTitleById(sensorData.sensorId),
          sensorId: sensorData.sensorId,
          data: sensorData.data.map((dp) => ({ x: Utils.getSensorConvertedDate(dp.timestamp, selectedSite?.timezone || 'America/New_York'), y: Utils.getConvertedValue(sensorData.sensorId, dp.value) })),
          pointStyle: isFirstSensor ? 'circle' : 'rectRot',
          fill: false,
          steppedLine: 'before',
          pointRadius: 4,
          pointHoverRadius: 4,
          borderWidth: 2,
          borderDash: isFirstSensor ? [0, 0] : borderDash,
        };
      });
  }

  static getSensorTableWithDisplayedString(
    selectedItems: Map<string, NodeObject>,
    reqSensors: string[],
    data: SensorResponseData,
    timezone?: string,
    staticFields?: { [index: string]: StaticSensorValue },
    originalData?: GenericSensorTableData[],
  ): GenericSensorTableData[] {
    const tmpOriginalData = originalData?.map((item) => {
      const res = { nodeid: item.nodeid } as GenericSensorData;
      Object.keys(item).forEach((key) => {
        if (key !== 'nodeid') {
          res[key] = { value: item[key] };
        }
      });
      return res;
    });
    return this.getSensorTableDataWithValueAndSensor(selectedItems, reqSensors, data, timezone, tmpOriginalData, staticFields)
      .map((item) => {
        const res: GenericSensorTableData = { nodeid: item.nodeid } as GenericSensorTableData;
        Object.keys(item).forEach((key) => {
          if (key !== 'nodeid') {
            res[key] = `${item[key].value} ${item[key].unit ? item[key].unit : ''}`;
          }
        });
        return res;
      });
  }

  static getSensorTableDataWithValueAndSensor(
    selectedItems: Map<string, NodeObject>,
    reqSensors: string[],
    data: SensorResponseData,
    timezone?: string,
    originalData?: GenericSensorData[],
    staticFields: { [index: string]: StaticSensorValue } = {},
  ): GenericSensorData[] {
    const newData: GenericSensorData[] = [];
    const percent = 100;
    selectedItems.forEach((node) => {
      const originalValue = originalData && originalData.find((i) => i.nodeid === node.nodeid);
      const nodeSensorData = reqSensors
        .reduce(
          (o, key) => ({ ...{ [key]: { value: '--' } }, ...o }),
          originalValue || { nodeid: node.nodeid },
        ) as GenericSensorData;

      if (data && data.sensorHistories) {
        data.sensorHistories?.forEach((item) => {
          if (item.nodeId === node.nodeid && item.data.length > 0) {
            const dataBySensorId = reqSensors.length === 1 ? { [Sensors.getSensorId(reqSensors[0])]: item.data } : Sensors.groupBySensorId(item);
            Object.keys(dataBySensorId).forEach((key) => {
              if (dataBySensorId[key] && dataBySensorId[key].length) {
                const sensorName: string = Sensors.getSensorName(key);
                const sensorUnit = Sensors.getSensorUnit(sensorName);
                const dataItem = dataBySensorId[key].sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())[0];
                const datapoint = Sensors.getFirstSensorDatapoint(dataItem, Sensors.getSensorConversion(sensorName));
                const time = Utils.getSensorConvertedDate(datapoint.timestamp, timezone || 'America/New_York');
                let convertedSensorUnitObj = Sensors.getConvertedUnits(datapoint.value, sensorUnit);
                if (sensorName === 'modemBootCount') {
                  convertedSensorUnitObj = {
                    value: parseFloat(datapoint.value).toString(),
                    unit: '',
                  };
                }
                if (sensorName === 'powerFactor') {
                  convertedSensorUnitObj = {
                    value: Math.round(datapoint.value * percent).toString(),
                    unit: '%',
                  };
                }
                if (sensorName === 'driverLevel') {
                  convertedSensorUnitObj = {
                    value: Math.round(parseFloat(datapoint.value)).toString(),
                    unit: '%',
                  };
                  if (reqSensors.includes('lastDriverLevelReport')) {
                    nodeSensorData.lastDriverLevelReport = { value: datapoint.timestamp, unit: '', time };
                  }
                }

                if (!originalValue
                  || !originalValue[sensorName]?.time
                  || DateTime.fromJSDate(new Date(originalValue[sensorName].time as string)) < DateTime.fromJSDate(new Date(time))
                ) {
                  nodeSensorData[sensorName] = { ...convertedSensorUnitObj, time };
                }
              }
            });
          }
        });
      }

      const newStaticFields = { ...staticFields };

      Object.keys(staticFields).forEach((staticField) => {
        if ((node as any)[staticField] !== undefined) {
          const conversionFn = staticFields[staticField].conversion || ((val) => val);
          newStaticFields[staticField] = { value: conversionFn((node as any)[staticField]) };
        }
      });

      newData.push({ ...nodeSensorData, ...newStaticFields });
    });
    return newData;
  }

  private static groupBySensorId(item: { nodeId: string; message: string; data: { sensorId: string; timestamp: string; value: number; }[]; }):
  { [index: string]: { timestamp: string; value: number; }[]; } {
    return item.data.reduce<{ [index: string]: { timestamp: string; value: number; }[]; }>((acc, nextItem) => {
      if (acc[nextItem.sensorId]) {
        acc[nextItem.sensorId].push({ value: nextItem.value, timestamp: nextItem.timestamp });
      } else {
        acc[nextItem.sensorId] = [{ value: nextItem.value, timestamp: nextItem.timestamp }];
      }
      return acc;
    }, {} as { [index: string]: { timestamp: string; value: number; }[]; });
  }

  static getSensorTriggerUrl(params: { customerId: string, siteId: string }): string {
    return `/organizations/${params.customerId}/sites/${params.siteId}/sensors/retrieve/trigger`;
  }

  static getSensorDataUrl(params: { customerId: string, siteId: string }): string {
    return `/organizations/${params.customerId}/sites/${params.siteId}/sensors/retrieve/read`;
  }

  static getSensorPayload({ nodeIds, sensorIds }:
    SensorRequestParams): RealTimeSensorRequestPayload {
    return {
      nodeIds,
      sensorIds,
    };
  }

  static getSensorPayloadForDriverLevel({ nodeIds, sensorIds }:
    SensorRequestParams): RealTimeSensorRequestPayload {
    return {
      nodeIds,
      sensorIds,
    };
  }

  static async sensorDataFetcherFn(
    siteId: string,
    orgId: string,
    selectedNodes: NodeObject[],
  ): Promise<SensorResponseData> {
    const url = `/organizations/${orgId}/sites/${siteId}/sensors/latest`;
    const payloadParams = {
      nodeIds: selectedNodes.map((nodeobj) => nodeobj.nodeid),
    } as BulkSensorRequestPayload;

    const response = await postRequest<BulkSensorRequestPayload, BulkSensorReponse>(url, payloadParams);
    const sensorHistories: SensorHistories[] = [];

    response.data?.data?.forEach((d) => {
      const sensorHistory: SensorHistories = {
        nodeId: d.nodeId,
        message: d.message,
        data: d.data[0].data,
      };

      sensorHistories.push(sensorHistory);
    });

    return {
      sensorHistories,
    };
  }

  static async retrySensorDataFetcher(
    siteId: string,
    orgId: string,
    selectedNodes: NodeObject[],
    sensorIds: string[],
  ): Promise<SensorResponseData> {
    const payloadParams = {
      nodeIds: selectedNodes.map((nodeobj) => nodeobj.nodeid),
      sensorIds,
    } as SensorRequestParams;

    // trigger retrieval from device
    await postRequest<RealTimeSensorRequestPayload, SensorResponseData>(
      Sensors.getSensorTriggerUrl({
        customerId: orgId,
        siteId,
      }),
      Sensors.getSensorPayload({
        ...payloadParams,
      }),
    );

    // poll 5 times sequentially
    let finalResponse: SensorResponseData = { sensorHistories: [] };

    for (let attempt = 0; attempt < 8; attempt += 1) {
      // eslint-disable-next-line no-await-in-loop
      const resp = await postRequest<RealTimeSensorRequestPayload, SensorResponseData>(
        Sensors.getSensorDataUrl({
          customerId: orgId,
          siteId,
        }),
        Sensors.getSensorPayload(payloadParams),
      );
      if (!resp.error) {
        const sensorsLength = sensorIds.filter((sensor) => sensor !== '').length;
        const nodeHasAllSensorData = resp.data?.sensorHistories?.filter((dataForNode) => dataForNode.data.length === sensorsLength).length === payloadParams.nodeIds.length;
        if (nodeHasAllSensorData) {
          finalResponse = resp.data;
        }
      }
    }

    return finalResponse;
  }

  static getFirstSensorDatapoint(
    datapoint: { timestamp: string, value: number | string } | undefined,
    conversion: (val: any) => any = (val) => val,
    fixedNumber = 2,
  ): SensorDatapoint {
    return {
      timestamp: datapoint?.timestamp || '--',
      value: datapoint?.value !== undefined ? Number(conversion(datapoint.value)).toFixed(fixedNumber).toString() : '--',
    };
  }

  static fetchAndDisplayChartData(
    startDate: Date,
    endDate: Date,
    selectedCustomer: SelectedCustomer,
    selectedSite: SelectedSite,
    selectedItems: Map<string, NodeObject>,
    sensor1: SelectBoxItemType,
    sensor2: SelectBoxItemType,
    setChartDataset: React.Dispatch<React.SetStateAction<ChartDataSets[] | undefined>>,
    setRawChartDataset: React.Dispatch<React.SetStateAction<SensorReportResponseItem[] | undefined>>,
    setChartModalToggle: (newVal: boolean) => void,
    setWaiting: React.Dispatch<React.SetStateAction<boolean>>,
    addNotification: (item: AddNotificationProps) => void,
  ): void {
    const maxDayDiff = 30;
    const maxDataPoints = 5000;

    const dayDiff = Math.ceil(DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), ['days']).toObject().days || 0) + 1;
    if (dayDiff > maxDayDiff) {
      addNotification({ type: 'warning', message: 'Please select maximum 30 days difference between both the dates.' });
    } else if (sensor1.key === '0' && sensor2.key === '0') {
      addNotification({ type: 'warning', message: 'Please select at least one Sensor.' });
    } else {
      const timezone = selectedSite.timezone || 'America/Los_Angeles';

      const startOfTheDay = DateTime.fromISO(startDate.toISOString(), { zone: timezone, setZone: true });
      const endOfTheDay = DateTime.fromISO(endDate.toISOString(), { zone: timezone, setZone: true });

      setChartDataset([]);
      setRawChartDataset([]);
      setChartModalToggle(true);
      setWaiting(true);
      const payload = Sensors.createChartPayload(
        startOfTheDay,
        endOfTheDay,
        selectedItems,
        sensor1.key,
        sensor2.key,
        timezone,
      );

      postRequest<SensorRequestPayload, SensorReportResponse[] | { looker_error?: string }>(`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/sensors/sensors-dashboard`, payload)
        .then(
          (response) => {
            if (response.status >= 400 || response.error) {
              addNotification({ type: 'error', message: response.error });
              setWaiting(false);
            } else {
              const finalResult = Sensors.getChartDataFromResults(
                selectedSite,
                response.data as unknown as SensorReportResponse,
                sensor1.key,
                selectedItems,
              );
              if (finalResult?.map(((i) => i.data)).flat().length > maxDataPoints) {
                addNotification({ type: 'info', message: 'Too many data points. Try reducing the time span or number of nodes.' });
                setChartDataset([]);
                setRawChartDataset([]);
                setWaiting(false);
              } else {
                setChartDataset(finalResult);
                setRawChartDataset(response.data as unknown as SensorReportResponseItem[]);
                setWaiting(false);
              }
            }
          },
          (error) => {
            addNotification({ type: 'error', message: 'An internal error occurred and sensor data cannot be retrieved at this time.' });
            setChartDataset([]);
            setRawChartDataset([]);
            setWaiting(false);
          },
        );
    }
  }

  static getSensorName(sensorId: string): string {
    switch (sensorId) {
      case 'lt':
        return 'driverLevel';
      case 'mP':
      case 'mp':
      case 'P':
        return 'activePower';
      case 'mw':
      case 'w':
        return 'cumulativeEnergyUse';
      case 'l-i':
      case 'l':
        return 'ambientLight';
      case 'sinr':
        return 'sinr';
      case 'RF':
      case 'rf':
        return 'radioSignalStrength';
      case 'rsrq':
        return 'linkQuality';
      case 'mi':
        return 'mainsCurrent';
      case 'mPr':
      case 'mpr':
        return 'reactivePower';
      case 'v':
        return 'mainsVoltage';
      case 'mwr':
        return 'cumulativeReactiveEnergyUse';
      case 'BHL':
      case 'bhl':
        return 'cumulativeOnStateLuminaire';
      case 'BHN':
        return 'cumulativeOnStateNode';
      case 'T':
        return 'internalTemperature';
      case 'mPF':
      case 'mpf':
      case 'PF':
        return 'powerFactor';
      case 'cellid':
        return 'cellId';
      case 'bc':
        return 'nodePowerCycleCount';
      case 'rlym':
        return 'relayCycleCount';
      case 'mdmtm':
        return 'modemUptime';
      case 'wbcnt':
        return 'modemBootCount';
      case 'rcnt':
        return 'apFsRestoreCount';
      default: break;
    }

    return '';
  }

  static getSensorUnit(sensorName: string): string {
    switch (sensorName) {
      case 'driverLevel':
        return '%';
      case 'activePower':
        return 'W';
      case 'cumulativeEnergyUse':
        return 'kWh';
      case 'ambientLight':
        return 'lux';
      case 'sinr':
        return 'dB';
      case 'radioSignalStrength':
        return 'dBm';
      case 'linkQuality':
        return 'dB';
      case 'mainsVoltage':
        return 'V';
      case 'mainsCurrent':
        return 'A';
      case 'reactivePower':
        return 'VAR';
      case 'cumulativeReactiveEnergyUse':
        return 'VARh';
      case 'cumulativeOnStateLuminaire':
        return 'h';
      case 'internalTemperature':
        return 'degF';
      case 'powerFactor':
        return '';
      case 'cellId':
        return '';
      case 'nodePowerCycleCount':
        return '';
      case 'relayCycleCount':
        return '';
      case 'modemUptime':
        return 's';
      case 'modemBootCount':
        return '';
      case 'apFsRestoreCount':
        return '';
      default: break;
    }

    return '';
  }

  static getRoundValue(value: string | undefined): string {
    return ((!value && value !== '0') || Number.isNaN(parseFloat(value))) ? '--' : Math.round(parseFloat(value)).toString();
  }

  static getConvertedUnits(value: number | string, unit: string): { value: string; unit: string; } {
    const rounding = 1000;
    const toFixed = 2;
    const val = Number(value);
    if (!Number.isNaN(val)) {
      const needToConvert = val >= rounding;
      const convertedValue = needToConvert ? (val / rounding).toFixed(toFixed) : val.toString();

      if (unit === 'A') {
        return {
          value: convertedValue,
          unit: needToConvert ? 'mA' : 'A',
        };
      }

      if (unit === 'kWh') {
        return {
          value: convertedValue,
          unit: needToConvert ? 'MWh' : 'kWh',
        };
      }
    }

    return { value: value?.toString() || '--', unit };
  }

  static getSensorConversion(sensorName: string): any {
    const dbConv1 = 2;
    const dbConv2 = 200;
    const dbConv3 = 10;
    const roundTo = 10;
    const round = 1000;
    const secondsInHour = 3600;

    switch (sensorName) {
      case 'sinr':
        return (val: any): string => {
          if (Number.isNaN(val)) {
            return val;
          }

          const dbval = (val * dbConv1 - dbConv2) / dbConv3;
          return (Math.round(dbval * roundTo) / roundTo).toString();
        };
      case 'cumulativeEnergyUse':
        return (val: number) => (val / round);
      case 'cumulativeOnStateLuminaire':
        return (val: number) => (val / secondsInHour);
      case 'cumulativeOnStateNode':
        return (val: number) => (val / secondsInHour);

      default: break;
    }

    return undefined;
  }

  static getSensorTitleById = (sensorId: string): string =>
    Sensors.getSensorName(sensorId);

  static getSensorTitleByName = (sensorId: string): string => {
    switch (sensorId) {
      case 'driverLevel':
        return 'Driver level';
      case 'ambientLight':
        return 'Ambient light level';
      case 'radioSignalStrength':
        return 'Radio signal strength';
      case 'linkQuality':
        return 'Link quality';
      case 'sinr':
        return 'SINR';
      case 'mainsCurrent':
        return 'Mains current';
      case 'activePower':
        return 'Active power';
      case 'mainsVoltage':
        return 'Mains voltage';
      case 'reactivePower':
        return 'Reactive power';
      case 'cumulativeEnergyUse':
        return 'Active energy';
      case 'cumulativeReactiveEnergyUse':
        return 'Reactive energy';
      case 'cumulativeOnStateLuminaire':
        return 'Fixture burn hours';
      case 'cumulativeOnStateNode':
        return 'Node burn hours';
      case 'powerFactor':
        return 'Power factor';
      case 'internalTemperature':
        return 'Internal temperature';
      case 'cellId':
        return 'Cell ID';
      case 'nodePowerCycleCount':
        return 'Node power cycle count';
      case 'relayCycleCount':
        return 'Relay cycle Count';
      case 'modemUptime':
        return 'Modem uptime';
      case 'modemBootCount':
        return 'Modem Boot Count';
      case 'apFsRestoreCount':
        return 'AP FS restore count';
      default:
        return '';
    }
  };

  static getSensorId(type: string): string {
    switch (type) {
      case 'driverLevel':
        return 'lt';
      case 'activePower':
        return 'mP';
      case 'cumulativeEnergyUse':
        return 'mw';
      case 'ambientLight':
        return 'l-i';
      case 'sinr':
        return 'sinr';
      case 'radioSignalStrength':
        return 'RF';
      case 'mainsCurrent':
        return 'mi';
      case 'mainsVoltage':
        return 'v';
      case 'reactivePower':
        return 'mPr';
      case 'cumulativeReactiveEnergyUse':
        return 'mwr';
      case 'linkQuality':
        return 'rsrq';
      case 'cumulativeOnStateLuminaire':
        return 'BHL';
      case 'cumulativeOnStateNode':
        return 'BHN';
      case 'powerFactor':
        return 'mPF';
      case 'internalTemperature':
        return 'T';
      case 'cellId':
        return 'cellid';
      case 'nodePowerCycleCount':
        return 'bc';
      case 'relayCycleCount':
        return 'rlym';
      case 'modemUptime':
        return 'mdmtm';
      case 'modemBootCount':
        return 'wbcnt';
      case 'apFsRestoreCount':
        return 'rcnt';
      default:
        break;
    }

    return '';
  }

  static getLightsOnIcon(val: number): JSX.Element {
    const icon100 = 100;
    const icon75 = 75;
    const icon50 = 50;
    const icon25 = 25;

    if (val === icon100) {
      return <LightsOn100Icon />;
    }

    if (val > icon75) {
      return <LightsOn75Icon />;
    }

    if (val > icon50) {
      return <LightsOn50Icon />;
    }

    if (val > icon25) {
      return <LightsOn25Icon />;
    }

    return <LightsOffIcon />;
  }
}

export default Sensors;
