import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import Textbox from '../../../../Common/Components/Textbox';
import { GroupActionModalProps } from '../../../../types/GroupActionsPropTypes';
import { postRequest } from '../../../../utils/fetch';
import formValidation from '../../../../utils/form/formValidation';

import { ReactComponent as ScrollRightIcon } from '../../../../img/icons/scroll-right.svg';

function CreateOrgGroupWithNodes(props: GroupActionModalProps): JSX.Element {
  const { setOpenModal, selectedNodes, selectedCustomer, selectedSite, addNotification, updateGroup } = props;
  const [putNodesOrgGroup, setPutNodesOrgGroup] = useState({
    name: '',
    description: '',
    hasError: false,
    errorMessage: '',
  });

  const validationRules = { required: true, min: 2, max: 256, noSpecCharacters: true };
  function checkNameValidation(newName?: string) {
    const name = newName ?? putNodesOrgGroup.name;
    const validation = formValidation(
      name,
      validationRules,
    );
    setPutNodesOrgGroup((oldValues) => ({
      ...oldValues,
      name,
      hasError: validation.hasError,
      errorMessage: validation.errorMsg,
    }));
  }
  const nodesSelectedSize = (selectedNodes.size <= 10000) ? `${selectedNodes.size}` : 'First 10000';
  return (
    <Modal
      title="Create org group with nodes"
      width="528"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        if (!putNodesOrgGroup.name) {
          checkNameValidation();
        } else if (!putNodesOrgGroup.hasError) {
          try {
            const postBody = {
              operation: 'CREATE_GROUP',
              name: putNodesOrgGroup.name,
              description: putNodesOrgGroup.description,
              nodeIds: (selectedNodes.size <= 10000) ? Array.from(selectedNodes.keys()) : Array.from(selectedNodes.keys()).splice(0, 10000),
              type: 'ORGANIZATIONAL',
              nodeType: 'LSN',
              scheduleId: null,
            };
            if (postBody.nodeIds.length === 0) {
              addNotification({ type: 'error', message: 'Your "Create org group with nodes" operation has failed: Node List is empty, it must contain at least one element. Please try again.' });
              setOpenModal(0);
            } else {
              const result = await postRequest(
                `/organizations/${selectedCustomer?.id}/sites/${selectedSite?.id}/async-jobs/group-move`,
                postBody,
              );
              if (!result.error) {
                addNotification({ type: 'success', message: 'Your "Create org group with nodes" operation is completed.' });
                updateGroup();
                setOpenModal(0);
              } else {
                addNotification({ type: 'error', message: `Your "Create org group with nodes" operation has failed: ${result.error}` });
              }
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Create org group with nodes" operation has failed.' });
          }
        }
      }}
    >
      <div className="group-actions__modal-container">
        <div className="content">
          <div className="content__container">
            <div className="content__container-nodes-size">
              <div className="content__container-nodes-selected">
                <span>{nodesSelectedSize}</span>
                Nodes selected
              </div>
              <div className="margin-top-15">
                <ScrollRightIcon />
              </div>
            </div>
            <div className="content__container-form">
              <Textbox
                label="Org group name"
                placeholder="Enter name"
                value={putNodesOrgGroup.name}
                onChange={(event) => {
                  const name = event.target.value;
                  checkNameValidation(name);
                }}
                isRequired
                error={putNodesOrgGroup.hasError}
                errorMessage={putNodesOrgGroup.errorMessage}
              />
              <Textbox
                label="Description"
                placeholder="Enter description"
                value={putNodesOrgGroup.description}
                onChange={(event) =>
                  setPutNodesOrgGroup((oldVal) =>
                    ({ ...oldVal, description: event.target.value }))}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateOrgGroupWithNodes;
