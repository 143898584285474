import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import Table from '../../../Common/Components/Table';
import TableToolbar from '../../Admin/Pages/Components/TableToolbar';
import getHeaderProps from '../../../utils/getHeaderProps';
import { useAppContext } from '../../../utils/AppContext';
import Loading from '../../../Common/Components/Loading';
import Button from '../../../Common/Components/Button';
import { SiteObject } from '../../../types/SiteObject';
import { ReportObject } from '../../../types/ReportObject';
import Utils from '../../../utils/Utils';
import { ReportsListProps } from '../../../types/ReportsListProps';
import ViewReportDetails from './ViewReportDetails';
import { getRequest } from '../../../utils/fetch';
import { reportTypeTitles } from '../../../utils/constants';

function ReportsList(props: ReportsListProps): JSX.Element {
  const { reports, reportType, selectedCustomer, selectedSite, setListReportDefinitions } = props;

  const { addNotification } = useAppContext();
  const isNonReadOnly = Utils.isNonReadOnly();
  const reportsHeaders = getHeaderProps('Reports');
  const [reportsListItems, setReportsListItems] = useState<ReportObject[]>();

  const [openModal, setModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportObject>();
  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(selectedCustomer.id ? [`/organizations/${selectedCustomer.id}/sites`, 'GetSites'] : null);
  const site = useMemo(() => sitesResp?.find((item) => item.siteid === selectedSite?.id), [sitesResp, selectedSite?.id]);
  useEffect(() => {
    if (reports) {
      const allReportItems = reports.filter((rpt, i) =>
        rpt.reportType === reportType).map((item) => {
        const tableItem: ReportObject = { ...item,
          reportDate: Utils.convertISOtoAlarmTime2(item.reportDate, site ? site.time_zone : 'utc'),
          startDate: Utils.convertISOtoAlarmTime2(item.startDate, site ? site.time_zone : 'utc'),
          endDate: Utils.convertISOtoAlarmTime2(item.endDate, site ? site.time_zone : 'utc'),
        };
        return tableItem;
      });

      setReportsListItems(allReportItems);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNonReadOnly, reportType, site]);
  return (
    <>
      <TableToolbar
        title={`${reportTypeTitles[reportType]} Reports`}
        addClass="btn-container"
      >
        <>
          {isNonReadOnly && (
            <div className="tabletoolbar__children">
              <Button
                onClick={() => {
                  setListReportDefinitions(true);
                }}
                label={`Create new ${reportTypeTitles[reportType]} report`}
                buttonType="primary"
              />
            </div>
          )}
        </>
      </TableToolbar>
      {reports
        ? (
          <div className="table table--light system-alerts auto-height">
            <Table
              headers={reportsHeaders}
              data={reportsListItems}
              skipCellMeasure
              cellOnClickColumns={['reportName', 'reportAccount', 'reportSite', 'reportDate', 'reportStatus', 'startDate', 'endDate']}
              cellOnClick={async (report: ReportObject) => {
                try {
                  const result = await getRequest(`/organizations/${selectedCustomer?.id}/reports/${report.reportId}`);
                  if (!result.error) {
                    result.reportDate = Utils.convertISOtoAlarmTime2(result.reportDate, site ? site.time_zone : 'utc');
                    result.startDate = Utils.convertISOtoAlarmTime2(result.startDate, site ? site.time_zone : 'utc');
                    result.endDate = Utils.convertISOtoAlarmTime2(result.endDate, site ? site.time_zone : 'utc');
                    setSelectedReport(result);
                    setModalOpen(true);
                  } else {
                    addNotification({ type: 'error', message: 'An error occurred while accessing the report details.' });
                  }
                } catch (e) {
                  addNotification({ type: 'error', message: 'An error occurred while accessing the report details.' });
                }
              }}
              autoheight
            />
          </div>
        ) : (
          <Loading />
        )}
      {openModal && selectedReport && (
        <ViewReportDetails
          setModalOpen={setModalOpen}
          reportDetails={selectedReport}
        />
      )}

    </>
  );
}

export default ReportsList;
