import React, { useState } from 'react';
import DropDown from '../../../Common/Components/DropDown';
import Button from '../../../Common/Components/Button';
import { AlarmsDurationPropsType } from '../../../types/AlarmsDuration';
import SelectBox from '../../../Common/Components/SelectBox';
import Textbox from '../../../Common/Components/Textbox';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import Utils from '../../../utils/Utils';

function AlarmsDuration(props: AlarmsDurationPropsType): JSX.Element {
  const {
    className,
    alarmsDurationSettings,
    handleActiveToolbar,
    setApplyAlarmsDuration,
    alarmTypeItems,
  } = props;

  const compareItems = [
    { key: '0', title: 'Any' },
    { key: '1', title: 'More than' },
    { key: '2', title: 'Less than' },
  ];

  const listWidthName = 240;
  const listWidthDuration = 106;

  const [valueType, setValueType] = useState<SelectBoxItemType>(
    Utils.valueToSelectBoxItem(alarmsDurationSettings.alarmType, alarmTypeItems),
  );
  const [valueCompare, setValueCompare] = useState<SelectBoxItemType>(
    Utils.valueToSelectBoxItem(alarmsDurationSettings.compare, compareItems),
  );

  const [valueDays, setValueDays] = useState<string>(alarmsDurationSettings.days);
  const [valueHours, setValueHours] = useState<string>(alarmsDurationSettings.hours);

  return (
    <DropDown
      className={className}
    >
      <div className="dropdown__content alarms-duration">
        <div className="alarms-duration__form-section">
          <SelectBox
            label="Alarm name"
            title={valueType.title}
            selectedItemKey={valueType.key}
            list={Utils.arrayToSelectbox(alarmTypeItems, 'key', 'title')}
            selectboxWidth={listWidthName}
            listWidth={listWidthName}
            onClick={(item: SelectBoxItemType) => {
              setValueType(item);
            }}
            type="dark"
          />
          <div className="alarms-duration__duration-line">
            <SelectBox
              label="Duration"
              title={valueCompare.title}
              selectedItemKey={valueCompare.key}
              list={Utils.arrayToSelectbox(compareItems, 'key', 'title')}
              listWidth={listWidthDuration}
              onClick={(item: SelectBoxItemType) => {
                setValueCompare(item);
              }}
              type="dark"
            />
            {valueCompare.title !== 'Any' && (
              <>
                <Textbox
                  label="Days"
                  name="Days"
                  styleType="dark"
                  value={valueDays}
                  onChange={(e) => { setValueDays(e.target.value); }}
                />
                <Textbox
                  label="Hrs"
                  name="Hrs"
                  styleType="dark"
                  value={valueHours}
                  onChange={(e) => { setValueHours(e.target.value); }}
                />
              </>
            )}
          </div>
        </div>
        <div className="jobs-filter__button-bar">
          <Button
            buttonType="primary"
            onClick={() => {
              handleActiveToolbar(0);
            }}
          >
            <span>Cancel</span>
          </Button>
          <Button
            buttonType="secondary"
            onClick={() => {
              setApplyAlarmsDuration({
                alarmType: valueType.title,
                compare: valueCompare.title,
                days: valueDays,
                hours: valueHours,
              });
              handleActiveToolbar(0);
            }}
          >
            <span>Apply</span>
          </Button>
        </div>
      </div>
    </DropDown>
  );
}

export default AlarmsDuration;
