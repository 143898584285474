import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { WizardReviewProps } from '../../../../../types/AlertActivityReportWizard';
import Textbox from '../../../../../Common/Components/Textbox';
import { basedOnAlertTypeList, basedOnSeverityList, dayOfWeekList, statusList } from '../../../../../utils/constants';
import Utils from '../../../../../utils/Utils';

function WizardReview(props: WizardReviewProps): JSX.Element {
  const { reportObject, setReportObject, filterBy } = props;

  const alertTypes = useMemo(() => basedOnAlertTypeList.filter(
    (alertType) => reportObject.filters.types?.find((item) => item === alertType.id),
  ).map((val) => val.name), [reportObject.filters.types]);

  const severities = useMemo(() => basedOnSeverityList.filter(
    (severity) => reportObject.filters.severities?.find((item) => item === severity.id),
  ).map((val) => val.name), [reportObject.filters.severities]);

  const statuses = useMemo(() => statusList.filter(
    (status) => reportObject.filters.statuses?.find((item) => item === status.id),
  ).map((val) => val.name), [reportObject.filters.statuses]);

  const updateReportName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setReportObject((origItem) => {
      const newItem = { ...origItem };
      newItem.name = event.target.value;
      return newItem;
    });
  }, [setReportObject]);

  return (
    <div className="wizard-review">
      <Textbox
        label="Report name"
        placeholder="Report name"
        value={reportObject.name}
        onChange={updateReportName}
        isRequired
      />
      <div className="report-summary-title">Report summary</div>
      <div className="report-summary-content">
        <div className="report-summary-content--left">
          Nodes
        </div>
        <div className="report-summary-content--right">
          {`${reportObject.filters.nodeIds?.length || 0} node${reportObject.filters.nodeIds && reportObject.filters.nodeIds?.length > 1 ? 's' : ''}`}
        </div>
        <div className="report-summary-content--left">
          {filterBy === 'alert_type' ? 'Alert types' : 'Severity'}
        </div>
        <div className={`report-summary-content--right ${filterBy === 'alert_type' ? 'alert-type' : ''}`}>
          {filterBy === 'alert_type'
            ? alertTypes.map((alertType) => <div key={alertType} className="chip-item">{alertType}</div>)
            : severities.join(', ')}
        </div>
        <div className="report-summary-content--left">
          Status
        </div>
        <div className="report-summary-content--right">
          {statuses.join(', ')}
        </div>
        <div className="report-summary-content--left">
          Report
        </div>
        <div className="report-summary-content--right">
          {reportObject.frequency === 'ONCE' ? 'One-time report' : 'Recurring report'}
        </div>
        <div className="report-summary-content--left">
          {reportObject.frequency === 'ONCE' ? 'Start/End date' : 'Schedule'}
        </div>
        <div className="report-summary-content--right">
          {reportObject.frequency === 'ONCE' && (`${Utils.getConvertedDate(reportObject.filters.raisedAfter)} - ${Utils.getConvertedDate(reportObject.filters.raisedBefore)}`)}
          {reportObject.frequency === 'DAILY' && ('Daily')}
          {reportObject.frequency === 'WEEKLY' && (`Weekly on ${dayOfWeekList.find((day) => day.key === reportObject.reportOnDay?.toString())?.title}`)}
          {reportObject.frequency === 'MONTHLY' && (`Monthly on ${Utils.addOrdinalSuffix(reportObject.reportOnDay)}`)}
        </div>
        <div className="report-summary-content--left">
          Recipients
        </div>
        <div className="report-summary-content--right recipients">
          {reportObject.recipients.map((recipient) => <div key={recipient.email} className="chip-item">{recipient.email}</div>)}
        </div>
      </div>
    </div>
  );
}

export default WizardReview;
