import React from 'react';
import { SubtoolbarLinkPropsType } from '../../../types/SubtoolbarLinkPropsType';

function SubtoolbarLink(props: SubtoolbarLinkPropsType): JSX.Element {
  const {
    title,
    children,
    changeActiveSubtoolbar,
    activeSubtoolbars,
    id,
  } = props;

  return (
    <div
      data-testid="subtoolbar-section"
      onClickCapture={changeActiveSubtoolbar}
      className={`toolbar__subtoolbar-section-item ${
        activeSubtoolbars.includes(id) ? 'active' : ''
      }`}
    >
      {children}
      <span className="title">{title}</span>
    </div>
  );
}

export default SubtoolbarLink;
