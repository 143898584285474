import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import AccountPartnerForm from './AccountPartnerForm';
import EditAccountModalCustomTitle from './EditAccountModalCustomTitle';
import { useAppContext } from '../../../../../utils/AppContext';
import { postRequest, putRequest } from '../../../../../utils/fetch';
import formValidation from '../../../../../utils/form/formValidation';
import { ModalType } from '../../../../../types/modal';
import { PartnerModalProps, PartnerPostProp } from '../../../../../types/AccountPartnerModal';

function PartnerModal(props: PartnerModalProps): JSX.Element {
  const { type, setModalOpen, partner, updateCustomers } = props;
  const [partnerData, setPartnerData] = useState({
    name: partner.name,
    type: partner.type || 'PARTNER',
    street1: partner.street1,
    street2: partner.street2,
    city: partner.city,
    state: partner.state,
    postal_code: partner.postal_code,
    country: partner.country,
    contact_email: partner.contact_email,
    contact_phone: partner.contact_phone,
    contact_name: partner.contact_name,
    vzids: [] as string[],
  });

  const [partnerValidation, setPartnerValidation] = useState({
    hasError: false,
    errorMessage: '',
  });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  let modalProps: ModalType = {
    secondaryButtonLabel: 'Cancel',
    setModalOpen: () => setModalOpen(0),
  };
  if (type === 'create') {
    modalProps = {
      ...modalProps,
      title: 'Create partner',
      primaryButtonLabel: 'Create',
      primaryButtonDisabled: submitDisabled,
      className: 'padding-30',
    };
  } else {
    modalProps = {
      ...modalProps,
      title: 'Edit partner',
      primaryButtonLabel: 'Save',
      customTitle: <EditAccountModalCustomTitle item={partner} title="Edit partner" addClass="remove-last-border partner-element custom-width" />,
      className: 'padding-30',
    };
  }

  const { addNotification } = useAppContext();

  function setName(newName?: string) {
    const name = newName !== undefined ? newName : partner.name;
    const validation = formValidation(
      name,
      { required: true, min: 2, max: 256, noSpecCharacters: true },
    );

    setPartnerData((oldValues) => ({
      ...oldValues,
      name,
    }));

    setPartnerValidation((oldValues) => ({
      ...oldValues,
      hasError: validation.hasError,
      errorMessage: validation.errorMsg,
    }));
  }

  return (
    <Modal
      width="550"
      {...modalProps}
      primaryButtonAction={async () => {
        if (partnerData.name === '') {
          setName(partnerData.name);
          return;
        }

        try {
          const postBody: PartnerPostProp = {
            name: partnerData.name,
            type: partnerData.type || 'PARTNER',
            street1: partnerData.street1,
            street2: partnerData.street2,
            city: partnerData.city,
            state: partnerData.state,
            postal_code: partnerData.postal_code,
            country: partnerData.country,
            contact_email: partnerData.contact_email,
            contact_phone: partnerData.contact_phone,
            contact_name: partnerData.contact_name,
            vzids: [],
          };

          let result;
          if (type === 'create') {
            setSubmitDisabled(true);
            result = await postRequest('/organizations', postBody);
          } else {
            result = await putRequest(`/organizations/${partner.orgid}`, postBody);
          }

          if (!result.error) {
            addNotification({ type: 'success', message: `Your ${modalProps.title} operation is completed.` });
            updateCustomers();
            setModalOpen(0);
          } else {
            setSubmitDisabled(false);
            addNotification({ type: 'error', message: `Your ${modalProps.title} operation has failed: ${result.error}` });
          }
        } catch (error) {
          setSubmitDisabled(false);
          addNotification({ type: 'error', message: `Your ${modalProps.title} operation has failed.` });
        }
      }}
    >
      <AccountPartnerForm
        data={partnerData}
        setPartner={setPartnerData}
        partnerValidation={partnerValidation}
      />
    </Modal>
  );
}

export default PartnerModal;
