import React from 'react';
import Modal from '../../../../Common/Components/Modal';
import { postRequest } from '../../../../utils/fetch';
import { LightsGroupsAddNodeToOrgGroupModalProps } from '../../../../types/LightsGroupProps';

function LightsGroupsAddNodeToOrgGroup(props: LightsGroupsAddNodeToOrgGroupModalProps): JSX.Element {
  const {
    setModalOpen,
    selectedNode,
    selectedCustomer,
    selectedSite,
    addNotification,
    updateGroup,
    addToOrgGroups,
  } = props;

  return (
    <Modal
      setModalOpen={() => setModalOpen(0)}
      width="330"
      title="Add node to org group"
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        try {
          const newNodeList = [selectedNode.nodeid];
          const postBody = {
            operation: 'ADD_NODES',
            targetGroupId: addToOrgGroups?.groupId,
            nodeIds: newNodeList,
          };

          const result = await postRequest(
            `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/async-jobs/group-move`,
            postBody,
          );

          if (!result.error) {
            addNotification({ type: 'success', message: 'Your "Add node to org group" operation is completed.' });
            if (updateGroup) {
              updateGroup();
            }
            setModalOpen(0);
          } else {
            addNotification({ type: 'error', message: `Your "Add node to org group" operation has failed: ${result.error}` });
          }
        } catch (e) {
          addNotification({ type: 'error', message: 'Your "Add node to org group" operation has failed.' });
        }
      }}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setModalOpen(0)}
    >
      <div>
        <div>
          Are you sure you want to add this node to org group
          <span className="group-name">
            {' '}
            { addToOrgGroups?.name }
            ?
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default LightsGroupsAddNodeToOrgGroup;
