import React, { useState } from 'react';
import DropDown from '../../../Common/Components/DropDown';
import { JobsSortPropsType } from '../../../types/JobsSort';
import SelectBox from '../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import Utils from '../../../utils/Utils';

function JobsSort(props: JobsSortPropsType): JSX.Element {
  const {
    className,
    jobSortSettings,
    handleActiveToolbar,
    setApplyJobSort,
  } = props;

  const jobSortItems = [
    { title: 'Start date', key: '0' },
    { title: 'End date', key: '1' },
    { title: 'User', key: '2' },
    { title: 'Firmware version', key: '3' },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [valueSort, setValueSort] = useState<SelectBoxItemType>(
    Utils.valueToSelectBoxItem(jobSortSettings.primary, jobSortItems),
  );

  const listWidth = 228;

  return (
    <DropDown
      className={className}
    >
      <div className="dropdown__content jobs-sort">
        <SelectBox
          title={valueSort.title}
          initialOpen
          selectedItemKey={valueSort.key}
          list={Utils.arrayToSelectbox(jobSortItems, 'key', 'title')}
          listWidth={listWidth}
          onClick={(item: SelectBoxItemType) => {
            setApplyJobSort({
              primary: item.title,
            });
            handleActiveToolbar(0);
          }}
          type="dark"
        />
      </div>
    </DropDown>
  );
}

export default JobsSort;
