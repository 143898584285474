import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import Button from '../../../Common/Components/Button';
import Loading from '../../../Common/Components/Loading';
import SlideIn from '../../../Common/Components/SlideIn';
import { AlertObject, AlertNewObject } from '../../../types/AlertObject';
import { NodeObject } from '../../../types/NodeObject';
import { SiteObject } from '../../../types/SiteObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Utils from '../../../utils/Utils';
import AlarmPlaybook from '../../Alarms/Components/AlarmPlaybook';
import SlideinInfoAlert from '../Components/SlideinInfoAlert';
import LightsAlarmHistory from './Components/LightsAlarmHistory';
import LightsAlarmsModal from './Components/LightsAlarmsModal';

const alarmsSlideInWidth = 450;

function LightsAlarms(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoaded, setIsLoaded] = useState(false);
  const [openConfirmDismissAlert, setOpenConfirmDismissAlert] = useState(false);
  const [openAlarmPlaybook, setOpenAlarmPlaybook] = useState(0);
  const [alertObj, setAlertObj] = useState<AlertNewObject>();
  const [alerts, setAlerts] = useState<AlertNewObject[] | undefined>(undefined);
  const [alertDismissArray, setAlertDismissArray] = useState<Array<string> | undefined>(undefined);
  const [openAlarmHistory, setOpenAlarmHistory] = useState(0);
  const [sorted, setSorted] = useState(alerts);

  const [expand, setExpand] = useState(false);

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(selectedCustomer.id ? [`/organizations/${selectedCustomer.id}/sites`, 'GetSites'] : null);
  const site = useMemo(() => sitesResp?.find((item) => item.siteid === selectedSite.id), [sitesResp, selectedSite.id]);

  const { data: alertsResp,
    mutate: refreshAlerts } = useSWR<Array<AlertObject>>(
      selectedSite.id && selectedNode.nodeid
        ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/${selectedNode.nodeid}/alerts?activeOnly=true&withDisplay=true`, 'RefreshAlerts']
        : null,
    );

  useEffect(() => {
    if (alertsResp !== undefined) {
      const newList: Array<AlertNewObject> = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < alertsResp.length; i++) {
        const createdAt = Utils.convertISOtoAlarmTime(alertsResp[i].raisedAt as unknown as string, site?.time_zone);
        const updatedAt = Utils.convertISOtoAlarmTime(alertsResp[i].clearedAt as unknown as string, site?.time_zone);

        if (i !== 0 && alertsResp[i].name === alertsResp[i - 1].name) {
          const newListCurrentIndex = newList.length - 1;

          if (newList[i - 1]) {
            // eslint-disable-next-line max-len
            newList[i - 1].nodeData.push({
              id: alertsResp[i].nodeId,
              alertid: alertsResp[i].id,
              description: alertsResp[i].description || (alertsResp[i].details as Record<string, string>).message || '',
              dismissed: alertsResp[i].dismissedAt !== undefined && alertsResp[i].dismissedAt !== '',
              severity: alertsResp[i].severity,
              createdAt,
              updatedAt,
            });
          } else {
            // eslint-disable-next-line max-len
            newList[newListCurrentIndex].nodeData.push({
              id: alertsResp[i].nodeId,
              alertid: alertsResp[i].id,
              description: alertsResp[i].description || (alertsResp[i].details as Record<string, string>).message || '',
              dismissed: alertsResp[i].dismissedAt !== undefined && alertsResp[i].dismissedAt !== '',
              severity: alertsResp[i].severity,
              createdAt,
              updatedAt,
            });
          }
        } else {
          const obj: AlertNewObject = {
            severity: alertsResp[i].severity,
            name: alertsResp[i].name,
            type: alertsResp[i].type,
            action: alertsResp[i].action || '',
            nodeData: [
              {
                id: alertsResp[i].nodeId,
                alertid: alertsResp[i].id,
                description: alertsResp[i].description || (alertsResp[i].details as Record<string, string>).message || '',
                dismissed: alertsResp[i].dismissedAt !== undefined && alertsResp[i].dismissedAt !== '',
                severity: alertsResp[i].severity,
                createdAt,
                updatedAt,
              },
            ],
          };

          newList.push(obj);
        }
      }

      setAlerts(newList);
      setSorted(newList);
      setIsLoaded(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertsResp]);

  return (
    <div className="alarms">
      <SlideIn
        bgStyle="light"
        slideInWidth={alarmsSlideInWidth}
        expand={expand}
        setExpand={setExpand}
      >
        <>
          <div className="slide-in__title">
            <span>Alarms</span>
            <div className="slide-in__title-subtitle">
              <span className="bold">Node ID</span>
              {selectedNode.nodeid}
              <div className="slide-in__links">
                <div className="slide-in__link">
                  <Button label="View history" onClick={() => setOpenAlarmHistory(1)} />
                </div>
              </div>
            </div>
          </div>
          <div className="slide-in__content">
            {sorted !== undefined
              ? (
                <SlideinInfoAlert
                  alerts={sorted}
                  setOpenConfirmDismissAlert={() => setOpenConfirmDismissAlert(true)}
                  setAlertObj={setAlertObj}
                  setAlertDismissArray={setAlertDismissArray}
                  setOpenAlarmPlaybook={() => setOpenAlarmPlaybook(1)}
                />
              )
              : <Loading />}
          </div>
          { openConfirmDismissAlert && (
            <LightsAlarmsModal
              setOpenConfirmDismissAlert={setOpenConfirmDismissAlert}
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              alertObj={alertObj}
              alertDismissArray={alertDismissArray}
              refreshAlertsList={refreshAlerts}
              setLoader={() => setIsLoaded(false)}
            />
          )}
        </>
      </SlideIn>
      {(openAlarmHistory > 0) && (
        <LightsAlarmHistory
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          selectedNode={selectedNode}
          openModal={openAlarmHistory}
          setOpenModal={setOpenAlarmHistory}
          site={site}
        />
      )}
      {openAlarmPlaybook === 1 && alertObj && (
      <AlarmPlaybook
        alarm={alertObj}
        setShowModal={setOpenAlarmPlaybook}
      />
      )}
    </div>
  );
}

export default LightsAlarms;
