import { LeafletButton, LeafletButtonOptions } from '../types/global';

export default (): void => {
  L.Control.Button = L.Control.extend({
    options: {} as L.ControlOptions,

    button: {} as LeafletButton,

    map: undefined as unknown as L.Map,

    container: undefined as unknown as HTMLElement,

    initialize(btnOptions) {
      this.button = {} as LeafletButton;
      this.setButton(btnOptions);
    },

    onAdd(map) {
      this.map = map;
      const container = L.DomUtil.create('div', `leaflet-control-button ${this.button.className}-container`);

      this.container = container;

      this.update();
      return this.container;
    },

    onRemove(map) {
      //
    },

    setButton(btnOptions: LeafletButtonOptions) {
      const button = {
        className: btnOptions.className,
        toggledClassName: btnOptions.toggledClassName || 'leaflet-control-toggled',
        onClick: btnOptions.onClick,
        offClick: btnOptions.offClick,
        onMouseover: btnOptions.onMouseover,
        onMouseout: btnOptions.onMouseout,
        toggleStatus: false,
        disabled: false,
        tooltipText1: btnOptions.tooltipText1,
        tooltipText2: btnOptions.tooltipText2,
      };

      this.options.position = btnOptions.position || 'topright';
      this.button = button;
      this.update();
    },

    destroy() {
      this.button = {} as LeafletButton;
      this.update();
    },

    toggle(newStatus?) {
      this.button.toggleStatus = newStatus !== undefined ? newStatus : !this.button.toggleStatus;
      this.update();

      if (this.button.onClick && this.button.offClick) {
        if (this.button.toggleStatus) {
          this.button.onClick();
        } else {
          this.button.offClick();
        }
      }
    },

    disable() {
      this.button.disabled = true;
      this.update();
    },

    enable() {
      this.button.disabled = false;
      this.update();
    },

    update() {
      if (!this.map) {
        return;
      }

      this.container.innerHTML = '';
      this.makeButton(this.button);
    },

    makeButton(button: LeafletButton) {
      const newButton = L.DomUtil.create('div', 'leaflet-buttons-control-button', this.container);
      const tooltip = L.DomUtil.create('div', 'lf-tooltip', this.container);

      L.DomUtil.addClass(newButton, button.className);

      if (button.disabled) {
        L.DomUtil.addClass(newButton, 'disabled');
      } else {
        if (button.toggleStatus) {
          tooltip.innerHTML = button.tooltipText1 || '';
          L.DomUtil.addClass(this.container, button.toggledClassName || 'leaflet-control-toggled');
          if (button.tooltipText1 !== undefined) {
            tooltip.innerHTML = button.tooltipText1;
          }
        } else {
          L.DomUtil.removeClass(this.container, button.toggledClassName || 'leaflet-control-toggled');
          if (button.tooltipText2 !== undefined) {
            tooltip.innerHTML = button.tooltipText2;
          }
        }

        if (this.button.onClick && this.button.offClick) {
          L.DomEvent
            .addListener(newButton, 'click', L.DomEvent.stop)
            .addListener(newButton, 'click', this.clicked, this);
          // L.DomEvent.disableClickPropagation(newButton); // this somehow breaks the next consecutive click...
        }

        if (this.button.onMouseover) {
          L.DomEvent
            .addListener(newButton, 'mouseover', L.DomEvent.stop)
            .addListener(newButton, 'mouseover', this.mouseover, this)
            .addListener(newButton, 'mouseout', L.DomEvent.stop)
            .addListener(newButton, 'mouseout', this.mouseout, this);
        }
      }

      return newButton;
    },

    clicked() {
      this.toggle();
    },

    mouseover() {
      if (this.button.onMouseover) {
        this.button.onMouseover();
      }
    },

    mouseout() {
      if (this.button.onMouseout) {
        this.button.onMouseout();
      }
    },
  });
};
