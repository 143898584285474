/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { ModalFullPageProps } from '../../types/ModalFullPageProps';

function ModalFullPage(props: ModalFullPageProps): JSX.Element {
  const { children } = props;

  return (
    <div className="modal-fullpage">
      {children}
    </div>
  );
}

export default ModalFullPage;
