/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState } from 'react';
import { FixturesPageState } from '../../types/FixturesPageProps';
import { FixtureObject } from '../../types/FixtureObject';
import getHeaderProps from '../getHeaderProps';

export default function useFixturesPageState(): FixturesPageState {
  const [headers, setHeaders] = useState(getHeaderProps('FixturesDetail'));
  const [toggleActionsFixture, setToggleActionsFixture] = useState(false);
  const [toggleCopyFixture, setToggleCopyFixture] = useState(false);
  const [toggleViewFixture, setToggleViewFixture] = useState(false);

  const [selectedFixtures, setSelectedFixtures] = useState<Map<string, FixtureObject>>(new Map());

  return {
    headers,
    setHeaders,
    toggleActionsFixture,
    setToggleActionsFixture,
    toggleCopyFixture,
    setToggleCopyFixture,
    toggleViewFixture,
    setToggleViewFixture,
    selectedFixtures,
    setSelectedFixtures,
  };
}
