/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import Chart, { ChartYAxe } from 'chart.js';
import { DateTime } from 'luxon';
import 'chartjs-plugin-zoom';
import Loading from '../../../Common/Components/Loading';
import NoData from '../../../Common/Components/NoData';
import { ChartLegend, ChartLegendProps, getLabelForDateType, LightingChartPropTypes, HeaderLegendStateProp } from '../../../types/LightingChartPropTypes';
import Sensors from '../../../utils/Sensors';

import { ReactComponent as Sensor1LegendIcon } from '../../../img/icons/sensor1-legend.svg';
import { ReactComponent as Sensor2LegendIcon } from '../../../img/icons/sensor2-legend.svg';
import Checkbox from '../../../Common/Components/Checkbox';

function LightingChart(props: LightingChartPropTypes): JSX.Element {
  const { site, rawDatasets, id, type = 'line', chartProps, isLoading, changedDataset, sensorName1, sensorName2 } = props;

  const [hiddenLegends, setHiddenLegends] = useState<Set<string>>(new Set());
  const [legends, setLegends] = useState<ChartLegend>(new Map());
  const [headerLegendCheckboxes, setHeaderLegendCheckboxes] = useState<HeaderLegendStateProp>({
    allNodes: true,
    allSensor1: true,
    allSensor2: true,
  });

  const ref = useRef<HTMLCanvasElement>(null);
  const chart = useRef<Chart>();

  const colors: string[][] = [
    ['0088CE', '66B7E1'], // verizon blue
    ['FF8400', 'FFB566'], // florida grove
    ['98D92E', 'C2E28A'], // avocado
    ['9032BF', 'C899DF'], // purple
    ['747676', 'AFAFAF'], // gray 4
    ['00AC3E', '66CD8B'], // verizon green
    ['D9008D', 'E866BA'], // lipstick
    ['3DF8FF', 'B1FCFF'], // day glow blue
    ['AE4509', 'CE8F6B'], // rust
    ['000000', '535353'], // black
  ];
  const getLabelFromDate = (date: Date): getLabelForDateType => {
    const isoDateString = date.toISOString();
    const t = DateTime.fromISO(isoDateString, { zone: site ? site.time_zone : 'UTC', setZone: true }).toLocaleString({
      locale: 'en-US',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      second: '2-digit',
    }).split(', ');
    const full = `${t[1]} ${t[0]}`;

    const short = DateTime.fromISO(isoDateString).toLocaleString({
      locale: 'en-US',
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
      month: 'short',
      day: 'numeric',
      timeZone: site ? site.time_zone : 'UTC',
    });

    return {
      full,
      short,
    };
  };

  const getYAxes = () => {
    const threshold = 0.1;
    const yAxes: ChartYAxe[] = [];

    if (chartProps.sensor1.key !== '0') {
      yAxes.push(
        {
          type: 'linear',
          position: 'left',
          id: chartProps.sensor1.key,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          afterDataLimits(axis: any) {
            const padding = (axis.max - axis.min) * threshold;
            // eslint-disable-next-line no-param-reassign
            axis.max += padding;
            // eslint-disable-next-line no-param-reassign
            axis.min -= padding;
          },
          scaleLabel: {
            display: true,
            labelString: `\u25CF  ${chartProps.sensor1.title}`,
            fontColor: '#000',
            fontSize: 16,
          },
        },
      );
    }

    if (chartProps.sensor2.key !== '0') {
      yAxes.push(
        {
          type: 'linear',
          position: 'right',
          id: chartProps.sensor2.key,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          afterDataLimits(axis: any) {
            const padding = (axis.max - axis.min) * threshold;
            // eslint-disable-next-line no-param-reassign
            axis.max += padding;
            // eslint-disable-next-line no-param-reassign
            axis.min -= padding;
          },
          scaleLabel: {
            display: true,
            labelString: `\u25C6  ${chartProps.sensor2.title}`,
            fontColor: '#000',
            fontSize: 16,
          },
        },
      );
    }

    return yAxes;
  };

  const isDatasetEmpty = () => rawDatasets.every((sensorReport) => sensorReport.data == null || sensorReport.data.length === 0);

  useEffect(() => {
    if (!isDatasetEmpty()) {
      legends.clear();
      hiddenLegends.clear();
      const allNodes = true;
      const allSensor1 = true;
      const allSensor2 = true;

      setHeaderLegendCheckboxes((oldValues) => ({
        ...oldValues,
        allNodes,
        allSensor1,
        allSensor2,
      }));
      const tmpLegends = new Map(legends);

      for (let i = 0, j = 0; i < rawDatasets.length; i += 1) {
        const { label: nodeId = '', nodeName = '', sensorLabel = '' } = rawDatasets[i];
        const sensorName = Sensors.getSensorTitleByName(sensorLabel);

        if (!tmpLegends.has(nodeId)) {
          tmpLegends.set(nodeId, {
            nodeIndex: j,
            nodeId,
            nodeName,
            sensors: new Map(),
          });

          j += 1;
        }

        const legendObj = tmpLegends.get(nodeId) as ChartLegendProps;
        const newSensors = legendObj.sensors;
        newSensors.set(sensorLabel, { sensorName, sensorIndex: i });
        tmpLegends.set(nodeId, { ...legendObj, sensors: newSensors });
      }

      setLegends(tmpLegends);

      chart.current = new Chart(ref.current as HTMLCanvasElement, {
        type,
        data: {
          datasets: rawDatasets.map((dataset) => {
            const legend = tmpLegends.get(dataset.label || '');
            const nodeIndex = legend?.nodeIndex || 0;
            const sensorIndex = chartProps.sensor1.key === dataset.sensorLabel ? 0 : 1;

            // eslint-disable-next-line no-param-reassign
            dataset.borderColor = `#${colors[nodeIndex][sensorIndex]}`;
            // eslint-disable-next-line no-param-reassign
            dataset.backgroundColor = `#${colors[nodeIndex][sensorIndex]}`;

            // eslint-disable-next-line no-param-reassign
            dataset.steppedLine = 'before';
            return dataset;
          }),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          showLines: true,
          layout: {
            padding: {
              top: 20,
            },
          },
          scales: {
            xAxes: [{
              type: 'time',
              bounds: 'ticks',
              ticks: {
                autoSkip: false,
                minRotation: 45,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                callback(value: any, index: any, values: any): string {
                  if (!values[index]
                    || typeof values[index] !== 'object'
                    || !new Date(values[index].value)) {
                    return '';
                  }
                  return getLabelFromDate(new Date(values[index].value)).short;
                },
              },
              position: 'bottom',
              scaleLabel: {
                display: true,
                labelString: 'Time',
                fontSize: 18,
              },
            }],
            yAxes: getYAxes(),
          },
          plugins: {
            zoom: {
              zoom: {
                zoomEnabled: true,
                enabled: true,
                mode: 'x',
                sensitivity: 3,
              },
            },
          },
          tooltips: {
            xPadding: 10,
            yPadding: 10,
            callbacks: {
              title: (tooltips) => {
                const date = tooltips[0].xLabel?.toString() || 'no valid date';
                return date;
              },
              label: (tooltips, data) => {
                let nodeId = '';
                let sensor = '';
                let tooltipLabel = '';
                let nodeName = '';
                if (tooltips.datasetIndex !== undefined
                  && chart.current?.data?.datasets !== undefined) {
                  const currElement = chart.current?.data?.datasets[tooltips.datasetIndex];
                  nodeName = currElement.nodeName || '';
                  nodeId = currElement.label || '';
                  tooltipLabel = nodeName !== ''
                    ? `${nodeName} (${nodeId})`
                    : nodeId;
                  sensor = Sensors.getSensorTitleByName(currElement.sensorLabel || '');
                }
                return `${tooltipLabel} - ${sensor} : ${tooltips.value}`;
              },
            },
          },
          legend: {
            display: false,
          },
        },
      });
    }

    return () => {
      if (chart.current) {
        chart.current.clear();
        chart.current.destroy();
        chart.current = undefined;
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawDatasets]);

  const legendHeaderOnClick = (headerLabel: string, headerChecked: boolean) => {
    if (headerLabel === 'All Nodes') {
      rawDatasets.forEach((ds) => {
        // eslint-disable-next-line no-param-reassign
        ds.hidden = !ds.hidden;
      });
      const newHiddenLegends = new Set(hiddenLegends);
      legends.forEach((legend) => {
        if (legend.nodeId) {
          const nodeSensorLabels = Array.from(legend.sensors.keys());
          nodeSensorLabels.forEach((nodeSensorLabel) => {
            const hiddenLegendKey = `${legend.nodeId}-${nodeSensorLabel}`;
            const sensorIndex = legend.sensors.get(nodeSensorLabel)?.sensorIndex;

            if (sensorIndex !== undefined) {
              const meta = chart.current?.getDatasetMeta(sensorIndex);

              if (meta) {
                if (headerLegendCheckboxes.allNodes) {
                  meta.hidden = true;
                  const allNodes = false;
                  const allSensor1 = false;
                  const allSensor2 = false;
                  newHiddenLegends.add(hiddenLegendKey);
                  newHiddenLegends.add(legend.nodeId);
                  setHeaderLegendCheckboxes((oldValues) => ({
                    ...oldValues,
                    allNodes,
                    allSensor1,
                    allSensor2,
                  }));
                } else {
                  meta.hidden = false;
                  newHiddenLegends.delete(hiddenLegendKey);
                  newHiddenLegends.delete(legend.nodeId);
                  const allNodes = true;
                  const allSensor1 = true;
                  const allSensor2 = true;
                  setHeaderLegendCheckboxes((oldValues) => ({
                    ...oldValues,
                    allNodes,
                    allSensor1,
                    allSensor2,
                  }));
                }
              }
            }
          });
        }
      });
      setHiddenLegends(newHiddenLegends);
      changedDataset(newHiddenLegends);
      chart.current?.update();
    }
    if (headerLabel === 'All Sensor1') {
      rawDatasets.forEach((ds) => {
        if (chartProps.sensor2.key !== ds.sensorLabel) {
          // eslint-disable-next-line no-param-reassign
          ds.hidden = !ds.hidden;
        }
      });
      const newHiddenLegends = new Set(hiddenLegends);
      legends.forEach((legend) => {
        if (legend.nodeId) {
          const nodeSensorLabels = Array.from(legend.sensors.keys());
          nodeSensorLabels.forEach((nodeSensorLabel) => {
            const hiddenLegendKey = `${legend.nodeId}-${nodeSensorLabel}`;
            const sensorIndex = legend.sensors.get(nodeSensorLabel)?.sensorIndex;

            if (sensorIndex !== undefined) {
              const meta = chart.current?.getDatasetMeta(sensorIndex);
              if (chartProps.sensor1.key === nodeSensorLabel) {
                if (meta) {
                  if (headerLegendCheckboxes.allSensor1) {
                    meta.hidden = true;
                    newHiddenLegends.add(hiddenLegendKey);
                    uncheckLegendNodeIfNecessary(legend, newHiddenLegends);
                    const allSensor1 = false;
                    setHeaderLegendCheckboxes((oldValues) => ({
                      ...oldValues,
                      allSensor1,
                    }));
                  } else {
                    meta.hidden = false;
                    newHiddenLegends.delete(hiddenLegendKey);
                    newHiddenLegends.delete(legend.nodeId);
                    checkLegendAllNodesIfNecessary(newHiddenLegends);
                    const allSensor1 = true;
                    setHeaderLegendCheckboxes((oldValues) => ({
                      ...oldValues,
                      allSensor1,
                    }));
                  }
                }
              }
            }
          });
        }
      });
      setHiddenLegends(newHiddenLegends);
      changedDataset(newHiddenLegends);
      chart.current?.update();
    }

    if (headerLabel === 'All Sensor2') {
      rawDatasets.forEach((ds) => {
        if (chartProps.sensor1.key !== ds.sensorLabel) {
          // eslint-disable-next-line no-param-reassign
          ds.hidden = !ds.hidden;
        }
      });
      const newHiddenLegends = new Set(hiddenLegends);
      legends.forEach((legend) => {
        if (legend.nodeId) {
          const nodeSensorLabels = Array.from(legend.sensors.keys());
          nodeSensorLabels.forEach((nodeSensorLabel) => {
            const hiddenLegendKey = `${legend.nodeId}-${nodeSensorLabel}`;
            const sensorIndex = legend.sensors.get(nodeSensorLabel)?.sensorIndex;

            if (sensorIndex !== undefined) {
              const meta = chart.current?.getDatasetMeta(sensorIndex);
              if (chartProps.sensor2.key === nodeSensorLabel) {
                if (meta) {
                  if (headerLegendCheckboxes.allSensor2) {
                    meta.hidden = true;
                    newHiddenLegends.add(hiddenLegendKey);
                    uncheckLegendNodeIfNecessary(legend, newHiddenLegends);
                    const allSensor2 = false;
                    setHeaderLegendCheckboxes((oldValues) => ({
                      ...oldValues,
                      allSensor2,
                    }));
                  } else {
                    meta.hidden = false;
                    newHiddenLegends.delete(hiddenLegendKey);
                    newHiddenLegends.delete(legend.nodeId);
                    checkLegendAllNodesIfNecessary(newHiddenLegends);
                    const allSensor2 = true;
                    setHeaderLegendCheckboxes((oldValues) => ({
                      ...oldValues,
                      allSensor2,
                    }));
                  }
                }
              }
            }
          });
        }
      });
      setHiddenLegends(newHiddenLegends);
      changedDataset(newHiddenLegends);
      chart.current?.update();
    }
  };

  const legendOnClick = (nodeId: string, sensorLabel = '') => {
    const newHiddenLegends = new Set(hiddenLegends);
    const legend = legends.get(nodeId);

    if (legend) {
      if (sensorLabel === '') {
        const nodeSensorLabels = Array.from(legend.sensors.keys());
        nodeSensorLabels.forEach((nodeSensorLabel) => {
          const hiddenLegendKey = `${nodeId}-${nodeSensorLabel}`;
          const sensorIndex = legend.sensors.get(nodeSensorLabel)?.sensorIndex;
          if (sensorIndex !== undefined) {
            const meta = chart.current?.getDatasetMeta(sensorIndex);

            if (meta) {
              if (hiddenLegends.has(nodeId)) {
                meta.hidden = false;
                newHiddenLegends.delete(hiddenLegendKey);
                newHiddenLegends.delete(nodeId);
                checkLegendAllNodesIfNecessary(newHiddenLegends);
                checkLegendAllSensorsIfNecessary(newHiddenLegends, nodeSensorLabel);
              } else {
                meta.hidden = true;
                newHiddenLegends.add(hiddenLegendKey);
                newHiddenLegends.add(nodeId);
                const allNodes = false;
                if (chartProps.sensor1.key === nodeSensorLabel) {
                  const allSensor1 = false;

                  setHeaderLegendCheckboxes((oldValues) => ({
                    ...oldValues,
                    allNodes,
                    allSensor1,
                  }));
                } else {
                  const allSensor2 = false;

                  setHeaderLegendCheckboxes((oldValues) => ({
                    ...oldValues,
                    allNodes,
                    allSensor2,
                  }));
                }
              }
            }
          }
        });
      } else {
        const hiddenLegendKey = `${nodeId}-${sensorLabel}`;
        const sensorIndex = legend.sensors.get(sensorLabel)?.sensorIndex;

        if (sensorIndex !== undefined) {
          const meta = chart.current?.getDatasetMeta(sensorIndex);
          if (meta) {
            if (hiddenLegends.has(hiddenLegendKey)) {
              meta.hidden = false;
              newHiddenLegends.delete(hiddenLegendKey);
              newHiddenLegends.delete(nodeId);
              checkLegendAllNodesIfNecessary(newHiddenLegends);
              checkLegendAllSensorsIfNecessary(newHiddenLegends, sensorLabel);
            } else {
              meta.hidden = true;
              newHiddenLegends.add(hiddenLegendKey);
              uncheckLegendNodeIfNecessary(legend, newHiddenLegends);
              if (chartProps.sensor1.key === sensorLabel) {
                const allSensor1 = false;
                setHeaderLegendCheckboxes((oldValues) => ({
                  ...oldValues,
                  allSensor1,
                }));
              }
              if (chartProps.sensor2.key === sensorLabel) {
                const allSensor2 = false;
                setHeaderLegendCheckboxes((oldValues) => ({
                  ...oldValues,
                  allSensor2,
                }));
              }
            }
          }
        }
      }
    }
    setHiddenLegends(newHiddenLegends);
    changedDataset(newHiddenLegends);

    chart.current?.update();
  };

  const uncheckLegendNodeIfNecessary = (legend: ChartLegendProps, newHiddenLegends: Set<string>) => {
    const nodeSensorLabels = Array.from(legend.sensors.keys());
    const allSensorsAreHiddenForNode = nodeSensorLabels
      .map((nodeSensorLabel) => `${legend.nodeId}-${nodeSensorLabel}`)
      .every((nodeSensorLabel) => newHiddenLegends.has(nodeSensorLabel));
    if (allSensorsAreHiddenForNode) {
      newHiddenLegends.add(legend.nodeId);
      const allNodes = false;
      setHeaderLegendCheckboxes((oldValues) => ({
        ...oldValues,
        allNodes,
      }));
    }
  };

  const checkLegendAllNodesIfNecessary = (newHiddenLegends: Set<string>) => {
    const tmpLegends = new Map(legends);
    const allNodes = !Array.from(tmpLegends.keys())
      .some((node) => newHiddenLegends.has(node));
    setHeaderLegendCheckboxes((oldValues) => ({
      ...oldValues,
      allNodes,
    }));
  };

  const checkLegendAllSensorsIfNecessary = (newHiddenLegends: Set<string>, sensorLabel: string) => {
    const allSensorShown = !Array.from(newHiddenLegends.values()).some((l) => l.includes(sensorLabel));
    if (allSensorShown && chartProps.sensor1.key === sensorLabel) {
      const allSensor1 = true;
      setHeaderLegendCheckboxes((oldValues) => ({
        ...oldValues,
        allSensor1,
      }));
    } else if (allSensorShown && chartProps.sensor2.key === sensorLabel) {
      const allSensor2 = true;
      setHeaderLegendCheckboxes((oldValues) => ({
        ...oldValues,
        allSensor2,
      }));
    }
  };

  const generateLegendElements = () => {
    const allLegendsHtml: JSX.Element[] = [];

    if (rawDatasets) {
      legends.forEach((legend) => {
        allLegendsHtml.push(
          <div key={`l-${legend.nodeIndex}`} className="chart-legends__list--element">
            <div className="legend-node">
              <Checkbox
                checked={!hiddenLegends.has(legend.nodeId)}
                dark={false}
                onClick={() => legendOnClick(legend.nodeId)}
              />
              <div>
                <div className="legend-node--id">{legend.nodeId}</div>
                {legend.nodeName && (
                  <div className="legend-node--name">{legend.nodeName}</div>
                )}
              </div>
            </div>
            <div className="legend-sensors">
              {legend.sensors.has(chartProps.sensor1.key) && (
                <div className="legend-sensors--1">
                  <Checkbox
                    checked={!hiddenLegends.has(`${legend.nodeId}-${chartProps.sensor1.key}`)}
                    dark={false}
                    small
                    color={colors[legend.nodeIndex] && colors[legend.nodeIndex][0] ? colors[legend.nodeIndex][0] : colors[0][0]}
                    onClick={() => legendOnClick(legend.nodeId, chartProps.sensor1.key)}
                  />
                  <div className="legend-sensors--1-title">{legend.sensors.get(chartProps.sensor1.key)?.sensorName}</div>
                </div>
              )}
              {legend.sensors.has(chartProps.sensor2.key) && (
                <div className="legend-sensors--2">
                  <Checkbox
                    checked={!hiddenLegends.has(`${legend.nodeId}-${chartProps.sensor2.key}`)}
                    dark={false}
                    small
                    color={colors[legend.nodeIndex] && colors[legend.nodeIndex][1] ? colors[legend.nodeIndex][1] : colors[0][1]}
                    onClick={() => legendOnClick(legend.nodeId, chartProps.sensor2.key)}
                  />
                  <div className="legend-sensors--2-title">{legend.sensors.get(chartProps.sensor2.key)?.sensorName}</div>
                </div>
              )}
            </div>
          </div>,
        );
      });
    }

    return (
      <div className="chart-legends__list-container">
        {allLegendsHtml}
      </div>
    );
  };

  const generateHeaderLegendElements = () => {
    const headerLegendsHtml: JSX.Element[] = [];

    if (rawDatasets) {
      // legends.forEach((legend) => {
      headerLegendsHtml.push(
        <div key="lh-00" className="chart-legends__list--element">
          <div className="legend-node">
            <Checkbox
              checked={headerLegendCheckboxes.allNodes}
              dark={false}
              onClick={() => legendHeaderOnClick('All Nodes', headerLegendCheckboxes.allNodes)}
            />
            <div>
              <div className="legend-node--id"> All Nodes</div>
            </div>
          </div>
          <div className="legend-sensors">
            <div className="legend-sensors--1">
              <Checkbox
                checked={headerLegendCheckboxes.allSensor1}
                dark={false}
                small
                onClick={() => legendHeaderOnClick('All Sensor1', headerLegendCheckboxes.allSensor1)}

              />
              <div className="legend-sensors--1-title">
                Sensor1 (
                {chartProps.sensor1?.title}
                )
              </div>
            </div>
            {chartProps.sensor2.key !== '0' && (
            <div className="legend-sensors--2">
              <Checkbox
                checked={headerLegendCheckboxes.allSensor2}
                dark={false}
                small
                onClick={() => legendHeaderOnClick('All Sensor2', headerLegendCheckboxes.allSensor2)}

              />
              <div className="legend-sensors--2-title">
                Sensor2 (
                {chartProps.sensor2?.title}
                )
              </div>
            </div>
            )}
          </div>
        </div>,
      );
      // });
    }

    return (
      <div className="chart-legends__list-container">
        {headerLegendsHtml}
      </div>
    );
  };
  const generateLegend = () => (
    <>
      <div className="chart-legends__header">
        <div className="chart-legends__header--title">Legend</div>
        <div className="chart-legends__header--legends">
          {sensorName1 !== 'Select' && (
            <div>
              <Sensor1LegendIcon />
              <div>{sensorName1}</div>
            </div>
          )}
          {sensorName2 !== 'Select' && (
            <div>
              <Sensor2LegendIcon />
              <div>{sensorName2}</div>
            </div>
          )}
        </div>
      </div>
      <div className="chart-legends__list">
        {generateHeaderLegendElements()}
        {generateLegendElements()}
      </div>
    </>
  );

  return (
    <div className="chart-container">
      <Loading isLoading={isLoading} />
      {!isDatasetEmpty() && (
        <>
          <div className="chart">
            <canvas ref={ref} id={id} />
          </div>
          <div className="chart-legends">
            {generateLegend()}
          </div>
        </>
      )}
      {(!isLoading && isDatasetEmpty()) && <NoData />}
    </div>
  );
}

export default LightingChart;
