class FetchError extends Error {
  info: string;

  status: number;

  constructor(message?: string) {
    super(message);
    this.info = '';
    this.status = 0;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export default FetchError;
