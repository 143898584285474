import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import { AutoOpenSelectboxListType } from '../../../../types/AutoOpenSelectboxPropsType';
import { RemoveNodesFromOrgGroupProps } from '../../../../types/GroupActionsPropTypes';
import { LightsGroupsItem } from '../../../../types/LightsGroupProps';
import { postRequest } from '../../../../utils/fetch';
import GroupActionsOrgGroup from '../GroupActionsOrgGroup';
import Loading from '../../../../Common/Components/Loading';
import { httpAccepted, httpOk } from '../../../../utils/constants';

function RemoveNodesFromOrgGroup(props: RemoveNodesFromOrgGroupProps): JSX.Element {
  const { setOpenModal, selectedNodes, addNotification, orgGroups, selectedCustomer, selectedSite, updateGroup } = props;
  const [selectedOrgGroup, setSelectedOrgGroup] = useState<AutoOpenSelectboxListType>({ groupId: '', name: '' });
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      title="Remove nodes from org group"
      width="546"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        try {
          if (selectedOrgGroup.groupId === '') {
            addNotification({ type: 'error', message: 'Please select an org group.' });
          } else {
            let count = 0;
            const nodeLimit = 10000;
            while (count < Array.from(selectedNodes.keys()).length / nodeLimit) {
              setLoading(true);

              const removedNodes = Array.from(selectedNodes.keys()).slice(count * nodeLimit, count * nodeLimit + nodeLimit);
              const groupObject = orgGroups.find((group) => group.groupId === selectedOrgGroup.groupId) as LightsGroupsItem;
              const newNodeList = groupObject && groupObject.nodeList?.filter((node) => !removedNodes.includes(node));
              const postBody = {
                operation: 'REMOVE_NODES',
                nodeIds: removedNodes,
                fromGroupId: selectedOrgGroup.groupId,
              };
              groupObject.nodeList = newNodeList;

              // eslint-disable-next-line no-await-in-loop
              const result = await postRequest(
                `/organizations/${selectedCustomer?.id}/sites/${selectedSite?.id}/async-jobs/group-move`,
                postBody,
              );
              if ((count + 1 > Array.from(selectedNodes.keys()).length / nodeLimit)) {
                setLoading(false);
                addNotification({ type: 'success', message: 'Your "Remove nodes from org group" operation is completed.' });
                updateGroup();
                setOpenModal(0);
              }
              if (result.status === httpAccepted || httpOk) {
                count += 1;
              }
            }
          }
        } catch (e) {
          addNotification({ type: 'error', message: 'Your "Remove nodes from org group" operation has failed.' });
        }
      }}
    >
      <Loading isLoading={loading} />
      <GroupActionsOrgGroup
        title="Remove selected nodes"
        comment="Nodes will be removed from all selected org groups."
        selectedNodes={selectedNodes}
        orgGroups={orgGroups.filter((group) => group.nodeList?.some((nodeId) => Array.from(selectedNodes.keys()).includes(nodeId)))}
        selectedOrgGroup={selectedOrgGroup}
        setSelectedOrgGroup={setSelectedOrgGroup}
      />
    </Modal>
  );
}

export default RemoveNodesFromOrgGroup;
