/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import HeaderItem from './Components/HeaderItem';
import HeaderButton from './Components/HeaderButton';
import HeaderDropDown from './Components/HeaderDropDown';

// icons:
import { ReactComponent as LogoIcon } from '../../img/logo/lighting-logo.svg';
import { ReactComponent as DotdotdotIcon } from '../../img/icons/dotdotdot.svg';
import { ReactComponent as SettingsIcon } from '../../img/icons/settings.svg';
import { ReactComponent as SupportIcon } from '../../img/icons/support.svg';
import { ReactComponent as ProfileIcon } from '../../img/icons/profile.svg';
import { ReactComponent as LocatorIcon } from '../../img/icons/site-locator.svg';
import { ReactComponent as SiteConfigIcon } from '../../img/icons/site-config.svg';
import { ReactComponent as LightsIcon } from '../../img/icons/lighting-group.svg';
import { ReactComponent as GroupsIcon } from '../../img/icons/groups-2.svg';
import { ReactComponent as SchedulesIcon } from '../../img/icons/schedule-large.svg';
import { ReactComponent as JobsIcon } from '../../img/icons/shovel.svg';
import { ReactComponent as EnergyIcon } from '../../img/icons/smart-meter.svg';
import { ReactComponent as ReportsIcon } from '../../img/icons/reports.svg';
import { ReactComponent as AlarmsIcon } from '../../img/icons/alarms.svg';
import { ReactComponent as SyncIcon } from '../../img/icons/sync.svg';
import { ReactComponent as TimeZoneIcon } from '../../img/icons/time-zone.svg';

import { HeaderTypes } from '../../types/HeaderTypes';
import DropDown from '../Components/DropDown';
import Utils from '../../utils/Utils';
import ChangePasswordModal from './Components/ChangePasswordModal';
import SoftwareVersionModal from './Components/SoftwareVersionModal';
import AssumeRoleModal from './Components/AssumeRoleModal';
import SessionExpirationModal from './Components/SessionExpirationModal';
import Tooltip from '../Components/Tooltip';
import { BASEPATH, userGuideUrl } from '../../utils/constants';
import { getRequest } from '../../utils/fetch';
import { AsyncJobsSummaryObject } from '../../types/AsyncJobsSummaryObject';

import { SiteObject } from '../../types/SiteObject';

function Header(props: HeaderTypes): JSX.Element {
  const {
    path,
    selectedCustomer,
    // setSelectedCustomer,
    selectedSite,
  } = props;
  const isLoginEndPoint = window.location.pathname === `${BASEPATH}/login`;
  const isCodeEndPoint = window.location.pathname === `${BASEPATH}/code`;

  const tooltipOffset = -5;
  const userInfo = Utils.getUserInfo();

  // const showOtaJobs = Utils.isVerizonUser();
  const showAnalysisTab = Utils.isVerizonUser();
  const [runningJobsCount, setRunningJobsCount] = useState(0);
  const [toggleHeaderDropDown, setToggleHeaderDropDown] = useState(null);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [softwareVersionModalOpen, setSoftwareVersionModalOpen] = useState(false);
  const [assumeRoleModalOpen, setAssumeRoleModalOpen] = useState(false);
  const [loggingOutInProgress, setLoggingOutInProgress] = useState(false);

  const { apiURLV4, applicationVersion, gitBuild, featureToggles } = window.NSN;
  const { assumeRoleEnabled } = JSON.parse(featureToggles ?? '{}') ?? {};
  const releaseVersion = applicationVersion && gitBuild ? `${applicationVersion}.${gitBuild}` : applicationVersion || '';
  const [, setActiveHeaderButton] = useState(null);

  const { data: asyncJobsSummaryResp } = useSWR<AsyncJobsSummaryObject>(
    selectedSite.id ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/async-jobs-summary`, 'Header'] : null,
  );

  useEffect(() => {
    setRunningJobsCount(asyncJobsSummaryResp?.inProgressCount || 0);
  }, [asyncJobsSummaryResp]);

  /**
  const sixtyDaysAgo: number = (new Date()).getTime() - (60 * 24 * 60 * 60 * 1000);
  const { data: runningFirmwareCount } = useSWR<number>(
    (selectedSite.id && showOtaJobs) ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/ota_status`, 'Header'] : null,
    (url) => getRequest(url, {}, (data: FirmwareJobObject[]): number =>
      data.filter((firmwarejob: FirmwareJobObject) => firmwarejob.status === 'JOB_STARTED' && (new Date(firmwarejob.when)).getTime() > sixtyDaysAgo).length),
  );
  if (showOtaJobs) {
    if (runningJobsCount !== undefined && runningFirmwareCount !== undefined) {
      totalRunningJobsCount = runningJobsCount + runningFirmwareCount;
    }
  } else if (runningJobsCount !== undefined) {
    totalRunningJobsCount = runningJobsCount;
  }
  */

  const getProfileTooltipText = (() => {
    if (Utils.isAssumingRole()) {
      return `Assuming Role: ${Utils.getUserRoleTitle(Utils.getAssumedRole())};\nOrg: ${Utils.getAssumedOrgName()}`;
    }
    return 'Profile';
  });

  const { data: timeZone } = useSWR<string>(
    selectedSite.id ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}`, 'Header'] : null,
    (url) => getRequest(url, {}, (data: SiteObject): string => DateTime.fromJSDate(new Date(), { zone: data.time_zone || undefined }).toFormat('ZZZZ')),
  );

  if (!sessionStorage.getItem('userInfo') || isLoginEndPoint || isCodeEndPoint) {
    return <div />;
  }

  return (
    <nav className="header">
      <ul className="header__list">
        <Link to="/sites" className="header__list-menu">
          <div className="header__list-menu-dot">
            <DotdotdotIcon />
          </div>
          <div className="header__list-logo">
            <LogoIcon />
          </div>
        </Link>
        <HeaderItem link="/sites">
          <>
            <LocatorIcon />
            <span>Sites</span>
          </>
        </HeaderItem>
        <HeaderItem link="/lights" isDisabled={selectedSite?.name === ''}>
          <>
            <LightsIcon />
            <span>Lights</span>
          </>
        </HeaderItem>
        <HeaderItem link="/groups" isDisabled={selectedSite?.name === ''}>
          <>
            <GroupsIcon />
            <span>Groups</span>
          </>
        </HeaderItem>
        <HeaderItem link="/schedules" isDisabled={selectedSite?.name === ''}>
          <>
            <SchedulesIcon />
            <span>Schedules</span>
          </>
        </HeaderItem>
        <HeaderItem link="/jobs" isDisabled={selectedSite?.name === ''}>
          <>
            <JobsIcon />
            <span>Jobs</span>
          </>
        </HeaderItem>
        <HeaderItem link="/energy" isDisabled={selectedSite?.name === ''}>
          <>
            <EnergyIcon />
            <span>Energy</span>
          </>
        </HeaderItem>
        <HeaderItem link="/alarms" isDisabled={selectedSite?.name === ''}>
          <>
            <AlarmsIcon />
            <span>Alarms</span>
          </>
        </HeaderItem>
        {showAnalysisTab
          && (
            <HeaderItem link="/analysis" isDisabled={selectedSite?.name === ''}>
              <>
                <ReportsIcon />
                <span>Analysis</span>
              </>
            </HeaderItem>
          )}
      </ul>
      <div className="header__links">
        {timeZone !== undefined && (
          <div className="header__links--timezone">
            <TimeZoneIcon />
            {timeZone}
          </div>
        )}
        {selectedSite?.name !== '' && (
          <div className="header__links--jobs">
            <SyncIcon />
            <span className="header__main--jobs-val">{runningJobsCount || 0}</span>
            <span>jobs running</span>
          </div>
        )}
        <Tooltip disabled={selectedSite?.name === ''} text="Config" position="left" delayed offset={tooltipOffset}>
          <HeaderButton disabled={selectedSite?.name === ''} order={0} icon={<SiteConfigIcon />} link="/config/fixtures" path={path} name="config" setActiveHeaderButton={setActiveHeaderButton} />
        </Tooltip>
        <Tooltip text="Admin" position="left" delayed offset={tooltipOffset}>
          <HeaderButton order={1} icon={<SettingsIcon />} link="/admin/accounts" path={path} name="admin" setActiveHeaderButton={setActiveHeaderButton} />
        </Tooltip>
        <Tooltip text="User Guide (opens in new tab)" position="left" delayed offset={tooltipOffset}>
          <HeaderDropDown icon={<SupportIcon />} link={userGuideUrl} external />
        </Tooltip>
        <Tooltip text={getProfileTooltipText()} position="left" delayed offset={tooltipOffset}>
          <HeaderDropDown
            order={1}
            setToggle={setToggleHeaderDropDown}
            toggle={toggleHeaderDropDown}
            icon={<ProfileIcon />}
            link="/user"
            path={path}
            last
            addStyle={Utils.isAssumingRole() ? { border: '12px solid #CCC' } : {}}
          >
            <DropDown>
              <div className="dropdown__content-user">
                <div className="dropdown__content-user-detail">
                  <span className="user-name">{userInfo.name}</span>
                  <span className="user-customer">{Utils.getUserRoleTitle()}</span>
                  <span className="user-site">{userInfo.user?.orgName || userInfo.user?.orgNames[0] || ''}</span>
                </div>
                <div className="dropdown__content-user-link" data-testid="logoutButton">
                  <button
                    type="button"
                    onClick={() => {
                      if (!loggingOutInProgress) {
                        setLoggingOutInProgress(true);
                        Utils.logout();
                      }
                    }}
                  >
                    {Utils.isAssumingRole() ? 'Unset Role and Logout' : 'Logout'}
                  </button>
                </div>
                {(Utils.isSensityUser() && assumeRoleEnabled === 'true')
                  && (
                    <div className="dropdown__content-user-link">
                      {!Utils.isAssumingRole()
                        && (
                          <button
                            type="button"
                            onClick={() => {
                              setAssumeRoleModalOpen(true);
                              setToggleHeaderDropDown(null);
                            }}
                          >
                            Assume Role
                          </button>
                        )}
                    </div>
                  )}
                <div className="dropdown__content-user-link">
                  <button
                    type="button"
                    onClick={() => {
                      setChangePasswordModalOpen(true);
                      setToggleHeaderDropDown(null);
                    }}
                  >
                    Change password
                  </button>
                </div>
                <div className="dropdown__content-user-link">
                  <button
                    type="button"
                    onClick={() => {
                      setSoftwareVersionModalOpen(true);
                      setToggleHeaderDropDown(null);
                    }}
                  >
                    Software Version
                  </button>
                </div>
              </div>
            </DropDown>
          </HeaderDropDown>
        </Tooltip>
      </div>
      {changePasswordModalOpen && (
        <ChangePasswordModal setModalOpen={setChangePasswordModalOpen} />
      )}
      {softwareVersionModalOpen && (
        <SoftwareVersionModal
          setModalOpen={setSoftwareVersionModalOpen}
          releaseVersion={releaseVersion || ''}
          apiURL={apiURLV4 || ''}
          gitBuild={gitBuild || ''}
        />
      )}
      {assumeRoleModalOpen && (
        <AssumeRoleModal
          modalOpen={setAssumeRoleModalOpen}
        />
      )}
      <SessionExpirationModal />
    </nav>
  );
}

export default Header;
