import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../../Common/Components/Button';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { GenericSensorTableData } from '../../../types/GenericSensorData';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { useAppContext } from '../../../utils/AppContext';
import { slideInWidth } from '../../../utils/constants';
import getHeaderProps from '../../../utils/getHeaderProps';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import RealTimeValues from '../Components/RealTimeValues';
import Loading from '../../../Common/Components/Loading';

function LightsEnergyMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite, fetchedBulkSensorData } = props;
  const { addNotification } = useAppContext();

  const headers = getHeaderProps('LightsEnergy');
  const [energyMultiTableData, setEnergyMultiTableData] = useState<GenericSensorTableData[]>();
  const [loading, setLoading] = useState(false);

  const timestamp = useRef<number>(0);

  const [openRealTimeValues, setOpenRealTimeValues] = useState(false);
  const [sensorRealTimeValue, setSelectedSensorRealTimeValue] = useState({ title: 'Select', key: '0' });
  const sensorListRealTimeValue = [
    { title: 'Active energy use', key: 'cumulativeEnergyUse' },
    { title: 'Reactive energy use', key: 'cumulativeReactiveEnergyUse' },
    { title: 'Fixture burn hours', key: 'cumulativeOnStateLuminaire' },
  ];
  const displayedSensors = ['cumulativeEnergyUse', 'cumulativeReactiveEnergyUse', 'cumulativeOnStateLuminaire'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(fetchedBulkSensorData).then((response) => {
      const newData = Sensors.getSensorTableWithDisplayedString(
        selectedItems,
        displayedSensors,
        response,
        selectedSite.timezone,
        { commissionedDate: { value: '' } },
        undefined,
      );
      // eslint-disable-next-line no-return-assign
      newData.forEach((e) => e.commissionedDate = Utils.getConvertedDate(selectedItems.get(e.nodeid)?.commissionedDate || '', undefined, selectedSite.timezone) || '--');
      setEnergyMultiTableData(newData);
    }).finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer.id, selectedItems, selectedSite.id, fetchedBulkSensorData]);

  const refreshSensors = useCallback(() => {
    setLoading(true);
    const currentDate = Date.now();
    timestamp.current = currentDate;

    const originalData = energyMultiTableData;
    const tmpDisplayedData = energyMultiTableData?.map((rowData) => Object.entries(rowData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? { ...acc, ...{ [e[0]]: '--' } } : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: rowData.nodeid }));
    setEnergyMultiTableData(tmpDisplayedData);

    Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      Array.from(selectedItems.values()).map((n) => n),
      reqSensors.map((name) => Sensors.getSensorId(name)),
    ).then((resp) => {
      if (timestamp.current <= currentDate) {
        const newData = Sensors.getSensorTableWithDisplayedString(
          selectedItems,
          reqSensors,
          resp,
          selectedSite.timezone,
          { commissionedDate: { value: '' } },
          originalData,
        );
        // eslint-disable-next-line no-return-assign
        newData.forEach((e) => e.commissionedDate = Utils.getConvertedDate(selectedItems.get(e.nodeid)?.commissionedDate || '', undefined, selectedSite.timezone) || '--');
        setEnergyMultiTableData(newData);
      }
    }).catch((e) => {
      addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
    }).finally(() => setLoading(false));
  }, [addNotification, energyMultiTableData, reqSensors, selectedCustomer.id, selectedItems, selectedSite.id, selectedSite.timezone]);

  return (
    <div className="energy-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Energy</span>
            <div className="slide-in__title-subtitle">
              <span>
                <span>Lifetime cumulative values</span>
                <Tooltip
                  addTooltipClass="energy-tooltip"
                  text="Energy use, node burn hours, and fixture burn hours are calculated over the lifetime of the installed node."
                >
                  <InfoIcon />
                </Tooltip>
              </span>
              <div className="slide-in__link">
                <Button
                  onClick={() => setOpenRealTimeValues(!openRealTimeValues)}
                  label="Get real time values"
                />
              </div>
            </div>
          </div>
          {loading ? <Loading isLoading={loading} /> : (
            <div className="slide-in__content">
              <div className="table">
                <Table
                  headers={headers}
                  data={energyMultiTableData}
                  fixColCount={1}
                  dark
                />
              </div>
            </div>
          )}
        </>
      </SlideIn>
      {openRealTimeValues && (
      <RealTimeValues
        sensor={sensorRealTimeValue}
        sensorList={sensorListRealTimeValue}
        sensorSelectboxAction={(item: SelectBoxItemType) => setSelectedSensorRealTimeValue(item)}
        closeButton
        closeButtonAction={() => setOpenRealTimeValues(false)}
        refreshValue={() => {
          setReqSensors([sensorRealTimeValue.key]);
          refreshSensors();
          setOpenRealTimeValues(false);
        }}
      />
      )}
    </div>
  );
}

export default LightsEnergyMulti;
