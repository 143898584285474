import React from 'react';
import { TableToolbarSubtitleProps } from '../../../../types/TableToolbarSubtitleProps';

import { ReactComponent as DownloadIcon } from '../../../../img/icons/download.svg';

function TableToolbarSubtitle(props: TableToolbarSubtitleProps): JSX.Element {
  const { listLength, download, subtitle = 'Listed' } = props;

  return (
    <div className="tabletoolbar-subtitle">
      {listLength > 0
  && (
    <>
      <span className="tabletoolbar-subtitle__bold">
        {listLength}
        {' '}
      </span>
      {subtitle}
      <button type="button" className="tabletoolbar-subtitle__download">
        <DownloadIcon onClick={() => download()} />
      </button>
    </>
  )}
    </div>
  );
}

export default TableToolbarSubtitle;
