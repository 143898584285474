import React from 'react';
import { DropDownPropsType } from '../../types/DropDownPropsType';
import Button from './Button';

function DropDown(props: DropDownPropsType): JSX.Element {
  const {
    title,
    subtitle,
    children,
    primaryButtonLabel,
    primaryButtonAction,
    primaryButtonType = 'textlink',
    primaryButtonSize = 'medium',
    secondaryButtonSize = 'medium',
    secondaryButtonLabel,
    secondaryButtonAction,
    secondaryButtonType = 'textlink',
    className,
  } = props;

  return (
    <div className={`dropdown ${className || ''}`}>
      {title && (
        <div className={`dropdown__title ${subtitle ? '' : 'space-bottom'}`}>
          {title}
        </div>
      )}
      {subtitle && <div className="dropdown__subtitle">{subtitle}</div>}
      <div className="dropdown__content">{children}</div>
      {(primaryButtonLabel || secondaryButtonLabel) && (
        <div className="dropdown__footer">
          {primaryButtonLabel && primaryButtonAction && (
            <Button
              buttonType={primaryButtonType}
              buttonSize={primaryButtonSize}
              onClick={() => primaryButtonAction()}
              label={primaryButtonLabel}
            />
          )}
          {secondaryButtonLabel && secondaryButtonAction && (
            <Button
              buttonType={secondaryButtonType}
              buttonSize={secondaryButtonSize}
              onClick={() => secondaryButtonAction()}
              label={secondaryButtonLabel}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DropDown;
