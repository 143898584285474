import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import Textbox from '../../../../../Common/Components/Textbox';
import { DeleteNodesProps } from '../../../../../types/DeleteNodesProps';
import { useAppContext } from '../../../../../utils/AppContext';
import { putRequest, deleteRequestAll } from '../../../../../utils/fetch';
import formValidation from '../../../../../utils/form/formValidation';

function DeleteNodes(props: DeleteNodesProps): JSX.Element {
  const {
    setOpenModal,
    selectedCustomer,
    selectedSite,
    selectedNodes,
    updateSiteAssigment,
  } = props;

  const { addNotification } = useAppContext();

  const [editTextBox, _setEditTextBox] = useState({
    editErrorMsg: '',
    error: false,
    value: '',
  });

  const setEditTextBox = (val: string) => {
    const validation = formValidation(
      val,
      { max: 45 },
    );

    _setEditTextBox((oldVal) => ({
      value: validation.hasError ? editTextBox.value : val,
      editErrorMsg: validation.errorMsg,
      error: validation.hasError,
    }));
  };
  return (
    <div className="multi-node-modals">
      <div className="manual-override">
        <Modal
          title="Delete nodes"
          width="335"
          setModalOpen={() => setOpenModal(0)}
          primaryButtonLabel="Submit"
          primaryButtonAction={async () => {
            if (selectedNodes.size > 0) {
              if (editTextBox.value !== '') {
                const putBody = {
                  nodeids: Array.from(selectedNodes.keys()),
                  note: editTextBox.value,
                };

                try {
                  const resp = await putRequest(
                    '/nodes/note',
                    putBody,
                  );

                  if (!resp.error) {
                    addNotification({ type: 'success', message: 'Your "Edit note" operation was successful' });
                  } else {
                    addNotification({ type: 'error', message: 'Your "Edit note" operation has failed.' });
                  }
                } catch (error) {
                  addNotification({ type: 'error', message: 'Your "Edit note" operation has failed.' });
                }
              }

              try {
                const requests: { path: string }[] = Array.from(selectedNodes.values()).map(
                  (value) => ({
                    path: `/organizations/${selectedCustomer.id}/nodes/${value.node_id}`,
                  }),
                );
                deleteRequestAll(requests)
                  .then((results) => {
                    if (results.resolved === requests.length) {
                      addNotification({ type: 'info', message: 'Nodes have been successfully deleted.' });
                      updateSiteAssigment();
                    } else {
                      addNotification({ type: 'error', message: 'Your "Delete nodes" operation has failed.' });
                    }
                  });
              } catch (e) {
                addNotification({ type: 'error', message: 'Your "Delete nodes" operation has failed.' });
              }
              setOpenModal(0);
            }
          }}
          secondaryButtonLabel="Cancel"
          secondaryButtonAction={() => {
            setOpenModal(0);
          }}
          borderTopColor="yellow"
        >
          <div className="subtitle">
            Delete nodes from
            <span>
              {' '}
              {selectedSite.name}
            </span>
            ?
          </div>
          <div className="content">
            <div className="content-selected-nodes">
              <span className="content-selected-nodes-title">Delete</span>
              <span className="content-selected-nodes-big">{selectedNodes.size}</span>
              <span>{selectedNodes.size > 1 ? 'Nodes selected' : 'Node selected'}</span>
            </div>
          </div>
          <div className="edit-note">
            <span className="edit-note__title">Change note for each node</span>
            <Textbox
              label="Note"
              error={editTextBox.error}
              errorMessage={editTextBox.editErrorMsg}
              placeholder="Edit note"
              name="Name"
              type="text"
              value={editTextBox.value}
              onChange={(e) => setEditTextBox(e.target.value)}
            />
            <span className="edit-note__info">Max 45 characters</span>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default DeleteNodes;
