import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import { AutoOpenSelectboxListType } from '../../../../types/AutoOpenSelectboxPropsType';
import { AddNodesToOrgGroupProps } from '../../../../types/GroupActionsPropTypes';
// import { LightsGroupsItem } from '../../../../types/LightsGroupProps';
import { postRequest } from '../../../../utils/fetch';
import GroupActionsOrgGroup from '../GroupActionsOrgGroup';
import { httpAccepted, httpOk } from '../../../../utils/constants';
import Loading from '../../../../Common/Components/Loading';

function AddNodesToOrgGroup(props: AddNodesToOrgGroupProps): JSX.Element {
  const { setOpenModal, selectedNodes, orgGroups, addNotification, selectedCustomer, selectedSite, updateGroup } = props;
  const [selectedOrgGroup, setSelectedOrgGroup] = useState<AutoOpenSelectboxListType>({ groupId: '', name: '' });
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      title="Add nodes to org group"
      width="546"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        try {
          if (selectedOrgGroup.groupId === '') {
            addNotification({ type: 'error', message: 'Please select an org group.' });
          } else {
            let count = 0;
            const nodeLimit = 10000;
            while (count < Array.from(selectedNodes.keys()).length / nodeLimit) {
              setLoading(true);

              const newNodeList = Array.from(selectedNodes.keys()).slice(count * nodeLimit, count * nodeLimit + nodeLimit);

              const postBody = {
                operation: 'ADD_NODES',
                targetGroupId: selectedOrgGroup.groupId,
                nodeIds: newNodeList,
              };

              // eslint-disable-next-line no-await-in-loop
              const result = await postRequest(
                `/organizations/${selectedCustomer?.id}/sites/${selectedSite?.id}/async-jobs/group-move`,
                postBody,
              );

              if ((count + 1 > Array.from(selectedNodes.keys()).length / nodeLimit)) {
                setLoading(false);
                addNotification({ type: 'success', message: 'Your "Add nodes to org group" operation has completed.' });
                updateGroup();
                setOpenModal(0);
              }
              if (result.status === httpAccepted || httpOk) {
                count += 1;
              }
            }
          }
        } catch (e) {
          addNotification({ type: 'error', message: 'Your "Add nodes to org group" operation has failed.' });
        }
      }}
    >
      <Loading isLoading={loading} />
      <GroupActionsOrgGroup
        title="Add selected nodes"
        comment="Nodes will be added to all selected org group."
        selectedNodes={selectedNodes}
        orgGroups={orgGroups.filter((group) => Array.from(selectedNodes.keys()).some((id) => !group.nodeList?.includes(id)))}
        selectedOrgGroup={selectedOrgGroup}
        setSelectedOrgGroup={setSelectedOrgGroup}
      />
    </Modal>
  );
}

export default AddNodesToOrgGroup;
