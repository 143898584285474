import React, { useState } from 'react';
import Datepicker from './Datepicker';
import RadioButtons from './RadioButtons';
import { RadioButtonElementProps } from '../../types/RadioButtonsProps';
import { WizardPeriodSelectorProps } from '../../types/WizardDateAndColumnSelectorProps';
import Utils from '../../utils/Utils';

function WizardPeriodSelector(props: WizardPeriodSelectorProps): JSX.Element {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    granularity,
    setGranularity,
    addClass = '',
  } = props;

  const granularityOptions = [
    'MONTH',
    'DAY',
    'HOUR',
    '15_MIN',
  ];

  const [selectedGranularity, setSelectedGranularity] = useState<RadioButtonElementProps>({ key: granularity, label: Utils.getGranularityOptionString(granularity) });

  return (
    <div className={`date-period-selector ${addClass}`}>
      <div className="date-period-selector__instruction">Select date range</div>
      <Datepicker
        placement="bottom"
        selectedDate={startDate}
        setSelectedDate={setStartDate}
        popperPosition="popup"
        placeholder="Select date"
        label="Start date"
        type="light"
        maxDate={endDate || new Date(Date.now())}
        defaultEmpty
        required
      />
      <Datepicker
        placement="bottom"
        selectedDate={endDate}
        setSelectedDate={setEndDate}
        popperPosition="popup"
        placeholder="Select date"
        label="End date"
        type="light"
        minDate={startDate}
        maxDate={new Date(Date.now())}
        defaultEmpty
        required
      />
      <div className="date-period-selector__radio-title margin-top-15"> Select data interval </div>
      <RadioButtons
        onClick={(clickedRadioBtn: RadioButtonElementProps) => {
          setGranularity(clickedRadioBtn.key);
          setSelectedGranularity(clickedRadioBtn);
        }}
        selected={selectedGranularity}
        list={granularityOptions.map((e) => ({ key: e, label: Utils.getGranularityOptionString(e) }))}
        type="light"
        extraClasses="vertical"
      />
    </div>
  );
}

export default WizardPeriodSelector;
