import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { FotaCampaignDetails, FotaCampaignDetailsModalProps } from '../../../../../types/FotaCampaigns';
import Modal from '../../../../../Common/Components/Modal';
import getHeaderProps from '../../../../../utils/getHeaderProps';
import Utils from '../../../../../utils/Utils';
import { ReactComponent as SyncIcon } from '../../../../../img/icons/sync.svg';
import Loading from '../../../../../Common/Components/Loading';
import Table from '../../../../../Common/Components/Table';

function FotaCampaignDetailsModal(props: FotaCampaignDetailsModalProps): JSX.Element {
  const { campaign, setOpenModal, getStateDot } = props;

  const headers = getHeaderProps('FotaCampaignDetails');
  const [fotaCampaignDetails, setFotaCampaignDetails] = useState<FotaCampaignDetails[]>();

  const { data: fotaCampaignDetailsResp, mutate: updateFotaCampaignDetails, isValidating } = useSWR<FotaCampaignDetails[]>(
    [`/fota/campaign/${campaign.campaignId}/details`, `GetFotaCampaignDetails-${campaign.campaignId}`],
  );

  useEffect(() => {
    if (fotaCampaignDetailsResp && fotaCampaignDetailsResp.length > 0) {
      setFotaCampaignDetails(fotaCampaignDetailsResp.map((item) => {
        const tableItem = { ...item };
        tableItem.opStatus = (tableItem.opStatus) ? 'True' : 'False';
        tableItem.taggedAt = (tableItem.taggedAt) ? Utils.getConvertedDate((new Date(tableItem.taggedAt))) : 'Scheduled';
        tableItem.downloadedAt = (tableItem.downloadedAt) ? Utils.getConvertedDate((new Date(tableItem.downloadedAt))) : 'FW not yet downloaded';
        tableItem.lastUpdate = (tableItem.lastUpdate) ? Utils.getConvertedDate((new Date(tableItem.lastUpdate))) : 'No status updates yet';

        return tableItem;
      }));
    } else {
      setFotaCampaignDetails([]);
    }
  }, [fotaCampaignDetailsResp]);

  const [filteredTableData, setFilteredTableData] = useState([]);

  return (
    <Modal
      className="fota-campaign-details-modal"
      width="1230"
      height="660"
      setModalOpen={() => setOpenModal(0)}
      title={campaign.name}
      primaryButtonLabel="Close"
      primaryButtonAction={() => setOpenModal(0)}
      secondaryButtonLabel="Download CSV"
      secondaryButtonAction={() => Utils.downloadCSV(headers, filteredTableData || [], `fota-campaign-${campaign.name}`, true)}
      hasTable
    >
      <>
        {(!isValidating) && (
        <SyncIcon
          data-testid="icon"
          className="list-element__refresh refreshFotaCampaignDetails"
          onClick={() => {
            updateFotaCampaignDetails();
          }}
        />
        )}
        {(isValidating) && (
        <SyncIcon
          data-testid="icon"
          className="list-element__refresh refreshLoading refreshFotaCampaignDetails"
        />
        )}
      </>
      <div className="fota-campaign-details-header">
        <div>
          <div className="fota-campaign-details-header__nodes">
            <div>Nodes</div>
            <span>{campaign.numberOfNodes}</span>
          </div>
          <div className="fota-campaign-details-header__status">
            <div>Campaign status</div>
            <span>{getStateDot(campaign.state || '')}</span>
          </div>
          <div className="fota-campaign-details-header__enddate">
            <div>End date</div>
            {campaign.endDate ? Utils.getConvertedDate(new Date(campaign.endDate), 'LL/dd/yyyy') : 'N/A'}
          </div>
        </div>
      </div>
      <div className="fota-campaign-details-container">
        {(fotaCampaignDetails && Array.isArray(fotaCampaignDetails))
          ? (
            <div className="table table--light">
              <Table
                headers={headers}
                data={fotaCampaignDetails}
                setFilteredTableData={setFilteredTableData}
                dark={false}
                skipCellMeasure
              />
            </div>
          ) : (
            <Loading />
          )}
      </div>
    </Modal>
  );
}

export default FotaCampaignDetailsModal;
