/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { RadioButtonElementProps, RadioButtonsProps } from '../../types/RadioButtonsProps';

function RadioButtons(props: RadioButtonsProps): JSX.Element {
  const { list, selected, onClick, type, extraClasses = '', disabled } = props;

  return (
    <div className={`radio-buttons ${type === 'light' ? 'light' : 'dark'} ${extraClasses}`}>
      {
        list.map((radioButton: RadioButtonElementProps) => (
          <div
            className="radio-buttons__element"
            onClick={() => {
              if (!disabled) {
                onClick(radioButton);
              }
            }}
            key={radioButton.key}
          >
            <div className={`radio-buttons__element-circle ${selected.key === radioButton.key ? 'selected' : ''}`} />
            <div className="radio-buttons__element-label">{radioButton.label}</div>
          </div>
        ))
      }
    </div>
  );
}

export default RadioButtons;
