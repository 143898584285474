import { useState } from 'react';
import { GroupsPageProps } from '../../types/GroupsPageProps';

export default function useGroupPageState(): GroupsPageProps {
  const [selectedGroupIds, _setSelectedGroupIds] = useState<Record<string, Array<string>>>({
    lightingGroupIds: [],
    orgGroupIds: [],
  });
  const [activeToolbarBtn, setActiveToolbarBtn] = useState<number>(0);

  const setSelectedGroupIds = (newValues: Array<string>, type: string) => {
    _setSelectedGroupIds((oldValues) => {
      const tmpValues = { ...oldValues };
      tmpValues[type] = newValues;

      return tmpValues;
    });
  };

  const resetSelectedGroupIds = () => {
    _setSelectedGroupIds({
      lightingGroupIds: [],
      orgGroupIds: [],
    });
  };

  const [toggleCreateGroup, setToggleCreateGroup] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEnergyModal, setOpenEnergyModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmDeleteGroup, setConfirmDeleteGroup] = useState(false);

  return {
    selectedGroupIds,
    setSelectedGroupIds,
    resetSelectedGroupIds,
    activeToolbarBtn,
    setActiveToolbarBtn,
    toggleCreateGroup,
    setToggleCreateGroup,
    openEditModal,
    setOpenEditModal,
    openEnergyModal,
    setOpenEnergyModal,
    openDeleteModal,
    setOpenDeleteModal,
    confirmDeleteGroup,
    setConfirmDeleteGroup,
  };
}
