import React from 'react';
import { ToolbarItemPropsType } from '../../types/ToolbarItemPropsType';

function ToolbarItem(props: ToolbarItemPropsType): JSX.Element {
  const {
    children,
    icon,
    onclick,
    tabPanelisActive,
    order,
    addClass,
    fields,
    content,
    vertical,
  } = props;

  return (
    <div className={`toolbar-tabs__container-tabset-container ${addClass || ''}`}>
      <div
        role="button"
        onClickCapture={() => onclick(order)}
        className={`toolbar-tabs__container-tabset-btn ${order === tabPanelisActive ? 'active' : ''}`}
      >
        <span className="toolbar-tabs__container-tabset-btn-content">
          <div className="toolbaritem">
            {content || <></>}
            {!content
              && (
              <>
                {icon || <></>}
                <div className="toolbaritem__field-section">
                  {fields?.map((f, idx) => (
                    // eslint-disable-next-line prefer-template
                    <div data-testid={`toolbaritem__field${f.value}`} className={'toolbaritem__field' + (vertical ? ' vertical' : '')} key={idx}>
                      <div className="toolbaritem__field-name">
                        {f.name}
                      </div>
                      {f.value.length > 0
                        && (
                        <div className="toolbaritem__field-value">
                          {f.value}
                        </div>
                        )}
                    </div>
                  ))}
                </div>
              </>
              )}
          </div>
        </span>
      </div>
      {order === tabPanelisActive && children}
    </div>
  );
}

export default ToolbarItem;
