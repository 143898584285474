import React from 'react';
import DropDown from '../../../Common/Components/DropDown';
import LightingGroupsList from './LightingGroupsList';
import { LightingGroupScheduleListProps } from '../../../types/LightingGroupScheduleList';

function LightingGroupScheduleList(props: LightingGroupScheduleListProps): JSX.Element {
  const {
    groups = [],
    handleActiveToolbar,
    selectedGroups,
    setSelectedGroups,
    groupsSchedule,
    selectedDate,
    schedules,
    updateSchedules,
    setActiveSchedule,
    setOpenScheduleModal,
    selectedCustomer,
    selectedSite,
  } = props;

  const modalScheduleView = 3;

  return (
    <DropDown
      className="dropdown-lighting-group-list"
    >
      <div className="dropdown-schedule-list__content">
        <LightingGroupsList
          data={groups}
          selectedItems={selectedGroups}
          setSelectedItems={setSelectedGroups}
          groupsSchedule={groupsSchedule}
          selectedDate={selectedDate}
          schedules={schedules}
          updateSchedules={updateSchedules}
          setActiveSchedule={setActiveSchedule}
          setOpenScheduleModal={() => {
            setOpenScheduleModal(modalScheduleView);
            handleActiveToolbar(0);
          }}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
        />
      </div>
    </DropDown>
  );
}

export default LightingGroupScheduleList;
