import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from '../../../Common/Components/Modal';
import { JobDetailsPropsType, JobDetailItem } from '../../../types/JobDetails';
import { JobDetailsObject, JobDetailsItem } from '../../../types/JobDetailsObject';
import Table from '../../../Common/Components/Table';
import Utils from '../../../utils/Utils';
import Loading from '../../../Common/Components/Loading';
import { getRequest } from '../../../utils/fetch';

import { ReactComponent as JobSuccessIcon } from '../../../img/icons/job-success.svg';
import { ReactComponent as JobErrorIcon } from '../../../img/icons/job-error.svg';
import { ReactComponent as JobSyncIcon } from '../../../img/icons/job-sync.svg';
import { ReactComponent as JobStoppedIcon } from '../../../img/icons/job-stopped.svg';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';

import CopyJobId from './CopyJobId';
import JobDeleteGroup from './JobDeleteGroup';
import { jobStatusMsg, jobTypeMsg } from '../../../utils/constants';

function JobDetails(props: JobDetailsPropsType): JSX.Element {
  const {
    headers,
    job,
    customer,
    site,
    setModalOpen,
  } = props;

  const fixColCount = 2;

  const history = useHistory();

  function StatusCell(status: string) {
    switch (status) {
      case 'In process':
        return (
          <span>
            <JobSyncIcon />
            <span className="jobdetails-container__stopped"> In process</span>
          </span>
        );
        break;
      case 'Failed':
        return (<span className="jobdetails-container__failed">Failed</span>);
        break;
      case 'Succeeded':
        return (<span className="jobdetails-container__succeeded">Succeeded</span>);
        break;
      case 'Stopped':
        return (<span className="jobdetails-container__stopped">Stopped</span>);
        break;
      case 'Skipped':
        return (<span className="jobdetails-container__stopped">Skipped</span>);
        break;
      default:
        return (<span />);
        break;
    }
  }

  const [filteredTableData, setFilteredTableData] = useState([]);

  const downloadJobDetails = (data: Record<string, string>[]) => {
    const fileName = 'job_details';
    let csvHeaders = headers;
    csvHeaders = csvHeaders.map((header) => {
      if (header.key === 'statusFmt') {
        return { key: 'status', val: 'Status' };
      }
      return header;
    });
    Utils.downloadCSV(csvHeaders, filteredTableData, fileName);
  };

  const defaultJobDetailFilterSettings: Record<string, string> = {
    status: 'All',
  };

  const [jobDetailFilterSettings, setJobDetailFilter] = useState(defaultJobDetailFilterSettings);

  const [filteredDetails, setFilteredDetails] = useState<JobDetailItem[]>([]);
  const [refreshLoadingDetails, setRefreshLoadingDetails] = useState(true);
  const [refreshSpinning, setRefreshSpinning] = useState(false);

  const [jobDetailsResp, setJobDetailsResp] = useState<JobDetailsObject>({} as JobDetailsObject);
  const [jobDetailsItemsResp, setJobDetailsItemsResp] = useState<JobDetailsItem[]>({} as JobDetailsItem[]);

  const jobdetailsPath = `/organizations/${customer.id}/sites/${site.id}/async-jobs/${job.id}`;
  const jobdetailsItemsPath = `/organizations/${customer.id}/sites/${site.id}/async-jobs/${job.id}/items`;

  useEffect(() => {
    if (site.id && job.id && refreshLoadingDetails) {
      getRequest(jobdetailsPath)
        .then((responses1) => {
          getRequest(jobdetailsItemsPath)
            .then((responses2) => {
              setRefreshLoadingDetails(false);
              setRefreshSpinning(false);
              setJobDetailsResp(responses1);
              setJobDetailsItemsResp(responses2);
            });
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshLoadingDetails]);

  useEffect(
    () => {
    // Get true (nodes-only) stats from details array
      /* if (jobDetailsResp && jobDetailsItemsResp) {
        jobDetailsResp.stats = { total: 0, processed: 0, passed: 0, failed: 0, skipped: 0, stopped: 0 };
        // jobDetailsResp.details.forEach((detail) => {
        jobDetailsItemsResp.forEach((jobitems) => {
          if (jobDetailsResp.stats) {
            jobDetailsResp.stats.total += 1;
            switch (jobDetailsResp.status) {
              case 'FAILED': jobDetailsResp.stats.failed += 1; jobDetailsResp.stats.processed += 1; break;
              case 'COMPLETED':
              case 'FINISHED': jobDetailsResp.stats.passed += 1; jobDetailsResp.stats.processed += 1; break;
              case 'SKIPPED': jobDetailsResp.stats.skipped += 1; jobDetailsResp.stats.processed += 1; break;
              case 'ABORTED':
              case 'STOPPED': jobDetailsResp.stats.stopped += 1; jobDetailsResp.stats.processed += 1; break;
              default: break;
            }
          }
        });
        // });
      } */
    },
    [jobDetailsResp,
      jobDetailsItemsResp,
    ],
  );

  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  useEffect(
    () => {
      if (Array.isArray(jobDetailsItemsResp) && jobDetailsItemsResp?.length === 0) {
        setFilteredDetails([]);
        forceUpdate();
      } else if (jobDetailsItemsResp?.length && Array.isArray(jobDetailsItemsResp)) {
      // create array of table rows based on operation type
      // eslint-disable-next-line react-hooks/exhaustive-deps
        let jobDetailsData: JobDetailItem[] = [];
        switch (jobDetailsResp?.operationType) {
          case 'CREATE_GROUP':
          case 'UPDATE_GROUP':
          case 'ADD_NODES':
          case 'REMOVE_NODES':
          case 'DELETE_NODES':
          case 'ASSIGN_NODE_TO_SITE':
            jobDetailsData = jobDetailsItemsResp.filter((detail: {type: string}) => (
              detail.type === 'NODE' || 'GROUP'
            )).map((detail) => (
              {
                entityId: detail?.entityId,
                name: detail.data?.nodeName,
                fromgroup: detail.data?.fromGroup?.name,
                togroup: detail.data?.toGroup?.name,
                status: {
                  FAILED: 'Failed',
                  RUNNING: 'In process',
                  CREATING: 'In process',
                  CREATED: 'In process',
                  SCHEDULED: 'In process',
                  STARTED: 'In process',
                  SKIPPED: 'Skipped',
                  COMPLETED: 'Succeeded',
                  FINISHED: 'Succeeded',
                  ABORTED: 'Stopped',
                }[detail?.status] || 'Unknown',
                statusFmtTableFilter: detail?.status,
                statusFmtTableSort: detail?.status,
                type: detail?.type,
                operation: detail?.operation,
                data: detail?.data,
                failmessage: detail?.failMessage || '---',
                updated: detail?.updated ? Utils.convertISOtoJobTime(detail.updated, site.time_zone) : '---',
              }
            ));
            break;
          default:
            break;
        }

        let details: JobDetailItem[] = jobDetailsData.filter((detail: JobDetailItem) => {
          switch (jobDetailFilterSettings.status) {
            case 'All':
              return true;
              break;
            case 'processed':
              return detail.status !== 'In process';
              break;
            case 'passed':
              return detail.status === 'Succeeded';
              break;
            default:
              return detail.status === jobDetailFilterSettings.status;
              break;
          }
        });
        details = details.map((detail) => {
          const d = detail;
          d.statusFmt = StatusCell(detail.status);
          return d;
        });
        setFilteredDetails(details);
        forceUpdate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forceUpdate,
      jobDetailFilterSettings,
      jobDetailsResp,
      jobDetailsItemsResp,
    ],
  );

  // eslint-disable-next-line consistent-return
  const jobStatusDisplay = (status: string) => {
    switch (status) {
      case 'COMPLETED':
        return (
          <span className="jobs-list-item__status job-succeeded">
            <JobSuccessIcon />
            {jobStatusMsg[status]}
          </span>
        );
      case 'RUNNING':
      case 'CREATING':
        return (
          <span className="jobs-list-item__status job-in-progress">
            <JobSyncIcon />
            {jobStatusMsg[status]}
          </span>
        );
      case 'FAILED':
        return (
          <span className="jobs-list-item__status job-failed">
            <JobErrorIcon />
            {jobStatusMsg[status]}
          </span>
        );
      case 'ABORTED':
        return (
          <span className="jobs-list-item__status job-stopped">
            <JobStoppedIcon />
            {jobStatusMsg[status]}
          </span>
        );
      default:
        break;
    }
  };

  if (job.operationType === 'DELETE_GROUP') {
    return (
      <JobDeleteGroup
        job={job}
        setModalOpen={setModalOpen}
        message={job.failMessage || 'The delete group operation failed.'}
      />
    );
  }

  return (
    <Modal
      className="jobdetails-modal"
      width="1246"
      height="659"
      setModalOpen={setModalOpen}
      title={jobTypeMsg[job.operationType]}
      primaryButtonLabel="View on lights page"
      primaryButtonAction={() => history.push('/lights', { selectedNodes: filteredDetails.map((detail) => detail.entityId) })}
      secondaryButtonLabel="Export CSV"
      secondaryButtonAction={() => {
        downloadJobDetails(
          filteredDetails.map((detail:JobDetailItem) => (
            {
              entityId: detail?.entityId,
              name: detail.data?.nodeName,
              fromgroup: detail.data?.fromGroup?.name,
              togroup: detail.data?.toGroup?.name,
              status: detail?.status,
              failmessage: detail?.failmessage,
              updated: detail?.updated,
            }
          )),
        );
      }}
    >
      <CopyJobId
        text1="Click to copy Job ID"
        text2="Job ID copied to clipboard!"
        value={job.id}
      />
      <>
        {(!refreshSpinning) && (
        <SyncIcon
          data-testid="icon"
          className="list-element__refresh refreshJobDetails"
          onClick={() => {
            setRefreshLoadingDetails(!refreshLoadingDetails);
            setRefreshSpinning(!refreshSpinning);
          }}
        />
        )}
        {(refreshSpinning) && (
        <SyncIcon
          data-testid="icon"
          className="list-element__refresh refreshLoading refreshJobDetails"
        />
        )}
      </>
      <div className="jobdetails-header">
        <div className="jobdetails-header__startdate">
          <div>Start date</div>
          <span>{site ? Utils.convertISOtoJobTime(job.taskCreationTime, site.time_zone) : ''}</span>
        </div>
        <div className="jobdetails-header__enddate">
          <div>End date</div>
          <span>{site ? Utils.convertISOtoJobTime(job.finishTime, site.time_zone) : ''}</span>
        </div>
        <div className="jobdetails-header__status">
          <div>Job status</div>
          {jobDetailsResp ? jobStatusDisplay(jobDetailsResp.status) : ''}
        </div>
        <div className="jobdetails-header__username">
          <div>User</div>
          <span>{job.username}</span>
        </div>
      </div>
      <div className="jobdetails-summary">
        <div
          className="jobdetails-summary__total"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'All' })}
        >
          <div>Total Items</div>
          <div>{jobDetailsResp?.stats?.total?.toLocaleString() || '--'}</div>
        </div>
        <div
          className="jobdetails-summary__processed"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'processed' })}
        >
          <div>Processed</div>
          <div>{jobDetailsResp?.stats?.processed?.toLocaleString() || '--'}</div>
        </div>
        <div
          className="jobdetails-summary__succeeded"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'passed' })}
        >
          <div>Succeeded</div>
          <span>{jobDetailsResp?.stats?.passed?.toLocaleString() || '--'}</span>
        </div>
        <div
          className="jobdetails-summary__failed"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'Failed' })}
        >
          <div>Failed</div>
          <span>{jobDetailsResp?.stats?.failed?.toLocaleString() || '--'}</span>
        </div>
        <div
          className="jobdetails-summary__skipped"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'Skipped' })}
        >
          <div>Skipped</div>
          <div>{jobDetailsResp?.stats?.skipped?.toLocaleString() || '--'}</div>
        </div>
        <div
          className="jobdetails-summary__stopped"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'Stopped' })}
        >
          <div>Stopped</div>
          <div>{jobDetailsResp?.stats?.stopped?.toLocaleString() || '--'}</div>
        </div>
      </div>
      <div className="jobdetails-container">
        {jobDetailsResp && Array.isArray(jobDetailsItemsResp)
          ? (
            <div className="table table--light">
              <Table
                headers={headers}
                data={filteredDetails}
                setFilteredTableData={setFilteredTableData}
                fixColCount={fixColCount}
                skipCellMeasure
              />
            </div>
          ) : (
            <Loading />
          )}
      </div>
    </Modal>
  );
}

export default JobDetails;
