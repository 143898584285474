import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { SystemAlertModalProps } from '../../../../../types/SystemalertModalProps';
import { useAppContext } from '../../../../../utils/AppContext';
import Button from '../../../../../Common/Components/Button';
import Textbox from '../../../../../Common/Components/Textbox';
import { postRequest, putRequest } from '../../../../../utils/fetch';
import Utils from '../../../../../utils/Utils';

function SystemAlertModal(props: SystemAlertModalProps): JSX.Element {
  const { setOpenModal,
    selectedAlert,
    updateAlerts,
  } = props;

  const { addNotification } = useAppContext();

  const [comment, setComment] = useState(selectedAlert.comment);

  return (
    <div className="system-alert">
      <Modal
        title="System alert"
        width="1000"
        setModalOpen={() => setOpenModal(0)}
        secondaryButtonAction={() => setOpenModal(0)}
        secondaryButtonLabel="Close"
      >
        <div className="content">
          <div className="content-text">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>id:</td>
                    <td>{selectedAlert.id}</td>
                  </tr>
                  <tr>
                    <td>version:</td>
                    <td>{selectedAlert.version}</td>
                  </tr>
                  <tr>
                    <td>category:</td>
                    <td>{selectedAlert.category}</td>
                  </tr>
                  <tr>
                    <td>products:</td>
                    <td>{JSON.stringify(selectedAlert.products)}</td>
                  </tr>
                  <tr>
                    <td>severity:</td>
                    <td>{selectedAlert.severity}</td>
                  </tr>
                  <tr>
                    <td>status:</td>
                    <td>
                      {selectedAlert.status}
                      {selectedAlert.status === 'Open' && Utils.isSensityUserAdmin()
                        ? (
                          <Button
                            buttonType="secondary"
                            buttonSize="small"
                            label="Close and save comment"
                            onClick={async () => {
                              const payload = {
                                id: selectedAlert.id,
                                status: 'Closed',
                                comment,
                                closedBy: Utils.getUserInfo().user.email,
                              };
                              try {
                                const path = '/system-feedback-channel/system-alerts';
                                const resp = await putRequest(path, payload);
                                if (!resp.error) {
                                  addNotification({ type: 'success', message: 'System alert has been closed.' });
                                } else {
                                  addNotification({ type: 'error', message: 'Your "Close alert" operation has failed.' });
                                }
                              } catch (e) {
                                addNotification({ type: 'error', message: 'Your "Close alert" operation has failed.' });
                              }

                              setOpenModal(0);
                              updateAlerts();
                            }}
                          />
                        ) : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>message:</td>
                    <td>{selectedAlert.message}</td>
                  </tr>
                  <tr>
                    <td>cause:</td>
                    <td>{selectedAlert.cause}</td>
                  </tr>
                  <tr>
                    <td>remedy:</td>
                    <td>
                      {selectedAlert.remedy?.indexOf('Restart the reminder action') === 0 && Utils.isSensityUserAdmin()
                        && (
                          <span>
                            <Button
                              buttonType="secondary"
                              buttonSize="small"
                              label="Restart the reminder action"
                              onClick={async () => {
                                try {
                                  const path = `/system-feedback-channel/system-alerts/${selectedAlert.id}/action/restart-reminder`;
                                  const resp = await postRequest(path, undefined);
                                  if (!resp.error) {
                                    addNotification({ type: 'success', message: 'Reminder restart triggered.' });
                                  } else {
                                    addNotification({ type: 'error', message: 'Reminder restart failed.' });
                                  }
                                } catch (e) {
                                  addNotification({ type: 'error', message: 'Reminder restart failed.' });
                                }
                              }}
                            />
                          </span>
                        )}
                      {selectedAlert.remedy !== 'Restart the reminder action'
                        && (
                          <span>{ selectedAlert.remedy }</span>
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td>action:</td>
                    <td>{JSON.stringify(selectedAlert.action)}</td>
                  </tr>
                  <tr>
                    <td>metadata:</td>
                    <td><pre>{JSON.stringify(selectedAlert.metadata, undefined, 3)}</pre></td>
                  </tr>
                  <tr>
                    <td>source:</td>
                    <td>{selectedAlert.source}</td>
                  </tr>
                  <tr>
                    <td>comment:</td>
                    <td>
                      <Textbox
                        value={comment || ''}
                        onChange={(event) => setComment(event.target.value)}
                        type="textarea"
                        disabled={!Utils.isSensityUserAdmin()}
                      />
                      {Utils.isSensityUserAdmin() && (
                      <Button
                        buttonType="secondary"
                        buttonSize="small"
                        label="Save comment"
                        onClick={async () => {
                          const payload = {
                            id: selectedAlert.id,
                            comment,
                          };
                          try {
                            const path = '/system-feedback-channel/system-alerts';
                            const resp = await putRequest(path, payload);
                            if (!resp.error) {
                              addNotification({ type: 'success', message: 'System alert has been updated.' });
                            } else {
                              addNotification({ type: 'error', message: 'Your operation has failed.' });
                            }
                          } catch (e) {
                            addNotification({ type: 'error', message: 'Your operation has failed.' });
                          }

                          setOpenModal(0);
                          updateAlerts();
                        }}
                      />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>closedBy:</td>
                    <td>{selectedAlert.closedBy}</td>
                  </tr>
                  <tr>
                    <td>createdAt:</td>
                    <td>{selectedAlert.createdAt}</td>
                  </tr>
                  <tr>
                    <td>updatedAt:</td>
                    <td>{selectedAlert.updatedAt}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SystemAlertModal;
