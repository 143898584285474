import { ChangePasswordMfaFormProps, ChangePasswordMfaFormErrors, UseChangePasswordMfaForm, ChangePasswordFormProps } from '../../types/ChangePasswordProps';
import { SubmitFn, ValidateFn } from '../../types/useFormProps';
import { useAppContext } from '../AppContext';
import Utils from '../Utils';
import { postRequest } from '../fetch';

export default function useChangePasswordMfaForm(
  formValues: ChangePasswordFormProps,
  mfaSession: string,
): UseChangePasswordMfaForm {
  const { addNotification } = useAppContext();

  const validate: ValidateFn<ChangePasswordMfaFormProps, ChangePasswordMfaFormErrors> = (
    values: ChangePasswordMfaFormProps,
  ): ChangePasswordMfaFormErrors => {
    const errors = {} as ChangePasswordMfaFormErrors;

    if (!values.mfaCode) {
      errors.mfaCode = true;
    }

    return errors;
  };

  const submit: SubmitFn<ChangePasswordMfaFormProps> = async (
    formValuesMfa,
    setLoading,
    setModalOpen,
  ) => {
    setLoading(true);

    const resp = await postRequest<
      // eslint-disable-next-line camelcase
      { mfacode: string, session: string, password: string, old_password: string },
      { mfa?: boolean, session?: string}
    >(
      `/organizations/${Utils.getUserOrgId()}/users/${Utils.getUserID()}/mfa`,
      {
        mfacode: formValuesMfa.mfaCode,
        session: mfaSession,
        password: formValues.newPassword,
        old_password: formValues.oldPassword,
      },
    );

    setLoading(false);

    if (resp.error) {
      addNotification({ type: 'error', message: resp.error });
    } else {
      setModalOpen(false);
      addNotification({ type: 'success', message: 'Password updated successfully. Please log back in.' });
    }
  };

  return {
    validate,
    submit,
  };
}
