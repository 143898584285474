import React from 'react';
import { CollectGPSModalProps } from '../../types/CollectGPSModalProps';
import { useAppContext } from '../../utils/AppContext';
import { postRequest } from '../../utils/fetch';
import Modal from './Modal';

function CollectGPSModal(props: CollectGPSModalProps): JSX.Element {
  const { setModalOpen, nodeList } = props;
  const { addNotification } = useAppContext();
  return (
    <Modal
      title="Collect GPS info"
      width="360"
      setModalOpen={() => setModalOpen()}
      primaryButtonLabel="Confirm"
      primaryButtonAction={async () => {
        try {
          const path = `/nodes/${nodeList[0]}/request-gps`;
          const result = await postRequest(
            path,
          );
          if (!result.error) {
            addNotification({ type: 'info', message: 'Collect GPS command was sent to the node.' });
          } else {
            addNotification({ type: 'error', message: 'Collect GPS command failed to be sent to the node.' });
          }
          setModalOpen();
        } catch (e) {
          addNotification({ type: 'error', message: `Collect GPS command failed: ${e}` });
          setModalOpen();
        }
      }}
      secondaryButtonLabel="Cancel"
      className="commissioning"
    >
      <span>Are you sure you want to collect GPS info for this node?</span>
    </Modal>
  );
}

export default CollectGPSModal;
