import React, { useState } from 'react';

import { CopyJobIdPropsType } from '../../../types/CopyJobId';
import Tooltip from '../../../Common/Components/Tooltip';
import Button from '../../../Common/Components/Button';
import Utils from '../../../utils/Utils';

import { ReactComponent as JobIDIcon } from '../../../img/icons/job-id.svg';

function CopyJobId(props: CopyJobIdPropsType): JSX.Element {
  const {
    text1,
    text2,
    value,
  } = props;

  const tooltipOffset = 0;
  const ms = 1000;
  const [tooltipText, setTooltipText] = useState(text1);

  return (
    <div className="copyjobid">
      <div
        onMouseOut={() => setTimeout(() => setTooltipText(text1), ms)}
        onBlur={() => setTimeout(() => setTooltipText(text1), ms)}
      >
        <Tooltip text={tooltipText} offset={tooltipOffset}>
          <Button
            onClick={() => {
              Utils.copyToClipboard(value);
              setTooltipText(text2);
            }}
          >
            <JobIDIcon />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

export default CopyJobId;
