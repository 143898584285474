import React, { useRef, useState } from 'react';
import useSWR from 'swr';
import { usePerformanceMark, Stage } from '@cabify/prom-react';

import AdminSidebarConfigItems from '../Components/AdminSidebarConfigItems';
import AdminSidebar from '../../../Common/Components/AdminSidebar';
import Button from '../../../Common/Components/Button';
import { PageComponentProps } from '../../../types/PageComponentProps';
import { customAttributeFetcherFn } from '../../../utils/ApiDataHelpers';
import { CustomAttributeInstance } from '../../../types/CustomAttributes';
import Utils from '../../../utils/Utils';
import Table from '../../../Common/Components/Table';
import Modal from '../../../Common/Components/Modal';
import { useAppContext } from '../../../utils/AppContext';
import TableToolbar from '../../Admin/Pages/Components/TableToolbar';
import TableToolbarSubtitle from '../../Admin/Pages/Components/TableToolbarSubtitle';
import Loading from '../../../Common/Components/Loading';

function CustomAttributes(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;
  const { addNotification } = useAppContext();

  const { data: customAttributes, mutate: updateAttributes } = useSWR<Array<CustomAttributeInstance>>(
    selectedSite.id
      ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/lkp/custom-attributes`]
      : null,
    customAttributeFetcherFn,
  );

  const headers: Array<{key: string, val: string}> = [{ key: 'nodeId', val: 'Node ID' }];

  customAttributes?.forEach((ca) => {
    Object.keys(ca).forEach((key) => {
      if (!['attributes', 'instanceId', 'fixtureType', 'nodeId'].includes(key)) {
        if (!headers.find((h) => h.val === key)) {
          headers.push({ key, val: key });
        }
      }
    });
  });

  const [openModal, setOpenModal] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const downloadCustomAttributes = () => {
    if (customAttributes) {
      Utils.downloadCSV(headers, filteredTableData, 'custom_attributes');
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const handleFileUpload = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setUploadFile(file);
      setOpenModal(1);
    }
  };
  const clearInputFileAndCloseModal = () => {
    if (inputFile !== null && inputFile.current !== null) {
      inputFile.current.value = '';
    }
    setOpenModal(0);
  };

  const colWidthCalcFn = (col: number): number => {
    if (col <= 9) {
      return 300;
    }

    return 400;
  };

  usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'CustomAttributes');

  return (
    <>
      <div className="config__content custom-attributes">
        <AdminSidebar selectedSite={selectedSite} title="Site config">
          <AdminSidebarConfigItems path={window.location.pathname} />
        </AdminSidebar>
        <div className="page-content page-content--medium-margin page-content--large-bottom-margin">
          <TableToolbar
            title="Custom attributes"
            subtitle={<TableToolbarSubtitle listLength={customAttributes?.length || 0} download={() => downloadCustomAttributes()} />}
            addClass="btn-container"
          >
            <>
              {Utils.isAdminUser()
                && (
                <div className="tabletoolbar__children">
                  <Button
                    label="Upload GIS file"
                    buttonType="secondary"
                    onClick={() => handleFileUpload()}
                    extraClasses="config__content-toolbar-button"
                  />
                </div>
                )}
            </>
          </TableToolbar>
          <div className="table table--light top-border-light auto-height">
            <Table
              headers={headers}
              data={customAttributes}
              setFilteredTableData={setFilteredTableData}
              fixColCount={1}
              autoheight
              skipCellMeasure
              colWidthCalcFn={colWidthCalcFn}
            />
          </div>
        </div>
      </div>
      {openModal === 1 && (
        <Modal
          width="280"
          title="Upload GIS file"
          setModalOpen={() => setOpenModal(0)}
          primaryButtonLabel="Yes"
          primaryButtonAction={async () => {
            setIsLoading(true);
            try {
              const siteName = selectedSite.name.replace(/ |,/g, '_');
              const fileName = `${siteName}_${(new Date()).getTime()}.xlsx`;
              const resp = await Utils.uploadGISfile(`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/gis-import/file/${fileName}`, uploadFile);
              const uploadResp = await resp.json();

              if (uploadResp.error || resp.status >= 400) {
                const errorMessage = Utils.consolidateDuplicationErrorMessage(uploadResp.message);
                addNotification({ type: 'error', message: `Your "Upload GIS file" operation has failed: ${errorMessage || ''}` });
              } else {
                addNotification({ type: 'success', message: 'Your "Upload GIS file" operation has completed.' });
                updateAttributes();
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Upload GIS file" operation has failed' });
            }
            clearInputFileAndCloseModal();
            setIsLoading(false);
          }}
          secondaryButtonLabel="Cancel"
          secondaryButtonAction={() => clearInputFileAndCloseModal()}
        >
          <>
            <Loading isLoading={isLoading} />
            <div className="upload-confirm">
              <div className="upload-confirm__content">
                <span>Are you sure you want to upload a new GIS file?</span>
                <span>This action will override the existing custom attributes.</span>
              </div>
            </div>
          </>
        </Modal>
      )}
      <input type="file" id="file" ref={inputFile} accept=".xlsx" style={{ display: 'none' }} onChange={onChangeFileUpload} />
    </>
  );
}

export default CustomAttributes;
