import React from 'react';
import AnalysisSidebarItem from '../../../Common/Components/AnalysisSidebarItem';
import { AnalysisSidebarItemsProps } from '../../../types/AnalysisSidebarItems';
// not the exact icons
import { ReactComponent as NetworkIcon } from '../../../img/icons/network.svg';
import { ReactComponent as EnergyIcon } from '../../../img/icons/energy.svg';
import { ReactComponent as PowerIcon } from '../../../img/icons/electric-utility.svg';
import { reportTypeTitles } from '../../../utils/constants';

function AnalysisSidebarItems(props: AnalysisSidebarItemsProps): JSX.Element {
  const { reportType, setReportType, setListReportDefinitions, setIsDashboard, isDashboard } = props;

  return (
    <>
      <AnalysisSidebarItem
        icon={<NetworkIcon />}
        title={reportTypeTitles.NETWORK}
        type="NETWORK"
        reportType={reportType}
        setReportType={setReportType}
        setListReportDefinitions={setListReportDefinitions}
        setIsDashboard={setIsDashboard}
        isDashboard={isDashboard}
      />
      <AnalysisSidebarItem
        icon={<EnergyIcon />}
        title={reportTypeTitles.ENERGY}
        type="ENERGY"
        reportType={reportType}
        setReportType={setReportType}
        setListReportDefinitions={setListReportDefinitions}
        setIsDashboard={setIsDashboard}
        isDashboard={isDashboard}
      />
      <AnalysisSidebarItem
        icon={<PowerIcon />}
        title={reportTypeTitles.POWER_QUALITY}
        type="POWER_QUALITY"
        reportType={reportType}
        setReportType={setReportType}
        setListReportDefinitions={setListReportDefinitions}
        setIsDashboard={setIsDashboard}
        isDashboard={isDashboard}
      />
    </>
  );
}

export default AnalysisSidebarItems;
