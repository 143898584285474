import React, { useCallback, useState } from 'react';
import SplitPane from 'react-split-pane';
import { SplitPaneWrapperProps } from '../../types/SplitPaneWrapperProps';

const minPanelSize = 0;
const maxPanelSize = -40;
const minGroupPanelSize = 470;
const maxGroupPanelSize = 768;

export default function SplitPaneWrapper(props: SplitPaneWrapperProps): JSX.Element {
  const { children, storageKey, type, setSplitPaneSize } = props;
  const [isCollapsed, setIsCollapsed] = useState(localStorage.getItem(storageKey) === '0');

  const onChange = useCallback((size: number) => {
    if (size === 0) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
    localStorage.setItem(storageKey, `${size}`);
  }, [storageKey]);

  const onDragFinished = useCallback((size: number) => {
    if (setSplitPaneSize) {
      setSplitPaneSize(size);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      { type === 'lights' ? (
        <SplitPane
          allowResize
          split="vertical"
          primary="second"
          minSize={minPanelSize}
          maxSize={maxPanelSize}
          defaultSize={parseInt(localStorage.getItem(storageKey) || '470', 10)}
          onDragFinished={onDragFinished}
          onChange={onChange}
          style={{ position: 'relative', height: 'calc(100% - 40px)' }}
          className={`${isCollapsed ? 'collapsed' : ''}`}
        >
          {children}
        </SplitPane>
      )
        : (
          <SplitPane
            allowResize
            split="vertical"
            primary="second"
            minSize={minGroupPanelSize}
            maxSize={maxGroupPanelSize}
            defaultSize={parseInt(localStorage.getItem(storageKey) || '470', 10)}
            onDragFinished={onDragFinished}
            onChange={onChange}
            style={{ position: 'relative', height: 'calc(100% - 40px)' }}
          >
            {children}
          </SplitPane>
        )}
    </>
  );
}
