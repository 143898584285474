import React, { useEffect, useState } from 'react';
import useSWR from 'swr';

import { DateTime } from 'luxon';
import { PageComponentProps } from '../../../../types/PageComponentProps';
import Table from '../../../../Common/Components/Table';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import TableToolbar from '../Components/TableToolbar';
import Button from '../../../../Common/Components/Button';
import getHeaderProps from '../../../../utils/getHeaderProps';
import { FotaCampaign, FotaCampaignTableItem } from '../../../../types/FotaCampaigns';
import { ReactComponent as EditIcon } from '../../../../img/icons/edit.svg';
import { ReactComponent as StopIcon } from '../../../../img/icons/job-stopped-solid.svg';
import CreateEditFotaCampaignModal from './Components/CreateEditFotaCampaignModal';
import Modal from '../../../../Common/Components/Modal';
import { postRequest } from '../../../../utils/fetch';
import { useAppContext } from '../../../../utils/AppContext';
import FotaCampaignDetailsModal from './Components/FotaCampaignDetailsModal';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import Utils from '../../../../utils/Utils';

const ACTIVE = 'active';
const CREATED = 'created';

const getStateDot = (state: string) => (
  <div>
    <span className={`status-dot ${state.toLowerCase()}`} />
    {state}
  </div>
);

function FotaCampaignsPage(props: PageComponentProps): JSX.Element {
  const { addNotification } = useAppContext();

  const modalCreateFotaCampaign = 1;
  const modalEditFotaCampaign = 2;
  const modalStopFotaCampaign = 3;
  const modalFotaCampaignDetails = 4;

  const headers = getHeaderProps('FotaCampaigns');
  const [openModal, setOpenModal] = useState(0);
  const [fotaCampaigns, setFotaCampaigns] = useState<FotaCampaignTableItem[]>([]);
  const [selectedFotaCampaign, setSelectedFotaCampaign] = useState<FotaCampaign>();

  const isNonReadOnly = Utils.isNonReadOnly();

  const { data: fotaCampaignsResp, mutate: updateFotaCampaignsResp } = useSWR<FotaCampaign[]>(
    ['/fota/campaign', 'GetFotaCampaigns'],
  );

  const colWidthCalcFn = (col: number, width: number): number => {
    const { actions, status } = { status: 130, actions: 150 };
    const remainColCount = col - 2;
    const colWidth = (width - status - actions) / (remainColCount);
    const notCustomizableHeadersWidth = [status, actions];
    const customizableHeadersWidth = Array.from(new Array(headers.length - notCustomizableHeadersWidth.length), (item) => item || colWidth);

    return [...notCustomizableHeadersWidth, ...customizableHeadersWidth][col];
  };

  useEffect(() => {
    if (fotaCampaignsResp && fotaCampaignsResp.length > 0) {
      setFotaCampaigns(fotaCampaignsResp.map((item) => {
        const state = item.state?.toLowerCase();
        const numberOfNodes = item.numberOfNodes || 0;
        const tableItem: FotaCampaignTableItem = {
          name: item.name,
          state: getStateDot(item.state || ''),
          stateTableFilter: item.state || '',
          stateTableSort: item.state || '',
          creationDate: (item.creationDate) ? DateTime.fromISO(item.creationDate).toISODate() : 'Data missing',
          targetVersion: item.targetVersion,
          numberOfNodes,
          numberOfNodesCompleted: item.numberOfNodesCompleted || 0,
          downloadRetryCount: item.downloadRetryCount,
          downloadHttpTimeout: `${item.downloadHttpTimeout} sec`,
          onDemand: item.onDemand === true ? 'True' : 'False',
          campaignTimeout: `${item.campaignTimeout} day${item.campaignTimeout > 1 ? 's' : ''}`,
          errorMessage: item.errorMessage || '',
          actions: (
            <div className="fota-campaign-actions">
              { isNonReadOnly && (
              <>
                <div className="fota-campaign-actions--item">
                  {(state === ACTIVE || state === CREATED) && (
                  <EditIcon
                    onClick={() => {
                      setSelectedFotaCampaign(item);
                      setOpenModal(modalEditFotaCampaign);
                    }}
                  />
                  )}
                </div>
                <div className="fota-campaign-actions--item">
                  {(state === ACTIVE || state === CREATED) && (
                  <StopIcon
                    onClick={() => {
                      setSelectedFotaCampaign(item);
                      setOpenModal(modalStopFotaCampaign);
                    }}
                  />
                  )}
                </div>

              </>
              )}
              <div className="fota-campaign-actions--item">
                {numberOfNodes > 0 && (
                  <Button
                    onClick={() => {
                      setSelectedFotaCampaign(item);
                      setOpenModal(modalFotaCampaignDetails);
                    }}
                    label="Details"
                    buttonType="secondary"
                    buttonSize="small"
                  />
                )}
              </div>
            </div>
          ),
        };

        return tableItem;
      }) // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => ((a.creationDate > b.creationDate) ? -1 : 1)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fotaCampaignsResp]);

  return (
    <div className="config__content fota-campaigns">
      <AdminSidebar title="Account admin">
        <AdminSidebarAdminItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin">
        <TableToolbar title="FOTA Campaigns">
          <div className="fota-campaigns-tabletoolbar">
            { isNonReadOnly && (
            <div className="tabletoolbar__children">
              <Button
                label="Create new"
                buttonType="secondary"
                onClick={() => setOpenModal(modalCreateFotaCampaign)}
              />
            </div>
            )}
          </div>
        </TableToolbar>
        <div className="table table--light top-border-light auto-height">
          <Table
            headers={headers}
            data={fotaCampaigns}
            autoheight
            colWidthCalcFn={colWidthCalcFn}
          />
        </div>
      </div>
      {openModal === modalCreateFotaCampaign && (
        <CreateEditFotaCampaignModal
          setOpenModal={setOpenModal}
          updateFotaCampaignsResp={updateFotaCampaignsResp}
        />
      )}
      {selectedFotaCampaign !== undefined && (
        (openModal === modalEditFotaCampaign && (
          <CreateEditFotaCampaignModal
            campaign={selectedFotaCampaign}
            setOpenModal={setOpenModal}
            updateFotaCampaignsResp={updateFotaCampaignsResp}
          />
        ))
        || (openModal === modalStopFotaCampaign && (
          <Modal
            title="Stop campaign"
            width="360"
            setModalOpen={() => setOpenModal(0)}
            primaryButtonLabel="Confirm"
            primaryButtonAction={async () => {
              try {
                const result = await postRequest(`/fota/campaign/${selectedFotaCampaign.campaignId}/close`);

                if (!result.error) {
                  addNotification({ type: 'info', message: 'Your "Stop campaign" operation is completed.' });
                  setOpenModal(0);
                  updateFotaCampaignsResp();
                } else {
                  addNotification({ type: 'error', message: `Your "Stop campaign" operation has failed: ${result.error}` });
                  setOpenModal(0);
                }
              } catch (e) {
                addNotification({ type: 'error', message: 'Your "Stop campaign" operation has failed.' });
                setOpenModal(0);
              }
            }}
            secondaryButtonLabel="Cancel"
          >
            <span>Are you sure you want to stop this campaign?</span>
          </Modal>
        ))
        || (openModal === modalFotaCampaignDetails && (
          <FotaCampaignDetailsModal
            campaign={selectedFotaCampaign}
            setOpenModal={setOpenModal}
            getStateDot={getStateDot}
          />
        ))
      )}
    </div>
  );
}

export default FotaCampaignsPage;
