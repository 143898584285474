import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as SiteConfigIcon } from '../../../img/icons/site-config.svg';
import { ReactComponent as LightsIcon } from '../../../img/icons/lighting-group.svg';
import { ReactComponent as GroupsIcon } from '../../../img/icons/groups-2.svg';
import { ReactComponent as SchedulesIcon } from '../../../img/icons/schedule-large.svg';
import { ReactComponent as JobsIcon } from '../../../img/icons/shovel.svg';
import { ReactComponent as EnergyIcon } from '../../../img/icons/smart-meter.svg';
import { ReactComponent as AlarmsIcon } from '../../../img/icons/alarms.svg';
import { RecentlyViewedSiteItemProps } from '../../../types/RecentlyViewedSites';
import Tooltip from '../../../Common/Components/Tooltip';

function RecentlyViewedSiteItem(props: RecentlyViewedSiteItemProps): JSX.Element {
  const { site, selectedSite } = props;

  return (
    <div className={`recently-viewed__card ${site.siteid === selectedSite.id ? 'recently-viewed__card--selected' : ''}`}>
      <div className="recently-viewed__name">{site.name}</div>
      <div className="recently-viewed__address">
        <div>{site.city}</div>
        ,
        <div className="margin-left-5">{site.state}</div>
      </div>
      <div className="recently-viewed__icons">
        <Tooltip text="Go to Lights">
          <NavLink to="/lights">
            <LightsIcon />
          </NavLink>
        </Tooltip>
        <Tooltip text="Go to Groups">
          <NavLink to="/groups">
            <GroupsIcon />
          </NavLink>
        </Tooltip>
        <Tooltip text="Go to Schedules">
          <NavLink to="/schedules">
            <SchedulesIcon />
          </NavLink>
        </Tooltip>
        <Tooltip text="Go to Jobs">
          <NavLink to="/jobs">
            <JobsIcon />
          </NavLink>
        </Tooltip>
        <Tooltip text="Go to Energy">
          <NavLink to="/energy">
            <EnergyIcon />
          </NavLink>
        </Tooltip>
        <Tooltip text="Go to Alarms">
          <NavLink to="/alarms">
            <AlarmsIcon />
          </NavLink>
        </Tooltip>
        <Tooltip text="Go to Site configuration">
          <NavLink to="/config/fixtures">
            <SiteConfigIcon />
          </NavLink>
        </Tooltip>
      </div>
    </div>
  );
}

export default RecentlyViewedSiteItem;
