import React from 'react';
import { DropDownButtonPropTypes } from '../../types/DropDownButtonPropTypes';

function DropDownButton(props: DropDownButtonPropTypes): JSX.Element {
  const { click, text, icon, addClass } = props;
  return (
    <button
      type="button"
      className={`dropdown-button ${addClass}`}
      onClick={() => click()}
    >
      {icon}
      {text}
    </button>
  );
}

export default DropDownButton;
