import React from 'react';
import Modal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import Utils from '../../../utils/Utils';
import { getRequest } from '../../../utils/fetch';
import { SessionExpirationModalProps } from '../../../types/SessionExpirationModalProps';
import {
  defaultCountdownSeconds,
  defaultExtendSessionSeconds,
  defaultIdleTimeoutSeconds,
  lastInteractionCookieName,
  sessionExpirationCookieName,
} from '../../../utils/constants';

const idleEventNames = [
  'keydown',
  'click',
  'dblclick',
  'mousemove',
  'touchmove',
  'scroll',
  'wheel',
];

type SessionExpirationModalState = {
  modalShown: boolean;
  expireMinutes: number;
  expireSeconds: number;
  isLoading: boolean;
};

class SessionExpirationModal extends React.Component<SessionExpirationModalProps, SessionExpirationModalState> {
  readonly sessionIdleTimeout: number;

  readonly countdownSeconds: number;

  tokenExpiryDate?: number;

  sessionTimer?: NodeJS.Timeout;

  countdownTimer?: NodeJS.Timeout;

  constructor(props: SessionExpirationModalProps) {
    super(props);

    this.state = {
      modalShown: props.modalShown || false,
      expireMinutes: 0,
      expireSeconds: 0,
      isLoading: false,
    };

    this.sessionIdleTimeout = this.getSessionIdleTimeout();
    this.countdownSeconds = this.getCountdownSeconds();
  }

  componentDidMount(): void {
    this.updateDummyTokenExpiryDate();
    this.updateIdleTime();
    this.bindIdleEvents();
    this.startSessionTimer();
  }

  componentWillUnmount(): void {
    this.unbindIdleEvents();
    this.stopTimers();
  }

  getSessionIdleTimeout = (): number => {
    const { idleTimeoutSeconds } = this.props;
    return idleTimeoutSeconds || defaultIdleTimeoutSeconds;
  };

  getCountdownSeconds = (): number => {
    const { countdownSeconds } = this.props;
    return countdownSeconds || defaultCountdownSeconds;
  };

  getTokenExpiryDate = (): number => {
    const { tokenExpirySeconds } = this.props;
    return (tokenExpirySeconds && this.tokenExpiryDate)
      ? this.tokenExpiryDate
      : (parseInt(Utils.getCookie(sessionExpirationCookieName), 10) || 0);
  };

  updateDummyTokenExpiryDate = (): void => {
    const { tokenExpirySeconds } = this.props;
    if (tokenExpirySeconds) {
      this.tokenExpiryDate = this.makeDummyTokenExpiryDate(tokenExpirySeconds);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  makeDummyTokenExpiryDate = (expirySeconds: number): number => {
    const date = new Date();
    date.setTime(date.getTime() + (expirySeconds * 1000));
    return Math.floor(date.getTime() / 1000);
  };

  isIdTokenExpired = (): boolean =>
    this.getTokenExpiryDate() > 0
    && Date.now() > ((this.getTokenExpiryDate() - this.countdownSeconds) * 1000);

  updateIdleTime = (): void => {
    const { modalShown } = this.state;
    if (!modalShown && this.sessionIdleTimeout) {
      Utils.setCookie(
        lastInteractionCookieName,
        Date.now().toString(),
        this.sessionIdleTimeout + 1,
      );
    }
  };

  // eslint-disable-next-line class-methods-use-this
  isIdleTimeExpired = (): boolean =>
    Date.now() > (parseInt(Utils.getCookie(lastInteractionCookieName), 10)
    + ((this.sessionIdleTimeout - this.countdownSeconds) * 1000));

  logOut = (): void => {
    this.stopTimers();
    this.setState({ modalShown: false, isLoading: true });
    Utils.logout();
  };

  extendSession = async (): Promise<void> => {
    this.stopTimers();
    this.setState({ modalShown: false, isLoading: true });
    const result = await getRequest('/organizations/users/user_configuration');

    if (result.error) {
      this.logOut();
    } else {
      Utils.setCookie(sessionExpirationCookieName, (Math.floor(Date.now() / 1000) + 3570).toString(), defaultIdleTimeoutSeconds);
      this.setState({ isLoading: false });
      this.updateIdleTime();
      this.updateDummyTokenExpiryDate();
      this.startSessionTimer();
    }
  };

  updateExpireCounters = (secondsLeft: number): void =>
    this.setState({
      expireMinutes: Math.floor(secondsLeft / 60) || 0,
      expireSeconds: secondsLeft % 60 || 0,
    });

  startCountdown = (): void => {
    this.setState({ modalShown: true });
    this.updateExpireCounters(this.calculateSecondsLeft());
    this.countdownTimer = setInterval(() => {
      const secondsLeft = this.calculateSecondsLeft();
      this.updateExpireCounters(secondsLeft);
      if (!this.isIdleTimeExpired() && !this.isIdTokenExpired() && this.countdownTimer) {
        clearInterval(this.countdownTimer);
        this.setState({ modalShown: false, isLoading: false });
        this.updateIdleTime();
        return false;
      }
      if (secondsLeft < 1) {
        this.logOut();
      }
      return true;
    }, 1000);
  };

  // eslint-disable-next-line class-methods-use-this
  calculateSecondsLeft = (): number => {
    const sessionExpirationTime = parseInt(Utils.getCookie(sessionExpirationCookieName), 10) - defaultExtendSessionSeconds;
    const currentDateTimeInSeconds = Math.trunc(Date.now() / 1000);

    const result = sessionExpirationTime - currentDateTimeInSeconds;

    return (Number.isNaN(result) || result <= 0) ? 0 : Math.ceil(result);
  };

  startSessionTimer = (): void => {
    if (this.sessionTimer) {
      clearInterval(this.sessionTimer);
    }
    this.sessionTimer = setInterval(this.onSessionTimerTick, 1000);
  };

  onSessionTimerTick = (): void => {
    const { modalShown } = this.state;
    if (!modalShown && (this.isIdleTimeExpired() || this.isIdTokenExpired())) {
      this.startCountdown();
    }
  };

  stopTimers = (): void => {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
    if (this.sessionTimer) {
      clearInterval(this.sessionTimer);
    }
  };

  bindIdleEvents = (): void =>
    idleEventNames.forEach((eventName: string): void =>
      document.addEventListener(eventName, this.updateIdleTime));

  unbindIdleEvents = (): void =>
    idleEventNames.forEach((eventName: string): void =>
      document.removeEventListener(eventName, this.updateIdleTime));

  // eslint-disable-next-line class-methods-use-this
  zeroPad = (value: number): string =>
    value.toString().padStart(2, '0');

  render(): JSX.Element {
    const { modalShown, expireMinutes, expireSeconds, isLoading } = this.state;
    return (
      <>
        {modalShown && (
          <Modal
            title="Inactivity"
            className="session-expiration-modal"
            width="430"
            secondaryButtonAction={this.logOut}
            secondaryButtonLabel="Log out"
            primaryButtonAction={this.extendSession}
            primaryButtonLabel="Stay logged in"
          >
            <>
              <div className="modal__content-left">
                <p>
                  Due to inactivity, your session will expire in:
                </p>
                <p>
                  <span className="session-expiration-modal__countdown">
                    {`${this.zeroPad(expireMinutes)}:${this.zeroPad(expireSeconds)}`}
                  </span>
                </p>
                <p>
                  Would you like to stay logged in?
                </p>
              </div>
            </>
          </Modal>
        )}
        {isLoading && <Loading />}
      </>
    );
  }
}

export default SessionExpirationModal;
