import React from 'react';
import Button from '../../../Common/Components/Button';
import Tooltip from '../../../Common/Components/Tooltip';
import { JobStatusPropsType } from '../../../types/JobStatus';
import { jobStatusMsg } from '../../../utils/constants';
import Utils from '../../../utils/Utils';

function JobStatus(props: JobStatusPropsType): JSX.Element {
  const {
    addClass,
    job,
    icon,
    action,
    showDetails,
    setShowDetailsModal,
    setShowStopJobModal,
    setSelectedJob,
  } = props;

  const isNonReadOnly = Utils.isNonReadOnly();
  const tooltipText = 'Stop job';
  const tooltipOffset = -16;

  return (
    <>
      <div className={`jobs-list-item__status ${addClass}`}>
        {icon}
        <span>
          {jobStatusMsg[job.status]}
          {job.creationFailMessage
            ? (
              <>
                <br />
                <span>{job.creationFailMessage}</span>
              </>
            ) : ''}
        </span>
      </div>
      <div className="job-status-btns">
        {isNonReadOnly && action && (
          <div
            className="jobs-list-item__action"
            role="button"
            onClickCapture={() => {
              setSelectedJob(job);
              setShowStopJobModal(true);
            }}
          >
            <Tooltip text={tooltipText} offset={tooltipOffset}>
              {action}
            </Tooltip>
          </div>
        )}
        {showDetails && setShowDetailsModal && (
        <div className="jobs-list-item__details">
          <Button
            buttonType="secondary"
            onClick={() => {
              setSelectedJob(job);
              setShowDetailsModal(true);
            }}
            buttonSize="small"
          >
            <span>Details</span>
          </Button>
        </div>
        )}
      </div>
    </>
  );
}

export default JobStatus;
