/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState } from 'react';
import { AnalysisPageProps } from '../../types/AnalysisPageProps';
import { TableHeadersProp } from '../../types/TableHeadersProp';
import { NodeObject } from '../../types/NodeObject';
import { GroupObject } from '../../types/GroupObject';
import Utils from '../Utils';
import getHeaderProps from '../getHeaderProps';

export default function useJobsPageState(): AnalysisPageProps {
  const [activeToolbar, setActiveToolbar] = useState(0);
  const [activeToolbarBtn, setActiveToolbarBtnToolbar] = useState<number>(0);
  const [selectedNodes, _setSelectedNodes] = useState<Map<string, NodeObject>>(new Map());
  const [selectedGroups, _setSelectedGroups] = useState<Map<string, GroupObject>>(new Map());

  const customizedHeader = JSON.parse(localStorage.getItem('AnalysisTableHeader') || '[]');
  const headersLocal = customizedHeader.length === 0 ? getHeaderProps(Utils.isVerizonUser() ? 'VZAlarms' : 'Alarms') : customizedHeader;

  const [headers, _setHeaders] = useState(headersLocal);

  const handleActiveToolbar = (toolbarNum: number) =>
    setActiveToolbar(() => (toolbarNum === activeToolbar ? 0 : toolbarNum));
  const setHeaders = (newHeaders: TableHeadersProp[]) => {
    localStorage.setItem(Utils.isVerizonUser() ? 'VZAlarmsTableHeader' : 'AlarmsTableHeader', JSON.stringify(newHeaders));
    _setHeaders(newHeaders);
  };

  const setSelectedNodes = (newState: Map<string, NodeObject>) => {
    _setSelectedNodes((oldState) => {
      const oldNodeIds = Array.from(oldState.keys());
      const newNodeIds = Array.from(newState.keys());
      const added = newNodeIds.filter((i) => !oldNodeIds.includes(i));
      const removed = oldNodeIds.filter((i) => !newNodeIds.includes(i));

      window.dispatchEvent(new CustomEvent('MapSelectedItemsChange', { detail: { added, removed } }));

      return newState;
    });
  };
  const setSelectedGroups = (newState: Map<string, GroupObject>) => {
    _setSelectedGroups((oldState) => {
      const oldGroupIds = Array.from(oldState.keys());
      const newGroupIds = Array.from(newState.keys());
      const added = newGroupIds.filter((i) => !oldGroupIds.includes(i));
      const removed = oldGroupIds.filter((i) => !newGroupIds.includes(i));

      window.dispatchEvent(new CustomEvent('MapSelectedItemsChange', { detail: { added, removed } }));

      return newState;
    });
  };

  return {
    selectedNodes,
    setSelectedNodes,
    selectedGroups,
    setSelectedGroups,
    headers,
    setHeaders,
    activeToolbar,
    activeToolbarBtn,
    setActiveToolbarBtnToolbar,
    handleActiveToolbar,
  };
}
