import React from 'react';
import Modal from '../../../../Common/Components/Modal';
import SelectBox from '../../../../Common/Components/SelectBox';
import { UpdateOrgGroupProps } from '../../../../types/GroupActionsPropTypes';
import { SelectBoxItemType } from '../../../../types/SelectBoxPropsType';
import { postRequest } from '../../../../utils/fetch';
import Utils from '../../../../utils/Utils';
import GroupActionsModalContent from '../GroupActionsModalContent';
import GroupActionsTargetGroup from '../GroupActionsTargetGroup';

import { ReactComponent as ScrollRightIcon } from '../../../../img/icons/scroll-right.svg';
import { ReactComponent as OrgGroupIcon } from '../../../../img/icons/org-group.svg';
import { ReactComponent as WarningIcon } from '../../../../img/icons/warning.svg';
import { LightsGroupsItem } from '../../../../types/LightsGroupProps';

const selectBoxWidthWider = 410;

function UpdateOrgGroup(props: UpdateOrgGroupProps): JSX.Element {
  const {
    setOpenModal,
    updateOrgGroup,
    setUpdateOrgGroup,
    selectedNodes,
    selectedCustomer,
    selectedSite,
    addNotification,
    orgGroups,
    getOrgGroupNodes,
    updateGroup,
  } = props;
  return (
    <Modal
      title="Redefine org group"
      width="614"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        if (!updateOrgGroup.list.title) {
          setUpdateOrgGroup((oldVal) => ({ ...oldVal, hasError: true }));
        } else if (!updateOrgGroup.hasError) {
          try {
            const groupObject = orgGroups.find((group) => group.groupId === updateOrgGroup.list.key) as LightsGroupsItem;
            const newNodeList = Array.from(selectedNodes.keys());
            groupObject.nodeList = newNodeList;
            const postBody = {
              operation: 'UPDATE_GROUP',
              groupId: groupObject.groupId,
              nodeIds: newNodeList,
              name: groupObject.name,
              description: groupObject.description,
            };

            const result = await postRequest(
              `/organizations/${selectedCustomer?.id}/sites/${selectedSite?.id}/async-jobs/group-move`,
              postBody,
            );
            if (!result.error) {
              addNotification({ type: 'success', message: 'Your "Redefine org group" operation is completed.' });
              updateGroup();
              setOpenModal(0);
            } else {
              addNotification({ type: 'error', message: `Your "Redefine org group" operation has failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Redefine org group" operation has failed.' });
          }
        }
      }}
    >
      <div className="group-actions__modal-container">
        <SelectBox
          label="Target org group"
          onClick={(item: SelectBoxItemType) => {
            setUpdateOrgGroup({
              hasError: item.title === '',
              list: item,
            });
          }}
          selectedItemKey={updateOrgGroup.list.key || ''}
          list={Utils.arrayToSelectbox(orgGroups || [], 'id', 'name')}
          title={updateOrgGroup.list.title || 'Select'}
          type="light"
          listWidth={selectBoxWidthWider}
          isHorizontal
          isRequired
          error={updateOrgGroup.hasError}
          errorMessage="Required"
        />
        <GroupActionsModalContent
          selectedNodesSize={selectedNodes.size}
          groupName={updateOrgGroup.list.title}
          title="Selected nodes"
          addClass="title-top"
          type="org-group"
          titleType="baseline"
        >
          <div className="double-targetgroups">
            <div className="double-targetgroups__content">
              <div className="double-targetgroups__content-title">
                <span>Add</span>
                <span
                  className="double-targetgroups__content-title-number"
                >
                  {getOrgGroupNodes().add.length}
                </span>
              </div>
              <GroupActionsTargetGroup
                hasGroupName={updateOrgGroup.list.title !== ''}
                groupName={updateOrgGroup.list.title}
                icon={<OrgGroupIcon />}
                infoboxTitlePlaceholder="Org group not selected"
                infoboxTitle="Org group"
              />
            </div>
            <div className="double-targetgroups__content default">
              <div className="double-targetgroups__content-title">
                <span>Remove</span>
                <span
                  className="double-targetgroups__content-title-number"
                >
                  {getOrgGroupNodes().remove?.length || 0}
                </span>
              </div>
              <ScrollRightIcon className="icon" />
              <div className="target-group-info-org-update">
                <div className="target-group-info-org-update-content">
                  <WarningIcon className="target-group-info-org-update-content-icon" />
                  <span>These nodes will be removed from the target org group.</span>
                </div>
              </div>
            </div>
          </div>
        </GroupActionsModalContent>
      </div>
    </Modal>
  );
}

export default UpdateOrgGroup;
