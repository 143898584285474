/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState } from 'react';
import { UsersPageState, SelectedPartner, SelectedAccount, UsersLocationState } from '../../types/UsersPageProps';
import { UserObject } from '../../types/UserObject';
import getHeaderProps from '../getHeaderProps';
import Utils from '../Utils';

export default function useUsersPageState(locationState?: UsersLocationState): UsersPageState {
  const usersMainHeadersFn = () => {
    const userRole = Utils.getUserRole();
    if (userRole === 'SENSITY_ADMIN') {
      return 'UsersVerizonAdminMain';
    }
    if (['SENSITY_USER', 'SENSITY_READ_ONLY'].includes(userRole)) {
      return 'UsersVerizonNonAdminMain';
    }
    if (userRole === 'PARTNER_ADMIN') {
      return 'UsersPartnerAdminMain';
    }
    if (userRole.startsWith('PARTNER')) {
      return 'UsersPartnerNonAdminMain';
    }
    if (userRole === 'END_USER_ADMIN') {
      return 'UsersEndUserAdminMain';
    }
    return 'UsersEndUserNonAdminMain';
  };

  const headersType = usersMainHeadersFn();
  const [selectedUsers, setSelectedUsers] = useState<Map<string, UserObject>>(new Map());
  const [headers, setHeaders] = useState(getHeaderProps(headersType));
  const [toggleCreateUser, setToggleCreateUser] = useState(false);
  const [toggleEditUser, setToggleEditUser] = useState(false);

  const [selectedPartner, _setSelectedPartner] = useState<SelectedPartner>({ id: locationState?.partnerId || '', name: locationState?.partnerName || '' });
  const [selectedAccount, setSelectedAccount] = useState<SelectedAccount>({ id: locationState?.accountId || '', name: locationState?.accountName || '' });
  const setSelectedPartner = (newValue: SelectedPartner) => {
    _setSelectedPartner(newValue);
    setSelectedAccount({ id: '', name: '' });
  };

  return {
    headers,
    headersType,
    setHeaders,
    selectedUsers,
    setSelectedUsers,
    toggleCreateUser,
    setToggleCreateUser,
    toggleEditUser,
    setToggleEditUser,
    selectedPartner,
    setSelectedPartner,
    selectedAccount,
    setSelectedAccount,
  };
}
