import React from 'react';
import { ScheduleEventActionPoint } from '../types/ScheduleObject';

class CanvasUtils {
  static canvasHalfPxAdjustment = 0.5;

  static lineDash = [4, 2];

  static getCanvasXY(event: React.MouseEvent, canvasMargin: number): { canvasX: number, canvasY: number } | undefined {
    const rect = (event.target as HTMLElement).closest('.timeline__points')?.getBoundingClientRect();

    if (rect) {
      return {
        canvasX: event.clientX - rect.left - canvasMargin,
        canvasY: event.clientY - rect.top - canvasMargin,
      };
    }

    return undefined;
  }

  static drawScheduleBgAndGrid(
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number,
    gridSize = 40,
    canvasMargin = 10.5,
  ): void {
    // grid
    ctx.beginPath();
    ctx.setLineDash([]);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#D8DADA80';

    // grid X
    const gridXSize = 11;
    for (let i = 0; i < gridXSize; i += 1) {
      ctx.moveTo(0, i * gridSize + canvasMargin);
      ctx.lineTo(width, i * gridSize + canvasMargin);
    }

    // grid Y
    const gridYSize = 25;
    for (let i = 0; i < gridYSize; i += 1) {
      ctx.moveTo(i * gridSize + canvasMargin, 0);
      ctx.lineTo(i * gridSize + canvasMargin, height);
    }

    ctx.stroke();
    ctx.closePath();

    // dashed lines
    ctx.beginPath();
    ctx.setLineDash(CanvasUtils.lineDash);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#747676';
    // dawn left
    const dashed1GridSize = 6;
    const dashed1 = dashed1GridSize * gridSize + canvasMargin;
    ctx.moveTo(dashed1, 0);
    ctx.lineTo(dashed1, height);
    // dawn right
    const dashed2GridSize = 7;
    const dashed2 = dashed2GridSize * gridSize + canvasMargin;
    ctx.moveTo(dashed2, 0);
    ctx.lineTo(dashed2, height);
    // dusk left
    const dashed3GridSize = 17;
    const dashed3 = dashed3GridSize * gridSize + canvasMargin;
    ctx.moveTo(dashed3, 0);
    ctx.lineTo(dashed3, height);
    // dusk right
    const dashed4GridSize = 18;
    const dashed4 = dashed4GridSize * gridSize + canvasMargin;
    ctx.moveTo(dashed4, 0);
    ctx.lineTo(dashed4, height);

    ctx.stroke();
    ctx.closePath();
    ctx.setLineDash([]);

    const nightGridSize = 6;
    const dayGridSize = 10;

    // night-dawn
    const grid6M = gridSize * nightGridSize + canvasMargin;
    const x1 = grid6M;
    const w1 = grid6M;
    const grad1 = ctx.createLinearGradient(0, 0, x1, 0);
    grad1.addColorStop(0, '#0E164159');
    grad1.addColorStop(1, '#10194959');
    ctx.fillStyle = grad1;
    ctx.fillRect(0, 0, w1, height);

    // dawn
    const x2 = x1 + gridSize;
    const w2 = gridSize;
    const grad2 = ctx.createLinearGradient(x1, 0, x2, 0);
    grad2.addColorStop(0, '#10194959');
    grad2.addColorStop(1, '#0088CE14');
    ctx.fillStyle = grad2;
    ctx.fillRect(x1, 0, w2, height);

    // day
    const x3 = x2 + gridSize * dayGridSize;
    const w3 = gridSize * dayGridSize;
    ctx.fillStyle = '#0088CE14';
    ctx.fillRect(x2, 0, w3, height);

    // dusk
    const x4 = x3 + gridSize;
    const w4 = gridSize;
    const grad4 = ctx.createLinearGradient(x3, 0, x4, 0);
    grad4.addColorStop(0, '#0088CE14');
    grad4.addColorStop(1, '#10194959');
    ctx.fillStyle = grad4;
    ctx.fillRect(x3, 0, w4, height);

    // dusk-night
    const x5 = x4 + gridSize * nightGridSize;
    const w5 = grid6M;
    const grad5 = ctx.createLinearGradient(x4, 0, x5, 0);
    grad5.addColorStop(0, '#10194959');
    grad5.addColorStop(1, '#0E164159');
    ctx.fillStyle = grad5;
    ctx.fillRect(x4, 0, w5, height);
  }

  static drawScheduleDimmingPoints(
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number,
    canvasMargin: number,
    dimmingPoints: ScheduleEventActionPoint[],
  ): void {
    const lastPoint = dimmingPoints[dimmingPoints.length - 1];

    for (let i = 0; i < dimmingPoints.length; i += 1) {
      const point = dimmingPoints[i];
      const nextPoint = dimmingPoints[i + 1];
      const realX = Math.floor(point.x + canvasMargin) + CanvasUtils.canvasHalfPxAdjustment;
      const realY = Math.floor(point.y + canvasMargin) + CanvasUtils.canvasHalfPxAdjustment;

      if (i === 0) {
        // draw last point's line and bg from 0 to first point
        CanvasUtils.drawScheduleDimmingLineAndBg(
          ctx,
          lastPoint.action.photocell_enabled,
          CanvasUtils.canvasHalfPxAdjustment,
          Math.floor(lastPoint.y + canvasMargin) + CanvasUtils.canvasHalfPxAdjustment,
          realX,
          height,
          realX,
          realY,
        );
      }

      // draw point's line and bg from point to next point
      CanvasUtils.drawScheduleDimmingLineAndBg(
        ctx,
        point.action.photocell_enabled,
        realX,
        realY,
        Math.floor(nextPoint ? nextPoint.x - point.x : width - point.x),
        height,
        nextPoint ? Math.floor(nextPoint.x + canvasMargin) + CanvasUtils.canvasHalfPxAdjustment : undefined,
        nextPoint ? Math.floor(nextPoint.y + canvasMargin) + CanvasUtils.canvasHalfPxAdjustment : undefined,
      );

      if (point.dragged) {
        // yellow dash guide for moving point
        ctx.beginPath();
        ctx.setLineDash(CanvasUtils.lineDash);
        ctx.strokeStyle = '#FFBC3D99';
        ctx.lineWidth = 1;
        ctx.moveTo(realX, 0);
        ctx.lineTo(realX, height);
        ctx.moveTo(0, realY);
        ctx.lineTo(width, realY);
        ctx.stroke();
        ctx.closePath();
      }
    }
  }

  static drawScheduleDimmingLineAndBg(
    ctx: CanvasRenderingContext2D,
    photocell: boolean,
    x: number,
    y: number,
    w: number,
    h: number,
    nextX?: number,
    nextY?: number,
  ): void {
    // horizontal line
    const boldLineWidth = 2;
    const photocellLineDash = [10, 5];

    ctx.beginPath();
    ctx.strokeStyle = '#FFBC3D';
    ctx.lineWidth = boldLineWidth;

    if (photocell) {
      ctx.setLineDash(photocellLineDash);
      const grad = ctx.createLinearGradient(x, y, x, h / (window.devicePixelRatio || 1));
      grad.addColorStop(0, '#FFFFFF00');
      grad.addColorStop(1, '#FFBC3D4D');
      ctx.fillStyle = grad;
    } else {
      ctx.setLineDash([]);
      ctx.fillStyle = '#FFBC3D33';
    }

    ctx.moveTo(x, y);
    ctx.lineTo(nextX || x + w, y);
    ctx.stroke();
    ctx.closePath();

    if (nextX && nextY) {
      // vertical lines from point to next point
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.strokeStyle = '#FFBC3D';
      ctx.lineWidth = 1;
      ctx.moveTo(nextX, y);
      ctx.lineTo(nextX, nextY);
      ctx.stroke();
      ctx.closePath();
    }

    // background
    ctx.fillRect(x, y, w, h - y);
  }
}

export default CanvasUtils;
