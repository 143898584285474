/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import { AccountPageListProps } from '../../../../../types/AccountPageList';

import { ReactComponent as SitesIcon } from '../../../../../img/icons/site-locator.svg';
import { ReactComponent as UsersIcon } from '../../../../../img/icons/group.svg';
import { ReactComponent as EditIcon } from '../../../../../img/icons/edit.svg';
import Tooltip from '../../../../../Common/Components/Tooltip';
import Utils from '../../../../../utils/Utils';

function AccountsPageList(props: AccountPageListProps): JSX.Element {
  const { listItem, setModalOpen, setEditedCustomer } = props;
  const editModal = 3;

  return (
    <div className="customer-list__items">
      <div className="customer-list__items-main">
        <div className="customer-list__items-name">
          {listItem.name}
        </div>
        <div className="customer-list__items-id">
          ID:
          {' '}
          {listItem.orgid}
        </div>
      </div>
      <div className="customer-list__items-city">
        <div className="customer-list__items-label">City</div>
        {listItem.city || '--'}
      </div>
      <div className="customer-list__items-state">
        <div className="customer-list__items-label">State</div>
        {listItem.state || '--' }
      </div>
      <div className="customer-list__items-sites">
        <Tooltip text="Go to Site manager">
          <Link to={{ pathname: '/admin/sites', state: { accountId: listItem.orgid, accountName: listItem.name } }}>
            <SitesIcon />
          </Link>
        </Tooltip>
      </div>
      <div className="customer-list__items-users">
        <Tooltip text="Go to Account Users">
          <Link to={{ pathname: '/admin/users', state: { accountId: listItem.orgid, accountName: listItem.name } }}>
            <UsersIcon />
          </Link>
        </Tooltip>
      </div>
      {Utils.isSensityUserOrAdminUser() && (
        <div className="customer-list__items-edit">
          <Tooltip text="Edit account">
            <button
              className="customer-list__items-edit"
              type="button"
              onClick={() => {
                setModalOpen(editModal);
                setEditedCustomer(listItem);
              }}
            >
              <EditIcon />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default AccountsPageList;
