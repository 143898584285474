import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import Loading from '../../../Common/Components/Loading';
import Modal from '../../../Common/Components/Modal';
import RadioButtons from '../../../Common/Components/RadioButtons';
import SelectBox from '../../../Common/Components/SelectBox';
import Textbox from '../../../Common/Components/Textbox';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { FirmwareUpdateModalProps } from '../../../types/FirmwareUpdateModalProps';
import { GroupObject } from '../../../types/GroupObject';
import { RadioButtonElementProps } from '../../../types/RadioButtonsProps';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { useAppContext } from '../../../utils/AppContext';
import { httpOk } from '../../../utils/constants';
import { postRequest } from '../../../utils/fetch';
import Utils from '../../../utils/Utils';

function FirmwareUpdateModal(props: FirmwareUpdateModalProps): JSX.Element {
  const { selectedCustomer, selectedSite, selectedFirmware, closeModal } = props;
  const { addNotification } = useAppContext();

  const [valueType, setValueType] = useState<RadioButtonElementProps>({ label: 'Site', key: 'site' });
  const [description, setDescription] = useState<string>();
  const [selectedGroup, setSelectedGroup] = useState<SelectBoxItemType>();
  const [openGroupSelect, setOpenGroupSelect] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalHeight, setModalHeight] = useState('auto');

  const siteUpdateFirmwarePath = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/firmwares/${selectedFirmware?.firmwareId}/assign/site`;
  const groupUpdateFirmwarePath = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/firmwares/${selectedFirmware?.firmwareId}/assign/groups/${selectedGroup?.key}`;
  const getAllGroupsPath = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups`;

  const { data: groupsResp } = useSWR<GroupObject[]>(selectedSite.id
    ? [getAllGroupsPath, 'GetGroups']
    : null);
  const lightingGroups: GroupObject[] | undefined = useMemo(
    () => groupsResp?.filter((group) => group.type !== 'ORGANIZATIONAL'),
    [groupsResp],
  );

  const minGroupLengthForHigherModal = 3;
  const listWidth = 332;

  useEffect(() => {
    if (valueType.key === 'site') {
      setModalHeight('390');
    } else if ((lightingGroups && lightingGroups.length > minGroupLengthForHigherModal && openGroupSelect)
     || (valueType.key === 'group' && !selectedGroup && submitted)) {
      setModalHeight('484');
    } else {
      setModalHeight('464');
    }
  }, [openGroupSelect, valueType, selectedGroup, submitted, lightingGroups]);

  const allSelectionTypes: Array<RadioButtonElementProps> = [
    { key: 'site', label: 'Site' },
    { key: 'group', label: 'Lighting Group' },
  ];

  const sendUpdate = async () => {
    setSubmitted(true);
    if (!(valueType.key === 'group' && !selectedGroup)) {
      setIsLoading(true);
      const updatePath = valueType.key === 'site' ? siteUpdateFirmwarePath : groupUpdateFirmwarePath;
      postRequest(updatePath, { description, version: selectedFirmware?.version })
        .then((results) => {
          if (results.status === httpOk) {
            addNotification({
              type: 'success',
              message: '"Update firmware" operation is successfully initiated.',
            });
            closeModal();
          } else {
            throw Error(results.error);
          }
        })
        .catch((e: Error) => {
          addNotification({ type: 'error', message: `Your "Update firmware" operation has failed. "${e.message}"` });
          closeModal();
        });
    }
  };

  return (
    <Modal
      title="Start firmware update"
      width="392"
      height={modalHeight}
      setModalOpen={closeModal}
      primaryButtonAction={() => sendUpdate()}
      primaryButtonLabel="Submit"
      secondaryButtonLabel="Cancel"
    >
      <Loading isLoading={isLoading} />
      <div className="selection-label">
        Update all nodes in
      </div>
      <RadioButtons
        onClick={(clickedRadioBtn: RadioButtonElementProps) =>
          setValueType(clickedRadioBtn)}
        selected={valueType}
        list={allSelectionTypes}
        type="light"
      />
      <>
        {valueType.key === 'group'
          && (
            <SelectBox
              label="Lighting group"
              title={selectedGroup?.title || 'Select'}
              selectedItemKey={selectedGroup?.key || ''}
              isRequired
              onClick={(item: SelectBoxItemType) => {
                setSelectedGroup(item);
              }}
              errorMessage="Required"
              error={valueType.key === 'group' && !selectedGroup && submitted}
              list={(lightingGroups && Utils.arrayToSelectbox(lightingGroups, 'groupid', 'name')) || []}
              type="light"
              listWidth={listWidth}
              setIsClosedScheduleList={() => setOpenGroupSelect(!openGroupSelect)}
            />
          )}
      </>
      <Textbox
        type="text"
        name="description"
        value={description}
        placeholder="Enter text"
        onChange={(event) => setDescription(event && event.target && event.target.value)}
        label="Job description"
      />
      <div className="submit-info">
        <InfoIcon className="icon" />
        <div>
          This action will start an Update app firmware job for the site or group. You can monitor the job on the jobs page.
        </div>
      </div>
    </Modal>
  );
}

export default FirmwareUpdateModal;
