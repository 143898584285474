import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../Common/Components/Button';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import { GetAllRequests } from '../../../types/Fetch';
import { GenericSensorTableData, StaticSensorValue } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { TSData } from '../../../types/TSData';
import { slideInWidth } from '../../../utils/constants';
import { getRequestAll, postRequest } from '../../../utils/fetch';
import getHeaderProps from '../../../utils/getHeaderProps';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import RealTimeValues from '../Components/RealTimeValues';
import LightsNetworkSessionHistory from './Components/LightsNetworkSessionHistory';
import Loading from '../../../Common/Components/Loading';
import { useAppContext } from '../../../utils/AppContext';

function LightsNetworkMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite, fetchedBulkSensorData } = props;
  const { addNotification } = useAppContext();
  const isVerizonUser = Utils.isVerizonUser();
  const headers = isVerizonUser ? getHeaderProps('LightsNetwork') : getHeaderProps('LightsNetworkNonVerizonUsers');
  const history = useHistory();

  const [networkMultiTableData, setNetworkMultiTableData] = useState<GenericSensorTableData[]>();

  const timestamp = useRef<number>(0);

  const [openSessionHistory, setOpenSessionHistory] = useState(0);
  const [openRealTimeValues, setOpenRealTimeValues] = useState(false);
  const [sensorRealTimeValue, setSelectedSensorRealTimeValue] = useState({ title: 'Select', key: '0' });
  const internalUserSensorList = [
    { title: 'Signal strength', key: 'radioSignalStrength' },
    { title: 'SiNR', key: 'sinr' },
    { title: 'Received link quality', key: 'linkQuality' },
  ];
  const customerSensorList = [{ title: 'Signal strength', key: 'radioSignalStrength' }];

  const sensorListRealTimeValue = Utils.isVerizonUser() ? internalUserSensorList : customerSensorList;

  const displayedSensors = ['linkQuality', 'sinr', 'radioSignalStrength', 'modemBootCount'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);
  const [loading, setLoading] = useState(false);
  const dateConvertFn = (val) => Utils.getConvertedDate(val, undefined, selectedSite.timezone);

  const commissioningFields = {
    tsNetStat: { value: '--' },
    activationDate: { value: '--', conversion: dateConvertFn },
    commissionedDate: { value: '--', conversion: (val) => val || '--' },
    // ligLastReported: { value: '--', conversion: dateConvertFn },
    // lastRegistration: { value: '--', conversion: dateConvertFn },
    // firstInNetsense: { value: '--', conversion: dateConvertFn },
  } as {[index: string]: StaticSensorValue};

  useEffect(() => {
    setNetworkMultiTableData(undefined);
    setReqSensors(displayedSensors);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    getAllData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, fetchedBulkSensorData]);

  function getAllData() {
    setLoading(true);
    const currentDate = Date.now();
    timestamp.current = currentDate;

    Promise.allSettled(
      [getConnectionData(selectedItems), getNetworkSensorData(), getTsData(selectedItems)],
    ).then((results) => {
      if (results[0].status === 'fulfilled' && results[1].status === 'fulfilled' && results[2].status === 'fulfilled') {
        const newData = (results[1] as PromiseFulfilledResult<GenericSensorTableData[]>).value.map((value) => {
          const tsDataValue = (results[2] as PromiseFulfilledResult<TSData[]>).value;
          const activationDate = tsDataValue.find((d) => d.nodeId === value.nodeid)?.lastActivationDate;
          return {
            ...value,
            ...{
              platform: (results[0] as PromiseFulfilledResult<{ network: string; nodeid: string; }[]>).value.find((d) => d.nodeid === value.nodeid)?.network || '--',
            },
            ...{
              tsNetStat: tsDataValue.find((d) => d.nodeId === value.nodeid)?.connected === 'true' ? 'Connected' : 'Disconnected' || '--',
              activationDate: activationDate ? Utils.getConvertedDate(activationDate, undefined, selectedSite.timezone) : '--',
            },
          };
        });
        setNetworkMultiTableData(newData);
      } else {
        setNetworkMultiTableData([]);
      }
    }).finally(() => setLoading(false));
  }

  async function getNetworkSensorData(): Promise<{ [index: string]: string; nodeid: string; }[]> {
    return Sensors.getSensorTableWithDisplayedString(
      selectedItems,
      reqSensors,
      fetchedBulkSensorData,
      selectedSite.timezone,
      commissioningFields,
    );
  }

  async function updateNetworkSensorData(): Promise<{ [index: string]: string; nodeid: string; }[]> {
    const resp = await Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      Array.from(selectedItems.values()).map((n) => n),
      reqSensors.map((name) => Sensors.getSensorId(name)),
    );

    return Sensors.getSensorTableWithDisplayedString(
      selectedItems,
      reqSensors,
      resp,
      selectedSite.timezone,
      commissioningFields,
    );
  }

  async function getTsData(selectedItemsP: Map<string, NodeObject>): Promise<TSData[]> {
    let tsdata: TSData[] = [];
    try {
      const responses = await postRequest<{ nodeIds: string[] }, TSData[]>(
        `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/device_information`,
        { nodeIds: Array.from(selectedItemsP.keys()) },
      );
      if (responses.error) {
        throw Error(responses.error);
      }
      tsdata = responses.data;
    } catch (error) {
      addNotification({ type: 'warning', message: `Could not fetch external device information: '${error.message || error}'` });
    }
    return tsdata;
  }

  async function getConnectionData(selectedItemsP: Map<string, NodeObject>): Promise<{ nodeid: string; network: string; }[]> {
    const connectionRequests: GetAllRequests = Array.from(selectedItemsP.values())
      .map((node) => ({ path: `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/${node.nodeid}/connection_status` }));
    const responses = await getRequestAll(connectionRequests);
    return responses.results.filter((res) => res.status === 'fulfilled').map((res) => {
      const result = res as PromiseFulfilledResult<{ 'nodeId': string; isConnected: boolean; }>;
      return { nodeid: result.value.nodeId,
        // eslint-disable-next-line no-nested-ternary
        network: (result.value && result.value.isConnected !== null) ? ((result.value.isConnected) ? 'Yes' : 'No') : '--' };
    });
  }

  function updateConnectionData() {
    setLoading(true);
    getConnectionData(selectedItems).then((result) => {
      const newData = networkMultiTableData?.map((value) => (
        { ...value, ...{ platform: result.find((d) => d.nodeid === value.nodeid)?.network } }));
      setNetworkMultiTableData(newData as GenericSensorTableData[]);
    }).finally(() => setLoading(false));
    setLoading(true);
    getTsData(selectedItems).then((result) => {
      const newTsdata = networkMultiTableData?.map((value) => (
        { ...value, ...{ tsNetStat: result.find((d) => d.nodeId === value.nodeid)?.connected === 'true' ? 'Connected' : 'Disconnected' || '--', activationDate: result.find((d) => d.nodeId === value.nodeid)?.lastActivationDate || '--' } }
      ));
      setNetworkMultiTableData(newTsdata as GenericSensorTableData[]);
    }).finally(() => setLoading(false));
  }

  function updateSensorData() {
    setLoading(true);
    updateNetworkSensorData().then((result) => {
      const newData = networkMultiTableData?.map((value) => (
        { ...value, ...result.find((d) => d.nodeid === value.nodeid) }));
      setNetworkMultiTableData(newData);
    }).finally(() => setLoading(false));
  }

  return (
    <div className="network-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Network</span>
            <div className="slide-in__links">
              <div className="slide-in__link">
                <Button
                  onClick={() => { updateConnectionData(); setOpenRealTimeValues(false); }}
                  label="Get new network status"
                />
              </div>
              <div className="slide-in__link">
                <Button
                  onClick={() => setOpenRealTimeValues(!openRealTimeValues)}
                  label="Get real time values"
                />
              </div>
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    localStorage.setItem('analysisSelectedNodes', JSON.stringify(Array.from(selectedItems.keys())));
                    localStorage.setItem('analysisNetworkStatsDashboardFlag', 'true');
                    localStorage.setItem('analysisPowerQualityDashboardFlag', 'false');
                    history.push('/analysis');
                  }}
                  label="Navigate to Dashboard"
                />
              </div>
              { /* Utils.isVerizonUser() && (
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    setOpenSessionHistory(1);
                    setOpenRealTimeValues(false);
                  }}
                  label="Connection history"
                />
              </div>
                ) */}
            </div>
          </div>
          {loading ? <Loading isLoading={loading} /> : (
            <div className="slide-in__content">
              <div className="table">
                <Table
                  headers={headers}
                  data={networkMultiTableData}
                  fixColCount={1}
                  dark
                />
              </div>
            </div>
          )}
        </>
      </SlideIn>
      {(openSessionHistory > 0) && (
      <LightsNetworkSessionHistory
        openModal={openSessionHistory}
        setOpenModal={setOpenSessionHistory}
      />
      )}
      {openRealTimeValues && (
      <RealTimeValues
        sensor={sensorRealTimeValue}
        sensorList={sensorListRealTimeValue}
        sensorSelectboxAction={(item: SelectBoxItemType) => setSelectedSensorRealTimeValue(item)}
        closeButton
        closeButtonAction={() => setOpenRealTimeValues(false)}
        refreshValue={() => {
          setReqSensors([sensorRealTimeValue.key]);
          updateSensorData();
          setOpenRealTimeValues(false);
        }}
      />
      )}
    </div>
  );
}

export default LightsNetworkMulti;
