import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';
import { AnalysisPageProps } from '../../types/AnalysisPageProps';
import useAnalysisPageState from '../../utils/state/useAnalysisPageState';
import Loading from '../../Common/Components/Loading';
import AdminSidebar from '../../Common/Components/AdminSidebar';
import { ReportObject } from '../../types/ReportObject';
import { ReportDefinitionObject } from '../../types/ReportDefinitionObject';
import { reportsFetcherFn } from '../../utils/ApiDataHelpers';
import ReportsList from './Components/ReportsList';
import ReportDefinitionsList from './Components/ReportDefinitionsList';
import AnalysisSidebarItems from './Components/AnalysisSidebarItems';
import Utils from '../../utils/Utils';
import DashboardSidebarItems from './Components/DashboardSidebarItems';
import Dashboard from './Components/Dashboard';
import EnergyPage from '../Energy/EnergyPage';

function AnalysisPage(props: AnalysisPageProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
  } = props;
  const {
    selectedNodes,
    setSelectedNodes,
    selectedGroups,
    setSelectedGroups,
  } = useAnalysisPageState();

  const [reportType, setReportType] = useState('NETWORK');
  const [listReportDefinitions, setListReportDefinitions] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reportDefinitions, setReportDefinitions] = useState<ReportDefinitionObject[]>([]);
  const [isDashboard, setIsDashboard] = useState(false);
  const { data: reportsResp } = useSWR<Array<ReportObject>>(() => `/organizations/${selectedCustomer.id || '65a7341d-91c6-4286-90f7-2583b6b82f07'}/reports`, reportsFetcherFn);
  const [isNetworkDashboard, setIsNetworkDashboard] = useState(false);
  const [isPowerQualityDashboard, setIsPowerQualityDashboard] = useState(false);
  const [isAdvancedEnergyDashboard, setIsAdvancedEnergyDashboard] = useState(false);

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'Analysis');
  useEffect(() => {
    if (localStorage.getItem('analysisNetworkStatsDashboardFlag') === 'true') {
      setReportType('Network Statistics');
      localStorage.setItem('analysisNetworkStatsDashboardFlag', 'false');
    }
    if (localStorage.getItem('analysisPowerQualityDashboardFlag') === 'true') {
      setReportType('Power Quality');
      localStorage.setItem('analysisPowerQualityDashboardFlag', 'false');
    }
  }, []);

  useEffect(() => {
    if (reportType === 'Network Statistics') {
      setIsDashboard(true);
      setIsPowerQualityDashboard(false);
      setIsNetworkDashboard(true);
      setIsAdvancedEnergyDashboard(false);
    }
    if (reportType === 'Power Quality') {
      setIsDashboard(true);
      setIsNetworkDashboard(false);
      setIsPowerQualityDashboard(true);
      setIsAdvancedEnergyDashboard(false);
    }
    if (reportType === 'Advanced Energy') {
      setIsDashboard(true);
      setIsNetworkDashboard(false);
      setIsPowerQualityDashboard(false);
      setIsAdvancedEnergyDashboard(true);
    }
  }, [reportType]);

  return (
    <div className="content content--admin analysis-page">
      <AdminSidebar title="Analysis">
        <>
          <div className="analysis-sidebar-site-name">{selectedSite.name}</div>
          <div className="analysis-sidebar-subheading">Reports</div>
          <AnalysisSidebarItems
            reportType={reportType}
            setReportType={setReportType}
            setListReportDefinitions={setListReportDefinitions}
            setIsDashboard={setIsDashboard}
            isDashboard={false}
          />
          {Utils.isVerizonUser() && (
          <>
            <div className="analysis-sidebar-subheading">Dashboards</div>
            <DashboardSidebarItems
              reportType={reportType}
              setReportType={setReportType}
              setListReportDefinitions={setListReportDefinitions}
              setIsDashboard={setIsDashboard}
              isDashboard
            />
          </>
          )}

        </>
      </AdminSidebar>
      <div className="page-content page-content--medium-margin page-content--large-bottom-margin sitemanager">
        {listReportDefinitions && !isDashboard
          && (
            <ReportDefinitionsList
              selectedNodes={selectedNodes}
              setSelectedNodes={setSelectedNodes}
              selectedGroups={selectedGroups}
              setSelectedGroups={setSelectedGroups}
              reportType={reportType}
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
            />
          )}
        {!listReportDefinitions && !isDashboard && reportsResp
          && (
          <ReportsList
            reports={reportsResp}
            reportType={reportType}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            setListReportDefinitions={setListReportDefinitions}
            setReportDefinitions={setReportDefinitions}
          />
          )}
        {isDashboard && isNetworkDashboard
          && (
            <Dashboard
              reports={[]}
              reportType={reportType}
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              isNetworkStats="network-statistics"
            />
          )}
        {isDashboard && isPowerQualityDashboard
          && (
            <Dashboard
              reports={[]}
              reportType={reportType}
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              isNetworkStats="power-quality"
            />
          )}

        {isDashboard && isAdvancedEnergyDashboard
          && (
            <EnergyPage
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
            />
          )}
        {!reportsResp && !isDashboard
          && (
            <Loading />
          )}
      </div>
    </div>
  );
}

export default AnalysisPage;
