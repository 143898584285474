import React from 'react';

function NoData(): JSX.Element {
  return (
    <div className="no-data">
      <div className="no-data__border">
        <div className="no-data__border-text">

          <span className="no-data__border-text--title">No data available</span>
          <span className="no-data__border-text--subtitle" />
        </div>
        <div className="no-data__border-image">
          <div className="nodata-image" />
        </div>
      </div>
    </div>
  );
}

export default NoData;
