import React from 'react';
import Modal from '../../../Common/Components/Modal';
import { AlarmPlaybookProps } from '../../../types/AlarmPlaybookProps';

function AlarmPlaybook(props: AlarmPlaybookProps): JSX.Element {
  const { setShowModal, alarm } = props;

  return (
    <div className="alarm-playbook">
      <Modal
        title={`${alarm.name} playbook`}
        width="344"
        setModalOpen={() => setShowModal(0)}
        primaryButtonAction={() => setShowModal(0)}
        secondaryButtonAction={() => setShowModal(0)}
        primaryButtonLabel="OK"
        secondaryButtonLabel="Cancel"
      >
        <div className="content">
          <div className="content-text">
            <div>
              {alarm.action || 'Contact Verizon support by email at IoTtechsupport@verizonwireless.com.'}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AlarmPlaybook;
