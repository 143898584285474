import React from 'react';
import { DimminPointBtnProps } from '../../../types/DimminPointBtnProps';

import { ReactComponent as SunsetSunriseIcon } from '../../../img/icons/sunrise-sunset.svg';
import { ReactComponent as FixedTimeIcon } from '../../../img/icons/fixed-time.svg';

function DimmingPointBtn(props: DimminPointBtnProps): JSX.Element {
  const { type, photocell, onClick, selected = false, isDisabled = false } = props;

  const icon = type === 'fixed' ? <FixedTimeIcon /> : <SunsetSunriseIcon />;
  const iconColorClass = photocell ? 'photocell' : 'fixed';
  const selectedClass = selected ? 'selected' : '';

  return (
    <button
      disabled={isDisabled}
      className={`dimmingpoints-setter__notification-content-btn ${iconColorClass} ${selectedClass}`}
      type="button"
      onClick={onClick}
    >
      { icon }
    </button>
  );
}

export default DimmingPointBtn;
