import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import enUS from 'date-fns/locale/en-US';
import { DatepickerProps } from '../../types/Datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function Datepicker(props: DatepickerProps): JSX.Element {
  const {
    placement = 'top',
    selectedDate,
    setSelectedDate,
    placeholder = 'Select date',
    label,
    labelPlacement = 'above',
    popperPosition = 'inline',
    type = 'dark',
    minDate,
    maxDate,
    inline = false,
    dateTimeformat = 'MMMM d, yyyy',
    defaultEmpty = false,
    required = false,
    excludeDates,
  } = props;

  registerLocale('en-US', enUS);

  useEffect(() => {
    if (!defaultEmpty && !selectedDate) {
      setSelectedDate(new Date(Date.now()));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`react-datepicker-container react-datepicker-container--${type} react-datepicker-container--${labelPlacement}`}>
      {label && (
      <div className={`react-datepicker__label--${labelPlacement}`}>
        {label}
        {required && (<span className="react-datepicker__label--required">*</span>)}
      </div>
      )}
      <div className="react-datepicker-inner-container">
        <DatePicker
          className={`react-datepicker--${type}`}
          calendarClassName={`react-datepicker--${type}`}
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date as Date)}
          dateFormat={dateTimeformat}
          showPopperArrow={false}
          popperPlacement={placement}
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '0px, 14px',
            },
          }}
          placeholderText={placeholder}
          locale="en-US"
          disabledKeyboardNavigation
          minDate={minDate}
          maxDate={maxDate}
          popperClassName={`react-datepicker__popper--${popperPosition}`}
          inline={inline}
          excludeDates={excludeDates}
        />
      </div>
    </div>
  );
}

export default Datepicker;
