import React from 'react';
import Modal from '../../../Common/Components/Modal';
import { ClearAlarmProps } from '../../../types/ClearAlarmProps';
import { useAppContext } from '../../../utils/AppContext';
import { postRequest } from '../../../utils/fetch';

function ClearAlarm(props: ClearAlarmProps): JSX.Element {
  const { setShowModal,
    alarm,
    selectedCustomer,
    selectedSite } = props;

  const { addNotification } = useAppContext();

  return (
    <div className="alarm-clear">
      <Modal
        title="Dismiss alarm"
        width="284"
        setModalOpen={() => setShowModal(0)}
        primaryButtonAction={async () => {
          if (alarm !== undefined) {
            try {
              const path = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/dismiss`;
              const result = await postRequest(path, { alertIds: [alarm.id] });

              if (!result.error) {
                addNotification({ type: 'success', message: `Alarm "${alarm.type}" successfully dismissed.` });
              } else {
                addNotification({ type: 'error', message: `Cannot dismiss the selected alarm. API reported error: ${result.error}` });
              }
              setShowModal(0);
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Dismiss alarm" operation has failed.' });
              setShowModal(0);
            }
          }
        }}
        secondaryButtonAction={() => setShowModal(0)}
        primaryButtonLabel="Dismiss"
        secondaryButtonLabel="Cancel"
      >
        <div className="content">
          <div className="content-text">
            <div>
              <span>
                Are you sure you want to dismiss this
                <br />
                <b>{alarm.name}</b>
                <span> alarm?</span>
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ClearAlarm;
