/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import useSWR from 'swr';

import Modal from '../../../../../Common/Components/Modal';
import { AlertActivityReportResult, DownloadAlertActivityReportModalProps } from '../../../../../types/AlertActivityReports';
import { ReactComponent as ReportsIcon } from '../../../../../img/icons/reports.svg';
import Button from '../../../../../Common/Components/Button';
import { deleteRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';
import Utils from '../../../../../utils/Utils';
import Loading from '../../../../../Common/Components/Loading';

const modalControlsHeight = 170;
const downloadItemHeight = 75;
const downloadMaxRows = 4;

const isNonReadOnly = Utils.isNonReadOnly();
function DownloadAlertActivityReportModal(props: DownloadAlertActivityReportModalProps): JSX.Element {
  const { selectedCustomer, selectedSite, selectedReport, setOpenModal } = props;

  const { addNotification } = useAppContext();
  const [selectedReportResult, setSelectedReportResult] = useState<AlertActivityReportResult>();
  const [openRemoveReportModal, setOpenRemoveReportModal] = useState(false);
  const downloadUrl = `${window.NSN.apiURLV4}/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/reports/${selectedReport.id}/results`;

  const downloadFile = (fileName: string) => {
    const a = document.createElement('a');
    a.href = `${downloadUrl}/${fileName}`;
    a.download = fileName;
    a.click();
  };

  const { data: alertActivityReportResultsResp, mutate: updateReportResults } = useSWR<AlertActivityReportResult[]>(
    [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/reports/${selectedReport.id}/results`, `GetAlertActivityReportResults-${selectedReport.id}`],
  );

  return (
    <>
      {!openRemoveReportModal ? (
        <Modal
          title="Download report"
          width="360"
          height={(alertActivityReportResultsResp && alertActivityReportResultsResp.length < downloadMaxRows
            ? Math.max((modalControlsHeight + (alertActivityReportResultsResp.length * downloadItemHeight)), modalControlsHeight + 20)
            : (modalControlsHeight + (downloadMaxRows * downloadItemHeight))).toString()}
          setModalOpen={() => setOpenModal(0)}
          secondaryButtonLabel="Close"
          hasTable
        >
          {!alertActivityReportResultsResp ? (
            <Loading />
          ) : (alertActivityReportResultsResp.length === 0 ? (
            <span>No results.</span>
          ) : (
            alertActivityReportResultsResp.map((item, i) => (
              <div key={i} className="alert-report-item">
                <div className="alert-report-item__icon"><ReportsIcon fill="#0088CE" /></div>
                <div className="alert-report-item__details">
                  <div className="alert-report-item__title">
                    <div className="alert-report-item__title--name">{selectedReport.name}</div>
                  </div>
                  <div className="alert-report-item__created">
                    Created on
                    {' '}
                    {Utils.getConvertedDate(item.generationDate, undefined, 'utc')}
                  </div>
                  <div className="alert-report-item__links">
                    <Button
                      buttonType="textlink"
                      buttonSize="medium"
                      label="Download"
                      onClick={() => downloadFile(item.objectName)}
                    />
                    {(i > 0 && isNonReadOnly) && (
                    <Button
                      buttonType="textlink"
                      onClick={() => {
                        setSelectedReportResult(item);
                        setOpenRemoveReportModal(true);
                      }}
                      label="Remove"
                    />
                    )}
                  </div>
                </div>
              </div>
            ))
          ))}
        </Modal>
      ) : (
        <Modal
          title="Delete result"
          width="360"
          setModalOpen={() => setOpenRemoveReportModal(false)}
          primaryButtonLabel="Confirm"
          primaryButtonAction={async () => {
            try {
              const result = await deleteRequest(`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/reports/${selectedReport.id}/results/${selectedReportResult?.objectName}`);

              if (!result.error) {
                addNotification({ type: 'info', message: 'Your delete result operation is completed.' });
              } else {
                addNotification({ type: 'error', message: `Your delete result operation has failed: ${result.error}` });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your delete result user operation has failed.' });
            } finally {
              updateReportResults();
              setSelectedReportResult(undefined);
              setOpenRemoveReportModal(false);
            }
          }}
          secondaryButtonLabel="Cancel"
        >
          <span>Are you sure you want to delete this result?</span>
        </Modal>
      )}
    </>
  );
}

export default DownloadAlertActivityReportModal;
