export const nodePropMap = {
  model: 'A',
  fixtureType: 'B',
  nodeid: 'C',
  name: 'D',
  groupnamelist: 'E',
  groupidlist: 'BE',
  timezone: 'F',
  level: 'G',
  remoteNetwork: 'H',
  scheduleId: 'I',
  ip: 'J',
  fixturename: 'K',
  schedulename: 'L',
  fixtureid: 'M',
  bssid: 'N',
  softwareVersion: 'O',
  latitude: 'P',
  longitude: 'Q',
  ligStat: 'R',
  netStat: 'S',
  senStat: 'T',
  hardware: 'U',
  network: 'V',
  software: 'W',
  ligLastReported: 'X',
  deviceFirmwareVersion: 'Y',
  appFirmwareLastUpdated: 'BB',
  msisdn: 'Z',
  iccid: 'AA',
  declared: 'AB',
  activationDate: 'AC',
  gpsSampleSize: 'AD',
  gpsAccuracy: 'AE',
  gpsLastReportedTimestamp: 'AF',
  commissionedDate: 'AG',
  commissionState: 'AH',
  note: 'AI',
  accountName: 'AL',
  accountRef: 'AM',
  latitudeUser: 'AN',
  longitudeUser: 'AO',
  latitudeGps: 'AP',
  longitudeGps: 'AQ',
  tsNetStat: 'AR',
  customAttributes_SLID: 'AS',
  customAttributes_LAMPSEQ: 'AT',
  customAttributes_LAT: 'AU',
  customAttributes_LON: 'AV',
  customAttributes_LAMPDESC: 'AW',
  customAttributes_LAMPWATTS: 'AX',
  customAttributes_LUMTYPE: 'AY',
  customAttributes_CATALOG_NUM: 'AZ',
  autoCommissioned: 'BA',
  lightingGroupConfirmation: 'BD',
};

export default {
  nodePropMap,
};
