import React from 'react';
import { ButtonProps } from '../../types/ButtonProps';

function Button(props: ButtonProps): JSX.Element {
  const { label, extraClasses, onClick, disabled = false, children, buttonType = 'textlink', buttonSize = 'medium' } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      data-testid={label}
      className={`${extraClasses || ''} btn btn-${buttonType} btn-${buttonSize}`}
    >
      {children || label}
    </button>
  );
}

export default Button;
