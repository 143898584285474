import React, { useCallback, useEffect, useRef, useState } from 'react';
import SlideIn from '../../../Common/Components/SlideIn';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { GenericSensorData } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { useAppContext } from '../../../utils/AppContext';
import Sensors from '../../../utils/Sensors';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';

function LightsEnergy(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite, fetchedBulkSensorData } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const displayedSensors = ['cumulativeEnergyUse', 'cumulativeReactiveEnergyUse', 'cumulativeOnStateLuminaire'];
  const timestamp = useRef<number>(0);
  const [sensorData, setSensorData] = useState<(GenericSensorData)>({ nodeid: selectedNode.nodeid } as GenericSensorData);
  const [refreshLoadingSensors, setRefreshLoadingSensors] = useState(false);
  const [latestSensorTsFromDWH, setLatestSensorTsFromDWH] = useState(new Date(0).getTime());
  const [onDemandReadTriggered, setOnDemandReadTriggered] = useState(false);
  const [newSensorDataAvailable, setNewSensorDataAvailable] = useState(false);

  const { addNotification } = useAppContext();

  useEffect(() => {
    Promise.resolve(fetchedBulkSensorData).then((response) => {
      const newData = Sensors.getSensorTableDataWithValueAndSensor(
        selectedItems,
        displayedSensors,
        response,
        selectedSite.timezone,
      );
      let latestSensorTs = new Date(0).getTime();
      if (response.sensorHistories && response.sensorHistories[0]) {
        Object.values(response.sensorHistories[0].data).forEach((d) => {
          const utcSensorTimestamp = `${d.timestamp}Z`;
          const sensorTs = new Date(utcSensorTimestamp).getTime();
          if (latestSensorTs < sensorTs) {
            latestSensorTs = sensorTs;
          }
        });
      }
      setLatestSensorTsFromDWH(latestSensorTs);
      setSensorData(newData[0]);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedBulkSensorData]);

  const refreshSensors = useCallback(() => {
    setRefreshLoadingSensors(true);
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = sensorData ? [{ ...sensorData }] : undefined;
    const tmpDisplayedData = Object.entries(sensorData).reduce((acc, e) =>
      (displayedSensors.includes(e[0]) ? acc : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: selectedNode.nodeid });
    setSensorData(tmpDisplayedData as GenericSensorData);

    Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      [selectedNode],
      displayedSensors.map((sen) => Sensors.getSensorId(sen)),
    )
      .then((response) => {
        setOnDemandReadTriggered(true);
        setRefreshLoadingSensors(false);
        if (timestamp.current <= currentDate) {
          let earliestSensorTs;
          if (response.sensorHistories && response.sensorHistories[0]) {
            Object.values(response.sensorHistories[0].data).forEach((d) => {
              const sensorTs = new Date(d.timestamp).getTime();
              if (!earliestSensorTs || sensorTs < earliestSensorTs) {
                earliestSensorTs = sensorTs;
              }
            });
          }
          if (latestSensorTsFromDWH < earliestSensorTs) {
            const newData = Sensors.getSensorTableDataWithValueAndSensor(
              selectedItems,
              displayedSensors,
              response,
              selectedSite.timezone,
              originalData,
            );
            setSensorData(newData[0]);
            setNewSensorDataAvailable(true);
          } else {
            setSensorData(sensorData);
          }
        }
      }).catch((e) => {
        addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNotification, selectedCustomer.id, selectedItems, selectedNode, selectedSite.id, sensorData]);

  return (
    <div className="energy">
      <SlideIn>
        <>
          <div className="slide-in__title">
            <span>Energy</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="custom-list-element sensors">
            <div className="list-element-title">Sensors</div>
            {(!refreshLoadingSensors) && (
              <SyncIcon
                data-testid="icon"
                className="list-element__refresh"
                onClick={() => {
                  refreshSensors();
                }}
              />
            )}
            {(onDemandReadTriggered && !newSensorDataAvailable && !refreshLoadingSensors) && (
            <div className="list-element__refresh-result">
              <Tooltip text="There's no newer data available.">
                <InfoIcon />
              </Tooltip>
            </div>
            )}
            {(refreshLoadingSensors) && (
            <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
            )}

            <div className="slide-in__content">
              <SlideinListElementBig
                title="Active energy use"
                valueBold={sensorData?.cumulativeEnergyUse?.value || '--'}
                date={sensorData?.cumulativeEnergyUse?.time}
                value={sensorData?.cumulativeEnergyUse?.unit || 'kWh'}
                isFirstElement
              />
              <SlideinListElementBig
                title="Reactive energy use"
                valueBold={sensorData?.cumulativeReactiveEnergyUse?.value || '--'}
                date={sensorData?.cumulativeReactiveEnergyUse?.time}
                value={sensorData?.cumulativeReactiveEnergyUse?.unit || 'VARh'}
              />
              <SlideinListElementBig
                title="Fixture burn hours"
                valueBold={sensorData?.cumulativeOnStateLuminaire?.value || '--'}
                date={sensorData?.cumulativeOnStateLuminaire?.time}
                value={sensorData?.cumulativeOnStateLuminaire?.unit || 'h'}
              />
              <div className="slide-in__content-info">
                <span>All values are cumulative</span>
                <Tooltip
                  addTooltipClass="energy-tooltip"
                  text="Energy use, node burn hours, and fixture burn hours are calculated over the lifetime of the installed node."
                >
                  <InfoIcon />
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsEnergy;
