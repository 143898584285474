import React from 'react';
import AdminSidebarItem from '../../../Common/Components/AdminSidebarItem';
import { AdminSidebarConfigItemsProps } from '../../../types/AdminSidebarConfigItems';

// not the exact icons
import { ReactComponent as FixturesIcon } from '../../../img/icons/operation.svg';
import { ReactComponent as CustomFieldsIcon } from '../../../img/icons/custom-fields.svg';
// import { ReactComponent as HardwareIcon } from '../../../img/icons/hardware.svg';
import { ReactComponent as ReportsIcon } from '../../../img/icons/reports.svg';
import { ReactComponent as AlarmsIcon } from '../../../img/icons/alarms.svg';
import { ReactComponent as AlertActivityReportsIcon } from '../../../img/icons/alert-activity-report.svg';
import Utils from '../../../utils/Utils';

function AdminSidebarConfigItems(props: AdminSidebarConfigItemsProps): JSX.Element {
  const { path } = props;

  return (
    <>
      <AdminSidebarItem
        icon={<FixturesIcon />}
        title="Fixtures"
        path={path}
        link="/config/fixtures"
      />
      {Utils.isVerizonUserorAdminUser() && (
      <>
        {
        /* // Disabling the App firmware link until the page works
        <AdminSidebarItem
          icon={<HardwareIcon />}
          title="App firmware"
          path={path}
          link="/config/firmware"
        />
        */ }
        <AdminSidebarItem
          icon={<CustomFieldsIcon />}
          title="Custom attributes"
          path={path}
          link="/config/customattributes"
        />
      </>
      )}
      {Utils.isSensityUserAdmin() && (
        <AdminSidebarItem
          icon={<AlarmsIcon />}
          title="Alarm Configuration"
          path={path}
          link="/config/alarmconfiguration"
        />
      )}
      <AdminSidebarItem
        icon={<ReportsIcon />}
        title="Audit trail"
        path={path}
        link="/config/audittrail"
      />
      <AdminSidebarItem
        icon={<AlertActivityReportsIcon />}
        title="Alert Activity Reports"
        path={path}
        link="/config/alertactivityreports"
      />
    </>
  );
}

export default AdminSidebarConfigItems;
