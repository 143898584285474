import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import RadioButtons from '../../../../../Common/Components/RadioButtons';

import Textbox from '../../../../../Common/Components/Textbox';
import SelectBox from '../../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import { RadioButtonElementProps } from '../../../../../types/RadioButtonsProps';
import { CreateEditFixtureProps, NewEditFixtureStateProp, NewEditFixtureValidationProp, CreateEditFixturePostProp } from '../../../../../types/CreateEditFixtureProps';
import Utils from '../../../../../utils/Utils';
import { postRequest, patchRequest, deleteRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';
import formValidation from '../../../../../utils/form/formValidation';

function CreateEditFixture(props: CreateEditFixtureProps): JSX.Element {
  const { modalOpen, selectedCustomer, updateFixtures, actions, fixtureDetails } = props;
  const { addNotification } = useAppContext();
  const selectBoxWidth = 221;
  const [fixtureTypeFilter] = useState(Utils.getFixtureType());

  const [createEditFixture, setCreateEditFixture] = useState<NewEditFixtureStateProp>({
    name: fixtureDetails?.name || '',
    powerDraw: fixtureDetails?.powerDraw || 0,
    description: fixtureDetails?.description || '',
    type: fixtureTypeFilter.find((ft) => ft.title === fixtureDetails?.type) || '',
    power0: fixtureDetails?.power0 || 0,
    power10: fixtureDetails?.power10 || 0,
    power20: fixtureDetails?.power20 || 0,
    power30: fixtureDetails?.power30 || 0,
    power40: fixtureDetails?.power40 || 0,
    power50: fixtureDetails?.power50 || 0,
    power60: fixtureDetails?.power60 || 0,
    power70: fixtureDetails?.power70 || 0,
    power80: fixtureDetails?.power80 || 0,
    power90: fixtureDetails?.power90 || 0,
    power100: fixtureDetails?.power100 || 0,
    manufacturer: fixtureDetails?.manufacturer || '',
    manufacturerSku: fixtureDetails?.manufacturerSku || '',
    minLevelFailureDetection: fixtureDetails?.minLevelFailureDetection || '',
    nemaSocket: fixtureDetails?.nemaSocket || 'Yes',
    alertMode: fixtureDetails?.alertMode || 1,
    margin: fixtureDetails?.margin || 10,
  });

  const [createEditFixtureValidation, setCreateEditFixtureValidation] = useState<NewEditFixtureValidationProp>({
    nameError: false,
    nameErrorMessage: '',
    powerDrawError: false,
    powerDrawErrorMessage: '',
    power0Error: false,
    power0ErrorMessage: '',
    power10Error: false,
    power10ErrorMessage: '',
    power20Error: false,
    power20ErrorMessage: '',
    power30Error: false,
    power30ErrorMessage: '',
    power40Error: false,
    power40ErrorMessage: '',
    power50Error: false,
    power50ErrorMessage: '',
    power60Error: false,
    power60ErrorMessage: '',
    power70Error: false,
    power70ErrorMessage: '',
    power80Error: false,
    power80ErrorMessage: '',
    power90Error: false,
    power90ErrorMessage: '',
    power100Error: false,
    power100ErrorMessage: '',
    marginError: false,
    marginErrorMessage: '',
  });

  const [nemaSocket, setNemaSocket] = useState<RadioButtonElementProps>({ label: createEditFixture?.nemaSocket, key: createEditFixture?.nemaSocket });
  const [alertMode, setAlertMode] = useState<RadioButtonElementProps>({ label: createEditFixture?.alertMode.toString(), key: createEditFixture?.alertMode.toString() });
  const setName = (newName: string) => {
    const name = newName;
    const validation = formValidation(
      name,
      { required: true },
    );
    setCreateEditFixture((oldValues) => ({
      ...oldValues,
      name,
    }));
    if (validation.hasError) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        nameError: validation.hasError,
        nameErrorMessage: validation.errorMsg,
      }));
    } else {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        nameError: false,
        nameErrorMessage: '',
      }));
    }
  };

  const setNumericFields = (fieldName: string, newValue: string) => {
    const powerDrawRegex = /^(?:0|[1-9]\d{0,3})$/;
    const marginRegex = /^(?:100|[1-9]\d?|0)$/;

    if (newValue === '') {
      setCreateEditFixture((oldValues) => ({
        ...oldValues,
        [fieldName]: '',
      }));
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        [`${fieldName}Error`]: false,
        [`${fieldName}ErrorMessage`]: '',
      }));
      return;
    }
    const trimmedValue = newValue.replace(/^0+(?=\d)/, '');
    if (fieldName !== 'margin' && !powerDrawRegex.test(trimmedValue)) {
      return;
    }
    if (fieldName === 'margin' && !marginRegex.test(trimmedValue)) {
      return;
    }
    const validation = formValidation(
      trimmedValue,
      { checkNumberFormat: true },
    );

    setCreateEditFixture((oldValues) => ({
      ...oldValues,
      [fieldName]: trimmedValue,
    }));

    setCreateEditFixtureValidation((oldValues) => ({
      ...oldValues,
      [`${fieldName}Error`]: validation.hasError,
      [`${fieldName}ErrorMessage`]: validation.hasError ? validation.errorMsg : '',
    }));
  };

  function checkPowerDrawLevelsValidation(): boolean {
    const powerLevels = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];

    const hasInvalidPowerLevels = powerLevels.slice(0, -1).some((level, index) => {
      const currentPower = Number(createEditFixture[`power${level}`]);
      const nextPower = Number(createEditFixture[`power${powerLevels[index + 1]}`]);

      if (currentPower > nextPower) {
        setCreateEditFixtureValidation((oldValues) => ({
          ...oldValues,
          [`power${level}Error`]: true,
          [`power${level}ErrorMessage`]: `Power${level} should be <= Power${powerLevels[index + 1]}`,
        }));
        return true;
      }
      return false;
    });

    return hasInvalidPowerLevels;
  }
  const fixtureTitle = (actions === 'Create') ? 'Create new ' : 'Edit ';

  return (
    <div className="fixture-interactions create-fixture">
      <Modal
        setModalOpen={modalOpen}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          const currentFixture = createEditFixture;
          if (currentFixture.name === '') {
            setName(currentFixture.name);
            return;
          }

          if (alertMode.key === '2' && checkPowerDrawLevelsValidation()) {
            return;
          }
          try {
            const postBody: CreateEditFixturePostProp = {
              name: currentFixture.name,
              powerDraw: Number(currentFixture.powerDraw),
              description: currentFixture.description,
              type: currentFixture.type.title || '',
              power0: Number(currentFixture.power0),
              power10: Number(currentFixture.power10),
              power20: Number(currentFixture.power20),
              power30: Number(currentFixture.power30),
              power40: Number(currentFixture.power40),
              power50: Number(currentFixture.power50),
              power60: Number(currentFixture.power60),
              power70: Number(currentFixture.power70),
              power80: Number(currentFixture.power80),
              power90: Number(currentFixture.power90),
              power100: Number(currentFixture.power100),
              manufacturer: currentFixture.manufacturer,
              manufacturerSku: currentFixture.manufacturerSku,
              minLevelFailureDetection: currentFixture.minLevelFailureDetection,
              margin: Number(currentFixture.margin),
              nemaSocket: nemaSocket.key,
              alertMode: Number(alertMode.key),
            };
            let result;
            if (actions === 'Create') {
              result = await postRequest(
                `/organizations/${selectedCustomer.id}/fixtures/types`,
                postBody,
              );
            } else {
              result = await patchRequest(
                `/organizations/${selectedCustomer.id}/fixtures/types/${fixtureDetails?.id}`,
                {
                  description: postBody.description,
                  power0: postBody.power0,
                  power10: postBody.power10,
                  power20: postBody.power20,
                  power30: postBody.power30,
                  power40: postBody.power40,
                  power50: postBody.power50,
                  power60: postBody.power60,
                  power70: postBody.power70,
                  power80: postBody.power80,
                  power90: postBody.power90,
                  power100: postBody.power100,
                  manufacturer: postBody.manufacturer,
                  manufacturerSku: postBody.manufacturerSku,
                  minLevelFailureDetection: postBody.minLevelFailureDetection,
                  margin: postBody.margin,
                  nemaSocket: nemaSocket.key,
                  alertMode: Number(alertMode.key),
                },
              );
            }
            if (!result.error) {
              addNotification({ type: 'info', message: `Your ${actions} fixture operation is completed.` });
              if (updateFixtures) {
                updateFixtures();
              }
            } else {
              addNotification({ type: 'error', message: `Your ${actions} fixture operation has failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: `Your ${actions} user operation has failed.` });
          }
          modalOpen(false);
        }}
        secondaryButtonLabel={actions === 'Edit' ? 'Delete' : undefined}
        secondaryButtonAction={async () => {
          try {
            const result = await deleteRequest(
              `/organizations/${selectedCustomer.id}/fixtures/types/${fixtureDetails?.id}`,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Delete fixture" operation is completed.' });
              if (updateFixtures) {
                updateFixtures();
              }
            } else {
              addNotification({ type: 'error', message: `Your "Delete fixture" operation has failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Delete fixture" operation has failed.' });
          }
          modalOpen(false);
        }}
        tertiaryButtonLabel="Cancel"
        tertiaryButtonAction={() => modalOpen(false)}
        width="770"
        height="770"
      >
        <div className="fixture-title">
          {fixtureTitle}
          fixture profile
        </div>

        <div className="modal-divided">
          <div className="modal-divided__leftcontent">
            <div className="modal-divided__content-data">
              <div className="fixture-details"> Fixture details </div>
              <Textbox
                label="Name"
                name="Name"
                value={createEditFixture.name}
                placeholder="Enter"
                onChange={(event) => {
                  setName(event.target.value);
                }}
                disabled={actions === 'Edit'}
                isRequired
                error={createEditFixtureValidation.nameError}
                errorMessage={createEditFixtureValidation.nameErrorMessage}
              />
              <Textbox
                label="Description"
                name="Description"
                value={createEditFixture.description}
                placeholder="Enter"
                onChange={(event) => {
                  const description = event.target.value;
                  setCreateEditFixture((oldValues) => ({ ...oldValues, description }));
                }}
              />
              <div className="typeWattageWrapper">
                <SelectBox
                  label="Fixture type"
                  onClick={(item: SelectBoxItemType) =>
                    setCreateEditFixture((oldValues) => ({ ...oldValues, type: item }))}
                  selectedItemKey={createEditFixture?.type?.key || ''}
                  list={Utils.arrayToSelectbox(fixtureTypeFilter || [], 'key', 'title')}
                  title={createEditFixture.type.title || 'Select'}
                  type="light"
                  listWidth={selectBoxWidth}
                  disabled={actions === 'Edit'}
                />
                <Textbox
                  label="Wattage rating"
                  name="powerDraw"
                  type="text"
                  value={createEditFixture.powerDraw.toString()}
                  placeholder="0 - 9999"
                  disabled={actions === 'Edit'}
                  onChange={(event) => setNumericFields('powerDraw', event.target.value)}
                  error={createEditFixtureValidation.powerDrawError}
                  errorMessage={createEditFixtureValidation.powerDrawErrorMessage}
                />
                <span className="wattageRatingUnits"> W </span>
              </div>
              <div className="nemaSocketWrapper">
                <span>NEMA socket</span>
                <RadioButtons
                  onClick={(clickedRadioBtn: RadioButtonElementProps) =>
                    setNemaSocket(clickedRadioBtn)}
                  selected={nemaSocket}
                  list={[{ label: 'Yes', key: 'Yes' }, { label: 'No', key: 'No' }]}
                  type="light"
                />
              </div>
              <div className="manufacturerSkuWrapper">
                <Textbox
                  label="Manufacturer"
                  name="Manufacturer"
                  value={createEditFixture.manufacturer}
                  placeholder="Enter"
                  onChange={(event) => {
                    const manufacturer = event.target.value;
                    setCreateEditFixture((oldValues) => ({ ...oldValues, manufacturer }));
                  }}
                />
                <Textbox
                  label="Manufacturer SKU"
                  name="ManufacturerSKU"
                  value={createEditFixture.manufacturerSku}
                  placeholder="Enter"
                  onChange={(event) => {
                    const manufacturerSku = event.target.value;
                    setCreateEditFixture((oldValues) => ({ ...oldValues, manufacturerSku }));
                  }}
                />
              </div>
              <div className="nemaSocketWrapper">
                <div>
                  To detect Overpower/Underpower alerts on a node associated with
                </div>
                <div className="second-line">
                  this fixture profile, compare its actual power draw...
                </div>
                <RadioButtons
                  onClick={(clickedRadioBtn: RadioButtonElementProps) =>
                    setAlertMode(clickedRadioBtn)}
                  selected={alertMode}
                  list={[{ label: 'with the node\'s own calibrated values.',
                    key: '1' }, { label: 'with values entered here for the profile.', key: '2' }]}
                  type="light"
                />
              </div>
            </div>
          </div>
          <div className="modal-divided__rightcontent">
            <div className="powerDrawSection">
              <div className="powerdraw-details"> Fixture profile power draw</div>
              <div className="powerDrawWrapper no-border">
                <div className="powerDrawHeading1"> Driver level </div>
                <div className="powerDrawHeading2"> Minimum power draw </div>
              </div>

              {['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'].map((lvl) => (
                <div key={`Power${lvl}`} className="powerDrawWrapper">
                  <div className="powerDrawtitle">
                    {`${lvl}%`}
                  </div>
                  {alertMode.key === '2' && (
                  <>
                    <Textbox
                      label=""
                      name={`power${lvl}`}
                      value={createEditFixture[`power${lvl}`] ?? ''}
                      placeholder="0 - 9999"
                      type="text"
                      small
                      onChange={(event) => {
                        setNumericFields(`power${lvl}`, event.target.value);
                      }}
                      error={createEditFixtureValidation[`power${lvl}Error`]}
                      errorMessage={createEditFixtureValidation[`power${lvl}ErrorMessage`]}
                    />
                    <span className={`powerDrawUnits ${createEditFixtureValidation[`power${lvl}Error`] ? 'error' : ''}`}> W </span>
                  </>
                  )}
                  {alertMode.key === '1' && (
                    <span>
                      {createEditFixture[`power${lvl}`]}
                      <span>
                        &nbsp;W
                      </span>
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="powerDrawMargin">
              <Textbox
                label="Margin (%)"
                name="margin"
                value={createEditFixture.margin.toString() ?? ''}
                placeholder="0 - 100"
                type="text"
                small
                onChange={(event) => {
                  setNumericFields('margin', event.target.value);
                }}
                error={createEditFixtureValidation.marginError}
                errorMessage={createEditFixtureValidation.marginErrorMessage}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateEditFixture;
