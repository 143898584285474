import React from 'react';
import { SWRConfig } from 'swr';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useSWRGetRequest } from './utils/fetch';
import routes from './routes';
import './scss/bundle.scss';
import Header from './Common/Header/Header';
import useCustomerSiteSelect from './utils/useCustomerSiteSelect';
import { AppContextProvider } from './utils/AppContext';
import NotificationBarContainer from './Common/Components/NotificationBarContainer';
import { BASEPATH } from './utils/constants';

function App(): JSX.Element {
  const isCodeEndPoint = window.location.pathname === `${BASEPATH}/code`;
  const commonProps = useCustomerSiteSelect();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function CustomRoute(props: any) {
    const { component: Component, ...rest } = props;

    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <AppContextProvider>
            <NotificationBarContainer />
            <Header {...commonProps} path={window.location.pathname} />
            <Component {...commonProps} {...routeProps} />
          </AppContextProvider>
        )}
      />
    );
  }

  return (
    <div className="container">
      <Router basename={BASEPATH}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            errorRetryCount: 10,
            fetcher: useSWRGetRequest,
          }}
        >
          <Switch>
            {routes.map((route, i) => (
              <CustomRoute
                exact
                key={i}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
          <Route exact path="/">
            <Redirect push to="/sites" />
          </Route>
          {!sessionStorage.getItem('userInfo') && !isCodeEndPoint && (
            <Redirect push to="/login" />
          )}
        </SWRConfig>
      </Router>
    </div>
  );
}

export default App;
