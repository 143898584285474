/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import useSWR from 'swr';
import Modal from '../../../Common/Components/Modal';
// import Button from '../../../Common/Components/Button';
import { ReportTemplateResolution } from '../../../types/ReportTemplateObject';
import { ReportDefinitionsTableItem } from '../../../types/ReportDefinitionObject';
import { NodeObject } from '../../../types/NodeObject';
import { GroupObject } from '../../../types/GroupObject';
import { ReportDefinitionModalProps } from '../../../types/ReportDefinitionModalProps';
import { RadioButtonElementProps } from '../../../types/RadioButtonsProps';
import Table from '../../../Common/Components/Table';
import Textbox from '../../../Common/Components/Textbox';
import SelectBox from '../../../Common/Components/SelectBox';
import RadioButtons from '../../../Common/Components/RadioButtons';
import Datepicker from '../../../Common/Components/Datepicker';
import { postRequest, putRequest } from '../../../utils/fetch';
import Utils from '../../../utils/Utils';
import { reportDictionary, nodesPageSize, reportDays, scheduleDimensions } from '../../../utils/constants';
import { useAppContext } from '../../../utils/AppContext';
import { nodesFetcherFn } from '../../../utils/ApiDataHelpers';
import getHeaderProps from '../../../utils/getHeaderProps';

function ReportDefinitionModal(props: ReportDefinitionModalProps): JSX.Element {
  const { setModalOpen, action, selectedReportDefinitionsTableItem, selectedCustomer, selectedSite, reportTemplate,
    selectedNodes, setSelectedNodes, selectedGroups, setSelectedGroups, updateReportDefinitionsList } = props;
  const { addNotification } = useAppContext();
  const selectBoxWidth = 272;
  if (selectedReportDefinitionsTableItem.selectedResolution === null) {
    // eslint-disable-next-line prefer-destructuring
    selectedReportDefinitionsTableItem.selectedResolution = reportTemplate.availableResolutions[0];
  }

  const { data: nodesResp } = useSWR<Array<NodeObject>>(() => `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/lkp/nodes?size=${nodesPageSize}`, nodesFetcherFn);

  const {
    data: groupsResp,
  } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups']
    : null);

  function pruneFields(payload, actionType) {
    const tmp = structuredClone(payload);
    delete tmp.frequency;
    delete tmp.scope;
    delete tmp.actions;
    delete tmp.createdAt;
    delete tmp.templateId;
    delete tmp.templateVersion;
    delete tmp.ownerOrgId;
    delete tmp.targetOrgId;
    delete tmp.reportProcessor;
    delete tmp.startDate;
    delete tmp.reportDefinitionId;
    if (payload.selectedReportScheduleType === 'ONE_TIME') {
      delete tmp.endDate;
      delete tmp.reportDay;
      delete tmp.reportDate;
      delete tmp.reportHour;
      delete tmp.scheduleInterval;
      tmp.scheduleDimension = 'ONE_TIME';
    }
    if (payload.selectedReportScheduleType === 'SCHEDULED') {
      if (payload.scheduleDimension === 'HOURLY') {
        delete tmp.reportHour;
        delete tmp.reportDate;
        delete tmp.reportDay;
      }
      if (payload.scheduleDimension === 'DAILY') {
        delete tmp.reportDay;
        delete tmp.reportDate;
      }
      if (payload.scheduleDimension === 'WEEKLY') {
        delete tmp.reportDate;
      }
      if (payload.scheduleDimension === 'MONTHLY') {
        delete tmp.reportDay;
      }
      delete tmp.oneTimeStartDate;
      delete tmp.oneTimeEndDate;
    }
    return tmp;
  }

  function stringsToDates(def) {
    if (def.startDate) { def.startDate = new Date(def.startDate); }
    if (def.endDate) { def.endDate = new Date(def.endDate); }
    if (def.oneTimeStartDate) { def.oneTimeStartDate = new Date(def.oneTimeStartDate); }
    if (def.oneTimeEndDate) { def.oneTimeEndDate = new Date(def.oneTimeEndtDate); }
    return def;
  }

  const nodesHeader = getHeaderProps('NodesForReportDefinition');
  const groupsHeader = getHeaderProps('GroupsForReportDefinition');
  // eslint-disable-next-line operator-linebreak
  const [editedReportDefinitionsTableItem, setEditedReportDefinitionsTableItem] =
   useState<ReportDefinitionsTableItem>(stringsToDates(selectedReportDefinitionsTableItem) || Utils.defaultReportDefinition(reportTemplate.processor));

  const scheduleTypeList = [
    { label: 'One time', key: 'ONE_TIME' },
    { label: 'Scheduled', key: 'SCHEDULED' },
  ];

  function availableToSelected(key) {
    return {
      availableDimensions: 'selectedReportDimension',
      availableReportTypes: 'selectedReportScheduleType',
      availableResolutions: 'selectedResolution',
    }[key];
  }

  function resolutionToString(res: ReportTemplateResolution) {
    return (
      `${res.value} ${res.unit.toLowerCase()}${res.value > 1 ? 's' : ''}`
    );
  }

  function resolutionsToArray(res: ReportTemplateResolution[]) {
    const foo = res.map((r, idx) =>
      ({
        key: JSON.stringify(r),
        title: resolutionToString(r),
      }));
    return foo;
  }

  function enumsToArray(enums: string[]) {
    const foo = enums.map((en, idx) =>
      ({
        key: en,
        title: reportDictionary[en],
      }));
    return foo;
  }

  function getTimeRangeForResolution(res) {
    const timeRangeIdx = reportTemplate.availableTimeRanges.findIndex((t) => t.resolutionUnit === res.unit);
    const timeRangeValue = reportTemplate.availableTimeRanges[timeRangeIdx].maxRange.value;
    const timeRangeUnit = `${reportDictionary[reportTemplate.availableTimeRanges[timeRangeIdx].maxRange.unit]}${timeRangeValue > 1 ? 's' : ''}`;
    return `${timeRangeValue} ${timeRangeUnit}`;
  }

  function getDateList() {
    return Array.from(Array(32).keys()).slice(1).map((d) => ({ key: d.toString(), title: (d).toString() }));
  }

  function convertHour(h) {
    if (h === 0) return '12 am';
    if (h === 12) return '12 pm';
    return (h < 12 ? `${h} am` : `${h - 12} pm`);
  }

  function getHourList() {
    const hours = Array.from(Array(24).keys());
    return hours.map((h) => ({
      key: h.toString(),
      title: convertHour(h),
    }));
  }

  async function submit() {
    let payloadError = false;
    try {
      let result;
      delete editedReportDefinitionsTableItem.actions;
      delete editedReportDefinitionsTableItem.statusFmt;
      let postBody = structuredClone(editedReportDefinitionsTableItem);
      if (editedReportDefinitionsTableItem.reportName === '') {
        payloadError = true;
        addNotification({ type: 'error', message: 'Report name is required.' });
      } else {
        switch (editedReportDefinitionsTableItem.selectedReportDimension) {
          case 'NODE':
          case 'NODES':
            if (selectedNodes.size === 0) {
              payloadError = true;
              addNotification({ type: 'error', message: 'No nodes have been selected.' });
            } else {
              postBody.selectedReportTarget = Array.from(selectedNodes.keys());
            }
            break;
          case 'LIGHTING_GROUP':
          case 'TAG':
            if (selectedGroups.size === 0) {
              payloadError = true;
              addNotification({ type: 'error', message: 'No group been selected.' });
            } else if (selectedGroups.size > 1) {
              payloadError = true;
              addNotification({ type: 'error', message: 'Only one group can be selected.' });
            } else {
              postBody.selectedReportTarget = Array.from(selectedGroups.keys());
            }
            break;
          case 'SITE':
            postBody.selectedReportTarget = [selectedSite.id];
            break;
          default:
            postBody.selectedReportTarget = [];
        }
        if (!payloadError) {
          postBody.startDate = postBody.startDate ? (new Date(postBody.startDate)).toISOString() : null;
          postBody.endDate = postBody.endDate
            ? (new Date(postBody.endDate)).toISOString()
            : new Date(Date.now() + 31 * 24 * 60 * 60 * 1000).toISOString();
          postBody.oneTimeStartDate = postBody.oneTimeStartDate ? (new Date(postBody.oneTimeStartDate)).toISOString() : null;
          postBody.oneTimeEndDate = postBody.oneTimeEndDate ? (new Date(postBody.oneTimeEndDate)).toISOString() : null;
          postBody = pruneFields(postBody, action);
          if (action === 'Create') {
            result = await postRequest(
              `/organizations/${selectedCustomer.id}/templates/${reportTemplate.templateId}/report-definitions`,
              postBody,
            );
          } else {
            result = await putRequest(
              `/organizations/${selectedCustomer.id}/templates/${reportTemplate.templateId}/report-definitions/${editedReportDefinitionsTableItem.reportDefinitionId}`,
              postBody,
            );
          }
          if (!result.error) {
            addNotification({ type: 'info', message: `Your ${action} Report Definition is complete.` });
            setModalOpen(false);
            updateReportDefinitionsList();
          } else {
            addNotification({ type: 'error', message: `Your ${action} Report Defintion operation has failed: ${result.error}` });
          }
        }
      }
    } catch (e) {
      addNotification({ type: 'error', message: `Your ${action} Report Definition operation has failed.` });
    }
  }

  return (
    <div className="create-edit-report-definition">
      <Modal
        title={`${action} Report Definition`}
        setModalOpen={setModalOpen}
        primaryButtonLabel="Save"
        primaryButtonAction={() => submit()}
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => setModalOpen(false)}
        // eslint-disable-next-line no-nested-ternary
        tertiaryButtonLabel={`${action === 'Edit' ? (editedReportDefinitionsTableItem.status === 'ACTIVE' ? 'Deactivate and Save' : 'Activate and Save') : ''}`}
        tertiaryButtonAction={() => {
          editedReportDefinitionsTableItem.status = editedReportDefinitionsTableItem.status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE';
          submit();
        }}
        width="1080"
        height="625"
      >
        <div className="report-definition__report-name">
          <div>
            <Textbox
              label="Name"
              value={editedReportDefinitionsTableItem.reportName}
              onChange={(e) => {
                setEditedReportDefinitionsTableItem((oldVal) => {
                  const newVal = { ...oldVal };
                  newVal.reportName = e.target.value;
                  return newVal;
                });
              }}
              isRequired
            />
          </div>
          <div>
            <RadioButtons
              onClick={(clickedRadioBtn: RadioButtonElementProps) =>
                setEditedReportDefinitionsTableItem((oldVal) => {
                  const newVal = { ...oldVal };
                  newVal.selectedReportScheduleType = clickedRadioBtn.key;
                  return newVal;
                })}
              selected={scheduleTypeList.find((t) => t.key === editedReportDefinitionsTableItem.selectedReportScheduleType) || { label: '', key: '' }}
              list={scheduleTypeList}
              type="light"
            />
          </div>
        </div>
        <div className="report-definition__modal-content">
          <div className="col1">
            {Object.keys(reportTemplate).map((key, idx) => {
              switch (key) {
                case 'availableScheduleDimensions':
                  return (
                    <SelectBox
                      key={idx}
                      label={reportDictionary[key]}
                      type="light"
                      listWidth={selectBoxWidth}
                      title={reportDictionary[editedReportDefinitionsTableItem[availableToSelected(key)]]}
                      list={Utils.arrayToSelectbox(enumsToArray(reportTemplate[key]), 'key', 'title')}
                      onClick={(item) => {
                        setEditedReportDefinitionsTableItem((oldVal) => {
                          const newVal = { ...oldVal };
                          newVal[availableToSelected(key)] = item.title.toUpperCase().replace(' ', '_');
                          return newVal;
                        });
                      }}
                    />
                  );
                case 'availableResolutions':
                  if (reportTemplate.processor === 'ADVANCED_ENERGY') {
                    return (
                      <SelectBox
                        key={idx}
                        label={reportDictionary.availableResolutions}
                        type="light"
                        listWidth={selectBoxWidth}
                        title={resolutionToString(editedReportDefinitionsTableItem[availableToSelected(key)])}
                        list={Utils.arrayToSelectbox(resolutionsToArray(reportTemplate.availableResolutions), 'key', 'title')}
                        onClick={(item) => {
                          setEditedReportDefinitionsTableItem((oldVal) => {
                            const newVal = { ...oldVal };
                            newVal[availableToSelected(key)] = JSON.parse(item.key);
                            return newVal;
                          });
                        }}
                      />
                    );
                  }
                  return '';
                  break;
                case 'templateId':
                case 'name':
                case 'version':
                case 'active':
                case 'processor':
                case 'template':
                case 'access':
                  return ('');
                default:
                  return ('');
              }
            })}
            {editedReportDefinitionsTableItem.selectedReportScheduleType === 'ONE_TIME'
              && reportTemplate.processor === 'ADVANCED_ENERGY'
              && (
              <div className="report-definitions-timerange-message">
                With Granularity set to
                <br />
                {resolutionToString(editedReportDefinitionsTableItem.selectedResolution as ReportTemplateResolution)}
                ,
                <br />
                the maximum date range is
                <br />
                {getTimeRangeForResolution(editedReportDefinitionsTableItem.selectedResolution)}
                .
              </div>
              )}
            {editedReportDefinitionsTableItem.selectedReportScheduleType === 'SCHEDULED'
              && (
                <>
                  <div className="report-definition-frequency">
                    <Textbox
                      label="Generate the report every"
                      value={editedReportDefinitionsTableItem.scheduleInterval.toString()}
                      onChange={(e) => {
                        setEditedReportDefinitionsTableItem((oldVal) => {
                          const newVal = { ...oldVal };
                          newVal.scheduleInterval = parseInt(e.target.value, 10);
                          return newVal;
                        });
                      }}
                      type="number"
                      min="1"
                      max="31"
                    />
                    <SelectBox
                      label="&nbsp;"
                      type="light"
                      title={scheduleDimensions.find((d) => d.key === editedReportDefinitionsTableItem.scheduleDimension)?.title || ''}
                      list={scheduleDimensions}
                      onClick={(item) => {
                        setEditedReportDefinitionsTableItem((oldVal) => {
                          const newVal = { ...oldVal };
                          newVal.scheduleDimension = item.key;
                          return newVal;
                        });
                      }}
                      listWidth={120}
                    />
                  </div>
                  <div className="report-definition-when">
                    {editedReportDefinitionsTableItem.scheduleDimension === 'WEEKLY'
                      && (
                        <SelectBox
                          label="Run it on this day of the week"
                          type="light"
                          listWidth={selectBoxWidth}
                          title={reportDays.find((d) => (parseInt(d.key, 10)) === editedReportDefinitionsTableItem.reportDay)?.title || ''}
                          list={reportDays}
                          onClick={(item) => {
                            setEditedReportDefinitionsTableItem((oldVal) => {
                              const newVal = { ...oldVal };
                              newVal.reportDay = parseInt(item.key, 10);
                              return newVal;
                            });
                          }}
                        />
                      )}
                    {editedReportDefinitionsTableItem.scheduleDimension === 'MONTHLY'
                      && (
                        <SelectBox
                          label="Run it on this day of the month"
                          type="light"
                          title={((editedReportDefinitionsTableItem.reportDate || 0) || 1).toString()}
                          list={getDateList()}
                          onClick={(item) => {
                            setEditedReportDefinitionsTableItem((oldVal) => {
                              const newVal = { ...oldVal };
                              newVal.reportDate = parseInt(item.key, 10);
                              return newVal;
                            });
                          }}
                          listWidth={selectBoxWidth}
                        />
                      )}
                  </div>
                  {editedReportDefinitionsTableItem.scheduleDimension !== 'HOURLY'
                    && (
                      <SelectBox
                        label="Run it at this hour of the day"
                        type="light"
                        title={convertHour(editedReportDefinitionsTableItem.reportHour)}
                        list={getHourList()}
                        onClick={(item) => {
                          setEditedReportDefinitionsTableItem((oldVal) => {
                            const newVal = { ...oldVal };
                            newVal.reportHour = parseInt(item.key, 10);
                            return newVal;
                          });
                        }}
                        listWidth={selectBoxWidth}
                      />
                    )}
                </>
              )}
          </div>
          <div className="col2">
            {editedReportDefinitionsTableItem.selectedReportScheduleType === 'ONE_TIME'
              && (
                <>
                  <Datepicker
                    placement="bottom"
                    selectedDate={editedReportDefinitionsTableItem.oneTimeStartDate ? new Date(editedReportDefinitionsTableItem.oneTimeStartDate) : undefined}
                    setSelectedDate={(item) => {
                      setEditedReportDefinitionsTableItem((oldVal) => {
                        const newVal = { ...oldVal };
                        newVal.oneTimeStartDate = item;
                        return newVal;
                      });
                    }}
                    popperPosition="popup"
                    placeholder="Enter date"
                    label="Collect data from"
                    type="light"
                    maxDate={editedReportDefinitionsTableItem.oneTimeEndDate ? new Date(editedReportDefinitionsTableItem.oneTimeEndDate) : new Date()}
                    defaultEmpty
                    required
                  />
                  <Datepicker
                    placement="bottom"
                    selectedDate={editedReportDefinitionsTableItem.oneTimeEndDate ? new Date(editedReportDefinitionsTableItem.oneTimeEndDate) : undefined}
                    setSelectedDate={(item) => {
                      setEditedReportDefinitionsTableItem((oldVal) => {
                        const newVal = { ...oldVal };
                        newVal.oneTimeEndDate = item;
                        return newVal;
                      });
                    }}
                    popperPosition="popup"
                    placeholder="Enter date"
                    label="through"
                    type="light"
                    minDate={editedReportDefinitionsTableItem.oneTimeStartDate ? new Date(editedReportDefinitionsTableItem.oneTimeStartDate) : new Date()}
                    maxDate={new Date(Date.now())}
                    defaultEmpty
                    required
                  />
                </>
              )}

            {editedReportDefinitionsTableItem.selectedReportScheduleType === 'SCHEDULED'
              && (
                <>
                  <Datepicker
                    placement="bottom"
                    selectedDate={editedReportDefinitionsTableItem.endDate
                      ? new Date(editedReportDefinitionsTableItem.endDate)
                      : new Date(Date.now() + 31 * 24 * 60 * 60 * 1000)}
                    setSelectedDate={(item) => {
                      setEditedReportDefinitionsTableItem((oldVal) => {
                        const newVal = { ...oldVal };
                        newVal.endDate = item;
                        return newVal;
                      });
                    }}
                    popperPosition="popup"
                    placeholder="Enter date"
                    label="Stop running the report after"
                    type="light"
                    minDate={new Date()}
                    maxDate={new Date(Date.now() + 183 * 24 * 60 * 60 * 1000)}
                    defaultEmpty
                    required
                  />
                  {action === 'Edit' && editedReportDefinitionsTableItem.status !== 'ACTIVE'
                    && (
                      <div className="activate-message">
                        <p>
                          This Report Definition is currently deactivated and is not producing reports.
                        </p>
                        <p>
                          Use the
                          Activate and Save
                          button below to restart reports, but be sure that the above &quot;Stop&quot; value
                          is set to a future date.
                        </p>
                      </div>
                    )}
                </>
              )}
          </div>
          <div className="col3">
            <div className="selectbox-wrapper">
              <SelectBox
                label="Scope"
                type="light"
                title={reportDictionary[editedReportDefinitionsTableItem.selectedReportDimension]}
                list={Utils.arrayToSelectbox(enumsToArray(reportTemplate.availableDimensions), 'key', 'title')}
                onClick={(item) => {
                  setEditedReportDefinitionsTableItem((oldVal) => {
                    const newVal = { ...oldVal };
                    newVal.selectedReportDimension = item.title.toUpperCase().replace(' ', '_');
                    return newVal;
                  });
                }}
              />
            </div>

            <div className="report-definition-dimension-table table table--light">
              {(editedReportDefinitionsTableItem.selectedReportDimension === 'NODE'
                || editedReportDefinitionsTableItem.selectedReportDimension === 'NODES')
                && nodesResp
                && (
                  <Table
                    headers={nodesHeader}
                    data={nodesResp}
                    selectedItems={selectedNodes}
                    setSelectedItems={setSelectedNodes}
                    caller="nodes"
                    multiSelect
                    skipCellMeasure
                  />
                )}
              {editedReportDefinitionsTableItem.selectedReportDimension === 'LIGHTING_GROUP'
                && groupsResp
                && (
                  <Table
                    headers={groupsHeader}
                    data={groupsResp.filter((group, idx) => group.type === 'LIGHTING' || group.type === 'SITE_LIGHTING')}
                    selectedItems={selectedGroups}
                    setSelectedItems={setSelectedGroups}
                    skipCellMeasure
                  />
                )}
              {editedReportDefinitionsTableItem.selectedReportDimension === 'SITE'
                && groupsResp
                && (
                  <div className="report-definitions-scope-message">
                    Data from the entire
                    <br />
                      {selectedSite.name}
                    <br />
                    site will be included in the report.
                  </div>
                )}
              {editedReportDefinitionsTableItem.selectedReportDimension === 'ACCOUNT'
                && groupsResp
                && (
                  <div className="report-definitions-scope-message">
                    Data from the entire
                    <br />
                      {selectedCustomer.name}
                    <br />
                    account will be included in the report.
                  </div>
                )}

              {editedReportDefinitionsTableItem.selectedReportDimension === 'TAG'
                && groupsResp
                && (
                  <div>
                    {groupsResp.filter((group, idx) => group.type === 'ORGANIZATIONAL').length === 0
                      && (
                        <div className="report-definitions-scope-message">
                          No organizational groups are defined.
                        </div>
                      )}
                    {groupsResp.filter((group, idx) => group.type === 'ORGANIZATIONAL').length > 0
                      && (
                        <Table
                          headers={groupsHeader}
                          data={groupsResp.filter((group, idx) => group.type === 'ORGANIZATIONAL')}
                          selectedItems={selectedGroups}
                          setSelectedItems={setSelectedGroups}
                          skipCellMeasure
                        />
                      )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ReportDefinitionModal;
