// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { SubmitFn, UseFormProps, ValidateFn } from '../types/useFormProps';

export default function useForm<V, E>(
  validateFn: ValidateFn<V, E>,
  submitFn: SubmitFn<V>,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>> = () => undefined,
): UseFormProps<V, E> {
  const [formValues, setFormValues] = useState<V>({} as V);
  const [formErrors, setFormErrors] = useState<E>({} as E);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loading = useRef(false);
  const setLoading = (value: boolean) => {
    loading.current = value;
  };

  // Submit when handleSubmit fired and no errors
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      setIsSubmitting(false);
      submitFn(formValues, setLoading, setModalOpen);
    }
  }, [formValues, formErrors, isSubmitting, submitFn, setModalOpen]);

  // Every <form> element's submit button event
  const handleSubmit = (event: Event): void => {
    if (event) {
      event.preventDefault();
    }

    if (loading.current) {
      return;
    }

    setFormErrors(validateFn(formValues));
    setIsSubmitting(true);
  };

  // Every input field's change event
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any): void => {
    event.persist();
    const { name, value } = event?.target;

    setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    setFormErrors(validateFn({ ...formValues, [name]: value }, formErrors, name));
    setIsSubmitting(false);
  };

  return {
    loading,
    setLoading,
    handleSubmit,
    handleChange,
    formValues,
    formErrors,
  };
}
