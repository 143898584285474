import React from 'react';
import Utils from '../../../../../utils/Utils';
import { useAppContext } from '../../../../../utils/AppContext';
import { SiteModalCustomTitleProps } from '../../../../../types/SiteModalCustomTitleProps';

import { ReactComponent as CopyIcon } from '../../../../../img/icons/duplicate.svg';

function SiteModalCustomTitle(props: SiteModalCustomTitleProps): JSX.Element {
  const { site, orgId, title } = props;

  const { addNotification } = useAppContext();

  return (
    <div className="edit-modal">
      <div className="edit-modal__title" data-testid={title}>{title}</div>
      <div className="edit-modal__element">
        <div className="edit-modal__subtitle">Created</div>
        {Utils.getConvertedDate(new Date(site.created), 'MM/dd/yyyy, h:mm a ZZZZ')}
      </div>
      <div className="edit-modal__element">
        <div className="edit-modal__subtitle">Updated</div>
        {Utils.getConvertedDate(new Date(site.updated), 'MM/dd/yyyy, h:mm a ZZZZ')}
      </div>
      <div className="edit-modal__element flex-row">
        <div className="edit-modal__subtitle">Site ID</div>
        <button
          type="button"
          onClick={() => {
            Utils.copyToClipboard(site.siteid);
            addNotification({ type: 'success', message: 'Your "Copy to clipboard" operation was successful' });
          }}
        >
          <CopyIcon />
        </button>
      </div>
      <div className="edit-modal__element flex-row">
        <div className="edit-modal__subtitle">Org ID</div>
        <button
          type="button"
          onClick={() => {
            Utils.copyToClipboard(orgId);
            addNotification({ type: 'success', message: 'Your "Copy to clipboard" operation was successful' });
          }}
        >
          <CopyIcon />
        </button>
      </div>
    </div>
  );
}

export default SiteModalCustomTitle;
