/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createRef, useEffect, useMemo, useState } from 'react';

import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { WizardNodeSelectorProps } from '../../../../../types/AlertActivityReportWizard';
import SelectBox from '../../../../../Common/Components/SelectBox';
import Table from '../../../../../Common/Components/Table';
import getHeaderProps from '../../../../../utils/getHeaderProps';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import { NodeObject } from '../../../../../types/NodeObject';
import { TableFiltering, TableSelection, TableSorting } from '../../../../../types/table';
import { nodesGroupByFixtureType, nodesGroupByLightingGroup } from '../../../../../utils/ApiDataHelpers';
import Utils from '../../../../../utils/Utils';
import Button from '../../../../../Common/Components/Button';
import { ReactComponent as CaretIcon } from '../../../../../img/icons/down-arrow.svg';
import Checkbox from '../../../../../Common/Components/Checkbox';
import WizardNodeGroupedList from './WizardNodeGroupedList';
import Loading from '../../../../../Common/Components/Loading';
import { ReactComponent as FilterIcon } from '../../../../../img/icons/filter.svg';
import { ReactComponent as SortIcon } from '../../../../../img/icons/sort.svg';
import { ReactComponent as CloseSmallIcon } from '../../../../../img/icons/close-small.svg';
import TableColumnFilterField from '../../../../../Common/Components/TableColumnFilterField';

const listRowHeight = 43;
const realListRowHeight = 40;
const rowHeightExpandedHeader = 30;

function WizardNodeSelector(props: WizardNodeSelectorProps): JSX.Element {
  const { loading, nodes, reportObject, setReportObject } = props;

  const headersNodes = getHeaderProps('AlertActivityReportsNodes');
  const [viewBy, setViewBy] = useState<SelectBoxItemType>({ key: 'nodeid', title: 'Node ID' });
  const [expanded, setExpanded] = useState<Set<string>>(new Set());
  const groupedListRef = createRef<List>();
  const updateList = () => {
    groupedListRef.current?.forceUpdateGrid();
    groupedListRef.current?.recomputeRowHeights();
  };

  const origNodes = useMemo<NodeObject[]>(() => Array.from(nodes.values()), [nodes]);
  const groupByFixtureNodes = useMemo<Map<string, NodeObject[]>>(() => nodesGroupByFixtureType(nodes), [nodes]);
  const groupByLightingGroupNodes = useMemo<Map<string, NodeObject[]>>(() => nodesGroupByLightingGroup(nodes), [nodes]);
  const [sortedAndFilteredNodes, setSortedAndFilteredNodes] = useState<Map<string, NodeObject[]>>(new Map([['', origNodes]]));
  const [filtering, setFiltering] = useState<TableFiltering>({});
  const [sorting, _setSorting] = useState<TableSorting>({ by: 'key', dir: 'asc' });
  const setSorting = (column: string) => {
    _setSorting((oldSorting) => {
      const newSorting: TableSorting = { by: column, dir: 'asc' };

      if (oldSorting?.by === column) {
        newSorting.dir = Utils.getNextSortDir(oldSorting);

        if (newSorting.dir === '') {
          newSorting.by = '';
        }
      }

      return newSorting;
    });
  };

  useEffect(() => {
    switch (viewBy.key) {
      case 'group':
        setSortedAndFilteredNodes(Utils.filterAndSortMap(filtering, sorting, groupByLightingGroupNodes));
        break;
      case 'fixture':
        setSortedAndFilteredNodes(Utils.filterAndSortMap(filtering, sorting, groupByFixtureNodes));
        break;
      default:
        setSortedAndFilteredNodes(Utils.filterAndSortMap(filtering, sorting, new Map([['', origNodes]])));
        break;
    }

    updateList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering, sorting]);

  const [selectedNodes, _setSelectedNodes] = useState<Map<string, NodeObject>>(new Map());

  useEffect(() => {
    switch (viewBy.key) {
      case 'group':
        setSortedAndFilteredNodes(groupByLightingGroupNodes);
        break;
      case 'fixture':
        setSortedAndFilteredNodes(groupByFixtureNodes);
        break;
      default:
        setSortedAndFilteredNodes(new Map([['', origNodes]]));
        break;
    }

    _setSorting({ by: 'key', dir: 'asc' });
    setFiltering({});
    setExpanded(new Set());
    updateList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBy.key]);

  useEffect(() => {
    if (
      nodes.size > 0
      && reportObject.filters.nodeIds
      && reportObject.filters.nodeIds?.length > 0
      && selectedNodes.size === 0
    ) {
      const newMap = new Map();

      nodes.forEach((node) => {
        reportObject.filters.nodeIds?.forEach((nodeId) => {
          if (node.nodeid === nodeId) {
            newMap.set(nodeId, node);
          }
        });
      });

      _setSelectedNodes(newMap);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  const setSelectedNodes = (items: Map<string, NodeObject>) => {
    _setSelectedNodes(items);
    setReportObject((origItem) => {
      const nodesArr = Array.from(items.keys());
      const newItem = { ...origItem };
      newItem.filters.nodeIds = nodesArr;

      return newItem;
    });
  };

  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    if (sortedAndFilteredNodes.size === 0) {
      return <div key={key} style={style}>&nbsp;</div>;
    }

    const arr = Array.from(sortedAndFilteredNodes);
    const itemName = arr[index][0];
    const itemNodes = arr[index][1];

    return (
      <div key={key} style={{ ...style, height: realListRowHeight }}>
        <div className="node-list-item">
          <CaretIcon
            className={`node-list-item--caret ${expanded.has(itemName) ? 'open' : ''}`}
            onClick={() => {
              if (itemNodes.length > 0) {
                setExpanded((oldVal) => {
                  const newVal = new Set(oldVal);

                  if (newVal.has(itemName)) {
                    newVal.delete(itemName);
                  } else {
                    newVal.add(itemName);
                  }

                  return newVal;
                });

                updateList();
              }
            }}
          />
          <Checkbox
            dark={false}
            checked={itemNodes.every((node) => selectedNodes.has(node.nodeid))}
            onClick={(checked) => {
              const newVal = new Map(selectedNodes);

              itemNodes.forEach((node) => {
                if (checked) {
                  newVal.set(node.nodeid, node);
                } else {
                  newVal.delete(node.nodeid);
                }
              });

              setSelectedNodes(newVal);
              updateList();
            }}
          />
          <div className="node-list-item--name">{itemName}</div>
          <div className={`node-list-item--count ${arr.length > 6 ? 'has-scroll' : ''}`}>{itemNodes.length}</div>
        </div>
        {expanded.has(itemName) && (
          <WizardNodeGroupedList
            viewBy={viewBy.key}
            nodes={arr[index] && arr[index][1] ? arr[index][1] : []}
            selectedItems={selectedNodes}
            setSelectedItems={setSelectedNodes as React.Dispatch<React.SetStateAction<TableSelection>>}
            updateList={updateList}
          />
        )}
      </div>
    );
  };

  const getRowHeight = ({ index }: {index: number}) => {
    const arr = Array.from(sortedAndFilteredNodes);

    if (arr[index] && arr[index][0] !== undefined && expanded.has(arr[index][0])) {
      return Math.min(arr[index][1].length, 5) * realListRowHeight + rowHeightExpandedHeader + listRowHeight;
    }

    return listRowHeight;
  };

  return (
    <div className="wizard-node-selector">
      <SelectBox
        label="View by"
        title={viewBy.title}
        type="light"
        listWidth={220}
        selectedItemKey={viewBy.key}
        list={[
          { key: 'nodeid', title: 'Node ID' },
          { key: 'group', title: 'Group' },
          { key: 'fixture', title: 'Fixture type' },
        ]}
        onClick={(item) => setViewBy(item)}
      />
      <div className="table table--light">
        {loading && <Loading />}
        {viewBy.key === 'nodeid' && (
          <Table
            headers={headersNodes}
            data={origNodes}
            selectedItems={selectedNodes}
            setSelectedItems={setSelectedNodes as React.Dispatch<React.SetStateAction<TableSelection>>}
            dark={false}
            skipCellMeasure
          />

        )}
        {(viewBy.key === 'group' || viewBy.key === 'fixture') && (
          <>
            <div className="table-list table__header">
              <div className="table__header-cell expand-btn">
                <Button
                  label={(sortedAndFilteredNodes.size > 0 && expanded.size === Array.from(sortedAndFilteredNodes.keys()).length) ? 'Collapse all' : 'Expand all'}
                  onClick={() => {
                    const allKeys = Array.from(sortedAndFilteredNodes.keys());

                    if (expanded.size === allKeys.length) {
                      setExpanded(new Set());
                    } else {
                      setExpanded(new Set(allKeys));
                    }

                    updateList();
                  }}
                />
              </div>
              <div className="table__header-cell header-name">
                {filtering.key !== undefined ? (
                  <TableColumnFilterField
                    onChange={(event) => {
                      setFiltering((oldVal) => ({ ...oldVal, key: event?.target?.value || '' }));
                    }}
                    value={filtering.key || ''}
                    icon={<FilterIcon style={{ left: '10px' }} />}
                    placeholder={`Enter ${viewBy.key === 'group' ? 'Group' : 'Fixture type'}`}
                    autofocus
                    dark={false}
                    close={(
                      <span
                        className="close"
                        style={{ right: '10px', top: 'calc(50% - 8px)' }}
                        onClick={() => {
                          setFiltering((oldVal) => {
                            const newVal = { ...oldVal };
                            delete newVal.key;
                            return newVal;
                          });
                        }}
                      >
                        <CloseSmallIcon />
                      </span>
                  )}
                  />
                ) : (
                  <>
                    <button
                      type="button"
                      className="filter"
                      onClick={() => setFiltering((oldVal) => ({ ...oldVal, key: '' }))}
                    >
                      <FilterIcon />
                    </button>
                    {viewBy.key === 'group' ? 'Group' : 'Fixture type'}
                    <button
                      type="button"
                      className={`sort ${sorting.by === 'key' ? sorting.dir : ''}`}
                      onClick={() => setSorting('key')}
                    >
                      <SortIcon />
                    </button>
                  </>
                )}
              </div>
              <div className="table__header-cell header-count">
                Nodes
                <button
                  type="button"
                  className={`sort ${sorting.by === 'count' ? sorting.dir : ''}`}
                  onClick={() => setSorting('count')}
                >
                  <SortIcon />
                </button>
              </div>
            </div>
            <div className="table-list table__data">
              <AutoSizer disableWidth>
                {() => (
                  <List
                    ref={groupedListRef}
                    width={1}
                    height={297}
                    rowHeight={getRowHeight}
                    rowCount={sortedAndFilteredNodes.size || 0}
                    rowRenderer={rowRenderer}
                    containerStyle={{
                      width: '100%',
                      maxWidth: '100%',
                    }}
                    style={{
                      width: '100%',
                    }}
                    list={Array.from(sortedAndFilteredNodes.values())}
                  />
                )}
              </AutoSizer>
            </div>
          </>
        )}
      </div>
      <div className="wizard-node-selector-footer">
        {nodes.size}
        {' '}
        nodes,
        {' '}
        {selectedNodes.size}
        {' '}
        selected
      </div>
    </div>
  );
}

export default WizardNodeSelector;
