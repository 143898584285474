import React, { useState } from 'react';
import Loading from '../../../Common/Components/Loading';
import Modal from '../../../Common/Components/Modal';
import { ClearAlarmTypeProps } from '../../../types/ClearAlarmTypeProps';
import { useAppContext } from '../../../utils/AppContext';
import { postRequest } from '../../../utils/fetch';

function ClearAlarmType(props: ClearAlarmTypeProps): JSX.Element {
  const {
    setShowModal,
    selectedClearType,
    selectedCustomer,
    selectedSite,
    alarms,
    updateAlerts,
  } = props;

  const { addNotification } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);

  const clearByTypeLimit = 1000;

  const selectedAlarms = alarms.filter((alarm, idx) => alarm.name === selectedClearType && alarm.status.match(/active/i));
  const selectedAlarmsCount = selectedAlarms.length;
  if (selectedAlarmsCount > clearByTypeLimit) {
    selectedAlarms.length = clearByTypeLimit;
  }

  return (
    <div className="alarm-clear">
      <Modal
        title="Dismiss alarms"
        width="372"
        setModalOpen={() => setShowModal(0)}
        primaryButtonAction={async () => {
          setIsLoading(true);
          if (selectedAlarms.length > 0) {
            const idlist = selectedAlarms.map((alarm, idx) => alarm.id);
            try {
              const path = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/dismiss`;
              const resp = await postRequest(path, { alertIds: idlist });
              if (!resp.error) {
                if (selectedAlarmsCount > clearByTypeLimit) {
                  addNotification({ type: 'success', message: `${idlist.length} of the ${selectedAlarmsCount} active ${selectedClearType} alerts have been dismissed.` });
                } else {
                  addNotification({ type: 'success', message: `${idlist.length} ${selectedClearType} alerts have been dismissed.` });
                }
              } else {
                addNotification({ type: 'error', message: 'Your "Dismiss alarms" operation has failed.' });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Dismiss alarms" operation has failed.' });
            }
          } else {
            addNotification({ type: 'error', message: `There were no active ${selectedClearType} alarms to dismiss.` });
          }
          setIsLoading(false);
          setShowModal(0);
          updateAlerts();
        }}
        secondaryButtonAction={() => setShowModal(0)}
        primaryButtonLabel="Dismiss"
        secondaryButtonLabel="Cancel"
      >
        {isLoading
          ? <Loading isLoading />
          : (
            <div className="content">
              <div className="content-text">
                <>
                  {selectedAlarmsCount > clearByTypeLimit
                    && (
                    <div>
                      <span>
                        {clearByTypeLimit}
                        &nbsp;
                        of
                        &nbsp;
                        {selectedAlarmsCount}
                        &nbsp;
                        active
                        &nbsp;
                        <b>{selectedClearType}</b>
                        &nbsp;
                        alarms can be dismissed at a time.
                        <br />
                      </span>
                    </div>
                    )}
                  <div>
                    <span>
                      Are you sure you want to dismiss these
                      <br />
                      <b>{selectedClearType}</b>
                      <span> alarms?</span>
                    </span>
                  </div>
                </>
              </div>
            </div>
          )}
      </Modal>
    </div>
  );
}

export default ClearAlarmType;
