import React from 'react';

import { ReactComponent as CloseIcon } from '../../img/icons/close.svg';
import { ReactComponent as SuccessIcon } from '../../img/icons/success.svg';
import { ReactComponent as ErrorIcon } from '../../img/icons/error.svg';
import { ReactComponent as WarningIcon } from '../../img/icons/warning.svg';
import { ReactComponent as InfoIcon } from '../../img/icons/info.svg';
import { NotificationBarProps } from '../../types/NotificationBarProps';

function NotificationBar(props: NotificationBarProps): JSX.Element {
  const { id, type, message, onClose } = props;

  const renderIcon = (iconVariant: string): JSX.Element => {
    switch (iconVariant) {
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'info':
        return <InfoIcon />;
      default:
        return <SuccessIcon />;
    }
  };

  return (
    <div className={`notification-bar notification-bar--${type}`} id={id}>
      <div className="notification-bar__content">
        <span>{renderIcon(type)}</span>
        <span className="notification-bar__message">{message}</span>
      </div>
      <button
        type="button"
        className="notification-bar__close-btn"
        id={id}
        onClick={() => onClose()}
      >
        <CloseIcon />
      </button>
    </div>
  );
}

export default NotificationBar;
