import React from 'react';
import NotificationBar from './NotificationBar';
import { useAppContext } from '../../utils/AppContext';

function NotificationBarContainer(): JSX.Element {
  const { notifications, deleteNotification } = useAppContext();

  return (
    <div className="notification-bars">
      {notifications?.map((notification) => (
        <NotificationBar
          key={notification.id}
          id={notification.id}
          type={notification.type}
          message={notification.message}
          onClose={() => deleteNotification(notification.id)}
        />
      ))}
    </div>
  );
}

export default NotificationBarContainer;
