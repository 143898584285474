import React from 'react';
import { RebootModalProps } from '../../types/RebootModalProps';
import { useAppContext } from '../../utils/AppContext';
import { postRequest } from '../../utils/fetch';
import Modal from './Modal';

function RebootModal(props: RebootModalProps): JSX.Element {
  const { setModalOpen, customerid, siteid, nodeList } = props;
  const { addNotification } = useAppContext();
  return (
    <Modal
      title="Reboot modem"
      width="360"
      setModalOpen={() => setModalOpen()}
      primaryButtonLabel="Confirm"
      primaryButtonAction={async () => {
        try {
          const path = `/organizations/${customerid}/sites/${siteid}/lights-control/reboot/nodes/${nodeList[0]}`;
          const result = await postRequest(
            path,
          );
          if (!result.error) {
            addNotification({ type: 'info', message: 'Modem reboot command was sent to the node.' });
          } else {
            addNotification({ type: 'error', message: 'Modem reboot command failed to sent to the node.' });
          }
          setModalOpen();
        } catch (e) {
          addNotification({ type: 'error', message: `Modem reboot command failed: ${e}` });
          setModalOpen();
        }
      }}
      secondaryButtonLabel="Cancel"
      className="commissioning"
    >
      <span>Are you sure you want to reboot the modem for this node?</span>
    </Modal>
  );
}

export default RebootModal;
