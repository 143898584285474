import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';
import Utils from '../../../../utils/Utils';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import Table from '../../../../Common/Components/Table';
import { AllSiteObject, SiteWithNodeAggregates, TotalNumberOfNodes } from '../../../../types/SiteObject';
import getHeaderProps from '../../../../utils/getHeaderProps';
import { CustomerObject } from '../../../../types/CustomerObject';
import { getRequest } from '../../../../utils/fetch';
import Button from '../../../../Common/Components/Button';
import AdminSiteModal from './Components/AdminSiteModal';
import { partnersCustomersFn } from '../../../../utils/ApiDataHelpers';
import { SitemanagerPageProps } from '../../../../types/SitemanagerPageProps';
import Tooltip from '../../../../Common/Components/Tooltip';
import TableToolbar from '../Components/TableToolbar';
import TableToolbarSubtitle from '../Components/TableToolbarSubtitle';

import { ReactComponent as SiteConfigIcon } from '../../../../img/icons/site-config.svg';
import { ReactComponent as EditIcon } from '../../../../img/icons/edit.svg';

function SitesPage(props: SitemanagerPageProps): JSX.Element {
  const { setSelectedCustomer, setSelectedSite, location: { state: locationState } = {} } = props;

  const history = useHistory();
  const headers = getHeaderProps('SiteManagerMain');
  const isSensityAdminOrUser = Utils.isSensityUserAdmin();

  const { data: orderedCustomers } = useSWR<Array<CustomerObject> | undefined>(
    ['/organizations', 'GetCustomers'],
    (url) => getRequest(url, {}, (data: Array<CustomerObject>): Array<CustomerObject> => {
      const tmpData = data ? [...data] : [];
      tmpData?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      return tmpData;
    }),
  );

  const { partners, customersMap } = useMemo(() => partnersCustomersFn(orderedCustomers), [orderedCustomers]);

  const [siteObjectForModal, setSiteObjectForModal] = useState<SiteWithNodeAggregates>({} as SiteWithNodeAggregates);
  const [openModal, _setOpenModal] = useState(0);
  const setOpenModal = (modalType: number, site?: SiteWithNodeAggregates) => {
    setSiteObjectForModal(site || {} as SiteWithNodeAggregates);
    _setOpenModal(modalType);
  };

  const modalCreate = 1;
  const modalEdit = 2;
  const modalView = 3;

  const [sitesWithNodeAggregates, setSitesWithNodeAggregates] = useState<SiteWithNodeAggregates[]>([]);

  const { data: sites, mutate: updateSites } = useSWR<AllSiteObject[]>(
    ['/sites', 'SiteManagerPage'],
    (url) => getRequest(url, {}, (data: Array<AllSiteObject>): Array<AllSiteObject> => {
      const tmpData = data?.filter((s) => s.orgid !== '' && !s.defaultSite);
      tmpData?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      return tmpData;
    }),
  );

  const { data: totalNumberOfNodes } = useSWR<TotalNumberOfNodes>(['/sites/node-count', 'SiteSelector'], (url) => getRequest(url));

  useEffect(() => {
    const nodeCountMap = new Map(totalNumberOfNodes?.map((site) => [site.siteid, site.nodes_added_since_n_days]));

    const newSites = sites?.map((site) => ({ ...site,
      number_of_nodes: nodeCountMap.get(site.siteid),
      actions: (
        <div className="sitemanager-actions">
          <Tooltip text="Go to Site config" addStyle={{ marginRight: `${isSensityAdminOrUser ? '15px' : '0'}` }}>
            <SiteConfigIcon
              onClick={() => {
                setSelectedCustomer({ id: site.orgid, name: site.org_name });
                setSelectedSite({ id: site.siteid, name: site.name });
                history.push('/config/fixtures');
              }}
            />
          </Tooltip>
          {isSensityAdminOrUser && (
          <Tooltip text="Edit site">
            <EditIcon
              onClick={() => {
                setOpenModal(modalEdit, { ...site, number_of_nodes: nodeCountMap?.get(site.siteid) } as SiteWithNodeAggregates);
              }}
            />
          </Tooltip>
          )}
        </div>
      ),
    } as SiteWithNodeAggregates));

    setSitesWithNodeAggregates(newSites || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, totalNumberOfNodes]);

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'Sites');
  const [filteredTableData, setFilteredTableData] = useState([]);

  return (
    <div className="content content--admin">
      <AdminSidebar title="Account admin">
        <AdminSidebarAdminItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin page-content--large-bottom-margin sitemanager">
        <TableToolbar
          addClass="sitemanager"
          title="Site manager"
          subtitle={<TableToolbarSubtitle listLength={sitesWithNodeAggregates?.length || 0} download={() => Utils.downloadCSV(headers.filter((header) => header.key !== 'actions'), filteredTableData || [], 'sitemanager')} />}
        >
          <div className="tabletoolbar__children">
            {isSensityAdminOrUser && (
              <Button
                onClick={() => {
                  setOpenModal(modalCreate, {
                    name: '',
                    contact_email: '',
                    contact_name: '',
                    contact_phone: '',
                    latitude: '',
                    longitude: '',
                    country: '',
                    state: '',
                    city: '',
                    street1: '',
                    street2: '',
                    postal_code: '',
                    geo_fence: undefined,
                  } as unknown as SiteWithNodeAggregates);
                }}
                label="Create site"
                buttonType="secondary"
              />
            )}
          </div>
        </TableToolbar>
        <div className="table table--light site-manager auto-height">
          <Table
            headers={headers.filter((header) => !header.isHidden)}
            data={sitesWithNodeAggregates}
            setFilteredTableData={setFilteredTableData}
            initialFiltering={locationState?.accountName ? { org_name: locationState?.accountName } : undefined}
            skipCellMeasure
            colWidthCalcFn={(col: number, width: number): number => {
              const { city, state, actions } = { city: 200, state: 150, actions: 100 };
              const margin = 5;
              const remainColCount = 4;

              const colWidth = (width - city - state - actions - margin) / remainColCount;
              const sesquiCol = colWidth * 1.5;
              return [sesquiCol, city, state, sesquiCol, colWidth, actions][col];
            }}
            cellOnClickColumns={['name']}
            cellOnClick={(site: SiteWithNodeAggregates) => {
              setOpenModal(modalView, site);
            }}
            autoheight
          />
        </div>
      </div>
      {openModal > 0 && (
      <AdminSiteModal
        type={['create', 'edit', 'view'][openModal - 1]}
        site={siteObjectForModal}
        partners={partners}
        customersMap={customersMap}
        setModalOpen={setOpenModal}
        updateSites={updateSites}
      />
      )}
    </div>
  );
}

export default SitesPage;
