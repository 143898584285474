/* eslint-disable react/jsx-tag-spacing */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../Common/Components/Button';
import DimmingSequence from './DimmingSequence';
import { DimmingSequencesProps } from '../../../types/DimmingSequencesProps';
import { ScheduleEvent, ScheduleObject } from '../../../types/ScheduleObject';

import { ReactComponent as PlusIcon } from '../../../img/icons/plus.svg';
import { ReactComponent as NextArrowIcon } from '../../../img/icons/next-arrow.svg';
import { ReactComponent as PrevArrowIcon } from '../../../img/icons/prev-arrow.svg';

function DimmingSequences(props: DimmingSequencesProps): JSX.Element {
  const { dimmingSequencesTitle, setScheduleData, scheduleData, selectedSequenceIndex, setSelectedSequenceIndex, isReadOnly } = props;

  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollable, setScrollable] = useState(false);
  const scrollableDiv = useRef<HTMLDivElement>(null);

  const newSequenceObject: ScheduleEvent = {
    actions: [],
    // these 3 below never changes
    photocell_enabled: false,
    photocell_highLevel: 100,
    photocell_lowLevel: 0,
  };

  const deleteSequenceDisabled = useMemo(() => {
    const event = scheduleData.events[0];
    return (scheduleData.events.length === 1 && ((!event.date && !event.days) || event.actions.length === 0));
  }, [scheduleData.events]);

  const addSequenceDisabled = useMemo(() => {
    const lastEvent = scheduleData.events[scheduleData.events.length - 1];
    let invalid = true;

    if ((lastEvent.date || lastEvent.days) && lastEvent.actions.length > 0) {
      invalid = false;
    }

    return invalid;
  }, [scheduleData.events]);

  useEffect(() => {
    const sequenceWidth = 220;
    if (scrollableDiv.current?.offsetWidth && sequenceWidth * scheduleData.events.length > scrollableDiv.current?.offsetWidth) {
      setScrollable(true);
    } else {
      setScrollable(false);
    }
  }, [scheduleData.events, scrollableDiv.current?.offsetWidth]);

  const prevGradBg = scrollLeft > 0;
  const prevDisabled = scrollLeft === 0;
  const nextGradBg = scrollableDiv.current && scrollableDiv.current.scrollWidth - scrollLeft > scrollableDiv.current.offsetWidth;
  const nextDisabled = scrollableDiv.current && scrollableDiv.current.scrollWidth - scrollLeft === scrollableDiv.current.offsetWidth;

  return (
    <div className="dimming-sequences">
      <span className="dimming-sequences__title">{dimmingSequencesTitle}</span>
      {scrollable && (
        <div className={`prev-arrow-container ${prevGradBg ? 'gradient-bg' : ''}`}>
          <PrevArrowIcon
            className={`prev-arrow ${prevDisabled ? 'disabled' : ''}`}
            onClick={() => scrollableDiv.current?.scrollBy({ left: -220, behavior: 'smooth' })}
          />
        </div>
      )}
      <div
        ref={scrollableDiv}
        onScroll={(event: React.UIEvent) => {
          const target = event.target as HTMLDivElement;
          setScrollLeft(target.scrollLeft);
        }}
        className={`dimming-sequences__content hide-scrollbar ${scrollable ? 'scrollable' : ''} ${isReadOnly ? 'readonly' : ''}`}
      >
        {scheduleData.events.map((event, index: number) => (
          <DimmingSequence
            key={index}
            index={index}
            selectedIndex={selectedSequenceIndex}
            scheduleEvent={event}
            setSelectedSequence={() => setSelectedSequenceIndex(index)}
            isReadOnly={isReadOnly}
            deleteSequenceDisabled={deleteSequenceDisabled}
            deleteSequence={() => {
              let newSequenceList = [...scheduleData.events];

              if (newSequenceList.length === 1) {
                newSequenceList = [newSequenceObject];
              } else {
                newSequenceList.splice(index, 1);
              }

              setSelectedSequenceIndex(Math.max(index - 1, 0));
              setScheduleData((oldSchedule) => ({
                ...oldSchedule,
                events: newSequenceList,
              }));
            }}
          />
        ))}
      </div>
      {scrollable && (
        <div className={`next-arrow-container ${nextGradBg ? 'gradient-bg' : ''}`}>
          <NextArrowIcon
            className={`next-arrow ${nextDisabled ? 'disabled' : ''}`}
            onClick={() => scrollableDiv.current?.scrollBy({ left: 220, behavior: 'smooth' })}
          />
        </div>
      )}
      {!isReadOnly && (
        <Button
          extraClasses={`${addSequenceDisabled ? 'disabled' : ''}`}
          buttonType="icon"
          buttonSize="circle"
          onClick={() => {
            scheduleData.events.map((event, index: number) => (
              setSelectedSequenceIndex(Math.max(index + 1, 0))
            ));
            setScheduleData((oldSchedule: ScheduleObject) => ({
              ...oldSchedule,
              events: [...oldSchedule.events, { ...newSequenceObject }],
            }));
          }}
          disabled={addSequenceDisabled}
        >
          <PlusIcon />
        </Button>
      )}
    </div>
  );
}

export default DimmingSequences;
