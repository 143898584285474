import React from 'react';
import Modal from '../../../../Common/Components/Modal';
import { LightsAlarmsModalProps } from '../../../../types/LightsAlarmsModalProps';
import { useAppContext } from '../../../../utils/AppContext';
import { postRequest } from '../../../../utils/fetch';

function LightsAlarmsModal(props: LightsAlarmsModalProps): JSX.Element {
  const {
    setOpenConfirmDismissAlert,
    selectedCustomer,
    selectedSite,
    alertObj,
    alertDismissArray,
    refreshAlertsList,
    setLoader,
  } = props;

  const { addNotification } = useAppContext();

  return (
    <Modal
      setModalOpen={() => setOpenConfirmDismissAlert(false)}
      className="dismiss-alert-modal"
      title="Dismiss alert"
      primaryButtonAction={async () => {
        setOpenConfirmDismissAlert(false);
        setLoader();
        if ((alertDismissArray !== undefined) && (alertObj !== undefined)) {
          try {
            const path = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/dismiss`;
            const result = await postRequest(path, { alertIds: alertDismissArray });

            if (!result.error) {
              if (alertDismissArray.length > 1) {
                addNotification({ type: 'success', message: `${alertDismissArray.length} alerts successfully dismissed.` });
              } else {
                addNotification({ type: 'success', message: `Alert "${alertObj.type}" successfully dismissed.` });
              }

              refreshAlertsList();
            } else {
              addNotification({ type: 'error', message: `Cannot dismiss the selected alert(s). API reported error: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Alert dismiss" operation is failed.' });
          }
        }
      }}
      primaryButtonLabel="Dismiss alert"
      secondaryButtonAction={() => setOpenConfirmDismissAlert(false)}
      secondaryButtonLabel="Close"
    >
      <div>
        Are you sure you want to dismiss the selected alert(s)?
      </div>
    </Modal>
  );
}

export default LightsAlarmsModal;
