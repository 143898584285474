import React, { useCallback, useState } from 'react';
import useSWR from 'swr';
import { CreateEditFotaCampaignModalProps, FirmwareVersion, FotaCampaign } from '../../../../../types/FotaCampaigns';
import Modal from '../../../../../Common/Components/Modal';
import Textbox from '../../../../../Common/Components/Textbox';
import RadioButtons from '../../../../../Common/Components/RadioButtons';
import { RadioButtonElementProps } from '../../../../../types/RadioButtonsProps';
import Loading from '../../../../../Common/Components/Loading';
import { postRequest, putRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';
import { ReactComponent as SuccessIcon } from '../../../../../img/icons/success.svg';
import SelectBox from '../../../../../Common/Components/SelectBox';

function CreateEditFotaCampaignModal(props: CreateEditFotaCampaignModalProps): JSX.Element {
  const { campaign, setOpenModal, updateFotaCampaignsResp } = props;

  const { addNotification } = useAppContext();

  const onDemandBtns: RadioButtonElementProps[] = [{ key: 'true', label: 'True' }, { key: 'false', label: 'False' }];
  const [loading, setLoading] = useState(false);
  const [doneWithSuccess, setDoneWithSuccess] = useState<boolean>(false);
  const [invalidName, setInvalidName] = useState<boolean>(false);
  const [invalidVersion, setInvalidVersion] = useState<boolean>(false);
  const [editedCampaign, setEditedCampaign] = useState<FotaCampaign>(campaign || {
    name: '',
    targetVersion: '',
    onDemand: false,
    campaignTimeout: 3,
    downloadHttpTimeout: 45,
    downloadRetryCount: 3,
  });

  const { data: firmwareVersionResp } = useSWR<FirmwareVersion[]>(
    ['/fota/firmwares', 'GetFotaFirmwareVersions'],
  );

  const validateForm = useCallback(() => {
    let isValid = true;

    if (!editedCampaign.name) {
      isValid = false;
      setInvalidName(true);
    } else {
      setInvalidName(false);
    }

    if (!editedCampaign.targetVersion) {
      isValid = false;
      setInvalidVersion(true);
    } else {
      setInvalidVersion(false);
    }

    return isValid;
  }, [editedCampaign.name, editedCampaign.targetVersion]);

  async function sendFotaCampaignRequest() {
    if (validateForm()) {
      try {
        setLoading(true);
        let result;

        if (campaign?.campaignId) {
          result = await putRequest<Partial<FotaCampaign>, undefined>(
            `/fota/campaign/${campaign?.campaignId}`,
            {
              name: editedCampaign.name,
              campaignTimeout: editedCampaign.campaignTimeout,
            },
          );
        } else {
          result = await postRequest<FotaCampaign, undefined>(
            '/fota/campaign',
            editedCampaign,
          );
        }

        if (result.status >= 200 && result.status < 300) {
          setDoneWithSuccess(true);
          updateFotaCampaignsResp();
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        addNotification({ type: 'warning', message: `Could not ${editedCampaign.campaignId ? 'edit' : 'create'} campaign: '${error}'` });
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      {!doneWithSuccess ? (
        <Modal
          title={`${campaign ? 'Edit' : 'Create'} a campaign`}
          width="360"
          height={invalidName || invalidVersion ? '645' : '620'}
          setModalOpen={() => setOpenModal(0)}
          primaryButtonAction={() => sendFotaCampaignRequest()}
          primaryButtonLabel="Submit"
          secondaryButtonLabel="Cancel"
          className="create-edit-campaign"
        >
          <Loading isLoading={loading} />
          <Textbox
            type="text"
            label="Campaign name"
            name="campaign name"
            placeholder="Enter name"
            value={editedCampaign?.name}
            error={invalidName}
            errorMessage="Required"
            onChange={(event) => {
              setEditedCampaign((oldVal) => {
                const newVal = { ...oldVal };
                newVal.name = event.target.value;
                return newVal;
              });
            }}
            isRequired
          />
          <SelectBox
            label="Target version"
            title={firmwareVersionResp?.find((fw) => fw.displayName === editedCampaign.targetVersion)?.displayName || 'Select'}
            listWidth={240}
            type="light"
            disabled={!!campaign}
            isRequired
            error={invalidVersion}
            errorMessage="Required"
            selectedItemKey={editedCampaign?.targetVersion}
            list={firmwareVersionResp?.map((fw) => ({ key: fw.displayName, title: fw.displayName }))
              .sort((a, b) => (a.key > b.key ? -1 : 1))}
            onClick={(item) => {
              setEditedCampaign((oldVal) => {
                const newVal = { ...oldVal };
                newVal.targetVersion = item.key;
                return newVal;
              });
            }}
          />
          <Textbox
            type="number"
            label="Download retry count"
            name="download retry count"
            placeholder="Enter"
            min="0"
            max="10"
            disabled={!!campaign}
            value={editedCampaign?.downloadRetryCount.toString()}
            onChange={(event) => setEditedCampaign((oldVal) => {
              const newVal = { ...oldVal };
              newVal.downloadRetryCount = parseInt(event.target.value, 10);
              return newVal;
            })}
          />
          <Textbox
            type="number"
            label="Device session timeout (seconds)"
            name="device session timeout"
            placeholder="Enter"
            min="1"
            max="45"
            disabled={!!campaign}
            value={editedCampaign?.downloadHttpTimeout.toString()}
            onChange={(event) => setEditedCampaign((oldVal) => {
              const newVal = { ...oldVal };
              newVal.downloadHttpTimeout = parseInt(event.target.value, 10);
              return newVal;
            })}
          />
          <span className="radio-button-label">On-demand</span>
          <RadioButtons
            type="light"
            list={onDemandBtns}
            disabled={!!campaign}
            selected={editedCampaign.onDemand === true ? onDemandBtns[0] : onDemandBtns[1]}
            onClick={(item: RadioButtonElementProps) => {
              let onDemand = false;

              if (item.key === 'true') {
                onDemand = true;
              }

              setEditedCampaign((oldVal) => {
                const newVal = { ...oldVal };
                newVal.onDemand = onDemand;
                return newVal;
              });
            }}
          />
          <Textbox
            type="number"
            label="Campaign timeout (days)"
            name="campaign timeout"
            placeholder="Enter"
            min="1"
            max="14"
            value={editedCampaign?.campaignTimeout.toString()}
            onChange={(event) => setEditedCampaign((oldVal) => {
              const newVal = { ...oldVal };
              newVal.campaignTimeout = parseInt(event.target.value, 10);
              return newVal;
            })}
          />
        </Modal>
      ) : (
        <Modal
          setModalOpen={() => setOpenModal(0)}
          title={`Campaign ${editedCampaign.campaignId ? 'edited' : 'created'}`}
          primaryButtonAction={() => setOpenModal(0)}
          primaryButtonLabel="Close"
          width="360"
          height="485"
          className="success-modal"
        >
          <div className="create-edit-campaign-success">
            <div>
              <SuccessIcon width={18} height={18} />
            </div>
            <div>
              <div>
                {`${editedCampaign.campaignId ? editedCampaign.name : 'A new'}`}
                {' '}
                App FOTA campaign has been
                {' '}
                {`${editedCampaign.campaignId ? 'edited' : 'created'}`}
                :
              </div>
              <div className="create-edit-campaign-success--item">
                <span className="title">Campaign name</span>
                <span>{editedCampaign.name}</span>
              </div>
              <div className="create-edit-campaign-success--item">
                <span className="title">Target version</span>
                <span>{firmwareVersionResp?.find((fw) => fw.displayName === editedCampaign.targetVersion)?.displayName || 'N/A'}</span>
              </div>
              <div className="create-edit-campaign-success--item">
                <span className="title">Download retry count</span>
                <span>{editedCampaign.downloadRetryCount}</span>
              </div>
              <div className="create-edit-campaign-success--item">
                <span className="title">Device session timeout</span>
                <span>{`${editedCampaign.downloadHttpTimeout} sec`}</span>
              </div>
              <div className="create-edit-campaign-success--item">
                <span className="title">On-demand</span>
                <span>{editedCampaign.onDemand === true ? 'True' : 'False'}</span>
              </div>
              <div className="create-edit-campaign-success--item">
                <span className="title">Campaign timeout</span>
                <span>{`${editedCampaign.campaignTimeout} days`}</span>
              </div>
              <div className="create-edit-campaign-success--item">
                <span className="description">Please add nodes to this campaign to begin the operation.</span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default CreateEditFotaCampaignModal;
