/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState } from 'react';
import { JobsPageProps } from '../../types/JobsPageProps';
import { TableHeadersProp } from '../../types/TableHeadersProp';
import getHeaderProps from '../getHeaderProps';

export default function useJobsPageState(): JobsPageProps {
  const [activeToolbar, setActiveToolbar] = useState(0);
  const [activeToolbarBtn, setActiveToolbarBtnToolbar] = useState<number>(0);
  const [headers, _setHeaders] = useState(getHeaderProps('JobDetailsUpdateGroup'));

  const handleActiveToolbar = (toolbarNum: number) =>
    setActiveToolbar(() => (toolbarNum === activeToolbar ? 0 : toolbarNum));
  const setHeaders = (newHeaders: TableHeadersProp[]) => {
    localStorage.setItem('JobDetailsTableHeaders', JSON.stringify(newHeaders));
    _setHeaders(newHeaders);
  };

  return {
    headers,
    setHeaders,
    activeToolbar,
    activeToolbarBtn,
    setActiveToolbarBtnToolbar,
    handleActiveToolbar,
  };
}
