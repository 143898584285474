/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import 'leaflet/dist/leaflet.css';
import 'leaflet-pixi-overlay'; // Must be called before the 'leaflet' import
import L, { LatLngBounds, LeafletEvent, LeafletMouseEvent, TileLayer } from 'leaflet';
import InitLeafletButton from '../../utils/Leaflet.Button';

import { MapLayerType, MapPopupData, MapProps, MultiSelectToolOptions } from '../../types/MapProps';
import { useAppContext } from '../../utils/AppContext';
import Loading from './Loading';
import loadScript from '../../utils/loadScript';
import MapUtils from '../../utils/MapUtils';
import { subscribe, unsubscribe } from '../../utils/events.js';
import { nodesPageSize, here } from '../../utils/constants';

PIXI.utils.skipHello();

function MapComponent(props: MapProps): JSX.Element {
  const {
    id = 'map',
    zoom,
    center,
    defaultLayer = 'Dark',
    data,
    geoFence,
    setGeoFence,
    setMapError,
    allSitesGeoJson,
    setSearchResult,
    scheduleTimelines,
    timelineAnimating,
    timelineTime,
    setMapLoaded,
    selectedItems,
    setSelectedItems,
    featuresEnabled = {
      layersSelectionControl: true,
      selectionToolControl: false,
      scheduleTimeline: false,
      search: false,
      selectionToolOnlyPopupBtn: false,
    },
    gpsPinPosition,
    setGpsPinPosition,
    mapSize,
    pinnedNode,
    zoomedNode,
  } = props;

  const defaultZoom = zoom || 12;

  const [layerType, _setLayerType] = useState<MapLayerType>(defaultLayer);
  const setLayerType = (newLayerType: MapLayerType) => {
    localStorage.setItem(`${id}Type`, newLayerType);
    _setLayerType(newLayerType);
  };

  const { addNotification } = useAppContext();
  const mapRef = useRef<HTMLDivElement>(null);
  const mapSearchRef = useRef<HTMLDivElement>(null);
  const loader = useRef<PIXI.Loader>(new PIXI.Loader());
  const map = useRef<L.Map>();
  const pixiOverlay = useRef<any>();
  const bounds = useRef<LatLngBounds>();
  const isCentered = useRef<boolean>(false);
  const markerSprites = useRef<Array<PIXI.Sprite>>([]);
  const markerPin = useRef<PIXI.Sprite>();
  const notifiedInvalid = useRef<boolean>(false);
  const nodeIdsWithInvalidCoord = useRef<Array<string>>([]);
  const nodeIdToMarkerIndex = useRef<Record<string, number>>({});
  const lastHitTarget = useRef<PIXI.Sprite>();
  const multiSelectToolOptions = useRef<MultiSelectToolOptions>({
    enabled: false,
    mouseAction: { key: 'click', label: 'Click' },
    selectionType: { key: 'toggle', label: 'Toggle' },
  });
  const popupDisabled = useRef<boolean>(false);
  const popupDisabledManually = useRef<boolean>(false);
  const geoFencePolygon = useRef<L.Polygon>();
  const geoFencePolygonLayers = useRef<L.Layer[]>([]);

  const prevZoomLevel = useRef<number>(12);

  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const [openedPopup, setOpenedPopup] = useState<L.Popup>();
  const [openedPopupData, setOpenedPopupData] = useState<MapPopupData>();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const drawPartial = (nodes) => {
      const { utils } = pixiOverlay.current;
      const container = utils.getContainer();
      // to avoid timing issues that caused gray markers to appear on completed maps,
      //   draw gray markers only if there are no non-gray markera already present
      if (!(container.children.length > 0 && container.children[0].textureKey !== 'markerNeutral')) {
        MapUtils.drawMarkers(
          utils,
          loader,
          container,
          nodes,
          props.center,
          markerSprites,
          nodeIdToMarkerIndex,
          nodeIdsWithInvalidCoord,
          bounds,
          false,
          new Map(),
        );

        // force the map to re-center
        if (bounds.current) {
          isCentered.current = false;
          MapUtils.centerMapRef(map, bounds, center, isCentered);
        }
        utils.getRenderer().render(container);
      }
    };
    const handleDrawMarkers = (nodes) => {
      drawPartial(nodes.detail.slice(0, Math.floor(nodesPageSize / 2)));
      setTimeout(() => drawPartial(nodes.detail.slice(Math.floor(nodesPageSize / 2))), 100);
    };
    subscribe(
      'drawMarkers',
      handleDrawMarkers,
    );
    return function cleanup() {
      unsubscribe(
        'drawMarkers',
        handleDrawMarkers,
      );
    };
  }, [center, props.center]);

  // load marker sprites
  useEffect(() => {
    const markers = MapUtils.getMarkerArray(featuresEnabled.scheduleTimeline);
    MapUtils.loadMarkerSprites(loader, markers, resourcesLoaded, setResourcesLoaded);

    return () => {
      loader.current = new PIXI.Loader();
      markers.forEach((marker) => {
        PIXI.Texture.removeFromCache(marker.id);
        PIXI.Texture.removeFromCache(marker.icon);
        PIXI.BaseTexture.removeFromCache(marker.id);
        PIXI.BaseTexture.removeFromCache(marker.icon);
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load map
  useEffect(() => {
    if (mapRef.current !== null && map.current === undefined) {
      const mapLayers: {[index: string]: TileLayer} = {
        Map: L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&lang=en&style=explore.day&apiKey=${here.apiKey}&ppi=400&features=pois:disabled`, { minZoom: 4, maxZoom: 19 }),
        Hybrid: L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&lang=en&style=explore.satellite.day&apiKey=${here.apiKey}&ppi=400&features=pois:disabled`, { minZoom: 4, maxZoom: 19 }),
        Satellite: L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&lang=en&style=satellite.day&apiKey=${here.apiKey}&ppi=400`, { minZoom: 4, maxZoom: 19 }),
        Dark: L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&lang=en&style=explore.night&apiKey=${here.apiKey}&ppi=400&features=pois:disabled`, { minZoom: 4, maxZoom: 19 }),
        Light: L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&lang=en&style=lite.day&apiKey=${here.apiKey}&ppi=400&features=pois:disabled`, { minZoom: 4, maxZoom: 19 }),
      };
      const mapLayer = mapLayers[localStorage.getItem(`${id}Type`) || defaultLayer];
      const mapInstance = new L.Map(mapRef.current, {
        layers: [mapLayer],
        center,
        zoom: defaultZoom,
        zoomControl: false,
        zoomSnap: 0,
        zoomDelta: 0.33,
        fadeAnimation: false,
        closePopupOnClick: false,
        drawControlTooltips: false,
      });

      if (center != null && defaultZoom != null) {
        mapInstance.setView(center, defaultZoom);
      }

      L.control.zoom({ position: 'bottomright' }).addTo(mapInstance);

      if (featuresEnabled.layersSelectionControl) {
        L.control.layers(mapLayers).setPosition('bottomright').addTo(mapInstance);

        mapInstance.on('baselayerchange', (layer: LeafletEvent & { name: string }) => {
          setLayerType(layer.name as MapLayerType);
        });
      }

      mapInstance.on('contextmenu', () => false);

      InitLeafletButton();

      if (featuresEnabled.scheduleTimeline) {
        MapUtils.initScheduleTimelineControl(mapInstance);
      }

      if (featuresEnabled.alertsColorLabel) {
        MapUtils.initAlertsControl(mapInstance);
      }

      if (featuresEnabled.selectionToolControl || featuresEnabled.selectionToolOnlyPopupBtn) {
        MapUtils.initSelectionToolControl(
          mapInstance,
          markerSprites,
          popupDisabled,
          popupDisabledManually,
          setOpenedPopupData,
          multiSelectToolOptions,
          setSelectedItems,
          featuresEnabled.selectionToolOnlyPopupBtn,
        );
      }

      if (featuresEnabled.search && setSearchResult) {
        MapUtils.initSearch(mapInstance, mapSearchRef, setSearchResult);
      }

      map.current = mapInstance;
    }

    return () => {
      map.current?.remove();
      map.current = undefined;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // redraw polygon on site center change
  useEffect(() => {
    if (map.current && geoFence?.length) {
      if (geoFencePolygon.current) {
        map.current.removeLayer(geoFencePolygon.current);
        geoFencePolygon.current.remove();
        geoFencePolygon.current = undefined;
      }

      if (geoFencePolygonLayers.current) {
        geoFencePolygonLayers.current.forEach((geoFenceMarker) => {
          map.current?.removeLayer(geoFenceMarker);
          geoFenceMarker.remove();
        });
        geoFencePolygonLayers.current = [];
      }

      MapUtils.drawPolygon(map.current, center, geoFencePolygon, geoFencePolygonLayers, geoFence, allSitesGeoJson || [], setGeoFence, setMapError);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  // load pixi
  useEffect(() => {
    const pixiContainer = new PIXI.Container();
    pixiContainer.interactive = true;
    let firstDraw = true;
    let mapSelectedItemsChange: EventListener;

    pixiOverlay.current = L.pixiOverlay((utils, event) => {
      if (firstDraw && !featuresEnabled.scheduleTimeline) {
        mapSelectedItemsChange = (mapEvent: Event) => MapUtils.mapSelectedItemsChange(mapEvent, utils, loader, markerSprites, nodeIdToMarkerIndex);
        window.addEventListener('MapSelectedItemsChange', mapSelectedItemsChange);
      }

      firstDraw = false;

      if (featuresEnabled.scheduleTimeline && event.scheduleTimelines?.size && timelineAnimating && event.type === 'redraw') {
        MapUtils.scheduleAnimationRedraw(event, loader, markerSprites, nodeIdToMarkerIndex, event.scheduleTimelines);
      }

      // redraw markers
      const scale = utils.getScale();
      utils.getContainer().children.forEach((child: PIXI.Sprite) => child.scale.set(1 / scale));
      utils.getRenderer().render(utils.getContainer());
    }, pixiContainer, {
      doubleBuffering: /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream,
      shouldRedrawOnMove: (e: any) => e.flyTo || e.pinch,
    });

    pixiOverlay.current.addTo(map.current);
    MapUtils.initPixiZoomEvents(map, pixiOverlay);
    setOpenedPopup(undefined);

    return () => {
      window.removeEventListener('MapSelectedItemsChange', mapSelectedItemsChange);
      pixiContainer.removeChildren();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // trigger redraw on animation
  useEffect(() => {
    pixiOverlay.current.redraw({ type: 'redraw', timelineTime, scheduleTimelines });
  }, [timelineAnimating, timelineTime, scheduleTimelines]);

  // draw markers first time in new container
  useEffect(() => {
    if (pixiOverlay.current && data && resourcesLoaded) {
      const { utils } = pixiOverlay.current;
      const zoomLevel = utils.getMap().getZoom();
      const container = utils.getContainer();
      const renderer = utils.getRenderer();
      const scale = utils.getScale();
      bounds.current = undefined;

      // remove any markers from partial renders during loading
      container.removeChildren();

      MapUtils.drawMarkers(
        utils,
        loader,
        container,
        data,
        props.center,
        markerSprites,
        nodeIdToMarkerIndex,
        nodeIdsWithInvalidCoord,
        bounds,
        featuresEnabled.scheduleTimeline,
        scheduleTimelines,
      );

      if (!notifiedInvalid.current && nodeIdsWithInvalidCoord.current.length > 0) {
        notifiedInvalid.current = true;
        addNotification({
          type: 'warning',
          message: `${nodeIdsWithInvalidCoord.current.length} node(s) found with missing or invalid latitude/longitude. These nodes are temporarily displayed near the Site location. Please correct these locations as soon as possible.`,
        });
      }

      if (bounds.current) {
        MapUtils.centerMapRef(map, bounds, center, isCentered);
      }

      if (prevZoomLevel.current !== zoomLevel) {
        utils.getContainer().children.forEach((child: PIXI.Sprite) => child.scale.set(1 / scale));
      }
      prevZoomLevel.current = zoomLevel;
      renderer.render(container);

      if (setMapLoaded) {
        setMapLoaded(true);
      }
    }

    return () => pixiOverlay.current && pixiOverlay.current.utils.getContainer().removeChildren();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, resourcesLoaded]);

  const addNodeToSelected = useCallback((target: PIXI.Sprite) => {
    if (selectedItems && setSelectedItems) {
      const newSelectedNodes = new Map(Array.from(selectedItems));
      newSelectedNodes.set(target.nodeData.nodeid, target.nodeData);

      setSelectedItems(newSelectedNodes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const removeNodeFromSelected = useCallback((target: PIXI.Sprite) => {
    if (selectedItems && setSelectedItems) {
      const newSelectedNodes = new Map(Array.from(selectedItems));
      newSelectedNodes.delete(target.nodeData.nodeid);

      setSelectedItems(newSelectedNodes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  // mouse event handlers
  useEffect(() => {
    if (!featuresEnabled.scheduleTimeline && !featuresEnabled.search) {
      const nodeMarkerMouseEventHandler = (event: LeafletMouseEvent) => MapUtils.nodeMarkerMouseEventHandler(
        event,
        pixiOverlay,
        lastHitTarget,
        multiSelectToolOptions,
        featuresEnabled.selectionToolControl,
        popupDisabled,
        popupDisabledManually,
        setOpenedPopupData,
        selectedItems,
        setSelectedItems,
        addNodeToSelected,
        removeNodeFromSelected,
      );

      const gpsPinMouseEventHandler = (event: LeafletMouseEvent) => MapUtils.gpsPinMouseEventHandler(event, setGpsPinPosition);

      map.current?.on('click', nodeMarkerMouseEventHandler);
      map.current?.on('mousemove', nodeMarkerMouseEventHandler);

      if (gpsPinPosition) {
        map.current?.on('click', gpsPinMouseEventHandler);
      }

      return () => {
        map.current?.off('click', gpsPinMouseEventHandler);
        map.current?.off('click', nodeMarkerMouseEventHandler);
        map.current?.off('mousemove', nodeMarkerMouseEventHandler);
      };
    }

    return undefined;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, gpsPinPosition]);

  // handle popup
  useEffect(() => {
    // close only if different popup
    if (openedPopup) {
      map.current?.closePopup(openedPopup);
    }

    // open only if new popup
    if (openedPopupData) {
      setOpenedPopup(MapUtils.openPopup(map.current as L.Map, openedPopupData, { autoClose: false, minWidth: '322px' }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedPopupData]);

  // redraw on resize
  useEffect(() => {
    map.current?.invalidateSize();
  }, [mapSize]);

  // hovering over pin icon in table
  useEffect(() => {
    const { utils } = pixiOverlay.current;
    const container = utils.getContainer();
    const renderer = utils.getRenderer();

    if (markerPin.current) {
      container.removeChild(markerPin.current);
      markerPin.current = undefined;
      renderer.render(container);
    }

    if (pinnedNode) {
      MapUtils.drawPin(utils, loader, container, markerPin, pinnedNode, props.center);

      const pinLatLng = { lat: parseFloat(pinnedNode.latitude || '0'), lng: parseFloat(pinnedNode.longitude || '0') };

      if (!map.current?.getBounds().contains(pinLatLng)) {
        isCentered.current = false;
        bounds.current = L.latLngBounds([pinLatLng]);

        MapUtils.centerMapRef(map, bounds, pinLatLng, isCentered);
      }

      renderer.render(container);
    }
  }, [pinnedNode, props.center]);

  useEffect(() => {
    // MapUtils.displayPopupOnLocationIconClick(zoomedNode, setOpenedPopupData);
    if (zoomedNode) {
      setOpenedPopupData({
        offset: [0, -2],
        position: [
          parseFloat(zoomedNode.latitude),
          parseFloat(zoomedNode.longitude),
        ],
        content: MapUtils.generatePopupContent(zoomedNode),
      });
    }
  }, [zoomedNode]);

  return (
    <div className={`map ${layerType} ${gpsPinPosition ? 'cursor-gps' : ''}`}>
      {featuresEnabled.search && (
      <div ref={mapSearchRef} className={`${geoFence === undefined ? 'full-search' : ''} map-search-container`} />
      )}
      <div id={id} ref={mapRef} className="map-container" />
    </div>
  );
}

export default function LightingMap(props: MapProps): JSX.Element {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const load = async () => {
      try {
        await loadScript('leaflet-draw', 'https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.js');
        if (L.Draw) {
          setLoaded(true);
        }
      } catch (e) {
        //
      }
    };

    load();
  }, []);

  return (
    <>
      {loaded ? <MapComponent {...props} /> : <Loading />}
    </>
  );
}
