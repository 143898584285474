import React, { ChangeEvent, useMemo, useState } from 'react';
import useSWR from 'swr';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import Modal from '../../../Common/Components/Modal';
import SlideIn from '../../../Common/Components/SlideIn';
import Textbox from '../../../Common/Components/Textbox';
import Button from '../../../Common/Components/Button';
import { NodeObject } from '../../../types/NodeObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Utils from '../../../utils/Utils';
import { putRequest, patchRequest, patchRequestAll } from '../../../utils/fetch';
import { useAppContext } from '../../../utils/AppContext';
import formValidation from '../../../utils/form/formValidation';
import { CustomAttributeValue, CustomAttributeInstance } from '../../../types/CustomAttributes';

function LightsIDs(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems, updateNodes } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const [openModal, setOpenModal] = useState(false);

  const { data: customAttributeInstance } = useSWR<CustomAttributeInstance>([
    `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/lkp/nodes/custom-attributes?nodeId=${selectedNode.nodeid}`,
  ]);

  const defaultCustomAttributes: Map<string, CustomAttributeValue> = useMemo(() => {
    const tmpMap: Map<string, CustomAttributeValue> = new Map();
    if (customAttributeInstance && customAttributeInstance[0]) {
      // eslint-disable-next-line no-nested-ternary
      customAttributeInstance[0].attributes.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)).forEach((ca) => {
        if (ca.id) {
          tmpMap.set(ca.id, {
            label: ca.name,
            value: ca.value,
          });
        }
      });
    }
    return tmpMap;
  }, [customAttributeInstance]);

  const [editedCustomAttributes, setEditedCustomAttributes] = useState<Map<string, CustomAttributeValue>>(defaultCustomAttributes);

  const [nodeName, setNodeName] = useState(selectedNode.name);

  const isNonReadOnly = Utils.isNonReadOnly();

  const { addNotification } = useAppContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    setEditedCustomAttributes((oldValues) => {
      const newValues = new Map(oldValues);
      const newVal = newValues.get(id);

      if (newVal) {
        newVal.value = event.target.value;
        newValues.set(id, newVal);
      }

      return newValues;
    });
  };

  const updateNodeName = async () => {
    try {
      const patchBody = {
        name: nodeName,
      };

      const result = await patchRequest(
        `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/${selectedNode.nodeid}`,
        patchBody,
      );

      if (!result.error) {
        addNotification({ type: 'info', message: 'Your "Update Node name" operation is completed.' });
        if (updateNodes) {
          updateNodes();
        }
      } else {
        addNotification({ type: 'error', message: `Your "Update Node name" operation has failed: ${result.error}` });
      }
    } catch (e) {
      addNotification({ type: 'error', message: 'Your "Update Node name" operation has failed.' });
    }
  };

  const updateCustomAttributes = async () => {
    if (editedCustomAttributes.size === 0) {
      return;
    }

    const requests: { path: string, data: { value: string }}[] = [];

    Array.from(editedCustomAttributes.entries()).forEach(([id, value]) => {
      if (defaultCustomAttributes.get(id)?.value !== value.value) {
        requests.push({
          path: `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/customattributes/${id}`,
          data: { value: value.value },
        });
      }
    });

    patchRequestAll(requests).then((results) => {
      if (results.resolved === requests.length) {
        addNotification({ type: 'info', message: 'Your "Update Node IDs" operation is completed.' });
        if (updateNodes) {
          updateNodes();
        }
      } else {
        addNotification({ type: 'error', message: 'Your "Update Node IDs" operation has failed.' });
      }
    });
  };

  const [editTextBox, _setEditTextBox] = useState({
    editErrorMsg: '',
    error: false,
    value: selectedNode.note,
  });

  const setEditTextBox = (val: string) => {
    const validation = formValidation(
      val,
      { max: 45 },
    );

    _setEditTextBox((oldVal) => ({
      value: validation.hasError ? editTextBox.value : val,
      editErrorMsg: validation.errorMsg,
      error: validation.hasError,
    }));
  };

  return (
    <div className="lights-ids">
      <SlideIn>
        <>
          <div className="slide-in__title">
            <span>Light IDs</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="slide-in__content">
            <SlideinListElementBig
              title="IMEI"
              value={selectedNode.nodeid}
              valueType="small"
            />
            <SlideinListElementBig
              title="ICCID"
              value={selectedNode.iccid}
              valueType="small"
            />
            <SlideinListElementBig
              title="MDN"
              value={selectedNode.msisdn}
              valueType="small"
              border="light"
            />
            <SlideinListElementBig
              title="Model"
              value={selectedNode.modelName}
              valueType="small"
              border="light"
              isFirstElement
            />
            <div className="lights-ids__note">
              <Textbox
                label="Note"
                name="Enter note"
                value={editTextBox.value}
                placeholder="Enter note"
                onChange={(event) => setEditTextBox(event.target.value)}
                styleType="dark"
                type="textarea"
              />
            </div>

            {isNonReadOnly && (
            <div className="slide-in__link">
              <button
                onClick={async () => {
                  if (editTextBox.value !== '') {
                    const putbody = {
                      nodeids: [selectedNode.nodeid],
                      note: editTextBox.value,
                    };

                    try {
                      const resp = await putRequest(
                        '/nodes/note',
                        putbody,
                      );

                      if (!resp.error) {
                        addNotification({ type: 'success', message: 'Your "Edit note" operation was successful' });
                      } else {
                        addNotification({ type: 'error', message: 'Your "Edit note" operation has failed.' });
                      }
                    } catch (error) {
                      addNotification({ type: 'error', message: 'Your "Edit note" operation has failed.' });
                    }
                  }
                }}
                type="button"
                className=" btn btn-textlink btn-medium"
              >
                Update
              </button>
            </div>
            )}
          </div>
        </>
      </SlideIn>
      <SlideIn isExpanded>
        <>
          <div className="slide-in__content">
            <div className="slide-in__secondary-title lights-id">
              Custom attributes
            </div>
            <div className="slide-in__input-container">
              <Textbox
                label="Node name"
                name="Node name"
                value={nodeName}
                onChange={(event) => setNodeName(event.target.value)}
                styleType="dark"
              />
              {Array.from(editedCustomAttributes.entries()).map(([id, customAttribute]) => (
                <Textbox
                  key={id}
                  label={customAttribute.label}
                  name={customAttribute.label}
                  value={customAttribute.value}
                  placeholder="Value"
                  onChange={(event) => handleChange(event, id)}
                  styleType="dark"
                />
              ))}
            </div>
            {isNonReadOnly && (
            <div className="slide-in__link">
              <Button onClick={() => setOpenModal(true)} label="Update" />
            </div>
            )}
          </div>
        </>
      </SlideIn>
      {
        openModal && (
          <Modal
            width="330"
            setModalOpen={setOpenModal}
            title="Update customer IDs?"
            subtitle="Change customer ID values for the node?"
            secondaryButtonLabel="Cancel"
            primaryButtonLabel="Submit"
            primaryButtonAction={() => {
              updateNodeName();
              updateCustomAttributes();
              setOpenModal(false);
            }}
          />
        )
        }
    </div>
  );
}

export default LightsIDs;
