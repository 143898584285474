/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { ProjectPageListProps } from '../../../../../types/ProjectPageList';

import Utils from '../../../../../utils/Utils';
import Button from '../../../../../Common/Components/Button';
import { ReactComponent as TrashIcon } from '../../../../../img/icons/trash.svg';
import DeleteKey from './DeleteKey';
import Tooltip from '../../../../../Common/Components/Tooltip';

function ProjectsPageList(props: ProjectPageListProps): JSX.Element {
  const { listItem, updateProject } = props;
  const [selectedSlotKey, setSelectedSlotKey] = useState(Array<number>);
  const [toggleDeleteKey, setToggleDeleteKey] = useState(false);

  return (
    <>
      {(listItem && listItem.slot1Timestamp)
      && (
      <div className="project-list__items">
        <div className="project-list__items-main no_border">
          <div className="project-list__items-name">
            Slot 1 Date created:
          </div>
          <div className="project-list__items-id">
            {Utils.convertISOtoAlarmTime(listItem.slot1Timestamp as unknown as string, 'utc')}
          </div>
        </div>
        <Tooltip text="Delete slot1 key">
          <div className="project-list__items-main">
            <Button
              extraClasses="trashButton"
              onClick={() => {
                setSelectedSlotKey([1]);
                setToggleDeleteKey(true);
              }}
            >
              <TrashIcon />
            </Button>
          </div>
        </Tooltip>
      </div>
      )}
      {(listItem && listItem.slot2Timestamp)
      && (
      <div className="project-list__items">
        <div className="project-list__items-main no_border">
          <div className="project-list__items-name">
            Slot 2 Date created:
          </div>
          <div className="project-list__items-id">
            {Utils.convertISOtoAlarmTime(listItem.slot2Timestamp as unknown as string, 'utc')}
          </div>
        </div>
        <Tooltip text="Delete slot2 key">
          <div className="project-list__items-main">
            <Button
              extraClasses="trashButton"
              onClick={() => {
                setSelectedSlotKey([2]);
                setToggleDeleteKey(true);
              }}
            >
              <TrashIcon />
            </Button>
          </div>

        </Tooltip>
      </div>
      )}
      {toggleDeleteKey && (
        <DeleteKey
          modalOpen={setToggleDeleteKey}
          selectedSlotKey={selectedSlotKey}
          selectedProject={listItem}
          updateProject={updateProject}
        />
      )}
    </>
  );
}

export default ProjectsPageList;
