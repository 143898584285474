import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../img/icons/down-arrow.svg';
import { GroupActionListTitlePropTypes } from '../../../types/GroupActionListTitlePropTypes';

function GroupActionListTitle(props: GroupActionListTitlePropTypes): JSX.Element {
  const { children, icon, title, openDropdown, id, selectedNodes } = props;

  let subtitle = '';
  if (selectedNodes) {
    switch (selectedNodes.size) {
      case 0:
        subtitle = 'No nodes are selected';
        break;
      case 1:
        subtitle = '1 node is selected';
        break;
      default:
        subtitle = `${selectedNodes.size} nodes are selected`;
    }
  }

  return (
    <div className="group-actions__container">
      <div
        data-testid="group-action-container"
        className={`group-actions__container-group ${
          openDropdown === id ? 'open' : ''
        }`}
      >
        <button
          type="button"
          className="group-actions__container-group-title"
        >
          {icon}
          <div>
            {title}
            <ArrowIcon className="arrow" />
          </div>
        </button>
        {selectedNodes && openDropdown === id
          ? (
            <div className="group-actions__container-group-subtitle">
              {subtitle}
            </div>
          )
          : <span />}
        {children}
      </div>
    </div>
  );
}

export default GroupActionListTitle;
