/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { createRef, useEffect, useState } from 'react';
import useSWR from 'swr';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { NavLink, useHistory } from 'react-router-dom';
import { usePerformanceMark, Stage } from '@cabify/prom-react';
import { PageComponentProps } from '../../types/PageComponentProps';
import ToolbarHeading from '../../Common/Components/ToolbarHeading';
import Toolbar from '../../Common/Components/Toolbar';
import ToolbarLink from '../../Common/Components/ToolbarLink';
import Textbox from '../../Common/Components/Textbox';
import DropDown from '../../Common/Components/DropDown';
import Loading from '../../Common/Components/Loading';
import ToolbarButton from '../../Common/Components/ToolbarButton';
import RecentlyViewedSites from './Components/RecentlyViewedSites';
import ToolbarButtonContainer from '../../Common/Components/ToolbarButtonContainer';
import { AllSiteObject, NewSiteObject, TotalNumberOfNodes, TotalWatchList, TotalNumberOfNodesAddedSinceSunday, SiteWithNodeAggregates } from '../../types/SiteObject';
import { RecentlyViewedSitesObject } from '../../types/RecentlyViewedSites';
import { SiteFilterObject } from '../../types/SiteSelector';
import Tooltip from '../../Common/Components/Tooltip';
import { getRequest } from '../../utils/fetch';
import Utils from '../../utils/Utils';
import { useAppContext } from '../../utils/AppContext';

// icons
import { ReactComponent as FilterIcon } from '../../img/icons/filter.svg';
import { ReactComponent as SortIcon } from '../../img/icons/sort-arrows.svg';
import { ReactComponent as SiteConfigIcon } from '../../img/icons/site-config.svg';
import { ReactComponent as LightsIcon } from '../../img/icons/lighting-group.svg';
import { ReactComponent as GroupsIcon } from '../../img/icons/groups-2.svg';
import { ReactComponent as SchedulesIcon } from '../../img/icons/schedule-large.svg';
import { ReactComponent as JobsIcon } from '../../img/icons/shovel.svg';
import { ReactComponent as EnergyIcon } from '../../img/icons/smart-meter.svg';
import { ReactComponent as AlarmsIcon } from '../../img/icons/alarms.svg';
import { ReactComponent as SearchIcon } from '../../img/icons/search.svg';
import { ReactComponent as BinocularsIcon } from '../../img/icons/binoculars.svg';
import { ReactComponent as PlusIcon } from '../../img/icons/plus-small.svg';
import { ReactComponent as NotWorkingIcon } from '../../img/icons/not-working.svg';
import { ReactComponent as RecentlyViewedIcon } from '../../img/icons/impression-video-blk.svg';
import { ReactComponent as CSVFileIcon } from '../../img/icons/csv-file.svg';
import { ReactComponent as CloseIcon } from '../../img/icons/close.svg';

const listRowHeight = 43;

function SiteSelector(props: PageComponentProps): JSX.Element {
  const {
    setSelectedCustomer,
    selectedSite,
    setSelectedSite,
  } = props;

  const history = useHistory();
  const { data: sitesList } = useSWR<AllSiteObject[]>(
    ['/sites', 'SiteSelector'],
    (url) => getRequest(url, {}, (data: AllSiteObject[]): AllSiteObject[] => data?.filter((s) => s.orgid !== '' && !s.defaultSite)),
  );

  const { data: totalNumberOfNodes } = useSWR<TotalNumberOfNodes>(['/sites/node-count', 'SiteSelector'], (url) => getRequest(url));
  const { data: totalNumberOfNodesWatchList } = useSWR<TotalWatchList>(['/organizations/sites/alerts/count', 'SiteSelector'], (url) => getRequest(url));
  // eslint-disable-next-line max-len
  const { data: totalNumberOfNodesAddedSinceSunday } = useSWR<TotalNumberOfNodesAddedSinceSunday>(['/sites/node-count?sinceSunday=true', 'SiteSelector'], (url) => getRequest(url));

  const [sitesResp, setSitesResp] = useState<NewSiteObject>();
  const selectedSiteData = sitesResp && sitesResp.sites.find((item) => item.siteid === selectedSite.id);

  const { addNotification } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredAndSortedTable, setFilteredAndSortedTable] = useState(sitesResp?.sites || []);
  const [filteredAndSortedTableNodes, setFilteredAndSortedTableNodes] = useState(0);

  const [filter, setFilter] = useState({
    name: { value: '', isShown: false },
    city: { value: '', isShown: false },
    state: { value: '', isShown: false },
    org_name: { value: '', isShown: false },
    partner_org_name: { value: '', isShown: false },
  });

  const [sorted, setSorted] = useState({ by: '', dir: '' });
  const [openDropdown, setOpenDropdown] = useState(false);
  const [activeToolbarBtn, setActiveToolbarBtn] = useState(0);
  const [findSiteByNodeId, setFindSiteByNodeId] = useState('');
  const [filteredSiteId, setFilteredSiteId] = useState('');

  const toolbarList = 0;
  const toolbarRecentSites = 1;
  const toolbarDownload = 2;

  const list = createRef<List>();

  const handleFilter = (name: string, value: string) => {
    setFilter((oldValues) => ({
      ...oldValues,
      [name]: { value, isShown: true },
    }));

    handleFilterAndSort(
      {
        ...filter,
        [name as string]: { value, isShown: true } },
      null,
    );
  };

  const handleSort = (by: string) => {
    let dir = 'asc';
    if (sorted.by === by) {
      switch (sorted.dir) {
        case '': dir = 'asc'; break;
        case 'asc': dir = 'desc'; break;
        case 'desc': dir = ''; break;
        default: dir = 'asc';
      }
    }

    setSorted({ by, dir });
    handleFilterAndSort(null, { by, dir });
  };

  const handleFilterAndSort = (newFilter: null | SiteFilterObject, newSort: null | Record<string, string>) => {
    const filtering = newFilter || filter;
    const sorting = newSort || sorted;

    if (sitesResp && sitesResp.sites.length) {
      let tmpData = [...sitesResp.sites];

      if (Object.keys(filtering).length) {
        Object.keys(filtering).forEach((column) => {
          if (filtering[column].value !== '') {
            tmpData = tmpData.filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (item: any) => item[column]?.toLowerCase()
                .includes(filtering[column].value?.toLowerCase()),
            );
          }
        });
      }

      if (sorting.by !== '' && sorting.dir !== '') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tmpData.sort((a: any, b: any) => {
          const convertedA = a[sorting.by] === null ? 0 : a[sorting.by];
          const convertedB = b[sorting.by] === null ? 0 : b[sorting.by];

          if (convertedA > convertedB) {
            return sorting.dir === 'asc' ? 1 : -1;
          }

          if (convertedA < convertedB) {
            return sorting.dir === 'asc' ? -1 : 1;
          }

          return 0;
        });
      }
      setFilteredAndSortedTable(tmpData);
      setIsLoading(false);
    }
  };

  const handleRecentlyViewedSites = (site: SiteWithNodeAggregates) => {
    const storeKey = `recentSites-${Utils.getApiHost()}-${Utils.getUserID()}`;

    let tmpArr: RecentlyViewedSitesObject[] = JSON.parse(localStorage.getItem(storeKey) || '[]');
    tmpArr = tmpArr.filter((item: RecentlyViewedSitesObject) => item.siteid !== site.siteid);
    tmpArr.unshift({
      siteid: site.siteid,
      name: site.name,
      state: site.state,
      city: site.city,
      orgid: site.orgid,
      orgname: site.org_name,
    });

    localStorage.setItem(storeKey, JSON.stringify(tmpArr));
  };

  const navigateToLights = (siteName: string) => {
    if (siteName !== selectedSite.name) {
      const newSite = sitesResp?.sites.find((site) => site.name === siteName);
      if (newSite) {
        sessionStorage.setItem(`nodeCount-${newSite?.siteid}`, newSite?.number_of_nodes?.toString() || '-1');
      }
      history.push('/lights');
    }
  };

  useEffect(() => {
    if (sitesResp?.sites) {
      setFilteredAndSortedTable(sitesResp?.sites);
      if (sitesResp?.sites.length === 0) {
        setIsLoading(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitesResp]);

  useEffect(() => {
    if (filteredSiteId && sitesResp && sitesResp.sites.length) {
      let tmpData = [...sitesResp.sites];

      tmpData = tmpData.filter((item) => item.siteid === filteredSiteId);

      setFilteredAndSortedTable(tmpData);
      setIsLoading(false);
      setFilteredSiteId('');
    }
  }, [filteredSiteId, sitesResp]);

  useEffect(() => {
    if (sitesList) {
      const nodeCountMap = new Map(totalNumberOfNodes?.map((site) => [site.siteid, site.nodes_added_since_n_days]));
      const addedSinceSundayCountMap = new Map(totalNumberOfNodesAddedSinceSunday?.map((site) => [site.siteid, site.nodes_added_since_n_days]));
      const watchListCountMap = new Map(totalNumberOfNodesWatchList?.alerts?.map((alert) => [alert.siteid, alert.count]));

      const newSitesList = sitesList.map((site) => ({ ...site,
        number_of_nodes: nodeCountMap.get(site.siteid) || 0,
        added_since_sunday: addedSinceSundayCountMap.get(site.siteid) || 0,
        watch_list: watchListCountMap.get(site.siteid) || 0,
      }));

      const totalNodes = newSitesList.reduce((accumulator, site) => accumulator + site.number_of_nodes, 0);
      const totalSinceSunday = newSitesList.reduce((accumulator, site) => accumulator + site.added_since_sunday, 0);
      const totalWatchList = newSitesList.reduce((accumulator, site) => accumulator + site.watch_list, 0);

      const newSiteResp = {
        sites: newSitesList,
        total_nodes: totalNodes,
        nodes_added_since_sunday: totalSinceSunday,
        watch_list: totalWatchList,
      };

      setSitesResp(newSiteResp);
    }
  }, [sitesList, totalNumberOfNodes, totalNumberOfNodesAddedSinceSunday, totalNumberOfNodesWatchList]);

  useEffect(() => {
    if (filteredAndSortedTable) {
      let totalFilteredNodes = 0;
      filteredAndSortedTable.forEach((filtered) => {
        if (filtered.number_of_nodes) {
          totalFilteredNodes += filtered.number_of_nodes;
        }
      });
      setFilteredAndSortedTableNodes(totalFilteredNodes);
    }
  }, [filteredAndSortedTable]);

  const siteRowRenderer = ({ key, index, style }: ListRowProps) => {
    if (!filteredAndSortedTable) {
      return <></>;
    }

    const added = filteredAndSortedTable[index].added_since_sunday;

    return (
      <div key={key} style={style}>
        <div
          className={`site-selector__row ${filteredAndSortedTable[index].siteid === selectedSite.id ? 'site-selector__row--selected' : ''}`}
          onClick={
            () => {
              const selectedSiteToggle = filteredAndSortedTable[index].siteid === selectedSite.id
                ? { id: '', name: '', timezone: '', defaultSite: false }
                : { id: filteredAndSortedTable[index].siteid,
                  name: filteredAndSortedTable[index].name,
                  timezone: filteredAndSortedTable[index].time_zone,
                  defaultSite: filteredAndSortedTable[index].defaultSite };
              // checkRedirectOrg(filteredAndSortedTable[index].orgid);
              if (selectedSiteToggle.id !== '') {
                sessionStorage.setItem(
                  `nodeCount-${selectedSiteToggle.id}`,
                  filteredAndSortedTable[index]?.number_of_nodes?.toString() || '-1',
                );
              }
              setSelectedCustomer({ id: filteredAndSortedTable[index].orgid, name: filteredAndSortedTable[index].org_name });
              setSelectedSite(selectedSiteToggle);
              handleRecentlyViewedSites(filteredAndSortedTable[index]);
            }
          }
        >
          <div
            className="site-selector__site-datas"
            onClick={
              () => {
                navigateToLights(filteredAndSortedTable[index].name);
              }
            }
          >
            <div className="site-selector__site-name">{filteredAndSortedTable[index].name}</div>
            <div className="site-selector__site-city">{filteredAndSortedTable[index].city}</div>
            <div className="site-selector__site-state">{filteredAndSortedTable[index].state}</div>
            <div className="site-selector__site-account">{filteredAndSortedTable[index].org_name}</div>
            <div className="site-selector__site-partner">{filteredAndSortedTable[index].partner_org_name}</div>
          </div>
          <div
            className="site-selector__actions"
            onClick={
              () => {
                navigateToLights(filteredAndSortedTable[index].name);
              }
            }
          >
            <div className="site-selector__actions-nodes">
              {(filteredAndSortedTable[index].number_of_nodes)?.toLocaleString() || ''}
            </div>
            <div className="site-selector__actions-added">
              {(typeof added !== 'undefined' && added > 0) ? `+${(added).toLocaleString()}` : (added?.toString() || '')}
            </div>
            <div className="site-selector__actions-attn">
              {(filteredAndSortedTable[index].watch_list)?.toLocaleString() || ''}
            </div>
          </div>
          <div
            className="site-selector__icons"
            onClick={(event) => {
              if (filteredAndSortedTable[index].siteid === selectedSite.id) {
                event.stopPropagation();
              }
            }}
          >
            <Tooltip text="Go to Lights">
              <NavLink className="site-selector__icons-link" to="/lights">
                <LightsIcon />
              </NavLink>
            </Tooltip>
            <Tooltip text="Go to Groups">
              <NavLink className="site-selector__icons-link" to="/groups">
                <GroupsIcon />
              </NavLink>
            </Tooltip>
            <Tooltip text="Go to Schedules">
              <NavLink className="site-selector__icons-link" to="/schedules">
                <SchedulesIcon />
              </NavLink>
            </Tooltip>
            <Tooltip text="Go to Jobs">
              <NavLink className="site-selector__icons-link" to="/jobs">
                <JobsIcon />
              </NavLink>
            </Tooltip>
            <Tooltip text="Go to Energy">
              <NavLink className="site-selector__icons-link" to="/energy">
                <EnergyIcon />
              </NavLink>
            </Tooltip>
            <Tooltip text="Go to Alarms">
              <NavLink className="site-selector__icons-link" to="/alarms">
                <AlarmsIcon />
              </NavLink>
            </Tooltip>
            <Tooltip text="Go to Site configuration">
              <NavLink className="site-selector__icons-link last" to="/config/fixtures">
                <SiteConfigIcon />
              </NavLink>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'Sites');

  return (
    <div className="site-selector">
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <ToolbarHeading title="Site selector" subtitle={selectedSite.name || 'All sites'} />
          <ToolbarLink
            title={(selectedSiteData ? selectedSiteData.number_of_nodes?.toLocaleString('en-Us') : (sitesResp?.total_nodes?.toLocaleString('en-Us'))) || ''}
            subtitle="Total nodes"
            highlightActive={false}
          />
          <ToolbarLink
            title={(selectedSiteData ? selectedSiteData.added_since_sunday?.toLocaleString('en-Us') : (sitesResp?.nodes_added_since_sunday?.toLocaleString('en-Us'))) || ''}
            subtitle="Added this week"
            highlightActive={false}
            icon={<PlusIcon />}
            addClass="added"
          />
          <ToolbarLink
            title={(selectedSiteData ? selectedSiteData.watch_list?.toLocaleString('en-Us') : (sitesResp?.watch_list?.toLocaleString('en-Us'))) || ''}
            subtitle="Watch list"
            highlightActive={false}
            icon={<NotWorkingIcon />}
            addClass="watch-list"
          />
          <div className="toolbar__icon">
            <div
              className="toolbar__icon-container"
              onClick={() => setOpenDropdown(!openDropdown)}
              data-testid="search-by-id"
            >
              <BinocularsIcon />
            </div>
            {openDropdown && (
              <DropDown title="Find a site by node ID" className="site-selector__dropdown">
                <Textbox
                  styleType="dark"
                  placeholder="Enter node ID"
                  value={findSiteByNodeId}
                  onChange={(event) => setFindSiteByNodeId(event.target.value)}
                  icon={(
                    <SearchIcon onClick={async () => {
                      try {
                        const resp = await getRequest(`/nodes/${findSiteByNodeId}`);

                        if (resp.orgid && resp.siteid) {
                          setFilteredSiteId(resp.siteid);
                        } else {
                          addNotification({ type: 'error', message: `No node found with ID: ${findSiteByNodeId}.` });
                        }

                        setOpenDropdown(false);
                      } catch (e) {
                        addNotification({ type: 'error', message: `No node found with ID: ${findSiteByNodeId}.` });
                        setOpenDropdown(false);
                      }
                    }}
                    />
                  )}
                />
              </DropDown>
            )}
          </div>
        </div>
        <ToolbarButtonContainer>
          <ToolbarButton
            order={toolbarRecentSites}
            toggleToolbarBtn={setActiveToolbarBtn}
            activeToolbarBtn={activeToolbarBtn}
            icon={<RecentlyViewedIcon />}
            tooltipText="Recently viewed sites"
          >
            <RecentlyViewedSites
              selectedSite={selectedSite}
              setSelectedSite={setSelectedSite}
              setSelectedCustomer={setSelectedCustomer}

            />
          </ToolbarButton>
          <ToolbarButton
            order={toolbarDownload}
            toggleToolbarBtn={() => undefined}
            activeToolbarBtn={toolbarList}
            shouldOpenModal={() => Utils.downloadCSV([
              { key: 'name', val: 'Name' },
              { key: 'city', val: 'City' },
              { key: 'state', val: 'State' },
              { key: 'org_name', val: 'Account' },
              { key: 'partner_org_name', val: 'Partner' },
              { key: 'number_of_nodes', val: 'Nodes' },
              { key: 'added_since_sunday', val: 'Added' },
              { key: 'watch_list', val: 'Watch list' },
            ], filteredAndSortedTable || [], 'sites')}
            icon={<CSVFileIcon />}
            tooltipText="Download CSV"
            noborder
          />
        </ToolbarButtonContainer>
      </Toolbar>
      <div className="content">
        <div className="site-selector__header">
          <div className="site-selector__header-datas">
            <div className="site-selector__header-cell site-selector__header-cell--name">
              {
                  filter.name.isShown ? (
                    <Textbox
                      icon={(
                        <CloseIcon onClick={() => {
                          handleFilter('name', '');
                          setFilter((oldValues) => ({ ...oldValues, name: { value: '', isShown: false } }));
                        }}
                        />
                      )}
                      onChange={(e) => handleFilter(e.target.name, e.target.value)}
                      placeholder="Enter"
                      name="name"
                      value={filter.name.value}
                      autofocus
                    />
                  ) : (
                    <div className="site-selector__header-cell--text" onClick={() => setFilter((oldValues) => ({ ...oldValues, name: { value: '', isShown: true } }))}>
                      <FilterIcon />
                      Site name
                    </div>
                  )
                }
            </div>
            <div className="site-selector__header-cell">
              {
                  filter.city.isShown ? (
                    <Textbox
                      icon={(
                        <CloseIcon onClick={() => {
                          handleFilter('city', '');
                          setFilter((oldValues) => ({ ...oldValues, city: { value: '', isShown: false } }));
                        }}
                        />
                      )}
                      onChange={(e) => handleFilter(e.target.name, e.target.value)}
                      placeholder="Enter"
                      name="city"
                      value={filter.city.value}
                      autofocus
                    />
                  ) : (
                    <div className="site-selector__header-cell--text" onClick={() => setFilter((oldValues) => ({ ...filter, city: { value: '', isShown: true } }))}>
                      <FilterIcon />
                      City
                    </div>
                  )
                }
            </div>
            <div className="site-selector__header-cell">
              {
                  filter.state.isShown ? (
                    <Textbox
                      icon={(
                        <CloseIcon onClick={() => {
                          handleFilter('state', '');
                          setFilter((oldValues) => ({ ...oldValues, state: { value: '', isShown: false } }));
                        }}
                        />
                      )}
                      onChange={(e) => handleFilter(e.target.name, e.target.value)}
                      placeholder="Enter"
                      name="state"
                      value={filter.state.value}
                      autofocus
                    />
                  ) : (
                    <div className="site-selector__header-cell--text" onClick={() => setFilter((oldValues) => ({ ...oldValues, state: { value: '', isShown: true } }))}>
                      <FilterIcon />
                      State
                    </div>
                  )
                }
            </div>
            <div className="site-selector__header-cell site-selector__header-cell--account">
              {
                  filter.org_name.isShown ? (
                    <Textbox
                      icon={(
                        <CloseIcon onClick={() => {
                          handleFilter('org_name', '');
                          setFilter((oldValues) => ({ ...oldValues, org_name: { value: '', isShown: false } }));
                        }}
                        />
                      )}
                      onChange={(e) => handleFilter(e.target.name, e.target.value)}
                      placeholder="Enter"
                      name="org_name"
                      value={filter.org_name.value}
                      autofocus
                    />
                  ) : (
                    <div className="site-selector__header-cell--text" onClick={() => setFilter((oldValues) => ({ ...oldValues, org_name: { value: '', isShown: true } }))}>
                      <FilterIcon />
                      Account
                    </div>
                  )
                }
            </div>
            <div className="site-selector__header-cell site-selector__header-cell--partner">
              {
                  filter.partner_org_name.isShown ? (
                    <Textbox
                      icon={(
                        <CloseIcon onClick={() => {
                          handleFilter('partner_org_name', '');
                          setFilter((oldValues) => ({ ...oldValues, partner_org_name: { value: '', isShown: false } }));
                        }}
                        />
                      )}
                      onChange={(e) => handleFilter(e.target.name, e.target.value)}
                      placeholder="Enter"
                      name="partner_org_name"
                      value={filter.partner_org_name.value}
                      autofocus
                    />
                  ) : (
                    <div className="site-selector__header-cell--text" onClick={() => setFilter((oldValues) => ({ ...oldValues, partner_org_name: { value: '', isShown: true } }))}>
                      <FilterIcon />
                      Partner
                    </div>
                  )
                }
            </div>
          </div>
          <div className="site-selector__header-actions">
            <div onClick={() => handleSort('number_of_nodes')} className={`site-selector__sort ${sorted.by === 'number_of_nodes' ? sorted.dir : ''}`}>
              <SortIcon />
              Nodes
            </div>
            <div onClick={() => handleSort('added_since_sunday')} className={`site-selector__sort ${sorted.by === 'added_since_sunday' ? sorted.dir : ''}`}>
              <SortIcon />
              Added
            </div>
            <div onClick={() => handleSort('watch_list')} className={`site-selector__sort ${sorted.by === 'watch_list' ? sorted.dir : ''}`}>
              <SortIcon />
              Watch list
            </div>
          </div>
          <div>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <div className="site-selector__list-container">
          {filteredAndSortedTable.length === 0 && sitesResp && (
          <div className="no-sites-for-user">
            <span>No site found for logged in user.</span>
          </div>
          )}
          {filteredAndSortedTable && filteredAndSortedTable.length ? (
            <AutoSizer>
              {({ width, height }) => (
                <>
                  <List
                    ref={list}
                    width={width + 9}
                    height={height}
                    rowHeight={listRowHeight}
                    rowCount={filteredAndSortedTable.length || 0}
                    rowRenderer={siteRowRenderer}
                    list={filteredAndSortedTable}
                  />
                  <div className="site-selector__list-container--filter">
                    <span className="site-selector__list-container--filter-val">
                      {filteredAndSortedTable.length.toLocaleString() || '0'}
                    </span>
                    <span className="site-selector__list-container--filter-text"> filtered rows (</span>
                    <span className="site-selector__list-container--filter-val">
                      {filteredAndSortedTableNodes.toLocaleString() || '0'}
                    </span>
                    <span className="site-selector__list-container--filter-text"> filtered nodes)</span>
                  </div>
                </>
              )}
            </AutoSizer>
          ) : (<Loading isLoading={isLoading} />)}
        </div>
      </div>
    </div>
  );
}

export default SiteSelector;
