import React from 'react';
import ReactDOM from 'react-dom';
import { MetricsProvider, MetricsLogger } from '@cabify/prom-react';
import routes from './routes';
import App from './App';

const getPageName = (path: string) => {
  if (routes) {
    const routeComponent = routes.find((route) => path.indexOf(route.path) >= 0)?.component;
    return routeComponent?.name || 'unknown';
  }
  return 'unknown';
};

ReactDOM.render(
  <MetricsProvider
    appName="il-lighting-ui"
    owner="Intelligent Lighting"
    getNormalizedPath={getPageName}
    // metricsAggregatorUrl="https://tbd"
  >
    {process.env.NODE_ENV !== 'production'
        && <MetricsLogger />}
    <App />
  </MetricsProvider>,
  document.getElementById('root'),
);
