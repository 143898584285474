import React from 'react';
import { GroupActionListElementPropTypes } from '../../../types/GroupActionListElementPropTypes';

function GroupActionListElement(props: GroupActionListElementPropTypes): JSX.Element {
  const { title } = props;
  return (
    <li className="group-actions__container-group-list-element">
      <div
        className="group-actions__container-group-list-btn"
      >
        {title}
      </div>
    </li>
  );
}

export default GroupActionListElement;
