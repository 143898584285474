import React from 'react';
import Modal from '../../../Common/Components/Modal';
import Utils from '../../../utils/Utils';
import Button from '../../../Common/Components/Button';
import { ViewReportDetailsProps } from '../../../types/ViewReportDetailsProps';

import Textbox from '../../../Common/Components/Textbox';

function ViewReportDetails(props: ViewReportDetailsProps): JSX.Element {
  const { setModalOpen, reportDetails } = props;

  const downloadUrl = reportDetails.downloadUrl || '';

  const downloadFile = () => {
    const a = document.createElement('a');
    const urlSegments = downloadUrl.split('?')[0].split('/');
    const fileName = urlSegments[urlSegments.length - 1];

    a.href = downloadUrl;
    a.download = fileName;
    a.click();
  };

  const formattedDetails = Utils.formatReportDetails(reportDetails);

  return (
    <div className="analytics-interactions view-fixture">
      <Modal
        setModalOpen={setModalOpen}
        primaryButtonLabel="Close"
        primaryButtonAction={() => setModalOpen(false)}
        width="600"
      >
        <div className="modal-divided">
          <div className="modal-divided__leftcontent">
            <div className="modal-divided__content-data">
              <div className="viewReport-title">{reportDetails.reportName}</div>
              <Textbox
                label="Customer"
                value={formattedDetails.reportAccount}
                placeholder={formattedDetails.reportAccount}
                disabled
              />
              <Textbox
                label="Site"
                value={formattedDetails.reportSite}
                placeholder={formattedDetails.reportSite}
                disabled
              />
              <Textbox
                label="Status"
                value={formattedDetails.reportStatus}
                placeholder={formattedDetails.reportStatus}
                disabled
              />
              {reportDetails.failureMessage
                && (
                <Textbox
                  label="  Reason"
                  value={formattedDetails.failureMessage || '--'}
                  placeholder={formattedDetails.failureMessage}
                  disabled
                />
                )}
              <Textbox
                label="Report Date"
                value={formattedDetails.reportDate || '--'}
                placeholder={formattedDetails.reportDate}
                disabled
              />
              <Textbox
                label="Start Date"
                value={formattedDetails.startDate || '--'}
                placeholder={formattedDetails.startDate}
                disabled
              />
              <Textbox
                label="End Date"
                value={formattedDetails.endDate || '--'}
                placeholder={formattedDetails.endDate}
                disabled
              />
              {reportDetails.reportStatus === 'Ready'
                && (
                  <div className="center">
                    <Button
                      buttonType="secondary"
                      buttonSize="medium"
                      label="Download report"
                      onClick={downloadFile}
                    />
                  </div>
                )}

            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ViewReportDetails;
