import React, { useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import Loading from '../../../Common/Components/Loading';
import Button from '../../../Common/Components/Button';
import Tooltip from '../../../Common/Components/Tooltip';
import AssignScheduleModal from './AssignScheduleModal';
import { LightingGroupsListProps } from '../../../types/LightingGroupsListProps';

// icons
import { ReactComponent as FilterIcon } from '../../../img/icons/filter.svg';
import { ReactComponent as AssignScheduleIcon } from '../../../img/icons/assign-schedule.svg';
import { ReactComponent as SchedulesHighlightIcon } from '../../../img/icons/schedule-highlight.svg';
import { ReactComponent as SchedulesIcon } from '../../../img/icons/schedule-large.svg';
import { GroupObject } from '../../../types/GroupObject';
import Utils from '../../../utils/Utils';

const maxItems = 11;
const rowHeight = 43;
const listWidth = 430;

function LightingGroupsList(props: LightingGroupsListProps): JSX.Element {
  const {
    data = [],
    setSelectedItems,
    selectedItems,
    groupsSchedule,
    selectedDate,
    schedules,
    updateSchedules,
    setActiveSchedule,
    setOpenScheduleModal,
    selectedSite,
    selectedCustomer,
  } = props;
  const [openModal, setModalOpen] = useState(false);
  const [groupIndex, setGroupIndex] = useState(0);

  const isNonReadOnly = Utils.isNonReadOnly();

  const computedHeight = (data?.length || 0) < maxItems
    ? rowHeight * (data?.length || 0)
    : rowHeight * maxItems;

  const lightingGroupsListRenderer = ({ key, index, style }: ListRowProps) => {
    const currentGroup = data[index];
    const groupSchedules = groupsSchedule.get(currentGroup.groupId);
    const hasSpecialDate = groupSchedules?.events.some((event) => event.date?.toString() === selectedDate);

    return (
      <div key={key} style={style}>
        <div className="schedule-list__element">
          <button
            type="button"
            className={`schedule-list__filter ${selectedItems.has(currentGroup.groupId) ? 'schedule-list__filter--active' : ''}`}
            onClick={() => {
              const newSelectedGroups: Map<string, GroupObject> = new Map(Array.from(selectedItems));
              if (selectedItems.size > 0 && selectedItems.get(data[index].groupId)) {
                newSelectedGroups.delete(data[index].groupId);
              } else {
                newSelectedGroups.set(data[index].groupId, data[index]);
              }

              setSelectedItems(newSelectedGroups);
            }}
          >
            <FilterIcon />
          </button>
          <div className="schedule-list__name-cell schedule-list__name-cell--groups">
            <div className="schedule-list__name">
              {data[index].name}
            </div>
          </div>
          <div className="schedule-list__groups-btn">
            {hasSpecialDate
              ? <span className="schedule-list__special-date"><SchedulesHighlightIcon /></span>
              : <SchedulesIcon />}
            {groupSchedules && (
            <Button
              label={groupSchedules.name}
              buttonSize="small"
              extraClasses="margin-left-10"
              onClick={() => {
                setActiveSchedule(groupSchedules);
                setOpenScheduleModal(true);
              }}
            />
            )}
          </div>
          {isNonReadOnly && currentGroup.type !== 'SITE_LIGHTING' && (
          <div className="schedule-list__groups-add">
            <Tooltip text="Assign schedule to group">
              <button
                type="button"
                className="schedule-list__groups-add-btn"
                onClick={() => {
                  setModalOpen(true);
                  setGroupIndex(index);
                }}
              >
                <AssignScheduleIcon className="schedule-list__groups-add-icon" />
              </button>
            </Tooltip>
          </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {data && data.length ? (
        <List
          width={listWidth}
          height={computedHeight}
          rowHeight={rowHeight}
          rowCount={data && data.length}
          rowRenderer={lightingGroupsListRenderer}
          list={data}
        />
      ) : (<Loading />)}
      {openModal && (
      <AssignScheduleModal
        setModalOpen={setModalOpen}
        group={data[groupIndex]}
        groupsSchedule={groupsSchedule}
        schedules={schedules}
        updateSchedules={updateSchedules}
        selectedSite={selectedSite}
        selectedCustomer={selectedCustomer}
      />
      )}
    </>
  );
}

export default LightingGroupsList;
