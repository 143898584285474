import React from 'react';

import { AdminSidebarPropsType } from '../../types/AdminSidebarProps';

function AdminSidebar(props: AdminSidebarPropsType): JSX.Element {
  const { selectedSite, children, title } = props;

  return (
    <div className="admin__sidebar">
      <div className="admin__sidebar-title-section">
        <div className="admin__sidebar-title">{title}</div>
        {selectedSite?.name && (
        <div className="admin__sidebar-site-name" data-testid="selectedSiteName">
          {selectedSite.name}
        </div>
        )}
      </div>
      {children}
    </div>
  );
}

export default AdminSidebar;
