import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import Textbox from '../../../../../Common/Components/Textbox';
import { AdminEditProjectProps, EditProjectStateProp, EditProjectPostProp } from '../../../../../types/AdminEditProjectProps';
import { patchRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';

function EditProjectModal(props: AdminEditProjectProps): JSX.Element {
  const { selectedProject, modalOpen, updateProject } = props;
  const { addNotification } = useAppContext();
  const [editProject, setEditProject] = useState<EditProjectStateProp>({
    projectName: selectedProject?.projectName || '',
    projectId: selectedProject?.projectId || '',
  });

  const setProjectName = (newName?: string) => {
    const projectName = newName !== undefined ? newName : editProject.projectName;
    setEditProject((oldValues) => ({
      ...oldValues,
      projectName,
    }));
  };

  return (
    <div className="project-interactions edit-project">
      <Modal
        title="Edit project"
        setModalOpen={modalOpen}
        primaryButtonLabel="Save"
        primaryButtonAction={async () => {
          const currentProject = editProject;
          if (currentProject.projectName === '') {
            setProjectName(currentProject.projectName);
            return;
          }
          try {
            const postBody: EditProjectPostProp = {
              projectName: editProject?.projectName,
            };
            const result = await patchRequest(
              `/organizations/${selectedProject.orgId}/projects/${selectedProject.projectId}`,
              postBody,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Edit project" operation is completed.' });
              if (updateProject) {
                updateProject();
              }
            } else {
              addNotification({ type: 'error', message: `Your "Edit project" operation has failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Edit project" operation has failed.' });
          }
          modalOpen(false);
        }}
        secondaryButtonLabel="Cancel"
        width="410"
        secondaryButtonAction={() => modalOpen(false)}
      >
        <div className="admin-editproject-modal-content">
          <Textbox
            label="Project name"
            name="ProjectName"
            value={editProject.projectName}
            placeholder="Enter"
            onChange={(event) => {
              setProjectName(event.target.value);
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default EditProjectModal;
