/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
// import { List, ListRowProps, ListRowRenderer } from 'react-virtualized';
// import Button from '../../../Common/Components/Button';
import DropDown from '../../../Common/Components/DropDown';
// import { ReactComponent as LihtingGroupIcon } from '../../../img/icons/lighting-group.svg';
// import { ReactComponent as OrgGroupIcon } from '../../../img/icons/org-group.svg';
// import { ReactComponent as MapIcon } from '../../../img/icons/site-locator.svg';
import { GroupsSelectorPropsType } from '../../../types/GroupsSelector';
// import { LightsGroupsItem } from '../../../types/LightsGroupProps';
// import { useAppContext } from '../../../utils/AppContext';
// import GroupActionListTitle from '../../Lights/Components/GroupActionListTitle';

function ToolbarDashboardSelector(props: GroupsSelectorPropsType): JSX.Element {
  const {
    className,
    // lightingGroups,
    // orgGroups,
    // selectedChartBase,
    // setSelectedChartBase,
    // handleActiveToolbar,
    // setGroupFilterActiveTab,
    // groupFilterActiveTab,
  } = props;

  /* const { addNotification } = useAppContext();

  const groupLineHeight = 41;
  const listWidth = 260;
  const maxListHeight = 313;

  const basicSiteChartBase: ChartBase = {
    type: 'site',
    icon: <MapIcon />,
  };

  const [hightlightedGroup, setHightlightedGroup] = useState<LightsGroupsItem>(() => {
    switch (selectedChartBase.type) {
      case 'LIGHTING':
      case 'ORGANIZATIONAL': return selectedChartBase.group;
      default: return {} as LightsGroupsItem;
    }
  }); */

  /* const renderLightingGroupList = ({ key, index, style }: ListRowProps) => (
    renderGroupList(lightingGroups, { key, index, style } as ListRowProps)
  );

  const renderOrgGroupList = ({ key, index, style }: ListRowProps) => (
    renderGroupList(orgGroups, { key, index, style } as ListRowProps)
  );

  const renderGroupList = (groups: LightsGroupsItem[], { key, index, style }: ListRowProps) => (
    <div
      key={key}
      className={`group-list-row
      ${hightlightedGroup?.groupId === groups[index].groupId ? 'group-list-row--selected' : ''}`}
      style={style}
      onClick={() => {
        setHightlightedGroup(groups[index]);
      }}
    >
      <span className="group-list-row__group-name">
        {groups[index].name}
      </span>
      <span className="group-list-row__node-length">
        {groups[index].nodeList?.length}
      </span>
    </div>
  );

  const renderGroupSelectorTab = (
    accordionId: 'LIGHTING' | 'ORGANIZATIONAL',
    title: string,
    icon: JSX.Element,
    listElementRenderer: ListRowRenderer,
    groupList: LightsGroupsItem[],
  ) => (
    <GroupActionListTitle
      openDropdown={groupFilterActiveTab}
      id={accordionId}
      icon={icon}
      title={title}
    >
      <div className="group-actions__container-group-list">
        <List
          width={listWidth}
          height={Math.min(maxListHeight, groupList.length * groupLineHeight)}
          rowCount={groupList.length}
          rowHeight={groupLineHeight}
          rowRenderer={listElementRenderer}
          data={groupList}
        />
        <div className="group-actions__container-footer">
          <Button buttonSize="small" buttonType="primary" label="Cancel" onClick={cancelAction} />
          <Button
            buttonSize="small"
            buttonType="secondary"
            label="Submit"
            onClick={() => submitAction(
              { group: hightlightedGroup, type: accordionId, icon } as ChartBase,
            )}
          />
        </div>
      </div>
    </GroupActionListTitle>
  );

  const renderSiteSelectorTab = () => (
    <GroupActionListTitle
      openDropdown={groupFilterActiveTab}
      id="site"
      icon={<MapIcon />}
      title="Site"
    >
      <div className="group-actions__container-group-list">
        <span>
          Calculates total energy use for all nodes in the site for a maximum period of one month within a one-year timeframe.
        </span>
        <div className="group-actions__container-footer">
          <Button buttonSize="small" buttonType="primary" label="Cancel" onClick={cancelAction} />
          <Button
            buttonSize="small"
            buttonType="secondary"
            label="Submit"
            onClick={() => submitAction(basicSiteChartBase)}
          />
        </div>
      </div>
    </GroupActionListTitle>
  );

  const submitAction = (cb: ChartBase) => {
    if ((cb.type === 'site') || ((cb.type === 'ORGANIZATIONAL' || cb.type === 'LIGHTING') && cb.group.type?.includes(cb.type))) {
      setSelectedChartBase(cb);
      handleActiveToolbar(0);
    } else {
      addNotification({ type: 'warning', message: `Please select an element from the ${cb.type.toLowerCase()} groups section.` });
    }
  };

  const cancelAction = () => {
    handleActiveToolbar(0);
  }; */

  return (
    <div className="group-actions">
      <DropDown
        // primaryButtonAction={cancelAction}
        className={className}
      >
        <div>
          {/* } <div onClick={() => setGroupFilterActiveTab('site')}>
            {renderSiteSelectorTab()}
  </div> */}
          {/* } <div onClick={() => setGroupFilterActiveTab('LIGHTING')}>
            {renderGroupSelectorTab(
              'LIGHTING',
              'Lighting groups',
              <LihtingGroupIcon />,
              renderLightingGroupList,
              lightingGroups,
            )}
          </div>
          <div onClick={() => setGroupFilterActiveTab('ORGANIZATIONAL')}>
            {renderGroupSelectorTab(
              'ORGANIZATIONAL',
              'Org groups',
              <OrgGroupIcon />,
              renderOrgGroupList,
              orgGroups,
            )}
          </div> */}
        </div>
      </DropDown>
    </div>
  );
}

export default ToolbarDashboardSelector;
