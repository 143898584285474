import { DateTime } from 'luxon';
import { subDays } from 'date-fns';
import React, { useState } from 'react';
import DateRangePicker from '../../../../Common/Components/DateRangePicker';
import Modal from '../../../../Common/Components/Modal';
import RadioButtons from '../../../../Common/Components/RadioButtons';
import SelectBox from '../../../../Common/Components/SelectBox';
import { RadioButtonElementProps } from '../../../../types/RadioButtonsProps';
import { RelativeTimeOrDateRangeModalProps } from '../../../../types/RelativeTimeOrDateRangeModalProps';
import { SelectBoxItemType } from '../../../../types/SelectBoxPropsType';
import { useAppContext } from '../../../../utils/AppContext';

function RelativeTimeOrDateRangeModal(props: RelativeTimeOrDateRangeModalProps): JSX.Element {
  const { title, setOpenModal, primaryButtonAction } = props;

  const { addNotification } = useAppContext();
  const relativeTimeKey = '1';
  const dateRangeKey = '2';
  const timePeriodListWidth = 220;
  const timeListWidth = 105;

  const [valueType, setValueType] = useState<RadioButtonElementProps>({ label: 'Relative time', key: relativeTimeKey });
  const [timePeriod, setSelectedTimePeriod] = useState({ title: 'Last 24 hours', key: '1' });
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [daysDelta, setDaysDelta] = useState(0);
  const [startTime, setSelectedStartTime] = useState({ title: '12:00 AM', key: '1' });
  const [endTime, setSelectedEndTime] = useState({ title: '12:00 AM', key: '1' });

  const timePeriodList = [
    { title: 'Last 24 hours', key: '1' },
    { title: 'Last 7 days', key: '2' },
    { title: 'Last 15 days', key: '3' },
    { title: 'Last 30 days', key: '4' },
  ];

  const timeList: { title: string, key: string }[] = [];
  const everySecond = 2;
  const halfDay = 12;
  const fullDay = 24;
  const fullDayHalfHours = 48;

  for (let i = 0, hour = 12; i < fullDayHalfHours; i += 1) {
    timeList.push({
      title: `${hour}:${i % everySecond ? '30' : '00'} ${i < fullDay ? 'AM' : 'PM'}`,
      key: (i + 1).toString(),
    });
    if (i % everySecond) {
      if (hour === halfDay) {
        hour = 1;
      } else {
        hour += 1;
      }
    }
  }

  return (
    <Modal
      width="426"
      height="auto"
      setModalOpen={setOpenModal}
      title={title}
      primaryButtonLabel="Submit"
      primaryButtonAction={() => {
        if (valueType.key === dateRangeKey && daysDelta > 31) {
          addNotification({ type: 'error', message: 'Please select maximum 31 day difference between both the dates.' });
          return;
        }

        let to = DateTime.now();
        let from = to.minus({ days: 1 }).set({ hour: 0, minute: 0, second: 0 });

        if (valueType.key === relativeTimeKey) {
          const week = 7;
          const biweek = 15;
          const month = 29;
          let daysMinus = 1;

          if (timePeriod.key === '2') {
            daysMinus = week;
          } else if (timePeriod.key === '3') {
            daysMinus = biweek;
          } else if (timePeriod.key === '4') {
            daysMinus = month;
          }

          from = to.minus({ days: daysMinus });
        } else {
          const fromTime = DateTime.fromFormat(startTime.title, 'h:mm a').toFormat('HH:mm');
          const fromArr = fromTime.split(':');
          const toTime = DateTime.fromFormat(endTime.title, 'h:mm a').toFormat('HH:mm');
          const toArr = toTime.split(':');

          to = DateTime.fromJSDate(endDate).set({ hour: parseInt(toArr[0] || '0', 10), minute: parseInt(toArr[1] || '0', 10), second: 59 });
          from = DateTime.fromJSDate(startDate).set({ hour: parseInt(fromArr[0] || '0', 10), minute: parseInt(fromArr[1] || '0', 10), second: 0 });
        }
        if (from < to) {
          primaryButtonAction({ from, to });
        } else {
          addNotification({ type: 'error', message: 'Start date/time cannot be later than end date/time' });
        }
      }}
      secondaryButtonLabel="Cancel"
    >
      <RadioButtons
        onClick={(clickedRadioBtn: RadioButtonElementProps) => setValueType(clickedRadioBtn)}
        selected={valueType}
        list={[{ label: 'Relative time', key: relativeTimeKey }, { label: 'Date range', key: dateRangeKey }]}
        type="light"
      />
      {valueType.key === relativeTimeKey ? (
        <SelectBox
          label="Select time period"
          type="light"
          list={timePeriodList}
          onClick={(item: SelectBoxItemType) => setSelectedTimePeriod(item)}
          title={timePeriod.title}
          selectedItemKey={timePeriod.key}
          listWidth={timePeriodListWidth}
        />
      ) : (
        <div className="textboxes">
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            maxDaysRange={90}
            setDaysDelta={setDaysDelta}
            type="light"
          />
          <div className="textboxes__timeboxes">
            <SelectBox
              label="Start time"
              type="light"
              list={timeList}
              onClick={(item: SelectBoxItemType) => setSelectedStartTime(item)}
              title={startTime.title}
              selectedItemKey={startTime.key}
              listWidth={timeListWidth}
            />
            <SelectBox
              label="End time"
              type="light"
              list={timeList}
              onClick={(item: SelectBoxItemType) => setSelectedEndTime(item)}
              title={endTime.title}
              selectedItemKey={endTime.key}
              listWidth={timeListWidth}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default RelativeTimeOrDateRangeModal;
