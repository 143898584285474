import React from 'react';
import Utils from '../../../../../utils/Utils';
import { EditModalCustomTitleProps } from '../../../../../types/AccountModal';

import { ReactComponent as CopyIcon } from '../../../../../img/icons/duplicate.svg';
import { useAppContext } from '../../../../../utils/AppContext';

function EditAccountModalCustomTitle(props: EditModalCustomTitleProps): JSX.Element {
  const { item, title, addClass } = props;
  const { addNotification } = useAppContext();

  return (
    <div className={`edit-modal  ${addClass || ''}`}>
      <h2 className="edit-modal__title">{title}</h2>
      <div className="edit-modal__element">
        <div className="edit-modal__subtitle">Created</div>
        {Utils.getConvertedDate(new Date(item.created), 'MM/dd/yyyy, h:mm a ZZZZ')}
      </div>
      <div className="edit-modal__element">
        <div className="edit-modal__subtitle">Updated</div>
        {Utils.getConvertedDate(new Date(item.updated), 'MM/dd/yyyy, h:mm a ZZZZ')}
      </div>
      <div className="edit-modal__element copyid">
        <div className="edit-modal__subtitle">Copy ID</div>
        <button
          type="button"
          onClick={() => {
            Utils.copyToClipboard(item.orgid);
            addNotification({ type: 'success', message: 'Your "Copy to clipboard" operation was successful' });
          }}
        >
          <CopyIcon />
        </button>
      </div>
    </div>
  );
}

export default EditAccountModalCustomTitle;
