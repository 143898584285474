/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { AutoOpenSelectboxListType, AutoOpenSelectboxPropsType } from '../../types/AutoOpenSelectboxPropsType';

import { ReactComponent as CheckIcon } from '../../img/icons/check.svg';

const maxItemsNumber = 4;
const selectboxItemHeight = 37;

function AutoOpenSelectbox(props: AutoOpenSelectboxPropsType): JSX.Element {
  const { label, list, maxItems = maxItemsNumber, type = 'single', selectedItemList, onClick, children } = props;

  return (
    <div className="auto-open-selectbox">
      { children || (<div className="label">{label}</div>) }
      <ul
        className="auto-open-selectbox__list"
        style={{ maxHeight: `${maxItems * selectboxItemHeight}px` }}
      >
        {list.map((element: AutoOpenSelectboxListType) => {
          const isSelected = Array.isArray(selectedItemList)
            ? selectedItemList.filter((el: AutoOpenSelectboxListType) => el.groupId === element.groupId).length > 0
            : selectedItemList.groupId === element.groupId;
          return (
            <li
              key={element.groupId}
              className={`auto-open-selectbox__list-element ${isSelected ? 'selected' : ''}`}
              onClick={() => {
                onClick({ name: element.name, groupId: element.groupId }, !isSelected);
              }}
            >
              { type === 'single' && (
              <button className="btn" type="button">
                {element.name}
              </button>
              )}
              { type === 'multi' && (
              <button className="checkbox" type="button">
                <div className="check">
                  { isSelected && <CheckIcon />}
                </div>
                <span className="auto-open-selectbox__list-element-title">{element.name}</span>
              </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default AutoOpenSelectbox;
