/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useMemo, useEffect, ChangeEvent } from 'react';
import { ListRowProps } from 'react-virtualized';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import Modal from '../../Components/Modal';
import Searchbox from '../../Components/Searchbox';
import SelectBox from '../../Components/SelectBox';
import { CustomerObject } from '../../../types/CustomerObject';

import { AssumedRoleObject } from '../../../types/UserObject';
import { AssumeRoleModalProps } from '../../../types/AssumeRoleModalProps';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { useAppContext } from '../../../utils/AppContext';

import { getRequest } from '../../../utils/fetch';
import Utils from '../../../utils/Utils';

function AssumeRoleModal(props: AssumeRoleModalProps): JSX.Element {
  const { modalOpen } = props;
  const { addNotification } = useAppContext();
  const selectBoxWidth = 350;
  const listWidth = 200;
  const listRowHeight = 41;
  const searchBoxMaxItems = 3;
  const [selectedPartner, setSelectedPartner] = useState({ id: '', name: '' });
  const [searchPartner, setSearchPartner] = useState(selectedPartner?.name || '');

  const history = useHistory();

  const { data: customerResp } = useSWR<Map<string, CustomerObject>>(['/organizations', 'UsersPage'], getRequest, { compare: () => false });
  const allCustomers = useMemo(() => {
    const tmpMap = new Map();
    customerResp?.forEach((customer) => {
      tmpMap.set(customer.orgid, customer);
    });
    return tmpMap;
  }, [customerResp]);

  const customersList = useMemo(() => (allCustomers && allCustomers.size > 0 ? Array.from(allCustomers.values()).filter((customer: CustomerObject) => customer.type !== 'PARTNER').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : []), [allCustomers]);
  const [partnerAccountsList, setPartnerAccountsList] = useState(customersList);

  const [rolesFilter, setRolesFilter] = useState([{ title: 'None', key: '0' }]);

  const [assumedRole, setAssumedRole] = useState<AssumedRoleObject>({
    account: { title: '', key: '' },
    role: { title: '', key: '' },
  });

  useEffect(() => {
    if (selectedPartner.id !== '' && assumedRole.account.key === '') {
      setRolesFilter(Utils.getUserRolesByType('partner'));
    } else if ((assumedRole.account.key !== '')) {
      setRolesFilter(Utils.getUserRolesByType('account'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPartner,
    assumedRole.account,
  ]);

  const partnersList = useMemo(() =>
    ((allCustomers && allCustomers.size > 0)
      ? Array.from(allCustomers.values()).filter((customer: CustomerObject) =>
        customer.type === 'PARTNER').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      : []
    ), [allCustomers]);

  const setAccount = (newName?: SelectBoxItemType) => {
    const account = newName !== undefined ? newName : assumedRole.account;
    setAssumedRole((oldValues) => ({
      ...oldValues,
      account,
      role: { title: '', key: '' },
    }));
  };
  const setRole = (newName?: SelectBoxItemType) => {
    const role = newName !== undefined ? newName : assumedRole.role;
    setAssumedRole((oldValues) => ({
      ...oldValues,
      role,
    }));
  };
  useEffect(() => {
    if (selectedPartner.id !== '') {
      setAssumedRole((oldValues) => ({
        ...oldValues,
        account: { title: '', key: '' },
        role: { title: '', key: '' },
      }));
      setPartnerAccountsList(customersList.filter((partneraccount) => (partneraccount.type !== 'partner') && (partneraccount.po === selectedPartner.id)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPartner,
    customersList,
  ]);

  const [filteredPartnerList, setFilteredPartnerList] = useState(partnersList);
  const partnerRowRenderer = ({ key, index, style }: ListRowProps, setToggle: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!filteredPartnerList) {
      return <></>;
    }

    const { name } = filteredPartnerList[index];

    return (
      <li
        key={key}
        style={style}
        className="search-partner--list"
        onClick={() => {
          setToggle(false);
          setSearchPartner(name);
          setSelectedPartner({ id: filteredPartnerList[index].orgid, name });
        }}
      >
        <span className={name === selectedPartner?.name ? 'selected' : ''}>{name || ''}</span>
      </li>
    );
  };
  const handlePartnerListChange = (name: string) => {
    setSearchPartner(name);

    if (name === '') {
      setSelectedPartner({ id: '', name: '' });
      setFilteredPartnerList(partnersList);
    } else {
      setFilteredPartnerList(partnersList?.filter((item: CustomerObject) => item.name.toLowerCase().includes(name.toLowerCase())));
    }
  };
  return (
    <Modal
      title="Assume Account and Role"
      width="500"
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => modalOpen(false)}
      primaryButtonLabel="Submit"
      primaryButtonAction={() => {
        if ((selectedPartner.id !== '' || assumedRole.account.key !== '') && (assumedRole.role.key !== '')) {
          Utils.setAssumedRole(assumedRole, selectedPartner);
          modalOpen(false);

          // do a redirect to LoginCode route
          history.push('/code');
        } else {
          addNotification({ type: 'info', message: 'Please select Partner/ Account & Role to proceed' });
        }
      }}
      setModalOpen={modalOpen}
      className="changepassword assumerole"
    >
      <div>
        <span className="search-partnerdropdown__label"> Partner </span>
        <Searchbox
          label="Partner"
          searchValue={searchPartner}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handlePartnerListChange(event.target.value)}
          list={Utils.arrayToSelectbox(filteredPartnerList || [], 'orgid', 'name')}
          title={selectedPartner.name || 'Select'}
          listWidth={listWidth}
          listRowHeight={listRowHeight}
          listRowRenderer={partnerRowRenderer}
          rowNumber={searchBoxMaxItems}
          iconClickToggleList
          className="search-partnerdropdown assumeRole"
        />
        <SelectBox
          label="Account"
          onClick={(item: SelectBoxItemType) => setAccount(item)}
          selectedItemKey={assumedRole.account.key || ''}
          list={Utils.arrayToSelectbox(partnerAccountsList || [], 'orgid', 'name')}
          title={assumedRole.account.title || 'Select'}
          type="light"
          listWidth={selectBoxWidth}
        />

        <SelectBox
          label="Role"
          onClick={(item: SelectBoxItemType) => setRole(item)}
          selectedItemKey={assumedRole.role.key || ''}
          list={Utils.arrayToSelectbox(rolesFilter || [], 'key', 'title')}
          title={assumedRole.role.title || 'Select'}
          isRequired
          type="light"
          listWidth={selectBoxWidth}
        />

      </div>
    </Modal>
  );
}

export default AssumeRoleModal;
