import React from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { DeleteProjectProps } from '../../../../../types/DeleteProjectProps';
import { useAppContext } from '../../../../../utils/AppContext';
import { deleteRequest } from '../../../../../utils/fetch';
import { ReactComponent as WarningIcon } from '../../../../../img/icons/warning.svg';

function DeleteProject(props: DeleteProjectProps): JSX.Element {
  const {
    modalOpen,
    selectedProject,
    updateProject,
  } = props;

  const { addNotification } = useAppContext();
  return (
    <Modal
      setModalOpen={modalOpen}
      className="admin-project-modal"
      title="Delete project"
      primaryButtonAction={async () => {
        if (selectedProject !== undefined) {
          try {
            const path = `/organizations/${selectedProject.orgId}/api-key/project/${selectedProject.projectId}`;
            deleteRequest(path)
              .then((result) => {
                if (!result.error) {
                  if (updateProject) {
                    updateProject();
                  }
                  addNotification({
                    type: 'success',
                    message: `Your project ${selectedProject.projectName} has been successfully deleted`,
                  });
                } else {
                  addNotification({ type: 'error', message: 'Your Delete project operation has failed.' });
                }
              });
          } catch (e) {
            addNotification({ type: 'error', message: 'Your Delete project operation has failed.' });
          }
          modalOpen(false);
        }
      }}
      primaryButtonLabel="Apply"
      secondaryButtonAction={() => modalOpen(false)}
      secondaryButtonLabel="Cancel"
      borderTopColor="yellow"
    >
      <div className="admin-project-modal-content">
        <WarningIcon className="admin-user-modal-content-icon" />
        Are you sure you want to delete the selected project?
      </div>
    </Modal>
  );
}

export default DeleteProject;
