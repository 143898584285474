import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import Table from '../../../Common/Components/Table';
import TableToolbar from '../../Admin/Pages/Components/TableToolbar';
import getHeaderProps from '../../../utils/getHeaderProps';
import { ReportTemplateObject } from '../../../types/ReportTemplateObject';
import { ReportDefinitionObject, ReportDefinitionsTableItem } from '../../../types/ReportDefinitionObject';
import { ReportDefinitionsListProps } from '../../../types/ReportDefinitionsListProps';
import ReportDefinitionModal from './ReportDefinitionModal';
import { useAppContext } from '../../../utils/AppContext';
import Loading from '../../../Common/Components/Loading';
import Button from '../../../Common/Components/Button';
import Utils from '../../../utils/Utils';
import { reportTypeTitles, reportTemplateIds, reportDictionary } from '../../../utils/constants';
import { getRequest } from '../../../utils/fetch';
import { ReactComponent as EditIcon } from '../../../img/icons/edit.svg';

function ReportDefinitionsList(props: ReportDefinitionsListProps): JSX.Element {
  const { reportType, selectedCustomer, selectedSite,
    selectedNodes, setSelectedNodes, selectedGroups, setSelectedGroups } = props;
  const { addNotification } = useAppContext();
  const [openModal, setModalOpen] = useState(false);
  const [action, setAction] = useState<string>();
  const [reportTemplate, setReportTemplate] = useState<ReportTemplateObject>();
  const [reportDefinitionTableItems, setReportDefinitionTableItems] = useState<ReportDefinitionsTableItem[]>();
  const reportProcessors = {
    NETWORK: 'NETWORK_STATISTICS',
    ENERGY: 'ADVANCED_ENERGY',
    POWER_QUALITY: 'POWER_QUALITY',
    INT_TERRAGO: 'INT_TERRAGO',
  };
  const [selectedReportDefinitionsTableItem,
    setSelectedReportDefinitionsTableItem] = useState<ReportDefinitionsTableItem>(Utils.defaultReportDefinition(reportProcessors[reportType]));

  const isNonReadOnly = Utils.isNonReadOnly();
  const reportDefinitionsHeaders = getHeaderProps('ReportDefinitions');

  const { data: reportDefinitionsResp, mutate: updateReportDefinitionsList } = useSWR<Array<ReportDefinitionObject>>(
    [`/organizations/${selectedCustomer.id || '65a7341d-91c6-4286-90f7-2583b6b82f07'}/report-definitions`,
      'reportDefinitionsFetcherFn'],
    (url) => getRequest(url, {}, (data: ReportDefinitionObject[]): ReportDefinitionObject[] => data?.filter((rdef) => rdef.reportProcessor === reportProcessors[reportType])),
  );

  const getFrequency = (scheduleType, dimension, interval) => {
    const translate = {
      HOURLY: 'hour',
      DAILY: 'day',
      WEEKLY: 'week',
      MONTHLY: 'month',
    };
    if (scheduleType === 'ONE_TIME') return 'One time';
    const msg = 'Every ';
    if (interval === 1) return msg + translate[dimension];
    return `${msg}${interval} ${translate[dimension]}${interval > 1 ? 's' : ''}`;
  };

  useEffect(() => {
    if (reportDefinitionsResp) {
      setReportDefinitionTableItems(reportDefinitionsResp.map((item) => {
        const tableItem: ReportDefinitionsTableItem = { ...item,
          frequency: getFrequency(item.selectedReportScheduleType, item.scheduleDimension, item.scheduleInterval),
          scope: reportDictionary[item.selectedReportDimension],
          actions: (
            <div className="fota-campaign-actions">
              { isNonReadOnly && (
              <>
                <div className="fota-campaign-actions--item">
                  {item.selectedReportScheduleType === 'SCHEDULED' && (
                  <EditIcon />
                  )}
                </div>
              </>
              )}
            </div>
          ),
          statusFmt: (
            <div className="report-definition-status">
              <span className={`status-dot ${item.status?.toLowerCase()}`} />
              {{ EXECUTED: 'COMPLETED',
                DISABLED: 'NOT ACTIVE',
                ACTIVE: 'ACTIVE' }[item.status || 'ACTIVE']}
            </div>
          ),
        };
        return tableItem;
      }));
    }
  }, [isNonReadOnly, reportDefinitionsResp]);

  return (
    <>
      <TableToolbar
        title={`${reportTypeTitles[reportType]} Report Definitions`}
        addClass="btn-container"
      >
        <>
          {isNonReadOnly && (
            <div className="tabletoolbar__children">
              <Button
                onClick={async () => {
                  try {
                    const result = await getRequest('/reports/templates');
                    if (!result.error) {
                      const template = result.find((t, idx) =>
                        t.templateId === reportTemplateIds[reportProcessors[reportType]]);
                      setReportTemplate(template);
                    } else {
                      addNotification({ type: 'error', message: 'An error occurred while accessing the report template.' });
                    }
                  } catch (e) {
                    addNotification({ type: 'error', message: 'An error occurred while accessing the report template.' });
                  }
                  setAction('Create');
                  setSelectedReportDefinitionsTableItem(Utils.defaultReportDefinition(reportProcessors[reportType]));
                  setModalOpen(true);
                }}
                label="Create a new report definition"
                buttonType="primary"
              />
            </div>
          )}
        </>
      </TableToolbar>
      {reportDefinitionTableItems
        ? (
          <div className="table table--light system-alerts auto-height">
            <Table
              headers={reportDefinitionsHeaders}
              data={reportDefinitionTableItems}
              cellOnClickColumns={['reportName', 'actions']}
              cellOnClick={async (reportDefinitionTableItem: ReportDefinitionsTableItem) => {
                try {
                  const result = await getRequest('/reports/templates');
                  if (!result.error) {
                    const template = result.find((t, idx) =>
                      t.templateId === reportTemplateIds[reportProcessors[reportType]]);
                    setSelectedReportDefinitionsTableItem(reportDefinitionTableItem);
                    setReportTemplate(template);
                    setAction('Edit');
                    setModalOpen(true);
                  } else {
                    addNotification({ type: 'error', message: 'An error occurred while accessing the report template.' });
                  }
                } catch (e) {
                  addNotification({ type: 'error', message: 'An error occurred while accessing the report template.' });
                }
              }}
              skipCellMeasure
              autoheight
            />
          </div>
        ) : (
          <Loading />
        )}
      {openModal && reportTemplate
        && (
          <ReportDefinitionModal
            setModalOpen={setModalOpen}
            action={action || 'Create'}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            reportTemplate={reportTemplate}
            selectedReportDefinitionsTableItem={selectedReportDefinitionsTableItem}
            updateReportDefinitionsList={updateReportDefinitionsList}
          />
        )}
    </>
  );
}

export default ReportDefinitionsList;
