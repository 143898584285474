import React from 'react';
import { SubtoolbarPropsType } from '../../../types/SubtoolbarPropsType';

function Subtoolbar(props: SubtoolbarPropsType): JSX.Element {
  const { type, activeToolbar, order, children } = props;
  return (
    <div className="toolbar__subtoolbar">
      <section
        className={`toolbar__subtoolbar-section ${type} ${activeToolbar === order ? 'open' : 'hide'}`}
      >
        { children }
      </section>
    </div>
  );
}

export default Subtoolbar;
