import React from 'react';
import { ToolbarButtonContainerPropsType } from '../../types/ToolbarButtonContainerPropsType';

function ToolbarButtonContainer(props: ToolbarButtonContainerPropsType): JSX.Element {
  const { children } = props;

  return (
    <div className="toolbar-tabs__links">
      {children}
    </div>
  );
}

export default ToolbarButtonContainer;
