import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { WizardAlertFilterSelectorProps } from '../../../../../types/AlertActivityReportWizard';
import RadioButtons from '../../../../../Common/Components/RadioButtons';
import { RadioButtonElementProps } from '../../../../../types/RadioButtonsProps';
import { AlertActivityFilterBy, AlertActivityReportsFrequency } from '../../../../../types/AlertActivityReports';
import SelectBox from '../../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import DateRangePicker from '../../../../../Common/Components/DateRangePicker';
import FilterMultiSelectBox from '../../../../../Common/Components/FilterMultiSelectBox';
import {
  basedOnAlertTypeList,
  basedOnSeverityList,
  basedOnTypeList,
  dayOfMonthList,
  dayOfWeekList,
  frequencyList,
  scheduleList,
  statusList,
} from '../../../../../utils/constants';
import Utils from '../../../../../utils/Utils';
import { MultiSelectBoxItem } from '../../../../../types/MultiSelectBox';

function WizardAlertFilterSelector(props: WizardAlertFilterSelectorProps): JSX.Element {
  const { reportObject, setReportObject, setFilterBy } = props;

  const alertCategories = Utils.isVerizonUser() ? new Set(['alert', 'event']) : new Set(['alert']);

  // Accessible alert types - based on user type
  const [availableAlertTypes] = useState<MultiSelectBoxItem[]>(basedOnAlertTypeList.filter(
    (item) => alertCategories.has(item.type || ''),
  ));

  // Frequency - one-time or recurring
  const [frequency, setFrequency] = useState<RadioButtonElementProps>(frequencyList.find(
    (item) => item.key === (reportObject.frequency === 'ONCE' ? 'one-time' : 'recurring'),
  ) || frequencyList[0]);

  // Schedule - daily, weekly, monthly
  const [schedule, setSchedule] = useState<SelectBoxItemType | undefined>(scheduleList.find(
    (item) => item.key.toUpperCase() === reportObject.frequency,
  ) || undefined);

  // Schedule weekly - day of week
  const [dayOfWeek, setDayOfWeek] = useState<SelectBoxItemType | undefined>(dayOfWeekList.find(
    (item) => (reportObject.frequency === 'WEEKLY' ? item.key === reportObject.reportOnDay?.toString() : undefined),
  ) || undefined);

  // Schedule monthly - day of month
  const [dayOfMonth, setDayOfMonth] = useState<SelectBoxItemType | undefined>(dayOfMonthList.find(
    (item) => (reportObject.frequency === 'MONTHLY' ? item.key === reportObject.reportOnDay?.toString() : undefined),
  ) || undefined);

  // Start date - in case of frequency "ONCE"
  const [startDate, setStartDate] = useState<Date>(new Date(reportObject.filters.raisedAfter || new Date()));

  // End date - in case of frequency "ONCE"
  const [endDate, setEndDate] = useState<Date>(new Date(reportObject.filters.raisedBefore || new Date()));

  // Based on type
  const [basedOnType, setBasedOnType] = useState<RadioButtonElementProps>(basedOnTypeList.find(
    (item) => item.key === (reportObject.filters.severities && reportObject.filters.severities?.length > 0 ? 'severity' : 'alert_type'),
  ) || basedOnTypeList[0]);

  // Alert types
  const [alertTypes, setAlertTypes] = useState<Set<string>>(new Set(availableAlertTypes.filter(
    (alertType) => Array.from(reportObject.filters.types || []).find((item) => (item === alertType.id)),
  ).map((val) => val.name) || []));

  // Severities
  const [severities, setSeverities] = useState<Set<string>>(new Set(basedOnSeverityList.filter(
    (severity) => Array.from(reportObject.filters.severities || []).find((item) => item === severity.id),
  ).map((val) => val.name) || []));

  // Statuses
  const [statuses, setStatuses] = useState<Set<string>>(new Set(statusList.filter(
    (status) => Array.from(reportObject.filters.statuses || []).find((item) => item === status.id),
  ).map((val) => val.name) || []));

  useEffect(() => {
    setFilterBy(basedOnType.key as AlertActivityFilterBy);
  }, [basedOnType.key, setFilterBy]);

  useEffect(() => {
    setReportObject((origItem) => {
      const newItem = { ...origItem };

      if (frequency.key === 'one-time') {
        newItem.frequency = 'ONCE';
        newItem.filters.raisedAfter = DateTime.fromJSDate(startDate).startOf('day').toISO();
        newItem.filters.raisedBefore = DateTime.fromJSDate(endDate).endOf('day').toISO();
        delete newItem.reportOnDay;
      } else {
        newItem.frequency = schedule?.key.toUpperCase() as AlertActivityReportsFrequency;
        delete newItem.filters.raisedAfter;
        delete newItem.filters.raisedBefore;

        switch (schedule?.key) {
          case 'daily':
            delete newItem.reportOnDay;
            break;
          case 'weekly':
            newItem.reportOnDay = dayOfWeek?.key ? parseInt(dayOfWeek?.key, 10) : undefined;
            break;
          case 'monthly':
            newItem.reportOnDay = dayOfMonth?.key ? parseInt(dayOfMonth?.key, 10) : undefined;
            break;
          default: break;
        }
      }

      if (basedOnType.key === 'severity') {
        const severityKeys = basedOnSeverityList.filter(
          (severity) => Array.from(severities.values()).find((item) => item === severity.name),
        ).map((val) => val.id.toString());

        newItem.filters.severities = severityKeys.length > 0 ? severityKeys : [];
        delete newItem.filters.types;
      } else {
        const alertKeys = availableAlertTypes.filter(
          (alertType) => Array.from(alertTypes.values()).find((item) => item === alertType.name),
        ).map((val) => val.id.toString());

        newItem.filters.types = alertKeys.length > 0 ? alertKeys : [];
        delete newItem.filters.severities;
      }

      const statusKeys = statusList.filter(
        (status) => Array.from(statuses.values()).find((item) => item === status.name),
      ).map((val) => val.id.toString());

      newItem.filters.statuses = statusKeys.length > 0 ? statusKeys : [];

      return newItem;
    });
  }, [frequency.key, schedule?.key, startDate, endDate, basedOnType.key, alertTypes, severities, statuses, setReportObject, dayOfWeek?.key, dayOfMonth?.key, availableAlertTypes]);

  return (
    <div className="wizard-filter-selector">
      <div className="radio-buttons-label first">Publish</div>
      <RadioButtons
        selected={frequency}
        onClick={(item) => setFrequency(item)}
        type="light"
        extraClasses="vertical"
        list={frequencyList}
      />
      <div className="report-schedule">
        {frequency.key === 'recurring' && (
        <SelectBox
          label="Schedule"
          title={schedule?.title || 'Select'}
          type="light"
          listWidth={220}
          selectedItemKey={schedule?.key}
          list={scheduleList}
          onClick={(item) => setSchedule(item)}
        />
        )}
        {frequency.key === 'recurring' && schedule?.key === 'weekly' && (
          <>
            <span>on</span>
            <SelectBox
              title={dayOfWeek?.title || 'Select'}
              type="light"
              listWidth={110}
              selectedItemKey={dayOfWeek?.key}
              list={dayOfWeekList}
              onClick={(item) => setDayOfWeek(item)}
            />
          </>
        )}
        {frequency.key === 'recurring' && schedule?.key === 'monthly' && (
          <>
            <span>on</span>
            <SelectBox
              title={dayOfMonth?.title || 'Select'}
              type="light"
              listWidth={110}
              selectedItemKey={dayOfMonth?.key}
              list={dayOfMonthList}
              onClick={(item) => setDayOfMonth(item)}
            />
          </>
        )}
        {frequency.key === 'one-time' && (
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate as React.Dispatch<React.SetStateAction<Date>>}
            endDate={endDate}
            setEndDate={setEndDate as React.Dispatch<React.SetStateAction<Date>>}
            maxDaysRange={90}
            type="light"
          />
        )}
      </div>
      <div className="radio-buttons-label">Report based on</div>
      <RadioButtons
        selected={basedOnType}
        onClick={(item) => setBasedOnType(item)}
        type="light"
        extraClasses="vertical"
        list={basedOnTypeList}
      />
      {basedOnType.key === 'alert_type' && (
        <FilterMultiSelectBox
          inputId="alert-type-selectbox"
          width={220}
          data={availableAlertTypes}
          onCheckboxClick={(items) => setAlertTypes(items)}
          selectedData={alertTypes}
          hideSearchBox
        />
      )}
      {basedOnType.key === 'severity' && (
        <FilterMultiSelectBox
          inputId="severity-selectbox"
          width={220}
          data={basedOnSeverityList}
          onCheckboxClick={(items) => setSeverities(items)}
          selectedData={severities}
          hideSearchBox
        />
      )}
      <div className="selectbox-status">
        <FilterMultiSelectBox
          label="Status"
          inputId="status-selectbox"
          width={220}
          data={statusList}
          onCheckboxClick={(items) => setStatuses(items)}
          selectedData={statuses}
          hideSearchBox
        />
      </div>
    </div>
  );
}

export default WizardAlertFilterSelector;
