/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { List } from 'react-virtualized';
import { SearchBoxPropsType } from '../../types/SearchBoxType';
import { ReactComponent as SearchIcon } from '../../img/icons/search.svg';

const displayableItemNumer = 4;

function Searchbox(props: SearchBoxPropsType): JSX.Element {
  const {
    list,
    title,
    icon,
    type,
    searchValue,
    onChange,
    listRowRenderer,
    listWidth,
    listRowHeight,
    rowNumber = displayableItemNumer,
    alwaysShowIcon = false,
    iconClickToggleList = false,
    className = '',
    listStyle = {},
    alwaysOpen = false,
    showInput = true,
    setIsListOpen,
    isListOpen,
  } = props;

  const searchBoxType = type || '';
  const computedHeight = list.length < rowNumber ? (listRowHeight * list.length) : (listRowHeight * rowNumber);
  let iconValue = icon || (<SearchIcon className="search__icon" />);
  const [toggle, setToggle] = useState(false);
  const minHeight = 200;

  if (iconClickToggleList) {
    iconValue = (
      <div
        data-testid="searchbox-icon"
        onClick={() => {
          if (setIsListOpen) {
            if (isListOpen === 0) {
              setIsListOpen(Math.min(computedHeight, minHeight));
            } else {
              setIsListOpen(0);
            }
          }
          setToggle((oldValue) => !oldValue);
        }}
      >
        {iconValue}
      </div>
    );
  }

  return (
    <div className={`search ${searchBoxType} ${className}`}>
      {showInput && (
        <input
          type="text"
          className={`search__input ${searchBoxType}`}
          placeholder={title}
          value={searchValue}
          onChange={(e) => {
            onChange(e);
            setToggle(e.target.value !== '');
          }}
        />
      )}
      {((!searchValue || alwaysShowIcon) && showInput) && iconValue}
      {((toggle && (searchValue !== '' || iconClickToggleList)) || alwaysOpen) && (
      <List
        width={listWidth}
        height={computedHeight}
        rowCount={list.length}
        rowHeight={listRowHeight}
        rowRenderer={(rendererProps) => listRowRenderer(rendererProps, setToggle)}
        data={list}
        style={listStyle}
      />
      )}
    </div>
  );
}

export default Searchbox;
