/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Login from './Pages/Login/Login';
import LoginCode from './Pages/Login/LoginCode';

// Pages
import SiteSelectorPage from './Pages/Site/SiteSelector';
import LightsPage from './Pages/Lights/LightsPage';
import GroupsPage from './Pages/Groups/GroupsPage';
import JobsPage from './Pages/Jobs/JobsPage';
import AlarmsPage from './Pages/Alarms/AlarmsPage';
import SchedulesPage from './Pages/Schedules/SchedulesPage';
import EnergyPage from './Pages/Energy/EnergyPage';
import AnalysisPage from './Pages/Analysis/AnalysisPage';

// Config pages
import FixturesSettings from './Pages/Config/Pages/Fixtures/FixturesPage';
import AuditLogSettings from './Pages/Config/Pages/AuditLogPage';
import CustomAttributes from './Pages/Config/Pages/CustomAttributesPage';
import AlarmConfigPage from './Pages/Config/Pages/AlarmConfig/AlarmConfigPage';
import FirmwareSettings from './Pages/Config/Pages/FirmwarePage';
import AlertActivityReportsPage from './Pages/Config/Pages/AlertActivityReports/AlertActivityReportsPage';

// Admin pages
import SitesPage from './Pages/Admin/Pages/Sitemanager/SitemanagerPage';
import AccountsPage from './Pages/Admin/Pages/Accounts/AccountsPage';
import UsersPage from './Pages/Admin/Pages/Users/UsersPage';
import CommissioningPage from './Pages/Admin/Pages/Commissioning/CommissioningPage';
// eslint-disable-next-line import/extensions
import SiteAssignmentPage from './Pages/Admin/Pages/SiteAssignment/SiteAssignmentPage';
import SystemAlertsPage from './Pages/Admin/Pages/SystemAlerts/SystemAlertsPage';
import NodeDiagnosticsPage from './Pages/Admin/Pages/NodeDiagnostics/NodeDiagnosticsPage';
import FotaCampaignsPage from './Pages/Admin/Pages/FotaCampaigns/FotaCampaignsPage';
import ProjectAPIkeysPage from './Pages/Admin/Pages/ProjectAPIkeys/ProjectAPIkeysPage';

import { RouteType } from './types/router';

const routes: Array<RouteType> = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/sites',
    component: SiteSelectorPage,
  },
  {
    path: '/lights',
    component: LightsPage,
  },
  {
    path: '/groups',
    component: GroupsPage,
  },
  {
    path: '/jobs',
    component: JobsPage,
  },
  {
    path: '/alarms',
    component: AlarmsPage,
  },
  {
    path: '/schedules',
    component: SchedulesPage,
  },
  {
    path: '/energy',
    component: EnergyPage,
  },
  {
    path: '/analysis',
    onLeave: localStorage.removeItem('analysisSelectedNodes'),
    component: AnalysisPage,
  },
  {
    path: '/config/fixtures',
    component: FixturesSettings,
  },
  {
    path: '/config/customattributes',
    component: CustomAttributes,
  },
  {
    path: '/config/alarmconfiguration',
    component: AlarmConfigPage,
  },
  {
    path: '/config/firmware',
    component: FirmwareSettings,
  },
  {
    path: '/config/audittrail',
    component: AuditLogSettings,
  },
  {
    path: '/config/alertactivityreports',
    component: AlertActivityReportsPage,
  },
  {
    path: '/admin/fotacampaigns',
    component: FotaCampaignsPage,
  },
  {
    path: '/admin/projectAPIkeys',
    component: ProjectAPIkeysPage,
  },
  {
    path: '/admin/accounts',
    component: AccountsPage,
  },
  {
    path: '/admin/sites',
    component: SitesPage,
  },
  {
    path: '/admin/users',
    component: (props: any) => <UsersPage type="active" {...props} />,
  },
  {
    path: '/admin/suspendedusers',
    component: (props: any) => <UsersPage type="suspended" {...props} />,
  },
  {
    path: '/admin/commissioning',
    component: CommissioningPage,
  },
  {
    path: '/admin/siteassignment',
    component: SiteAssignmentPage,
  },
  {
    path: '/admin/systemalerts',
    component: SystemAlertsPage,
  },
  {
    path: '/admin/nodediagnostics',
    component: NodeDiagnosticsPage,
  },
  {
    path: '/code',
    component: LoginCode,
  },
];

export default routes;
