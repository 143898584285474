/* eslint-disable no-nested-ternary */
import React, { createRef, useEffect, useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import Button from './Button';
import Textbox from './Textbox';
import { ReactComponent as MapIcon } from '../../img/icons/plus.svg';
import { ReactComponent as TrashCanIcon } from '../../img/icons/trash.svg';
import { AccountNumberEditorProps } from '../../types/AccountNumberEditorProps';

const listWidth = 200;
const listHeight = 196;
const listRowHeight = 45;

function AccountNumberEditor(props: AccountNumberEditorProps): JSX.Element {
  const {
    accountNumbers,
    setAccountNumbers,
  } = props;

  const accountNumberRegex = /^[0-9]{10}-[0-9]{5}$/;

  const [newAccountNumber, setNewAccountNumber] = useState<string>();
  const [newAccountNumberError, setNewAccountNumberError] = useState<boolean>(false);
  const [newAccountNumberErrorMessage, setNewAccountNumberErrorMessage] = useState<string>('');
  const [displayedAccountNumbers, setDisplayedAccountNumbers] = useState<{ accountNumber: string, isFilled: boolean }[]>([]);

  const list = createRef<List>();
  const emptyAccountNumberCount = 4;

  useEffect(() => {
    const emptyAccountNumber = '';

    const accountNumbersWithFilledFlag = accountNumbers.map((acc) => ({ accountNumber: acc, isFilled: true }));
    setDisplayedAccountNumbers(accountNumbersWithFilledFlag
      .concat(
        new Array(Math.max((emptyAccountNumberCount - accountNumbersWithFilledFlag.length), 0))
          .fill({ accountNumber: emptyAccountNumber, isFilled: false }),
      ));
  }, [accountNumbers]);

  const renderer = ({ key, index, style }: ListRowProps) => (
    <div
      className={`accountnumber-editor__list-element ${displayedAccountNumbers[index].isFilled ? 'accountnumber-list-element--filled' : 'accountnumber-list-element--empty'}`}
      key={key}
      style={style}
    >
      <Textbox
        disabled
        value={displayedAccountNumbers[index].accountNumber}
      />
      <TrashCanIcon onClick={() => removeAccountNumber(displayedAccountNumbers[index].accountNumber)} />
    </div>
  );

  const addAccountNumber = () => {
    const numberError = !newAccountNumber || newAccountNumber.length === 0;
    const invalidNumberError = newAccountNumber?.match(accountNumberRegex) === null;
    const uniqueNumberError = accountNumbers.filter((acc) => acc === newAccountNumber).length !== 0;
    setNewAccountNumberError(uniqueNumberError || invalidNumberError || numberError);
    setNewAccountNumberErrorMessage(
      numberError
        ? 'Required field'
        : (invalidNumberError
          ? 'Account number must be a valid VZID.'
          : (uniqueNumberError
            ? 'Account numbers must be unique.'
            : '')),
    );
    if (!numberError && !uniqueNumberError && !invalidNumberError) {
      setAccountNumbers([...accountNumbers, newAccountNumber]);
      setNewAccountNumber('');
    }
  };

  const removeAccountNumber = (accountNumberToDelete: string) => {
    setAccountNumbers(accountNumbers.filter((acc) => acc !== accountNumberToDelete));
  };

  return (
    <div className="accountnumber-editor">
      <div className="accountnumber-editor__header">
        <Textbox
          onChange={(e) => {
            setNewAccountNumber(e.target.value);
          }}
          label="Account number(s)"
          placeholder="Enter VZID"
          value={newAccountNumber}
          error={newAccountNumberError}
          errorMessage={newAccountNumberErrorMessage}
          isRequired
        />
        <Button onClick={() => addAccountNumber()} buttonType="primary" extraClasses="round-button"><MapIcon /></Button>
      </div>
      <div className="accountnumber-editor__list">
        <List
          ref={list}
          width={listWidth}
          height={listHeight}
          rowHeight={listRowHeight}
          rowCount={displayedAccountNumbers && displayedAccountNumbers.length}
          rowRenderer={renderer}
          list={displayedAccountNumbers}
          containerStyle={{
            width: '100%',
            maxWidth: '100%',
          }}
          style={{
            width: '100%',
          }}
        />
      </div>
      <span className="accountnumber-editor__datasize-text">
        {accountNumbers.length}
        {' '}
        {accountNumbers.length > 1 ? 'account numbers' : 'account number'}
      </span>
    </div>
  );
}

export default AccountNumberEditor;
