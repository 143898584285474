import React, { useState } from 'react';
import { MultiSelectToolContainerProps } from '../../types/MapProps';
import { RadioButtonElementProps } from '../../types/RadioButtonsProps';
import { ReactComponent as CloseIcon } from '../../img/icons/close.svg';
import Button from './Button';
import RadioButtons from './RadioButtons';

export default function MultiSelectToolContainer(props: MultiSelectToolContainerProps): JSX.Element {
  const { container, mouseAction, setMouseAction, selectionType, setSelectionType } = props;
  const [mouseActionState, setMouseActionState] = useState(mouseAction);
  const [selectionTypeState, setSelectionTypeState] = useState(selectionType);

  const allMouseActions: Array<RadioButtonElementProps> = [
    { key: 'click', label: 'Click' },
    { key: 'hover', label: 'Hover' },
  ];
  const allSelectionTypes: Array<RadioButtonElementProps> = [
    { key: 'toggle', label: 'Toggle' },
    { key: 'selectOnly', label: 'Select only' },
    { key: 'deselectOnly', label: 'Deselect only' },
  ];

  return (
    <div className="multiselect-container">
      <div className="multiselect-header">
        <span>Multi select tool</span>
        <Button onClick={() => container.remove()}>
          <CloseIcon />
        </Button>
      </div>
      <div className="multiselect-action">
        <span>Mouse action</span>
        <RadioButtons
          onClick={(clickedRadioBtn: RadioButtonElementProps) => {
            setMouseAction(clickedRadioBtn);
            setMouseActionState(clickedRadioBtn);
          }}
          selected={mouseActionState}
          list={allMouseActions}
          type="dark"
        />
      </div>
      <div className="multiselect-type">
        <span>Selection type</span>
        <RadioButtons
          onClick={(clickedRadioBtn: RadioButtonElementProps) => {
            setSelectionType(clickedRadioBtn);
            setSelectionTypeState(clickedRadioBtn);
          }}
          selected={selectionTypeState}
          list={allSelectionTypes}
          type="dark"
        />
      </div>
    </div>
  );
}
