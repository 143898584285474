import React, { useState, useEffect } from 'react';
import { LoadingProps } from '../../types/loading';
import { subscribe, unsubscribe } from '../../utils/events.js';
import ProgressBar from './ProgressBar.js';

function Loading(props: LoadingProps): JSX.Element {
  const {
    fullScreen = false,
    isLoading = true,
    expectedCount = null,
    caller = null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    caption = null,
  } = props;
  const loadingClass = fullScreen ? 'loading--fullscreen' : 'loading--non-fullscreen';
  const [progressCount, setProgressCount] = useState(0);
  const [systemFailure, setSystemFailure] = useState(false);

  const handleUpdateProgress = (data) => {
    setProgressCount(data.detail);
  };

  const handleSystemFailure = (data) => {
    setSystemFailure(true);
    // eslint-disable-next-line no-alert
    alert('An internal error occurred while accessing data.\nPartial results may be displayed.\nRefreshing the page may resolve the issue.');
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    subscribe(
      'systemFailure',
      handleSystemFailure,
    );
    if (expectedCount && expectedCount > 1000) {
      subscribe(
        'updateProgress',
        handleUpdateProgress,
      );
      return function cleanup() {
        unsubscribe(
          'updateProgress',
          handleUpdateProgress,
        );
        unsubscribe(
          'systemFailure',
          handleSystemFailure,
        );
      };
    }
    return function cleanupSystemFailure() {
      unsubscribe(
        'systemFailure',
        handleSystemFailure,
      );
    };
  }, [expectedCount]);

  return (
    <div className={`loading ${loadingClass}`} style={{ display: isLoading ? 'flex' : 'none' }}>
      {systemFailure
        ? (
          <div>
            <div>
              An internal error has occurred.
            </div>
            <div>
              Refreshing the page may resolve this problem.
            </div>
          </div>
        )
        : (
          <ProgressBar
            size={200}
            caller={caller}
            labelColor="#FFF"
            trackColor="#999"
            indicatorColor="#FFF"
            showPercentage={expectedCount && expectedCount > 1000}
            progress={expectedCount && expectedCount > 1000
              ? Math.round(100 * (progressCount / expectedCount))
              : 100}
          />
        )}
    </div>
  );
}

export default Loading;
