/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import ProjectsPageList from './ProjectsPageList';
import Button from '../../../../../Common/Components/Button';
import { ProjectsPageProps } from '../../../../../types/ProjectsPageProps';
import { ProjectObject } from '../../../../../types/ProjectObject';

// icons
import { ReactComponent as TrashIcon } from '../../../../../img/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../../../../img/icons/edit.svg';

import Utils from '../../../../../utils/Utils';
import EditProject from './EditProject';
import DeleteProject from './DeleteProject';
import CreateProjectApiKey from './CreateProjectApikey';
import CopyProjectApiKey from './CopyProjectApiKey';
import Tooltip from '../../../../../Common/Components/Tooltip';

function ProjectsPageLayout(props: ProjectsPageProps): JSX.Element {
  const { projects, updateProjects, allCustomersList, allUsersList } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalOpen, setModalOpen] = useState(0);
  const [selectedProject, setSelectedProject] = useState({} as ProjectObject);
  const [toggleEditProject, setToggleEditProject] = useState(false);
  const [toggleDeleteProject, setToggleDeleteProject] = useState(false);
  const [toggleCreateProjectKey, setToggleCreateProjectKey] = useState(false);
  const [toggleCopyProjectApiKey, setToggleCopyProjectApiKey] = useState(false);
  const [copyActivatedKey, setCopyActivatedKey] = useState({} as ProjectObject);

  return (
    <div>
      {projects && projects.map((project) => (
        <>
          <div className="project">
            <div className="project__actions">
              <div className="project__actions-name">
                <div className="project__actions-name-title">
                  {project.projectName}
                </div>
                <div className="project__actions-text">
                  <span className="project__account-value">
                    {(project.slot1Timestamp && project.slot2Timestamp) ? '2' : (project.slot1Timestamp || project.slot2Timestamp) ? '1' : '0'}
                  </span>
                  {project !== undefined && project?.slot1Timestamp && project.slot2Timestamp ? ' Keys' : ' Key'}
                </div>
              </div>
              <div className="project__actions-name">
                <div className="project__actions-name-title">
                  Partner
                </div>
                <div className="project__actions-text">
                  <span className="project__actions-partneraccount-value">
                    {allCustomersList.get(project.parentOrgId) === undefined ? 'No partner' : (allCustomersList.get(project.parentOrgId)?.type === 'ROOT') ? allCustomersList.get(project.orgId)?.name : allCustomersList.get(project.parentOrgId)?.name}
                  </span>
                </div>
              </div>
              <div className="project__actions-name">
                <div className="project__actions-name-title">
                  Account
                </div>
                <div className="project__actions-text">
                  <span className="project__actions-partneraccount-value">
                    {allCustomersList.get(project.orgId) === undefined ? 'No account' : (allCustomersList.get(project.parentOrgId)?.type === 'ROOT') ? '' : allCustomersList.get(project.orgId)?.name}
                  </span>
                </div>
              </div>
              <div className="project__actions-name">
                <div className="project__actions-name-title">
                  Role
                </div>
                <div className="project__actions-text">
                  <span className="project__actions-partneraccount-value">
                    {project.role}
                  </span>
                </div>
              </div>
              <div className="project__actions-name">
                <div className="project__actions-name-title">
                  Created By
                </div>
                <div className="project__actions-text">
                  <span className="project__actions-partneraccount-value">
                    {allUsersList.get(project.createdUserId) === undefined ? project.createdUserId : allUsersList.get(project.createdUserId)?.name}
                  </span>
                </div>
              </div>
              {project !== undefined && (project?.slot1Timestamp && project.slot2Timestamp) ? (
                <div>
                  {Utils.isSensityAdmin() && (
                  <div>
                    <Button buttonType="secondary" buttonSize="small" disabled label="Create new key" onClick={() => (0)} />
                  </div>
                  )}
                </div>
              ) : (
                <div>
                  {Utils.isSensityAdmin() && (
                  <div>
                    <Button
                      buttonType="secondary"
                      buttonSize="small"
                      label="Create new key"
                      onClick={() => {
                        setToggleCreateProjectKey(true);
                        setSelectedProject(project);
                      }}
                    />
                  </div>
                  )}
                </div>
              )}
              <Tooltip text="Edit Project">
                <Button
                  extraClasses="trashButton"
                  onClick={() => {
                    setToggleEditProject(true);
                    setSelectedProject(project);
                  }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip text="Delete Project">
                <Button
                  extraClasses="trashButton"
                  onClick={() => {
                    setToggleDeleteProject(true);
                    setSelectedProject(project);
                  }}
                >
                  <TrashIcon />
                </Button>
              </Tooltip>
            </div>
            <div className="project-list">
              <div>
                <ProjectsPageList
                  listItem={project}
                  updateProject={updateProjects}
                  setModalOpen={setModalOpen}
                />
              </div>
            </div>
          </div>
        </>
      ))}
      {toggleCreateProjectKey && (
        <CreateProjectApiKey
          modalOpen={setToggleCreateProjectKey}
          setCopyActivatedKey={setCopyActivatedKey}
          setToggleCopyProjectApiKey={setToggleCopyProjectApiKey}
          selectedProject={selectedProject}
          updateProject={updateProjects}
          text=" Are you sure you want to create an API key for this project?"
        />
      )}
      {toggleCopyProjectApiKey && (
        <CopyProjectApiKey
          modalOpen={setToggleCopyProjectApiKey}
          copyApiKey={copyActivatedKey}
        />
      )}
      {toggleEditProject && (
      <EditProject
        modalOpen={setToggleEditProject}
        selectedProject={selectedProject}
        updateProject={updateProjects}
      />
      )}
      {toggleDeleteProject && (
      <DeleteProject
        modalOpen={setToggleDeleteProject}
        selectedProject={selectedProject}
        updateProject={updateProjects}
      />
      )}
    </div>
  );
}

export default ProjectsPageLayout;
