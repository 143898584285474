import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../Common/Components/Button';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import { GenericSensorTableData } from '../../../types/GenericSensorData';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { slideInWidth } from '../../../utils/constants';
import getHeaderProps from '../../../utils/getHeaderProps';
import Sensors from '../../../utils/Sensors';
import RealTimeValues from '../Components/RealTimeValues';
import Loading from '../../../Common/Components/Loading';

function LightsPowerMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems, fetchedBulkSensorData } = props;
  const headers = getHeaderProps('LightsPower');

  const [powerMultiTableData, setPowerMultiTableData] = useState<GenericSensorTableData[]>();
  const [loading, setLoading] = useState(false);

  const displayedSensors = ['activePower', 'mainsVoltage', 'mainsCurrent', 'powerFactor', 'reactivePower'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);
  const sensorListRealTimeValue = [
    { title: 'Active Power', key: 'activePower' },
    { title: 'Mains Voltage', key: 'mainsVoltage' },
    { title: 'Mains Current', key: 'mainsCurrent' },
    { title: 'Power factor', key: 'powerFactor' },
    { title: 'Reactive Power', key: 'reactivePower' },
  ];
  const [openRealTimeValues, setOpenRealTimeValues] = useState(false);
  const [sensorRealTimeValue, setSelectedSensorRealTimeValue] = useState({ title: 'Select', key: '0' });
  const history = useHistory();

  const timestamp = useRef<number>(0);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(fetchedBulkSensorData).then((response) => {
      const newData = Sensors.getSensorTableWithDisplayedString(
        selectedItems,
        displayedSensors,
        response,
        selectedSite.timezone,
      );
      setPowerMultiTableData(newData);
    }).finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer.id, selectedItems, selectedSite.id, fetchedBulkSensorData]);

  const refreshSensors = useCallback(() => {
    setLoading(true);
    const currentDate = Date.now();
    timestamp.current = currentDate;

    const originalData = powerMultiTableData;
    const tmpDisplayedData = powerMultiTableData?.map((rowData) => Object.entries(rowData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? { ...acc, ...{ [e[0]]: '--' } } : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: rowData.nodeid }));
    setPowerMultiTableData(tmpDisplayedData);

    Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      Array.from(selectedItems.values()).map((n) => n),
      reqSensors.map((name) => Sensors.getSensorId(name)),
    ).then((response) => {
      if (timestamp.current <= currentDate) {
        const newData = Sensors.getSensorTableWithDisplayedString(
          selectedItems,
          reqSensors,
          response,
          selectedSite.timezone,
          {},
          originalData,
        );
        setPowerMultiTableData(newData);
      }
    }).finally(() => setLoading(false));
  }, [powerMultiTableData, reqSensors, selectedCustomer.id, selectedItems, selectedSite.id, selectedSite.timezone]);

  return (
    <div className="power-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Power</span>
            <div className="slide-in__links">
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    setOpenRealTimeValues(!openRealTimeValues);
                  }}
                  label="Get real time values"
                />
              </div>
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    localStorage.setItem('analysisSelectedNodes', JSON.stringify(Array.from(selectedItems.keys())));
                    localStorage.setItem('analysisNetworkStatsDashboardFlag', 'false');
                    localStorage.setItem('analysisPowerQualityDashboardFlag', 'true');
                    history.push('/analysis');
                  }}
                  label="Navigate to Dashboard"
                />
              </div>
            </div>
          </div>
          {loading ? <Loading isLoading={loading} /> : (
            <div className="slide-in__content">
              <div className="table">
                <Table
                  headers={headers}
                  data={powerMultiTableData}
                  fixColCount={1}
                  dark
                />
              </div>
            </div>
          )}
        </>
      </SlideIn>
      {openRealTimeValues && (
      <RealTimeValues
        sensor={sensorRealTimeValue}
        sensorList={sensorListRealTimeValue}
        sensorSelectboxAction={(item: SelectBoxItemType) => setSelectedSensorRealTimeValue(item)}
        closeButton
        closeButtonAction={() => setOpenRealTimeValues(false)}
        refreshValue={() => {
          setReqSensors([sensorRealTimeValue.key]);
          refreshSensors();
          setOpenRealTimeValues(false);
        }}
      />
      )}
    </div>
  );
}

export default LightsPowerMulti;
