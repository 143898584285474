import React from 'react';
import { CheckboxPropTypes } from '../../types/CheckboxPropTypes';
import { ReactComponent as CheckIcon } from '../../img/icons/check.svg';

function Checkbox(props: CheckboxPropTypes): JSX.Element {
  const { checked = false, onClick, dark = true, disabled = false, small = false, color = undefined } = props;
  const lightClass = !dark ? '--light' : '';
  let style: React.CSSProperties = {};
  let iconStyle: React.CSSProperties = {};

  if (color) {
    style = {
      border: `2px solid #${color}`,
      backgroundColor: checked ? `#${color}` : '#FFF',
    };
    iconStyle = { fill: '#FFF' };
  }

  return (
    <div className={`checkbox${lightClass} ${disabled ? 'checkbox--disabled' : ''} ${small ? 'checkbox--small' : ''}`}>
      <button
        type="button"
        disabled={disabled}
        className={`checkbox${lightClass}__button`}
        style={style}
        onClick={() => {
          onClick(!checked);
        }}
      >
        {checked && <CheckIcon style={iconStyle} />}
      </button>
    </div>
  );
}

export default Checkbox;
