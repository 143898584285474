import React from 'react';
import { SlideinListElementPropsType } from '../../../types/SlideinListElementPropsType';

import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';

function SlideinListElementBig(
  props: SlideinListElementPropsType,
): JSX.Element {
  const {
    title,
    prefix,
    date,
    valueBold,
    value,
    border,
    customVal,
    isFirstElement,
    valueType,
    children,
    refreshLoading,
    refreshValue,
    icon,
  } = props;

  const addClass = isFirstElement ? 'mg-top' : '';

  return (
    <div
      className={`list-element ${border || ''} ${addClass}`}
    >
      <div className="list-element-title">
        {title}
        {icon}
      </div>
      {(valueBold || value || customVal) && (
        <div className={`list-element-content ${valueType || ''}`}>
          {valueBold && <span>{valueBold}</span>}
          {value}
          {customVal && <span className="custom-val">{customVal}</span>}
        </div>
      )}
      {(date || prefix) && (
        <div className="list-element-footer">
          {prefix && <span>{prefix}</span>}
          {date}
        </div>
      )}
      {children}
      {(refreshValue && !refreshLoading) && (
        <SyncIcon data-testid="icon" onClick={() => refreshValue()} className="list-element__refresh" />
      )}
      {(refreshLoading) && (
        <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
      )}
    </div>
  );
}

export default SlideinListElementBig;
