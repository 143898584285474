import React from 'react';
import { List, ListRowProps } from 'react-virtualized';
import Modal from '../../../Common/Components/Modal';
import { AssignedLightingGroupsModalProps } from '../../../types/AssignedLightingGroupsModalProps';

import { ReactComponent as SchedulesIcon } from '../../../img/icons/schedule-large.svg';

const listWidth = 340;
const modalExtHeight = 262;
const rowHeight = 43;
const maxItems = 8;

function AssignedLightingGroupsModal(props: AssignedLightingGroupsModalProps): JSX.Element {
  const {
    setModalOpen,
    groupNames,
    groups,
    groupsLength,
    name,
  } = props;
  const computedHeight = (groups?.length || 0) < maxItems
    ? rowHeight * (groups?.length || 0)
    : rowHeight * maxItems;

  const groupsListRenderer = ({ key, index, style }: ListRowProps) => (
    <div key={key} style={style}>
      <div className="assigned-groups__name">
        {groupNames.get(groups[index])}
      </div>
    </div>
  );

  return (
    <Modal
      title="Assigned lighting groups"
      setModalOpen={setModalOpen}
      width="402"
      height={(computedHeight + modalExtHeight).toString()}
      primaryButtonLabel="Close"
    >
      <div className="assigned-groups">
        <div className="assigned-groups__schedule">
          <SchedulesIcon />
          <div className="margin-left-15">
            <div className="assigned-groups__schedule-text">Schedule</div>
            <div className="assigned-groups__schedule-name">{name}</div>
          </div>
        </div>
        <div className="assigned-groups__section">
          <div>
            <span className="assigned-groups__section-value">{groupsLength}</span>
            {' '}
            Lighting groups
          </div>
        </div>
        {groupsLength ? (
          <div className="assigned-groups__list">
            <List
              width={listWidth}
              height={computedHeight}
              rowHeight={rowHeight}
              rowCount={groups && groupsLength}
              rowRenderer={groupsListRenderer}
              list={groups}
            />
          </div>
        ) : <div>No groups found</div>}
      </div>
    </Modal>
  );
}

export default AssignedLightingGroupsModal;
