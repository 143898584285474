/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component, createRef } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  GridCellProps,
  Index,
  MultiGrid,
} from 'react-virtualized';
import { TableFiltering, TableSelection, TableSorting, TableState, TableType } from '../../types/table';
import { ReactComponent as FilterIcon } from '../../img/icons/filter.svg';
import { ReactComponent as SortIcon } from '../../img/icons/sort.svg';
import { ReactComponent as CloseSmallIcon } from '../../img/icons/close-small.svg';
import Checkbox from './Checkbox';
import Loading from './Loading';
import TableColumnFilterField from './TableColumnFilterField';
import { TableHeadersProp } from '../../types/TableHeadersProp';

const defaultCellWidth = 200;
const tableHeaderHeight = 50;
const tableRowHeight = 40;
const multiSelectKey = 'Shift';

export default class Table extends Component<TableType, TableState> {
  readonly cache: CellMeasurerCache;

  readonly grid: React.RefObject<MultiGrid>;

  constructor(props: TableType) {
    super(props);

    this.grid = createRef<MultiGrid>();
    this.cache = new CellMeasurerCache({
      defaultWidth: defaultCellWidth,
      fixedHeight: true,
    });

    this.state = {
      dataLoaded: this.props.dataFetchedAlready || this.props.data !== undefined || false,
      sorting: { by: '', dir: '' },
      filtering: this.props.initialFiltering ? this.props.initialFiltering : {},
      filteredAndSortedData: this.props.data || [],
      // eslint-disable-next-line react/no-unused-state
      shouldUpdate: {},
      lastSelectedItemId: null,
      shouldMultiSelect: false,
    };

    this.setFiltering = this.setFiltering.bind(this);
    this.setSorting = this.setSorting.bind(this);
    this.cellRenderer = this.cellRenderer.bind(this);
  }

  componentDidMount(): void {
    if (this.props.multiSelect) {
      document.addEventListener('keydown', this.onKeyDown);
      document.addEventListener('keyup', this.onKeyUp);
    }
  }

  componentDidUpdate(prevProps: TableType, prevState: TableState): void {
    if (!this.state.dataLoaded
      && this.props.data !== undefined
      && (prevProps.data === undefined
      || prevProps.data.length === 0)
    ) {
      this.filterAndSortTable(null, null);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dataLoaded: true });
    }

    if (prevProps.data !== this.props.data
      || prevProps.data?.length !== this.props.data?.length) {
      this.filterAndSortTable(null, null);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dataLoaded: true });
    }

    if (prevProps.headers !== this.props.headers) {
      this.cache.clearAll();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        shouldUpdate: {},
      });
    }

    if (this.props.setFilteredCount && prevState.filteredAndSortedData.length !== this.state.filteredAndSortedData.length) {
      this.props.setFilteredCount(this.state.filteredAndSortedData.length);
    }

    if (this.props.colWidthCalcFn) {
      this.grid.current?.recomputeGridSize();
    }
  }

  componentWillUnmount(): void {
    if (this.props.multiSelect) {
      document.removeEventListener('keydown', this.onKeyDown);
      document.removeEventListener('keyup', this.onKeyUp);
    }
  }

  onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === multiSelectKey && !this.state.shouldMultiSelect) {
      this.setState({ shouldMultiSelect: true });
    }
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (event.key === multiSelectKey && this.state.shouldMultiSelect) {
      this.setState({ shouldMultiSelect: false });
    }
  };

  static getNextSortDir(sorting: TableSorting, headerKey: string): string {
    let nextSortDir = 'asc';

    if (sorting.by === headerKey) {
      switch (sorting.dir) {
        case '':
          nextSortDir = 'asc';
          break;
        case 'asc':
          nextSortDir = 'desc';
          break;
        case 'desc':
          nextSortDir = '';
          break;
        default:
          nextSortDir = 'asc';
          break;
      }
    }

    return nextSortDir;
  }

  setFiltering(filtering: TableFiltering): void {
    this.setState({ filtering });
    this.filterAndSortTable(filtering, null);
  }

  setSorting(sorting: TableSorting): void {
    this.setState({ sorting });
    this.filterAndSortTable(null, sorting);
  }

  static getColWidth(headers: TableHeadersProp[], col: number): number {
    return headers[col].width || defaultCellWidth;
  }

  hasSelectionCheckbox = (): boolean =>
    this.props.headers.length > 0
    && this.props.headers[0].key === 'rowSelectCheckbox';

  getUniqueKey = (): string =>
    this.props.headers[0].uniqueKey || '';

  enrichDataWithSelectionState = (data: any[], uniqueKey: string): any[] =>
    data.map((item: any) => ({
      ...item,
      rowSelectCheckboxTableSort: (this.props.selectedItems && this.props.selectedItems.has(item[uniqueKey]) ? 0 : 1),
    }));

  filterAndSortTable(newFilter: TableFiltering | null, newSort: TableSorting | null): void {
    const { data } = this.props;
    const filtering = newFilter || this.state.filtering;
    const sorting = newSort || this.state.sorting;

    if (data && data.length) {
      let tmpData = this.hasSelectionCheckbox()
        ? this.enrichDataWithSelectionState(data, this.getUniqueKey())
        : [...data];

      if (Object.keys(filtering).length) {
        Object.keys(filtering).forEach((filterCol) => {
          tmpData = tmpData.filter(
            (item: any) => {
              let filteredItem = '';

              if (item.customAttributes && filterCol.startsWith('customAttributes_')) {
                const attrKey = filterCol.replace('customAttributes_', '');

                if (item.customAttributes[attrKey]) {
                  filteredItem = item.customAttributes[attrKey];
                }
              } else {
                filteredItem = Object.prototype.hasOwnProperty.call(item, `${filterCol}TableFilter`) ? item[`${filterCol}TableFilter`] : item[filterCol];
              }

              const searchText = filtering[filterCol]?.toString().toLowerCase() || '';

              if (filteredItem === null || filteredItem === undefined) {
                filteredItem = '';
              }

              return filteredItem.toString().toLowerCase().includes(searchText);
            },
          );
        });
      }

      if (sorting.by !== '' && sorting.dir !== '') {
        tmpData.sort((a: any, b: any) => {
          let valA = '';
          let valB = '';

          valA = Object.prototype.hasOwnProperty.call(a, `${sorting.by}TableSort`)
            ? a[`${sorting.by}TableSort`]
            : a[sorting.by] || '';
          valB = Object.prototype.hasOwnProperty.call(b, `${sorting.by}TableSort`)
            ? b[`${sorting.by}TableSort`]
            : b[sorting.by] || '';

          if (valA < valB) {
            return sorting.dir === 'asc' ? -1 : 1;
          }

          if (valA > valB) {
            return sorting.dir === 'asc' ? 1 : -1;
          }

          return 0;
        });
      }

      if (tmpData.length === 0) {
        // adding 1 fake row so scrolling to right will not break
        tmpData.push({ fake: '' });
      }

      this.setState({ filteredAndSortedData: tmpData });
      if (this.props.setFilteredTableData) {
        this.props.setFilteredTableData(tmpData);
      }
    } else {
      this.setState({ filteredAndSortedData: [] });
      if (this.props.setFilteredTableData) {
        this.props.setFilteredTableData([]);
      }
    }
  }

  cellRenderer(cell: GridCellProps): JSX.Element {
    const { columnIndex, rowIndex, key, style, parent } = cell;
    const { headers, cellOnClick, cellOnClickColumns, selectedItems, setSelectedItems = () => undefined, multiSelect } = this.props;
    const { sorting, filtering, filteredAndSortedData, lastSelectedItemId, shouldMultiSelect } = this.state;
    const { uniqueKey = '', headStyle = {}, rowStyle = {} } = headers[columnIndex];
    const nextSortDir = Table.getNextSortDir(sorting, headers[columnIndex].key);
    const sortingFunc = () => {
      this.setSorting({
        by: nextSortDir === '' ? '' : headers[columnIndex].key,
        dir: nextSortDir,
      });
      resetMultiSelection();
    };
    let className = '';
    let extraStyle: Omit<React.CSSProperties, 'padding'> = { paddingLeft: '20px', paddingRight: '20px' };
    let content: JSX.Element | string | null = null;
    let filterContent: JSX.Element | string = '';
    let sortContent: JSX.Element | string = '';
    let innerContent: JSX.Element | string | null = null;
    const extraProps: React.AllHTMLAttributes<HTMLElement> = {};

    const onItemSelected = (checked: boolean, item: any): void => {
      if (selectedItems) {
        setSelectedItems(makeItemSelection(checked, item, selectedItems));
        if (multiSelect) {
          this.setState({ lastSelectedItemId: item[uniqueKey] });
        }
      }
    };

    const makeItemSelection = (checked: boolean, item: any, selItems: TableSelection): TableSelection => {
      const newSelectedItems = new Map(selItems);
      (multiSelect && shouldMultiSelect ? findMultiSelectedItems(item) : [item]).forEach((selItem: any) => {
        if (checked) {
          newSelectedItems.set(selItem[uniqueKey], selItem);
        } else {
          newSelectedItems.delete(selItem[uniqueKey]);
        }
      });
      return newSelectedItems;
    };

    const findMultiSelectedItems = (item: any): any[] => {
      if (lastSelectedItemId === null || findItemIndex(lastSelectedItemId) === -1) {
        return [item];
      }
      const currentIndex = findItemIndex(item[uniqueKey]);
      const lastIndex = findItemIndex(lastSelectedItemId);
      return filteredAndSortedData.slice(
        Math.min(lastIndex, currentIndex),
        Math.max(lastIndex, currentIndex) + 1,
      );
    };

    const findItemIndex = (itemId: string): number =>
      filteredAndSortedData.findIndex((item: any) => item[uniqueKey] === itemId);

    const resetMultiSelection = () => {
      if (multiSelect) {
        this.setState({ lastSelectedItemId: null });
      }
    };

    if (rowIndex === 0) {
      // table header
      filterContent = headers[columnIndex].canFilter !== false ? (
        <button
          type="button"
          className="filter"
          data-testid={`filter ${headers[columnIndex].key}`}
          onClick={() => this.setFiltering({ ...filtering, [headers[columnIndex].key]: '' })}
        >
          <FilterIcon />
        </button>
      ) : (
        ''
      );

      sortContent = headers[columnIndex].canSort !== false ? (
        <button
          type="button"
          className={`sort ${
            sorting.by === headers[columnIndex].key ? sorting.dir : ''
          }`}
          onClick={sortingFunc}
        >
          <SortIcon />
        </button>
      ) : (
        ''
      );

      if (headStyle) {
        extraStyle = { ...extraStyle, ...headStyle };
      }

      className = 'table__header-cell';

      if (headers[columnIndex].key === 'rowSelectCheckbox' && uniqueKey) {
        innerContent = (
          <Checkbox
            dark={this.props.dark || false}
            checked={
              (filteredAndSortedData.length > 0 && filteredAndSortedData.every((item: any) => selectedItems?.has(item[uniqueKey])))
            }
            onClick={(checked) => {
              if (selectedItems && filteredAndSortedData) {
                if (checked) {
                  setSelectedItems(new Map([
                    ...Array.from(selectedItems),
                    ...filteredAndSortedData.map((item: any) => [item[uniqueKey], item]),
                  ]));
                } else {
                  const newSelectedItems = new Map(selectedItems);
                  filteredAndSortedData.forEach(
                    (item: any) => newSelectedItems.delete(item[uniqueKey]),
                  );

                  setSelectedItems(newSelectedItems);
                }
                resetMultiSelection();
              }
            }}
          />
        );
      } else if (filtering[headers[columnIndex].key] !== undefined) {
        const defaultPadding = 10;
        const paddingLeft = parseInt(extraStyle.paddingLeft?.toString().replace('px', '') || '10', 10);
        // const paddingRight = parseInt(extraStyle.paddingRight?.toString().replace('px', '') || '10', 10);
        extraStyle.paddingLeft = `${(paddingLeft - defaultPadding)}px`;

        filterContent = '';
        sortContent = '';
        innerContent = (
          <TableColumnFilterField
            onChange={(event) => {
              this.setFiltering({
                ...filtering,
                [headers[columnIndex].key]: event?.target?.value || '',
              });
              resetMultiSelection();
            }}
            value={filtering[headers[columnIndex].key] || ''}
            icon={<FilterIcon style={{ left: '10px' }} />}
            placeholder={`Enter ${headers[columnIndex].val}`}
            autofocus
            dark={this.props.dark || false}
            close={(
              <span
                className="close"
                style={{ right: '10px', top: 'calc(50% - 8px)' }}
                onClick={() => {
                  const newFiltering = { ...filtering };
                  delete newFiltering[headers[columnIndex].key];
                  this.setFiltering({ ...newFiltering });
                  resetMultiSelection();
                }}
              >
                <CloseSmallIcon />
              </span>
            )}
          />
        );
      } else if (sortContent) {
        innerContent = <span onClick={sortingFunc} className="clickable">{headers[columnIndex].val}</span>;
      } else {
        innerContent = <span>{headers[columnIndex].val}</span>;
      }

      content = (
        <>
          {filterContent}
          {innerContent}
          {sortContent}
        </>
      );
    } else if (filteredAndSortedData && filteredAndSortedData[rowIndex - 1]) {
      // table data
      if (rowStyle) {
        extraStyle = { ...extraStyle, ...rowStyle };
      }

      const item = filteredAndSortedData[rowIndex - 1];
      className = 'table__data-cell';

      if (cellOnClick && cellOnClickColumns?.includes(headers[columnIndex].key)) {
        extraProps.onClick = (event) => cellOnClick(item, event);
      }

      if (headers[columnIndex].key === 'rowSelectCheckbox' && uniqueKey) {
        className += ' table__checkbox';
        const itemId = item[uniqueKey];

        content = (
          <Checkbox
            dark={this.props.dark || false}
            checked={selectedItems?.has(itemId) || false}
            onClick={(checked) => onItemSelected(checked, item)}
          />
        );
      } else if (item.customAttributes && headers[columnIndex].key.startsWith('customAttributes_')) {
        const attrKey = headers[columnIndex].key.replace('customAttributes_', '');

        if (item.customAttributes[attrKey]) {
          content = item.customAttributes[attrKey];
        } else {
          content = item[headers[columnIndex].key];
        }
      } else {
        content = item[headers[columnIndex].key];
      }
    } else {
      content = '';
    }

    if (this.props.skipCellMeasure) {
      return (
        <div
          key={key}
          className={className}
          style={{
            ...style,
            ...extraStyle,
          }}
          {...extraProps}
        >
          {content}
        </div>
      );
    }

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        {({ registerChild }) => (
          <div
            ref={registerChild as (instance: HTMLDivElement | null) => void}
            className={className}
            style={{
              ...style,
              ...extraStyle,
            }}
            {...extraProps}
          >
            {content}
          </div>
        )}
      </CellMeasurer>
    );
  }

  colWidthFn(col: Index, width: number): number {
    if (this.props.colWidthCalcFn) {
      return this.props.colWidthCalcFn(col.index, width);
    }

    if (this.props.skipCellMeasure) {
      return Table.getColWidth(this.props.headers, col.index);
    }

    return defaultCellWidth;
  }

  render(): JSX.Element {
    const { filteredAndSortedData } = this.state;

    if (!this.state.dataLoaded || filteredAndSortedData === undefined) {
      return (this.props.noContent || <Loading isLoading caller={this.props.caller} expectedCount={this.props.expectedCount} />);
    }

    return (
      <AutoSizer>
        {({ height, width }) => {
          const rowsHeight = tableRowHeight * (filteredAndSortedData.length + 1) + 26;
          const tableHeight = rowsHeight < height ? rowsHeight : height;

          return (
            <MultiGrid
              ref={this.grid}
              filteredAndSortedData={filteredAndSortedData} // for update
              selectedItems={this.props.selectedItems} // for update
              cellRenderer={this.cellRenderer}
              deferredMeasurementCache={this.props.skipCellMeasure ? undefined : this.cache}
              columnCount={this.props.headers.length}
              rowCount={filteredAndSortedData.length + 1}
              fixedColumnCount={this.props.fixColCount || 0}
              fixedRowCount={this.props.fixRowCount || 1}
              width={width || 500}
              height={this.props.autoheight ? tableHeight : height || 500}
              columnWidth={this.props.skipCellMeasure ? (col) => this.colWidthFn(col, width) : this.cache.columnWidth}
              rowHeight={({ index }) => (index === 0 ? tableHeaderHeight : tableRowHeight)}
              estimatedRowSize={40}
              classNameTopLeftGrid="table__header shadow"
              classNameTopRightGrid="table__header"
              classNameBottomLeftGrid="table__data shadow"
              classNameBottomRightGrid="table__data"
              enableFixedColumnScroll
            />
          );
        }}
      </AutoSizer>
    );
  }
}
