import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import SelectBox from '../../../Common/Components/SelectBox';
import SlideIn from '../../../Common/Components/SlideIn';
import Loading from '../../../Common/Components/Loading';
import SlideinInfoAlert from '../Components/SlideinInfoAlert';
import LightsAlarmsModal from './Components/LightsAlarmsModal';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { SiteObject } from '../../../types/SiteObject';
import { AlertObject, AlertNewObject } from '../../../types/AlertObject';
import Utils from '../../../utils/Utils';
import { getRequest } from '../../../utils/fetch';
import AlarmPlaybook from '../../Alarms/Components/AlarmPlaybook';

const alarmsMultiSlideInWidth = 450;

function LightsAlarmsMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems } = props;

  const [openConfirmDismissAlert, setOpenConfirmDismissAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<AlertNewObject>();
  const [alerts, setAlerts] = useState<AlertNewObject[] | undefined>(undefined);
  const [alertDismissArray, setAlertDismissArray] = useState<Array<string> | undefined>(undefined);
  const [openAlarmPlaybook, setOpenAlarmPlaybook] = useState(0);
  const [update, setUpdate] = useState({});

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(selectedCustomer.id ? [`/organizations/${selectedCustomer.id}/sites`, 'GetSites'] : null);
  const site = useMemo(() => sitesResp?.find((item) => item.siteid === selectedSite.id), [sitesResp, selectedSite.id]);

  const timestamp = useRef<number>(0);
  const emptyAlerts = () => {
    setAlerts(undefined);
    setSorted(undefined);
    setUpdate({});
  };

  useEffect(() => {
    (async () => {
      const currentDate = Date.now();
      timestamp.current = currentDate;

      const path = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/alerts?activeOnly=true&withDisplay=true`;
      const idlist = Array.from(selectedItems.values()).map((node) => (node.nodeid)).join(',');
      const options: RequestInit = {};
      options.headers = new Headers();
      options.headers.set('x-node-ids', idlist);
      const resp: AlertObject[] = await getRequest(path, options);

      let alertList: AlertObject[] = [];

      if (timestamp.current <= currentDate) {
        alertList = resp;
        const newAlertList = alertList.filter((alert) => !alert.clearedAt)
          .sort((a: AlertObject, b: AlertObject) => {
            const firstAlertName = a.name || '';
            const secondAlertName = b.name || '';
            return firstAlertName.localeCompare(secondAlertName);
          });
        const newList: Array<AlertNewObject> = [];

        if (newAlertList !== undefined) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < newAlertList.length; i++) {
            const createdAt = Utils.convertISOtoAlarmTime(newAlertList[i].raisedAt as unknown as string, site?.time_zone);
            const updatedAt = Utils.convertISOtoAlarmTime(newAlertList[i].clearedAt as unknown as string, site?.time_zone);

            if (i !== 0 && newAlertList[i].name === newAlertList[i - 1].name) {
              const newListCurrentIndex = newList.length - 1;

              if (newList[i - 1]) {
                // eslint-disable-next-line max-len
                newList[i - 1].nodeData.push({
                  id: newAlertList[i].nodeId,
                  alertid: newAlertList[i].id,
                  description: newAlertList[i].description || (newAlertList[i].details as Record<string, string>).message || '',
                  dismissed: newAlertList[i].dismissedAt !== undefined && newAlertList[i].dismissedAt !== '',
                  severity: newAlertList[i].severity,
                  createdAt,
                  updatedAt,
                });
              } else {
                // eslint-disable-next-line max-len
                newList[newListCurrentIndex].nodeData.push({
                  id: newAlertList[i].nodeId,
                  alertid: newAlertList[i].id,
                  description: newAlertList[i].description || (newAlertList[i].details as Record<string, string>).message || '',
                  dismissed: newAlertList[i].dismissedAt !== undefined && newAlertList[i].dismissedAt !== '',
                  severity: newAlertList[i].severity,
                  createdAt,
                  updatedAt,
                });
              }
            } else {
              const obj: AlertNewObject = {
                severity: newAlertList[i].severity,
                name: newAlertList[i].name || '',
                type: newAlertList[i].type,
                action: newAlertList[i].action || '',
                nodeData: [
                  {
                    id: newAlertList[i].nodeId,
                    alertid: newAlertList[i].id,
                    description: newAlertList[i].description || (newAlertList[i].details as Record<string, string>).message || '',
                    dismissed: newAlertList[i].dismissedAt !== undefined && newAlertList[i].dismissedAt !== '',
                    severity: newAlertList[i].severity,
                    createdAt,
                    updatedAt,
                  },
                ],
              };

              newList.push(obj);
            }
          }
        }

        setSorted(newList);
        setAlerts(newList);
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, selectedItems]);

  const filters = [
    {
      title: 'Date',
      key: 'updated',
    },
    {
      title: 'Node ID',
      key: 'nodeid',
    },
    {
      title: 'Alarm name',
      key: 'ufname',
    },
    {
      title: 'Status',
      key: 'severity',
    },
  ];

  const [filter, setFilter] = useState({ key: 'date', title: 'Sort by' });
  const [sorted, setSorted] = useState(alerts);
  const sortBy = (filterItem: {key: string; title: string}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortedArray = [...alerts as AlertNewObject[]].sort((a: any, b: any) => {
      const valA = a[filterItem.key];
      const valB = b[filterItem.key];

      if (valA < valB) {
        return filterItem.key === 'date' ? 1 : -1;
      }

      if (valA > valB) {
        return filterItem.key === 'date' ? -1 : 1;
      }

      return 0;
    });

    setFilter(filterItem);
    setSorted(sortedArray);
  };

  return (
    <div className="alarms-multi">
      <SlideIn slideInWidth={alarmsMultiSlideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Alarms</span>
            <div className="slide-in__actions">
              <SelectBox
                type="dark"
                title={filter.title}
                list={
                  (filters && Utils.arrayToSelectbox(filters, 'key', 'title')) || []
                }
                onClick={(item: SelectBoxItemType) => {
                  sortBy(item);
                }}
              />
            </div>
          </div>
          <div className="slide-in__content">
            {sorted !== undefined
              ? (
                <SlideinInfoAlert
                  alerts={sorted}
                  setOpenConfirmDismissAlert={setOpenConfirmDismissAlert}
                  setAlertObj={setAlertObj}
                  setAlertDismissArray={setAlertDismissArray}
                  setOpenAlarmPlaybook={() => setOpenAlarmPlaybook(1)}
                />
              )
              : <Loading />}
          </div>
          { openConfirmDismissAlert && (
            <LightsAlarmsModal
              setOpenConfirmDismissAlert={setOpenConfirmDismissAlert}
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              alertObj={alertObj}
              alertDismissArray={alertDismissArray}
              refreshAlertsList={emptyAlerts}
              setLoader={() => setSorted(undefined)}
            />
          )}
          {openAlarmPlaybook === 1 && alertObj && (
          <AlarmPlaybook
            alarm={alertObj}
            setShowModal={setOpenAlarmPlaybook}
          />
          )}
        </>
      </SlideIn>
    </div>
  );
}

export default LightsAlarmsMulti;
