import React from 'react';

import { TableFooterProps } from '../../types/TableFooterProps';

function TableFooter(props: TableFooterProps): JSX.Element {
  const { children } = props;
  return (
    <div className="table-footer">
      { children }
    </div>
  );
}

export default TableFooter;
