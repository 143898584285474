import React from 'react';

import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { ReactComponent as WarningIcon } from '../../../img/icons/warning.svg';
import { GroupActionSubmitInfoProps } from '../../../types/GroupActionsPropTypes';

function GroupActionSubmitInfo(props: GroupActionSubmitInfoProps): JSX.Element {
  const { isVisible } = props;
  return (
    <div className={`submit-info ${isVisible ? 'submit-info--visible' : ''}`}>
      <div className="submit-info-info">
        <InfoIcon className="icon" />
        <div>
          <div>
            A job will start to perform this action.
          </div>
          <div>
            You can monitor the job on the Jobs page.
          </div>
        </div>
      </div>
      <div className="submit-info-warning">
        <WarningIcon className="icon" />
        <div>
          <div>
            Please allow up to
            <span className="submit-info-warning-bold"> 3 days </span>
            for the
          </div>
          <div>
            nodes to receive their new schedule.
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupActionSubmitInfo;
