/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import DropDown from '../../../Common/Components/DropDown';
import { RecentlyViewedSitesProps, RecentlyViewedSitesObject } from '../../../types/RecentlyViewedSites';
import Utils from '../../../utils/Utils';
import RecentlyViewedSiteItem from './RecentlyViewedSiteItem';

function RecentlyViewedSites(props: RecentlyViewedSitesProps): JSX.Element {
  const { selectedSite, setSelectedSite, setSelectedCustomer } = props;
  const storeKey = `recentSites-${Utils.getApiHost()}-${Utils.getUserID()}`;
  const recentlyViewed: RecentlyViewedSitesObject[] = JSON.parse(localStorage.getItem(storeKey) || '[]');

  return (
    <DropDown title="Recently viewed sites" className="recently-viewed__dropdown">
      <div className="recently-viewed__items">
        {
          recentlyViewed && recentlyViewed.map(
            (site) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                data-testid="recently-viewed-item"
                key={site.siteid}
                onClick={() => {
                  setSelectedCustomer({ id: site.orgid, name: site.orgname });
                  setSelectedSite({ id: site.siteid, name: site.name });
                }}
              >
                <RecentlyViewedSiteItem
                  selectedSite={selectedSite}
                  site={site}
                />
              </div>
            ),
          )
          }
      </div>
    </DropDown>
  );
}

export default RecentlyViewedSites;
