/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ScheduleEventActionPoint } from '../../../types/ScheduleObject';
import { DimmingPointsTimelineProps } from '../../../types/DimmingPointsTimelineProps';
import DimmingPointBtn from './DimmingPointBtn';
import Utils from '../../../utils/Utils';
import CanvasUtils from '../../../utils/CanvasUtils';
import { fixSunrise, fixSunset } from '../../../utils/constants';
import { ReactComponent as TrashIcon } from '../../../img/icons/trash.svg';

function DimmingPointsTimeline(props: DimmingPointsTimelineProps): JSX.Element {
  const { draggedDimmingPoint, setDraggedDimmingPoint, scheduleData, setScheduleData, selectedSequenceIndex, isReadOnly } = props;

  const yAxisList = ['100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%', '0%'];
  const xAxisList = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm', '12am'];

  const [newActionPoint, setNewActionPoint] = useState<ScheduleEventActionPoint>();
  const [tooltip, setTooltip] = useState<{ x: number | undefined, y: number | undefined }>({ x: undefined, y: undefined });

  const getCanvasSizes = () => {
    // editbox on the right + timeline margin + page margins + timeline padding
    const fixSizes = 262 + 20 + 75 + 20;
    const canvasWidth = window.innerWidth - fixSizes;
    const gridSize = (canvasWidth - 21) / 24;
    const canvasHeight = (10 * gridSize) + 21;

    return {
      canvasWidth,
      gridSize,
      canvasHeight,
    };
  };

  const canvas = useRef<HTMLCanvasElement>(null);
  const [canvasWidth, setCanvasWidth] = useState(getCanvasSizes().canvasWidth);
  const canvasMargin = 10.5;
  const [gridSize, setGridSize] = useState(getCanvasSizes().gridSize);
  const [canvasHeight, setCanvasHeight] = useState(getCanvasSizes().canvasHeight);
  const minsInDay = 1440;
  const percent = 100;

  const handleResize = () => {
    setCanvasWidth(getCanvasSizes().canvasWidth);
    setGridSize(getCanvasSizes().gridSize);
    setCanvasHeight(getCanvasSizes().canvasHeight);
  };

  window.addEventListener('resize', handleResize);

  const dimmingPoints: ScheduleEventActionPoint[] = useMemo(
    () => scheduleData.events[selectedSequenceIndex]?.actions?.map((action) => ({
      action,
      dragged: false,
      x: Math.ceil((Utils.parseScheduleActionTime(action.time, { sunrise: fixSunrise, sunset: fixSunset }) / minsInDay) * (canvasWidth - (2 * canvasMargin))),
      y: Math.ceil(((percent - action.level) / percent) * (canvasHeight - (2 * canvasMargin))),
    })).sort((a, b) => a.x - b.x),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [draggedDimmingPoint, canvasWidth, canvasHeight, scheduleData, selectedSequenceIndex],
  );

  const tmpDimmingPoints: ScheduleEventActionPoint[] = useMemo(() => {
    if (newActionPoint) {
      const tmpArr = [...dimmingPoints];

      if (draggedDimmingPoint) {
        if (draggedDimmingPoint.editing) {
          tmpArr[draggedDimmingPoint.index] = newActionPoint;
        } else {
          tmpArr.push(newActionPoint);
        }
      }

      return tmpArr.map((actionPoint) => ({
        action: actionPoint.action,
        dragged: actionPoint.dragged,
        x: actionPoint.x,
        y: actionPoint.y,
      })).sort((a, b) => a.x - b.x);
    }

    return dimmingPoints;
  }, [draggedDimmingPoint, newActionPoint, dimmingPoints]);

  useEffect(() => {
    // high dpi canvas
    if (canvas.current) {
      const ctx = canvas.current.getContext('2d');

      if (ctx) {
        canvas.current.style.width = `${canvasWidth}px`;
        canvas.current.style.height = `${canvasHeight}px`;
        const scale = window.devicePixelRatio;
        canvas.current.width = Math.floor(canvasWidth * scale);
        canvas.current.height = Math.floor(canvasHeight * scale);
        ctx.scale(scale, scale);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, canvasWidth, canvasHeight, gridSize]);

  useEffect(() => {
    if (canvas.current) {
      const ctx = canvas.current.getContext('2d');

      if (ctx) {
        ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
        CanvasUtils.drawScheduleBgAndGrid(ctx, canvas.current.width, canvas.current.height, gridSize, canvasMargin);
        CanvasUtils.drawScheduleDimmingPoints(ctx, canvas.current.width, canvas.current.height, canvasMargin, tmpDimmingPoints);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpDimmingPoints, canvasWidth, canvasHeight, gridSize]);

  const dragEnd = () => {
    if (!isReadOnly && draggedDimmingPoint !== undefined) {
      setDraggedDimmingPoint(undefined);
      setNewActionPoint(undefined);
      setScheduleData((oldSchedule) => {
        const newSchedule = { ...oldSchedule };

        newSchedule.events = newSchedule.events.map((oldEvent, index) => {
          const newEvent = { ...oldEvent };
          if (index === selectedSequenceIndex) {
            newEvent.actions = tmpDimmingPoints.map((actionPoint) => actionPoint.action);
          }

          return newEvent;
        });

        return newSchedule;
      });
    }
  };

  const nightGridSize = 6;
  const dayGridSize = 10;

  return (
    <div className="timeline">
      <div className="timeline__uppertext">
        <div style={{ width: `${(nightGridSize * gridSize) + canvasMargin}px` }}>Night</div>
        <div style={{ width: `${gridSize}px` }}>Dawn</div>
        <div style={{ width: `${dayGridSize * gridSize}px` }}>Day</div>
        <div style={{ width: `${gridSize}px` }}>Dusk</div>
        <div style={{ width: `${(nightGridSize * gridSize) + canvasMargin}px` }}>Night</div>
      </div>
      <canvas ref={canvas} />
      <div
        className="timeline__points"
        style={{ width: canvasWidth, height: canvasHeight }}
        onMouseMove={(event: React.MouseEvent) => {
          if (!isReadOnly && draggedDimmingPoint !== undefined) {
            const calcAction = Utils.calcActionByCanvas(event, draggedDimmingPoint, tmpDimmingPoints, canvasWidth, canvasHeight, canvasMargin);

            if (calcAction && (newActionPoint?.action.level !== calcAction.action.level || newActionPoint?.action.time !== calcAction.action.time)) {
              setNewActionPoint(calcAction);
            }

            if (tooltip.x && tooltip.y) {
              setTooltip({ x: undefined, y: undefined });
            }
          }
        }}
        onMouseUp={dragEnd}
        onClick={() => {
          setTooltip({ x: undefined, y: undefined });
        }}
      >
        {draggedDimmingPoint?.index !== undefined && dimmingPoints[draggedDimmingPoint.index] && (
          <div className="ghost-dimmingpoint" style={{ top: dimmingPoints[draggedDimmingPoint.index].y, left: dimmingPoints[draggedDimmingPoint.index].x }} />
        )}
        {tmpDimmingPoints.map((point, index) => {
          const pointTooltip = tooltip.x === point.x && tooltip.y === point.y;

          return (
            <div
              key={`${index}-dimmingpoint`}
              style={{ top: point.y, left: point.x }}
              onMouseDown={() => {
                if (!isReadOnly && draggedDimmingPoint === undefined) {
                  const type = point.action.time.includes('sun') ? 'sunsetsunrise' : 'fixed';
                  setDraggedDimmingPoint({ photocell: point.action.photocell_enabled, type, index, editing: true });
                }
              }}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();

                if (pointTooltip) {
                  setTooltip({ x: undefined, y: undefined });
                } else {
                  setTooltip({ x: point.x, y: point.y });
                }
              }}
            >
              <div className="spec-tooltip">
                <DimmingPointBtn
                  type={point.action.time.includes('sun') ? 'sunsetsunrise' : 'fixed'}
                  photocell={point.action.photocell_enabled}
                  onClick={() => undefined}
                  index={index}
                  editing
                />
                <div className={`spec-tooltip__element top timeline__bg-tooltip ${point.dragged || pointTooltip ? 'tooltip-show' : ''}`}>
                  <div className="spec-tooltip__element-data">
                    <div>
                      <span className="tooltip-title">{point.action.photocell_enabled ? 'Photocell-controlled:' : 'Start:'}</span>
                      <span className="tooltip-title">{point.action.photocell_enabled ? 'Max driver level:' : 'Driver level:'}</span>
                    </div>
                    <div>
                      <span className="tooltip-value">{Utils.convertTimeForTooltip(point.action.time)}</span>
                      <span className="tooltip-value">
                        {point.action.level}
                        %
                      </span>
                    </div>
                  </div>
                  {!isReadOnly && pointTooltip && (
                    <div
                      className="spec-tooltip__element-delete"
                      onMouseDown={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        setDraggedDimmingPoint(undefined);
                        setNewActionPoint(undefined);
                        setScheduleData((oldSchedule) => {
                          const newSchedule = { ...oldSchedule };

                          newSchedule.events = newSchedule.events.map((oldEvent, eventIndex) => {
                            const newEvent = { ...oldEvent };
                            if (eventIndex === selectedSequenceIndex) {
                              newEvent.actions = tmpDimmingPoints
                                .filter((actionPoint) => actionPoint.x !== point.x && actionPoint.y !== point.y)
                                .map((actionPoint) => actionPoint.action);
                            }

                            return newEvent;
                          });

                          return newSchedule;
                        });
                      }}
                    >
                      <TrashIcon />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="timeline__y">
        <div>Driver level</div>
        {yAxisList.map((driverLevel, i) => {
          const style = {
            marginTop: i === 0 ? 15 : 0,
            height: `${gridSize}px`,
          };
          return (<div key={`${i}-${driverLevel}`} style={style}>{driverLevel}</div>);
        })}
      </div>
      <div className="timeline__x">
        {xAxisList.map((hour, i) => {
          const style = {
            width: i === (xAxisList.length - 1) ? 'fit-content' : gridSize,
          };
          return (<div key={`${i}-${hour}`} style={style}>{hour}</div>);
        })}
      </div>
    </div>
  );
}

export default DimmingPointsTimeline;
