/* eslint-disable react/no-unused-state */
import React, { Component, ReactNode, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AddNotificationProps, AppContextProps, NotificationsObject } from '../types/AppContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppContext = React.createContext<AppContextProps>([] as any);

export default AppContext;
export const useAppContext = (): AppContextProps => useContext(AppContext);

export class AppContextProvider extends Component<
  { children: ReactNode },
  { notifications: Array<NotificationsObject>,
    addNotification: (item: AddNotificationProps) => void,
    deleteNotification: (id: string) => void,
    setGlobalTooltipMode: (newVal: boolean) => void,
    globalTooltipMode: boolean,
  }
> {
  private displayTime: Record<string, number> = {
    success: 5000,
    error: 10000,
    warning: 5000,
    info: 5000,
  };

  constructor(props: { children: ReactNode }) {
    super(props);

    this.state = {
      notifications: [],
      addNotification: this.addNotification,
      deleteNotification: this.deleteNotification,
      setGlobalTooltipMode: this.setGlobalTooltipMode,
      globalTooltipMode: false,
    };

    this.addNotification = this.addNotification.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
    this.setGlobalTooltipMode = this.setGlobalTooltipMode.bind(this);
  }

  setGlobalTooltipMode = (newVal: boolean): void => {
    this.setState({ globalTooltipMode: newVal });
  };

  addNotification = (item: AddNotificationProps): void => {
    const id = uuidv4();
    this.setState((prevState) => ({
      notifications: [...prevState.notifications, {
        ...item,
        id,
        timer: setTimeout(
          () => this.deleteNotification(id),
          this.displayTime[item.type],
        ),
      }],
    }));
  };

  deleteNotification = (id: string): void => {
    const { notifications } = this.state;

    clearTimeout(
      notifications.find((item: { id: string }) => id === item.id)?.timer,
    );

    this.setState((prevState) => ({
      notifications: prevState.notifications.filter((item: { id: string }) => id !== item.id),
    }));
  };

  render(): JSX.Element {
    const { children } = this.props;

    return (
      <AppContext.Provider value={this.state}>
        {children}
      </AppContext.Provider>
    );
  }
}
