import React from 'react';
import { NavLink } from 'react-router-dom';
import { HeaderItemPropsType } from '../../../types/HeaderItemPropsType';

function HeaderItem(props: HeaderItemPropsType): JSX.Element {
  const { children, link, isDisabled } = props;

  return (
    <li className="header__list-item" data-testid={link.replace('/', '')}>
      <NavLink
        to={isDisabled ? '' : link}
        activeClassName="active"
        exact
        className={`header__list-item-link ${isDisabled ? 'disabled' : ''}`}
      >
        { children }
      </NavLink>
    </li>
  );
}

export default HeaderItem;
