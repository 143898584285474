/* eslint-disable max-len */
import React from 'react';
import Modal from '../../../../../Common/Components/Modal';

import { CopyProjectKeyProps } from '../../../../../types/CopyProjectKeyProps';
import { ReactComponent as NotificationIcon } from '../../../../../img/icons/notification.svg';
import Utils from '../../../../../utils/Utils';

function CopyProjectApiKey(props: CopyProjectKeyProps): JSX.Element {
  const {
    modalOpen,
    copyApiKey,
  } = props;
  return (
    <Modal
      width="550"
      setModalOpen={modalOpen}
      title="New Key has been created"
      primaryButtonAction={() => Utils.copyToClipboard(JSON.stringify(copyApiKey, undefined, '\t'))}
      primaryButtonLabel="Copy JSON to clipboard"
      secondaryButtonAction={() => modalOpen(false)}
      secondaryButtonLabel="Close"
      className="admin-user-modal"
    >
      <div className="admin-project-modal-content">
        <div className="copyKeyWarning">
          <NotificationIcon className="admin-user-modal-content-icon" />
          &nbsp; After closing this dialogue, you won’t be able to display this key anymore, as we’re only storing the hash version (for safety reasons). Please make sure you’re keeping it safe and send only to authorised personnel.
        </div>
        <div className="copyKeyWrapper">
          {JSON.stringify(copyApiKey, undefined, '\t')}
        </div>
      </div>
    </Modal>
  );
}

export default CopyProjectApiKey;
