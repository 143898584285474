/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
// import { Button } from '@verizon/react-component-lib/dist';
import { ModalType } from '../../types/modal';
import { ReactComponent as CloseIcon } from '../../img/icons/close.svg';
import Button from './Button';

function Modal(props: ModalType): JSX.Element {
  const {
    title,
    subtitle,
    customTitle,
    width,
    widthType,
    height,
    heightType,
    children,
    className = '',
    secondaryButtonLabel,
    secondaryButtonAction,
    secondaryButtonDisabled = false,
    primaryButtonLabel,
    primaryButtonAction,
    primaryButtonDisabled = false,
    tertiaryButtonLabel,
    tertiaryButtonAction,
    tertiaryButtonDisabled = false,
    setModalOpen,
    borderTopColor,
    hasTable = false,
  } = props;

  const modalSize = {
    width: width ? `${width}${widthType || 'px'}` : 'auto',
    height: height ? `${height}${heightType || 'px'}` : 'auto',
    // eslint-disable-next-line no-nested-ternary
    left: (width !== undefined && Number(width)
      ? (widthType === '%' ? `${50 - Number(width) / 2}%` : `calc(50% - ${Number(width) / 2}${widthType || 'px'})`)
      : '50%'),
    transform: widthType !== '%' && (width === undefined || !Number(width)) ? 'translate(-50%, 0)' : undefined,
  };

  const closeModal = () => {
    if (setModalOpen) {
      setModalOpen(false);
    }
  };

  let classNames = className;
  classNames += borderTopColor ? ` modal-bordertop-${borderTopColor}` : '';

  return (
    <>
      <div style={modalSize} className={`modal ${classNames}`} data-testid="modal-component">
        <button type="button" onClick={() => closeModal()} data-testid="modal-close" className="modal__close-button"><CloseIcon /></button>
        {customTitle || (
          <>
            {title && <h2 className="modal__title">{title}</h2>}
            {subtitle && <h2 className="modal__subtitle">{subtitle}</h2>}
          </>
        )}
        {children && (
          <div className={`modal__content ${hasTable ? 'modal__content-has-table' : ''}`}>
            {children}
          </div>
        )}
        <div className="modal__footer">
          {tertiaryButtonLabel && (
            <Button
              extraClasses="modal__secondary-button btn-secondary btn-medium margin-right-15"
              disabled={tertiaryButtonDisabled}
              label={tertiaryButtonLabel}
              data-testid={tertiaryButtonLabel}
              buttonType="secondary"
              buttonSize="medium"
              onClick={tertiaryButtonAction
                ? (event) => tertiaryButtonAction(event)
                : () => closeModal()}
            />
          )}
          {secondaryButtonLabel && (
            <Button
              disabled={secondaryButtonDisabled}
              onClick={secondaryButtonAction
                ? (event) => secondaryButtonAction(event)
                : () => closeModal()}
              label={secondaryButtonLabel}
              data-testid={secondaryButtonLabel}
              buttonType="secondary"
              buttonSize="medium"
              extraClasses="margin-right-15"
            />
          )}
          {primaryButtonLabel && (
            <Button
              disabled={primaryButtonDisabled}
              onClick={primaryButtonAction
                ? (event) => primaryButtonAction(event)
                : () => closeModal()}
              label={primaryButtonLabel}
              data-testid={primaryButtonLabel}
              buttonType="primary"
              buttonSize="medium"
            />
          )}
        </div>
      </div>
      <div className="modal__backdrop" />
    </>
  );
}

export default Modal;
