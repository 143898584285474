import React, { useMemo, useState } from 'react';
import { ChartDataSets } from 'chart.js';

import { DateTime } from 'luxon';
import SelectBox from '../../../Common/Components/SelectBox';
import Button from '../../../Common/Components/Button';
import { PlotSensorProps } from '../../../types/PlotSensorProps';
import Sensors from '../../../utils/Sensors';
import { useAppContext } from '../../../utils/AppContext';

import { ReactComponent as CloseIcon } from '../../../img/icons/close.svg';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SensorReportResponseItem } from '../../../types/SensorRequestPayload.d';
import LightingChartModal from './LightingChartModal';

const selectBoxWidth = 200;

function PlotSensor(props: PlotSensorProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
    selectedItems,
    closeButton = false,
    closeButtonAction,
    listWidth = selectBoxWidth,
  } = props;

  const { addNotification } = useAppContext();

  const sensorList = [
    { title: 'Energy', key: '1' },
    { title: 'Energy, driver', key: '2' },
    { title: 'Power, driver', key: '3' },
    { title: 'Energy, power', key: '4' },
  ];

  const timeFrameList = [
    { title: 'Last 24 hours', key: '1' },
    { title: 'Last 7 days', key: '2' },
    { title: 'Last 15 days', key: '3' },
    { title: 'Last 30 days', key: '4' },
  ];

  const [sensor, setSelectedSensor] = useState({ title: 'Select', key: '0' });
  const [timeFrame, setSelectedTimeFrame] = useState({ title: 'Select', key: '0' });
  const [chartModalToggle, setChartModalToggle] = useState(false);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [chartDataset, setChartDataset] = useState<ChartDataSets[]>();
  const [rawChartDataset, setRawChartDataset] = useState<SensorReportResponseItem[]>();

  const { sensor1, sensor2 }: { sensor1: SelectBoxItemType, sensor2: SelectBoxItemType } = useMemo(() => {
    switch (sensor.key) {
      case '1': return {
        sensor1: { key: 'cumulativeEnergyUse', title: 'Active energy' },
        sensor2: { key: '0', title: 'Select' },
      };
      case '2': return {
        sensor1: { key: 'cumulativeEnergyUse', title: 'Active energy' },
        sensor2: { key: 'driverLevel', title: 'Driver level' },
      };
      case '3': return {
        sensor1: { key: 'activePower', title: 'Active power' },
        sensor2: { key: 'driverLevel', title: 'Driver level' },
      };
      case '4': return {
        sensor1: { key: 'cumulativeEnergyUse', title: 'Active energy' },
        sensor2: { key: 'activePower', title: 'Active power' },
      };
      default: return {
        sensor1: { key: '0', title: 'Select' },
        sensor2: { key: '0', title: 'Select' },
      };
    }
  }, [sensor]);

  const { startDate, endDate }: { startDate: Date, endDate: Date } = useMemo(() => {
    const end = DateTime.now();
    const week = 7;
    const biweek = 15;
    const month = 29;

    let daysMinus = 1;

    if (timeFrame.key === '2') {
      daysMinus = week;
    } else if (timeFrame.key === '3') {
      daysMinus = biweek;
    } else if (timeFrame.key === '4') {
      daysMinus = month;
    }

    return {
      startDate: end.minus({ days: daysMinus }).toJSDate(),
      endDate: end.toJSDate(),
    };
  }, [timeFrame]);

  const fetchAndDisplayChartData = (
    newStartDate?: Date,
    newEndDate?: Date,
    newSensor1?: SelectBoxItemType,
    newSensor2?: SelectBoxItemType,
  ) => {
    Sensors.fetchAndDisplayChartData(
      newStartDate || startDate,
      newEndDate || endDate,
      selectedCustomer,
      selectedSite,
      selectedItems,
      newSensor1 || sensor1,
      newSensor2 || sensor2,
      setChartDataset,
      setRawChartDataset,
      setChartModalToggle,
      setWaiting,
      addNotification,
    );
  };

  return (
    <div className="plot-sensors">
      {closeButton
      && (
        <button
          type="button"
          className="plot-sensors__close-button"
          onClick={closeButtonAction}
        >
          <CloseIcon />
        </button>
      )}
      <div className="plot-sensors__title">
        Plot sensor values
      </div>
      <SelectBox
        label="Sensors"
        type="dark"
        list={sensorList}
        onClick={setSelectedSensor}
        title={sensor.title}
        selectedItemKey={sensor.key}
        listWidth={listWidth}
      />
      <SelectBox
        label="Timeframe"
        type="dark"
        list={timeFrameList}
        onClick={setSelectedTimeFrame}
        title={timeFrame.title}
        selectedItemKey={timeFrame.key}
        listWidth={listWidth}
      />
      <div className="slide-in__link">
        <Button onClick={() => fetchAndDisplayChartData()} label="Create chart" />
      </div>
      {chartModalToggle
      && (
        <div className="chart-modal">
          <LightingChartModal
            site={selectedSite}
            chartDataset={chartDataset || []}
            rawChartDataset={rawChartDataset || []}
            chartProps={{
              sensor1,
              sensor2,
              startDate,
              endDate,
              sensorList,
            }}
            fetchAndDisplayChartData={fetchAndDisplayChartData}
            setChartModalToggle={setChartModalToggle}
            isLoading={waiting}
          />
        </div>
      )}
    </div>
  );
}

export default PlotSensor;
