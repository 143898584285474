import React, { useState } from 'react';
import {
  List,
  AutoSizer,
  ListRowProps,
} from 'react-virtualized';
import { JobsListPropsType } from '../../../types/JobsList';
import { JobObject } from '../../../types/JobObject';
import { jobTypeMsg } from '../../../utils/constants';
// import getHeaderProps from '../../../utils/getHeaderProps';

import Utils from '../../../utils/Utils';

import { ReactComponent as JobSuccessIcon } from '../../../img/icons/job-success.svg';
import { ReactComponent as JobWarningIcon } from '../../../img/icons/job-warning.svg';
import { ReactComponent as JobErrorIcon } from '../../../img/icons/job-error.svg';
import { ReactComponent as JobSyncIcon } from '../../../img/icons/job-sync.svg';
import { ReactComponent as JobStoppedIcon } from '../../../img/icons/job-stopped.svg';
import { ReactComponent as JobStoppedSolidIcon } from '../../../img/icons/job-stopped-solid.svg';
import JobStatus from './JobStatus';
import JobDetails from './JobDetails';
// import FirmwareJobDetails from './FirmwareJobDetails';
import StopJob from './StopJob';
import CopyJobId from './CopyJobId';

const jobsWithMoreThanOneNode = [
  'ADD_NODES',
  'CREATE_GROUP',
  'DELETE_GROUP',
  'UPDATE_GROUP',
  'REMOVE_NODES',
  'DELETE_NODES',
  'UPDATE_FIRMWARE_SITE',
  'UPDATE_FIRMWARE_GROUP',
  'ASSIGN_NODE_TO_SITE',
];

function JobsList(props: JobsListPropsType): JSX.Element {
  const {
    headers,
    filteredjobs,
    site,
    customer,
  } = props;

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showStopJobModal, setShowStopJobModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState<JobObject>();

  const rowHeight = 75;

  function rowRenderer({ key, index, style, parent }: ListRowProps) {
    const job = filteredjobs[index];

    return (
      <div key={key} style={style}>
        <div className="jobs-list-item">
          <div className="jobs-list-item__type">
            <CopyJobId
              text1="Click to copy Job ID"
              text2="Job ID copied to clipboard!"
              value={job.id}
            />
            <span>{jobTypeMsg[job.operationType]}</span>
          </div>
          {job.operationType.indexOf('UPDATE_FIRMWARE') === 0
            ? (
              <>
                <div className="jobs-list-item__firmware">
                  <div className="jobs-list-item__firmware-container">
                    <div className="jobs-description">
                      <span>Description</span>
                      {job.description}
                    </div>
                    <div>
                      <span>Firmware ID</span>
                      {job.toVersion}
                    </div>
                    <div>
                      {job.targettype === 'node' && (
                      <>
                        <span>Node ID</span>
                        {job.targetid}
                      </>
                      )}
                      {job.targettype === 'group' && (
                      <>
                        <span>Group</span>
                        {job.targetid}
                      </>
                      )}
                      {job.targettype === 'site' && (
                      <>
                        <span>Site</span>
                        {site.name}
                      </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="jobs-list-item__date long">
                  <div className="jobs-list-item__section">
                    <div className="jobs-list-item__section-title">
                      Start date
                    </div>
                    <span>{Utils.convertISOtoJobTime(job.taskCreationTime, site.time_zone)}</span>
                  </div>
                </div>
              </>
            )
            : (
              <>
                <div className={`jobs-list-item__info ${job.operationType === 'DELETE_GROUP' ? 'long' : ''} `}>
                  <div className="jobs-list-item__section">
                    <div className="jobs-list-item__section-title">Group</div>
                    <div className="jobs-list-item__section-content">{(job.data && job.data.groupName) ? job.data.groupName : ''}</div>
                  </div>
                </div>
                {job.operationType !== 'DELETE_GROUP' ? (
                  <>
                    {job.stats?.total > 1 ? (
                      <div className="jobs-list-item__node">
                        <div className="jobs-list-item__node-info">
                          <div className="jobs-list-item__node-info--addRemove">
                            <div className="jobs-list-item__section-title">
                              Add nodes
                            </div>
                            <span>
                              {job.data?.numberOfNodesToAdd || '--'}
                            </span>
                          </div>
                          <div className="jobs-list-item__node-info--addRemove">
                            <div className="jobs-list-item__section-title">
                              Remove nodes
                            </div>
                            <span>{job.data?.numberOfNodesToRemove || '--'}</span>
                          </div>
                        </div>
                      </div>
                    )
                      : (
                        <div className="jobs-list-item__node">
                          <div className="jobs-list-item__node-info">
                            <div className="jobs-list-item__node-info--addRemove">
                              <div className="jobs-list-item__section-title">
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {job.data?.numberOfNodesToAdd ? 'Add nodes' : (job.data?.numberOfNodesToRemove ? 'Remove nodes' : 'Nodes')}
                              </div>
                              <span>
                                {job.data?.numberOfNodesToAdd || '--'}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )
                  : <></>}
                <div className="jobs-list-item__date">
                  <div className="jobs-list-item__section">
                    <div className="jobs-list-item__section-title">
                      Start date
                    </div>
                    <span>{Utils.convertISOtoJobTime(job.taskCreationTime, site.time_zone)}</span>
                  </div>
                </div>
                <div className="jobs-list-item__date">
                  <div className="jobs-list-item__section">
                    <div className="jobs-list-item__section-title">
                      End date
                    </div>
                    <span>{Utils.convertISOtoJobTime(job.finishTime, site.time_zone)}</span>
                  </div>
                </div>
                <div className="jobs-list-item__user">
                  <div className="jobs-list-item__section">
                    <div className="jobs-list-item__section-title">User </div>
                    <span className="jobs-list-item__section-content">{job.username}</span>
                  </div>
                </div>
              </>
            )}
          {jobsWithMoreThanOneNode.indexOf(job.operationType) >= 0
            && (job.operationType !== 'DELETE_GROUP'
            && job.operationType !== 'DELETE_NODE'
            && job.operationType.indexOf('UPDATE_FIRMWARE') !== 0)
              && (
                <>
                  {job.status === 'COMPLETED' && (
                    <JobStatus
                      addClass="job-succeeded"
                      icon={<JobSuccessIcon />}
                      showDetails
                      setShowDetailsModal={setShowDetailsModal}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={setSelectedJob}
                      job={job}
                    />
                  )}
                  {job.status === 'FAILED' && (
                    <JobStatus
                      addClass="job-failed"
                      icon={<JobErrorIcon />}
                      showDetails
                      setShowDetailsModal={setShowDetailsModal}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={setSelectedJob}
                      job={job}
                    />
                  )}
                  {(job.status === 'RUNNING'
                    || job.status === 'CREATING') && (
                    <JobStatus
                      addClass="job-in-progress"
                      icon={<JobSyncIcon />}
                      showDetails
                      setShowDetailsModal={setShowDetailsModal}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={setSelectedJob}
                      action={<JobStoppedSolidIcon />}
                      job={job}
                    />
                  )}
                  {job.status === 'PARTIAL_SUCCESS' && (
                    <JobStatus
                      addClass="job-partially-succeeded"
                      icon={<JobWarningIcon />}
                      showDetails
                      setShowDetailsModal={setShowDetailsModal}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={setSelectedJob}
                      job={job}
                    />
                  )}
                  {job.status === 'ABORTED' && (
                    <JobStatus
                      addClass="job-stopped"
                      icon={<JobStoppedIcon />}
                      showDetails
                      setShowDetailsModal={setShowDetailsModal}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={setSelectedJob}
                      job={job}
                    />
                  )}
                </>
              )}
          {job.operationType === 'DELETE_NODE'
              && (
                <>
                  {job.status === 'COMPLETED' && (
                    <JobStatus
                      addClass="job-succeeded"
                      icon={<JobSuccessIcon />}
                      showDetails={false}
                      setShowDetailsModal={() => null}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={() => null}
                      job={job}
                    />
                  )}
                  {job.status === 'FAILED' && (
                    <JobStatus
                      addClass="job-failed"
                      icon={<JobErrorIcon />}
                      showDetails={false}
                      setShowDetailsModal={() => null}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={() => null}
                      job={job}
                    />
                  )}
                  {(job.status === 'RUNNING'
                    || job.status === 'CREATING') && (
                    <JobStatus
                      addClass="job-in-progress"
                      icon={<JobSyncIcon />}
                      showDetails
                      setShowDetailsModal={setShowDetailsModal}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={setSelectedJob}
                      action={<JobStoppedSolidIcon />}
                      job={job}
                    />
                  )}
                  {job.status === 'PARTIAL_SUCCESS' && (
                    <JobStatus
                      addClass="job-partially-succeeded"
                      icon={<JobWarningIcon />}
                      showDetails={false}
                      setShowDetailsModal={() => null}
                      setShowStopJobModal={setShowStopJobModal}
                      setSelectedJob={() => null}
                      job={job}
                    />
                  )}
                  {job.status === 'ABORTED' && (
                  <JobStatus
                    addClass="job-stopped"
                    icon={<JobStoppedIcon />}
                    showDetails={false}
                    setShowDetailsModal={() => null}
                    setShowStopJobModal={setShowStopJobModal}
                    setSelectedJob={() => null}
                    job={job}
                  />
                  )}
                </>
              )}
          {job.operationType.indexOf('UPDATE_FIRMWARE') === 0
                && (
                  <>
                    {job.status === 'COMPLETED' && (
                      <JobStatus
                        addClass="job-succeeded"
                        icon={<JobSuccessIcon />}
                        showDetails
                        setShowDetailsModal={setShowDetailsModal}
                        setShowStopJobModal={setShowStopJobModal}
                        setSelectedJob={setSelectedJob}
                        job={job}
                      />
                    )}
                    {job.status === 'FAILED' && (
                      <JobStatus
                        addClass="job-failed"
                        icon={<JobErrorIcon />}
                        showDetails={job.targettype !== 'node'}
                        setShowDetailsModal={setShowDetailsModal}
                        setShowStopJobModal={setShowStopJobModal}
                        setSelectedJob={setSelectedJob}
                        job={job}
                      />
                    )}
                    {(job.status === 'RUNNING'
                      || job.status === 'CREATING'
                      || job.status === 'JOB_STARTED') && (
                      <JobStatus
                        addClass="job-in-progress"
                        icon={<JobSyncIcon />}
                        showDetails={job.targettype !== 'node'}
                        setShowDetailsModal={setShowDetailsModal}
                        setShowStopJobModal={setShowStopJobModal}
                        setSelectedJob={setSelectedJob}
                        action={<JobStoppedSolidIcon />}
                        job={job}
                      />
                    )}
                    {job.status === 'ABORTED' && (
                      <JobStatus
                        addClass="job-stopped"
                        icon={<JobStoppedIcon />}
                        showDetails={job.targettype !== 'node'}
                        setShowDetailsModal={setShowDetailsModal}
                        setShowStopJobModal={setShowStopJobModal}
                        setSelectedJob={setSelectedJob}
                        job={job}
                      />
                    )}
                  </>
                )}
          {job.operationType === 'DELETE_GROUP'
              && (
              <>
                {job.status === 'COMPLETED' && (
                  <JobStatus
                    addClass="job-succeeded"
                    icon={<JobSuccessIcon />}
                    showDetails={false}
                    setShowDetailsModal={() => null}
                    setShowStopJobModal={setShowStopJobModal}
                    setSelectedJob={() => null}
                    job={job}
                  />
                )}
                {job.status === 'FAILED' && (
                  <JobStatus
                    addClass="job-failed"
                    icon={<JobErrorIcon />}
                    showDetails
                    setShowDetailsModal={setShowDetailsModal}
                    setShowStopJobModal={setShowStopJobModal}
                    setSelectedJob={setSelectedJob}
                    job={job}
                  />
                )}
              </>
              )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="jobs-list" style={{ width: '100%', height: 'calc( 100vh - 142px )' }}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={rowHeight}
              rowCount={filteredjobs.length}
              rowRenderer={rowRenderer}
              data={filteredjobs}
            />
          )}
        </AutoSizer>
      </div>
      {/* {showDetailsModal && selectedJob !== undefined && selectedJob.operationType.indexOf('UPDATE_FIRMWARE') === 0
        && (
          <FirmwareJobDetails
            headers={getHeaderProps('FirmwareJobDetails')}
            job={selectedJob}
            customer={customer}
            site={site}
            setModalOpen={setShowDetailsModal}
          />
        )} */}
      {showDetailsModal && selectedJob !== undefined && selectedJob.operationType.indexOf('UPDATE_FIRMWARE') < 0
        && (
          <JobDetails
            headers={headers}
            job={selectedJob}
            customer={customer}
            site={site}
            setModalOpen={setShowDetailsModal}
          />
        )}
      {showStopJobModal
        && (
        <StopJob
          job={selectedJob}
          selectedCustomer={customer}
          selectedSite={site}
          setModalOpen={setShowStopJobModal}
        />
        )}
    </>
  );
}

export default JobsList;
