/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState } from 'react';
import { AlarmsPageProps } from '../../types/AlarmsPageProps';
import { TableHeadersProp } from '../../types/TableHeadersProp';
import { AlertObject } from '../../types/AlertObject';
import Utils from '../Utils';
import getHeaderProps from '../getHeaderProps';

export default function useJobsPageState(): AlarmsPageProps {
  const [activeToolbar, setActiveToolbar] = useState(0);
  const [activeToolbarBtn, setActiveToolbarBtnToolbar] = useState<number>(0);
  const [selectedAlarms, _setSelectedAlarms] = useState<Map<string, AlertObject>>(new Map());

  const customizedHeader = JSON.parse(localStorage.getItem('AlarmsTableHeader') || '[]');
  const headersLocal = customizedHeader.length === 0 ? getHeaderProps(Utils.isVerizonUser() ? 'VZAlarms' : 'Alarms') : customizedHeader;

  const [headers, _setHeaders] = useState(headersLocal);

  const handleActiveToolbar = (toolbarNum: number) =>
    setActiveToolbar(() => (toolbarNum === activeToolbar ? 0 : toolbarNum));
  const setHeaders = (newHeaders: TableHeadersProp[]) => {
    localStorage.setItem(Utils.isVerizonUser() ? 'VZAlarmsTableHeader' : 'AlarmsTableHeader', JSON.stringify(newHeaders));
    _setHeaders(newHeaders);
  };

  const setSelectedAlarms = (newState: Map<string, AlertObject>) => {
    _setSelectedAlarms((oldState) => {
      const oldAlertIds = Array.from(oldState.keys());
      const newAlertIds = Array.from(newState.keys());
      const added = newAlertIds.filter((i) => !oldAlertIds.includes(i));
      const removed = oldAlertIds.filter((i) => !newAlertIds.includes(i));

      window.dispatchEvent(new CustomEvent('MapSelectedItemsChange', { detail: { added, removed } }));

      return newState;
    });
  };

  return {
    selectedAlarms,
    setSelectedAlarms,
    headers,
    setHeaders,
    activeToolbar,
    activeToolbarBtn,
    setActiveToolbarBtnToolbar,
    handleActiveToolbar,
  };
}
