import React from 'react';
import { DimmingPointsSetterProps } from '../../../types/DimmingPointsSetterProps';
import DimmingPointBtn from './DimmingPointBtn';

function DimmingPointsSetter(props: DimmingPointsSetterProps): JSX.Element {
  const { scheduleData, draggedDimmingPoint, setDraggedDimmingPoint, selectedSequenceIndex, isDefaultSite, isReadOnly } = props;

  const maxActions = 6;
  const maxPhotocellActions = 1;
  const isTimelineDimmingPointsMax: boolean = scheduleData.events[selectedSequenceIndex]?.actions.length >= maxActions;
  const isTimelinePhotocellMax: boolean = scheduleData.events[selectedSequenceIndex]?.actions
    .filter((action) =>
      action.photocell_enabled)
    .length >= maxPhotocellActions;
  const defaultDimmingPointProps = {
    index: -1,
    editing: false,
  };

  const btnPhotocellSunsetSunrise = 1;
  const btnPhotocellFixedTime = 2;
  const btnFixedLevelSunsetSunrise = 3;
  const btnFixedLevelFixedTime = 4;

  let selectedButton = 0;

  if (draggedDimmingPoint?.index === -1) {
    if (draggedDimmingPoint?.photocell) {
      if (draggedDimmingPoint?.type === 'sunsetsunrise') {
        selectedButton = btnPhotocellSunsetSunrise;
      } else {
        selectedButton = btnPhotocellFixedTime;
      }
    } else if (draggedDimmingPoint?.type === 'sunsetsunrise') {
      selectedButton = btnFixedLevelSunsetSunrise;
    } else {
      selectedButton = btnFixedLevelFixedTime;
    }
  }

  const tooltipText = (text = 'Max 6 dimming points (1 photocell) allowed') => (
    <div className="spec-tooltip__element top timeline__bg-tooltip">
      {text}
    </div>
  );

  return (
    <>
      {isReadOnly ? (
        <div className="dimmingpoints-setter readonly">
          <div className="dimmingpoints-setter__title">Dimming points key</div>
          {(!isDefaultSite) && (
          <div className="dimmingpoints-setter__notification">
            <div className="dimmingpoints-setter__notification-title">Photocell-controlled</div>
            <div className="dimmingpoints-setter__notification-content">
              <DimmingPointBtn type="sunsetsunrise" photocell onClick={() => undefined} />
              <span>Sunrise/sunset</span>
            </div>
            {/* "Photocell-controlled + Fixed time" will be available in Schedules V2 */}
            {false && (
              <div className="dimmingpoints-setter__notification-content">
                <DimmingPointBtn type="fixed" photocell onClick={() => undefined} />
                <span>Fixed time</span>
              </div>
            )}
          </div>
          )}
          <div className="dimmingpoints-setter__notification">
            <div className="dimmingpoints-setter__notification-title">Fixed level</div>
            {(!isDefaultSite) && (
            <div className="dimmingpoints-setter__notification-content">
              <DimmingPointBtn type="sunsetsunrise" photocell={false} onClick={() => undefined} />
              <span>Sunrise/sunset</span>
            </div>
            )}
            <div className="dimmingpoints-setter__notification-content">
              <DimmingPointBtn type="fixed" photocell={false} onClick={() => undefined} />
              <span>Fixed time</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="dimmingpoints-setter">
          <div className="dimmingpoints-setter__title">Set dimming points</div>
          <span className="dimmingpoints-setter__subtitle">
            <span className="bold">Click to place </span>
            dimming point on the grid. Maximum 6 dimming points.
          </span>
          {(!isDefaultSite) && (
          <div className="dimmingpoints-setter__notification">
            <div className="dimmingpoints-setter__notification-title">Photocell-controlled</div>
            <div className="dimmingpoints-setter__notification-content">
              <div className="spec-tooltip">
                <DimmingPointBtn
                  type="sunsetsunrise"
                  photocell
                  selected={selectedButton === btnPhotocellSunsetSunrise}
                  onClick={() => (selectedButton === btnPhotocellSunsetSunrise
                    ? setDraggedDimmingPoint(undefined)
                    : setDraggedDimmingPoint({ ...defaultDimmingPointProps, photocell: true, type: 'sunsetsunrise' })
                  )}
                  isDisabled={isTimelineDimmingPointsMax || isTimelinePhotocellMax}
                />
                {(isTimelineDimmingPointsMax || isTimelinePhotocellMax) ? tooltipText() : tooltipText('Set driver level (if photocell turns light on) relative to sunrise or sunset')}
              </div>
              <span>Sunrise/sunset</span>
            </div>
            {/* "Photocell-controlled + Fixed time" will be available in Schedules V2 */}
            {false && (
            <div className="dimmingpoints-setter__notification-content">
              <div className="spec-tooltip">
                <DimmingPointBtn
                  type="fixed"
                  photocell
                  selected={selectedButton === btnPhotocellFixedTime}
                  onClick={() => (selectedButton === btnPhotocellFixedTime
                    ? setDraggedDimmingPoint(undefined)
                    : setDraggedDimmingPoint({ ...defaultDimmingPointProps, photocell: true, type: 'fixed' })
                  )}
                  isDisabled={isTimelineDimmingPointsMax}
                />
                {isTimelineDimmingPointsMax ? tooltipText() : tooltipText('Set max driver level at a fixed time')}
              </div>
              <span>Fixed time</span>
            </div>
            )}
          </div>
          )}
          <div className="dimmingpoints-setter__notification">
            <div className="dimmingpoints-setter__notification-title">Fixed level</div>
            {(!isDefaultSite) && (
            <div className="dimmingpoints-setter__notification-content">
              <div className="spec-tooltip">
                <DimmingPointBtn
                  type="sunsetsunrise"
                  photocell={false}
                  selected={selectedButton === btnFixedLevelSunsetSunrise}
                  onClick={() => (selectedButton === btnFixedLevelSunsetSunrise
                    ? setDraggedDimmingPoint(undefined)
                    : setDraggedDimmingPoint({ ...defaultDimmingPointProps, photocell: false, type: 'sunsetsunrise' })
                  )}
                  isDisabled={isTimelineDimmingPointsMax}
                />
                {isTimelineDimmingPointsMax ? tooltipText() : tooltipText('Set driver level relative to sunrise or sunset')}
              </div>
              <span>Sunrise/sunset</span>
            </div>
            )}
            <div className="dimmingpoints-setter__notification-content">
              <div className="spec-tooltip">
                <DimmingPointBtn
                  type="fixed"
                  photocell={false}
                  selected={selectedButton === btnFixedLevelFixedTime}
                  onClick={() => (selectedButton === btnFixedLevelFixedTime
                    ? setDraggedDimmingPoint(undefined)
                    : setDraggedDimmingPoint({ ...defaultDimmingPointProps, photocell: false, type: 'fixed' })
                  )}
                  isDisabled={isTimelineDimmingPointsMax}
                />
                {isTimelineDimmingPointsMax ? tooltipText() : tooltipText('Set driver level at a fixed time')}
              </div>
              <span>Fixed time</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DimmingPointsSetter;
