import React from 'react';
import { ToolbarHeadingPropsType } from '../../types/ToolbarHeadingPropsType';

function ToolbarHeading(props: ToolbarHeadingPropsType): JSX.Element {
  const { title, subtitle = '' } = props;

  return (
    <div className="toolbar-tabs__container-title">
      <span className="toolbar-tabs__container-title-main">{ title }</span>
      <span className="toolbar-tabs__container-title-subtitle" data-testid="subtitleName">{ subtitle }</span>
    </div>
  );
}

export default ToolbarHeading;
