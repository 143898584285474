import React from 'react';
import { TableFooterItemProps } from '../../types/TableFooterProps';

function TableFooterItem(props: TableFooterItemProps): JSX.Element {
  const { selectedValuesLength, boldText, text, hasBorder, isLast } = props;
  return (
    <div className={`table-footer__item ${hasBorder ? 'table-footer__item--with-border' : ''} ${isLast ? 'table-footer__item--last' : ''}`}>
      <div className="table-footer__bold">{boldText}</div>
      <span className="table-footer__text">{text}</span>
      <span className="table-footer__values">
        {selectedValuesLength}
      </span>
    </div>
  );
}

export default TableFooterItem;
