/* eslint-disable camelcase */
import React from 'react';
import Textbox from '../../../../../Common/Components/Textbox';
import { AccountPartnerFormProps } from '../../../../../types/AccountPartnerForm';

function AccountPartnerForm(props: AccountPartnerFormProps): JSX.Element {
  const { data, setPartner, partnerValidation } = props;

  return (
    <div className="partner-form">
      <div>
        <Textbox
          label="Partner name"
          name="Partner name"
          value={data.name}
          isRequired
          placeholder="Enter"
          onChange={(e) => {
            const name = e.target.value;
            setPartner((oldValues) => ({ ...oldValues, name }));
          }}
          error={partnerValidation.hasError}
          errorMessage={partnerValidation.errorMessage}
        />
      </div>
      <div className="partner-form__inputs">
        <div className="partner-form__inputs-contact">
          <div className="partner-form__subtitles">Contact info</div>
          <Textbox
            label="Name"
            name="Name"
            placeholder="Enter"
            value={data.contact_name}
            onChange={(e) => {
              const contact_name = e.target.value;
              setPartner((oldValues) => ({ ...oldValues, contact_name }));
            }}
          />
          <Textbox
            label="Phone"
            name="Phone"
            placeholder="Enter"
            value={data.contact_phone}
            onChange={(e) => {
              const contact_phone = e.target.value;
              setPartner((oldValues) => ({ ...oldValues, contact_phone }));
            }}
          />
          <Textbox
            label="Email"
            name="Email"
            placeholder="Enter"
            value={data.contact_email}
            onChange={(e) => {
              const contact_email = e.target.value;
              setPartner((oldValues) => ({ ...oldValues, contact_email }));
            }}
          />
        </div>
        <div className="partner-form__inputs-address">
          <div className="partner-form__subtitles">Site address</div>
          <Textbox
            label="Address 1"
            name="Address 1"
            placeholder="Enter"
            value={data.street1}
            onChange={(e) => {
              const street1 = e.target.value;
              setPartner((oldValues) => ({ ...oldValues, street1 }));
            }}
          />
          <Textbox
            label="Address 2"
            name="Address 2"
            placeholder="Enter"
            value={data.street2}
            onChange={(e) => {
              const street2 = e.target.value;
              setPartner((oldValues) => ({ ...oldValues, street2 }));
            }}
          />
          <Textbox
            label="City"
            name="City"
            placeholder="Enter"
            value={data.city}
            onChange={(e) => {
              const city = e.target.value;
              setPartner((oldValues) => ({ ...oldValues, city }));
            }}
          />
          <div className="partner-form__state">
            <Textbox
              label="State"
              name="State"
              placeholder="Enter"
              value={data.state}
              onChange={(e) => {
                const state = e.target.value;
                setPartner((oldValues) => ({ ...oldValues, state }));
              }}
            />
            <Textbox
              label="Zip"
              name="Zip"
              placeholder="Enter"
              value={data.postal_code}
              onChange={(e) => {
                const postal_code = e.target.value;
                setPartner((oldValues) => ({ ...oldValues, postal_code }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountPartnerForm;
