/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { DashboardSidebarItemPropsType } from '../../types/AnalysisSidebarItemProps';

function DashboardSidebarItem(props: DashboardSidebarItemPropsType): JSX.Element {
  const { icon, title, type, reportType, setReportType, setListReportDefinitions, setIsDashboard, isDashboard } = props;

  const isSelected = (reportType === type) ? 'admin__sidebar-item--selected' : '';
  return (
    <div
      data-testid="admin-sidebar-item"
      className={`admin__sidebar-item ${isSelected}`}
    >
      <div
        className="admin__sidebar-link"
        onClick={() => {
          setReportType(type);
          setListReportDefinitions(false);
          setIsDashboard(isDashboard);
        }}
      >
        <div className="admin__sidebar-item-icon">{icon}</div>
        <div className="admin__sidebar-item-title">{title}</div>
        <span className="admin__sidebar-item-line" />
      </div>
    </div>
  );
}

export default DashboardSidebarItem;
