const loadScript = (id: string, url: string): Promise<void> => new Promise((resolve, reject) => {
  if (document.getElementById(id)) {
    resolve();
  } else {
    const script = document.createElement('script');
    script.src = url;
    script.id = id;
    script.onload = () => resolve();
    script.onerror = () => reject();

    document.body.appendChild(script);
  }
});

export default loadScript;
