import React from 'react';
import DashboardSidebarItem from '../../../Common/Components/DashboardSidebarItem';
import { DashboardSidebarItemsProps } from '../../../types/AnalysisSidebarItems';
// not the exact icons
import { ReactComponent as NetworkIcon } from '../../../img/icons/network.svg';
import { ReactComponent as PowerIcon } from '../../../img/icons/electric-utility.svg';
import { ReactComponent as EnergyIcon } from '../../../img/icons/energy.svg';
// import { ReactComponent as PowerIcon } from '../../../img/icons/electric-utility.svg';

function DashboardSidebarItems(props: DashboardSidebarItemsProps): JSX.Element {
  const { reportType, setReportType, setListReportDefinitions, setIsDashboard, isDashboard } = props;
  return (
    <>
      <DashboardSidebarItem
        icon={<NetworkIcon />}
        title="Network Statistics"
        type="Network Statistics"
        reportType={reportType}
        setReportType={setReportType}
        setListReportDefinitions={setListReportDefinitions}
        setIsDashboard={setIsDashboard}
        isDashboard={isDashboard}
      />

      <DashboardSidebarItem
        icon={<PowerIcon />}
        title="Power Quality"
        type="Power Quality"
        reportType={reportType}
        setReportType={setReportType}
        setListReportDefinitions={setListReportDefinitions}
        setIsDashboard={setIsDashboard}
        isDashboard={isDashboard}
      />

      <DashboardSidebarItem
        icon={<EnergyIcon />}
        title="Advanced Energy"
        type="Advanced Energy"
        reportType={reportType}
        setReportType={setReportType}
        setListReportDefinitions={setListReportDefinitions}
        setIsDashboard={setIsDashboard}
        isDashboard={isDashboard}
      />
    </>
  );
}

export default DashboardSidebarItems;
