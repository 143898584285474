/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import { DateTime } from 'luxon';

import { DashboardListProps } from '../../../types/DashboardListProps';
import AnalysisChart from './AnalysisChart';
import { getRequest, postRequest } from '../../../utils/fetch';
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import Toolbar from '../../../Common/Components/Toolbar';
import ToolbarHeading from '../../../Common/Components/ToolbarHeading';
import ToolbarLink from '../../../Common/Components/ToolbarLink';
import { ReactComponent as CalendarIcon } from '../../../img/icons/calendar.svg';
import { ReactComponent as DownloadIcon } from '../../../img/icons/download.svg';
import { ReactComponent as LightsIcon } from '../../../img/icons/lighting-group.svg';

import { AnalysisReportGraphProps, AnalysisReportResponseItem } from '../../../types/AnalysisReportGraphProps';
import { SiteObject } from '../../../types/SiteObject';
import { GroupObject } from '../../../types/GroupObject';
import { NodeObject } from '../../../types/NodeObject';
import { ChartBase } from '../../../types/GroupsSelector';
import { groupsNodesFn, nodesFetcherFn } from '../../../utils/ApiDataHelpers';
import { useAppContext } from '../../../utils/AppContext';
import useEnergyPageState from '../../../utils/state/useEnergyPageState';
import Utils from '../../../utils/Utils';
import DatePeriodSelector from '../../Energy/components/DatePeriodSelector';

import { ReactComponent as CloseIcon } from '../../../img/icons/close.svg';
import { httpAccepted, nodesPageSize } from '../../../utils/constants';
import { AnalysisDashboardQueryPayload } from '../../../types/AnalysisDashboardQueryPayload';
import Sensors from '../../../utils/Sensors';
import ToolbarDashboardSelector from './ToolbarDashboardSelector';
import SelectBox from '../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import Button from '../../../Common/Components/Button';

const basicNodeChartBase: ChartBase = {
  type: 'nodeList',
  icon: <LightsIcon />,
  selectedNodes: [],
};

function Dashboard(props: DashboardListProps): JSX.Element {
  const { addNotification } = useAppContext();
  const { reportType, selectedSite, selectedCustomer, isNetworkStats, defaultSelectedChartBase = basicNodeChartBase, isModal = false, closeModal } = props;
  const {
    activeToolbar,
    handleActiveToolbar,
  } = useEnergyPageState();
  const initDate = () => {
    const end = DateTime.fromMillis(Date.now());
    const start = end.minus({ days: 29 });
    return {
      start,
      end,
    };
  };
  // Site data

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(() => `/organizations/${selectedCustomer.id}/sites`);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const site = useMemo(() => sitesResp?.find(
    (item) => item.siteid === selectedSite.id,
  ), [sitesResp, selectedSite.id]);

  // Nodes

  const { data: nodesResp } = useSWR<Array<NodeObject> | undefined>(
    selectedSite.id
      ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/lkp/nodes?size=${nodesPageSize}`, 'GetNodesResp']
      : null,
    nodesFetcherFn,
  );

  const { data: groupsResp } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups']
    : null);
  const {
    lightingGroups,
    orgGroups,
  } = useMemo(() => groupsNodesFn(groupsResp || [], nodesResp), [groupsResp, nodesResp]);
  const [groupFilterActiveTab, setGroupFilterActiveTab] = useState<string>('site');
  const [selectedChartBase, setSelectedChartBase] = useState<ChartBase>(defaultSelectedChartBase);
  const [startDate, setStartDate] = useState(initDate().start);
  const [endDate, setEndDate] = useState(initDate().end);
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [chartSubTitle, setChartSubtitle] = useState<string>('All nodes');
  const [sensorFilter, setSensorFilter] = useState([{ title: '', key: '' }]);
  const [selectedSensor, setSelectedSensor] = useState<SelectBoxItemType>({ key: '', title: '' });

  const [networkReportChartProps, setNetworkReportChartProps] = useState<AnalysisReportGraphProps>({
    waiting: true,
    noDataAvailable: false,
    records: [],
    granularity: 'RAW',
  });
  const queryInterval = useRef<NodeJS.Timeout>();
  const isMounted = useRef<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState({});
  const isFetched = useRef<boolean>(false);
  const twoSec = 2000;

  useEffect(() => {
    setFormattedDate(`${startDate.toFormat('LLLL d')} - ${endDate.toFormat('LLLL d, yyyy')}`);
  }, [startDate, endDate]);

  useEffect(() => {
    if (localStorage.getItem('analysisSelectedNodes')) {
      const selected = localStorage.getItem('analysisSelectedNodes');
      if (selected !== null) {
        setSelectedChartBase({ type: 'nodeList',
          icon: <LightsIcon />,
          selectedNodes: (selected !== null) ? JSON.parse(selected) : [],
        });
      }
    } else {
      addNotification({ type: 'warning', message: 'Please select nodes from Lights page to view the dashboard.' });
    }
  }, [nodesResp]);
  useEffect(() => {
    let matchChartBaseToSubtitle;
    switch (selectedChartBase.type) {
      case ('site'):
        matchChartBaseToSubtitle = 'All nodes';
        break;
      case ('nodeList'):
        matchChartBaseToSubtitle = selectedChartBase.selectedNodes?.length;
        break;
      case ('nodeid'):
        matchChartBaseToSubtitle = selectedChartBase.selectedNode;
        break;
      default: matchChartBaseToSubtitle = selectedChartBase.group.name;
    }
    setChartSubtitle(matchChartBaseToSubtitle);
  }, [selectedChartBase]);

  const toolbarNodeSiteGroup = 2;
  const toolbarTime = 3;
  const toolbarReport = 4;
  useEffect(() => {
    async function sendDashboardQuery(payload: AnalysisDashboardQueryPayload) {
      if (payload.nodeIds.length !== 0) {
        try {
          // eslint-disable-next-line max-len
          const { status, data, error } = await postRequest<AnalysisDashboardQueryPayload, { token: string; }>(`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/dashboards/${isNetworkStats}`, payload);
          if (status === httpAccepted && data?.token) {
            const { token } = data;
            startPolling(token, payload, fetchData);
          } else {
            throw new Error(error);
          }
        } catch (error) {
          setNetworkReportChartProps((oldValues) => ({ ...oldValues, waiting: false, noDataAvailable: true }));
          addNotification({ type: 'warning', message: `Could not fetch report data: '${error}'` });
        }
      } else {
        setNetworkReportChartProps((oldValues) => ({ ...oldValues, waiting: false, noDataAvailable: true }));
      }
    }
    async function startPolling(
      queryTaskId: string,
      payload: AnalysisDashboardQueryPayload,
      fn: (_qId: string, _payload: AnalysisDashboardQueryPayload) => Promise<void>,
    ) {
      isFetched.current = false;
      clearInterval(queryInterval.current as NodeJS.Timeout);
      queryInterval.current = setInterval(() => fn(queryTaskId, payload), twoSec);
    }
    const abortController = new AbortController();
    const fetchData = async (queryTaskId: string, payload: AnalysisDashboardQueryPayload) => {
      try {
        const path = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/dashboards/${isNetworkStats}/${queryTaskId}`;
        const resp = await getRequest(path);

        if (resp?.dashboardDataSet && resp?.status !== undefined && resp?.status === 'COMPLETED') {
          if (!isFetched.current && resp.dashboardDataSet && isMounted) {
            isFetched.current = true;
            clearInterval(queryInterval.current as NodeJS.Timeout);
            if (resp.dashboardDataSet.length === 0) {
              setNetworkReportChartProps({
                records: [],
                granularity: payload.granularity,
                waiting: false,
                noDataAvailable: true,
              } as AnalysisReportGraphProps);
            } else {
              const tmpArr: Array<AnalysisReportResponseItem> = [];
              resp.dashboardDataSet.forEach((report) => {
                report.dataSets.forEach((datasetname) => {
                  tmpArr.push({
                    label: report.dataSetSubject,
                    nodeId: report.dataSetSubject,
                    sensorId: datasetname.dataSetName,
                    sensorLabel: Sensors.getSensorTitleById(datasetname.dataSetName),
                    pointStyle: 'circle',
                    fill: false,
                    steppedLine: 'before',
                    pointRadius: 4,
                    pointHoverRadius: 4,
                    borderWidth: 2,
                    data: datasetname.dataPoints.map((dp) => ({ x: Utils.getSensorConvertedDate(dp.timeStamp, selectedSite?.timezone || 'America/New_York'), y: Utils.getConvertedValue(datasetname.dataSetName, dp.value) })),
                  });
                });
              });
              const sensorIdsArr: Array<{ title: string; key: string; }> = [];
              tmpArr.forEach((recorddata) => {
                if (!sensorIdsArr.find((e) => e.key === recorddata.sensorId)) {
                  sensorIdsArr.push({ title: Sensors.getSensorTitleByName(Sensors.getSensorName(recorddata.sensorId)), key: recorddata.sensorId });
                }
              });
              setSensorFilter(sensorIdsArr);
              setSelectedSensor({ key: sensorIdsArr[0].key, title: sensorIdsArr[0].title });
              setNetworkReportChartProps({
                records: tmpArr,
                granularity: payload.granularity,
                waiting: false,
                noDataAvailable: false,
              });
            }
          }
        } else if (resp?.dashboardDataSet && resp?.status !== undefined && resp?.status === 'IN_PROGRESS') {
          setNetworkReportChartProps({
            waiting: true,
            noDataAvailable: false,
          } as AnalysisReportGraphProps);
          startPolling(queryTaskId, payload, fetchData);
        } else if (resp?.dashboardDataSet && resp?.status !== undefined && resp?.status === 'FAILED') {
          if (!isFetched.current && resp.dashboardDataSet && isMounted) {
            isFetched.current = true;
            clearInterval(queryInterval.current as NodeJS.Timeout);
            if (resp.dashboardDataSet.length === 0) {
              setNetworkReportChartProps({
                records: [],
                granularity: payload.granularity,
                waiting: false,
                noDataAvailable: true,
              } as AnalysisReportGraphProps);
            }
            addNotification({ type: 'warning', message: 'Could not fetch network statistics data for the requested query as it returned a FAILED status.' });
          }
        } else {
          addNotification({ type: 'error', message: `An error occurred while retrieving ${isNetworkStats} data for the requested query.` });
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          return;
        }
        if (isMounted.current) {
          clearInterval(queryInterval.current as NodeJS.Timeout);
          setNetworkReportChartProps({
            records: [],
            granularity: payload.granularity,
            waiting: false,
            noDataAvailable: true,
          } as AnalysisReportGraphProps);
          addNotification({ type: 'warning', message: `Could not fetch report data: '${error}'` });
        }
      }
    };
    setNetworkReportChartProps((oldValues) => ({ ...oldValues, waiting: true, noDataAvailable: false }));
    if (site !== undefined) {
      sendDashboardQuery(Utils.createAnalysisDashboardPayload(startDate, endDate, selectedChartBase, selectedSite, site));
    }
    return () => {
      isMounted.current = false;
      clearInterval(queryInterval.current as NodeJS.Timeout);
      abortController.abort();
      setState({});
    };
    // eslint-disable-next-line no-return-assign
  }, [startDate, endDate, selectedChartBase, selectedSite, site, selectedCustomer.id, addNotification]);

  const [csvDataSet, setCsvDataSet] = useState<AnalysisReportResponseItem[]>(networkReportChartProps.records);

  useEffect(() => {
    setCsvDataSet(networkReportChartProps.records);
  }, [networkReportChartProps.records]);

  const generateCsvData = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csvData: Record<string, any>[] = [];

    csvDataSet.forEach((dataset) => {
      const sensorName = Sensors.getSensorName(dataset.sensorId);
      const nodeid = `${dataset.nodeId}\t`;
      const sensorUnit = Sensors.getSensorUnit(sensorName);
      const sensorid = `${Sensors.getSensorTitleByName(sensorName)}  ${sensorUnit !== '' ? `(${sensorUnit})` : ''}`;
      if (site?.time_zone) {
        dataset.data.forEach((datapoint) => {
          csvData.push({
            time: datapoint.x,
            node: nodeid,
            sensor: sensorid,
            value: Utils.getConvertedValue(dataset.sensorId, datapoint.y),
          });
        });
      }
    });

    return csvData;
  }, [csvDataSet, site?.time_zone]);
  return (
    <div className="energy-panel analysisdashboard-panel">
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <div className="toolbar-tabs__container-tabset-group">
            <ToolbarHeading title={`${reportType} Dashboard`} />
            <ToolbarLink
              icon={selectedChartBase.icon}
              title={isModal ? chartSubTitle : Utils.getChartTypeString(selectedChartBase.type)}
              subtitle={isModal ? Utils.getChartTypeString(selectedChartBase.type) : chartSubTitle}
              onclick={() => !isModal && handleActiveToolbar(toolbarNodeSiteGroup)}
              order={toolbarNodeSiteGroup}
              tabPanelisActive={activeToolbar}
              addClass={`toolbar-tabs__container-tabset-container--${isModal ? 'large-font wide' : 'wide'}`}
              highlightActive={false}
            >
              <ToolbarDashboardSelector
                className=""
                lightingGroups={lightingGroups}
                orgGroups={orgGroups}
                selectedChartBase={selectedChartBase}
                setSelectedChartBase={setSelectedChartBase}
                handleActiveToolbar={handleActiveToolbar}
                setGroupFilterActiveTab={setGroupFilterActiveTab}
                groupFilterActiveTab={groupFilterActiveTab}
                selectedSite={selectedSite}
              />
            </ToolbarLink>
            <ToolbarLink
              icon={<CalendarIcon />}
              title="Time period"
              subtitle={formattedDate}
              onclick={() => handleActiveToolbar(toolbarTime)}
              tabPanelisActive={activeToolbar}
              order={toolbarTime}
              addClass="toolbar-tabs__container-tabset-container--date last"
              highlightActive={false}
            >
              <DatePeriodSelector
                startDate={startDate.toJSDate()}
                endDate={endDate.toJSDate()}
                setStartDate={(dt) => setStartDate(DateTime.fromJSDate(dt as Date))}
                setEndDate={(dt) => setEndDate(DateTime.fromJSDate(dt as Date))}
                maxDays={365}
                handleActiveToolbar={handleActiveToolbar}
              />
            </ToolbarLink>
            {(sensorFilter.length > 1)
          && (
          <ToolbarLink
            addClass="toolbar-tabs__container-tabset-container--selectbox last"
          >
            <SelectBox
              type="light"
              label="Selected sensor"
              title={selectedSensor.title}
              selectedItemKey={selectedSensor.key || sensorFilter[0].key}
              onClick={(item: SelectBoxItemType) => {
                setSelectedSensor({ key: item.key, title: item.title });
              }}
              list={sensorFilter && Utils.arrayToSelectbox(Array.from(sensorFilter.values()), 'key', 'title')}
              selectboxWidth={200}
              listWidth={200}
            />
          </ToolbarLink>
          )}
          </div>

          {isModal && (
          <ToolbarLink
            icon={<CloseIcon />}
            onclick={closeModal}
            tabPanelisActive={activeToolbar}
            order={toolbarReport}
            addClass="toolbar-tabs__container-tabset-container--close"
            highlightActive={false}
          />
          )}
        </div>
      </Toolbar>
      <div className="content">
        {!networkReportChartProps.waiting && !networkReportChartProps.noDataAvailable && (
        <div className="energy-title">
          <Button
            onClick={() => Utils.downloadCSV([
              { key: 'time', val: 'Time' },
              { key: 'node', val: 'Node' },
              { key: 'sensor', val: 'Sensor' },
              { key: 'value', val: 'Value' },
            ], generateCsvData(), `${isNetworkStats}-chart`)}
            buttonType="textlink"
            extraClasses="energy-title-button"
          >
            <span>
              Download chart data
              <DownloadIcon title="Download" />
            </span>
          </Button>
        </div>
        ) }
        <AnalysisChart
          id="line-chart"
          site={selectedSite}
          rawDatasets={networkReportChartProps.records || []}
          selectedSensor={selectedSensor}
          isLoading={networkReportChartProps.waiting}
        />
      </div>
    </div>
  );
}

export default Dashboard;
