/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { usePerformanceMark, Stage } from '@cabify/prom-react';
import { ListRowProps } from 'react-virtualized';
import Table from '../../../../Common/Components/Table';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import getHeaderProps, { defaultHeaders } from '../../../../utils/getHeaderProps';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import { NodeObject } from '../../../../types/NodeObject';
import { nodesFetcherFn } from '../../../../utils/ApiDataHelpers';
import { CommissioningObject } from '../../../../types/CommissioningObject';
import Tooltip from '../../../../Common/Components/Tooltip';
import Modal from '../../../../Common/Components/Modal';
import Button from '../../../../Common/Components/Button';
// import Textbox from '../../../../Common/Components/Textbox';
// import formValidation from '../../../../utils/form/formValidation';
import { getRequest, postRequest, postRequestAll/* , putRequest */ } from '../../../../utils/fetch';
import { useAppContext } from '../../../../utils/AppContext';
import DropDown from '../../../../Common/Components/DropDown';
import Utils from '../../../../utils/Utils';
import DownloadList from '../../../../Common/Components/DownloadList';
import Loading from '../../../../Common/Components/Loading';
// import SelectNodesByFile from '../../../../Common/Components/SelectNodesByFile';
import CustomizeList from '../../../../Common/Components/CustomizeList';
import { TableHeadersProp } from '../../../../types/TableHeadersProp';
import { nodesPageSize } from '../../../../utils/constants';
import RebootModal from '../../../../Common/Components/RebootModal';
import RetryAddToSLGModal from '../../../../Common/Components/RetryAddToSLGModal';
import CollectGPSModal from '../../../../Common/Components/CollectGPSModal';
import ManualGPSModal from '../../../../Common/Components/ManualGPSModal';
import TableToolbar from '../Components/TableToolbar';
import Searchbox from '../../../../Common/Components/Searchbox';
// import SelectBox from '../../../../Common/Components/SelectBox';
import { SiteObject, AllSiteObject } from '../../../../types/SiteObject';
import { ScheduleObject } from '../../../../types/ScheduleObject';
import { CustomerObject } from '../../../../types/CustomerObject';
import ScheduleModal from '../../../Schedules/Components/ScheduleModal';

import { SelectedPartner } from '../../../../types/UsersPageProps';

import { ReactComponent as RebootIcon } from '../../../../img/icons/reboot.svg';
import { ReactComponent as CommisionIcon } from '../../../../img/icons/commision.svg';
import { ReactComponent as DotdotdotIcon } from '../../../../img/icons/dotdotdot.svg';
import { ReactComponent as CustomizeIcon } from '../../../../img/icons/customize-list.svg';
import { ReactComponent as DownloadIcon } from '../../../../img/icons/download.svg';
import { ReactComponent as ArrowIcon } from '../../../../img/icons/arrow.svg';
import { ReactComponent as SyncIcon } from '../../../../img/icons/sync.svg';
import { ReactComponent as EditIcon } from '../../../../img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../img/icons/trash.svg';

import { GroupObject } from '../../../../types/GroupObject';
import CreateLigGroupWithNodes from '../../../Lights/Components/GroupActionsModals/CreateLigGroupWithNodes';
import DeleteNodes from './Components/DeleteNodes';

const tooltipOffset = 0;
const listWidth = 300;
const listRowHeight = 40;
const minListHeight = 200;
const searchBoxMaxItems = 8;
const maxSelectedNodes = 100;
const maxSelectedRebootNodes = 10;
const maxSelectedDeleteNodes = 1000;

const fixColCount = 4;
const manualCommissionModalHeight = 220;
function SiteAssignmentPage(): JSX.Element {
  const { addNotification } = useAppContext();

  const [selectedCommissioningObjectMap, setSelectedCommissioningObjectMap] = useState<Map<string, CommissioningObject>>(new Map());
  const [modalToggle, setModalToggle] = useState(0);
  const [selectedNode, setSelectedNode] = useState<NodeObject | undefined>(undefined);
  const [selectedAccount, setSelectedAccount] = useState({ name: '', id: '' });
  // const [editTextBox, _setEditTextBox] = useState({
  //   editErrorMsg: '',
  //   error: false,
  //   value: '',
  // });

  const [multipleAccounts, setMultipleAccounts] = useState(false);
  const [defaultSiteId, setDefaultSiteId] = useState<string>('');
  const [orgId, setOrgId] = useState('');
  const [accountList, setAccountList] = useState<Array<{ name: string, id: string }>>([]);
  const [filteredAccountList, setFilteredAccountList] = useState<Array<{ name: string, id: string }>>([]);
  const [openModal, setOpenModal] = useState(0);
  const [selectedDropdown, setSelectedDropdown] = useState(0);
  const [searchAccount, setSearchAccount] = useState(selectedAccount.name || '');

  const isVerizonUserorAdminUser = Utils.isVerizonUserorAdminUser();
  const isNonReadOnly = Utils.isNonReadOnly();
  const isSensityUserAdmin: boolean = Utils.isSensityUserAdmin();
  const modalToggleReboot = 1;
  const modalToggleCollectGPS = 2;
  const modalToggleManualGPS = 3;
  const openModalGPSMode = 1;
  const openModalManualAssignment = 2;
  const openModalDownloadList = 3;
  const openModalCustomizeList = 4;
  const openModalAddNodesFromFile = 5;
  const openModifyScheduleModal = 6;
  const openModalRebootNodes = 7;
  const openModalRetryAddToSLG = 8;
  const dropdownCommission = 1;
  const dropdownDeleteNodes = 2;
  const dropdownMore = 3;

  /*
  const setEditTextBox = (val: string) => {
    const validation = formValidation(
      val,
      { max: 45 },
    );

    _setEditTextBox((oldVal) => ({
      value: validation.hasError ? editTextBox.value : val,
      editErrorMsg: validation.errorMsg,
      error: validation.hasError,
    }));
  };
  */

  const { data: orderedCustomers } = useSWR<Array<CustomerObject> | undefined>(
    ['/organizations', 'GetCustomers'],
    (url) => getRequest(url, {}, (data: Array<CustomerObject>): Array<CustomerObject> => {
      const tmpData = data ? [...data] : [];
      tmpData?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      return tmpData;
    }),
  );
  const { data: siteList } = useSWR<AllSiteObject[]>(
    ['/sites', 'Commissioning'],
    (url) => getRequest(url, {}, (data: AllSiteObject[]): AllSiteObject[] => data?.filter((s) => s.orgid !== '')),
  );

  useEffect(() => {
    const orgDefaultSite = {};
    if (siteList && orderedCustomers) {
      siteList.forEach((site) => {
        if (site.defaultSite) {
          orgDefaultSite[site.orgid] = site.siteid;
        }
      });
      switch (Object.keys(orgDefaultSite).length) {
        case 0: // no default site(s)
          break;
        case 1: // only one account with default site
          const [org, site] = Object.entries(orgDefaultSite)[0];
          setOrgId(org);
          if (typeof site === 'string') {
            setDefaultSiteId(site);
          }
          break;
        default: // multiple accounts
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const tmpList: any[] = [];
          Object.keys(orgDefaultSite).forEach((key) =>
            tmpList.push({
              id: key,
              name: orderedCustomers.find((c) => c.orgid === key)?.name,
            }));
          setAccountList(tmpList);
          setFilteredAccountList(tmpList);
          setMultipleAccounts(true);
          break;
      }
    }
  }, [siteList, orderedCustomers]);

  const { data: nodesResp, mutate: updateSiteAssignment, isValidating } = useSWR<Array<NodeObject> | undefined>(
    (defaultSiteId && (orgId || selectedAccount?.id))
      ? [`/organizations/${orgId || selectedAccount.id}/sites/${defaultSiteId}/lkp/nodes?size=${nodesPageSize}`, 'SiteAssignmentPage']
      : null,
    nodesFetcherFn,
  );

  const { data: orgSites } = useSWR<SiteObject[]>(
    orgId || selectedAccount.id ? [`/organizations/${orgId || selectedAccount.id}/sites`, 'AdminSiteModalSites'] : null,
  );

  const [accountListComputedHeight, setAccountListComputedHeight] = useState(0);

  const handleAccountListChange = (name: string) => {
    setSelectedAccount({ id: '', name: '' });
    setSearchAccount(name);
    setMissingSiteLightingGroup(false);
    if (name === '') {
      setFilteredAccountList(accountList);
      setAccountListComputedHeight(0);
    } else {
      const filtered = accountList?.filter((item) => item.name.toLowerCase().includes(name.toLowerCase()));
      setFilteredAccountList(filtered);
      setAccountListComputedHeight(Math.min(filtered.length * listRowHeight, minListHeight));
    }
  };

  const accountRowRenderer = ({ key, index, style }: ListRowProps, setToggle: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!filteredAccountList) {
      return <></>;
    }

    return (
      <li
        key={key}
        style={style}
        className="search-partner--list"
        onClick={() => {
          setToggle(false);
          setSelectedAccount(filteredAccountList[index]);
          setSearchAccount(filteredAccountList[index].name);
          setDefaultSiteId(siteList?.find((s) => s.defaultSite && s.orgid === filteredAccountList[index].id)?.siteid || '');
          setSelectedCommissioningObjectMap(new Map([]));
        }}
      >
        <span className={filteredAccountList[index].name === selectedAccount.name ? 'selected' : ''}>{filteredAccountList[index].name || ''}</span>
      </li>
    );
  };

  const nodesNodeObjectList = useMemo(() => nodesResp?.map((c: NodeObject) =>
    ({
      node_id: c.nodeid,
      ...isNonReadOnly && { actions: (
        <div className="commissioning-actions">
          <Tooltip text="Reboot modem" offset={tooltipOffset}>
            <RebootIcon
              onClick={() => { setModalToggle(modalToggleReboot); setSelectedNode(c); }}
            />
          </Tooltip>
          { /* <Tooltip text="Collect GPS" offset={tooltipOffset} addStyle={{ marginLeft: '10px' }}>
            <LocationIcon
              onClick={() => { setModalToggle(modalToggleCollectGPS); setSelectedNode(c); }}
            />
      </Tooltip> */ }
          <Tooltip text="Enter GPS coordinates manually" offset={tooltipOffset} addStyle={{ marginLeft: '10px' }}>
            <EditIcon
              onClick={() => { setModalToggle(modalToggleManualGPS); setSelectedNode(c); }}
            />
          </Tooltip>
        </div>),
      },
      account_name: c.accountName,
      account_id: c.accountRef,
      status: c.commissionState,
      activatedTableSort: Date.parse(c.activationDate) || 0,
      network: c.netStat ? 'Connected' : 'Disconnected',
      declaredTableSort: Date.parse(c.declared) || 0,
      declared: Utils.getConvertedDate(c.declared),
      commisionedTableSort: Date.parse(c.commissionedDate) || 0,
      commissionState: c.commissionState,
      commissioned: Utils.getConvertedDate(c.commissionedDate),
      activationDate: Utils.getConvertedDate(c.activationDate),
      gpsSampleSize: c.gpsSampleSize,
      gpsAccuracy: c.gpsAccuracy,
      gpsLastReportedTimestamp: Utils.getConvertedDate(c.gpsLastReportedTimestamp),
      note: c.note,
      latitude: c.latitude,
      longitude: c.longitude,
      softwareVersion: c.softwareVersion,
      deviceFirmwareVersion: c.deviceFirmwareVersion,
      lightingGroupConfirmation: c.lightingGroupConfirmation === 'CONFIRMED' ? 'true' : 'false',
      model: c.model,
      msisdn: c.msisdn,
      iccid: c.iccid,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })), [nodesResp]);

  const [filteredCount, setFilteredCount] = useState(-1);
  // Create / Edit schedule modals
  const newSchedule: ScheduleObject = {
    name: '',
    description: '',
    events: [
      {
        actions: [],
        // these 3 below never changes
        photocell_enabled: false,
        photocell_highLevel: 100,
        photocell_lowLevel: 0,
      },
    ],
    lightingGroups: [],
    network: {
      highLevel: 60,
      highTime: '18:00:00',
      photocell_enabled: true,
      photocell_highLevel: 100,
      photocell_lowLevel: 0,
    },
    scheduleId: '',
    sites: [],
  };

  const customizedHeader = JSON.parse(localStorage.getItem(
    isSensityUserAdmin ? 'VerizonAdminUserSiteAssignmentListHeader' : 'SiteAssignmentListHeader',
  ) || '[]');
  const headers = customizedHeader.length === 0 ? getHeaderProps(
    isSensityUserAdmin ? 'VerizonAdminUserSiteAssignment' : 'SiteAssignment',
  ) : customizedHeader;

  const [headerList, _setHeaders] = useState(headers);
  const setHeaders = (newHeaders: TableHeadersProp[]) => {
    localStorage.setItem(
      isSensityUserAdmin ? 'VerizonAdminUserSiteAssignmentListHeader' : 'SiteAssignmentListHeader',
      JSON.stringify(newHeaders),
    );
    _setHeaders(newHeaders);
  };

  const inputFile = useRef<HTMLInputElement>(null);
  /* const handleFileUpload = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  }; */
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setUploadFile(file);
      setOpenModal(openModalAddNodesFromFile);
    }
  };
  const clearInputFileAndCloseModal = () => {
    if (inputFile !== null && inputFile.current !== null) {
      inputFile.current.value = '';
    }
    setOpenModal(0);
  };

  const [listComputedHeight, setListComputedHeight] = useState(0);
  const [searchboxFilteredSiteList, setSearchboxFilteredSiteList] = useState<SelectedPartner[]>([]);
  const [searchboxSiteList, setSearchboxSiteList] = useState<SelectedPartner[]>([]);

  useEffect(() => {
    if (orgSites) {
      const newList: SelectedPartner[] = [];

      orgSites.forEach((siteData: SiteObject) => {
        if (!siteData.defaultSite) {
          newList.push({
            name: siteData.name,
            id: siteData.siteid,
          });
        }
      });
      setSearchboxSiteList(newList);
      setSearchboxFilteredSiteList(newList);
    }
  }, [orgSites]);

  const [selectedSite, setSelectedSite] = useState<SelectedPartner>({ id: '', name: '' });
  const [searchSite, setSearchSite] = useState(selectedSite.name || '');

  // Schedules
  const [activeSchedule, setActiveSchedule] = useState<ScheduleObject>(newSchedule);
  const [openScheduleModal, _setOpenScheduleModal] = useState(2);
  const setOpenScheduleModal = (newVal: number) => {
    if (newVal === 0) {
      setActiveSchedule(newSchedule);
    }

    _setOpenScheduleModal(2);
    setOpenModal(0);
  };
  const { data: schedulesResp, mutate: updateSchedules } = useSWR<Array<ScheduleObject>>(
    (defaultSiteId && (orgId || selectedAccount?.id)) ? [`/organizations/${orgId || selectedAccount.id}/sites/${defaultSiteId}/schedules-detailed`, 'SchedulesPage'] : null,
  );

  const { data: groupsResp, mutate: updateGroup } = useSWR<Array<GroupObject>>(
    (defaultSiteId && (orgId || selectedAccount?.id)) ? [`/organizations/${orgId || selectedAccount.id}/sites/${defaultSiteId}/groups`, 'GroupsPage'] : null,
  );
  const defaultSLG = useMemo(() => (groupsResp ? Array.from(groupsResp.values()).filter((group: GroupObject) => group.type === 'SITE_LIGHTING').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : []), [groupsResp]);
  const [missingSiteLightingGroup, setMissingSiteLightingGroup] = useState(false);
  const [showMissingSiteLightingGroupWarning, setShowMissingSiteLightingGroupWarning] = useState(true);

  useEffect(() => {
    if (groupsResp && defaultSLG.length === 0) {
      setMissingSiteLightingGroup(true);
      setShowMissingSiteLightingGroupWarning(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsResp, defaultSLG]);

  const siteRowRenderer = ({ key, index, style }: ListRowProps, setToggle: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!searchboxFilteredSiteList) {
      return <></>;
    }

    const { name, id } = searchboxFilteredSiteList[index];

    return (
      <li
        key={key}
        style={style}
        className="search-partner--list"
        onClick={() => {
          setToggle(false);
          setSelectedSite({ id: searchboxFilteredSiteList[index].id, name: searchboxFilteredSiteList[index].name });

          const filtered = searchboxSiteList?.filter((item: SelectedPartner) => item.name.toLowerCase().includes(name.toLowerCase()));
          setSearchboxFilteredSiteList(filtered);
          setListComputedHeight(Math.min(filtered.length * listRowHeight, minListHeight));

          setListComputedHeight(0);
        }}
      >
        <span className={id === selectedSite.id ? 'selected' : ''}>{name || ''}</span>
      </li>
    );
  };
  const handleSiteListChange = (name: string) => {
    setSelectedSite({ id: '', name: '' });
    setSearchSite(name);

    if (name === '') {
      setSearchboxFilteredSiteList(searchboxSiteList);
      setListComputedHeight(0);
    } else {
      const filtered = searchboxSiteList?.filter((item: SelectedPartner) => item.name.toLowerCase().includes(name.toLowerCase()));
      setSearchboxFilteredSiteList(filtered);
      setListComputedHeight(Math.min(filtered.length * listRowHeight, minListHeight));
    }
  };

  const modifySchedule = () => {
    if (schedulesResp) {
      setActiveSchedule(schedulesResp[0]);
      setOpenModal(openModifyScheduleModal);
    }
  };

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'Commissioning');
  return (
    <>
      <div className="content commissioning siteassignment users">
        <AdminSidebar title="Account admin">
          <AdminSidebarAdminItems path={window.location.pathname} />
        </AdminSidebar>
        {true && (
        <div className="page-content users-content">
          <TableToolbar title="Site assignment" addClass="users">
            <div className={`tabletoolbar__children ${!multipleAccounts ? 'no-border' : ''}`}>
              {multipleAccounts && (
                <div className="search-partner-and-account margin-right-auto">
                  <Searchbox
                    type="light"
                    list={filteredAccountList}
                    title={searchAccount || 'Select account'}
                    icon={<ArrowIcon className="search__icon" />}
                    alwaysShowIcon
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleAccountListChange(event.target.value)}
                    searchValue={searchAccount}
                    listWidth={listWidth}
                    listRowHeight={listRowHeight}
                    listRowRenderer={accountRowRenderer}
                    rowNumber={searchBoxMaxItems}
                    iconClickToggleList
                    className="search-partner last"
                    listStyle={{ position: 'absolute' }}
                    isListOpen={accountListComputedHeight}

                  />
                </div>
              )}
              {defaultSiteId && (
                <>
                  {(!isValidating) && (
                    <div>
                      <Tooltip text="Refresh the node list" offset={tooltipOffset}>
                        <SyncIcon
                          data-testid="icon"
                          className="list-element__refresh"
                          onClick={() => {
                            updateSiteAssignment();
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {(isValidating) && (
                    <div>
                      <Tooltip text="Refresh the node list" offset={tooltipOffset}>
                        <SyncIcon
                          data-testid="icon"
                          className="list-element__refresh refreshLoading"
                        />
                      </Tooltip>
                    </div>
                  )}

                  <div className="modifySchedule">
                    <Button
                      label="Modify schedule"
                      onClick={() => {
                        modifySchedule();
                      }}
                      buttonType="secondary"
                    />
                  </div>

                </>
              )}
              <div className="commissioning__info">
                <div className="commissioning__info--element">
                  Total nodes
                  <span className="commissioning__info--element-val">
                    {nodesNodeObjectList?.length.toLocaleString() || '0'}
                  </span>
                </div>
                <div className="commissioning__info--element">
                  Nodes filtered
                  <span className="commissioning__info--element-val">
                    {filteredCount >= 0 ? filteredCount.toLocaleString() : nodesNodeObjectList?.length.toLocaleString() || '0'}
                  </span>
                </div>
                <div className="commissioning__info--actions">
                  { isVerizonUserorAdminUser && (
                    <button
                      type="button"
                      className={`commissioning__info--actions-btn ${selectedDropdown === dropdownCommission ? 'selected' : ''}`}
                      onClick={() => setSelectedDropdown(selectedDropdown === dropdownCommission ? 0 : dropdownCommission)}
                    >
                      <CommisionIcon />
                    </button>
                  )}
                  {
                  selectedDropdown === dropdownCommission && (
                    <div className="actions-commission">
                      <DropDown>
                        <>
                          <button
                            type="button"
                            onClick={() => {
                              if (selectedCommissioningObjectMap.size === 0) {
                                addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
                              } else {
                                setOpenModal(openModalGPSMode);
                                setSelectedDropdown(0);
                              }
                              setSelectedDropdown(0);
                            }}
                            className="actions-commission__element"
                          >
                            Restart auto-commission
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if (selectedCommissioningObjectMap.size === 0) {
                                addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
                              } else if (selectedCommissioningObjectMap.size > maxSelectedNodes) {
                                addNotification({ type: 'error', message: `More than ${maxSelectedNodes} nodes selected. Please fix selection and try again.` });
                              } else {
                                setOpenModal(openModalManualAssignment);
                                setSelectedDropdown(0);
                              }
                              setSelectedDropdown(0);
                            }}
                            className="actions-commission__element"
                          >
                            Manual commission
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if (selectedCommissioningObjectMap.size === 0) {
                                addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
                              } else if (selectedCommissioningObjectMap.size > maxSelectedRebootNodes) {
                                addNotification({ type: 'error', message: `More than ${maxSelectedRebootNodes} nodes selected. Please fix selection and try again.` });
                              } else {
                                setOpenModal(openModalRebootNodes);
                                setSelectedDropdown(0);
                              }
                              setSelectedDropdown(0);
                            }}
                            className="actions-commission__element"
                          >
                            Reboot node(s)
                          </button>
                          { isSensityUserAdmin
                            && (
                            <button
                              type="button"
                              onClick={() => {
                                if (selectedCommissioningObjectMap.size === 0) {
                                  addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
                                } else if (selectedCommissioningObjectMap.size > maxSelectedRebootNodes) {
                                  addNotification({ type: 'error', message: `More than ${maxSelectedRebootNodes} nodes selected. Please fix selection and try again.` });
                                } else {
                                  setOpenModal(openModalRetryAddToSLG);
                                  setSelectedDropdown(0);
                                }
                                setSelectedDropdown(0);
                              }}
                              className="actions-commission__element"
                            >
                              Retry add to SLG
                            </button>
                            )}
                        </>
                      </DropDown>
                    </div>
                  )
                }
                  {isSensityUserAdmin ? (
                    <Tooltip text="Permanently delete nodes" offset={tooltipOffset}>
                      <button
                        type="button"
                        className={`commissioning__info--actions-btn ${selectedDropdown === dropdownDeleteNodes ? 'selected' : ''}`}
                        onClick={() => {
                          if (selectedCommissioningObjectMap.size === 0) {
                            addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
                          } else if (selectedCommissioningObjectMap.size > maxSelectedDeleteNodes) {
                            addNotification({ type: 'error', message: `More than ${maxSelectedDeleteNodes} nodes selected. Please fix selection and try again.` });
                          } else {
                            setSelectedDropdown(dropdownDeleteNodes);
                          }
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    </Tooltip>
                  ) : <></>}
                  <button
                    type="button"
                    className={`commissioning__info--actions-btn ${selectedDropdown === dropdownMore ? 'selected' : ''}`}
                    onClick={() => setSelectedDropdown(selectedDropdown === dropdownMore ? 0 : dropdownMore)}
                  >
                    <DotdotdotIcon />
                  </button>
                  {
                  selectedDropdown === dropdownMore && (
                    <div className="actions-commission">
                      <DropDown>
                        <>
                          <button type="button" onClick={() => { setOpenModal(openModalCustomizeList); setSelectedDropdown(0); }} className="actions-commission__element">
                            <CustomizeIcon />
                            Customize list
                          </button>
                          <button type="button" onClick={() => { setOpenModal(openModalDownloadList); setSelectedDropdown(0); }} className="actions-commission__element">
                            <DownloadIcon />
                            Download list
                          </button>
                          {/*  <SelectNodesByFile
                            addClass="actions-commission__element"
                            setSelectedNodes={setSelectedCommissioningObjectMap}
                            nodesList={nodesNodeObjectMap()}
                            selectedNodes={selectedCommissioningObjectMap}
                            closeDropdown={() => setSelectedDropdown(0)}
                          >
                            <>
                              <AssignIcon />
                              Select nodes by file
                            </>
                          </SelectNodesByFile>
                           <button type="button" onClick={() => { handleFileUpload(); setSelectedDropdown(0); }} className="actions-commission__element">
                              <UploadIcon />
                              Add nodes from file
                            </button> */ }
                        </>
                      </DropDown>
                    </div>
                  )
                }
                </div>
              </div>
            </div>
          </TableToolbar>
          {(orderedCustomers && siteList) && !defaultSiteId && !multipleAccounts
          && (
          <div className="nodefaultsite">
            Your account(s) have not been set up to
            <br />
            process unassigned nodes.
            <br />
            If needed, please contact Verizon support by email at
            <br />
            IoTtechsupport@verizonwireless.com
          </div>
          )}
          {isValidating
          && (
            <Loading />
          )}
          {nodesNodeObjectList
          && (
          <div className="table table--light auto-height">
            <Table
              headers={headerList.filter((h: TableHeadersProp) => !h.isHidden)}
              data={nodesNodeObjectList}
              selectedItems={selectedCommissioningObjectMap}
              setSelectedItems={setSelectedCommissioningObjectMap}
              dataFetchedAlready={false}
              caller="nodes"
              skipCellMeasure
              multiSelect
              fixColCount={fixColCount}
              setFilteredCount={setFilteredCount}
              autoheight
            />
          </div>
          )}
        </div>
        )}
      </div>
      {modalToggle === modalToggleReboot && (
        <RebootModal
          customerid={selectedAccount.id}
          siteid={defaultSiteId}
          nodeList={selectedNode ? [selectedNode.nodeid] : []}
          setModalOpen={() => setModalToggle(0)}
        />
      )}
      {modalToggle === modalToggleCollectGPS && (
        <CollectGPSModal
          nodeList={selectedNode ? [selectedNode.nodeid] : []}
          setModalOpen={() => setModalToggle(0)}
        />
      )}
      {modalToggle === modalToggleManualGPS && (
        <ManualGPSModal
          nodeList={selectedNode ? [selectedNode.nodeid] : []}
          setModalOpen={() => setModalToggle(0)}
          customerId={orgId || selectedAccount.id}
          siteId={defaultSiteId}
        />
      )}
      { /* }
      {modalToggle === modalToggleEditNote && (
      <Modal
        title="Edit note"
        width="360"
        setModalOpen={() => { setModalToggle(0); setEditTextBox(''); }}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          setModalToggle(0);
          const postbody = {
            nodeids: [selectedNode?.nodeid],
            note: editTextBox.value,
          };

          try {
            const resp = await putRequest(
              '/nodes/note',
              postbody,
            );

            if (!resp.error) {
              addNotification({ type: 'success', message: 'Your "Edit note" operation was successful' });
            } else {
              addNotification({ type: 'error', message: 'Your "Edit note" operation has failed.' });
            }
          } catch (error) {
            addNotification({ type: 'error', message: 'Your "Edit note" operation has failed.' });
          }
        }}
        secondaryButtonLabel="Cancel"
        className="commissioning"
      >
        <Textbox
          label="Note"
          error={editTextBox.error}
          errorMessage={editTextBox.editErrorMsg}
          placeholder="Edit note"
          name="Name"
          type="text"
          value={editTextBox.value}
          onChange={(e) => setEditTextBox(e.target.value)}
        />
        <span className="commissioning__note">Max 45 characters</span>
      </Modal>
      )}
      { */ }

      {openModal === openModalRetryAddToSLG && (
        <RetryAddToSLGModal
          nodeList={Array.from(selectedCommissioningObjectMap.keys())}
          setModalOpen={() => setOpenModal(0)}
          customerid={orgId || selectedAccount.id}
          siteid={defaultSiteId}
        />
      )}

      {openModal === openModalRebootNodes && (
        <Modal
          title="Reboot modem"
          width="360"
          setModalOpen={() => setOpenModal(0)}
          primaryButtonLabel="Confirm"
          primaryButtonAction={async () => {
            setOpenModal(0);
            const nodeIdList = Array.from(selectedCommissioningObjectMap.keys());
            try {
              const requests: { path: string}[] = Array.from(nodeIdList).map(
                (value) => ({
                  path: `/organizations/${selectedAccount.id}/sites/${defaultSiteId}/lights-control/reboot/nodes/${value}`,
                }),
              );
              postRequestAll(requests)
                .then((results) => {
                  if (results.resolved === requests.length) {
                    addNotification({ type: 'info', message: 'Modem reboot command was sent to the selected node(s).' });
                  } else {
                    addNotification({ type: 'error', message: 'Modem reboot command failed to send to the selected node(s).' });
                  }
                });
            } catch (error) {
              addNotification({ type: 'error', message: `Modem reboot command failed: ${error}` });
            }
          }}
          secondaryButtonLabel="Cancel"
          className="commissioning"
        >
          <span>Are you sure you want to reboot the modem for these node(s)?</span>
        </Modal>
      )}
      {openModal === openModalGPSMode && (
      <Modal
        title="Begin Auto-commissioning"
        width="336"
        setModalOpen={() => setOpenModal(0)}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          setOpenModal(0);
          const nodeIdList = Array.from(selectedCommissioningObjectMap.keys());
          try {
            const requests: { path: string}[] = Array.from(nodeIdList).map(
              (value) => ({
                path: `/nodes/${value}/commission`,
              }),
            );
            postRequestAll(requests)
              .then((results) => {
                if (results.resolved === requests.length) {
                  addNotification({ type: 'success', message: 'The selected node(s) has successfully started commissioning.' });
                } else if (results.resolved === 0) {
                  addNotification({ type: 'error', message: 'Your "Auto-commission" operation has failed.' });
                } else {
                  addNotification({ type: 'error', message: `Only ${results.resolved} of the ${requests.length} selected nodes have successfully started commissioning.` });
                }
              });
          } catch (error) {
            addNotification({ type: 'error', message: 'Your "Auto-commission" operation has failed.' });
          }
        }}
        secondaryButtonLabel="Cancel"
        className="auto-commission"
      >
        <span>Are you sure you want to set GPS mode on the selected nodes?</span>
      </Modal>
      )}

      {openModal === openModalManualAssignment && (
      <Modal
        title="Assign to site manually"
        width="360"
        height={`${listComputedHeight + manualCommissionModalHeight}`}
        setModalOpen={() => setOpenModal(0)}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          setOpenModal(0);
          const nodeIdList = Array.from(selectedCommissioningObjectMap.values());

          try {
            const selectedCustomerId = siteList?.find((s) => s.siteid === selectedSite.id)?.orgid;
            if (selectedCustomerId === undefined) {
              addNotification({ type: 'warning', message: 'Please select a site before clicking the Submit button.' });
            } else {
              const path = `/organizations/${selectedCustomerId}/sites/${selectedSite.id}/nodes/commission`;
              const data: string[] = nodeIdList.map((value) => value.node_id);
              postRequest(path, data)
                .then((result) => {
                  if (!result.error) {
                    addNotification({ type: 'success', message: 'The selected node(s) have successfully started assigning to the site.' });
                    setSelectedCommissioningObjectMap(new Map([]));
                    updateSiteAssignment();
                  } else {
                    addNotification({ type: 'error', message: 'Your "Manual assign" operation has failed.' });
                  }
                });
            }
          } catch (error) {
            addNotification({ type: 'error', message: 'Your "Manual assign" operation has failed.' });
          }

          clearInputFileAndCloseModal();
        }}
        secondaryButtonLabel="Cancel"
        className={`auto-commission ${listComputedHeight > 0 ? 'modal-full-height' : ''}`}
      >
        <div className="search-site margin-right-auto">
          <span className="search-site__label">
            Assign to site
          </span>
          <Searchbox
            list={searchboxFilteredSiteList}
            type="light"
            icon={<ArrowIcon className="search__icon" />}
            alwaysShowIcon
            title="Select site"
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleSiteListChange(event.target.value)}
            searchValue={selectedSite.name || searchSite}
            listWidth={listWidth}
            listRowHeight={listRowHeight}
            listRowRenderer={siteRowRenderer}
            rowNumber={searchBoxMaxItems}
            iconClickToggleList
            className="search-site__searchox"
            listStyle={{ position: 'absolute' }}
            setIsListOpen={setListComputedHeight}
            isListOpen={listComputedHeight}
          />
        </div>
      </Modal>
      )}
      {selectedDropdown === dropdownDeleteNodes && (
        <DeleteNodes
          selectedCustomer={selectedAccount}
          selectedSite={selectedSite}
          selectedNodes={selectedCommissioningObjectMap}
          setOpenModal={setSelectedDropdown}
          updateSiteAssigment={updateSiteAssignment}
        />
      )}
      {openModal === openModalDownloadList && (
        <DownloadList
          setOpenModal={setOpenModal}
          headerList={headerList}
          skipColumns={['rowSelectCheckbox', 'actions']}
          entireList={nodesNodeObjectList}
          selectedNodes={selectedCommissioningObjectMap}
          fileName="commissioning_list"
        />
      )}
      {openModal === openModalCustomizeList && (
        <CustomizeList
          setOpenModal={setOpenModal}
          headerList={headerList}
          setHeaders={setHeaders}
          defaultHeaders={defaultHeaders.SiteAssignment}
        />
      )}
      {openModal === openModalAddNodesFromFile && (
        <Modal
          width="330"
          title="Add nodes from file"
          setModalOpen={() => setOpenModal(0)}
          primaryButtonLabel="Upload"
          primaryButtonAction={async () => {
            try {
              const resp = await Utils.sendFormData('/nodes', undefined, uploadFile);
              const uploadedNodeidList = await resp.json();
              if (uploadedNodeidList.error || uploadedNodeidList.length === 0) {
                addNotification({ type: 'error', message: 'Your "Add nodes from file" operation has failed.' });
              } else {
                addNotification({ type: 'success', message: 'Your "Add nodes from file" operation is completed.' });
                updateSiteAssignment();
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Add nodes from file" operation has failed' });
            }
            clearInputFileAndCloseModal();
          }}
          secondaryButtonLabel="Cancel"
          secondaryButtonAction={() => clearInputFileAndCloseModal()}
        >
          <div className="add-nodes">
            <span className="add-nodes__title">File name: </span>
            <span>{uploadFile?.name}</span>
            <div className="add-nodes__content">
              Upload a CSV file with values on each line for nodeid, model, orgid, siteid, latitude and longitude (in that order).
              Also include a header row with those column names.
            </div>
          </div>
        </Modal>
      )}
      <input type="file" id="file" ref={inputFile} accept=".csv" style={{ display: 'none' }} onChange={onChangeFileUpload} />
      { openModal === openModifyScheduleModal && (
      <ScheduleModal
        selectedDefaultCustomer={orgId || selectedAccount.id}
        selectedDefaultSite={defaultSiteId}
        schedules={schedulesResp}
        activeSchedule={activeSchedule}
        openScheduleModal={openScheduleModal}
        setOpenScheduleModal={setOpenScheduleModal}
        updateSchedules={updateSchedules}
      />
      )}

      { (missingSiteLightingGroup && showMissingSiteLightingGroupWarning)
          && (
          <CreateLigGroupWithNodes
            setOpenModal={() => setShowMissingSiteLightingGroupWarning(false)}
            selectedNodes={new Map()}
            selectedDefaultCustomer={orgId || selectedAccount.id}
            selectedDefaultSite={defaultSiteId}
            addNotification={addNotification}
            schedulesResp={schedulesResp}
            updateGroup={updateGroup}
            siteLightingGroupOnly
          />
          )}
    </>
  );
}

export default SiteAssignmentPage;
