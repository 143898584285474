import React from 'react';
import Modal from '../../../Common/Components/Modal';
import { StopJobProps } from '../../../types/StopJobProps';
import { useAppContext } from '../../../utils/AppContext';
import { patchRequest, postRequest } from '../../../utils/fetch';

function StopJob(props: StopJobProps): JSX.Element {
  const {
    setModalOpen,
    selectedCustomer,
    selectedSite,
    job,
  } = props;

  const { addNotification } = useAppContext();

  return (
    <div className="stop-job">
      <Modal
        title="Stop job"
        width="325"
        setModalOpen={setModalOpen}
        primaryButtonAction={async () => {
          if (job !== undefined) {
            if (['UPDATE_FIRMWARE_NODE', 'UPDATE_FIRMWARE_SITE', 'UPDATE_FIRMWARE_GROUP'].includes(job.operationType)) {
              try {
                const result = await postRequest(
                  `/organizations/${selectedCustomer?.id}/sites/${selectedSite?.id}/ota_status/${job.id}/stop`,
                );

                if (!result.error) {
                  addNotification({ type: 'success', message: 'Firmware update successfully stopped.' });
                } else {
                  addNotification({ type: 'error', message: `Cannot stop the update. API reported error: ${result.error}` });
                }
                setModalOpen(false);
              } catch (e) {
                addNotification({ type: 'error', message: 'The update was not able to be stopped.' });
                setModalOpen(false);
              }
            } else {
              try {
                const result = await patchRequest(
                  `/organizations/${selectedCustomer?.id}/sites/${selectedSite?.id}/async-jobs/${job.id}`,
                  { abort: true },
                );

                if (!result.error) {
                  addNotification({ type: 'success', message: `Job "${job.operationType}" successfully stopped.` });
                } else {
                  addNotification({ type: 'error', message: `Cannot stop the selected job. API reported error: ${result.error}` });
                }
                setModalOpen(false);
              } catch (e) {
                addNotification({ type: 'error', message: 'The job was not able to be stopped.' });
                setModalOpen(false);
              }
            }
          }
        }}
        secondaryButtonAction={() => setModalOpen(false)}
        primaryButtonLabel="Submit"
        secondaryButtonLabel="Cancel"
      >
        <div className="content">
          <div className="content-text">
            {
              job.operationType === 'Update application firmware' && job.stats?.total > 1 && (
              <div>
                <span>Are you sure you want to stop the job for the remaining nodes?</span>
                <span className="content-text__comment">You can resume the job later.</span>
              </div>
              )
             }
            {
              job.operationType === 'Update application firmware' && job.stats?.total === 1 && (
              <div>
                <span>Are you sure you want to stop the job for the remaining nodes?</span>
              </div>
              )
            }
            {
              job.operationType !== 'Update application firmware' && job.stats?.total === 1 && (
              <div>
                <span>Are you sure you want to stop the job?</span>
              </div>
              )
            }
            {
              job.operationType !== 'Update application firmware' && job.stats?.total > 1 && (
              <div>
                <span>Are you sure you want to stop the job?</span>
                <span className="content-text__comment">You can resume the job later.</span>
              </div>
              )
            }
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default StopJob;
