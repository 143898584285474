import React, { createRef, useState } from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import Button from '../../../Common/Components/Button';
import Checkbox from '../../../Common/Components/Checkbox';
import Loading from '../../../Common/Components/Loading';
import { GroupListsProps } from '../../../types/GroupsLists';
import GroupsDetailView from './GroupsDetailView';

// icons
import { ReactComponent as CaretIcon } from '../../../img/icons/down-arrow.svg';
import { ReactComponent as EditIcon } from '../../../img/icons/edit.svg';
import { ReactComponent as ScheduleIcon } from '../../../img/icons/schedule-large.svg';
import { ReactComponent as TotalEnergyIcon } from '../../../img/icons/smart-meter.svg';
import Utils from '../../../utils/Utils';

const rowHeightExpanded = 203;
const rowHeight = 43;
function LightingGroupsList(props: GroupListsProps): JSX.Element {
  const {
    data,
    selectedCustomer,
    selectedSite,
    selectedItems,
    setSelectedItems,
    setEditGroup,
    setOpenEditModal = () => undefined,
    setEnergyGroup,
    setOpenEnergyModal = () => undefined,
    groupsSchedule,
    setGroupSchedule,
    setOpenScheduleModal,
    scheduledDriverLevels,
  } = props;
  const [openedDetailView, setOpenDetailView] = useState<Array<number>>([]);
  const isNonReadOnly = Utils.isNonReadOnly();
  const list = createRef<List>();
  const getRowHeight = ({ index }: {index: number}) => (openedDetailView.includes(index) ? rowHeightExpanded : rowHeight);
  const lightingGroupRenderer = ({ key, index, style }: ListRowProps) => (
    <div key={key} style={style}>
      <div className={`groups-list__elements ${openedDetailView.includes(index) ? 'open' : ''}`}>
        <div className="groups-list__first-cell">
          <CaretIcon
            className={`groups-list__element ${openedDetailView.includes(index) ? 'groups-list__element--open' : ''} `}
            onClick={() => {
              if (openedDetailView.includes(index)) {
                setOpenDetailView(openedDetailView.filter((item) => item !== index));
                if (setGroupSchedule) {
                  setGroupSchedule(groupsSchedule?.get(data[index].groupId));
                }
              } else {
                setOpenDetailView([...openedDetailView, index]);
                if (setGroupSchedule) {
                  setGroupSchedule(groupsSchedule?.get(data[index].groupId));
                }
              }
              list.current?.forceUpdateGrid();
              list.current?.recomputeRowHeights(index);
            }}
          />
          <Checkbox
            checked={selectedItems.includes(data[index].groupId)}
            onClick={(checked) => {
              if (checked) {
                setSelectedItems([...selectedItems, data[index].groupId], 'lightingGroupIds');
              } else {
                setSelectedItems(
                  selectedItems.filter((id: string) => id !== data[index].groupId),
                  'lightingGroupIds',
                );
              }
            }}
          />
          <div className="groups-list__element">{data[index].name}</div>
        </div>
        <div className="groups-list__alerts-cell">
          <div className="groups-list__element">{data[index].nodeList?.length || 0}</div>
          { /* <div className="groups-list__element groups-list__element--ok-nodes">{ data[index].nodeList?.length - data[index].alertCount || 0}</div>
          <div className="groups-list__element groups-list__element--alert-nodes">{data[index].alertCount}</div> */ }
        </div>
        <div className="groups-list__schedules-cell">
          <ScheduleIcon />
          <Button
            extraClasses="groups-list__element groups-list__element--schedule"
            onClick={() => {
              if (setGroupSchedule && setOpenScheduleModal) {
                setGroupSchedule(groupsSchedule?.get(data[index].groupId));
                setOpenScheduleModal(1);
              }
            }}
            label={groupsSchedule?.get(data[index].groupId)?.name || ''}
          />
        </div>
        <Button extraClasses="groups-list__action-cell" onClick={() => { setOpenEnergyModal(true); setEnergyGroup(data[index]); }}>
          <TotalEnergyIcon className="groups-list__element" />
        </Button>
        {isNonReadOnly && (
        <Button extraClasses="groups-list__action-cell" onClick={() => { setOpenEditModal(true); setEditGroup(data[index]); }}>
          <EditIcon className="groups-list__element" />
        </Button>
        )}
      </div>
      <div>
        {openedDetailView.includes(index) && (
        <GroupsDetailView
          group={data[index]}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          descriptionLabel="Group description"
          scheduledDriverLevel={scheduledDriverLevels?.get(data[index].scheduleId) || 0}
        />
        )}
      </div>
    </div>
  );

  return (
    <>
      {data.length ? (
        <AutoSizer disableWidth>
          {({ height }) => (
            <List
              ref={list}
              width={1}
              height={height}
              rowHeight={getRowHeight}
              rowCount={data && data.length}
              rowRenderer={lightingGroupRenderer}
              list={data}
              containerStyle={{
                width: '100%',
                maxWidth: '100%',
              }}
              style={{
                width: '100%',
              }}
            />
          )}
        </AutoSizer>
      ) : (<Loading />)}
    </>
  );
}

export default LightingGroupsList;
