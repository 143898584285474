/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
import { GetAllRequests, NonGetAllRequests } from '../types/Fetch';
import { BASEPATH, nodesPageSize } from './constants';
import FetchError from './FetchError';
import Utils from './Utils';
import { publish } from './events.js';

function getURL(path: string): string {
  const url = `${window.NSN.apiURLV4 || window.NSN.apiURL}${path}`;
  return url;
}

async function useSWRGetRequest(path: string, _cacheKey?: string): Promise<any> {
  return getRequest(path);
}

async function getRequest(
  path: string,
  options: RequestInit = {},
  modifyData: (data: any | Array<any>) => any | Array<any> = (data) => data,
  abortSignal?: AbortSignal,
  retry?: boolean,
): Promise<any> {
  const maxAttempts = 2;
  let url = getURL(path);

  if (!options || typeof options === 'string') {
    // eslint-disable-next-line no-param-reassign
    options = {};
  }

  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers();
  }

  // eslint-disable-next-line prefer-template
  (options.headers as Headers).set(Utils.JSONWebTokenName, 'bearer ' + (localStorage.getItem(Utils.JSONWebTokenName) || ''));
  (options.headers as Headers).set('x-lighting-ui', 'true');
  if (Utils.isVerizonUser()) {
    (options.headers as Headers).set('x-user-role', 'sensity');
  }
  if (Utils.isAssumingRole()) {
    if (localStorage.getItem(Utils.isPreviousLoggedInUser) === 'true') {
      (options.headers as Headers).set('x-assume-role', `${Utils.getAssumedRole().toUpperCase()}:${Utils.getAssumedOrg()}`);
    } else {
      Utils.clearAssumedRole();
    }
  }

  let uilimit = 1000000;
  if (url.indexOf('&uilimit=') > 0) {
    uilimit = parseInt(url.slice(url.lastIndexOf('&') + 9), 10);
    url = url.substring(0, url.lastIndexOf('&'));
  }

  let response;

  try {
    response = await fetch(url, { credentials: 'include', ...options, signal: abortSignal });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    if (!response && await tryLogin(response) === false) {
      return {
        data: {},
        error: '',
        status: 403,
      };
    }
  }

  if (response.headers.has(Utils.csrfTokenName)) {
    localStorage.setItem(Utils.csrfTokenName, response.headers.get(Utils.csrfTokenName) || '');
  }
  if (response.headers.has('vsc-oauth-id-token')) {
    localStorage.setItem(Utils.JSONWebTokenName, response.headers.get('vsc-oauth-id-token') || '');
  }

  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      if (response.headers.get('x-amzn-errortype') === 'IncompleteSignatureException') {
        // eslint-disable-next-line no-console
        console.log(`NOT DEPLOYED (returning []): ${path}`);
        return modifyData([]);
      }

      if (!retry && await tryLogin(response)) {
        return getRequest(path, options, modifyData, abortSignal, true);
      }
    }
    if (response.status === 404) {
      // temporarily return empty array on 404s
      return ({
        data: {},
        ok: false,
        status: 404,
        error: 'Not found',
      });
    }
    if (response.status === 500) {
      return ({
        data: {},
        ok: false,
        status: response.status,
        error: 'An unexpected error has occurred. Try refreshing the page.',
      });
    }

    const error = new FetchError(`${response.status}: ${response.statusText}`);
    try {
      error.info = await response.json();
    } catch (e) {
      error.info = JSON.stringify(e);
    }

    error.status = response.status;
    throw error;
  }

  if (response.status === 204) {
    return modifyData({});
  }

  try {
    const respObj = await response.json();

    if (respObj.content) {
      // endpoint has chunking

      if (path.includes('/lkp/nodes') && respObj.hasNext) {
        // for /nodes response, send lat/lngs of first chunk to LightingMap component
        publish('drawMarkers', respObj.content.map((compressedNode) => ({
          latitude: compressedNode.P,
          longitude: compressedNode.Q,
          mapStatus: 'Neutral',
        })));
      }

      while (respObj.hasNext && respObj.content.length < uilimit) {
        const separator = url.includes('?') ? '&' : '?';
        const afterUrl = `${url}${separator}after=${encodeURIComponent(respObj.after)}`;
        let attempts = 0;
        let nextRespObj;

        // send progress count to Loading component
        publish('updateProgress', respObj.content.length);

        // attempt each chunk up to maxAttempts (2)
        do {
          attempts += 1;
          const nextResp = await fetch(afterUrl, { credentials: 'include', ...options, signal: abortSignal });
          nextRespObj = await nextResp.json();
        }
        while (nextRespObj.error && attempts < maxAttempts);

        // failure on 2nd..nth chunk retrieval
        if (nextRespObj.error || attempts >= maxAttempts) {
          publish('systemFailure', '');
          return (modifyData(respObj.content));
        }

        // for /nodes response, send lat/lngs of next chunk to LightingMap component
        //  (but exclude last chunk)
        if (path.includes('/lkp/nodes') && nextRespObj.content.length === nodesPageSize) {
          publish('drawMarkers', nextRespObj.content.map((compressedNode) => ({
            latitude: compressedNode.P,
            longitude: compressedNode.Q,
            mapStatus: 'Neutral',
          })));
        }

        respObj.content = [...respObj.content, ...nextRespObj.content];
        respObj.hasNext = nextRespObj.hasNext;
        respObj.after = nextRespObj.after;
      }

      // all chunks received
      // postprocess the results (e.g. expand compresseed /nodes response)
      return (modifyData(respObj.content));
    }
    // no chunking
    // postprocess the results (e.g. expand compresseed /nodes response)
    return modifyData(respObj);
  } catch (e) {
    // error - return empty object
    publish('systemFailure', response.error);
    return modifyData({});
  }
}

async function getRequestAll(
  requests: GetAllRequests,
): Promise<{
  resolved: number,
  rejected: number,
  results: PromiseSettledResult<any>[]
}> {
  const promises: Promise<any>[] = [];

  requests.forEach((request) => {
    promises.push(new Promise((resolve, reject) => {
      getRequest(request.path, request.options || undefined, request.modifyData || undefined, request.abortSignal || undefined)
        .then((result) => resolve(result))
        .catch((e) => reject(e));
    }));
  });

  const results = await Promise.allSettled(promises);
  let resolved = 0;

  results.forEach((result) => {
    if (result.status === 'fulfilled') {
      resolved += 1;
    }
  });

  return {
    resolved,
    rejected: promises.length - resolved,
    results,
  };
}

async function nonGetRequest<I, O>(
  method: string,
  path: string,
  data?: I,
  retry?: boolean,
): Promise<{ data: O, error: string, status: number }> {
  const url = getURL(path);

  const netsenseCsrfToken: string = localStorage.getItem(Utils.csrfTokenName) || '';
  const headers: HeadersInit = new Headers();

  headers.set('Content-Type', 'application/json');
  headers.set('X-CSRF-Token', netsenseCsrfToken);
  // eslint-disable-next-line prefer-template
  headers.set(Utils.JSONWebTokenName, 'bearer ' + (localStorage.getItem(Utils.JSONWebTokenName) || ''));
  headers.set('x-lighting-ui', 'true');
  if (Utils.isAssumingRole()) {
    headers.set('x-assume-role', `${Utils.getAssumedRole().toUpperCase()}:${Utils.getAssumedOrg()}`);
  }

  let response;

  try {
    response = await fetch(url, {
      method,
      credentials: 'include',
      headers,
      body: JSON.stringify(data),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    if (!response && await tryLogin(response) === false) {
      return {
        data: {} as O,
        error: '',
        status: 403,
      };
    }
  }

  if (response.status === 401 || response.status === 403) {
    if (response.headers.get('x-amzn-errortype') === 'IncompleteSignatureException') {
      // eslint-disable-next-line no-console
      console.log(`NOT DEPLOYED (returning []): ${path}`);
      return {
        data: {} as O,
        error: '',
        status: response.status,
      };
    }

    if (!retry && await tryLogin(response)) {
      return nonGetRequest(method, path, data, true);
    }
  }

  const resp = {
    data: {} as O,
    error: '',
    status: response.status,
  };

  if (response.status === 204) {
    return resp;
  }

  if (!response.ok && response.status >= 400) {
    if (response.status === 401 || response.status === 403) {
      resp.error = response.statusText || 'Access Denied';
    } else {
      resp.error = response.statusText || 'Internal Server Error';
    }
  }

  try {
    const respObj = await response.json();

    if (respObj.error || response.status >= 400) {
      resp.error = respObj.message || response.statusText || resp.error;
    }

    if (!resp.error) {
      if (respObj.content) {
        // endpoint has pagination

        while (respObj.hasNext) {
          const separator = url.includes('?') ? '&' : '?';
          const afterUrl = `${url}${separator}after=${encodeURIComponent(respObj.after)}`;

          // eslint-disable-next-line no-await-in-loop
          const nextResp = await fetch(afterUrl, {
            method,
            credentials: 'include',
            headers,
            body: JSON.stringify(data),
          });

          // eslint-disable-next-line no-await-in-loop
          const nextRespObj = await nextResp.json();
          respObj.content = [...respObj.content, ...nextRespObj.content];
          respObj.hasNext = nextRespObj.hasNext;
          respObj.after = nextRespObj.after;
        }

        resp.data = respObj.content;
      } else {
        // no pagination
        resp.data = respObj;
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.log(e);
  }

  return resp;
}

async function nonGetRequestAll<I>(
  method: string,
  requests: NonGetAllRequests<I>,
): Promise<{
  resolved: number,
  rejected: number,
  results: PromiseSettledResult<any>[]
}> {
  const promises: Promise<any>[] = [];

  requests.forEach((request) => {
    promises.push(new Promise((resolve, reject) => {
      nonGetRequest(method, request.path, request.data || undefined)
        .then((result) => resolve(result))
        .catch((e) => reject(e));
    }));
  });

  const results = await Promise.allSettled(promises);
  let resolved = 0;

  results.forEach((result) => {
    if (result.status === 'fulfilled' && result.value.error === '') {
      resolved += 1;
    }
  });

  return {
    resolved,
    rejected: promises.length - resolved,
    results,
  };
}

async function tryLogin(response: Response) {
  try {
    const loginResponse = await fetch(`${window.NSN.apiURLV4}/organizations/users/user_configuration`, { credentials: 'include' });

    if (!loginResponse.ok && (response.status === 401 || response.status === 403)) {
      localStorage.removeItem('netsense-csrf-token');
      localStorage.removeItem(Utils.JSONWebTokenName);
      sessionStorage.clear();
      window.location.href = `${BASEPATH}/login`;
      return false;
    }

    if (loginResponse.headers.has('vsc-oauth-id-token')) {
      localStorage.setItem(Utils.JSONWebTokenName, loginResponse.headers.get('vsc-oauth-id-token') || '');
    }

    return true;
  } catch (e) {
    localStorage.removeItem('netsense-csrf-token');
    localStorage.removeItem(Utils.JSONWebTokenName);
    sessionStorage.clear();
    window.location.href = `${BASEPATH}/login`;
    return false;
  }
}

const postRequest = <I, O>(path: string, data?: I): Promise<{
  data: O, error: string, status: number }> => nonGetRequest<I, O>('POST', path, data);

const putRequest = <I, O>(path: string, data?: I): Promise<{
  data: O, error: string, status: number }> => nonGetRequest<I, O>('PUT', path, data);

const patchRequest = <I, O>(path: string, data?: I): Promise<{
  data: O, error: string, status: number }> => nonGetRequest<I, O>('PATCH', path, data);

const deleteRequest = <I, O>(path: string, data?: I): Promise<{
  data: O, error: string, status: number }> => nonGetRequest<I, O>('DELETE', path, data);

const postRequestAll = <I>(requests: Array<{ path: string, data?: I }>): Promise<{
  resolved: number, rejected: number, results: PromiseSettledResult<any>[] }> => nonGetRequestAll<I>('POST', requests);

const putRequestAll = <I>(requests: Array<{ path: string, data?: I }>): Promise<{
  resolved: number, rejected: number, results: PromiseSettledResult<any>[] }> => nonGetRequestAll<I>('PUT', requests);

const patchRequestAll = <I>(requests: Array<{ path: string, data?: I }>): Promise<{
  resolved: number, rejected: number, results: PromiseSettledResult<any>[] }> => nonGetRequestAll<I>('PATCH', requests);

const deleteRequestAll = <I>(requests: Array<{ path: string, data?: I }>): Promise<{
  resolved: number, rejected: number, results: PromiseSettledResult<any>[] }> => nonGetRequestAll<I>('DELETE', requests);

export {
  useSWRGetRequest,
  getRequest,
  getRequestAll,
  postRequest,
  postRequestAll,
  putRequest,
  putRequestAll,
  patchRequest,
  patchRequestAll,
  deleteRequest,
  deleteRequestAll,
  getURL,
};
