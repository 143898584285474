import React, { useEffect, useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import Textbox from '../../../../../Common/Components/Textbox';
import SelectBox from '../../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import { AdminCreateProjectProps, NewProjectStateProp, NewProjectValidationProp, CreateProjectPostProp } from '../../../../../types/AdminCreateProjectProps';
import Utils from '../../../../../utils/Utils';
import { postRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';
import formValidation from '../../../../../utils/form/formValidation';

function CreateProjectModal(props: AdminCreateProjectProps): JSX.Element {
  const { modalOpen, partnersList, customersList, updateProjects, isSensityUserAdmin, isPartnerUserAdmin, isEndUserAdmin } = props;
  const { addNotification } = useAppContext();
  const [partnerAccountsList, setPartnerAccountsList] = useState(customersList);
  const [createNewProject, setCreateNewProject] = useState<NewProjectStateProp>({
    projectname: '',
    partner: { title: '', key: '' },
    account: { title: '', key: '' },
    roles: { title: '', key: '' },
  });

  const [createProjectValidation, setCreateProjectValidation] = useState<NewProjectValidationProp>({
    accountError: false,
    projectnameError: false,
    projectnameErrorMessage: '',
    accountErrorMessage: '',
    roleError: false,
    roleErrorMessage: '',
    partnerError: false,
    partnerErrorMessage: '',
  });
  const [rolesFilter, setRolesFilter] = useState([{ title: 'None', key: '0' }]);

  const setProjectname = (newName?: string) => {
    const projectname = newName !== undefined ? newName : createNewProject.projectname;
    const validation = formValidation(
      projectname,
      { required: true },
    );
    setCreateNewProject((oldValues) => ({
      ...oldValues,
      projectname,
    }));
    setCreateProjectValidation((oldValues) => ({
      ...oldValues,
      projectnameError: validation.hasError,
      projectnameErrorMessage: validation.errorMsg,
    }));
  };

  const setRole = (newName?: SelectBoxItemType) => {
    const roles = newName !== undefined ? newName : createNewProject.roles;
    const validation = formValidation(
      roles.key,
      { required: true },
    );
    setCreateNewProject((oldValues) => ({
      ...oldValues,
      roles,
    }));
    setCreateProjectValidation((oldValues) => ({
      ...oldValues,
      roleError: validation.hasError,
      roleErrorMessage: validation.errorMsg,
    }));
  };
  const setPartner = (newName?: SelectBoxItemType) => {
    const partner = newName !== undefined ? newName : createNewProject.partner;
    const validation = formValidation(
      partner.key,
      { required: true },
    );
    setCreateNewProject((oldValues) => ({
      ...oldValues,
      partner,
      account: { title: '', key: '' },
      roles: { title: '', key: '' },
    }));
    setCreateProjectValidation((oldValues) => ({
      ...oldValues,
      partnerError: validation.hasError,
      partnerErrorMessage: validation.errorMsg,
      accountError: false,
      accountErrorMessage: '',
    }));
  };

  const checkPartnerSelected = (partneruser?:boolean, newName?: SelectBoxItemType) => {
    const account = newName !== undefined ? newName : createNewProject.account;
    if (!partneruser) {
      const validationError = (createNewProject.partner.key === '');
      const validationErrorMsg = (createNewProject.partner.key === '') ? 'Please select a partner' : '';
      if (validationError) {
        setCreateNewProject((oldValues) => ({
          ...oldValues,
          roles: { title: '', key: '' },
        }));
        setCreateProjectValidation((oldValues) => ({
          ...oldValues,
          accountError: validationError,
          accountErrorMessage: validationErrorMsg,
        }));
      } else {
        setCreateNewProject((oldValues) => ({
          ...oldValues,
          account,
          roles: { title: '', key: '' },
        }));
        setCreateProjectValidation((oldValues) => ({
          ...oldValues,
          accountError: false,
          accountErrorMessage: '',
        }));
      }
    } else {
      setCreateNewProject((oldValues) => ({
        ...oldValues,
        account,
        roles: { title: '', key: '' },
      }));
      setCreateProjectValidation((oldValues) => ({
        ...oldValues,
        accountError: false,
        accountErrorMessage: '',
      }));
    }
  };

  useEffect(() => {
    if (isSensityUserAdmin && createNewProject.partner.title === 'Sensity Systems') {
      setRolesFilter(Utils.getUserRolesByType('sensity'));
    } else if (((isSensityUserAdmin && createNewProject.partner.key !== '')) && createNewProject.account.key === '') {
      setRolesFilter(Utils.getUserRolesByType('partner_terrago'));
    } else if ((createNewProject.account.key !== '')) {
      setRolesFilter(Utils.getUserRolesByType('account_terrago'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createNewProject.partner,
    createNewProject.account,
  ]);

  useEffect(() => {
    if (createNewProject.partner.key !== '') {
      setPartnerAccountsList(customersList.filter((partneraccount) => (partneraccount.type !== 'partner') && (partneraccount.po === createNewProject.partner.key)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createNewProject.partner,
    customersList,
  ]);

  const selectBoxWidth = 300;

  return (
    <div className="project-interactions create-project">
      <Modal
        title="Create new project"
        setModalOpen={modalOpen}
        primaryButtonLabel="Create"
        primaryButtonAction={async () => {
          const currentProject = createNewProject;
          if (currentProject.projectname === '') {
            setProjectname(currentProject.projectname);
            return;
          }
          if (isSensityUserAdmin && currentProject.partner.key === '') {
            setPartner(currentProject.partner);
            return;
          }
          if (currentProject.roles.key === '') {
            setRole(currentProject.roles);
            return;
          }
          try {
            const roles = currentProject.roles.key.toUpperCase();

            const postBody: CreateProjectPostProp = {
              projectName: currentProject.projectname,
            };
            const selectedPartner = (isPartnerUserAdmin) ? partnersList[0].orgid : currentProject.partner.key;
            const selectedPartnerAccount = (currentProject.account.key !== '') ? currentProject.account.key : selectedPartner;
            const selectedCustomerId = (isEndUserAdmin) ? customersList[0].orgid : selectedPartnerAccount;
            const result = await postRequest(
              `/organizations/${selectedCustomerId}/api-key/project?targetRole=${roles}`,
              postBody,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Create project" operation is completed.' });
              if (updateProjects) {
                updateProjects();
              }
            } else {
              addNotification({ type: 'error', message: `Your "Create project" operation has failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Create project" operation has failed.' });
          }
          modalOpen(false);
        }}
        secondaryButtonLabel="Cancel"
        width="410"
        secondaryButtonAction={() => modalOpen(false)}
      >
        <div className="modal-divided">
          <div className="modal-divided__leftcontent">
            <div className="modal-divided__content-data">
              <Textbox
                label="Project name"
                name="ProjectName"
                value={createNewProject.projectname}
                placeholder="Enter"
                onChange={(event) => {
                  setProjectname(event.target.value);
                }}
                isRequired
                error={createProjectValidation.projectnameError}
                errorMessage={createProjectValidation.projectnameErrorMessage}
                gridTemplateColumns={`auto ${selectBoxWidth}px`}
              />
              <SelectBox
                label="Partner"
                onClick={(item: SelectBoxItemType) => setPartner(item)}
                selectedItemKey={createNewProject?.partner?.key || ''}
                list={Utils.arrayToSelectbox(partnersList || [], 'orgid', 'name')}
                title={createNewProject.partner.title || 'Select'}
                type="light"
                error={createProjectValidation.partnerError}
                errorMessage={createProjectValidation.partnerErrorMessage}
                isRequired
                listWidth={selectBoxWidth}
              />
              <SelectBox
                label="Account"
                onClick={(item: SelectBoxItemType) => {
                  checkPartnerSelected(isPartnerUserAdmin, item);
                }}
                selectedItemKey={createNewProject?.account?.key || ''}
                list={Utils.arrayToSelectbox(partnerAccountsList || [], 'orgid', 'name')}
                title={createNewProject.account.title || 'Select'}
                type="light"
                error={createProjectValidation.accountError}
                errorMessage={createProjectValidation.accountErrorMessage}
                listWidth={selectBoxWidth}
              />
              <SelectBox
                label="Role"
                onClick={(item: SelectBoxItemType) =>
                  setCreateNewProject((oldValues) => ({ ...oldValues, roleError: false, roleErrorMessage: '', roles: item }))}
                selectedItemKey={createNewProject?.roles?.key || ''}
                list={Utils.arrayToSelectbox(rolesFilter || [], 'key', 'title')}
                title={createNewProject.roles.title || 'Select'}
                isRequired
                error={createProjectValidation.roleError}
                errorMessage={createProjectValidation.roleErrorMessage}
                type="light"
                listWidth={selectBoxWidth}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateProjectModal;
