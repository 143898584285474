/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  ChangePasswordFormErrors,
  ChangePasswordFormProps,
  ChangePasswordMfaFormErrors,
  ChangePasswordMfaFormProps,
  ChangePasswordModalProps,
} from '../../../types/ChangePasswordProps';
import useChangePasswordForm from '../../../utils/form/useChangePasswordForm';
import useChangePasswordMfaForm from '../../../utils/form/useChangePasswordMfaForm';
import useForm from '../../../utils/useForm';
import Loading from '../../Components/Loading';
import Modal from '../../Components/Modal';
import Textbox from '../../Components/Textbox';

const pwReqNone = 'password-req-none';
const pwReqError = 'password-req-error';
const pwReqOk = 'password-req-ok';

function ChangePasswordModal(props: ChangePasswordModalProps): JSX.Element {
  const { setModalOpen } = props;
  const [onBlurError, setOnBlurError] = useState(false);
  const [mfaModal, setMfaModal] = useState(false);
  const [mfaSession, setMfaSession] = useState('');

  const { validate, submit } = useChangePasswordForm(setMfaModal, setMfaSession);
  const {
    loading,
    handleSubmit,
    handleChange,
    formValues,
    formErrors,
  } = useForm<ChangePasswordFormProps, ChangePasswordFormErrors>(
    validate,
    submit,
    setModalOpen,
  );

  const {
    validate: validateMfa,
    submit: submitMfa,
  } = useChangePasswordMfaForm(formValues, mfaSession);
  const {
    loading: loadingMfa,
    handleSubmit: handleSubmitMfa,
    handleChange: handleChangeMfa,
    formValues: formValuesMfa,
    formErrors: formErrorsMfa,
  } = useForm<ChangePasswordMfaFormProps, ChangePasswordMfaFormErrors>(
    validateMfa,
    submitMfa,
    setModalOpen,
  );

  const reqClass = {
    length: pwReqNone,
    digit: pwReqNone,
    specchar: pwReqNone,
    lowercase: pwReqNone,
    uppercase: pwReqNone,
  };

  if (formValues.newPassword) {
    reqClass.length = formErrors.newPasswordInvalid?.length ? pwReqError : pwReqOk;
    reqClass.digit = formErrors.newPasswordInvalid?.digit ? pwReqError : pwReqOk;
    reqClass.specchar = formErrors.newPasswordInvalid?.specchar ? pwReqError : pwReqOk;
    reqClass.lowercase = formErrors.newPasswordInvalid?.lowercase
      ? pwReqError
      : pwReqOk;
    reqClass.uppercase = formErrors.newPasswordInvalid?.uppercase
      ? pwReqError
      : pwReqOk;
  }

  if (onBlurError && !formErrors.newPasswordInvalid) {
    setOnBlurError(false);
  }

  return (
    <>
      {mfaModal ? (
        <Modal
          title="Authentication"
          width="500"
          secondaryButtonLabel="Request new code"
          secondaryButtonAction={handleSubmit}
          primaryButtonLabel="Submit"
          primaryButtonAction={handleSubmitMfa}
          primaryButtonDisabled={Object.keys(formErrorsMfa).length > 0}
          setModalOpen={setModalOpen}
          className="changepassword-mfa"
        >
          <>
            <Loading isLoading={loadingMfa.current} />
            <span>Please check your mobile device for authentication code.</span>
            <Textbox
              type="text"
              name="mfaCode"
              value={formValuesMfa.mfaCode || ''}
              placeholder="Enter"
              onChange={handleChangeMfa}
              label="Enter authentication code"
              disabled={
                (window.NSN.userInfo.idpUser && window.NSN.userInfo.idpUser === true)
                || false
              }
              autofocus
            />
          </>
        </Modal>
      ) : (
        <Modal
          title="Change password"
          width="500"
          secondaryButtonLabel="Close"
          primaryButtonLabel="Change password"
          primaryButtonAction={handleSubmit}
          primaryButtonDisabled={(
            Object.keys(formErrors).length > 0
            || !formValues.oldPassword
            || !formValues.newPassword
            || !formValues.newPasswordAgain
          )}
          setModalOpen={setModalOpen}
          className="changepassword"
        >
          <>
            <Loading isLoading={loading.current} />
            <div className="modal__content-left">
              <Textbox
                type="password"
                name="oldPassword"
                value={formValues.oldPassword || ''}
                placeholder="Enter"
                onChange={handleChange}
                label="Current password"
                autofocus
              />
              <Textbox
                type="password"
                name="newPassword"
                value={formValues.newPassword || ''}
                placeholder="Enter"
                onChange={handleChange}
                onBlur={() => setOnBlurError(!!formErrors.newPasswordInvalid)}
                label="Enter new password"
                error={onBlurError}
                errorMessage="Password does not meet requirements."
              />
              <Textbox
                type="password"
                name="newPasswordAgain"
                value={formValues.newPasswordAgain || ''}
                placeholder="Enter"
                onChange={handleChange}
                label="Confirm new password"
                error={(!!formValues.newPasswordAgain
                  && formValues.newPassword !== formValues.newPasswordAgain
                )}
                errorMessage="Passwords do not match."
              />
            </div>
            <div className="modal__content-right">
              <span className="password-req-label">Passwords must contain:</span>
              <div className="password-req">
                <span className={reqClass.length}>At least 8 characters</span>
                <span className={reqClass.digit}>A digit (0-9)</span>
                <span className={reqClass.specchar}>
                  A special character: ! # $ % & ( ) * , - . /
                </span>
                <span className={reqClass.lowercase}>A lowercase letter (a-z)</span>
                <span className={reqClass.uppercase}>An uppercase letter (A-Z)</span>
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
}

export default ChangePasswordModal;
