/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useMemo } from 'react';
import useSWR from 'swr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';

import { PageComponentProps } from '../../../../types/PageComponentProps';
import AdminSidebarConfigItems from '../../Components/AdminSidebarConfigItems';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import CreateAlarmConfig from './Components/CreateAlarmConfig';
import TableToolbar from '../../../Admin/Pages/Components/TableToolbar';
import { AlarmConfigObject } from '../../../../types/AlarmConfigObject';
import SelectBox from '../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../types/SelectBoxPropsType';
import { RadioButtonElementProps } from '../../../../types/RadioButtonsProps';
import Utils from '../../../../utils/Utils';
import { getRequest } from '../../../../utils/fetch';
import { GroupObject } from '../../../../types/GroupObject';
import RadioButtons from '../../../../Common/Components/RadioButtons';

function AlarmConfigPage(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;

  const listWidth = 332;
  const [selectNodes, setSelectNodes] = useState<RadioButtonElementProps>({ label: 'Site', key: 'Site' });
  const [selectedGroup, setSelectedGroup] = useState<SelectBoxItemType>();
  const [canSubmit, setCanSubmit] = useState(false);

  const getAllGroupsPath = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups`;
  const siteAlarmConfigPath = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/deviceconfiguration/lightstatealerts`;
  const groupAlarmConfigPath = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${selectedGroup?.key}/deviceconfiguration/lightstatealerts`;
  const [alarmConfigResp, setAlarmConfigResp] = useState<AlarmConfigObject>({} as AlarmConfigObject);
  const { data: groupsResp } = useSWR<GroupObject[]>(selectedSite.id
    ? [getAllGroupsPath, 'GetGroups']
    : null);
  const lightingGroups: GroupObject[] | undefined = useMemo(
    () => groupsResp?.filter((group) => group.type !== 'ORGANIZATIONAL'),
    [groupsResp],
  );

  useEffect(() => {
    if ((selectNodes.key === 'Lighting group')) {
      if (selectedGroup?.key !== '') {
        setAlarmConfigResp({} as AlarmConfigObject);
        setCanSubmit(false);
        getRequest(groupAlarmConfigPath)
          .then((responses) => {
            setAlarmConfigResp(responses);
          });
      } else {
        setCanSubmit(true);
      }
    }

    if ((selectNodes.key === 'Site')) {
      setSelectedGroup({ key: '', title: 'Select' });
      setCanSubmit(false);
      setAlarmConfigResp({} as AlarmConfigObject);
      getRequest(siteAlarmConfigPath)
        .then((responses) => {
          setAlarmConfigResp(responses);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectNodes.key,
    selectedGroup?.key,
  ]);

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'AlarmConfig');

  return (
    <div className="config__content alarmconfig">
      <AdminSidebar selectedSite={selectedSite} title="Site config">
        <AdminSidebarConfigItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin page-content--large-bottom-margin">
        <TableToolbar
          title="Alarm Configuration"
        />
        <div className="alarmconfig-container">
          <div className="alarmconfig-container__children">
            <div className="alarmconfig-container__heading">
              <span className="alarmconfig-container__heading-name">Dayburner/Off-at-Night Alarm</span>
              <span className="alarmconfig-container__heading-title">Light is ON despite sufficiently high ambient light level or Light is OFF despite sufficiently low ambient light level.</span>
            </div>
            <div className="alarmconfig-interactions create-alarmconfig">

              <div className="alarmconfig-divided">
                <div className="alarmconfig-divided__leftcontent">
                  <div className="alarmconfig-divided__content-data">
                    <div className="selectNodesWrapper">
                      <span>Select nodes in* </span>
                      <RadioButtons
                        onClick={(clickedRadioBtn: RadioButtonElementProps) =>
                          setSelectNodes(clickedRadioBtn)}
                        selected={selectNodes}
                        list={[{ label: 'Site', key: 'Site' }, { label: 'Lighting group', key: 'Lighting group' }]}
                        type="light"
                      />
                      <>
                        {selectNodes.key === 'Lighting group'
                      && (
                        <SelectBox
                          title={selectedGroup?.title || 'Select'}
                          selectedItemKey={selectedGroup?.key || ''}
                          onClick={(item: SelectBoxItemType) => {
                            setSelectedGroup(item);
                          }}
                          errorMessage="Required"
                          error={selectNodes.key === 'Lighting group' && selectedGroup?.title === 'Select'}
                          list={(lightingGroups && Utils.arrayToSelectbox(lightingGroups, 'groupId', 'name')) || []}
                          type="light"
                          listWidth={listWidth}
                        />
                      )}
                      </>
                    </div>

                  </div>
                </div>
                {Object.keys(alarmConfigResp).length > 0
                && (
                <CreateAlarmConfig
                  selectedCustomer={selectedCustomer}
                  selectedSite={selectedSite}
                  selectNodes={selectNodes.key}
                  selectedGroup={selectedGroup?.key}
                  alarmConfigResp={alarmConfigResp}
                  canSubmit={canSubmit}
                />
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlarmConfigPage;
