import React from 'react';
import Modal from '../../../Common/Components/Modal';
import { useAppContext } from '../../../utils/AppContext';
import { postRequest } from '../../../utils/fetch';

import { ReactComponent as WarningIcon } from '../../../img/icons/warning.svg';
import { DeleteGroupProps, DeleteGroupPostProp } from '../../../types/DeleteGroupProps';

const modalWidth = '400';

function DeleteGroup(props: DeleteGroupProps): JSX.Element {
  const { modalOpen, deleteGroup, selectedCustomer, selectedSite } = props;
  const { addNotification } = useAppContext();

  async function deleteOrgGroup() {
    const postBody: DeleteGroupPostProp = {
      operation: 'DELETE_GROUP',
      groupId: deleteGroup.groupId,
    };

    const result = await postRequest(
      `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/async-jobs/group-move`,
      postBody,
    );

    if (!result.error) {
      addNotification({ type: 'success', message: 'Your "Delete group" operation is completed.  Refresh the page to update.' });
    } else {
      addNotification({ type: 'error', message: `Your "Delete group" operation has failed: ${result.error}` });
    }
  }

  return (
    <div className="delete-group">
      <Modal
        setModalOpen={() => modalOpen(false)}
        title="Delete org group"
        primaryButtonAction={async () => {
          try {
            if (deleteGroup.nodeList.length > 0) {
              const postBody = {
                operation: 'REMOVE_NODES',
                nodeIds: deleteGroup.nodeList,
                fromGroupId: deleteGroup.groupId,
              };
              const result = await postRequest(
                `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/async-jobs/group-move`,
                postBody,
              );
              if (!result.error) {
                deleteOrgGroup();
              }
            } else {
              deleteOrgGroup();
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Delete group" operation has failed.' });
          }
          modalOpen(false);
        }}
        primaryButtonLabel="Submit"
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => modalOpen(false)}
        width={modalWidth}
        borderTopColor="yellow"
      >
        <div className="delete-group__confirm">
          <WarningIcon />
          <div>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            {deleteGroup.nodeList.length > 0
            && (
              <div>
                All nodes (
                {deleteGroup.nodeList.length}
                ) will be removed from the
                <span>
                  &nbsp;
                  { deleteGroup.name }
                  &nbsp;
                </span>
                group.
              </div>
            )}
            <div className="delete-group__warning">Are you sure you want to delete this group?</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteGroup;
