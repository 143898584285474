import React from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { useAppContext } from '../../../../../utils/AppContext';
import { postRequest } from '../../../../../utils/fetch';
import { CreateProjectKeyProps, CreateProjectApiKeyPostProp } from '../../../../../types/CreateProjectKeyProps';

import { ReactComponent as WarningIcon } from '../../../../../img/icons/warning.svg';
import { ProjectObject } from '../../../../../types/ProjectObject';

function CreateProjectApiKey(props: CreateProjectKeyProps): JSX.Element {
  const {
    modalOpen,
    selectedProject,
    text,
    updateProject,
    setCopyActivatedKey,
    setToggleCopyProjectApiKey,
  } = props;

  const { addNotification } = useAppContext();
  const operation = 'Create Project Key';

  return (
    <Modal
      setModalOpen={modalOpen}
      className="admin-project-modal"
      title={operation}
      primaryButtonAction={async () => {
        modalOpen(false);
        if (selectedProject !== undefined) {
          try {
            const path = `/organizations/${selectedProject.orgId}/api-key/project/${selectedProject.projectId}`;
            // if (operationIsGenerate) {
            // eslint-disable-next-line no-nested-ternary
            const slotValues = (selectedProject.slot1Timestamp) ? [2] : [1];
            const postBody: CreateProjectApiKeyPostProp = {
              slotNumber: slotValues,
            };
            const result = await postRequest(
              path,
              postBody,
            );
            const apiKey = (result.data as ProjectObject);
            if (!result.error) {
              if (updateProject) {
                updateProject();
              }
              setCopyActivatedKey(apiKey);
              setToggleCopyProjectApiKey(true);
            } else {
              addNotification({ type: 'error', message: `Your "${operation}" operation has failed.` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: `Your "${operation}" operation has failed.` });
          }
        }
      }}
      primaryButtonLabel="Submit"
      secondaryButtonAction={() => modalOpen(false)}
      secondaryButtonLabel="Cancel"
      borderTopColor="yellow"
    >
      <>
        <div className="admin-project-modal-content">
          <WarningIcon className="admin-project-modal-content-icon" />
          {text}
        </div>
      </>
    </Modal>
  );
}

export default CreateProjectApiKey;
