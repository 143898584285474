import React, { useState } from 'react';
import { DateTime } from 'luxon';

import Modal from '../../../../Common/Components/Modal';
import Table from '../../../../Common/Components/Table';
import { LightsNetworkSessionHistoryProps } from '../../../../types/LightsNetworkSessionHistoryProps';
import getHeaderProps from '../../../../utils/getHeaderProps';
import Utils from '../../../../utils/Utils';
import RelativeTimeOrDateRangeModal from './RelativeTimeOrDateRangeModal';

function LightsNetworkSessionHistory(props: LightsNetworkSessionHistoryProps): JSX.Element {
  const { openModal, setOpenModal } = props;

  const headers = getHeaderProps('SessionHistory');

  const [dateRange, setDateRange] = useState<{ from: DateTime, to: DateTime }>();

  const data = Utils.missingAPIdata([
    {
      deviceid: '015322000023722',
      mdn: '12345678901',
      imei: '123456789012345',
      iccid: '12345678901234567890',
      idaddress: '100.75.56.92',
      usage: '2,000 KB',
      start: '10/23/2030 5:45PM PDT',
      end: '10/23/2030 7:56PM PDT',
      duration: '2 hrs : 7 min : 3 sec',
    },
    {
      deviceid: '015322000023722',
      mdn: '12345678901',
      imei: '123456789012345',
      iccid: '12345678901234567890',
      idaddress: '100.75.56.92',
      usage: '200 KB',
      start: '10/23/2030 5:45PM PDT',
      end: '10/23/2030 7:56PM PDT',
      duration: '2 hrs : 7 min : 3 sec',
    },
    {
      deviceid: '015322000023722',
      mdn: '12345678901',
      imei: '123456789012345',
      iccid: '12345678901234567890',
      idaddress: '100.75.56.92',
      usage: '200 KB',
      start: '10/23/2030 5:45PM PDT',
      end: '10/23/2030 7:56PM PDT',
      duration: '2 hrs : 7 min : 3 sec',
    },
    {
      deviceid: '015322000023722',
      mdn: '12345678901',
      imei: '123456789012345',
      iccid: '12345678901234567890',
      idaddress: '100.75.56.92',
      usage: '200 KB',
      start: '10/23/2030 5:45PM PDT',
      end: '10/23/2030 7:56PM PDT',
      duration: '2 hrs : 7 min : 3 sec',
    },
    {
      deviceid: '015322000023722',
      mdn: '12345678901',
      imei: '123456789012345',
      iccid: '12345678901234567890',
      idaddress: '100.75.56.92',
      usage: '200 KB',
      start: '10/23/2030 5:45PM PDT',
      end: '10/23/2030 7:56PM PDT',
      duration: '2 hrs : 7 min : 3 sec',
    },
    {
      deviceid: '015322000023722',
      mdn: '12345678901',
      imei: '123456789012345',
      iccid: '12345678901234567890',
      idaddress: '100.75.56.92',
      usage: '200 KB',
      start: '10/23/2030 5:45PM PDT',
      end: '10/23/2030 7:56PM PDT',
      duration: '2 hrs : 7 min : 3 sec',
    },
    {
      deviceid: '015322000023722',
      mdn: '12345678901',
      imei: '123456789012345',
      iccid: '12345678901234567890',
      idaddress: '100.75.56.92',
      usage: '200 KB',
      start: '10/23/2030 5:45PM PDT',
      end: '10/23/2030 7:56PM PDT',
      duration: '2 hrs : 7 min : 3 sec',
    },
    {
      deviceid: '015322000023722',
      mdn: '12345678901',
      imei: '123456789012345',
      iccid: '12345678901234567890',
      idaddress: '100.75.56.92',
      usage: '200 KB',
      start: '10/23/2030 5:45PM PDT',
      end: '10/23/2030 7:56PM PDT',
      duration: '2 hrs : 7 min : 3 sec',
    },
  ]);

  const modalDate = 1;
  const modalTable = 2;
  const [filteredTableData, setFilteredTableData] = useState([]);

  return (
    <div className="network">
      {(openModal === modalDate) && (
      <RelativeTimeOrDateRangeModal
        title="Alarm history"
        setOpenModal={() => setOpenModal(0)}
        primaryButtonAction={(range: { from: DateTime, to: DateTime }) => {
          setDateRange(range);
          // get data
          setOpenModal(modalTable);
        }}
      />
      )}
      {(openModal === modalTable) && (
        <Modal
          width="90"
          widthType="%"
          setModalOpen={() => setOpenModal(0)}
          title="Session history"
          subtitle={`${Utils.getConvertedDate(dateRange?.from.toJSDate())} - ${Utils.getConvertedDate(dateRange?.to.toJSDate())}`}
          primaryButtonLabel="Close"
          primaryButtonAction={() => setOpenModal(0)}
          secondaryButtonLabel="Download CSV"
          secondaryButtonAction={() => Utils.downloadCSV(headers, filteredTableData, 'session_history')}
        >
          <div className="table table--light">
            <Table
              headers={headers}
              data={data}
              setFilteredTableData={setFilteredTableData}
              dataFetchedAlready
              skipCellMeasure
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LightsNetworkSessionHistory;
