/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import Table from '../../../../Common/Components/Table';
import { AlertObject } from '../../../../types/AlertObject';
import { LightsAlarmHistoryProps } from '../../../../types/LightsAlarmHistoryProps';
import { getRequest } from '../../../../utils/fetch';
import getHeaderProps from '../../../../utils/getHeaderProps';
import Utils from '../../../../utils/Utils';
import RelativeTimeOrDateRangeModal from './RelativeTimeOrDateRangeModal';

import { ReactComponent as AlarmIcon } from '../../../../img/icons/notification-filled.svg';

import Loading from '../../../../Common/Components/Loading';

function LightsAlarmHistory(props: LightsAlarmHistoryProps): JSX.Element {
  const { selectedCustomer, selectedSite, selectedNode, openModal, setOpenModal, site } = props;

  const headers = getHeaderProps('AlarmHistory');

  const [dateRange, setDateRange] = useState<{ from: DateTime, to: DateTime }>();
  const [alarmHistory, setAlarmHistory] = useState<AlertObject[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const modalDate = 1;
  const modalTable = 2;

  // render the Severity column, including colored icon
  // eslint-disable-next-line react/prop-types
  const severityCell = (severity: string) =>
    (
      <div>
        <AlarmIcon className={`alarm-icon alarm-icon__${severity}`} />
        <span>{severity}</span>
      </div>
    );
  const [filteredTableData, setFilteredTableData] = useState([]);
  return (
    <div className="alarms">
      {(openModal === modalDate) && (
        <>
          <Loading isLoading={isLoading} fullScreen />
          <RelativeTimeOrDateRangeModal
            title="Alarm history"
            setOpenModal={() => setOpenModal(0)}
            primaryButtonAction={async (range: { from: DateTime, to: DateTime }) => {
              setDateRange(range);
              setIsLoading(true);
              const startUTC = range.from.setZone('UTC');
              const endUTC = range.to.setZone('UTC');
              const path = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts?raisedAfter=${startUTC}&raisedBefore=${endUTC}&withDisplay=true`;
              const options: RequestInit = {};
              options.headers = new Headers();
              options.headers.set('x-node-ids', selectedNode.nodeid);
              const resp: AlertObject[] = await getRequest(path, options);
              const alarms = resp
                .filter((alarm) => alarm.originatorId === selectedNode.nodeid)
                .map((alarm) => {
                  alarm.status = alarm.clearedAt ? 'Dismissed' : 'Active';
                  alarm.raisedAtTableSort = alarm.raisedAtTableSort || new Date(alarm.raisedAt).getTime();
                  alarm.raisedAt = Utils.convertISOtoAlarmTime2(alarm.raisedAt, site ? site.time_zone : 'utc');
                  alarm.raisedAtFmt = Utils.getConvertedDate(new Date(alarm.raisedAt));
                  alarm.clearedAtTableSort = alarm.clearedAt ? new Date(alarm.clearedAt).getTime() : 0;
                  alarm.clearedAt = Utils.convertISOtoAlarmTime2((alarm.clearedAt ? alarm.clearedAt : ''), site ? site.time_zone : 'utc');
                  alarm.clearedAtFmt = alarm.clearedAt ? Utils.getConvertedDate(new Date(alarm.clearedAt)) : '';
                  alarm.severityFmt = alarm.severityFmt || severityCell(alarm.severity);
                  alarm.severityFmtTableSort = ['Minor', 'Major', 'Critical'].indexOf(alarm.severity);

                  return alarm;
                });

              setAlarmHistory(alarms);
              setIsLoading(false);
              setOpenModal(modalTable);
            }}
          />
        </>
      )}
      {(openModal === modalTable) && (
        <Modal
          width="1050"
          setModalOpen={() => setOpenModal(0)}
          title="Alarm history"
          subtitle={`${Utils.getConvertedDate(dateRange?.from.toJSDate())} - ${Utils.getConvertedDate(dateRange?.to.toJSDate())}`}
          primaryButtonLabel="Close"
          primaryButtonAction={() => setOpenModal(0)}
          secondaryButtonLabel="Download CSV"
          secondaryButtonAction={() => {
            const csvHeaders = headers.filter((item) => item.key !== 'severityFmt');
            csvHeaders.splice(1, 0, { key: 'severity', val: 'Severity' });
            Utils.downloadCSV(csvHeaders, filteredTableData, 'alarm_history');
          }}
        >
          <div className="table table--light">
            <Table
              headers={headers}
              data={alarmHistory}
              setFilteredTableData={setFilteredTableData}
              fixColCount={0}
              dataFetchedAlready
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LightsAlarmHistory;
