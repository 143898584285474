import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SlideIn from '../../../Common/Components/SlideIn';
import { ReactComponent as FairIcon } from '../../../img/icons/fair.svg';
import { ReactComponent as PoorIcon } from '../../../img/icons/low-poor.svg';
import { ReactComponent as MiddleGoodIcon } from '../../../img/icons/middle-good.svg';
import { GenericSensorData } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Sensors from '../../../utils/Sensors';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import Button from '../../../Common/Components/Button';

function LightsPower(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite, fetchedBulkSensorData } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const displayedSensors = ['activePower', 'mainsVoltage', 'mainsCurrent', 'powerFactor', 'reactivePower'];
  const timestamp = useRef<number>(0);
  const [sensorData, setSensorData] = useState<(GenericSensorData)>({ nodeid: selectedNode.nodeid } as GenericSensorData);
  const [refreshLoadingSensors, setRefreshLoadingSensors] = useState(false);
  const [latestSensorTsFromDWH, setLatestSensorTsFromDWH] = useState(new Date(0).getTime());
  const [onDemandReadTriggered, setOnDemandReadTriggered] = useState(false);
  const [newSensorDataAvailable, setNewSensorDataAvailable] = useState(false);
  const history = useHistory();

  useEffect(() => {
    Promise.resolve(fetchedBulkSensorData).then((response) => {
      const newData = Sensors.getSensorTableDataWithValueAndSensor(
        selectedItems,
        displayedSensors,
        response,
        selectedSite.timezone,
      );
      let latestSensorTs = new Date(0).getTime();
      if (response.sensorHistories && response.sensorHistories[0]) {
        Object.values(response.sensorHistories[0].data).forEach((d) => {
          const utcSensorTimestamp = `${d.timestamp}Z`;
          const sensorTs = new Date(utcSensorTimestamp).getTime();
          if (latestSensorTs < sensorTs) {
            latestSensorTs = sensorTs;
          }
        });
      }
      setLatestSensorTsFromDWH(latestSensorTs);
      setSensorData(newData[0]);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedBulkSensorData]);

  const refreshSensors = useCallback(() => {
    setRefreshLoadingSensors(true);
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = sensorData ? [{ ...sensorData }] : undefined;
    const tmpDisplayedData = Object.entries(sensorData).reduce((acc, e) =>
      (displayedSensors.includes(e[0]) ? acc : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: selectedNode.nodeid });
    setSensorData(tmpDisplayedData as GenericSensorData);

    Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      [selectedNode],
      displayedSensors.map((sen) => Sensors.getSensorId(sen)),
    )
      .then((response) => {
        setOnDemandReadTriggered(true);
        setRefreshLoadingSensors(false);
        if (timestamp.current <= currentDate) {
          let earliestSensorTs;
          if (response.sensorHistories && response.sensorHistories[0]) {
            Object.values(response.sensorHistories[0].data).forEach((d) => {
              const sensorTs = new Date(d.timestamp).getTime();
              if (!earliestSensorTs || sensorTs < earliestSensorTs) {
                earliestSensorTs = sensorTs;
              }
            });
          }
          if (latestSensorTsFromDWH < earliestSensorTs) {
            const newData = Sensors.getSensorTableDataWithValueAndSensor(
              selectedItems,
              displayedSensors,
              response,
              selectedSite.timezone,
              originalData,
            );
            setSensorData(newData[0]);
            setNewSensorDataAvailable(true);
          } else {
            setSensorData(sensorData);
          }
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer.id, selectedItems, selectedNode, selectedSite.id, sensorData]);

  const getIcon = (sensorId: 'mainsVoltage' | 'powerFactor') => {
    const voltageGoodMin = 104;
    const voltageGoodMax = 294;
    const powerGood = 90;
    const powerPoor = 80;
    if (sensorId === 'mainsVoltage') {
      if (sensorData?.mainsVoltage?.value && (parseFloat(sensorData?.mainsVoltage.value) < voltageGoodMin || parseFloat(sensorData?.mainsVoltage.value) > voltageGoodMax)) {
        return (
          <Tooltip
            text="Poor"
          >
            <PoorIcon />
          </Tooltip>
        );
      } if (sensorData?.mainsVoltage?.value && (parseFloat(sensorData?.mainsVoltage.value) >= voltageGoodMin && parseFloat(sensorData?.mainsVoltage.value) <= voltageGoodMax)) {
        return (
          <Tooltip
            text="Good"
          >
            <MiddleGoodIcon />
          </Tooltip>
        );
      }
    } if (sensorId === 'powerFactor') {
      if (sensorData?.powerFactor?.value && parseFloat(sensorData?.powerFactor.value) >= powerGood) {
        return (
          <Tooltip
            text="Good"
          >
            <MiddleGoodIcon />
          </Tooltip>
        );
      } if (sensorData?.powerFactor?.value && parseFloat(sensorData?.powerFactor.value) > powerPoor && parseFloat(sensorData?.powerFactor.value) < powerGood) {
        return (
          <Tooltip
            text="Fair"
          >
            <FairIcon />
          </Tooltip>
        );
      } if (sensorData?.powerFactor?.value && parseFloat(sensorData?.powerFactor.value) <= powerPoor) {
        return (
          <Tooltip
            text="Poor"
          >
            <PoorIcon />
          </Tooltip>
        );
      }
    }
    return <></>;
  };

  return (
    <div className="power">
      <SlideIn>
        <>
          <div className="slide-in__title">
            <span>Power</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="custom-list-element sensors">
            <div className="list-element-title">Sensors</div>
            {(!refreshLoadingSensors) && (
              <SyncIcon
                data-testid="icon"
                className="list-element__refresh"
                onClick={() => {
                  refreshSensors();
                }}
              />
            )}
            {(onDemandReadTriggered && !newSensorDataAvailable && !refreshLoadingSensors) && (
            <div className="list-element__refresh-result">
              <Tooltip text="There's no newer data available.">
                <InfoIcon />
              </Tooltip>
            </div>
            )}
            {(refreshLoadingSensors) && (
            <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
            )}

            <div className="slide-in__content">
              <SlideinListElementBig
                title="Active power"
                valueBold={sensorData?.activePower?.value || '--'}
                date={sensorData?.activePower?.time}
                value="W"
                isFirstElement
              />
              <SlideinListElementBig
                title="Mains voltage"
                valueBold={sensorData?.mainsVoltage?.value || '--'}
                date={sensorData?.mainsVoltage?.time}
                value="V"
                icon={getIcon('mainsVoltage')}
              />
              <SlideinListElementBig
                title="Mains current"
                valueBold={sensorData?.mainsCurrent?.value || '--'}
                date={sensorData?.mainsCurrent?.time}
                value={Sensors.getConvertedUnits(sensorData?.mainsCurrent?.value, 'A')?.unit || 'A'}
              />
              <SlideinListElementBig
                title="Power factor"
                valueBold={sensorData?.powerFactor?.value || '--'}
                date={sensorData?.powerFactor?.time}
                value="%"
                icon={getIcon('powerFactor')}
              />
              <SlideinListElementBig
                title="Reactive power"
                valueBold={sensorData?.reactivePower?.value || '--'}
                date={sensorData?.reactivePower?.time}
                value="var"
                border="light"
              />
            </div>
            <div className="slide-in__link">
              <Button
                onClick={() => {
                  localStorage.setItem('analysisSelectedNodes', JSON.stringify([selectedNode.nodeid]));
                  localStorage.setItem('analysisNetworkStatsDashboardFlag', 'false');
                  localStorage.setItem('analysisPowerQualityDashboardFlag', 'true');
                  history.push('/analysis');
                }}
                label="Navigate to Dashboard"
              />
            </div>
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsPower;
