/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import Modal from '../../../../../Common/Components/Modal';
import WizardModal from '../../../../../Common/Components/WizardModal';
import WizardNodeSelector from './WizardNodeSelector';
import WizardAlertFilterSelector from './WizardAlertFilterSelector';
import WizardOutputSelector from './WizardOutputSelector';
import WizardReview from './WizardReview';
import { ReactComponent as SuccessIcon } from '../../../../../img/icons/success.svg';
import { useAppContext } from '../../../../../utils/AppContext';
import { postRequest, putRequest } from '../../../../../utils/fetch';
import { NodeObject } from '../../../../../types/NodeObject';
import { groupsNodesFn, nodeExtendedPropsFn, nodesFetcherFn } from '../../../../../utils/ApiDataHelpers';
import { GroupObject } from '../../../../../types/GroupObject';
import { AlertActivityReportWizardProps } from '../../../../../types/AlertActivityReportWizard';
import { AlertActivityFilterBy, AlertActivityReportsObject } from '../../../../../types/AlertActivityReports';
import Loading from '../../../../../Common/Components/Loading';
import RecipientEditor from '../../../../../Common/Components/RecipientEditor';
import { Recipient } from '../../../../../types/EnergyReportPayload';

function AlertActivityReportWizard(props: AlertActivityReportWizardProps): JSX.Element {
  const { selectedCustomer, selectedSite, selectedReport, closeModal, updateAlertActivityReportList } = props;

  const { addNotification } = useAppContext();

  const emptyReportObject: AlertActivityReportsObject = useMemo(() => ({
    name: '',
    frequency: undefined,
    recipients: [],
    columns: [],
    filters: {},
  } as AlertActivityReportsObject), []);

  const [reportObject, setReportObject] = useState<AlertActivityReportsObject>(selectedReport || { ...emptyReportObject });
  const [filterBy, setFilterBy] = useState<AlertActivityFilterBy>(reportObject.filters.types && reportObject.filters.types.length > 0 ? 'alert_type' : 'severity');
  const [nodesList, setNodesList] = useState<Map<string, NodeObject>>(new Map());
  const [doneWithSuccess, setDoneWithSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [recipients, _setRecipients] = useState<Recipient[]>(reportObject.recipients);
  const setRecipients = (newVal: Recipient[]) => {
    _setRecipients(newVal);
    setReportObject((origItem) => {
      const newItem = { ...origItem };
      newItem.recipients = newVal;
      return newItem;
    });
  };

  // Nodes
  const { data: nodesResp } = useSWR<Array<NodeObject> | undefined>(
    selectedSite.id
      ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/lkp/nodes?size=5000`, 'GetNodesResp']
      : null,
    nodesFetcherFn,
  );

  // Groups
  const { data: groupsResp } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups']
    : null);

  const { groupsNodes = {} } = useMemo(() => groupsNodesFn(groupsResp, nodesResp), [groupsResp, nodesResp]);

  useEffect(() => {
    if (nodesResp?.length && Array.isArray(groupsResp)) {
      const nodes = nodeExtendedPropsFn(nodesResp, groupsNodes);
      setNodesList(nodes);
      setLoading(false);
    } else if (Array.isArray(nodesResp) && nodesResp.length === 0) {
      setLoading(false);
    }
  }, [nodesResp, groupsResp, groupsNodes]);

  // Validation
  const validateNodesPage = ({ displayError }: {displayError: boolean}) => {
    let isValid = true;
    let message = '';
    if (!reportObject.filters.nodeIds || reportObject.filters.nodeIds.length < 0) {
      isValid = false;
      message = 'Please select at least one node.';
    }
    if (reportObject.filters.nodeIds && reportObject.filters.nodeIds.length > 1000) {
      isValid = false;
      message = 'You can select a maximum of 1000 nodes. Please revise your selection.';
    }
    if (!isValid && displayError) {
      addNotification({ type: 'warning', message });
    }
    return isValid;
  };

  const validateFiltersPage = ({ displayError }: {displayError: boolean}) => {
    let isValid = true;
    let message = '';

    if (!reportObject.frequency) {
      isValid = false;
      message = 'Please select a Schedule.';
    }

    if (reportObject.frequency === 'ONCE' && (!reportObject.filters.raisedAfter || !reportObject.filters.raisedBefore)) {
      isValid = false;
      message = 'Please select a Start date and an End date.';
    }

    if ((reportObject.frequency === 'WEEKLY' || reportObject.frequency === 'MONTHLY') && !reportObject.reportOnDay) {
      isValid = false;
      message = 'Please select a day for the schedule.';
    }

    if (filterBy === 'alert_type' && reportObject.filters.types?.length === 0) {
      isValid = false;
      message = 'Please select at least one Alert type.';
    }

    if (filterBy === 'severity' && reportObject.filters.severities?.length === 0) {
      isValid = false;
      message = 'Please select at least one Severity.';
    }

    if (reportObject.filters.statuses?.length === 0) {
      isValid = false;
      message = 'Please select at least one Status.';
    }

    if (!isValid && displayError) {
      addNotification({ type: 'warning', message });
    }

    return isValid;
  };

  const validateColumnsPage = ({ displayError }: {displayError: boolean}) => {
    if (reportObject.columns.length > 0) {
      return true;
    }
    if (displayError) {
      addNotification({ type: 'warning', message: 'Please select at least one column.' });
    }
    return false;
  };

  const validateRecipientsPage = ({ displayError }: {displayError: boolean}) => {
    if (reportObject.recipients.length > 0) {
      return true;
    }
    if (displayError) {
      addNotification({ type: 'warning', message: 'Please add at least one recipient.' });
    }
    return false;
  };

  const validateSummaryPage = ({ displayError }: {displayError: boolean}) => {
    const { name } = reportObject;
    let message = '';

    if (!name || name.length <= 0 || name.length > 128) {
      message = 'Report name is required and must be between 1 and 128 characters.';
    } else if (!name.match(/[^<>"'`;]$/)) {
      message = 'Report name can only contain lowercase and uppercase letters, numbers and special characters, except: ^ < > " \' `';
    }

    if (message === '') {
      return true;
    }

    if (displayError) {
      addNotification({ type: 'warning', message });
    }

    return false;
  };

  async function sendAlertActivityReportRequest() {
    try {
      setLoading(true);
      let result;

      if (reportObject?.id) {
        result = await putRequest<Partial<AlertActivityReportsObject>, undefined>(
          `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/reports/${reportObject.id}`,
          {
            name: reportObject.name,
            frequency: reportObject.frequency,
            reportOnDay: reportObject.reportOnDay,
            recipients: reportObject.recipients,
            columns: reportObject.columns,
            filters: reportObject.filters,
          },
        );
      } else {
        result = await postRequest<AlertActivityReportsObject, undefined>(
          `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/reports`,
          reportObject,
        );
      }

      if (result.status >= 200 && result.status < 300) {
        setDoneWithSuccess(true);
        updateAlertActivityReportList();
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      addNotification({ type: 'warning', message: `Could not ${reportObject?.id ? 'edit' : 'create'} report: '${error}'` });
    } finally {
      setLoading(false);
    }
  }

  const navNodes = 1;
  const navFilters = 2;
  const navOutput = 3;
  const navRecipients = 4;
  const navReviewSave = 5;

  const navItems = new Map([
    [navNodes, { className: '',
      label: '1',
      labelName: 'Nodes',
      validatorFn: validateNodesPage,
      content:
  <WizardNodeSelector
    loading={loading}
    nodes={nodesList}
    reportObject={reportObject}
    setReportObject={setReportObject}
  />,
    }],
    [navFilters, { className: '',
      label: '2',
      labelName: 'Alert filters',
      validatorFn: validateFiltersPage,
      content:
  <WizardAlertFilterSelector
    reportObject={reportObject}
    setReportObject={setReportObject}
    setFilterBy={setFilterBy}
  />,
    }],
    [navOutput, { className: 'long-text',
      label: '3',
      labelName: 'Output columns & format',
      validatorFn: validateColumnsPage,
      content:
  <WizardOutputSelector
    reportObject={reportObject}
    setReportObject={setReportObject}
  />,
    }],
    [navRecipients, { className: '',
      label: '4',
      labelName: 'Recipients',
      validatorFn: validateRecipientsPage,
      content:
  <RecipientEditor
    selectedCustomer={selectedCustomer}
    recipients={recipients}
    setRecipients={setRecipients as React.Dispatch<React.SetStateAction<Recipient[]>>}
  /> }],
    [navReviewSave, { className: '',
      label: '5',
      labelName: 'Review & save',
      validatorFn: validateSummaryPage,
      content:
  <>
    {loading && <Loading />}
    <WizardReview
      reportObject={reportObject}
      setReportObject={setReportObject}
      filterBy={filterBy}
    />
  </> }],
  ]);

  return (
    <>
      {!doneWithSuccess ? (
        <WizardModal
          title="Alert activity report"
          width="810"
          height="610"
          navItems={navItems}
          closeModal={closeModal}
          submitButtonAction={() => {
            sendAlertActivityReportRequest();
          }}
        />
      ) : (
        <Modal
          setModalOpen={() => closeModal()}
          title="Success"
          primaryButtonAction={() => closeModal()}
          primaryButtonLabel="OK"
          width="350"
          height="170"
          className="success-modal"
        >
          <>
            <SuccessIcon />
            <div>
              <div>Your request has been submitted.</div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
}

export default AlertActivityReportWizard;
