/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import useSWR from 'swr';
import DropDown from './DropDown';
import Modal from './Modal';
import SelectBox from './SelectBox';
import { SelectBoxItemType } from '../../types/SelectBoxPropsType';
import Utils from '../../utils/Utils';
import { useAppContext } from '../../utils/AppContext';

import { ReactComponent as WarningIcon } from '../../img/icons/warning.svg';
import { postRequest } from '../../utils/fetch';
import { AddToFotaCampaignProps, AddToFotaCampaignResponse } from '../../types/AddToFotaCampaignProps';
import { FotaCampaign } from '../../types/FotaCampaigns';

const listWidth = 205;
const defaultMaxItems = 4;

function AddToFotaCampaign(props: AddToFotaCampaignProps): JSX.Element {
  const validCampaignStates = ['ACTIVE', 'CREATED'];

  const {
    selectedItems,
    closeDropdown,
  } = props;

  const [selectedCampaign, setSelectedCampaign] = useState({
    title: 'Select',
    key: '0',
  });

  const { data: fotaCampaignsResp } = useSWR<FotaCampaign[]>(
    [`/fota/campaign?filter=${validCampaignStates.join(',')}`, 'GetFotaCampaigns'],
  );

  const { addNotification } = useAppContext();
  const [openModal, setOpenModal] = useState(false);

  const createAddNodesResponseMessage = (response: AddToFotaCampaignResponse): string => {
    const duplicateNodesMessage = response.operationDetails.failedNodes.duplicates.length > 0 ? `The following node(s) are already added to this campaign:  ${response.operationDetails.failedNodes.duplicates.join(', ')}` : '';
    const alreadyInProgressNodesMessage = response.operationDetails.failedNodes.alreadyInProgress.length > 0 ? `The following node(s) have ongoing firmware upgrade processes in other campaigns:  ${response.operationDetails.failedNodes.alreadyInProgress.join(', ')}` : '';
    return `Your "Add to FOTA campaign" operation is completed. ${response.operationSummary.nodesAdded} node(s) were successfully added to the campaign. ${duplicateNodesMessage} ${alreadyInProgressNodesMessage}`;
  };

  return (
    <div className="multi-node-modals">
      {!openModal && (
      <DropDown
        title="Add to FOTA campaign"
        secondaryButtonType="secondary"
        secondaryButtonLabel="Submit"
        secondaryButtonSize="small"
        secondaryButtonAction={() => {
          if (selectedItems.size === 0) {
            addNotification({ type: 'warning', message: 'No nodes were selected for the operation.' });
          } else if (selectedCampaign.key === '0') {
            addNotification({ type: 'warning', message: 'No campaign is selected for the operation.' });
          } else {
            setOpenModal(true);
          }
        }}
        primaryButtonLabel="Cancel"
        primaryButtonType="primary"
        primaryButtonSize="small"
        primaryButtonAction={() => closeDropdown()}
        className="toolbar-action-dropdown"
      >
        <SelectBox
          label="Select campaign"
          type="dark"
          list={
            (fotaCampaignsResp
              && Utils.arrayToSelectbox(fotaCampaignsResp, 'campaignId', 'name'))
            || []
          }
          onClick={(item: SelectBoxItemType) => {
            setSelectedCampaign(item);
          }}
          title={selectedCampaign.title}
          selectedItemKey={selectedCampaign.key}
          listWidth={listWidth}
          maxItems={defaultMaxItems}
        />
      </DropDown>
      )}
      {openModal && (
        <Modal
          title="Confirm action"
          width="380"
          setModalOpen={() => {
            setOpenModal(false);
            closeDropdown();
          }}
          primaryButtonLabel="Submit"
          primaryButtonAction={async () => {
            try {
              const postBody = { nodes: Array.from(selectedItems.keys()) };

              const result = await postRequest(
                `/fota/campaign/${selectedCampaign.key}/addNodes`,
                postBody,
              );

              if (!result.error) {
                const response = result.data as AddToFotaCampaignResponse;
                const message = createAddNodesResponseMessage(response);
                const type = response.operationSummary.nodesFailed > 0 ? 'warning' : 'info';
                addNotification({ type, message });
                setOpenModal(false);
                closeDropdown();
              } else {
                addNotification({ type: 'error', message: `Your "Add to FOTA campaign" operation has failed: ${result.error}` });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Add to FOTA campaign" operation has failed.' });
            }
          }}
          secondaryButtonLabel="Cancel"
          borderTopColor="yellow"
          className="add-node-to-fota-campaign"
        >
          <div className="add-node-to-fota-campaign__confirm">
            <WarningIcon />
            <div>
              {selectedItems.size > 0
            && (
              <div>
                <span>
                  {selectedItems.size}
                  &nbsp;
                </span>
                node(s) will be added to
                <span>
                  &nbsp;
                  { selectedCampaign.title }
                </span>
                .
              </div>
            )}
              <div className="add-node-to-fota-campaign__warning">
                This campaign currently has
                {' '}
                <span>{fotaCampaignsResp?.find((c) => c.campaignId === selectedCampaign.key)?.numberOfNodes || 'N/A'}</span>
                {' '}
                nodes and will update all to firmware version
                {' '}
                <span>{fotaCampaignsResp?.find((c) => c.campaignId === selectedCampaign.key)?.targetVersion || 'N/A'}</span>
                . Please submit or cancel.
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AddToFotaCampaign;
