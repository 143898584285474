import React from 'react';
import { RealTimeValuesProps } from '../../../types/RealTimeValuesProps';

import { ReactComponent as CloseIcon } from '../../../img/icons/close.svg';
import SelectBox from '../../../Common/Components/SelectBox';
import Button from '../../../Common/Components/Button';

function RealTimeValues(props: RealTimeValuesProps): JSX.Element {
  const {
    closeButton = false,
    closeButtonAction,
    sensor,
    sensorList,
    sensorSelectboxAction,
    listWidth,
    refreshValue,
  } = props;
  return (
    <div className="plot-sensors">
      {closeButton
      && (
        <button
          type="button"
          className="plot-sensors__close-button"
          onClick={closeButtonAction}
        >
          <CloseIcon />
        </button>
      )}
      <div className="plot-sensors__title">
        Get real time values
      </div>
      <SelectBox
        label="Sensors"
        type="dark"
        list={sensorList}
        onClick={sensorSelectboxAction}
        title={sensor.title}
        selectedItemKey={sensor.key}
        listWidth={listWidth}
        alwaysOpen
      />
      <div className="slide-in__link">
        <Button onClick={() => refreshValue()} label="Submit" />
      </div>
    </div>
  );
}

export default RealTimeValues;
