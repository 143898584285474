import React, { useState } from 'react';
import { ManualGPSModalProps } from '../../types/ManualGPSModalProps';
import { useAppContext } from '../../utils/AppContext';
import { putRequest } from '../../utils/fetch';
import Modal from './Modal';
import Textbox from './Textbox';

function ManualGPSModal(props: ManualGPSModalProps): JSX.Element {
  const { setModalOpen, nodeList, customerId, siteId } = props;
  const { addNotification } = useAppContext();
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  return (
    <Modal
      title="Manual GPS entry"
      width="360"
      setModalOpen={() => setModalOpen()}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        try {
          const putBody = {
            latitudeUserDefined: parseFloat(latitude),
            longitudeUserDefined: parseFloat(longitude),
          };

          const result = await putRequest(
            `/organizations/${customerId}/sites/${siteId}/nodes/${nodeList[0]}/gps`,
            putBody,
          );

          if (!result.error) {
            addNotification({ type: 'info', message: 'User-supplied GPS location has been set.' });
          } else {
            addNotification({ type: 'error', message: 'User-supplied GPS location failed to set.' });
          }
          setModalOpen();
        } catch (e) {
          addNotification({ type: 'error', message: `User-supplied GPS location failed: ${e}` });
          setModalOpen();
        }
      }}
      secondaryButtonLabel="Cancel"
      className="commissioning"
    >
      <form action="">
        <Textbox
          type="number"
          label="Latitude"
          value={latitude}
          isRequired
          onChange={(event) => setLatitude(event.target.value)}
          min="-89"
          max="89"
        />
        <Textbox
          type="number"
          label="Longitude"
          value={longitude}
          isRequired
          onChange={(event) => setLongitude(event.target.value)}
          max="179"
          min="-179"
        />
      </form>
    </Modal>
  );
}

export default ManualGPSModal;
