import React from 'react';
import { ToolbarLinkPropsType } from '../../types/ToolbarLinkPropsType';

function ToolbarLink(props: ToolbarLinkPropsType): JSX.Element {
  const {
    children,
    icon,
    title,
    subtitleStyle,
    subtitle,
    subtitleNum,
    subtitleText,
    onclick,
    order,
    tabPanelisActive,
    addClass,
    highlightActive = true,
  } = props;

  const hasColoredSubtitle = subtitleNum !== 0 && subtitleNum !== undefined;
  // eslint-disable-next-line no-nested-ternary
  const subtitleTextPlural = ((subtitleNum !== undefined && subtitleNum > 1) || subtitleNum === 'All') ? `${subtitleText}s` : subtitleText;

  return (
    <div className={`toolbar-tabs__container-tabset-container ${addClass || ''}`}>
      <div
        role="button"
        onClickCapture={() => onclick && onclick(order)}
        className={`toolbar-tabs__container-tabset-btn
         ${order === tabPanelisActive ? 'active' : ''}
          ${highlightActive ? '' : 'no-highlight'}`}
      >
        <span className="toolbar-tabs__container-tabset-btn-content">
          {icon || <></>}
          <span className="toolbar-tabs__container-tabset-btn-content-title">
            {title}
          </span>
          <span
            className={`toolbar-tabs__container-tabset-btn-content-subtitle ${subtitleStyle || ''} ${hasColoredSubtitle ? 'colored' : ''}`}
          >
            { hasColoredSubtitle && (
              <div>
                <span>
                  {subtitleNum}
                </span>
                {' '}
                {subtitleTextPlural}
              </div>
            )}
            { !hasColoredSubtitle && <span>{subtitle}</span>}
          </span>
        </span>
      </div>
      {order === tabPanelisActive && children}
    </div>
  );
}

export default ToolbarLink;
