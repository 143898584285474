import React from 'react';

import { ReactComponent as ScrollRightIcon } from '../../../img/icons/scroll-right.svg';
import { ReactComponent as LihtingGroupIcon } from '../../../img/icons/lighting-group.svg';
import { GroupActionsTargetGroupProps } from '../../../types/GroupActionsTargetGroupProps';

function GroupActionsTargetGroup(props: GroupActionsTargetGroupProps): JSX.Element {
  const { hasGroupName, groupName, children, icon, infoboxTitle, infoboxTitlePlaceholder } = props;

  const currInfoboxTitle = !infoboxTitle ? 'Target lighting group' : infoboxTitle;
  const currInfoboxTitlePlaceholder = !infoboxTitle ? 'Target group not selected' : infoboxTitlePlaceholder;

  return (
    <div className="target-group">
      <ScrollRightIcon className="icon" />
      <div className="target-group-info">
        {icon || <LihtingGroupIcon className="target-group-info__icon" />}
        <div className="target-group-info__title">
          { children || (
          <>
            <span className="target-group-info__title-name title">
              { hasGroupName
                ? currInfoboxTitle
                : currInfoboxTitlePlaceholder}
            </span>
            { hasGroupName && (
            <span className="target-group-info__title-name groupname">
              {groupName}
            </span>
            )}
          </>
          )}
        </div>
      </div>
    </div>
  );
}

export default GroupActionsTargetGroup;
