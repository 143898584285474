import React from 'react';
import { TableToolbarProps } from '../../../../types/TableToolbarProps';

function TableToolbar(props: TableToolbarProps): JSX.Element {
  const { title, children, subtitle, addClass } = props;

  const hasSubtitle = subtitle !== undefined;

  return (
    <div className={`tabletoolbar ${addClass || ''}`}>
      <div className={`tabletoolbar__title ${hasSubtitle ? 'width-subtitle' : ''}`}>
        {title}
        {hasSubtitle && subtitle}
      </div>
      {children}
    </div>
  );
}

export default TableToolbar;
