import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../../Common/Components/Button';
import ManualOverride from '../../../Common/Components/ManualOverride';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import { GenericSensorTableData } from '../../../types/GenericSensorData';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { slideInWidth } from '../../../utils/constants';
import getHeaderProps from '../../../utils/getHeaderProps';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import PlotSensor from '../Components/PlotSensors';
import RealTimeValues from '../Components/RealTimeValues';
import Loading from '../../../Common/Components/Loading';

function LightsOperationMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems, fetchedBulkSensorData } = props;
  const isNonReadOnly = Utils.isNonReadOnly();

  const displayedSensors = ['driverLevel', 'activePower', 'cumulativeEnergyUse', 'ambientLight'/* , 'lastDriverLevelReport' */];
  const [reqSensors, setReqSensors] = useState(displayedSensors);

  const headers = getHeaderProps('LightsOperation');
  const [operationMultiTableData, setOperationMultiTableData] = useState<GenericSensorTableData[]>();
  const [loading, setLoading] = useState(false);
  const timestamp = useRef<number>(0);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(fetchedBulkSensorData).then((response) => {
      response.sensorHistories.forEach((s) => {
        const node = selectedItems.get(s.nodeId);
        if (node !== undefined) {
          node.ligLastReported = s.data.find((d) => d.sensorId === 'lt')?.timestamp || '';
          node.ligLastReported = Utils.getSensorConvertedDate(node.ligLastReported, selectedSite.timezone || 'America/New_York');
        }
      });
      const newData = Sensors.getSensorTableWithDisplayedString(
        selectedItems,
        displayedSensors,
        response,
        selectedSite.timezone,
      ).map((item) => {
        const node = selectedItems.get(item.nodeid);
        return { ...item,
          fixturename: node?.fixturename,
          schedulename: node?.schedulename,
          scheduledDriverLevel: `${(node?.schedDriverLevel || 0).toString()} %`,
          lastDriverLevelReport: node?.ligLastReported,
        } as GenericSensorTableData;
      });
      setOperationMultiTableData(newData);
    }).finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer.id, selectedItems, selectedSite.id, fetchedBulkSensorData]);

  const refreshSensors = useCallback(() => {
    setLoading(true);
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = operationMultiTableData;
    const tmpDisplayedData = operationMultiTableData?.map((rowData) => Object.entries(rowData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? { ...acc, ...{ [e[0]]: '--' } } : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: rowData.nodeid }));
    setOperationMultiTableData(tmpDisplayedData);

    Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      Array.from(selectedItems.values()).map((n) => n),
      reqSensors.map((name) => Sensors.getSensorId(name)),
    ).then((response) => {
      response.sensorHistories.forEach((s) => {
        const node = selectedItems.get(s.nodeId);
        if (node !== undefined) {
          node.ligLastReported = s.data.find((d) => d.sensorId === 'lt')?.timestamp || '';
          node.ligLastReported = Utils.getSensorConvertedDate(node.ligLastReported, selectedSite.timezone || 'America/New_York');
        }
      });
      if (timestamp.current <= currentDate) {
        const newData = Sensors.getSensorTableWithDisplayedString(
          selectedItems,
          reqSensors,
          response,
          selectedSite.timezone,
          {},
          originalData,
        )
          .map((item) => {
            const node = selectedItems.get(item.nodeid);
            return { ...item,
              fixturename: node?.fixturename,
              lastDriverLevelReport: node?.ligLastReported,
              schedulename: node?.schedulename,
              scheduledDriverLevel: `${(node?.schedDriverLevel || 0).toString()} %`,
            } as GenericSensorTableData;
          });
        setOperationMultiTableData(newData);
      }
    }).finally(() => setLoading(false));
  }, [operationMultiTableData, reqSensors, selectedCustomer.id, selectedItems, selectedSite.id, selectedSite.timezone]);

  const sensorListRealTimeValue = [
    { title: 'Driver Level', key: 'driverLevel' },
    { title: 'Active Power', key: 'activePower' },
    { title: 'Cumulative energy use', key: 'cumulativeEnergyUse' },
    { title: 'Ambient light', key: 'ambientLight' },
  ];

  const [sensorRealTimeValue, setSelectedSensorRealTimeValue] = useState({ title: 'Select', key: '0' });
  const [openManualOverride, setOpenManualOverride] = useState(false);
  const [openPlotSensors, setOpenPlotSensors] = useState(false);
  const [openRealTimeValues, setOpenRealTimeValues] = useState(false);

  return (
    <div className="operation-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Operation</span>
            <div className="slide-in__links">
              {isNonReadOnly && (
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    setOpenManualOverride(!openManualOverride);
                    setOpenPlotSensors(false);
                    setOpenRealTimeValues(false);
                  }}
                  label="Manual override"
                />
              </div>
              )}
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    setOpenPlotSensors(!openPlotSensors);
                    setOpenManualOverride(false);
                    setOpenRealTimeValues(false);
                  }}
                  label="Plot sensor values"
                />
              </div>
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    setOpenRealTimeValues(!openRealTimeValues);
                    setOpenPlotSensors(false);
                    setOpenManualOverride(false);
                  }}
                  label="Get real time values"
                />
              </div>
            </div>
          </div>
          <div className="slide-in__content">
            {loading ? <Loading isLoading={loading} /> : (
              <div className="table">
                <Table
                  headers={headers}
                  data={operationMultiTableData}
                  fixColCount={1}
                  dark
                />
              </div>
            )}
            {openManualOverride
            && (
            <ManualOverride
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              selectedItems={selectedItems}
              closeButton
              closeButtonAction={() => setOpenManualOverride(false)}
              closeManualOverride={() => setOpenManualOverride(false)}
              notDropdown
              listLength={selectedItems.size}
              type="nodes"
              primaryButtonLabel=""
            />
            )}
            {openPlotSensors && (
            <PlotSensor
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              selectedItems={selectedItems}
              closeButton
              closeButtonAction={() => setOpenPlotSensors(false)}
            />
            )}
            {openRealTimeValues && (
            <RealTimeValues
              sensor={sensorRealTimeValue}
              sensorList={sensorListRealTimeValue}
              sensorSelectboxAction={(item: SelectBoxItemType) => setSelectedSensorRealTimeValue(item)}
              closeButton
              closeButtonAction={() => setOpenRealTimeValues(false)}
              refreshValue={() => {
                setReqSensors([sensorRealTimeValue.key]);
                refreshSensors();
                setOpenRealTimeValues(false);
              }}
            />
            )}
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsOperationMulti;
