/* eslint-disable camelcase */
import React from 'react';
import SelectBox from '../../../../../Common/Components/SelectBox';
import Textbox from '../../../../../Common/Components/Textbox';
import AccountNumberEditor from '../../../../../Common/Components/AccountNumberEditor';
import Utils from '../../../../../utils/Utils';

import { AccountFormProps } from '../../../../../types/AccountForm';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import AccountEcpdEditor from '../../../../../Common/Components/AccountEcpdEditor';

const selectboxWidth = 300;

function AccountForm(props: AccountFormProps): JSX.Element {
  const { data, setAccount, selectedPartner, setSelectedPartner, partners, currentPartner, validation } = props;
  return (
    <div className="account-form">
      <div className="account-form__inputs-account">
        <div className="account-form__subtitles">Account details</div>
        {(partners?.size && selectedPartner && setSelectedPartner) ? (
          <SelectBox
            type="light"
            label="Partner"
            title={selectedPartner.name}
            selectedItemKey={selectedPartner.id}
            onClick={(item: SelectBoxItemType) => {
              setSelectedPartner({ id: item.key, name: item.title });
            }}
            list={partners && Utils.arrayToSelectbox(Array.from(partners.values()), 'orgid', 'name')}
            isRequired
            error={validation.hasError}
            errorMessage={validation.errorMessage}
            selectboxWidth={selectboxWidth}
            listWidth={selectboxWidth}
          />
        ) : (
          <Textbox
            label="Partner"
            isRequired
            disabled
            value={currentPartner?.name}
            error={validation.hasNameError}
            errorMessage={validation.nameErrorMessage}
          />
        )}
        <Textbox
          label="Account name"
          name="Account name"
          placeholder="Enter"
          isRequired
          value={data.name}
          onChange={(e) => {
            const name = e.target.value;
            setAccount((oldValues) => ({ ...oldValues, name, hasNameError: false }));
          }}
          error={validation.hasNameError}
          errorMessage={validation.nameErrorMessage}
        />
        <AccountNumberEditor
          accountNumbers={data.vzids ? data.vzids : []}
          setAccountNumbers={(acc) => {
            setAccount((oldValues) => ({ ...oldValues, vzids: acc, hasNumError: false }));
          }}
        />
        <Textbox
          label="Notes"
          name="Notes"
          placeholder="Enter"
          value={data.notes}
          onChange={(e) => {
            const notes = e.target.value;
            setAccount((oldValues) => ({ ...oldValues, notes }));
          }}
          error={validation.hasNotesError}
          errorMessage={validation.NotesErrorMessage}
        />
        <AccountEcpdEditor
          accountEcpds={data.ecpd ? data.ecpd : []}
          setAccountEcpds={(acc) => {
            setAccount((oldValues) => ({ ...oldValues, ecpd: acc, hasNumError: false }));
          }}
        />
        {(Utils.isVerizonUser())
        && (
        <>
          <div className="account-form__subtitles">Account GPS coordinates</div>
          <div className="account-form__gps">
            <Textbox
              label="Latitude"
              name="Latitude"
              placeholder="Enter"
              isRequired
              value={data.latitude ?? ''}
              onChange={(e) => {
                const latitude = e.target.value;
                setAccount((oldValues) => ({ ...oldValues, latitude }));
              }}
              error={validation.hasLatError}
              errorMessage={validation.LatErrorMessage}
            />
            <Textbox
              label="Longitude"
              name="Longitude"
              placeholder="Enter"
              value={data.longitude ?? ''}
              isRequired
              onChange={(e) => {
                const longitude = e.target.value;
                setAccount((oldValues) => ({ ...oldValues, longitude }));
              }}
              error={validation.hasLonError}
              errorMessage={validation.LonErrorMessage}
            />
          </div>
        </>
        )}
      </div>
      <div className="account-form__inputs-contact">
        <div className="account-form__subtitles">Contact info</div>
        <Textbox
          label="Name"
          name="Name"
          placeholder="Enter"
          value={data.contact_name}
          onChange={(e) => {
            const contact_name = e.target.value;
            setAccount((oldValues) => ({ ...oldValues, contact_name }));
          }}
        />
        <Textbox
          label="Phone"
          name="Phone"
          placeholder="Enter"
          value={data.contact_phone}
          onChange={(e) => {
            const contact_phone = e.target.value;
            setAccount((oldValues) => ({ ...oldValues, contact_phone }));
          }}
        />
        <Textbox
          label="Email"
          name="Email"
          placeholder="Enter"
          value={data.contact_email}
          onChange={(e) => {
            const contact_email = e.target.value;
            setAccount((oldValues) => ({ ...oldValues, contact_email }));
          }}
        />
      </div>
      <div className="account-form__inputs-address">
        <div className="account-form__subtitles">Site address</div>
        <Textbox
          label="Address 1"
          name="Address 1"
          placeholder="Enter"
          value={data.street1}
          onChange={(e) => {
            const street1 = e.target.value;
            setAccount((oldValues) => ({ ...oldValues, street1 }));
          }}
        />
        <Textbox
          label="Address 2"
          name="Address 2"
          placeholder="Enter"
          value={data.street2}
          onChange={(e) => {
            const street2 = e.target.value;
            setAccount((oldValues) => ({ ...oldValues, street2 }));
          }}
        />
        <Textbox
          label="City"
          name="City"
          placeholder="Enter"
          value={data.city}
          onChange={(e) => {
            const city = e.target.value;
            setAccount((oldValues) => ({ ...oldValues, city }));
          }}
        />
        <div className="account-form__state">
          <Textbox
            label="State"
            name="State"
            placeholder="Enter"
            value={data.state}
            onChange={(e) => {
              const state = e.target.value;
              setAccount((oldValues) => ({ ...oldValues, state }));
            }}
          />
          <Textbox
            label="Zip"
            name="Zip"
            placeholder="Enter"
            value={data.postal_code}
            onChange={(e) => {
              const postal_code = e.target.value;
              setAccount((oldValues) => ({ ...oldValues, postal_code }));
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AccountForm;
