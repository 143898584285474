import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import SelectBox from '../../../../Common/Components/SelectBox';
import Textbox from '../../../../Common/Components/Textbox';
import { CreateLigGroupWithNodesProps, GroupDefaultScheduleResponseItem } from '../../../../types/GroupActionsPropTypes';
import { SelectBoxItemType } from '../../../../types/SelectBoxPropsType';
import { postRequest } from '../../../../utils/fetch';
import Utils from '../../../../utils/Utils';
import GroupActionsModalContent from '../GroupActionsModalContent';
import formValidation from '../../../../utils/form/formValidation';

import { ReactComponent as ScrollRightIcon } from '../../../../img/icons/scroll-right.svg';

const selectBoxWidth = 400;

function CreateLigGroupWithNodes(props: CreateLigGroupWithNodesProps): JSX.Element {
  // eslint-disable-next-line max-len
  const { setOpenModal, selectedNodes, selectedCustomer, selectedSite, addNotification, schedulesResp, updateGroup, siteLightingGroupOnly, selectedDefaultCustomer, selectedDefaultSite } = props;

  const getDefaultSchedule = () => {
    const defaultSchedule = schedulesResp?.find((sch) => sch.isDefault);
    if (defaultSchedule) {
      return { title: defaultSchedule.name, key: defaultSchedule.scheduleId };
    }
    return { title: '', key: '' };
  };

  const [putNodesLightingGroup, setPutNodesLightingGroup] = useState({
    name: '',
    description: '',
    schedule: (getDefaultSchedule() as SelectBoxItemType),
    hasError: false,
    errorMessage: '',
  });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const validationRules = { required: true, min: 2, max: 256, noSpecCharacters: true };
  function checkNameValidation(newName?: string) {
    const name = newName ?? putNodesLightingGroup.name;
    const validation = formValidation(
      name,
      validationRules,
    );
    setPutNodesLightingGroup((oldValues) => ({
      ...oldValues,
      name,
      hasError: validation.hasError,
      errorMessage: validation.errorMsg,
    }));
  }
  const validDefaultSchedule = schedulesResp?.find((sch) => sch.isDefault);
  const nodesSelectedSize = selectedNodes.size <= 10000 ? `${selectedNodes.size}` : 'First 10000';
  return (
    <Modal
      title={siteLightingGroupOnly ? 'Create Site Lighting Group' : 'Create lighting group with nodes'}
      width="580px"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonDisabled={submitDisabled}
      primaryButtonAction={async () => {
        if (!siteLightingGroupOnly && putNodesLightingGroup.name === '') {
          checkNameValidation();
        } else if (siteLightingGroupOnly || !putNodesLightingGroup.hasError) {
          setSubmitDisabled(true);
          const operationMsg = siteLightingGroupOnly ? 'Create Site Lighting Group' : 'Create lighting group with nodes';
          try {
            const schedule = putNodesLightingGroup.schedule.key ? [
              {
                scheduleId: putNodesLightingGroup.schedule.key,
                name: putNodesLightingGroup.schedule.title,
              },
            ] : [];

            const postBody = siteLightingGroupOnly ? {
              operation: 'CREATE_GROUP',
              name: 'Site Lighting Group',
              description: 'Default Lighting Group',
              nodeIds: [],
              type: 'SITE_LIGHTING',
              nodeType: 'LSN',
              scheduleId: schedule.length === 1 ? schedule[0].scheduleId : null,
            } : {
              operation: 'CREATE_GROUP',
              name: putNodesLightingGroup.name,
              description: putNodesLightingGroup.description,
              nodeIds: (selectedNodes.size <= 10000) ? Array.from(selectedNodes.keys()) : Array.from(selectedNodes.keys()).splice(0, 10000),
              type: 'LIGHTING',
              nodeType: 'LSN',
              scheduleId: schedule.length === 1 ? schedule[0].scheduleId : null,
            };
            if (postBody.nodeIds.length === 0 && !siteLightingGroupOnly) {
              addNotification({ type: 'error', message: 'Your "Create lighting group with nodes" operation has failed: Node List is empty, it must contain at least one element. Please try again.' });
              setOpenModal(0);
            } else {
              let result: { error: string; data?: unknown; status?: number; };
              if (selectedDefaultCustomer && selectedDefaultSite) {
                if (validDefaultSchedule === undefined) {
                  postRequest(
                    `/organizations/${selectedDefaultCustomer}/sites/${selectedDefaultSite}/schedules/create-default-schedule`,
                  ).then(async (response) => {
                    if (response.status === 201) {
                      addNotification({ type: 'success', message: 'Default schedule has been created successfully' });
                      const respData = response.data as GroupDefaultScheduleResponseItem;
                      const payload = { operation: 'CREATE_GROUP',
                        name: 'Site Lighting Group',
                        description: 'Default Lighting Group',
                        nodeIds: [],
                        type: 'SITE_LIGHTING',
                        nodeType: 'LSN',
                        scheduleId: respData.scheduleid,
                      };
                      result = await postRequest(
                        `/organizations/${selectedDefaultCustomer}/sites/${selectedDefaultSite}/async-jobs/group-move`,
                        payload,
                      );
                      if (!result.error) {
                        addNotification({ type: 'info', message: `Your "${operationMsg}" operation has started.  You can check the Jobs page for status.` });
                        updateGroup();
                        setOpenModal(0);
                      } else {
                        addNotification({ type: 'error', message: `Your "${operationMsg}" operation has failed: ${result.error}` });
                      }
                    } else {
                      addNotification({ type: 'error', message: 'Default schedule creation has failed. Hence the job to create the Site Lighting Group cannot be initiated. ' });
                    }
                  });
                } else {
                  result = await postRequest(
                    `/organizations/${selectedDefaultCustomer}/sites/${selectedDefaultSite}/async-jobs/group-move`,
                    postBody,
                  );
                  if (!result.error) {
                    addNotification({ type: 'info', message: `Your "${operationMsg}" operation has started.  You can check the Jobs page for status.` });
                    updateGroup();
                    setOpenModal(0);
                  } else {
                    addNotification({ type: 'error', message: `Your "${operationMsg}" operation has failed: ${result.error}` });
                  }
                }
              } else {
                result = await postRequest(
                  `/organizations/${selectedCustomer?.id}/sites/${selectedSite?.id}/async-jobs/group-move`,
                  postBody,
                );
                if (!result.error) {
                  addNotification({ type: 'info', message: `Your "${operationMsg}" operation has started.  You can check the Jobs page for status.` });
                  updateGroup();
                  setOpenModal(0);
                } else {
                  addNotification({ type: 'error', message: `Your "${operationMsg}" operation has failed: ${result.error}` });
                }
              }
            }
          } catch (e) {
            addNotification({ type: 'error', message: `Your "${operationMsg}" operation has failed.` });
          }
        }
      }}
    >
      <div className="group-actions__modal-container">
        <div className="content">
          <div className="content__container">
            {siteLightingGroupOnly
              ? (
                <div className="content-text">
                  This site has no Site Lighting Group and is not functional.
                  <br />
                  <br />
                  If a job to create the group is not already in progress,
                  <br />
                  then submit this form to launch the job to create it.
                  <br />
                  <br />
                  Check the Jobs page for status.
                  <br />
                  <br />
                  {(validDefaultSchedule === undefined)
                    ? (
                      <div>
                        Also this site does not have a default schedule, so a default schedule will be created as a part of Site Lighting Group creation.
                        <br />
                        <br />
                      </div>
                    ) : ('')}
                </div>
              ) : (
                <div className="content__container-nodes-size">
                  <div className="content__container-nodes-selected">
                    <span>{nodesSelectedSize}</span>
                    Nodes selected
                  </div>
                  <div className="margin-top-15">
                    <ScrollRightIcon />
                  </div>
                </div>
              )}
            <div className="content__container-form">
              <Textbox
                label="Lighting group name"
                value={siteLightingGroupOnly ? 'Site Lighting Group ' : putNodesLightingGroup.name}
                placeholder="Enter name"
                disabled={siteLightingGroupOnly}
                onChange={(event) => {
                  const name = event.target.value;
                  checkNameValidation(name);
                }}
                isRequired
                error={putNodesLightingGroup.hasError}
                errorMessage={putNodesLightingGroup.errorMessage}
                gridTemplateColumns="350px 350px"
              />
              <Textbox
                label="Description"
                placeholder="Enter description"
                value={siteLightingGroupOnly ? 'Default Lighting Group' : putNodesLightingGroup.description}
                disabled={siteLightingGroupOnly}
                onChange={(event) =>
                  setPutNodesLightingGroup((oldValues) =>
                    ({ ...oldValues, description: event.target.value }))}
                gridTemplateColumns="350px 350px"
              />
              <SelectBox
                label="Schedule"
                onClick={(item: SelectBoxItemType) =>
                  setPutNodesLightingGroup((oldValues) => ({ ...oldValues, schedule: item }))}
                selectedItemKey={putNodesLightingGroup?.schedule?.key || ''}
                list={Utils.arrayToSelectbox(schedulesResp || [], 'scheduleId', 'name')}
                title={putNodesLightingGroup.schedule.title || 'no schedule'}
                type="light"
                listWidth={selectBoxWidth}
              />
            </div>
          </div>
        </div>
        <GroupActionsModalContent
          groupName={putNodesLightingGroup.name || ''}
          type="create-group"
        />
      </div>
    </Modal>
  );
}

export default CreateLigGroupWithNodes;
