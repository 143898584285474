import React, { useState } from 'react';
import DropDown from '../../../Common/Components/DropDown';
import Button from '../../../Common/Components/Button';
import RadioButtons from '../../../Common/Components/RadioButtons';
import Datepicker from '../../../Common/Components/Datepicker';
import { RadioButtonElementProps } from '../../../types/RadioButtonsProps';
import { JobsFilterPropsType } from '../../../types/JobsFilter';
import SelectBox from '../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import Utils from '../../../utils/Utils';
import { jobStatusMsg, jobTypeMsg } from '../../../utils/constants';

function JobsFilter(props: JobsFilterPropsType): JSX.Element {
  const {
    className,
    jobFilterSettings,
    handleActiveToolbar,
    setApplyJobFilter,
  } = props;

  const displayByItems = [
    { label: 'Time frame', key: '0' },
    { label: 'Date range', key: '1' },
  ];

  const timeFrameItems = [
    { title: 'Today', key: '0' },
    { title: 'Last 7 days', key: '1' },
    { title: 'Last 30 days', key: '2' },
    { title: 'Last 60 days', key: '3' },
  ];

  const showOtaJobs = Utils.isVerizonUser();

  const jobTypeItems: SelectBoxItemType[] = [
    ...Object.entries(jobTypeMsg).filter((typemsg) => (showOtaJobs || typemsg[1].indexOf('firmware') < 0)).map((typeMsg) => ({ title: typeMsg[1], key: typeMsg[0] })),
  ];

  const jobStatusItems: RadioButtonElementProps[] = [
    ...Object.entries(jobStatusMsg).map((statusMsg) => ({ label: statusMsg[1], key: statusMsg[0] })),
  ];

  const [valueDisplayBy, setValueDisplayBy] = useState<RadioButtonElementProps>(
    Utils.valueToRadioButtonItem(jobFilterSettings.displayBy, displayByItems),
  );
  const [valueStatus, setValueStatus] = useState<RadioButtonElementProps>(
    { key: jobFilterSettings.status, label: jobStatusMsg[jobFilterSettings.status] },
  );
  const [valueType, setValueType] = useState<SelectBoxItemType>(
    { key: jobFilterSettings.jobType, title: jobTypeMsg[jobFilterSettings.jobType] },
  );
  const [valueTimeFrame, setValueTimeFrame] = useState<SelectBoxItemType>(
    Utils.valueToSelectBoxItem(jobFilterSettings.timeFrame, timeFrameItems),
  );
  const [valueStartDate, setValueStartDate] = useState<Date>(
    new Date(jobFilterSettings.startDate),
  );
  const [valueEndDate, setValueEndDate] = useState<Date>(
    new Date(jobFilterSettings.endDate),
  );

  const listWidth = 240;

  return (
    <DropDown
      className={className}
    >
      <div className="dropdown__content jobs-filter">
        <div className="jobs-filter__displayby-section">
          <div className="radio-buttons__label">
            Display by
          </div>
          <RadioButtons
            onClick={(clickedRadioBtn: RadioButtonElementProps) =>
              setValueDisplayBy(clickedRadioBtn)}
            selected={valueDisplayBy}
            list={displayByItems}
            type="dark"
          />
        </div>

        <div className="jobs-filter__form-section">
          {valueDisplayBy.key === '0' && (
          <SelectBox
            label="Time frame"
            title={valueTimeFrame.title}
            selectedItemKey={valueTimeFrame.key}
            list={Utils.arrayToSelectbox(timeFrameItems, 'key', 'title')}
            listWidth={listWidth}
            onClick={(item: SelectBoxItemType) => {
              setValueTimeFrame(item);
            }}
            type="dark"
          />
          )}
          {valueDisplayBy.key === '1' && (
            <>
              <div className="radio-buttons__label">
                Start date
              </div>
              <Datepicker
                placement="bottom"
                selectedDate={new Date(valueStartDate)}
                setSelectedDate={setValueStartDate}
                type="dark"
                maxDate={new Date(valueEndDate)}
              />
              <div className="radio-buttons__label">
                End date
              </div>
              <Datepicker
                placement="bottom"
                selectedDate={new Date(valueEndDate)}
                setSelectedDate={setValueEndDate}
                type="dark"
                minDate={new Date(valueStartDate)}
                maxDate={new Date()}
              />
            </>
          )}
          <SelectBox
            label="Job type"
            title={valueType.title}
            selectedItemKey={valueType.key}
            list={Utils.arrayToSelectbox(jobTypeItems, 'key', 'title')}
            listWidth={listWidth}
            onClick={(item: SelectBoxItemType) => {
              setValueType(item);
            }}
            type="dark"
          />
          <div className="radio-buttons__label">
            Status
          </div>
          <RadioButtons
            onClick={(clickedRadioBtn: RadioButtonElementProps) =>
              setValueStatus(clickedRadioBtn)}
            selected={valueStatus}
            list={jobStatusItems}
            type="dark"
          />
        </div>
        <div className="jobs-filter__button-bar">
          <Button
            buttonType="primary"
            onClick={() => {
              handleActiveToolbar(0);
            }}
          >
            <span>Cancel</span>
          </Button>
          <Button
            buttonType="secondary"
            onClick={() => {
              setApplyJobFilter({
                displayBy: valueDisplayBy.label,
                timeFrame: valueTimeFrame.title,
                startDate: valueStartDate.toISOString(),
                endDate: valueEndDate.toISOString(),
                jobType: valueType.key,
                status: valueStatus.key,
              });
              handleActiveToolbar(0);
            }}
          >
            <span>Apply</span>
          </Button>
        </div>
      </div>
    </DropDown>
  );
}

export default JobsFilter;
