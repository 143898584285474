import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, DropResult, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { WizardOutputSelectorProps } from '../../../../../types/AlertActivityReportWizard';
import Checkbox from '../../../../../Common/Components/Checkbox';
import Button from '../../../../../Common/Components/Button';
import { alertActivityReportColumsList } from '../../../../../utils/constants';
import { AlertActivityReportColumn } from '../../../../../types/AlertActivityReports';
import { ReactComponent as DnDIcon } from '../../../../../img/icons/drag-and-drop.svg';

function WizardOutputSelector(props: WizardOutputSelectorProps): JSX.Element {
  const { reportObject, setReportObject } = props;

  const [columns, setColumns] = useState<AlertActivityReportColumn[]>(alertActivityReportColumsList.map((item) => ({
    ...item,
    selected: (reportObject.columns.length > 0 ? !!reportObject.columns.find((col) => col === item.key) : item.selected),
  })));

  useEffect(() => {
    setReportObject((origItem) => {
      const newItem = { ...origItem };
      const newCols: string[] = [];

      columns.forEach((col) => {
        if (col.selected) {
          newCols.push(col.key);
        }
      });

      newItem.columns = newCols;

      return newItem;
    });
  }, [columns, setReportObject]);

  const setItemSelected = (index: number, checked: boolean) => {
    const newColumns = [...columns];
    newColumns[index].selected = checked;

    setColumns(newColumns);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(columns);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);

    setColumns(items);
  };

  return (
    <div className="wizard-output-selector">
      <div className="wizard-output-selector--title">Select and reorder columns</div>
      <div className="wizard-output-selector--header">
        <Checkbox
          checked={columns.every((col) => col.selected)}
          dark={false}
          onClick={(checked) => { setColumns((old) => old.map((col) => ({ ...col, selected: checked }))); }}
        />
        <Button
          label="Reset columns to default"
          onClick={() => { setColumns(alertActivityReportColumsList); }}
        />
      </div>
      <div className="wizard-output-selector--content">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided: DroppableProvided, _droppableSnapshot: DroppableStateSnapshot) => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                style={{
                  width: '590px',
                  maxHeight: '355px',
                  overflowY: 'scroll',
                }}
              >
                {columns.map((item, index) => (
                  <Draggable key={item.key} draggableId={item.key} index={index}>
                    {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => {
                      const usePortal: boolean = draggableSnapshot.isDragging;
                      const child = (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className="wizard-draggable-column"
                        >
                          <DnDIcon />
                          <Checkbox
                            checked={item.selected}
                            dark={false}
                            onClick={(checked) => setItemSelected(index, checked)}
                          />
                          <div className="column-title">{item.title}</div>
                        </div>
                      );

                      if (!usePortal) {
                        return child;
                      }

                      return ReactDOM.createPortal(child, document.body);
                    }}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default WizardOutputSelector;
