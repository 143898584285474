/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import DropDown from '../../../Common/Components/DropDown';

import { ReactComponent as LightingGroupIcon } from '../../../img/icons/lighting-group.svg';
import { ReactComponent as OrgGroupIcon } from '../../../img/icons/org-group.svg';

import { GroupActionsPropTypes } from '../../../types/GroupActionsPropTypes';
import GroupActionListTitle from './GroupActionListTitle';
import GroupActionListElement from './GroupActionListElement';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { useAppContext } from '../../../utils/AppContext';
import MoveNodesToLightingGroup from './GroupActionsModals/MoveNodesToLightingGroup';
import UpdateLightingGroup from './GroupActionsModals/UpdateLightingGroup';
import CreateLigGroupWithNodes from './GroupActionsModals/CreateLigGroupWithNodes';
import AddNodesToOrgGroup from './GroupActionsModals/AddNodesToOrgGroup';
import RemoveNodesFromOrgGroup from './GroupActionsModals/RemoveNodesFromOrgGroup';
import UpdateOrgGroup from './GroupActionsModals/UpdateOrgGroup';
import CreateOrgGroupWithNodes from './GroupActionsModals/CreateOrgGroupWithNodes';
import RetryAddToSLGModal from '../../../Common/Components/RetryAddToSLGModal';
import Utils from '../../../utils/Utils';

const notificationWarningMessage = 'No nodes were selected for the operation.';

function GroupActions(props: GroupActionsPropTypes): JSX.Element {
  const {
    lightingGroups,
    orgGroups,
    selectedNodes,
    schedulesResp,
    closeDropdown,
    selectedCustomer,
    selectedSite,
    updateGroup,
  } = props;

  const { addNotification } = useAppContext();
  const isSensityUserAdmin = Utils.isSensityUserAdmin();

  const selectedNodeList: string[] = Array.from(selectedNodes.keys());

  const [updateLightingGroup, setUpdateLightingGroup] = useState({
    hasError: false,
    list: ({ title: '', key: '' } as SelectBoxItemType),
  });
  const updateNodesLightingGroups = () => {
    if (updateLightingGroup.list.title === '') {
      return {
        add: [],
        remove: [],
      };
    }

    const nodeListOfSelectedLightingGroups = lightingGroups
      .find((lightingGroup) => lightingGroup.name === updateLightingGroup.list.title)
      ?.nodeList;

    const remove = nodeListOfSelectedLightingGroups
      ?.filter((nodeIdfilter: string) => !selectedNodeList
        .find((nodeId) => nodeId === nodeIdfilter));

    const add = selectedNodeList.filter((nodeId) => !nodeListOfSelectedLightingGroups
      ?.find((nodeIdFind) => nodeIdFind === nodeId));

    return {
      add,
      remove,
    };
  };

  const [updateOrgGroup, setUpdateOrgGroup] = useState({
    hasError: false,
    list: ({ title: '', key: '' } as SelectBoxItemType),
  });

  const [openDropdown, setOpenDropdown] = useState('lighting-groups');
  const [openModal, _setOpenModal] = useState(0);
  const setOpenModal = (newModal: number) => {
    _setOpenModal(newModal);

    if (newModal === 0) {
      closeDropdown();
    }
  };

  const getOrgGroupNodes = () => {
    if (updateOrgGroup.list.key === '') {
      return {
        add: [],
        remove: [],
      };
    }

    const selectedOrgGroupId = updateOrgGroup.list.key;
    const selectedOrgGroupsNodeIdList = orgGroups
      .find((orgGroup) => orgGroup.groupId === selectedOrgGroupId)?.nodeList;

    const add = selectedNodeList.filter((nodeIdfRomSelection) => !selectedOrgGroupsNodeIdList
      ?.find((x) => x === nodeIdfRomSelection));

    const remove = selectedOrgGroupsNodeIdList?.filter((nodeId) => !selectedNodeList
      ?.find((selectedNodeId) => selectedNodeId === nodeId));

    return {
      add,
      remove,
    };
  };

  const setOpenModalOrAddNoti = (modalId: number) => {
    if (selectedNodeList.length === 0) {
      addNotification({ type: 'warning', message: notificationWarningMessage });
    } else {
      setOpenModal(modalId);
    }
  };

  const modalMoveNodesToLightingGroup = 1;
  const modalUpdateLightingGroup = 2;
  const modalCreateLightingGroupWithNodes = 3;
  const modalAddNodesToOrgGroup = 4;
  const modalRemoveNodesFromOrgGroup = 5;
  const modalUpdateOrgGroup = 6;
  const modalCreateOrgGroupWithNodes = 7;
  const modalRetryAddToSLG = 8;

  const listElementTitle = (modalId: number, numSelected: number) => {
    let msg = '';
    const nodeMsg = numSelected <= 1 ? 'this node' : `these ${numSelected} nodes`;
    const maxNodesMsg = numSelected <= 10000 ? `these ${numSelected} nodes` : 'first 10000 nodes';
    const maxNodesMsgForSLG = numSelected <= 1000 ? `these ${numSelected} nodes` : 'first 1000 nodes';
    const nodesCreateGroupLimitMsg = numSelected <= 1 ? 'this node' : maxNodesMsg;

    switch (modalId) {
      case modalMoveNodesToLightingGroup:
        msg = (numSelected === 0)
          ? 'Move (select one or more nodes to move)'
          : `Move ${nodeMsg} to an existing group`;
        break;
      case modalUpdateLightingGroup:
      case modalUpdateOrgGroup:
        msg = (numSelected === 0)
          ? 'Redefine (select a new set of nodes for an existing group)'
          : `Redefine an existing group to contain only ${nodeMsg}`;
        break;
      case modalCreateLightingGroupWithNodes:
      case modalCreateOrgGroupWithNodes:
        msg = (numSelected === 0)
          ? 'Create (select one or more nodes for new group)'
          : `Create a new group with ${nodesCreateGroupLimitMsg}`;
        break;
      case modalAddNodesToOrgGroup:
        msg = (numSelected === 0)
          ? 'Add (select one or more nodes to add to a group)'
          : `Add ${nodeMsg} to an existing group`;
        break;
      case modalRemoveNodesFromOrgGroup:
        msg = (numSelected === 0)
          ? 'Remove (select one or more nodes to remove from a group)'
          : `Remove ${nodeMsg} from a group`;
        break;
      case modalRetryAddToSLG:
        msg = (numSelected === 0)
          ? 'Retry add to SLG (select one or more nodes to retry)'
          : `Retry adding ${maxNodesMsgForSLG} to SLG`;
        break;
      default:
        break;
    }
    return msg;
  };

  return (
    <div className="group-actions">
      { openModal === 0 && (
      <DropDown>
        <div className="group-actions__container">
          <div onClick={() => setOpenDropdown('lighting-groups')}>
            <GroupActionListTitle
              openDropdown={openDropdown}
              id="lighting-groups"
              icon={<LightingGroupIcon />}
              title="Lighting groups"
              selectedNodes={selectedNodes}
            >
              <ul className="group-actions__container-group-list">
                <div onClick={() => setOpenModalOrAddNoti(modalMoveNodesToLightingGroup)} className="no-top-mg">
                  <GroupActionListElement title={listElementTitle(modalMoveNodesToLightingGroup, selectedNodes.size)} />
                </div>
                {/* <div onClick={() => setOpenModalOrAddNoti(modalUpdateLightingGroup)}>
                  <GroupActionListElement title={listElementTitle(modalUpdateLightingGroup, selectedNodes.size)} />
                </div> */}
                <div onClick={() => setOpenModalOrAddNoti(modalCreateLightingGroupWithNodes)}>
                  <GroupActionListElement title={listElementTitle(modalCreateLightingGroupWithNodes, selectedNodes.size)} />
                </div>
                { isSensityUserAdmin
                  && (
                  <div onClick={() => setOpenModalOrAddNoti(modalRetryAddToSLG)}>
                    <GroupActionListElement title={listElementTitle(modalRetryAddToSLG, selectedNodes.size)} />
                  </div>
                  )}
              </ul>
            </GroupActionListTitle>
          </div>
          <div onClick={() => setOpenDropdown('org-groups')}>
            <GroupActionListTitle
              openDropdown={openDropdown}
              id="org-groups"
              icon={<OrgGroupIcon />}
              title="Organizational groups"
              selectedNodes={selectedNodes}
            >
              <ul className="group-actions__container-group-list">
                <div onClick={() => setOpenModalOrAddNoti(modalAddNodesToOrgGroup)} className="no-top-mg">
                  <GroupActionListElement title={listElementTitle(modalAddNodesToOrgGroup, selectedNodes.size)} />
                </div>
                <div onClick={() => setOpenModalOrAddNoti(modalRemoveNodesFromOrgGroup)}>
                  <GroupActionListElement title={listElementTitle(modalRemoveNodesFromOrgGroup, selectedNodes.size)} />
                </div>
                { /* }
                <div onClick={() => setOpenModalOrAddNoti(modalUpdateOrgGroup)}>
                  <GroupActionListElement title={listElementTitle(modalUpdateOrgGroup, selectedNodes.size)} />
                </div>
                { */ }
                <div onClick={() => setOpenModalOrAddNoti(modalCreateOrgGroupWithNodes)}>
                  <GroupActionListElement title={listElementTitle(modalCreateOrgGroupWithNodes, selectedNodes.size)} />
                </div>
              </ul>
            </GroupActionListTitle>
          </div>
        </div>
      </DropDown>
      )}
      {openModal === modalMoveNodesToLightingGroup && (
        <div className="no-padding-modal group-actions__modal">
          <MoveNodesToLightingGroup
            setOpenModal={setOpenModal}
            selectedNodes={selectedNodes}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            lightingGroups={lightingGroups}
            addNotification={addNotification}
            updateGroup={updateGroup}
          />
        </div>
      )}
      {openModal === modalUpdateLightingGroup && (
        <div className="no-padding-modal group-actions__modal">
          <UpdateLightingGroup
            setOpenModal={setOpenModal}
            selectedNodes={selectedNodes}
            updateLightingGroup={updateLightingGroup}
            setUpdateLightingGroup={setUpdateLightingGroup}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            addNotification={addNotification}
            lightingGroups={lightingGroups}
            updateNodesLightingGroups={updateNodesLightingGroups}
            updateGroup={updateGroup}
          />
        </div>
      )}
      {openModal === modalCreateLightingGroupWithNodes && (
        <div className="no-padding-modal group-actions__modal">
          <CreateLigGroupWithNodes
            setOpenModal={setOpenModal}
            selectedNodes={selectedNodes}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            addNotification={addNotification}
            schedulesResp={schedulesResp}
            updateGroup={updateGroup}
          />
        </div>
      )}
      {openModal === modalRetryAddToSLG && (
        <div className="no-padding-modal group-actions__modal">
          <RetryAddToSLGModal
            setModalOpen={() => setOpenModal(0)}
            nodeList={(selectedNodes.size <= 1000) ? Array.from(selectedNodes.keys()) : Array.from(selectedNodes.keys()).splice(0, 1000)}
            customerid={selectedCustomer.id}
            siteid={selectedSite.id}
          />
        </div>
      )}

      {openModal === modalAddNodesToOrgGroup && (
        <AddNodesToOrgGroup
          setOpenModal={setOpenModal}
          selectedNodes={selectedNodes}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          addNotification={addNotification}
          orgGroups={orgGroups}
          updateGroup={updateGroup}
        />
      )}
      {openModal === modalRemoveNodesFromOrgGroup && (
        <div className="remove-org">
          <RemoveNodesFromOrgGroup
            setOpenModal={setOpenModal}
            selectedNodes={selectedNodes}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            addNotification={addNotification}
            orgGroups={orgGroups}
            updateGroup={updateGroup}
          />
        </div>
      )}
      {openModal === modalUpdateOrgGroup && (
        <div className="no-padding-modal group-actions__modal">
          <UpdateOrgGroup
            setOpenModal={setOpenModal}
            updateOrgGroup={updateOrgGroup}
            setUpdateOrgGroup={setUpdateOrgGroup}
            selectedNodes={selectedNodes}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            addNotification={addNotification}
            orgGroups={orgGroups}
            getOrgGroupNodes={getOrgGroupNodes}
            updateGroup={updateGroup}
          />
        </div>
      )}
      {openModal === modalCreateOrgGroupWithNodes && (
        <div className="no-padding-modal create-org-group group-actions__modal">
          <CreateOrgGroupWithNodes
            setOpenModal={setOpenModal}
            selectedNodes={selectedNodes}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            addNotification={addNotification}
            updateGroup={updateGroup}
          />
        </div>
      )}
    </div>
  );
}

export default GroupActions;
