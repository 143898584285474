import React from 'react';
import Modal from '../../../../Common/Components/Modal';
import { LightsGroupsRemoveFromOrgGroupModalProps } from '../../../../types/LightsGroupProps';
import { postRequest } from '../../../../utils/fetch';

function LightsGroupsRemoveFromOrgGroup(props: LightsGroupsRemoveFromOrgGroupModalProps): JSX.Element {
  const {
    setModalOpen,
    selectedNode,
    selectedCustomer,
    selectedSite,
    addNotification,
    updateGroup,
    removeFromOrgGroup,
  } = props;
  return (
    <Modal
      title="Remove node from org group"
      width="372"
      setModalOpen={() => setModalOpen(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setModalOpen(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        try {
          const newNodeList = [selectedNode.nodeid];
          const postBody = {
            operation: 'REMOVE_NODES',
            fromGroupId: removeFromOrgGroup?.groupId,
            nodeIds: newNodeList,
          };

          const result = await postRequest(
            `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/async-jobs/group-move`,
            postBody,
          );

          if (!result.error) {
            addNotification({ type: 'success', message: 'Your "Remove node from org group" operation is completed.' });
            if (updateGroup) {
              updateGroup();
            }
            setModalOpen(0);
          } else {
            addNotification({ type: 'error', message: `Your "Remove node from org group" operation has failed: ${result.error}` });
          }
        } catch (e) {
          addNotification({ type: 'error', message: 'Your "Remove node from org group" operation has failed.' });
        }
      }}
    >
      <div>
        Are you sure you want to delete this node from org group
        <span className="group-name">
          {' '}
          { removeFromOrgGroup?.name }
          ?
        </span>
      </div>
    </Modal>
  );
}

export default LightsGroupsRemoveFromOrgGroup;
