import React from 'react';
import { RetryAddToSLGModalProps } from '../../types/RetryAddToSLGModalProps';
import { useAppContext } from '../../utils/AppContext';
import { postRequest } from '../../utils/fetch';
import Modal from './Modal';

function RetryAddToSLGModal(props: RetryAddToSLGModalProps): JSX.Element {
  const { setModalOpen, customerid, siteid, nodeList } = props;
  const { addNotification } = useAppContext();
  return (
    <Modal
      title="Retry add to SLG"
      width="360"
      setModalOpen={setModalOpen}
      primaryButtonLabel="Confirm"
      primaryButtonAction={async () => {
        try {
          const path = `/organizations/${customerid}/sites/${siteid}/site-lighting-group/nodes/retry`;
          const postBody = { nodeIds: nodeList };
          const result = await postRequest(
            path,
            postBody,
          );
          if (!result.error) {
            addNotification({ type: 'info', message: 'Addition of nodes to Site Lighting Group is being retried.' });
          } else {
            addNotification({ type: 'error', message: 'Addition of nodes to Site Lighting Group failed.' });
          }
          setModalOpen();
        } catch (e) {
          addNotification({ type: 'error', message: `Addition of nodes to Site Lighting Group failed: ${e}` });
          setModalOpen();
        }
      }}
      secondaryButtonLabel="Cancel"
      className="commissioning"
    >
      <span className="force-modal-padding">
        Are you sure you want to retry adding the
        <br />
        selected node(s) to the Site Lighting Group?
      </span>
    </Modal>
  );
}

export default RetryAddToSLGModal;
