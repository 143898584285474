/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { usePerformanceMark, Stage } from '@cabify/prom-react';
import Textbox from '../../../../Common/Components/Textbox';
import Button from '../../../../Common/Components/Button';
import { useAppContext } from '../../../../utils/AppContext';
import { postRequest, getRequest } from '../../../../utils/fetch';

import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import { NodediagnosticsPageProps, NodeDiagnosticsValidationProp } from '../../../../types/NodediagnosticsPageProps';
import { ResponseNodeDiagnosticsRealtime } from '../../../../types/NodeDiagnosticsRealtimeProps';

function NodeDiagnosticsPage(props: NodediagnosticsPageProps): JSX.Element {
  const [IMEI, setIMEI] = useState('');
  const [objectId, setObjectId] = useState();
  const [instanceId, setInstanceId] = useState();
  const [resourceId, setResourceId] = useState();
  const [subResourceId, setSubResourceId] = useState();
  const [Result, setResult] = useState({});
  const [ResultTitle, setResultTitle] = useState('');
  const [Waiting, setWaiting] = useState(false);
  const [Attempts, setAttempts] = useState(0);

  let count = 0;

  const { addNotification } = useAppContext();
  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  const [nodeDiagnosticsValidation, setNodeDiagnosticsValidation] = useState<NodeDiagnosticsValidationProp>({
    IMEIError: false,
    IMEIErrorMessage: '',
    resourceIDError: false,
    resourceIDErrorMessage: '',
  });
  function checkValidation() : boolean {
    if (IMEI === '') {
      setNodeDiagnosticsValidation((oldValues) => ({
        ...oldValues,
        IMEIError: true,
        IMEIErrorMessage: 'IMEI is required',
      }));
      return true;
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function convertValueField(response: { [k: string]: any }): { [k: string]: any } {
    if (response.resourcePath !== '26264/0') {
      return response;
    }

    const newValue = Object.fromEntries(
      Object.entries(response.value)
        .map((entry) => convertBase64ToNumber(entry as string[])),
    );

    return {
      resourcePath: response.resourcePath,
      value: newValue,
    };
  }

  function convertHexToNumber(value: string): number {
    return +escape(value)
      .split(/%/)
      .filter((e) => e)
      .map((e) => +(`0x${e}`))
      .join('');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function convertBase64ToNumber(entry: string[]): any[] {
    if (['26264/0/3', '26264/0/4', '26264/0/7'].includes(entry[0])) {
      return [entry[0], convertHexToNumber(atob(entry[1]))];
    }

    return [entry[0], atob(entry[1])];
  }

  function doExternal() {
    try {
      getRequest(
        `/diag/node/devicedetailexternal/nokia/${IMEI}?timeout=60`,
      ).then((response) => {
        setResultTitle('External');
        setResult(response);
        setWaiting(false);
      });
    } catch (e) {
      addNotification({ type: 'error', message: 'External call failed' });
    }
  }
  function doConnectionStatus() {
    try {
      getRequest(
        `/nodes/${IMEI}/connection-status`,
      ).then((response) => {
        setResultTitle('Connection status');
        if (response.status && response.status === 404) {
          setResult('No events have been sent by a node with this IMEI.');
        } else {
          setResult(response);
        }
        setWaiting(false);
      });
    } catch (e) {
      addNotification({ type: 'error', message: 'Connection status call failed' });
    }
  }
  function doGPS() {
    getRequest(
      `/nodes/${IMEI}`,
    ).then((response) => {
      if (!(response.orgid && response.siteid)) {
        addNotification({ type: 'error', message: `No node found with ID: ${IMEI}.` });
      } else {
        getRequest(
          `/organizations/${response.orgid}/sites/${response.siteid}/nodes/${IMEI}/gps`,
        ).then((response2) => {
          setResultTitle('GPS');
          setResult(response2);
        });
      }
      setWaiting(false);
    }).catch(() => {
      setWaiting(false);
      addNotification({ type: 'error', message: 'GPS call failed' });
    });
  }

  function doStatic() {
    try {
      getRequest(
        `/diag/node/devicedetailinternal/nokia/${IMEI}?timeout=60`,
      ).then((response) => {
        setResultTitle('Static');
        setResult(response);
        setWaiting(false);
      });
    } catch (e) {
      addNotification({ type: 'error', message: 'Static call failed' });
    }
  }

  function pollRealtime(requestID) {
    if (count >= 18) {
      addNotification({ type: 'info', message: 'No results after 18 attempts' });
      count = 0;
      setWaiting(false);
      setAttempts(0);
    } else {
      try {
        getRequest(
          `/diag/jobs/${requestID}`,
        ).then((response) => {
          // fetch library turns 204 into empty response
          if (Object.getOwnPropertyNames(response).length === 0) {
            if (count >= 0) {
              count += 1;
              setAttempts(count);
              forceUpdate();
              setWaiting(true);
              window.setTimeout(() => { pollRealtime(requestID); }, 5000);
            }
          } else {
            switch (response.status) {
              case 'DONE':
                count = 0;
                setAttempts(count);
                setResultTitle('Realtime');
                setResult(convertValueField(response.diagnosticsObject));
                setWaiting(false);
                break;
              case 'PENDING':
                count += 1;
                setAttempts(count);
                forceUpdate();
                setWaiting(true);
                window.setTimeout(() => { pollRealtime(requestID); }, 5000);
                break;
              case 'EXPIRED':
                addNotification({ type: 'error', message: `API call status : ${response.status}` });
                count = 0;
                setWaiting(false);
                break;
              default:
                addNotification({ type: 'error', message: `API call failed : ${response.status}` });
                count = 0;
                setWaiting(false);
                break;
            }
          }
        });
      } catch (e) {
        setWaiting(false);
        addNotification({ type: 'error', message: 'API call failed' });
      }
    }
  }

  function doRealtime() {
    const url = `/diag/node/devicedetailrealtime/${IMEI}`;
    type requestBody = {
      objectId?: string;
      instanceId?: string;
      resourceId?: string;
      subResourceId?: string;
    }
    const body: requestBody = {};
    if (resourceId && resourceId !== '') {
      body.resourceId = resourceId;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!confirm('By not specifying a resourceId, you are about to fetch all available resources for this node, '
        + 'which can take up 30 minutes through Nokia.  Are you sure you want to do this?')) {
        setWaiting(false);
        return;
      }
    }
    if (objectId && objectId !== '') {
      body.objectId = objectId;
    }
    if (instanceId && instanceId !== '') {
      body.instanceId = instanceId;
    }
    if (subResourceId && subResourceId !== '') {
      body.subResourceId = resourceId;
    }

    setWaiting(true);

    try {
      postRequest(url, body)
        .then((result) => {
          const requestID = (result.data as ResponseNodeDiagnosticsRealtime).jobId;
          if (result.status === 201) {
            count = 1;
            setAttempts(count);
            forceUpdate();
            window.setTimeout(() => { pollRealtime(requestID); }, 5000);
          } else {
            addNotification({ type: 'error', message: 'Call failed' });
          }
        });
    } catch (e) {
      addNotification({ type: 'error', message: 'Call failed' });
    }
  }

  // usePerformanceMark(isLoading ? Stage.Usable : Stage.Complete, 'Node Diagnostics');

  return (
    <div className="content content--admin">
      <AdminSidebar title="Node diagnostics">
        <AdminSidebarAdminItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin page-content--large-bottom-margin node-diagnostics">
        <div className="node-diagnostics-imei">
          <Textbox
            label="IMEI"
            isRequired
            value={IMEI}
            onChange={(e) => {
              setIMEI(e.target.value);
            }}
            error={nodeDiagnosticsValidation.IMEIError}
            errorMessage={nodeDiagnosticsValidation.IMEIErrorMessage}
          />
          <Button
            buttonType="secondary"
            disabled={Waiting}
            onClick={() => {
              if (!Waiting) {
                if (IMEI === '') {
                  setNodeDiagnosticsValidation((oldValues) => ({
                    ...oldValues,
                    IMEIError: true,
                    IMEIErrorMessage: 'IMEI is required',
                  }));
                } else {
                  setWaiting(true);
                  doExternal();
                }
              }
            }}
          >
            <span>External</span>
          </Button>
          <Button
            buttonType="secondary"
            disabled={Waiting}
            onClick={() => {
              if (!Waiting) {
                if (IMEI === '') {
                  setNodeDiagnosticsValidation((oldValues) => ({
                    ...oldValues,
                    IMEIError: true,
                    IMEIErrorMessage: 'IMEI is required',
                  }));
                } else {
                  setWaiting(true);
                  doConnectionStatus();
                }
              }
            }}
          >
            <span>Connection status</span>
          </Button>
          <Button
            buttonType="secondary"
            disabled={Waiting}
            onClick={() => {
              if (!Waiting) {
                if (IMEI === '') {
                  setNodeDiagnosticsValidation((oldValues) => ({
                    ...oldValues,
                    IMEIError: true,
                    IMEIErrorMessage: 'IMEI is required',
                  }));
                } else {
                  setWaiting(true);
                  doGPS();
                }
              }
            }}
          >
            <span>GPS</span>
          </Button>
          <Button
            buttonType="secondary"
            disabled={Waiting}
            onClick={() => {
              if (!Waiting) {
                if (IMEI === '') {
                  setNodeDiagnosticsValidation((oldValues) => ({
                    ...oldValues,
                    IMEIError: true,
                    IMEIErrorMessage: 'IMEI is required',
                  }));
                } else {
                  setWaiting(true);
                  doStatic();
                }
              }
            }}
          >
            <span>Static</span>
          </Button>
        </div>
        <div className="node-diagnostics-realtime">
          <Textbox
            label="objectId"
            value={objectId}
            type="number"
            onChange={(e) => {
              setObjectId(e.target.value);
            }}
          />
          <Textbox
            label="instanceId"
            value={instanceId}
            type="number"
            onChange={(e) => {
              setInstanceId(e.target.value);
            }}
          />
          <Textbox
            label="resourceId"
            value={resourceId}
            type="number"
            onChange={(e) => {
              setResourceId(e.target.value);
            }}
            error={nodeDiagnosticsValidation.resourceIDError}
            errorMessage={nodeDiagnosticsValidation.resourceIDErrorMessage}
          />
          <Textbox
            label="subResourceId"
            value={subResourceId}
            type="number"
            onChange={(e) => {
              setSubResourceId(e.target.value);
            }}
          />
          <Button
            buttonType="secondary"
            disabled={Waiting}
            onClick={() => {
              if (!Waiting) {
                if (checkValidation()) {
                  return;
                }
                doRealtime();
                setNodeDiagnosticsValidation((oldValues) => ({
                  ...oldValues,
                  IMEIError: false,
                  IMEIErrorMessage: '',
                  resourceIDError: false,
                  resourceIDErrorMessage: '',
                }));
                setWaiting(false);
              }
            }}
          >
            <span>{Attempts === 0 ? 'Realtime' : `Polling ${Attempts}/18`}</span>
          </Button>
        </div>
        <div className="node-diagnostics-result">
          <h2>{ResultTitle}</h2>
          <pre>
            {JSON.stringify(Result, undefined, 3)}
          </pre>
        </div>
      </div>
    </div>
  );
}

export default NodeDiagnosticsPage;
