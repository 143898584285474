/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { TooltipPropsType } from '../../types/TooltipPropsType';

import { useAppContext } from '../../utils/AppContext';

function Tooltip(props: TooltipPropsType): JSX.Element {
  const defaultOffset = 15;
  const sec1 = 1000;
  const sec2 = 2000;

  const {
    text,
    position,
    children,
    addStyle,
    showAlways = false,
    delayed = false,
    offset = defaultOffset,
    addTooltipClass = '',
    disabled = false,
  } = props;

  const pos = position === undefined ? 'top' : position;
  const tooltipStyle = disabled ? { display: 'none' } : {};

  const { globalTooltipMode, setGlobalTooltipMode } = useAppContext();

  const [isShown, setIsShown] = useState(showAlways);
  const globalTooltipTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => () => {
    if (setGlobalTooltipMode) {
      setGlobalTooltipMode(false);
    }

    if (globalTooltipTimeout.current) {
      clearTimeout(globalTooltipTimeout.current);
    }

    if (window.globalTooltipOffTimeout) {
      clearTimeout(window.globalTooltipOffTimeout);
      window.globalTooltipOffTimeout = undefined;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [tooltipElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: pos,
    modifiers: [
      { name: 'offset', options: { offset: [0, offset] } },
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'preventOverflow', options: { padding: 5 } },
    ],
  });

  return (
    <>
      <div
        ref={setReferenceElement}
        data-testid={text}
        className="tooltip"
        style={addStyle}
        onMouseMove={() => {
          if (!showAlways && delayed) {
            if (!globalTooltipMode) {
              if (globalTooltipTimeout.current) {
                clearTimeout(globalTooltipTimeout.current);
              }

              globalTooltipTimeout.current = setTimeout(() => {
                setGlobalTooltipMode(true);
                setIsShown(true);
              }, sec1);
            }
          }
        }}
        onMouseEnter={() => {
          if (!showAlways && delayed) {
            if (window.globalTooltipOffTimeout) {
              clearTimeout(window.globalTooltipOffTimeout);
              window.globalTooltipOffTimeout = undefined;
            }
            if (globalTooltipMode) {
              setIsShown(true);
            }
          } else if (!delayed) {
            setIsShown(true);
          }
        }}
        onMouseLeave={() => {
          if (!showAlways && delayed) {
            if (globalTooltipTimeout.current) {
              clearTimeout(globalTooltipTimeout.current);
            }
            window.globalTooltipOffTimeout = setTimeout(() => {
              setGlobalTooltipMode(false);
            }, sec2);
            setIsShown(false);
          } else if (!delayed) {
            setIsShown(false);
          }
        }}
      >
        {children}
      </div>
      {isShown && text && (
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, ...tooltipStyle }}
            className={`tooltip__element ${addTooltipClass}`}
            {...attributes.popper}
          >
            {text}
            <div
              ref={setArrowElement}
              style={styles.arrow}
              className="arrow"
              data-popper-arrow
            />
          </div>,
          document.getElementById('root') as HTMLElement,
        )
      )}
    </>
  );
}

export default Tooltip;
