import React from 'react';
import { NavLink } from 'react-router-dom';
import { AdminSidebarItemPropsType } from '../../types/AdminSidebarItemProps';

function AdminSidebarItem(props: AdminSidebarItemPropsType): JSX.Element {
  const { icon, expandicon, title, path, link, showSub = false, isSubItem = false, greyBackground = false } = props;

  const isSelected = path.includes(link) ? 'admin__sidebar-item--selected' : '';
  const isSubSelected = showSub ? 'admin__sidebar-item--subselected' : '';
  const subItemClass = isSubItem ? 'admin__sidebar-item--subitem' : '';
  const greyBackgroundClass = greyBackground ? 'admin__sidebar-item--greybg' : '';

  return (
    <div
      data-testid="admin-sidebar-item"
      className={`admin__sidebar-item ${isSelected} ${isSubSelected} ${subItemClass} ${greyBackgroundClass}`}
    >
      <NavLink
        to={link}
        exact
        className="admin__sidebar-link"
        data-testid="admin-sidebar-item-link"
      >
        <div className="admin__sidebar-item-icon">{icon}</div>
        <div className="admin__sidebar-item-title">{title}</div>
        <div className="admin__sidebar-item-expandicon">{expandicon}</div>
      </NavLink>
      <span className="admin__sidebar-item-line" />
    </div>
  );
}

export default AdminSidebarItem;
