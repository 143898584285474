/* eslint-disable max-len */
import React, { useState } from 'react';
import RadioButtons from '../../../../../Common/Components/RadioButtons';
import Button from '../../../../../Common/Components/Button';

import Textbox from '../../../../../Common/Components/Textbox';
import { RadioButtonElementProps } from '../../../../../types/RadioButtonsProps';
import { CreateAlarmConfigProps, CreateAlarmConfigValidationProp, CreateAlarmConfigStateProp, CreateAlarmConfigPostProp } from '../../../../../types/CreateAlarmConfigProps';
import { postRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';

function CreateAlarmConfig(props: CreateAlarmConfigProps): JSX.Element {
  const { selectedCustomer, selectedSite, selectNodes, selectedGroup, alarmConfigResp, canSubmit } = props;
  const { addNotification } = useAppContext();

  const [createAlarmConfig, setCreateAlarmConfig] = useState<CreateAlarmConfigStateProp>({
    selectNodes: selectNodes || 'Site',
    selectedGroup: selectedGroup || undefined,
    gracePeriod: alarmConfigResp?.gracePeriod || '120',
    scheduleGating: alarmConfigResp?.scheduleGating.toString() || 'true',
    manualOverrideGating: alarmConfigResp?.manualOverrideGating.toString() || 'true',
    canSubmit,
  });
  const [createAlarmConfigValidation, setCreateAlarmConfigValidation] = useState<CreateAlarmConfigValidationProp>({
    gracePeriodError: false,
    gracePeriodErrorMessage: '',
  });

  const [scheduleGating, setScheduleGating] = useState<RadioButtonElementProps>({ label: createAlarmConfig?.scheduleGating, key: createAlarmConfig?.scheduleGating });
  const [manualOverrideGating, setManualOverrideGating] = useState<RadioButtonElementProps>({ label: createAlarmConfig?.manualOverrideGating, key: createAlarmConfig?.manualOverrideGating });
  const siteAlarmConfigPath = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/deviceconfiguration/lightstatealerts`;
  const groupAlarmConfigPath = `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${createAlarmConfig.selectedGroup}/deviceconfiguration/lightstatealerts`;
  const [waiting, setWaiting] = useState(false);

  function checkValidation() : boolean {
    if (!Number.isInteger(Number(createAlarmConfig.gracePeriod))) {
      setCreateAlarmConfigValidation((oldValues) => ({
        ...oldValues,
        gracePeriodError: true,
        gracePeriodErrorMessage: 'Grace period is required to be an integer',
      }));
      return true;
    }

    if ((Number(createAlarmConfig.gracePeriod) < 15) || (Number(createAlarmConfig.gracePeriod) > 720)) {
      setCreateAlarmConfigValidation((oldValues) => ({
        ...oldValues,
        gracePeriodError: true,
        gracePeriodErrorMessage: 'Grace period is required to be within 15 to 720 range',
      }));
      return true;
    }
    return false;
  }

  const submitConfig = async () => {
    if (checkValidation()) {
      return;
    }
    const updatePath = createAlarmConfig.selectNodes === 'Site' ? siteAlarmConfigPath : groupAlarmConfigPath;
    if (createAlarmConfig.selectNodes === 'Site') {
      setCreateAlarmConfigValidation((oldValues) => ({
        ...oldValues,
        gracePeriodError: false,
        gracePeriodErrorMessage: '',
      }));
      const postBody: CreateAlarmConfigPostProp = {
        gracePeriod: Number(createAlarmConfig.gracePeriod),
        scheduleGating: (scheduleGating.key === 'true'),
        manualOverrideGating: (manualOverrideGating.key === 'true'),
      };
      setWaiting(true);
      try {
        const resp = await postRequest(
          updatePath,
          postBody,
        );

        if (!resp.error) {
          addNotification({ type: 'success', message: 'Your "Alarm configuration" operation was successful' });
          setWaiting(false);
        } else {
          addNotification({ type: 'error', message: 'Your "Alarm configuration" operation has failed.' });
          setWaiting(false);
        }
      } catch (error) {
        addNotification({ type: 'error', message: 'Your "Alarm configuration" operation has failed.' });
        setWaiting(false);
      }
    } else {
      setCreateAlarmConfigValidation((oldValues) => ({
        ...oldValues,
        gracePeriodError: false,
        gracePeriodErrorMessage: '',
      }));
      const postBody: CreateAlarmConfigPostProp = {
        gracePeriod: Number(createAlarmConfig.gracePeriod),
        scheduleGating: (scheduleGating.key === 'true'),
        manualOverrideGating: (manualOverrideGating.key === 'true'),
      };
      setWaiting(true);
      try {
        const resp = await postRequest(
          updatePath,
          postBody,
        );

        if (!resp.error) {
          addNotification({ type: 'success', message: 'Your "Alarm configuration" operation was successful' });
          setWaiting(false);
        } else {
          addNotification({ type: 'error', message: 'Your "Alarm configuration" operation has failed.' });
          setWaiting(false);
        }
      } catch (error) {
        addNotification({ type: 'error', message: 'Your "Alarm configuration" operation has failed.' });
        setWaiting(false);
      }
    }
  };

  return (
    <div className="alarmconfig-divided__rightcontent">
      <div className="gracePeriodWrapper">
        <Textbox
          label="Grace Period (max 12hrs)"
          name="GracePeriod"
          isRequired
          value={String(createAlarmConfig.gracePeriod)}
          placeholder="Enter"
          onChange={(event) => {
            const gracePeriod = event.target.value;
            setCreateAlarmConfig((oldValues) => ({ ...oldValues, gracePeriod }));
          }}
          error={createAlarmConfigValidation.gracePeriodError}
          errorMessage={createAlarmConfigValidation.gracePeriodErrorMessage}
        />
        <span className="gracePeriodUnits"> Min </span>
      </div>
      <div className="applyToNodesWrapper">
        <span>While running on schedule </span>
        <RadioButtons
          onClick={(clickedRadioBtn: RadioButtonElementProps) =>
            setScheduleGating(clickedRadioBtn)}
          selected={scheduleGating}
          list={[{ label: 'Disabled', key: 'false' }, { label: 'Enabled', key: 'true' }]}
          type="light"
        />
        <span>While on manual override </span>
        <RadioButtons
          onClick={(clickedRadioBtn: RadioButtonElementProps) =>
            setManualOverrideGating(clickedRadioBtn)}
          selected={manualOverrideGating}
          list={[{ label: 'Disabled', key: 'false' }, { label: 'Enabled', key: 'true' }]}
          type="light"
        />
      </div>
      <div className="alarmconfig__container-footer">
        <Button
          buttonSize="medium"
          buttonType="primary"
          label="Submit"
          disabled={canSubmit || waiting}
          onClick={() => submitConfig()}
        />
      </div>
    </div>
  );
}

export default CreateAlarmConfig;
