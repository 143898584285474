/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import AssignFixture from '../../../Common/Components/AssignFixture';
import Button from '../../../Common/Components/Button';
import Modal from '../../../Common/Components/Modal';
import ManualOverride from '../../../Common/Components/ManualOverride';
import SlideIn from '../../../Common/Components/SlideIn';
import { ReactComponent as FullSunIcon } from '../../../img/icons/full-sun.svg';
import { ReactComponent as HalfSunIcon } from '../../../img/icons/half-sun.svg';
import { ReactComponent as MoonIcon } from '../../../img/icons/moon.svg';
import { FixtureObject } from '../../../types/FixtureObject';
import { GenericSensorData } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { ScheduleObject } from '../../../types/ScheduleObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { useAppContext } from '../../../utils/AppContext';
import { getRequest, postRequest } from '../../../utils/fetch';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import ViewFixture from '../../Config/Pages/Fixtures/Components/ViewFixture';
import ScheduleModal from '../../Schedules/Components/ScheduleModal';
import PlotSensor from '../Components/PlotSensors';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';
import { RealTimeSensorRequestPayload, SensorHistories, SensorResponseData, SensorData } from '../../../types/SensorRequestData';

function LightsOperation(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems, fetchedBulkSensorData } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const [expand, setExpand] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(0);
  const [openFixtureModal, setOpenFixtureModal] = useState(0);
  const [openDisassociateFixtureModal, setOpenDisassociateFixtureModal] = useState(0);
  const [openAssociateFixtureModal, setOpenAssociateFixtureModal] = useState(0);
  const [latestSensorTsFromDWH, setLatestSensorTsFromDWH] = useState(new Date(0).getTime());
  const [onDemandReadTriggered, setOnDemandReadTriggered] = useState(false);
  const [newSensorDataAvailable, setNewSensorDataAvailable] = useState(false);
  const { addNotification } = useAppContext();

  const isNonReadOnly = Utils.isNonReadOnly();

  const timestamp = useRef<number>(0);

  const displayedSensors = ['driverLevel', 'activePower', 'cumulativeEnergyUse', 'ambientLight'/* , 'lastDriverLevelReport' */];
  const [sensorData, setSensorData] = useState<(GenericSensorData)>({ nodeid: selectedNode.nodeid } as GenericSensorData);
  const [refreshLoadingSensors, setRefreshLoadingSensors] = useState(false);

  const createMap = (data: SensorHistories) => new Map(data.data.map((obj) => [obj.sensorId, obj]));
  const createCombinedResponse = (sensorIds: string[], dataFromLdv: Map<string, SensorData>, dataFromTel: Map<string, SensorData>) => sensorIds.map((id) => {
    const obj1 = dataFromLdv.get(id);
    const obj2 = dataFromTel.get(id);

    if (obj1 !== undefined && obj2 !== undefined) {
      const obj1Timestamp = new Date(obj1.timestamp).getTime();
      const obj2Timestamp = new Date(obj2.timestamp).getTime();

      if (obj1Timestamp > obj2Timestamp) {
        return obj1;
      }
      return obj2;
    }
    if (obj1 !== undefined && obj2 === undefined) {
      return obj1;
    }

    if (obj2 !== undefined && obj1 === undefined) {
      return obj2;
    }

    return {} as SensorData;
  }).filter((obj) => Object.keys(obj).length !== 0);

  useEffect(() => {
    // get sensor values from database
    Promise.resolve(fetchedBulkSensorData).then(async (response) => {
      let latestSensorTs = new Date(0).getTime();
      if (response.sensorHistories && response.sensorHistories[0]) {
        Object.values(response.sensorHistories[0].data).forEach((d) => {
          const utcSensorTimestamp = `${d.timestamp}Z`;
          const sensorTs = new Date(utcSensorTimestamp).getTime();
          if (latestSensorTs < sensorTs) {
            latestSensorTs = sensorTs;
          }
        });
      }
      setLatestSensorTsFromDWH(latestSensorTs);

      // get sensor data from device (read without trigger)
      const sensorIds = displayedSensors.map((sen) => Sensors.getSensorId(sen));
      const resp = await postRequest<RealTimeSensorRequestPayload, SensorResponseData>(
        Sensors.getSensorDataUrl({
          customerId: selectedCustomer.id,
          siteId: selectedSite.id,
        }),
        Sensors.getSensorPayload({
          nodeIds: [selectedNode.nodeid],
          sensorIds,
        }),
      );
      if (!resp.error) {
        // eslint-disable-next-line no-constant-condition
        if (resp.data.sensorHistories.length > 0) {
          const finalData = resp.data;
          // - convert device response timestamps to ISO in order to compare with db values
          finalData.sensorHistories[0].data = finalData.sensorHistories[0].data.map((d) => {
            const converted = DateTime.fromISO(d.timestamp).setZone(selectedSite.timezone || 'utc').toFormat('yyyy-MM-dd HH:mm:ss');
            d.timestamp = converted;
            return d;
          });
          const mapOfLatest = createMap(response.sensorHistories[0]);
          const mapOfRead = createMap(finalData.sensorHistories[0]);

          const unionSet = new Set<string>();
          mapOfLatest.forEach((_, key) => unionSet.add(key));
          mapOfRead.forEach((_, key) => unionSet.add(key));

          const combinedResponse = createCombinedResponse(Array.from(unionSet), mapOfLatest, mapOfRead);
          response.sensorHistories[0].data = combinedResponse;
        }
      }
      const newData = Sensors.getSensorTableDataWithValueAndSensor(
        selectedItems,
        displayedSensors,
        response,
        selectedSite.timezone,
      );

      setSensorData(newData[0]);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, fetchedBulkSensorData]);

  const refreshSensors = useCallback(() => {
    setRefreshLoadingSensors(true);
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = sensorData ? [{ ...sensorData }] : undefined;
    const tmpDisplayedData = Object.entries(sensorData).reduce((acc, e) =>
      (displayedSensors.includes(e[0]) ? acc : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: selectedNode.nodeid });
    setSensorData(tmpDisplayedData as GenericSensorData);

    Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      [selectedNode],
      displayedSensors.map((sen) => Sensors.getSensorId(sen)),
    )
      .then((response) => {
        setOnDemandReadTriggered(true);
        setRefreshLoadingSensors(false);
        if (timestamp.current <= currentDate) {
          let earliestSensorTs;
          if (response.sensorHistories && response.sensorHistories[0]) {
            Object.values(response.sensorHistories[0].data).forEach((d) => {
              const sensorTs = new Date(d.timestamp).getTime();
              if (!earliestSensorTs || sensorTs < earliestSensorTs) {
                earliestSensorTs = sensorTs;
              }
            });
          }
          if (latestSensorTsFromDWH < earliestSensorTs) {
            const newData = Sensors.getSensorTableDataWithValueAndSensor(
              selectedItems,
              displayedSensors,
              response,
              selectedSite.timezone,
              originalData,
            );
            setSensorData(newData[0]);
            setNewSensorDataAvailable(true);
          } else {
            setSensorData(sensorData);
          }
        }
      }).catch((e) => {
        addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNotification, selectedCustomer.id, selectedItems, selectedNode, selectedSite.id, sensorData]);

  const getIcon = (val: number): JSX.Element => {
    const moon = 64;
    const sun = 96;

    if (val < moon) {
      return <MoonIcon />;
    } if (val > sun) {
      return <FullSunIcon />;
    } if (val <= sun && val >= moon) {
      return <HalfSunIcon />;
    }
    return <></>;
  };

  // Schedules

  const { data: schedulesResp } = useSWR<Array<ScheduleObject>>(
    selectedSite.id ? [`/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/schedules-detailed`, 'SchedulesPage'] : null,
    (url) => getRequest(url, {}, (data: ScheduleObject[]): ScheduleObject[] => data?.map((schedule) => {
      schedule.events.forEach((event) => {
        if (event.date) {
          event.date = DateTime.fromFormat(event.date as unknown as string, 'yyyy-LL-dd').toJSDate();
        }
      });
      return schedule;
    })),
  );

  const nodeSchedule : ScheduleObject | undefined = useMemo(() => schedulesResp?.find(
    (schedule) => schedule.lightingGroups?.find((groupid) => groupid === selectedNode.lightinggroupid),
  ), [schedulesResp, selectedNode]);

  // Fixture

  const { data: fixturesResp } = useSWR<Array<FixtureObject>>(selectedCustomer.id
    ? [`/organizations/${selectedCustomer.id}/fixtures/types`, 'GetOrgBaseFixtures'] : null);

  const modalScheduleView = 3;
  const listWidth = 185;

  return (
    <div className="operation">
      <SlideIn hasExpandButton expand={expand} setExpand={setExpand}>
        <>
          <div className="slide-in__title">
            <span>Operation</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="slide-in__content operation__content">
            <div className="custom-list-element">
              <div className="list-element-title">Schedule</div>
              <div className="custom-list-element-subtitle">
                {nodeSchedule?.name}
              </div>
              <div className="custom-list-element__content">
                <div className="custom-list-element__content-name">
                  Scheduled driver level
                </div>
                <div className="custom-list-element__content-info">
                  <div className="custom-list-element__content-info-data">
                    <div>
                      <span>{(selectedNode.schedDriverLevel || 0).toString()}</span>
                      %
                    </div>
                    {Sensors.getLightsOnIcon(selectedNode.schedDriverLevel || 0)}
                  </div>
                  <div className="slide-in__link">
                    <Button
                      label="View schedule"
                      onClick={() => {
                        setOpenScheduleModal(1);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-list-element fixture">
              <div className="list-element-title">Fixture</div>
              <div className="custom-list-element-subtitle">
                <div>{selectedNode.fixturename}</div>
              </div>
              <div className="slide-in__link multiple">
                {Utils.isSensityAdmin() && (selectedNode.fixturename !== '') && (
                  <Button
                    label="Disassociate"
                    extraClasses="margin-right-10"
                    onClick={() => setOpenDisassociateFixtureModal(1)}
                  />
                )}
                {Utils.isSensityAdmin() && (selectedNode.fixturename === '') && (
                  <Button
                    label="Associate"
                    extraClasses="margin-right-10"
                    onClick={() => setOpenAssociateFixtureModal(1)}
                  />
                )}
                <Button
                  label="View profile"
                  onClick={() => setOpenFixtureModal(1)}
                />
              </div>
            </div>

            <div className="custom-list-element sensors">
              <div className="list-element-title">Sensors</div>
              {(!refreshLoadingSensors) && (
              <SyncIcon
                data-testid="icon"
                className="list-element__refresh"
                onClick={() => {
                  refreshSensors();
                }}
              />
              )}
              {(onDemandReadTriggered && !newSensorDataAvailable && !refreshLoadingSensors) && (
              <div className="list-element__refresh-result">
                <Tooltip text="There's no newer data available.">
                  <InfoIcon />
                </Tooltip>
              </div>
              )}
              {(refreshLoadingSensors) && (
                <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
              )}

              <SlideinListElementBig
                title="Driver level"
                valueBold={Sensors.getRoundValue(sensorData?.driverLevel?.value)}
                date={sensorData?.driverLevel?.time}
                value="%"
                isFirstElement
                customVal={Sensors.getLightsOnIcon(sensorData?.driverLevel?.value
                  ? parseInt(sensorData?.driverLevel?.value, 10) : 0)}
              />
              <SlideinListElementBig
                title="Active power"
                valueBold={sensorData?.activePower?.value || '--'}
                date={sensorData?.activePower?.time}
                value="W"
              />
              <SlideinListElementBig
                title="Cumulative energy use"
                valueBold={sensorData?.cumulativeEnergyUse?.value || '--'}
                date={sensorData?.cumulativeEnergyUse?.time}
                value={sensorData?.cumulativeEnergyUse?.unit || 'kWh'}
              />
              <SlideinListElementBig
                title="Ambient light"
                valueBold={sensorData?.ambientLight?.value || '--'}
                date={sensorData?.ambientLight?.time}
                value="lux"
                border="light"
                icon={getIcon(parseInt(sensorData?.ambientLight?.value || '', 10))}
              />
            </div>
          </div>
        </>
      </SlideIn>
      {openDisassociateFixtureModal === 1 && (
        <Modal
          width="325"
          setModalOpen={() => setOpenDisassociateFixtureModal(0)}
          title="Disassociate fixture instance"
          secondaryButtonLabel="Cancel"
          primaryButtonLabel="Submit"
          primaryButtonAction={async () => {
            try {
              const postBody = {
                nodeIds: [selectedNode.nodeid],
              };

              const result = await postRequest(
                `/organizations/${selectedCustomer.id}/fixtures/nodes/remove`,
                postBody,
              );

              if (!result.error) {
                addNotification({ type: 'info', message: 'Your "Disassociate fixture instance" operation is completed.' });
                setOpenDisassociateFixtureModal(0);
              } else {
                addNotification({ type: 'error', message: `Your "Disassociate fixture instance" operation has failed: ${result.error}` });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Disassociate fixture instance" operation has failed.' });
            }
          }}
        >
          <div className="content">
            Are you sure you want to disassociate this node from its current fixture instance?
          </div>
        </Modal>
      )}
      {openAssociateFixtureModal === 1 && (
        <Modal
          width="325"
          setModalOpen={() => setOpenAssociateFixtureModal(0)}
          title="Associate fixture instance"
          secondaryButtonLabel="Cancel"
          primaryButtonLabel="Submit"
          primaryButtonAction={async () => {
            try {
              const requestBody = {
                override: false,
              };

              const result = await postRequest(
                `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/fixtures/nodes/${selectedNode.nodeid}/associate`,
                requestBody,
              );

              if (!result.error) {
                addNotification({ type: 'info', message: 'Your "Trigger fixture association" operation is completed.' });
                setOpenAssociateFixtureModal(0);
              } else {
                addNotification({ type: 'error', message: `Your "Trigger fixture association" operation has failed: ${result.error}` });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Trigger fixture association" operation has failed.' });
            }
          }}
        >
          <div className="content">
            Are you sure you want to trigger fixture association for this node?
          </div>
        </Modal>
      )}
      {openScheduleModal === 1 && nodeSchedule && (
        <ScheduleModal
          activeSchedule={nodeSchedule}
          openScheduleModal={modalScheduleView}
          selectedCustomer={selectedCustomer}
          setOpenScheduleModal={() => setOpenScheduleModal(0)}
        />
      )}
      {openFixtureModal === 1 && fixturesResp && (
        <ViewFixture
          modalOpen={() => setOpenFixtureModal(0)}
          fixtureDetails={fixturesResp?.find((fxt) => fxt.id === selectedNode.fixtureid)}
        />
      )}
      {expand && (
        <SlideIn
          hasExpandButton={false}
          expand={expand}
          setExpand={setExpand}
          isExpanded
          setExpandClose={setExpand}
        >
          <>
            <div className="slide-in__content--expanded manual-override">
              {isNonReadOnly && (
              <ManualOverride
                selectedCustomer={selectedCustomer}
                selectedSite={selectedSite}
                selectedItems={selectedItems}
                notDropdown
                type="nodes"
                listLength={selectedItems.size}
                primaryButtonLabel=""
              />
              )}
              <div className="manual-override-element">
                <PlotSensor
                  selectedCustomer={selectedCustomer}
                  selectedSite={selectedSite}
                  selectedItems={selectedItems}
                  listWidth={listWidth}
                />
              </div>
              <div className="manual-override-element fixture">
                {isNonReadOnly && (
                <AssignFixture
                  selectedCustomer={selectedCustomer}
                  selectedSite={selectedSite}
                  selectedItems={selectedItems}
                  closeAssignFixture={() => undefined}
                  notDropdown
                  hideSelectboxLabel
                  listWidth={listWidth}
                  isInSidebar
                  type="nodes"
                  selectedLength={selectedItems.size}
                  primaryButtonLabel=""
                />
                )}
              </div>
            </div>
          </>
        </SlideIn>
      )}
    </div>
  );
}

export default LightsOperation;
