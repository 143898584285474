import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../../Common/Components/Loading';
import { BASEPATH } from '../../utils/constants';

class Login extends Component {
  componentDidMount(): void {
    if (window.NSN.loginMockEnabled) {
      window.location.href = `${BASEPATH}/code?code=x`;
    } else {
      const url = `${window.NSN.loginappURL}/oauth2/authorize`;
      const responseType = '?response_type=code';
      const clientId = `&client_id=${window.NSN.loginappClientId}`;
      const redirectUrl = `&redirect_uri=${window.location.origin}${BASEPATH}/code`;
      const scope = '&scope=openid+email';
      const state = `&state=${uuidv4()}`;
      const referrerApp = '&referrer_app=lighting';

      window.location.href = url + responseType + clientId + redirectUrl + scope + state + referrerApp;
    }
  }

  render(): JSX.Element {
    return (<Loading fullScreen isLoading />);
  }
}

export default Login;
