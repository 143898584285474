import React from 'react';
import Modal from '../../../Common/Components/Modal';
import { JobDeleteGroupProps } from '../../../types/JobDeleteGroupProps';

function JobDeleteGroup(props: JobDeleteGroupProps): JSX.Element {
  const { setModalOpen, message } = props;

  return (
    <div className="job-delete-group">
      <Modal
        title="Delete lighting group"
        width="347"
        setModalOpen={setModalOpen}
        secondaryButtonAction={() => setModalOpen(false)}
        secondaryButtonLabel="Close"
      >
        <div className="content">
          <div className="content-text">
            <div>
              {message}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default JobDeleteGroup;
