import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../img/icons/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../img/icons/right-arrow.svg';
import { SlideInPropsType } from '../../types/SlideInPropsType';

const width = 225;
const expandedWidth = 225;

function SlideIn(props: SlideInPropsType): JSX.Element {
  const {
    children,
    hasExpandButton = false,
    isExpanded = false,
    slideInWidth = width,
    expandedSlideInWidth = expandedWidth,
    expand,
    setExpand,
    bgStyle,
  } = props;

  const style = {
    width: `${isExpanded ? expandedSlideInWidth : slideInWidth}px`,
    height: '100%',
  };

  return (
    <div
      className={`slide-in ${isExpanded ? 'slide-in--expanded' : ''} ${bgStyle || ''}`}
      style={style}
      data-testid="slide-in-component"
    >
      {hasExpandButton && setExpand && (
        <div className="slide-in__buttons">
          <button
            type="button"
            onClick={() => setExpand(!expand)}
            className="slide-in__expand-button"
            data-testid="slide-in-expand-button"
          >
            {expand ? <LeftArrowIcon /> : <RightArrowIcon />}
          </button>
        </div>
      )}
      {children}
    </div>
  );
}

export default SlideIn;
