/* eslint-disable no-nested-ternary */
import React from 'react';
import Tooltip from '../../../Common/Components/Tooltip';
import { SidebarItemPropsType } from '../../../types/SidebarItemPropsType';

function SidebarItem(props: SidebarItemPropsType): JSX.Element {
  const {
    order,
    icon,
    toggleSidebar,
    setToggleSidebar,
    isEnabled,
    colorType,
    tooltipText,
  } = props;

  const tooltipOffset = 0;
  const isOpen = order === toggleSidebar;

  return (
    <>
      <div
        data-testid="sidebar-item"
        className={`sidebar__item sidebar__item--${
          !isEnabled ? 'disabled' : isOpen ? 'open' : 'closed'
        }`}
      >
        <Tooltip text={tooltipText} position="right" offset={tooltipOffset} delayed>
          <button
            data-testid="sidebar-item-btn"
            type="button"
            className={`sidebar__item-button ${colorType || ''}`}
            onClick={() =>
              (isEnabled ? setToggleSidebar(isOpen ? -1 : order) : '')}
          >
            {icon}
          </button>
        </Tooltip>
      </div>
      <span />
    </>
  );
}

export default SidebarItem;
