import { useState } from 'react';
import { LightsPageState } from '../../types/LightsPageProps';
import { NodeObject } from '../../types/NodeObject';

export default function useLightsPageState(): LightsPageState {
  const [activeToolbar, setActiveToolbar] = useState<number>(0);
  const [activeToolbarBtn, setActiveToolbarBtnToolbar] = useState<number>(0);
  const [activeSubtoolbars, setActiveSubtoolbars] = useState<Array<string>>([]);
  const [selectedNodes, _setSelectedNodes] = useState<Map<string, NodeObject>>(new Map());

  const handleActiveSubtoolbars = (id: string) =>
    setActiveSubtoolbars((prevState) =>
      (prevState.find((x) => x === id)
        ? prevState.filter((x) => x !== id)
        : prevState.concat(id)));

  const handleActiveToolbar = (toolbarNum: number) => {
    setActiveToolbar(() => {
      setActiveSubtoolbars([]);
      return toolbarNum === activeToolbar ? 0 : toolbarNum;
    });
  };

  const setSelectedNodes = (newState: Map<string, NodeObject>) => {
    _setSelectedNodes((oldState) => {
      const oldNodeIds = Array.from(oldState.keys());
      const newNodeIds = Array.from(newState.keys());
      const added = newNodeIds.filter((i) => !oldNodeIds.includes(i));
      const removed = oldNodeIds.filter((i) => !newNodeIds.includes(i));

      window.dispatchEvent(new CustomEvent('MapSelectedItemsChange', { detail: { added, removed } }));

      return newState;
    });
  };

  return {
    selectedNodes,
    setSelectedNodes,
    activeToolbar,
    handleActiveSubtoolbars,
    activeToolbarBtn,
    setActiveToolbar,
    setActiveToolbarBtnToolbar,
    activeSubtoolbars,
    handleActiveToolbar,
  };
}
