/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import useSWR from 'swr';
import DropDown from './DropDown';
import Modal from './Modal';
import SelectBox from './SelectBox';
import { AssignFixtureProps } from '../../types/AssignFixtureProps';
import { FixtureObject } from '../../types/FixtureObject';
import { SelectBoxItemType } from '../../types/SelectBoxPropsType';
import Utils from '../../utils/Utils';
import { useAppContext } from '../../utils/AppContext';

import { ReactComponent as ScrollRightIcon } from '../../img/icons/scroll-right.svg';
import { postRequest } from '../../utils/fetch';

const fixtureListWidth = 205;
const sideBarMaxItems = 3;
const defaultMaxItems = 4;

function AssignFixture(props: AssignFixtureProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
    selectedItems,
    closeAssignFixture,
    updateNodes,
    notDropdown = false,
    hideSelectboxLabel = false,
    listWidth = fixtureListWidth,
    isInSidebar = false,
    type,
    selectedLength,
    primaryButtonLabel = 'Cancel',
  } = props;

  const [selectedFixture, setSelectedFixture] = useState({
    title: 'Select',
    key: '0',
  });

  const { data: fixturesResp } = useSWR<Array<FixtureObject>>(selectedSite.id ? [
    `/organizations/${selectedCustomer.id}/fixtures/types`,
    'GetFixtures',
  ] : null);

  const { addNotification } = useAppContext();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="multi-node-modals">
      {(!openModal || notDropdown) && (
      <DropDown
        title="Assign fixture"
        secondaryButtonType="secondary"
        secondaryButtonLabel="Submit"
        secondaryButtonSize="small"
        secondaryButtonAction={() => {
          const typeText = type === 'nodes' ? 'nodes' : 'groups';
          if (selectedLength === 0) {
            addNotification({ type: 'warning', message: `No ${typeText} were selected for the operation.` });
          } else if (selectedFixture.key === '0') {
            addNotification({ type: 'warning', message: 'No fixture is selected for the operation.' });
          } else {
            setOpenModal(true);
          }
        }}
        primaryButtonLabel={primaryButtonLabel}
        primaryButtonType="primary"
        primaryButtonSize="small"
        primaryButtonAction={() => closeAssignFixture()}
        className="toolbar-action-dropdown"
      >
        <SelectBox
          label={hideSelectboxLabel ? '' : 'Select fixture'}
          type="dark"
          list={
            (fixturesResp
              && Utils.arrayToSelectbox(fixturesResp, 'id', 'name'))
            || []
          }
          onClick={(item: SelectBoxItemType) => {
            setSelectedFixture(item);
          }}
          title={selectedFixture.title}
          selectedItemKey={selectedFixture.key}
          listWidth={listWidth}
          maxItems={isInSidebar ? sideBarMaxItems : defaultMaxItems}
        />
      </DropDown>
      )}
      {openModal && (
        <Modal
          title="Assign fixture"
          width="380"
          setModalOpen={() => {
            setOpenModal(false);
            closeAssignFixture();
          }}
          primaryButtonLabel="Submit"
          primaryButtonAction={async () => {
            try {
              let postBody = {};

              if (type === 'nodes' && selectedItems instanceof Map) {
                postBody = { nodeIds: Array.from(selectedItems.keys()) };
              } else {
                postBody = { groupIds: selectedItems };
              }

              const result = await postRequest(
                type === 'nodes'
                  ? `/organizations/${selectedCustomer.id}/fixtures/types/${selectedFixture.key}/assign/nodes`
                  : `/organizations/${selectedCustomer.id}/sites/${selectedSite.id}/fixtures/types/${selectedFixture.key}/assign/groups`,
                postBody,
              );

              if (!result.error) {
                addNotification({ type: 'info', message: 'Your "Assign Fixture" operation is completed.' });
                setOpenModal(false);
                closeAssignFixture();

                if (updateNodes) {
                  updateNodes();
                }
              } else {
                addNotification({ type: 'error', message: `Your "Assign Fixture" operation has failed: ${result.error}` });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Assign Fixture" operation has failed.' });
            }
          }}
          secondaryButtonLabel="Cancel"
        >
          <div className="content assign-fixture">
            <div className="content-selected-nodes">
              <span className="content-selected-nodes-big">
                {selectedLength}
              </span>
              <span>{type === 'nodes' ? (selectedLength > 1 ? 'Nodes selected' : 'Node selected') : (selectedLength > 1 ? 'Groups selected' : 'Group selected')}</span>
            </div>
            <div className="content__confirm">
              <div className="arrow">
                <ScrollRightIcon />
              </div>
              <div className="content__confirm-data">
                <span className="content__confirm-data-title">Fixture</span>
                <span className="content__confirm-data-value">
                  {`${selectedFixture.key !== '0' ? selectedFixture.title : '-'}`}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AssignFixture;
