import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Datepicker from '../../../Common/Components/Datepicker';

import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { DimmingPointsDateProps } from '../../../types/DimmingPointsDateProps';
import { ScheduleObject } from '../../../types/ScheduleObject';
import { scheduleDays } from '../../../utils/constants';
import Utils from '../../../utils/Utils';

function DimmingPointsDate(props: DimmingPointsDateProps): JSX.Element {
  const { scheduleData, setScheduleData, selectedSequenceIndex, isReadOnly } = props;
  const otherEvents = useMemo(() => scheduleData.events.filter((_, index) => index !== selectedSequenceIndex), [scheduleData.events, selectedSequenceIndex]);
  const allEvents = useMemo(() => [...scheduleData.events], [scheduleData.events]);

  const selectedDate = useMemo(() => allEvents[selectedSequenceIndex].date || new Date(), [allEvents, selectedSequenceIndex]);
  const disabledDays = useMemo(() => otherEvents.map((event) => event.days as string[]).flat(1), [otherEvents]);
  const disabledDates = useMemo(() => otherEvents.map((event) => event.date as Date).flat(1), [otherEvents]);

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(allEvents[selectedSequenceIndex].date ? 1 : 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSequenceIndex]);

  const setEventDates = useCallback((type: 'date' | 'days', newDate?: Date | string) => {
    const editedEvent = { ...allEvents[selectedSequenceIndex] };

    if (type === 'date' && newDate instanceof Date) {
      delete editedEvent.days;
      editedEvent.date = newDate;
    }

    if (type === 'days' && typeof newDate === 'string') {
      delete editedEvent.date;

      if (!editedEvent.days) {
        editedEvent.days = [];
      }

      if (editedEvent.days?.includes(newDate)) {
        editedEvent.days = editedEvent.days?.filter((day) => day !== newDate);
      } else {
        editedEvent.days?.push(newDate);
      }

      const tmpArr = scheduleDays.filter((day) => editedEvent.days?.includes(day.key)).map((day) => day.key);

      if (tmpArr.length === 0) {
        delete editedEvent.days;
      } else {
        editedEvent.days = tmpArr;
      }
    }

    setScheduleData((oldSchedule: ScheduleObject) => {
      const newSchedule = { ...oldSchedule };

      newSchedule.events = newSchedule.events.map((oldEvent, index) => {
        let newEvent = { ...oldEvent };

        if (index === selectedSequenceIndex) {
          newEvent = editedEvent;
        }

        return newEvent;
      });

      return newSchedule;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents, selectedSequenceIndex]);

  const isOverSpecialDaysLimit = useMemo(() => scheduleData.events.filter((e) => e.date).length >= 100, [scheduleData.events]);

  const shouldBeEditable = useMemo(() => !scheduleData.events[selectedSequenceIndex].date, [scheduleData.events, selectedSequenceIndex]);

  return (
    <>
      {isReadOnly ? (
        <div className="dimmingpoints-date readonly">
          {scheduleData.events[selectedSequenceIndex]?.days ? (
            <div className="dimmingpoints-date__content">
              <div className="dimmingpoints-date__content-title">Days of the week</div>
              <div className="dimmingpoints-date__content-list">
                {
                  scheduleDays.map((day, i) => (
                    <button
                      onClick={() => undefined}
                      type="button"
                      key={`day-${i}`}
                      className={`day ${scheduleData.events[selectedSequenceIndex]?.days?.includes(day.key) ? 'selected' : ''}`}
                    >
                      <span>{day.name}</span>
                    </button>
                  ))
                }
              </div>
            </div>
          ) : (
            <div className="dimmingpoints-date__content">
              <div className="dimmingpoints-date__content-title">Date</div>
              <div className="dimmingpoints-date__content-date">
                {scheduleData.events[selectedSequenceIndex]?.date !== undefined
                  ? Utils.getConvertedDate(scheduleData.events[selectedSequenceIndex].date, 'LLLL d, yyyy')
                  : ''}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="dimmingpoints-date">
          <div className="dimmingpoints-date__title">
            <button
              type="button"
              onClick={() => setActiveTab(0)}
              className={`dimmingpoints-date__title-text ${activeTab === 0 ? 'selected' : ''}`}
            >
              <span>Regular days</span>
            </button>
            <button
              disabled={isOverSpecialDaysLimit && shouldBeEditable}
              type="button"
              onClick={() => setActiveTab(1)}
              className={`dimmingpoints-date__title-text ${activeTab === 1 ? 'selected' : ''}`}
            >
              <span>Special date</span>
            </button>
          </div>
          { activeTab === 0 && (
          <div className="dimmingpoints-date__content">
            <div className="dimmingpoints-date__content-title">
              Select days of the week
            </div>
            <div className="dimmingpoints-date__content-list">
              {
              scheduleDays.map((day, i) => (
                <button
                  onClick={() => { setEventDates('days', day.key); }}
                  type="button"
                  key={`day-${i}`}
                  className={`day ${scheduleData.events[selectedSequenceIndex]?.days?.includes(day.key) ? 'selected' : ''}`}
                  disabled={disabledDays.includes(day.key)}
                >
                  <span>{day.name}</span>
                </button>
              ))
            }
            </div>
            <div className="dimmingpoints-date__content-footer">
              <InfoIcon />
              <span>
                Schedule updates require up to
                <span className="bold"> 3 days </span>
                to reach all nodes.
              </span>
            </div>
          </div>
          )}
          { activeTab === 1 && (
          <div className="dimmingpoints-date__content spec-date">
            <div className="dimmingpoints-date__content-title">
              Select date
            </div>
            <Datepicker
              placement="bottom"
              selectedDate={typeof selectedDate === 'string' ? new Date(Utils.getConvertedDate(selectedDate, 'LLLL d, yyyy', 'utc')) : selectedDate}
              setSelectedDate={(newDate: Date) => {
                setEventDates('date', newDate);
              }}
              placeholder="Select date"
              type="light"
              excludeDates={disabledDates}
              minDate={new Date()}
            />
            <div className="dimmingpoints-date__content-footer">
              <InfoIcon />
              <span>
                Schedule updates require up to
                <span className="bold"> 3 days </span>
                to reach all nodes.
              </span>
            </div>
          </div>
          )}
        </div>
      )}
    </>
  );
}

export default DimmingPointsDate;
