import { ChangePasswordFormProps, ChangePasswordFormErrors, UseChangePasswordForm } from '../../types/ChangePasswordProps';
import { SubmitFn, ValidateFn } from '../../types/useFormProps';
import { useAppContext } from '../AppContext';
import Utils from '../Utils';
import { postRequest } from '../fetch';

export default function useChangePasswordForm(
  setMfaModal: React.Dispatch<React.SetStateAction<boolean>>,
  setMfaSession: React.Dispatch<React.SetStateAction<string>>,
): UseChangePasswordForm {
  const { addNotification } = useAppContext();

  const validate: ValidateFn<ChangePasswordFormProps, ChangePasswordFormErrors> = (
    values: ChangePasswordFormProps,
    prevErrors?: ChangePasswordFormErrors,
    current?: string,
  ): ChangePasswordFormErrors => {
    const errors = {} as ChangePasswordFormErrors;

    const isPasswordInvalid = (password: string): Record<string, boolean> => ({
      length: password.length < 8,
      uppercase: !/[A-Z]/.test(password),
      lowercase: !/[a-z]/.test(password),
      digit: !/\d/.test(password),
      // eslint-disable-next-line no-useless-escape
      specchar: !/[\!\#\$\%\&\(\)\*\,\-\.\/]/.test(password),
    });

    if ((current === undefined || current === 'newPassword') && values.newPassword) {
      const passInvalid = isPasswordInvalid(values.newPassword);

      if (passInvalid.length
        || passInvalid.uppercase
        || passInvalid.lowercase
        || passInvalid.digit
        || passInvalid.specchar
      ) {
        errors.newPassword = true;
        errors.newPasswordInvalid = passInvalid;
      }
    }

    if (values.newPasswordAgain) {
      if (!values.newPassword
        || !values.newPasswordAgain
        || (values.newPassword !== values.newPasswordAgain)
      ) {
        errors.newPasswordAgain = true;
      }
    }

    switch (current) {
      case 'newPassword':
        if (prevErrors?.newPasswordAgain && values.newPassword !== values.newPasswordAgain) {
          errors.newPasswordAgain = prevErrors.newPasswordAgain;
        }
        break;
      case 'newPasswordAgain':
        if (prevErrors?.newPassword) {
          errors.newPassword = prevErrors.newPassword;
        }
        if (prevErrors?.newPasswordInvalid) {
          errors.newPasswordInvalid = prevErrors.newPasswordInvalid;
        }
        break;
      default:
        break;
    }

    return errors;
  };

  const submit: SubmitFn<ChangePasswordFormProps> = async (
    formValues,
    setLoading,
    setModalOpen,
  ) => {
    setLoading(true);

    const resp = await postRequest<
      // eslint-disable-next-line camelcase
      { password: string, old_password: string },
      { mfa?: boolean, session?: string}
    >(
      `/organizations/${Utils.getUserOrgId()}/users/${Utils.getUserID()}/update-password`,
      { password: formValues.newPassword, old_password: formValues.oldPassword },
    );

    setLoading(false);

    if (resp.error) {
      addNotification({ type: 'error', message: resp.error });
    } else {
      const { data } = resp;

      if (data && data.mfa && data.mfa === true && data.session) {
        setMfaModal(true);
        setMfaSession(data.session);
      } else {
        setModalOpen(false);
        addNotification({ type: 'success', message: 'Password updated successfully. Please log back in.' });
      }
    }
  };

  return {
    validate,
    submit,
  };
}
