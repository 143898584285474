import React from 'react';
import { DimmingPointsLuxOnOffProps } from '../../../types/DimmingPointsLuxOnOffProps';
import Textbox from '../../../Common/Components/Textbox';

function DimmingPointsLuxOnOff(props: DimmingPointsLuxOnOffProps): JSX.Element {
  const { scheduleData, setScheduleData, isReadOnly } = props;

  function setLuxMin(value: number) {
    const newSchedule = { ...scheduleData };
    if (newSchedule.configuration) {
      newSchedule.configuration.ph_lux_min = value;
    } else {
      newSchedule.configuration = { ph_lux_min: value, ph_lux_max: 240 };
    }
    setScheduleData(newSchedule);
  }

  function setLuxMax(value: number) {
    const newSchedule = { ...scheduleData };
    if (newSchedule.configuration) {
      newSchedule.configuration.ph_lux_max = value;
    } else {
      newSchedule.configuration = { ph_lux_min: 160, ph_lux_max: value };
    }
    setScheduleData(newSchedule);
  }

  return (
    <div className="dimmingpoints-luxonoff">
      <div className="dimmingpoints-luxonoff__title">Photocell sensitivity (lux)</div>
      <div className="dimmingpoints-luxonoff__fields">
        <Textbox
          label="Turn on below"
          name="ph_lux_min"
          disabled={isReadOnly}
          type="number"
          min="1"
          max="1299"
          value={Math.floor(((scheduleData.configuration?.ph_lux_min || 160) / 10)).toString()}
          onChange={(e) => setLuxMin(e.target.value * 10)}
        />
        <Textbox
          label="Turn off above"
          name="ph_lux_max"
          disabled={isReadOnly}
          type="number"
          min="1"
          max="1300"
          value={Math.floor(((scheduleData.configuration?.ph_lux_max || 240) / 10)).toString()}
          onChange={(e) => setLuxMax(e.target.value * 10)}
        />
      </div>
    </div>
  );
}

export default DimmingPointsLuxOnOff;
